import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { Button, Checkbox, Input,Toast, Drawer } from '../../../ui-kit';
import Loader from '../loader';
import STRINGS from '../../../../localization';
import RolesService from '../../../../services/roles';

const RolesFields = (props) => {
  const [permissionsData, setPermissionsData] = useState(null);
  const [isInputBlur, setIsInputBlur] = useState(false);

  const getPermissionsData = async () => {
    try {
      const data = await RolesService.getPermissions();
      setPermissionsData(data);
    } catch {
      Toast.error(STRINGS.components.toasts.error);
    }
  };

  useEffect(() => {
    getPermissionsData();
  }, []);

  const renderInputType = () => {
    return (isInputBlur && !props.nameRole && !props.selectedRoleTitle) ? 'danger' : 'default';
  };

  return (
    <Drawer maxWidth="xs" title={props.title} open={props.open} onClose={props.onClose} anchor="right"
      visible={props.isOpen}>
      <div className="modal">
        <div className="header-nosteps">
          <div className="title">
            <span>{props.title}</span>
          </div>
        </div>
        {props.loading === true ? (
          <Loader />
        ) : (
          <div className="body role-body">
            <div className="container_fullwidth">
            <div className="subtitle">
              <span>{`${STRINGS.screens.coupons.title} *`}</span>
            </div>
            <Input
              label={`${STRINGS.screens.roles.roleName} *`}
              defaultValue={props.selectedRoleTitle ?? ''}
              type="text"
              size="large"
              className="roles_fields_modal_input"
              onChange={(e) => props.setNameRole(e)}
              inputType={renderInputType()}
              onBlur={() => setIsInputBlur(true)}
            />
            </div>
            <div className="container_fullwidth">
                <div className="subtitle">
                  <span>{`${STRINGS.screens.roles.accesses} *`}</span>
                </div>
                <div className="permissions-list">
                {permissionsData?.map((permission) => (
                <Checkbox
                  onChange={() => props.checkCheckbox(permission.id.toString())}
                  checked={props.checkedCheckboxes?.includes(permission.id.toString())}
                  label={permission.permissionData?.[0]?.title} />
            ))}
             </div>
            </div>
          </div>
        )}
        <div className="footer role-footer">
          <div />
          <Button
            loading={props.isLoadingButtonSave}
            onClick={() => props.saveChanges()}
            className="roles_fields_modal_button"
            label={STRINGS.screens.roles.modal.save}
            disabled={!props.nameRole && !props.selectedRoleTitle || !props.checkedCheckboxes.length }
            type={!props.nameRole && !props.selectedRoleTitle || !props.checkedCheckboxes.length ? 'default' : 'primary'}
          />
        </div>
      </div>
    </Drawer>
  );
};

/**
 * Properties
 */

RolesFields.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  selectedRoleTitle: PropTypes.string,
  setNameRole: PropTypes.func,
  checkCheckbox: PropTypes.func,
  checkedCheckboxes: PropTypes.array,
  saveChanges: PropTypes.func,
  isLoadingButtonSave: PropTypes.bool,
};

RolesFields.defaultProps = {
  open: false,
  onClose: () => { },
  title: '',
  selectedRoleTitle: '',
  setNameRole: () => { },
  checkCheckbox: () => { },
  checkedCheckboxes: [],
  saveChanges: () => { },
  isLoadingButtonSave: false,
};

export default RolesFields;

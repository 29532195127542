import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Modal, Form, Button } from '../../../ui-kit';
import Row from '../../Row';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal as ModalPhoto, ModalGateway } from 'react-images';
import { URL } from '../../../../services/config';
import { getBeautyDateTimeString } from '../../../utils/datetime';

import { reasonTypes } from '../../../../constants/review-reasons';
import './styles.scss';
import Loader from './loader';

import STRINGS from '../../../../localization';

const FeedbackFields = (props) => {
  const {loading, setLoading} = props;

  const [review, setReview] = useState({});

  const [processed, setProcessed] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);

  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(async () => {
    if (props.selectedReview) {
      setLoading(true);
      const resp = await props.getReviewById(props.selectedReview?.id);
      if (resp) {
        setReview(resp);
        setProcessed(resp?.processed);
        setLoading(false);
      }
    }
  }, [props.selectedReview]);

  return (
    <Modal
      onClose={props.onClose}
      maxWidth="md"
      open={props.isOpen}
      title={STRINGS.screens.reviews.feedbackView}
      hidden={viewerIsOpen}
    >
      {loading ? (
       <Loader />
      ) : (
        <Form className="root">
          <div className="basic">
            <aside>
              <h3>{review?.name}</h3>
              <p>{getBeautyDateTimeString(new Date(review?.publicationDate))}</p>
            </aside>
            <aside>
              <div className="contacts-block">
                <Row title={STRINGS.screens.reviews.phoneNumber} data={review?.phoneNumber}/>
                <Row title={STRINGS.screens.reviews.reason} data={review?.types?.map((el) => reasonTypes[el]).join(', ')}/>
                <Row title={STRINGS.screens.reviews.email} data={review?.email}/>
                <Row title={STRINGS.screens.reviews.restaurant} data={review?.restaurantId}/>
              </div>
            </aside>
            <aside className="description">
              <p>{review?.message || STRINGS.screens.reviews.qq}</p>
            </aside>
            {review?.files?.length > 0 && (
              <aside className="photos">
                <>
                  <Gallery
                    photos={review?.files.map((image) => ({
                      src: `${URL}files/${image.fileId}`,
                      width: 4,
                      height: 3,
                    }))}
                    onClick={openLightbox}
                  />
                  <ModalGateway>
                    {viewerIsOpen && (
                      <ModalPhoto onClose={closeLightbox}>
                        <Carousel
                          currentIndex={currentImage}
                          views={review?.files.map((image) => ({
                            source: `${URL}files/${image.fileId}`,
                          }))}
                        />
                      </ModalPhoto>
                    )}
                  </ModalGateway>
                </>
              </aside>
            )}
          </div>
          <div className="bottom">
            {processed === false ? (
              <Button
                label={STRINGS.screens.reviews.setProcessed}
                type="primary"
                loading={props.loading}
                onClick={() => {
                  props.processed(review?.id, props.selectedReview.index);
                  setProcessed(true);
                }}
              />
            ) : (
              <Button
                label={STRINGS.screens.reviews.processed}
                type="default"
                disabled={true}
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      )}
    </Modal>
  );
};

FeedbackFields.propTypes = {
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func,
  feedbacks: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  loading: PropTypes.bool,
};
FeedbackFields.defaultProps = {
  showModal: false,
  handleShowModal: () => {},
  feedbacks: [],
  handleChangeStatus: () => {},
  loading: false,
};

export default FeedbackFields;

import React from 'react';
import { Spinner } from '../../ui-kit';

const ModalLoader = () => (
<div className="modal-loader">
    <Spinner />
</div>);


export default ModalLoader;

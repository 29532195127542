/**
 * @prettier
 */

import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import localization from './localization';
import user from './current-user';
import aggregators from './aggregators';
import orders from './orders';
import search from './header-search';
import stuff from './stuff';
import webkassaChecks from './webkassa-checks';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['stuff'],
};

const appReducer = combineReducers({
  localization,
  user,
  search,
  orders,
  webkassaChecks,
  stuff,
  aggregators,
});

const initialState = appReducer({}, {}, {}, {}, {}, {});

const RESET_REDUX = 'RESET_REDUX';

const rootReducer = (state, action) => {
  if (action.type === RESET_REDUX) {
    state = {
      ...initialState,
      localization: state.localization,
      stuff: state.stuff,
    };
  }

  return appReducer(state, action);
};

export const resetRedux = () => ({
  type: RESET_REDUX,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  return createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
};

export const store = configureStore();

export let persistor = persistStore(store);

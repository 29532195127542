/**
 * @prettier
 */

import React from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import UserFields from '../../components/modals/user-fields';
import { Paper } from '../../ui-kit';
import '../styles.scss';
import Skelet from './skelet';
import { URL } from '../../../services/config';
import PERSON_PLACEHOLDER from '../../../assets/person-placeholder.png';
import { getBeautyDateString } from '../../utils/datetime';
import { renderUserName } from '../helpers';

const View = (props) => {
  const { user } = props;

  return (
    <>
      <UserFields
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        setIsOpen={props.setShowModal}
        title={STRINGS.screens.users.edit}
        loadedUser={user}
        loading={props.loading}
        setLoading={props.setLoading}
        saveChanges={props.saveChanges}
      />
      <Head
        label={renderUserName(user?.firstName, user?.lastName)}
        button={{
          size: 'medium',
          icon: false,
          label: STRINGS.screens.users.edit,
          onClick: () => props.setShowModal('edit'),
        }}
        status={props.active === true ? 'active' : 'disabled'}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.users.title,
            link: '/users',
          },
          {
            label: props.id,
          },
        ]}
      />
      {props.skeletVisible ? (
        <Skelet />
      ) : (
        <Paper>
          <div className="user-view-container">
            <div>
              <div className="block">
                <div className="picture">
                  {user?.fileId ? (
                    <img className="image" src={`${URL}files/${user?.fileId}`} />
                  ) : (
                    <img className="image" src={PERSON_PLACEHOLDER} />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.modal.general}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.id}:</span>
                  <span>{props?.id}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.users.number}:</span>
                  <span>{user.phone}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.users.email}:</span>
                  <span>{user.email}</span>
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.modal.additional}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.users.sex}:</span>
                  <span>
                    {user?.gender
                      ? STRINGS.general.gender[user.gender]
                      : STRINGS.screens.modal.noData}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.users.birthDate}:</span>
                  <span>{getBeautyDateString(new Date(user.birthDate))}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.users.carNumber}:</span>
                  <span>{user.carNumber || STRINGS.screens.modal.noData}</span>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};
export default View;

import React, {useState, useEffect} from 'react';

import RestaurantsView from './restaurants-view';
import RestaurantsService from '../../../services/restaurants';
import ChocofoodService from '../../../services/chocofood';
import {controlGlovoClosing} from '../helpers';
import {Toast} from '../../ui-kit';
import STRINGS from '../../../localization';
import {useBottomScrollListener} from 'react-bottom-scroll-listener';
import {configureStatus} from '../../utils/configure-status';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {StatusRestaurantsView} from './status-restaurants-view';

const RestaurantContainer = () => {
    const screenHeight = document.documentElement.clientHeight;

    const SIZE = screenHeight <= 840 ? 10 : 20;

    const langId = useSelector((store) => store.localization.currentLanguage.id);

    const search = useSelector((store) => store.search.value);

    const {languages, advantages} = useSelector((store) => store.stuff);

    const [restaurants, setRestaurants] = useState([]);

    const [openRestaurants, setOpenRestaurants] = useState([]);

    const [skeletVisible, setSkeletVisible] = useState(true);

    const [page, setPage] = useState(1);

    const [showConfirm, setShowConfirm] = useState('');

    const [isLast, setIsLast] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [showModal, setShowModal] = useState('');

    const [loading, setLoading] = useState(false);

    const [bottomLoading, setBottomLoading] = useState(false);

    const [selectedRestaurant, setSelectedRestaurant] = useState();

    const [sort, setSort] = useState({type: '', sort: ''});

    const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

    const history = useHistory();

    const openRestaurantInfo = (id) => {
        setOpenRestaurants((prevState) => {
            if (prevState.includes(id)) {
                return prevState.filter((item) => item !== id);
            }
            return [...prevState, id];
        });
    };

    const addRestaurant = async (f) => {

        const mapInitialWorkingHours = () => {
            const serviceTypes = 3;
            const day = 7;
            var workingHours = [];
            for (let s = 0; s < serviceTypes; s++) {
                workingHours[s] = [];
                for (let d = 0; d < day; d++) {
                    workingHours[s][d] = {
                        serviceTypeId: s + 1,
                        day: d + 1,
                        operatingMode: 'DAY',
                        openTime: new Date(new Date(0, 0, 0, 0, 0)).toISOString(),
                        closeTime: new Date(new Date(0, 0, 0, 0, 0)).toISOString(),
                    };
                }
            }
            return [...workingHours[0], ...workingHours[1], ...workingHours[2]];
        };
        // setLoading(true);
        const dataToSend = {
            restaurantId: f.id,
            phone: f.phone?.length ? f.phone : null,
            address: f.address,
            restaurantData: f.restaurantData,
            workingHours: [...mapInitialWorkingHours()],
            email: f.email,
        };
        try {
            const resp = await RestaurantsService.addRestaurant({data: dataToSend});
            setRestaurants((past) => ({
                isLast: past.isLast,
                data: [
                    {
                        id: resp.id,
                        status: resp.status,
                        address: resp.address.addressData.find((el) => el.languageId === langId).title,
                        city: resp.address.city.cityData.find((el) => el.languageId === langId).name,
                        title: dataToSend.restaurantData.find((el) => el.languageId === langId).title,
                    },
                    ...past?.data,
                ],
            }));
            Toast.success(STRINGS.notify.success);
        } catch (error) {
            if (error?.status) {
                Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
            }
        }
        setShowModal('');
        setLoading(false);
    };

    const getRestaurantById = async (id) => {
        try {
            const data = await RestaurantsService.getRestaurantById(id);
            return data;
        } catch (error) {
            if (error?.status) {
                Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
            }
        }
    };

    const saveChanges = async (f) => {
        setLoading(true);
        await controlGlovoClosing(f);
        let dataToSend = {
            phone: f.phone?.length ? f.phone : null,
            tier: parseInt(f.tier, 10),
            address: f.address,
            restaurantData: f.restaurantData,
            workingHours: f.workingHours,
            email:f.email,
        };
        if (f?.id !== parseInt(f.unmodifiedRestaurant.id, 10)) {
            dataToSend = {...dataToSend, id: f.id};
        }
        if (f.restaurantAdvantages) {
            dataToSend = Object.assign(dataToSend, {restaurantAdvantages: f.restaurantAdvantages});
        }
        if (f.workingHours) {
            dataToSend = Object.assign(dataToSend, {workingHours: f.workingHours});
        }
        if (f.ipAddress !== null) {
            dataToSend = {...dataToSend, ipAddress: f.ipAddress};
        }
        if (f.printerName !== null) {
            dataToSend = {...dataToSend, printerName: f.printerName};
        }
        if (f.packageId !== null) {
            dataToSend = {...dataToSend, packageId: parseInt(f.packageId, 10)};
        }
        if (f.timezone !== '') {
            dataToSend = {...dataToSend, timeZone: f.timezone};
        }
        if (f.chocofoodActive !== f.initChocofoodActive) {
            try {
                await ChocofoodService.putClosing({isActive: f.chocofoodActive, restaurantId: f.id});
            } catch (error) {
                Toast.error(STRINGS.screens.restaurants.qo);
            }
        }
        try {
            await RestaurantsService.updateRestaurant({
                id: f.unmodifiedRestaurant.id,
                data: dataToSend,
            });

            Toast.success(STRINGS.notify.success);
        } catch (error) {
            if (error?.status) {
                Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
            }
        }

        setShowModal('');
        setLoading(false);
        setPage(1);
        await getRestaurants({
            page: 1,
            size: SIZE,
            langId: langId,
            search: search,
            sort: sorts,
        });
    };

    const getRestaurants = async (props) => {
        setSkeletVisible(true);
        try {
            const resp = await RestaurantsService.getRestaurants(props);
            setRestaurants({
                isLast: resp?.isLast,
                data: [...resp?.data],
            });
            setSkeletVisible(false);
        } catch (error) {
            setSkeletVisible(false);
        }
    };

    const handleDeleteRestaurant = async () => {
        setShowConfirm('');
        try {
            setRestaurants((past) => ({
                isLast: past.isLast,
                data: [...past?.data.filter((el) => el.id !== selectedRestaurant.id)],
            }));
            await RestaurantsService.deleteRestaurant(selectedRestaurant.id);
        } catch (error) {
            if (error?.status) {
                Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
            } else {
                Toast.success(STRINGS.notify.success);
            }
        }
    };
    const applySort = (mode) => {
        setSort((prevState) => {
            return {
                sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
                type: mode,
            };
        });
    };
    const getNextPage = async () => {
        setBottomLoading(true);
        try {
            const resp = await RestaurantsService.getRestaurants({
                page: page + 1,
                size: 10,
                langId: langId,
                search: search,
                sort: sorts,
            });

            setPage((old) => old + 1);
            setRestaurants({
                isLast: resp.isLast,
                data: [...restaurants?.data, ...resp.data],
            });
        } catch (error) {
        }
        setBottomLoading(false);
    };
    useBottomScrollListener(() => {
        if (!bottomLoading && !isLast) {
            getNextPage();
        }
    });

    const changeStatus = async (elem) => {
        try {
            await RestaurantsService.updateStatus(elem.id, configureStatus(elem));
            let temp = [...restaurants.data];
            temp.find((el) => el.id === elem.id).status = configureStatus(elem).status;
            setRestaurants((old) => ({isLast: old.isLast, data: temp}));
            Toast.success(STRINGS.notify.status);
        } catch (error) {
            if (error?.status) {
                Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
            }
        }
    };

    useEffect(() => {
        const getList = async () => {
            setPage(1);
            await getRestaurants({
                page: 1,
                size: SIZE,
                langId: langId,
                search: search,
                sort: sorts,
            });
        };
        getList();
    }, [search, sorts]);

    if (history.location.pathname === '/status-restaurants') {
        return (
            <StatusRestaurantsView
                bottomLoading={bottomLoading}
                loading={loading}
                setLoading={setLoading}
                restaurants={restaurants}
                setRestaurants={setRestaurants}
                languages={languages}
                getRestaurantById={getRestaurantById}
                currentPage={currentPage}
                sort={sort}
                applySort={applySort}
                showModal={showModal}
                setShowModal={setShowModal}
                setCurrentPage={setCurrentPage}
                openRestaurants={openRestaurants}
                openRestaurantInfo={openRestaurantInfo}
                showConfirm={showConfirm}
                setShowConfirm={setShowConfirm}
                advantages={advantages}
                setSkeletVisible={setSkeletVisible}
                skeletVisible={skeletVisible}
                history={history}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                changeStatus={changeStatus}
            />
        );
    }

    return (
        <RestaurantsView
            bottomLoading={bottomLoading}
            loading={loading}
            setLoading={setLoading}
            saveChanges={saveChanges}
            addRestaurant={addRestaurant}
            restaurants={restaurants}
            setRestaurants={setRestaurants}
            languages={languages}
            getRestaurantById={getRestaurantById}
            currentPage={currentPage}
            sort={sort}
            applySort={applySort}
            showModal={showModal}
            setShowModal={setShowModal}
            setCurrentPage={setCurrentPage}
            openRestaurants={openRestaurants}
            openRestaurantInfo={openRestaurantInfo}
            showConfirm={showConfirm}
            setShowConfirm={setShowConfirm}
            advantages={advantages}
            setSkeletVisible={setSkeletVisible}
            skeletVisible={skeletVisible}
            history={history}
            handleDeleteRestaurant={handleDeleteRestaurant}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            changeStatus={changeStatus}
        />
    );
};

export default RestaurantContainer;

import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {MainTabs, Toast, Icon, Confirm, Table, Tooltip, Button, SecondaryTabs} from '../../ui-kit';
import SkeletManagers from './skelet';
import {Head} from '../../components';
import SendMenu from '../../components/modals/send-menu';
import AggregatorMenu from '../../components/modals/AggregatorMenu';
import NOPHOTO from '../../../assets/nophoto.png';
import {URL} from '../../../services/config';
import {menuCategories} from '../../../constants/categories';
import {generatePrice} from '../../utils/generate-price';
import ProductShortView from '../../components/modals/product-short-view';
import ComboShortView from '../../components/modals/combo-short-view';
import STRINGS from '../../../localization';

import './styles.scss';
import {CloseAggregator} from './closeAggregator';

const ProductsView = (props) => {
    const inputRef = useRef();
    return (
        <>
            <Confirm
                visible={props.showConfirm === 'delete'}
                title={STRINGS.screens.roles.modal.confirmAction}
                onClose={() => props.setShowConfirm('')}
                subtitle={STRINGS.screens.goods.deleteQuestion}
                subtitleBold={props.selectedProduct?.title}
                buttons={[
                    {
                        label: STRINGS.screens.roles.modal.delete,
                        onClick: props.handleDeleteProduct,
                    },
                    {
                        label: STRINGS.screens.roles.modal.leave,
                        outline: true,
                        onClick: () => {
                            props.setShowConfirm('');
                        },
                    },
                ]}
            />
            <SendMenu
                currentRestaurant={{
                    title: STRINGS.screens.aggregators.qj,
                    id: parseInt(props.restaurantId, 10),
                    posId: parseInt(props.restaurantId, 10),
                    current: true,
                    number: 1,
                }}
                aggregatorName="-"
                CATEGORIES={props.CATEGORIES}
                showModal={props.showModal}
                selectedCategory={props.selectedCategory}
                getMenu={props.getMenu}
                loading={props.loading}
                isOpen={props.showModal === 'send'}
                saveChanges={props.uploadToAggregator}
                onClose={() => props.setShowModal('')}
                title={`${STRINGS.screens.menu.AA} ${props.aggregatorName}`}
                menu={props.menu}
                existingMenu={props.existingMenu}
            />
            <AggregatorMenu
                aggregator={props.aggregatorName}
                isOpen={props.showModal === 'add'}
                restaurantId={props.restaurantId}
                showModal={props.showModal}
                onClose={() => props.setShowModal('')}
                type={props.selectedAggregatorCategory}
            />
            <ProductShortView
                onClose={() => props.setShowModal('')}
                isOpen={props.showModal === 'products-view'}
                selectedProduct={props.selectedProduct}
                title={props.selectedProduct?.title}
            />
            <ComboShortView
                onClose={() => props.setShowModal('')}
                isOpen={props.showModal === 'combo-view'}
                selectedProduct={props.selectedProduct}
                title={props.selectedProduct?.title}
            />
            <Head
                label={STRINGS.screens.aggregators.qd}
                button={{
                    icon: false,
                    label: STRINGS.screens.menu.editMenu,
                    onClick: () => {
                        props.setShowModal('add');
                    },
                }}
                secondButton={{
                    size: 'medium',
                    outline: true,
                    icon: false,
                    label: `${STRINGS.screens.menu.AA} ${props.aggregatorName}`,
                    onClick: () => {
                        props.setShowModal('send');
                    },
                }}
                filter={false}
                options={props.CATEGORIES}
                breadCrumbs={[
                    {
                        label: STRINGS.components.sidebar.manage,
                        link: '/',
                    },
                    {
                        label: STRINGS.screens.restaurants.title,
                        link: '/restaurants',
                    },
                    {
                        label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurantId}`,
                        link: `/restaurants/${props.restaurantId}/`,
                    },
                    {
                        label: STRINGS.screens.aggregators.aggregators,
                    },
                ]}
            />

            <CloseAggregator aggregatorName={props.aggregatorName} restaurantId={props.restaurantId} />

            <div>
                <div className="summary_table">
                    <MainTabs
                        active={props.aggregatorName}
                        data={props.aggregators.map((el) => ({label: el.name, key: el.key}))}
                        onClick={props.setAggregatorName}
                    />
                    <div className="toolbar">
                        <SecondaryTabs
                            data={menuCategories}
                            selected={props.selectedAggregatorCategory}
                            setSelected={props.setSelectedAggregatorCategory}
                        />
                        <div className="aggregator-id-container">
                            <div className="text">
                                <span className="label">{STRINGS.screens.aggregators.qk}</span>
                                <input
                                    ref={inputRef}
                                    className="input-id"
                                    value={props.aggregatorId}
                                    onChange={(e) => props.setAggregatorId(e.target.value)}
                                    readOnly={props.toggleEdit === false}
                                />
                            </div>
                            <div
                                className="icon"
                                onClick={() => {
                                    props.setToggleEdit((prev) => !prev);
                                    inputRef.current.focus();
                                }}
                            >
                                {props.toggleEdit === true ? (
                                    <div
                                        onClick={() => {
                                            if (props.isAggregatorId === true) {
                                                props.putAggregatorsId();
                                            } else {
                                                props.postAggregatorsId();
                                            }
                                        }}
                                    >
                                        <Icon name="check" width="20" height="20" color="#3F8CFE"/>
                                    </div>
                                ) : (
                                    <Icon name="edit-aggregators" width="20" height="20" color="#3F8CFE"/>
                                )}
                            </div>

`                        </div>
                        <Button
                            size="medium"
                            label={STRINGS.screens.aggregators.qg}
                            outline={true}
                            loading={props.sendMenuToAggregatorLoading}
                            onClick={async () => {
                                const response = await props.sendMenuToAggregator();
                                if (response) {
                                    props.history.push(
                                        `/restaurants/${props.restaurantId}/aggregators/dataSendMenuPreview/`,
                                        {
                                            data: response,
                                        },
                                    );
                                } else {
                                    Toast.error(STRINGS.screens.aggregators.qf);
                                }
                            }}
                        />
                    </div>
                    {props.skeletVisible ? (
                        <SkeletManagers/>
                    ) : (
                        <>
                            <Table
                                loading={props.bottomLoading}
                                head={() => (
                                    <tr className="summary_table_heading">
                                        <th/>
                                        <th>{STRINGS.screens.goods.id}</th>
                                        <th>{STRINGS.screens.goods.title}</th>
                                        <th>{STRINGS.screens.goods.prices.default}</th>
                                        <th>{STRINGS.screens.goods.prices.delivery}</th>
                                        <th/>
                                    </tr>
                                )}
                            >
                                <>
                                    {props.existingMenu?.data?.map((item) => (
                                        <tr key={item.posId} className="summary_table_body unselectable">
                                            <td>
                                                {item?.fileId ? (
                                                    <img
                                                        className="image"
                                                        style={{width: '100px'}}
                                                        src={`${URL}files/${item?.fileId}`}
                                                    />
                                                ) : (
                                                    <img className="image" style={{width: '100px'}} src={NOPHOTO}/>
                                                )}
                                            </td>
                                            <td>{item.posId}</td>
                                            <td>{item.posTitle}</td>
                                            <td>{generatePrice(item.prices.default)}</td>
                                            <td>{generatePrice(item.prices.delivery)}</td>
                                            <td className="summary_table_edit">
                                                <div className="summary_table_edit_wrapper">
                                                    <Tooltip title={STRINGS.components.tooltips.open}>
                                                        <div
                                                            className="summary_table_iconWrapper"
                                                            onClick={() => {
                                                                props.setSelectedProduct({
                                                                    id: item?.posId,
                                                                    title: item?.posTitle,
                                                                });
                                                                props.setShowModal(`${props.selectedAggregatorCategory}-view`);
                                                            }}
                                                        >
                                                            <Icon name="visibility" width={24} height={24}/>
                                                        </div>
                                                    </Tooltip>
                                                    <Tooltip title={STRINGS.components.tooltips.delete}>
                                                        <div
                                                            className="summary_table_iconWrapper"
                                                            onClick={() => {
                                                                props.setSelectedProduct({
                                                                    id: item?.posId,
                                                                    title: item.posTitle,
                                                                });
                                                                props.setShowConfirm('delete');
                                                            }}
                                                        >
                                                            <Icon name="trash-outline" width={24} height={24}/>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            </Table>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

ProductsView.propTypes = {
    languages: PropTypes.array,
    goods: PropTypes.array,
    showModal: PropTypes.string,
    setShowModal: PropTypes.func,
    editableProduct: PropTypes.object,
    setEditableProduct: PropTypes.func,
    saveChanges: PropTypes.func,
};

ProductsView.defaultProps = {
    languages: [],
    goods: null,
    showModal: '',
    setShowModal: () => {
    },
    editableProduct: {},
    setEditableProduct: () => {
    },
    saveChanges: () => {
    },
};

export default ProductsView;

/**
 * @prettier
 */

import React, { useState } from 'react';

import { InputDate, Toast } from '../../../ui-kit';
import AggregatorService from '../../../../services/aggregator';
import STRINGS from '../../../../localization';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Spinner from '../../../ui-kit/spinner';
import Button from '@mui/material/Button';

const PunchCardsMobileReportContainer = () => {
  const history = useHistory();
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [isDateSelected, setIsDateSelected] = useState(false);

  const [loading, setLoading] = useState(false);

  const getMobileReport = async () => {
    setLoading(true);
    try {
      return await AggregatorService.getPunchCardsMobileReports({
        langId: langId,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    } finally {
      setLoading(false);
    }
  };
  const getData = async () => {
    const res = await getMobileReport();
    const fileName = `punch-cards-${startDate.toISOString()}-${endDate.toISOString()}.xlsx`;
    await AggregatorService.processFile(res, fileName);
    history.push('/dashboard');
    setLoading(false);
  };

  const formatDate = (date) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    if (!date) {
      return new Date().toLocaleDateString('ru-RU', options);
    }
    return date.toLocaleDateString('ru-RU', options);
  };

  return (
    <Modal open={!isDateSelected}>
      <div className="date-modal-wrapper">
        <div className="date-modal-inner">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="date-modal-title">Выберите период для отчета</div>
              <InputDate
                label="Начало периода"
                inputType="default"
                size="small"
                maxDate={new Date()}
                inputValue={formatDate(startDate)}
                onChange={setStartDate}
              />
              <InputDate
                label="Конец периода"
                inputType="default"
                size="small"
                maxDate={new Date()}
                inputValue={formatDate(endDate)}
                onChange={setEndDate}
              />
              <div className="date-modal-buttons">
                <Button variant="outlined" onClick={() => history.push('/dashboard')}>
                  {STRINGS.screens.modal.cancel}
                </Button>
                <Button
                  variant="contained"
                  className="button-submit"
                  onClick={() => {
                    getData();
                  }}
                >
                  {STRINGS.screens.modal.next}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PunchCardsMobileReportContainer;

import CategoriesService from '../../services/categories';
import { useState, useEffect } from 'react';

const extendData = (data) => {
  return [{id: 0, key: 0, label: 'Все товары'}, ...data.map((item) => ({...item, key: item.id, label: item.title}))];
};

const useCategories = ({ langId, type = ''}) => {

  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState({ firstLoading: true, opacityLoading: false });

  const getCategories = async () => {

    setLoading({ ...loading, opacityLoading: true });

    let data = [];
    if (type === 'products') {
      data = await CategoriesService.getCategories({key: type === 'products' ? '' : type, langId});
    } else {
      data = [];
    }

    setCategories(extendData(data));

    setLoading({ firstLoading: false, opacityLoading: false });
  };

  useEffect(() => {
      getCategories();
  }, [langId, type]);

  return { categories };
};

export default useCategories;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { URL } from '../config';

export default class WoltService {
  static async sendMenu({ data, restaurantId }) {
    let link = `${URL}wolt/menu/${restaurantId}/send`;
    try {
      const request = await api.post(link, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success(`Send menu to Wolt from ${restaurantId} restaurant result`, request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with sending menu to Wolt from ${restaurantId} restaurant`, error);
      throw await error.response?.json();
    }
  }
  static async putClosing(data) {
    let link = `${URL}wolt/${data.restaurantId}/closing`;
    try {
      const request = await api.patch(link, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ status: data.isActive }),
      });

      debug.success('Data retrieval put closing Wolt result', request);

      return true;
    } catch (error) {
      debug.error('Failed with configure put Wolt closing', error);
      throw await error.response?.json();
    }
  }
  static async getClosing(id) {
    let link = `${URL}wolt/${id}/closing`;
    try {
      const request = await api.get(link, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success(`Get closing from Wolt for ${id} restaurant`, request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with get closing from Wolt for ${id} restaurant`, error);
      throw await error.response?.json();
    }
  }
}

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Input, Drawer, CustomDropzone } from '../../../ui-kit';
import STRINGS from '../../../../localization';
import Loader from '../loader';
import { validateObject } from '../../../utils/input-functions';

const INIT_FOCUS_VALUE = {
  title0: true,
  title1: true,
  title2: true,
  serialNumber: true,
  file: true,
};

const INIT_STORY_TYPE_DATA = [
  { languageId: 1, title: '' },
  { languageId: 2, title: '' },
  { languageId: 3, title: '' },
];

const StoryTypeFields = (props) => {
  const maxStep = 2;
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [file, setFile] = useState(null);
  const [serialNumber, setSerialNumber] = useState('');
  const [storyTypeData, setStoryTypeData] = useState(INIT_STORY_TYPE_DATA);
  const prepareFile = async (fileId) => {
    let resp = await props.getFileById(fileId);
    resp = Object.assign(resp, { preview: URL.createObjectURL(resp), id: fileId });
    return resp;
  };
  const initialize = () => {
    setStep(1);
    setId();
    setSelectedLanguage(1);
    setFile(null);
    setSerialNumber();
    setStoryTypeData(INIT_STORY_TYPE_DATA);
    setIsFocus(INIT_FOCUS_VALUE);
  };
  const prepareStoryTypeData = (storyTypeDataPatched) => {
    return storyTypeData.map((el, key) => ({
      languageId: el.languageId,
      title: storyTypeDataPatched[key].title ? storyTypeDataPatched[key].title : '',
    }));
  };

  const validateSerialNumber = (sn) => {
    if (sn === props.selectedStoryType?.serialNumber) {
      return true;
    } else {
      return !props.serialNumbers.includes(parseInt(sn, 10));
    }
  };

  const [validation, setValidation] = useState();

  useEffect(() => {
    setValidation(validateObject(Object.assign(titles, { file, serialNumber }), SCHEME));
  }, [storyTypeData, file, serialNumber]);

  const SCHEME = {
    title0: (value) => value?.length > 0,
    title1: (value) => value?.length > 0,
    title2: (value) => value?.length > 0,
    serialNumber: (value) =>
      Number.isNaN(Number.parseFloat(value)) === false && validateSerialNumber(value),
    file: (value) => !!value,
  };
  const [isFocus, setIsFocus] = useState(INIT_FOCUS_VALUE);

  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };
  const titles = storyTypeData.reduce(
    (acc, el, key) => ({ ...acc, [`title${key}`]: el.title }),
    [],
  );

  const mapData = (story) => {
    setLoading(true);
    setId(story?.id);
    setSerialNumber(story?.serialNumber);
    setStoryTypeData(prepareStoryTypeData(story?.storyTypeData));
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await props.saveChanges({
      id: id,
      serialNumber: parseInt(serialNumber, 10),
      storyTypeData: storyTypeData,
      file: file,
    });
    setTimeout(() => setLoading(false), 100);
  };

  useEffect(() => {
    if (props.selectedStoryType && props.isOpen === true) {
      mapData(props.selectedStoryType);
    }
  }, [props.selectedStoryType, props.isOpen]);

  useEffect(async () => {
    if (props.selectedStoryType && props.isOpen === true && step === 1) {
      setLoading(true);
      const story = props.selectedStoryType;
      setFile(await prepareFile(story?.file?.id));
      setLoading(false);
    }
  }, [props.selectedStoryType, props.isOpen, step]);

  useEffect(() => {
    if (!props.isOpen) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <div className="modal">
        <Form onSubmit={handleSubmit}>
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.stories.serial} *</span>
                    </div>
                    <Input
                      className="container_fullwidth_one_first"
                      value={serialNumber}
                      onChange={setSerialNumber}
                      label={`${STRINGS.screens.stories.enterSerial} *`}
                      inputType={
                        !isFocus?.serialNumber &&
                        !validateSerialNumber(serialNumber) &&
                        !validation?.data?.serialNumber?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      type="number"
                      onFocus={() => changeFocus('serialNumber', true)}
                      onBlur={() => changeFocus('serialNumber', false)}
                    />
                    {!validateSerialNumber(serialNumber) && (
                      <div className="error">
                        <span>{STRINGS.screens.stories.uniqueSerialWarning}</span>
                      </div>
                    )}
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.img} *</span>
                    </div>
                    <CustomDropzone maxFiles={1} files={file} onChange={setFile} />
                  </div>
                </>
              )}
              {step === 2 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.langs} *</span>
                  </div>
                  <div className="langs">
                    {props.languages?.map((language) => (
                      <Button
                        key={language.id}
                        label={language.name}
                        value={language.id}
                        size="medium"
                        className="lang"
                        type={selectedLanguage === language.id ? 'primary' : 'default'}
                        data-active={selectedLanguage === language.id}
                        onClick={(e) => {
                          setSelectedLanguage(language.id);
                          e.preventDefault();
                        }}
                      >
                        <span className="day-label">{language.name}</span>
                      </Button>
                    ))}
                  </div>
                  <Input
                    className="container_fullwidth_one_first"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...storyTypeData];
                      temp[selectedLanguage - 1].title = val;
                      setStoryTypeData(temp);
                    }}
                    inputType={
                      !isFocus?.[`title${selectedLanguage - 1}`] &&
                      !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    value={storyTypeData[selectedLanguage - 1].title}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
                    onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                  />
                </div>
              )}
            </div>
          )}
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                disabled={!validation.isValid}
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                type={!validation.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

StoryTypeFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
StoryTypeFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default StoryTypeFields;

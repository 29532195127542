/**
 * @prettier
 */
import React from 'react';
import CategoryItem from './CategoryItem';
import '../styles.scss';

const DashboardCategory = ({ label, subcategories }) => {
  return (
    <div className="dashboard-category">
      <span className="dashboard-category-title">{label}</span>
      <div className="dashboard-category-items">
        {subcategories.map((el) => (
          <CategoryItem key={el.id} title={el.title} image={el.image} navigateTo={el.navigateTo} />
        ))}
      </div>
    </div>
  );
};

export default DashboardCategory;

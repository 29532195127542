import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Drawer, Select, SingleSelect, Checkbox } from '../../../ui-kit';

import STRINGS from '../../../../localization';
import { validateObject } from '../../../utils/input-functions';
import RestauransService from '../../../../services/restaurants';
import { useSelector } from 'react-redux';
import Loader from '../loader';
import { MAIL_REGEX, PHONE_REGEX } from '../../../../constants/regExp';

const ManagersFields = (props) => {
  const roles = useSelector((store) => store.stuff.roles);
  const STATUS_OPTIONS = Object.entries(roles).map((el) => ({
    label: el[1].title,
    key: parseInt(el[0],10),
  }));

  const [passwordVisible, setPasswordVisible] = useState(true);

  const SCHEME = {
    id: () => true,
    roleId: (value) => value !== null && value !== undefined,
    firstName: (value) => value?.length > 0,
    lastName: (value) => value?.length > 0,
    email: (value) => MAIL_REGEX.test(value),
    phone: (value) => value?.length > 0 ? PHONE_REGEX.test(value) : false,
    password: (value) => value?.length >= 6,
  };

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [id, setId] = useState();

  const [step, setStep] = useState(1);

  const [firstName, setFirstName] = useState('');

  const [lastName, setLastName] = useState('');

  const [phone, setPhone] = useState('');

  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');

  const [roleId, setRoleId] = useState(STATUS_OPTIONS[1]?.key);

  const [stopListAccess, setStopListAccess] = useState(false);

  const [restaurantId, setRestaurantId] = useState();

  const [validation, setValidation] = useState({ isValid: false });

  const [isFocus, setIsFocus] = useState({
    firstname: true,
    lastname: true,
    password: true,
    phone: true,
    email: true,
    roleId: true,
  });

  const [loading, setLoading] = useState(false);

  const initialize = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setRoleId(STATUS_OPTIONS[0]?.key);
    setPassword('');
    setRestaurantId();
    setPhone('');
    setStopListAccess(false);
    setIsFocus({
      firstname: true,
      lastname: true,
      password: true,
      phone: true,
      email: true,
      roleId: true,
    });
    props.setRestaurantsQuery('');
  };

  const changeFocus = (field, focus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: focus }));
  };

  const getRestaurantName = async (restId) => {
    try {
      const data = await RestauransService.getRestaurantById(restId);
      if (data) {
        return data?.address?.addressData.find((e) => e?.languageId === langId)?.title;
      }
    } catch (error) {}
  };

  const handleSave = async () => {
    setLoading(true);
    setStep(1);
    await props.saveChanges({
      id,
      firstName,
      lastName,
      phone,
      email,
      roleId: parseInt(roleId,10) + 1,
      password,
      stopListAccess,
      restaurantId: parseInt(restaurantId,10),
    });

    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  useEffect(async () => {
    if (props.userData?.id && props.isOpen) {
      const manager = props.userData;
      setId(manager.id);
      setFirstName(manager.firstName);
      setLastName(manager.lastName);
      setPhone(manager.phone);
      setEmail(manager.email || '');
      setStopListAccess(manager.stopListAccess);
      setPassword('********');
      setRoleId(
        STATUS_OPTIONS.find(
          (el) => el.label === manager?.role
        )?.key
      );
      if (manager.restaurantId) {
        setRestaurantId(manager.restaurantId);
        props.setRestaurantsQuery(await getRestaurantName(manager.restaurantId));
      }
    }
  }, [props.userData, props.isOpen]);

  useEffect(() => {
    setValidation(validateObject({ firstName, lastName, password, phone, email, roleId }, SCHEME));
  }, [firstName, lastName, password, phone, email, roleId]);

  useEffect(() => {
    if (!props.isOpen && !props.fromView) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => {
        setStep(1);
        props.onClose();
      }}
    >
      <div className="modal">
        <Form onSubmit={handleSave} autocomplete="new-password">
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.managers.authData} *</span>
                    </div>
                    <Input
                      name="email"
                      className="container_fullwidth_one_first"
                      label={`${STRINGS.screens.users.email} *`}
                      inputType={
                        !isFocus.email && !validation?.data?.email?.isValid ? 'danger' : 'default'
                      }
                      value={email}
                      onChange={setEmail}
                      size="large"
                      maxLength={120}
                      onFocus={() => changeFocus('email', true)}
                      onBlur={() => changeFocus('email', false)}
                    />
                    {!props.userData?.id && (
                      <>
                        <Input
                        name="password"
                          autocomplete="new-password"
                          className="container_fullwidth_one"
                          label={`${STRINGS.screens.users.password} *`}
                          inputType={
                            !isFocus.password && !validation?.data?.password?.isValid
                              ? 'danger'
                              : 'default'
                          }
                          type={passwordVisible ? 'password' : 'visibility'}
                          value={password}
                          onChange={setPassword}
                          size="large"
                          maxLength={120}
                          rightIcon={
                            passwordVisible && password
                              ? 'crossed-visibility'
                              : password && 'visibility'
                          }
                          iconClick={() => setPasswordVisible((prev) => !prev)}
                          onFocus={() => changeFocus('password', true)}
                          onBlur={() => changeFocus('password', false)}
                          dataIconPointer={true}
                        />
                        {password.length > 0 && password.length < 6 && !isFocus.password && (
                          <div className="error">
                            <span>{STRINGS.screens.managers.qw}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.basic} *</span>
                    </div>
                    <Input
                    name="firstName"
                      className="container_fullwidth_half_first"
                      size="large"
                      maxLength={32}
                      defaultValue={props.userData?.firstName}
                      label={`${STRINGS.screens.users.name} *`}
                      inputType={
                        !isFocus.firstname && !validation?.data?.firstName?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      value={firstName}
                      onChange={setFirstName}
                      onFocus={() => changeFocus('firstname', true)}
                      onBlur={() => changeFocus('firstname', false)}
                    />
                    <Input
                    name="lastName"
                      className="container_fullwidth_half_first"
                      label={`${STRINGS.screens.users.lastName} *`}
                      inputType={
                        !isFocus.lastname && !validation?.data?.lastName?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      size="large"
                      maxLength={120}
                      value={lastName}
                      onChange={setLastName}
                      onFocus={() => changeFocus('lastname', true)}
                      onBlur={() => changeFocus('lastname', false)}
                    />
                    <Input
                    name="phone"
                      inputType={
                        !isFocus.phone && !validation?.data?.phone?.isValid ? 'danger' : 'default'
                      }
                      type="phone"
                      value={phone}
                      onChange={setPhone}
                      className="container_fullwidth_one"
                      label={`${STRINGS.screens.managers.phone} *`}
                      onBlur={() => changeFocus('phone', false)}
                      onFocus={() => changeFocus('phone', true)}
                    />
                    <Select
                    name="roleId"
                      className="container_fullwidth_one"
                      label={`${STRINGS.screens.managers.chooseRole} *`}
                      options={STATUS_OPTIONS}
                      size="large"
                      selectType={
                        !isFocus.roleId && !validation?.data?.roleId?.isValid ? 'danger' : 'default'
                      }
                      value={roleId}
                      onChange={setRoleId}
                      onFocus={() => changeFocus('roleId', true)}
                      onBlur={() => changeFocus('roleId', false)}
                    />
                  </div>
                </>
              )}
              {step === 2 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.users.additional}</span>
                  </div>
                  <SingleSelect
                    name="restaurantId"
                    inputType="default"
                    className="container_fullwidth_one_first"
                    label={STRINGS.screens.managers.chooseRestaurant}
                    data={props.restaurants}
                    result={restaurantId}
                    setResult={setRestaurantId}
                    dataQuery={props.restaurantsQuery}
                    setDataQuery={props.setRestaurantsQuery}
                  />
                  <Checkbox
                    checked={stopListAccess}
                    onChange={setStopListAccess}
                    label={STRINGS.screens.managers.qq}
                  />
                </div>
              )}
            </div>
          )}
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === 2 ? (
              <Button
                disabled={!validation.isValid }
                className="submit"
                label={STRINGS.screens.modal.save}
                size="large"
                type={
                  !validation.isValid
                    ? 'disabled'
                    : 'primary'
                }
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

ManagersFields.defaultProps = {
  roles: [],
  isOpen: false,
  close: () => {},
  userData: {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default ManagersFields;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AggregatorsView from './aggregators-view';
import STRINGS from '../../../localization';
import { init, listenNewOrders, listenCancelOrders } from '../../../services/socket';
import GlovoService from '../../../services/glovo';
import ChocofoodService from '../../../services/chocofood';
import WoltService from '../../../services/wolt';
import YandexService from '../../../services/yandex';
import AggregatorsThunks from '../../../store/aggregators/thunks';

const AggregatorsContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const { glovo, chocofood, wolt, yandex, loading } = useSelector((store) => store.aggregators);

  const [isLoading, setIsLoading] = useState(false);
  const [openedRestaurant, setOpenedRestaurant] = useState(true);
  const [openedGlovo, setOpenedGlovo] = useState(false);
  const [openedChoco, setOpenedChoco] = useState(false);
  const [openedWolt, setOpenedWolt] = useState(false);
  const [openedYandex, setOpenedYandex] = useState(false);

  useEffect(() => {
    const restaurantId = user.data.restaurantId;

    const socketConnect = init(restaurantId);

    listenNewOrders(socketConnect, restaurantId);
    listenCancelOrders(socketConnect, restaurantId);

    return () => socketConnect.disconnect();
  }, []);

  useEffect(() => {
    if (openedChoco && openedGlovo && openedWolt && openedYandex) {
      setOpenedRestaurant(true);
    } else {
      setOpenedRestaurant(false);
    }
  }, [openedChoco, openedGlovo, openedWolt, openedYandex]);

  const closeEverything = async () => {
    try {
      await closeGlovo();

      await closeChoco();

      setOpenedRestaurant(true);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const openEverything = async () => {
    try {
      await openGlovo();

      await openChoco();

      await openWolt();

      setOpenedRestaurant(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const closeGlovo = async () => {
    try {
      setIsLoading(true);

      const current = new Date();
      current.setDate(current.getDate() + 1);

      const dateOffset = toDateOffset(current);
      await GlovoService.changeClosing(user.data.restaurantId, { until: dateOffset });
      await dispatch(AggregatorsThunks.glovoClosing(user.data.restaurantId));
      setOpenedGlovo(true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const openGlovo = async () => {
    try {
      setIsLoading(true);

      await GlovoService.deleteClosing(user.data.restaurantId);
      await dispatch(AggregatorsThunks.glovoClosing(user.data.restaurantId));
      setOpenedGlovo(false);

      setOpenedRestaurant(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const closeChoco = async () => {
    try {
      setIsLoading(true);

      await ChocofoodService.putClosing({
        restaurantId: user.data.restaurantId,
        isActive: false,
      });
      await dispatch(AggregatorsThunks.chocofoodClosing(user.data.restaurantId));
      setOpenedChoco(true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const openChoco = async () => {
    try {
      setIsLoading(true);

      await ChocofoodService.putClosing({
        restaurantId: user.data.restaurantId,
        isActive: true,
      });
      await dispatch(AggregatorsThunks.chocofoodClosing(user.data.restaurantId));
      setOpenedChoco(false);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const closeWolt = async () => {
    try {
      setIsLoading(true);

      await WoltService.putClosing({
        restaurantId: user.data.restaurantId,
        isActive: 'OFFLINE',
      });
      await dispatch(AggregatorsThunks.woltClosing(user.data.restaurantId));
      setOpenedWolt(true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const openWolt = async () => {
    try {
      setIsLoading(true);

      await WoltService.putClosing({
        restaurantId: user.data.restaurantId,
        isActive: 'ONLINE',
      });
      await dispatch(AggregatorsThunks.woltClosing(user.data.restaurantId));
      setOpenedWolt(false);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const closeYandex = async () => {
    try {
      setIsLoading(true);

      await YandexService.setClosing({
        restaurantId: user.data.restaurantId,
        isActive: false,
      });
      await dispatch(AggregatorsThunks.yandexClosing(user.data.restaurantId));
      setOpenedYandex(true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const openYandex = async () => {
    try {
      setIsLoading(true);

      await YandexService.setClosing({
        restaurantId: user.data.restaurantId,
        isActive: true,
      });
      await dispatch(AggregatorsThunks.yandexClosing(user.data.restaurantId));
      setOpenedYandex(false);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const buttonsData = [
    {
      opened: glovo?.isOpen,
      title: STRINGS.components.aggregatorButton.aggGlovoName,
      callbackOpen: openGlovo,
      callbackClose: closeGlovo,
    },
    {
      opened: chocofood?.isOpen,
      title: STRINGS.components.aggregatorButton.aggChocoName,
      callbackOpen: openChoco,
      callbackClose: closeChoco,
    },
    {
      opened: wolt?.isOpen,
      title: STRINGS.components.aggregatorButton.aggWoltName,
      callbackOpen: openWolt,
      callbackClose: closeWolt,
    },
    {
      opened: yandex?.isOpen,
      title: STRINGS.components.aggregatorButton.aggYandexName,
      callbackOpen: openYandex,
      callbackClose: closeYandex,
    },
  ];

  const toDateOffset = (date) => {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      dif +
      pad(tzo / 60) +
      ':' +
      pad(tzo % 60)
    );
  };

  return (
    <AggregatorsView
      isLoading={loading}
      buttonsData={buttonsData}
      openedRestaurant={openedRestaurant}
      setOpenedRestaurant={setOpenedRestaurant}
      openEverything={openEverything}
      closeEverything={closeEverything}
    />
  );
};

export default AggregatorsContainer;

/**
 * @prettier
 */

import React from 'react';

import { Icon, Confirm, Table, Button, MainTabs } from '../../ui-kit';
import Skelet from './skelet';
import { getBeautyDateTimeString } from '../../utils/datetime';
import { Head } from '../../components';
import { orderCategories } from '../../../constants/categories';
import colors from '../../styles/colors';

import STRINGS from '../../../localization';
import { generatePrice } from '../../utils/generate-price';
import { translateStatusOrders } from '../../utils/translate-status';

import './styles.scss';

const OrdersView = (props) => {
  const renderClassNameSort = (type) => {
    let str = 'summary_table_heading_sort';
    if (type === props.sort.type) {
      str += ' summary_table_heading_sort_active';
    }
    return str;
  };

  return (
    <>
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.orders.delete}
        subtitleBold={props.selectedOrder?.id}
        buttons={[
          {
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteOrder(),
          },
          {
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
              setTimeout(() => props.setSelectedOrder({}), 100);
            },
          },
        ]}
      />
      <Head
        label={STRINGS.screens.orders.orders}
        button={false}
        breadCrumbs={
          props.history.location.state?.breadCrumbs || [
            {
              label: STRINGS.components.sidebar.manage,
              link: '/',
            },
            {
              label: STRINGS.screens.orders.orders,
              link: '/orders-overview/',
            },
            {
              label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurantId}`,
              link: `/orders-overview/${props.restaurantId}`,
            },
          ]
        }
      />
      <div>
        <div className="summary_table">
          <MainTabs
            active={props.selectedCategory?.key}
            data={orderCategories.map((item) => ({ label: item.label, key: item.key }))}
            onClick={(key) =>
              props.setSelectedCategory(orderCategories.find((el) => el.key === key))
            }
          />
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              loading={props.bottomLoading}
              head={() => (
                <tr className="summary_table_heading">
                  <th>{STRINGS.screens.orders.id}</th>
                  <th>{STRINGS.screens.orders?.aggregator || 'mobile'}</th>
                  <th>{STRINGS.screens.orders.sum}</th>
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>{STRINGS.screens.orders.receiptDate}</span>
                      <span
                        onClick={() => props.applySort('ReceiptDateTime')}
                        className={renderClassNameSort('ReceiptDateTime')}
                      >
                        <div
                          className={
                            props.sort.sort === 'desc' && props.sort.type === 'ReceiptDateTime'
                              ? 'summary_table_heading_arrow'
                              : 'summary_table_heading_arrow_active'
                          }
                        >
                          <Icon
                            width={16}
                            height={16}
                            color={colors.color_gray._3}
                            name="arrow-bottom"
                          />
                        </div>
                      </span>
                    </div>
                  </th>
                  <th>{STRINGS.screens.orders.status}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.orders?.data?.map((order) => (
                  <tr
                    key={order.orderId}
                    className="summary_table_body"
                    onClick={async (event) => {
                      event.stopPropagation();
                      props.history.push(`/orders/${order?.orderPatternId}`, {
                        breadCrumbs: [
                          {
                            label: STRINGS.components.sidebar.manage,
                            link: '/',
                          },
                          {
                            label: STRINGS.screens.orders.orders,
                            link: '/orders-overview/',
                          },
                          {
                            label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurantId}`,
                            link: `/orders-overview/${props.restaurantId}`,
                          },
                        ],
                        restaurantId: props.restaurantId,
                      });
                    }}
                  >
                    <td>{order.orderPatternId}</td>
                    <td>{props.translateAggregator(order.aggregator?.key)?.title || 'mobile'}</td>
                    <td>{generatePrice(order?.orderBody?.orderMetadata?.price)}</td>
                    <td>
                      {order?.orderBody?.orderMetadata?.receiptDateTime
                        ? getBeautyDateTimeString(
                            new Date(order?.orderBody?.orderMetadata?.receiptDateTime),
                          )
                        : STRINGS.screens.modal.noData}
                    </td>
                    <td>
                      <Button
                        className="outline"
                        size="large"
                        label={translateStatusOrders(order?.status)?.status}
                        type={translateStatusOrders(order?.status)?.button}
                        typeButton="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.changeStatus({
                            id: order?.orderPatternId,
                            status: order?.status,
                            orderOperator: order?.orderOperator,
                          });
                        }}
                      />
                    </td>
                    {/*<td className="summary_table_edit">*/}
                    {/*  <div className="summary_table_edit_wrapper">*/}
                    {/*    <Tooltip title={STRINGS.components.tooltips.delete}>*/}
                    {/*      <div*/}
                    {/*        className="summary_table_iconWrapper"*/}
                    {/*        onClick={(e) => {*/}
                    {/*          e.stopPropagation();*/}
                    {/*          props.setSelectedOrder({ id: order?.id });*/}
                    {/*          props.setShowConfirm('delete');*/}
                    {/*        }}*/}
                    {/*      >*/}
                    {/*        <Icon name="trash-outline" width={24} height={24} />*/}
                    {/*      </div>*/}
                    {/*    </Tooltip>*/}
                    {/*  </div>*/}
                    {/*</td>*/}
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default OrdersView;

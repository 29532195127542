/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

export default class MainService {
  static #API_ENDPOINTS = {
    languages: 'languages',
    file: 'files',
    backgroundColors: 'background-colors',
    tiers: 'price-tiers',
  };

  static API_SETTINGS = {
    size: 30,
    page: 1,
    langId: 1, //fix
    mainProductId: 1,
  };

  static async getFileById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.file}/${id}`);

      debug.success('File data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.blob();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.file}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteFileById(id) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.file}?id=${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('File data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.file}`, error);
      throw await error.response?.json();
    }
  }
  static async postFile(data) {
    const bodyFormData = new FormData();
    bodyFormData.append('file', data);
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.file}`, {
        body: bodyFormData,
      });

      debug.success('File data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.addDiscount}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getLanguages() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.languages}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Language data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.languages}`, error);
      throw await error.response?.json();
    }
  }
  static async getTiers() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.tiers}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Language data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.tiers}`, error);
      throw await error.response?.json();
    }
  }
  static async getBackgroundColors() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.backgroundColors}`, {
        headers: {
          'content-type': 'application/json',
        },
      });
      debug.success('Coupon data retrieval result', request);
      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }
      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.backgroundColors}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

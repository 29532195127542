/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { genders } from '../../../../constants/genders';
import { Button, Form, Input, Drawer, Select, InputDate } from '../../../ui-kit';

import STRINGS from '../../../../localization';
import { validateObject } from '../../../utils/input-functions';
import { getBeautyDateString } from '../../../utils/datetime';
import UsersService from '../../../../services/users';
import Loader from '../loader';
import isEmail from 'validator/es/lib/isEmail';
import { MAIL_REGEX, PHONE_REGEX } from '../../../../constants/regExp';

const UserFields = (props) => {
  const SCHEME = {
    phone: (value) => PHONE_REGEX.test(value),
    email: (value) => (value?.length ? MAIL_REGEX.test(value) : true),
  };
  const [step, setStep] = useState(1);

  const [firstName, setFirstName] = useState('');

  const [lastName, setLastName] = useState('');

  const [phone, setPhone] = useState('');

  const [email, setEmail] = useState('');

  const [carNumber, setCarNumber] = useState('');

  const [birthDate, setBirthDate] = useState('');

  const [gender, setGender] = useState();

  const [user, setUser] = useState();

  const [loading, setLoading] = useState(true);

  const [validation, setValidation] = useState({ isValid: false });

  const [isFocus, setIsFocus] = useState({
    phone: true,
    email: true,
  });

  const changeFocus = (field, focus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: focus }));
  };

  const handleSave = async () => {
    setLoading(true);
    const data = {};
    if (firstName !== user.firstName) {
      data.firstName = firstName;
    }

    if (lastName !== user.lastName) {
      data.lastName = lastName;
    }

    if (phone !== user.phone) {
      if (!phone.includes('+')) {
        data.phone = '+' + phone;
      } else {
        data.phone = phone;
      }
    }

    if (!!email?.length && email !== user.email) {
      data.email = email;
    }

    if (carNumber !== user.carNumber) {
      data.carNumber = carNumber;
    }

    if (birthDate !== user.birthDate) {
      data.birthDate = birthDate;
    }

    if (gender && gender !== user.gender) {
      data.gender = gender;
    }

    await props.saveChanges(data);

    setTimeout(() => {
      initialize();
      setLoading(false);
    }, 100);
  };

  const initialize = () => {
    setStep(1);
    setUser();
    setFirstName('');
    setLastName('');
    setEmail('');
    setBirthDate('');
    setGender();
    setPhone('');
  };

  const mapData = (data) => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setPhone(data.phone);
    setEmail(data.email || '');
    setBirthDate(data.birthDate);
    setGender(genders.find((el) => el.key === data?.gender)?.key);
    setCarNumber(data.carNumber);
  };

  const getUserData = async (id) => {
    setLoading(true);
    try {
      const resp = await UsersService.getUserById(id);
      setLoading(false);
      setUser(resp);
      mapData(resp);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.isOpen && props.editableUser >= 0) {
      getUserData(props.editableUser);
    }
  }, [props.editableUser, props.isOpen]);

  useEffect(() => {
    if (props.isOpen && props.loadedUser && Object.values(props.loadedUser).length) {
      setUser(props.loadedUser);
      mapData(props.loadedUser);
      setLoading(false);
    }
  }, [props.loadedUser, props.isOpen]);

  useEffect(() => {
    if (!props.isOpen) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  useEffect(() => {
    setValidation(validateObject({ phone, email }, SCHEME));
  }, [phone, email]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => props.onClose()}
    >
      <div className="modal">
        <Form onSubmit={handleSave}>
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.dataForEnter}</span>
                    </div>
                    <Input
                      className="container_fullwidth_one_first"
                      defaultValue={phone}
                      type="phone"
                      label={`${STRINGS.screens.managers.phone} *`}
                      onBlur={() => changeFocus('phone', false)}
                      onFocus={() => changeFocus('phone', true)}
                      inputType={
                        !isFocus.phone && !validation?.data?.phone?.isValid ? 'danger' : 'default'
                      }
                      value={phone}
                      onChange={setPhone}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.personalData}</span>
                    </div>
                    <Input
                      className="container_fullwidth_half_first"
                      label={STRINGS.screens.users.name}
                      value={firstName}
                      onChange={setFirstName}
                      inputType="default"
                      onFocus={() => changeFocus('firstname', true)}
                      onBlur={() => changeFocus('firstname', false)}
                    />
                    <Input
                      className="container_fullwidth_half_first"
                      label={STRINGS.screens.users.lastName}
                      value={lastName}
                      onChange={setLastName}
                      inputType="default"
                      onFocus={() => changeFocus('lastname', true)}
                      onBlur={() => changeFocus('lastname', false)}
                    />
                    <Select
                      className="container_fullwidth_one"
                      label={STRINGS.screens.users.sex}
                      options={genders}
                      size="large"
                      value={gender}
                      inputType="default"
                      onChange={setGender}
                      selectType="default"
                    />
                    <InputDate
                      size="large"
                      className="container_fullwidth_one"
                      maxDate={new Date()}
                      label={STRINGS.screens.modal.birthDate}
                      onChange={setBirthDate}
                      inputValue={birthDate && getBeautyDateString(new Date(birthDate))}
                      value={birthDate}
                      inputType="default"
                      onFocus={() => changeFocus('birthDate', true)}
                      onBlur={() => changeFocus('birthDate', false)}
                    />
                    <Input
                      className="container_fullwidth_one"
                      label={STRINGS.screens.users.email}
                      inputType={!isFocus.email && !isEmail(email) && email ? 'danger' : 'default'}
                      value={email}
                      onChange={setEmail}
                      onFocus={() => changeFocus('email', true)}
                      onBlur={() => changeFocus('email', false)}
                    />
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.socials}</span>
                    </div>
                    <Input
                      className="container_fullwidth_one_first"
                      label={STRINGS.screens.users.vk}
                      size="large"
                      inputType="default"
                    />
                    <Input
                      className="container_fullwidth_one"
                      label={STRINGS.screens.users.fb}
                      size="large"
                      inputType="default"
                    />
                    <Input
                      className="container_fullwidth_one"
                      label={STRINGS.screens.users.google}
                      size="large"
                      inputType="default"
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.additional}</span>
                    </div>
                    <Input
                      className="container_fullwidth_one_first"
                      label={STRINGS.screens.users.plate}
                      inputType="default"
                      size="large"
                      maxLength={120}
                      name="carNumber"
                      type="text"
                      defaultValue={carNumber}
                      onChange={setCarNumber}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === 2 ? (
              <Button
                disabled={!validation.isValid}
                className="submit"
                label={STRINGS.screens.modal.save}
                size="large"
                type={!validation.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

UserFields.propTypes = {
  roles: PropTypes.object,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
UserFields.defaultProps = {
  roles: [],
  isOpen: false,
  close: () => {},
  userData: {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default UserFields;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';
export default class RestauransService {
  static #API_ENDPOINTS = {
    restaurants: 'restaurants',
    advantages: 'advantages',
    menu: 'menu/products',
    packages: 'packages',
  };

  static API_SETTINGS = {
    size: 15,
    langId: 1,
  };

  static async getRestaurants(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.restaurants}?page=${props.page}&size=${props.size}&langId=${
          props.langId
        }&search=${props.search}${props.sort ? props.sort : ''}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Restaurant data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.restaurants}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getGlovoClosing(restaurantId) {
    try {
      const request = await api.get(`${URL}glovo/${restaurantId}/closing`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Glovo closing data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch {
      return null;
    }
  }

  static async getAggregatorClosing(props) {
    try {
      const request = await api.get(`${URL}glovo/${props.restaurantId}/closing`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Restaurant data retrieval result', request);

      return request.json();
    } catch (error) {
      return { until: undefined };
    }
  }
  static async getAdvantages(langId) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.advantages}?langId=${langId}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Language data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.advantages}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getRestaurantById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.restaurants}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Restaurant data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.restaurants}${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateRestaurant({ id, data }) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.restaurants}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Restaurant data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.restaurants}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.restaurants}/${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(status),
      });

      debug.success('Restaurant data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PUT request by path: ${URL}${this.#API_ENDPOINTS.restaurants}/${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async putAggregatorClosing(props) {
    try {
      const request = await api.put(`${URL}${props.aggregator}/${props.restaurantId}/closing`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ until: props.data }),
      });

      debug.success('Restaurant data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error('Failed with PUT request ', error);
      throw await error.response?.json();
    }
  }
  static async updateID(id, newId) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.restaurants}/${id}/id`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ id: newId }),
      });

      debug.success('Restaurant data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PUT request by path: ${URL}${this.#API_ENDPOINTS.restaurants}/${id}/${newId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addRestaurant({ data: data }) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.restaurants}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Restaurant data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.restaurants}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteRestaurant(id) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.restaurants}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Restaurants data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.restaurants}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getMenu(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menu}/${props.restaurantId}?langId=${
          this.API_SETTINGS.langId
        }&page=${props.page}&size=${this.API_SETTINGS.size}&existing=${props.existing}&categoryId=${
          props.categoryId
        }
        `,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Restaurant data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.restaurants}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getPackages() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.packages}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Package data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.packages}`, error);
      throw await error.response?.json();
    }
  }
}

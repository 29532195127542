/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import './styles.scss';

const TitleComponent = ({ compData = {} }) => {
  return (
    <div className="detail-wrapper">
      <span className="detail">
        {compData.name} х {compData.quantity}
      </span>
      <span className="detail">
        {compData.price || 0} {STRINGS.components.receipt.tg}
      </span>
    </div>
  );
};

export default TitleComponent;

/**
 * @prettier
 */
import { localizationSetCurrentLanguage } from './localization';
import { Localization } from '../../localization';

/**
 * Redux actions for localization.
 */
class LocalizationActions {
  static initial() {
    return async (dispatch) => {
      try {
        const currentAsyncStorageLanguage = await Localization.getLanguageFromAsyncStorage();
        if (currentAsyncStorageLanguage) {
          dispatch(LocalizationActions.setLanguage(currentAsyncStorageLanguage));
        }
      } catch (err) {}
    };
  }

  static setLanguage(language) {
    return async (dispatch) => {
      try {
        await Localization.setLanguage(language);
        dispatch(localizationSetCurrentLanguage(language));
      } catch (error) {}
    };
  }
}

export default LocalizationActions;

const en = {
  general: {
    days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    shortNameOfDays: {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
    },
    currency: {
      tg: 'KZT',
    },
  },
  utils: {
    today: 'Today',
    yesterday: 'Yesterday',
  },
  components: {
    header: {
      langSelection: 'Choose language',
      search: 'Search',
      _search: 'search',
      empty: 'Nothing was found',
    },
    select: {
      empty: 'Empty',
    },
    date: {
      date: 'DD.ММ.YYYY',
    },
    webkassaReceipt: {
      organisation: 'QSR TOO',
      identicationNumber: 'БИН 110640022629',
      shift: 'Смена ',
      checkOrderNumber: 'Порядковый номер чека №',
      checkNumber: 'Чек №',
      cashier: 'Кассир',
      vat: 'В т.ч. НДС 12%',
      fiscalNumber: 'Фискальный признак:',
      fiscalOperator: 'Оператор фискальных данных:',
      link: 'Для проверки чека зайдите на сайт',
      fiscalCheckTitle: 'ФИСКАЛЬНЫЙ ЧЕК',
      fiscalOperatorNumber: 'ИНК ОФД',
      cashboxRegNumber: 'Код ККМ КГД (РНМ):',
      cashboxNumber: 'ЗНМ',
      webkassaLink: 'WEBKASSA.KZ',
      currencyMultiplier: '1 Шт х',
      price: 'Стоимость',
      total: 'ИТОГО:',
      time: 'Время:',
      vatSeries: 'НДС Серия 60001',
      vatNumber: '№ 0078759',
    },
    sidebar: {
      storyTypes: 'Story types',
      stories: 'Stories',
      stoplists: 'Stop-lists',
      orders: 'Orders',
      pointsshop: 'Points store',
      cities: 'Cities',
      menu: 'Menu',
      goods: 'Products',
      ingredients: 'Ingredients',
      feedbacks: 'Reviews',
      manage: 'Management',
      managers: 'Staff',
      discounts: 'Promotions',
      dashboard: 'Dashboard',
      users: 'Users',
      roles: 'Roles editor',
      coupons: 'Coupons',
      punchcards: 'Punchcards',
      restaurants: 'Restaurants',
      statusRestaurants: 'Status Restaurants',
      arendators: 'Rent',
      landlords: 'Арендодатели',
      development: 'Development',
      storybook: 'Storybook',
      buttons: 'Buttons',
      cards: 'Cards',
      selectionControls: 'Selection Controls',
      textFields: 'Text Fields',
      datePicker: 'Date picker',
      dropdownInput: 'Dropdown input',
      rentalObjects: 'Объекты аренды',
      skelet: 'Skelet',
      toasts: 'Toasts',
      drawer: 'Drawer',
      icons: 'Icons',
      modals: 'Modals',
      dropzone: 'Dropzone',
      restaurant: 'Restaurant',
      aggregator: 'Агрегатор',
      currentOrders: 'Current orders',
      readyOrders: 'Ready orders',
      webkassaChecks: 'Webkassa checks',
      archive: 'Archive',
    },
    gallery: {
      look: 'Просмотреть',
      showMore: 'Показать еще',
      empty: 'Изображений пока нет',
    },
    toasts: {
      role: {
        success: 'Новая роль добавлена',
        successDelete: 'Роль успешно удалена',
      },
      error: 'Произошла ошибка, попробуйте снова',
      saveChanges: 'Изменения сохранены',
    },
    tooltips: {
      delete: 'Delete',
      edit: 'Edit',
      users: 'Users',
      open: 'Open',
    },
    aggregatorButton: {
      title: 'Aggregator',
      close: 'Close restaurant',
      open: 'Open restaurant',
      aggGlovoName: 'Glovo',
      aggChocoName: 'Chocofood',
      aggWoltName: 'Wolt',
      aggYandexName: 'Yandex',
      aggDelivioName: 'Delivio',
      closedInfo: 'restaurant closed',
      checkFiscalNumber: 'Фискальный признак:',
    },
    currentOrdersButton: {
      title: 'Current orders',
      newOrdersSort: 'New orders',
      oldOrdersSort: 'Old orders',
      oderNumber: 'Order №',
      ready: 'Ready',
      service: 'Сервис:',
      sum: 'Sum:',
      position: 'Positions:',
      orderTime: 'Order time:',
      orderCode: 'Order code:',
      externalCode: 'External code:',
      courier: 'The courier will arrive in',
      client: 'Client will take in',
      paymentType: 'Payment type:',
      paymentTypeNameCard: 'card',
      paymentTypeNameCash: 'cash',
    },
    archiveOrder: {
      status: 'Status:',
      message: 'Причина:',
    },
    statuses: {
      created: 'Created',
      failed: 'Failed',
      ready: 'Ready',
      picked: 'Picked',
      canceled: 'Cancelled',
      active: 'Active',
      disabled: 'Disabled',
    },
    modal: {
      number: 'Order number',
      checkNumber: 'Check number',
    },
    descriptionRestaurantModal: {
      numberOrder: 'Order №',
      detailOrderTitle: 'Order details',
      timeOrderTitle: 'Время заказа',
      commentsOrderTitle: 'Комментарии к заказу',
      contentOrderTitle: 'Состав заказа',
      additionOrderTitle: 'Дополнительно:',
      currency: 'Тг',
      total: 'Итого:',
      remove: 'Убрать:',
    },
    webkassaDescriptionRestModal: {
      checkNumber: 'Признак чека: ',
      checkIsNotFiscalized: 'Чек не фискализирован',
      detailCheckTitle: 'Check details',
      timeCheckTitle: 'Check time',
      contentCheckTitle: 'Composition of the check',
      additionCheckTitle: 'Additionally:',
      cellTitle: 'Sale',
      returnTitle: 'Return',
      cashboxNumber: 'Cashbo number:',
      total: 'Total:',
      vat: 'Incl. VAT 12%:',
      currency: 'Tg',
      checkId: 'Check id in the system:',
    },
    receipt: {
      numberOrder: 'Номер заказа',
      dateOrder: 'Дата заказа:',
      timeOrder: 'Время заказа:',
      position: 'Позиция',
      count: 'Кол-во',
      price: 'Цена',
      positionCount: 'Количество позиций',
      sumOrder: 'Сумма заказа',
      total: 'Итого',
      tg: 'тг',
      courierCode: 'Кур. код',
      orderCode: 'Код заказа',
    },
    orderComponent: {
      data: 'Дата',
      time: 'Время',
    },
    wkDescriptionDefaultRestaurantModal: {
      title: 'Выберите чек',
      description: 'Нажмите на чек, чтобы посмотреть его детальную информацию.',
    },
    descriptionDefaultRestaurantModal: {
      title: 'Выберите заказ',
      description: 'Нажмите на заказ, чтобы посмотреть его детальную информацию, комментарии и состав.',
    },
    webkassaChecksButton: {
      title: 'Webkassa checks',
      newOrdersSort: 'New checks',
      oldOrdersSort: 'OldChecks',
      checkNumber: 'Check №',
      success: 'Printed',
      failed: 'Waiting for resubmission',
      orderTime: 'Check time:',
      total: 'Total:',
      status: 'Status:',
      operationType: 'Operation type:',
      aggregator: 'Aggregator:',
    },
    readyOrdersButton: {
      title: 'Готовые к выдаче',
      newOrdersSort: 'Новые заказы',
      oldOrdersSort: 'Старые заказы',
      oderNumber: 'Заказ №',
      ready: 'Готов',
      service: 'Сервис:',
      sum: 'Сумма:',
      position: 'Позиций:',
      orderTime: 'Время заказа:',
    },
    archiveButton: {
      title: 'Архив выданных заказов',
      filtersTitle: 'Фильтры',
    },
  },
  screens: {
    yandexDiscounts: {
      id: 'Идентификатор',
      position: 'Позиция',
      addYandexDiscounts: 'Добавить яндекс скидку',
      typeProduct: 'Тип продукта',
      editYandexDiscounts: 'Редатикровать яндекс скидку',
      type: 'Тип',
      name: 'Название',
      delete: 'Вы действительно хотите удалить ядекс акцию',
      discount: 'Скидка',
      status: 'Cтатус',
      yandexDiscounts: 'Яндекс скидки',
      discountName: 'Название',
    },
    storybook: {
      switch: 'Переключатели',
      switchDescription: 'Включает или выключает состояние отдельного параметра.',
      radioBtn: 'Радио-кнопки',
      radioBtnDescription: 'Радиокнопки позволяют пользователю выбрать один вариант из набора.',
      checkbox: 'Флажки',
      checkboxDescription: 'Флажки позволяют пользователю выбрать один или несколько элементов из набора.',
      female: 'Женский',
      male: 'Мужской',
      other: 'Другое',
      disabled: '(Отключенный вариант)',
      apple: 'Яблоко',
      kiwi: 'Киви',
      banana: 'Банан',
      containedButtons: 'Кнопки с заливкой',
      containedButtonsDescription: 'Кнопки с заливкой имеют залитое пространство внутри и содержат текст. Для использования достаточно использовать свойство type.',
      outlinedButtons: 'Outlined кнопки',
      outlinedButtonsDescription: 'Outlined кнопки не имеют заливки и обладают выделенными границами.',
      buttonSizes: 'Размеры кнопок',
      buttonSizesDescription: 'Для изменеия размеров кнопки достаточно передать свойсто size.',
      buttonIconTitle: 'Кнопки содержащие иконки',
      buttonIcon:'В компонент Button есть возможность внедрить иконку. Для внедрения иконки достаточно обязательно передать два свойства: ',
      _buttonIcon: 'В качестве дополнительных свойств, есть возможность передачи свойств ',
      component: 'В компонент',
      possible: 'есть возможность передачи любой существующей иконки из компонета',
      property: 'Для этого достаточно передать свойство',
      _property: 'В качестве дополнительных свойств, есть возможность передачи свойств',
      textFields: 'Поля ввода',
      textFieldsDescription: 'Текстовы поля предназначены для записи строк в стейт.',
      _component: 'Компонент',
      _textFieldsDescription: 'предназначен для ввода большого количества символов. Для изменения внешнего вида требуется передать свойство ',
      textFieldsSizes: 'Размеры текстовых полей',
      textFieldsSizesDescription: 'Текстовы поля могут иметь различные размеры. Для изменения размеров требуется передать свойство ',
      textArea: 'TextArea',
      date: 'Поле выбора даты',
      dateDescription: ' предназначен для выбора даты.',
      dateSize: 'Размеры компонета поля выбора даты',
      _dateSize: 'Поля выбора даты могут иметь различные размеры. Для изменения размеров требуется передать свойство ',
      nativeComponent: 'Нативный компонент',
      dropdown: 'Компонент dropdown',
      dropdownSizes: 'Размеры компонента dropdown',
      _dropdownSizes: 'может иметь различные размеры. Для изменения размеров требуется передать свойство',
    },
    authorization: {
      email: 'E-mail',
      auth: 'Авторизация',
      login: 'Логин',
      password: 'Пароль',
      go: 'Войти',
      fill: 'Заполните всю необходимую информацию ниже, чтобы войти в систему.',
      welcome: 'Welcome to Burger King!',
      solution: 'Лучшее решение для вашего бизнеса.',
    },
    users: {
      id: 'Id',
      additional: 'Дополнительная информация',
      socials: 'Социальные сети',
      vk: 'Вконтакте',
      fb: 'Facebook',
      google: 'Google',
      contact: 'Контактные данные',
      personalData: 'Личные данные',
      sex: 'Пол',
      plate: 'Номер автомобиля',
      status: 'Статус',
      invite: 'Код приглашения',
      edit: 'Редактировать пользователя',
      title: 'Пользователи',
      addUser: 'Добавить пользователя',
      name: 'Имя',
      lastName: 'Фамилия',
      email: 'Email',
      street: 'Адрес',
      number: 'Номер телефона',
      password: 'Пароль',
      role: 'Роль',
      save: 'Сохранить',
      question: 'Вы действительно хотите удалить пользователя',
    },
    stoplists: {
      restaurant: 'Название ресторана',
      id: 'Id',
      user: 'Создатель',
      warning: 'Предупреждение',
      title: 'Название',
      subtitle: 'Подзаголовок',
      description: 'Описание',
      stoplists: 'Стоп-листы',
      addModificatorStoplist: 'Добавить товар в стоп-лист',
      editStoplist: 'Редактировать стоп-лист',
      primaryPhoto: 'Заглавное фото',
      question: 'Вы действительно хотите удалить стоп-плист',
      date: 'Дата создания',
    },
    managers: {
      managers: 'Сотрудники',
      addManager: 'Добавить сотрудника',
      editManager: 'Редактировать сотрудника',
    },
    discounts: {
      status: 'Статус',
      id: 'Id',
      warning: 'Предупреждение',
      title: 'Название',
      title2: 'Название 2',
      subtitle: 'Подзаголовок',
      description: 'Описание',
      discounts: 'Акции',
      addDiscount: 'Добавить акцию',
      editDiscount: 'Редактировать акцию',
      primaryPhoto: 'Заглавное фото',
      question: 'Вы действительно хотите удалить акцию',
      startDate: 'Начало',
      expirationDate: 'Окончание',
    },
    points: {
      points: 'Магазин поинтов',
      id: 'Id',
      amount: 'Сумма',
      addProducts: 'Добавить продукты',
    },
    goods: {
      category: 'Категория',
      energyValueKj: 'Энергетическая ценность (кДж)',
      editCombo: 'Редактировать комбо',
      addCombo: 'Добавить комбо',
      combo: 'Комбо',
      description: 'Описание',
      energy: {
        saturatedFats: 'Насыщенные жиры (г)',
        fiber: 'Клетчатка (г)',
        sugar: 'Сахар (г)',
        salt: 'Соль (г)',
        proteins: 'Белки (г)',
        fats: 'Жиры (г)',
        carbs: 'Углеводы (г)',
      },
      prices: {
        price: 'Цена',
        default: 'Цена в зале',
        delivery: 'Цена на доставке',
      },
      energyValueKcal: 'Энергетическая ценность (ккал)',
      weight: 'Вес (г)',
      goods: 'Товары',
      good: 'Товар',
      picture: 'Картинка',
      title: 'Название',
      price: 'Цена',
      points: 'Поинты',
      id: 'ID',
      status: 'Статус',
      addGood: 'Добавить товар',
      editGood: 'Редактировать товар',
      deleteQuestion: 'Вы действительно хотите удалить товар',
      chooseIngredients: 'Выберите ингредиенты',
    },
    menu: {
      menu: 'Меню ресторана',
      addMenu: 'Добавить меню',
    },
    stories: {
      storyTypeId: 'Тип истории',
      title: 'Заголовок',
      subtitle: 'Подзаголовок',
      buttonText: 'Текст на кнопке',
      startDateTime:'Начало',
      endDateTime: 'Окончание',
      link: 'Ссылка',
      isTabBarDark: 'Темный интерфейс',
      stories: 'Истории',
      addStory: 'Добавить историю',
      editStory: 'Изменить историю',
      deleteQuestion: 'Вы действительно хотите удалить выбранную историю',
    },
    aggregators: {
      aggregators: 'Агрегаторы',
    },
    cities: {
      status: 'статус',
      id: 'Идентификатор',
      name: 'Название',
      geo: 'Координаты',
      editCity: 'Изменить город',
      addCity: 'Добавить город',
      cities: 'Города',
      city: 'Город',
      save: 'Сохранить',
      deleteQuestion: 'Вы действительно хотите удалить выбранный город',
      restaurants: 'Рестораны',
    },
    ingredients: {
      description: 'Описание',
      goods: 'Товары',
      ingredients: 'Ингредиенты',
      picture: 'Картинка',
      title: 'Название',
      price: 'Цена',
      energy: {
        proteins: 'Белки',
        fats: 'Жиры',
        carbs: 'углеводы',
      },
      id: 'ID',
      status: 'Статус',
      addIngredient: 'Добавить ингредиент',
      editIngredient: 'Редактировать ингредиент',
      deleteQuestion: 'Вы действительно хотите удалить ингредиент',
      chooseGoods: 'Выберите товары',
    },
    coupons: {
      id: 'Id',
      type: 'Тип',
      title: 'Название',
      code: 'Код',
      price: 'Цена',
      discount: 'Скидка, %',
      coupons: 'Купоны',
      addCoupon: 'Добавить купон',
      editCoupon: 'Редактировать купон',
      primaryPhoto: 'Заглавное фото',
      question: 'Вы действительно хотите удалить купон?',
      expirationDate: 'Окончание',
      menuChip: 'Выберите меню',
      color: 'Цвет фона',
    },
    punchcards: {
      title: 'Название',
      description: 'Описание',
      warning: 'Предупреждение',
      points: 'Количество поинтов',
      numberOfPurchases: 'Число покупок',
      punchcards: 'Панчкарты',
      addPunchcard: 'Добавить панч-карту',
      editPunchcard: 'Редактировать панч-карту',
      products: 'Список товаров',
      question: 'Вы действительно хотите удалить п-к',
      status: 'Статус',
      date: 'Дата создания',
    },
    roles: {
      title: 'Редактирование ролей',
      addRole: 'Добавить новую роль',
      role: 'Роль',
      accesses: 'Доступы',
      roleName: 'Название роли',
      modal: {
        confirmAction: 'Подтвердите действие',
        question: 'Вы действительно хотите удалить роль',
        delete: 'Удалить',
        leave: 'Оставить',
        save: 'Сохранить',
      },
    },
    restaurants: {
      id: 'Id',
      statusLabel: 'Статус',
      status: 'Статус',
      yandexDiscounts: 'Яндекс скидки',
      description: 'Описание',
      dropzone: 'Для загрузки изображениий перетяните в область или нажмите на любою область зоны',
      photos: 'Фотографии:',
      title: 'Рестораны',
      statusTitle: 'Status aggregators',
      name: 'Название',
      city: 'Город',
      address: 'Адрес',
      phone: 'Номер телефона',
      workingHours: 'Время работы',
      deliveryWay: 'Способы доставки',
      addRestaurant: 'Добавить ресторан',
      editRestaurant: 'Редактировать ресторан',
      leave: 'Вы действительно хотите удалить ресторан',
      statusGlovo: 'Статус Glovo',
      statusChoko: 'Статус Chokofood',
      statusYandex: 'Статус Yandex',
      statusWolt: 'Статус Wolt',
      lastUpdate: 'Последнее обновление',
    },
    reviews: {
      id: 'Id',
      restaurant: 'Ресторан',
      phoneNumber: 'Телефон',
      email:'e-mail',
      name: 'Имя',
      feedback: 'Отзыв',
      publicationDate: 'Дата',
      feedbacks: 'Отзывы',
      view: 'Просмотреть',
      delete: 'Удалить',
    },
    modal: {
      noTitle: 'Без названия',
      personalData: 'Личные данные',
      contact: 'Контактные данные',
      email: 'Электронная почта',
      socials: 'Социальные сети',
      additional: 'Дополнительная информация',
      basic: 'Общая информация',
      langs: 'Языки',
      couponColor: 'Цвет фона купона',
      purchaseNumber: 'Число покупок',
      menu: 'Выберите меню',
      advantages: 'Преимущества',
      workingTime: 'Время работы',
      picture: 'Изображение',
      energy: 'Энергетическая ценность',
      macro: 'Макронутриенты',
      addEnergy: 'Дополнительные элементы',
      allergen: 'Аллергены',
      ingredients: 'Ингредиенты',
      category: 'Категория',
      products: 'Товары',
    },
    orders: {
      orders: 'Заказы',
      order: 'Заказ',
      cancel: 'Отменить заказ',
      orderInfo: 'Информация о заказе',
      clientInfo: 'Данные клиента',
      details: 'Детали заказа',
      comment: 'Комментарий к заказу',
      includes: 'Cостав заказа',
    },
  },
};

export default en;

/**
 * @prettier
 */

import React from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import { Paper, Status } from '../../ui-kit';
import DiscountFields from '../../components/modals/discount-fields';
import '../styles.scss';
import { generatePrice } from '../../utils/generate-price';
import SkeletRestaurants from './skelet';
import { URL } from '../../../services/config';
import NOPHOTO from '../../../assets/nophoto.png';
import { getBeautyDateString } from '../../utils/datetime';

const View = (props) => {
  const { discount } = props;
  return (
    <>
      <>
        <DiscountFields
          langId={props.langId}
          getProductsAutocomplete={props.getProductsAutocomplete}
          onClose={() => props.setShowModal('')}
          isOpen={props.showModal === 'edit'}
          title={STRINGS.screens.discounts.editDiscount} //edit
          loadedDiscount={props.discount}
          getDiscountById={props.getDiscountById}
          getFileById={props.getFileById}
          saveChanges={props.saveChanges}
          loading={props.loading}
          setLoading={props.setLoading}
        />
        <Head
          label={props.history?.location?.state?.title}
          button={{
            size: 'medium',
            icon: false,
            label: STRINGS.screens.discounts.editDiscount,
            onClick: () => props.setShowModal('edit'),
          }}
          breadCrumbs={[
            {
              label: STRINGS.components.sidebar.manage,
              link: '/',
            },
            {
              label: STRINGS.components.sidebar.discounts,
              link: '/promotions',
            },
            {
              label: props.discountId,
            },
          ]}
          status={props.history?.location?.state?.status}
        />
        {props.skeletVisible ? (
          <SkeletRestaurants />
        ) : (
          <Paper>
            <div className="promotion-view-container">
              <div>
                <div className="block">
                  <div className="picture">
                    {discount?.promotionData?.find((el) => el.languageId === props.langId)
                      .fileId ? (
                      <img
                        className="image"
                        src={`${URL}files/${
                          discount?.promotionData?.find((el) => el.languageId === props.langId)
                            .fileId
                        }`}
                      />
                    ) : (
                      <img src={NOPHOTO} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.general}</span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.discounts.start}:</span>
                    <span>
                      {getBeautyDateString(new Date(discount?.startDate)) ||
                        STRINGS.screens.modal.noData}
                    </span>
                  </div>

                  <div className="row">
                    <span>{STRINGS.screens.discounts.expiration}:</span>
                    <span>
                      {getBeautyDateString(new Date(discount?.expirationDate)) ||
                        STRINGS.screens.modal.noData}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.discounts.subtitle}:</span>
                    <span>
                      {discount?.promotionData?.find((el) => el.languageId === props.langId)
                        .subtitle || STRINGS.screens.modal.noData}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.discounts.warning}:</span>
                    <span>
                      {discount?.promotionData?.find((el) => el.languageId === props.langId)
                        .warning || STRINGS.screens.modal.noData}
                    </span>
                  </div>
                </div>
                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.discounts.promotionDescription}</span>
                  </div>
                  <div className="data">
                    <span>
                      {discount?.promotionData?.find((el) => el.languageId === props.langId)
                        .description || STRINGS.screens.modal.noData}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        )}
      </>
    </>
  );
};
export default View;

/**
 * @prettier
 * @perfect - PS
 */

import ky from 'ky';

import { debug } from '../';
import AuthService from '../auth';

/**
 * This one makes api requests on entire application
 */
const api = ky.extend({
  retry: {
    limit: 3,
    methods: ['get', 'post', 'put', 'patch', 'delete'],
    statusCodes: [408, 401, 413, 403, 429, 500, 502, 503, 504],
  },
  timeout: 2500000,
  hooks: {
    beforeRetry: [
      async ({ request, error }) => {
        if (error.message === 'Unauthorized') {
          try {
            const resp = await AuthService.requestUserRefreshToken();
            await AuthService.setAuthToken({
              token: resp?.accessToken,
              refreshToken: resp?.refreshToken,
            });

            request.headers.set('Authorization', `Bearer ${resp?.accessToken}`);
          } catch (error) {
            debug.error('Failed to update token beforeRetry: ', error);
            throw error;
          }
        }
      },
    ],
    beforeRequest: [
      async (request) => {
        try {
          const token = await AuthService.getAuthToken();

          if (token) {
            request.headers.set('Authorization', `Bearer ${token}`);
          }
        } catch (error) {
          debug.error('Failed to set auth token beforeRequest', error);
        }
      },
    ],
  },
});

export default api;

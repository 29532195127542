/**
 * @prettier
 */

/**
 * Function for validate input object based on a validation scheme
 * @param {Object} validateObj - Object with input properties for validation
 * @param {Object<Function>} validateScheme - Validation scheme
 */
export const validateObject = (validateObj, validateScheme) => {
  return Object?.entries(validateObj)?.reduce(
    (result, [key, value]) => {
      const isValid = validateScheme[key]?.(value);
      result.isValid = Boolean(result?.isValid * isValid); // Discrete multiplication
      result.data = {
        ...result?.data,
        [key]: { value, isValid },
      };
      return result;
    },
    { isValid: true, data: {} },
  );
};

/**
 * Function for amount number type replacing
 * @param {String} text - A string
 */
export const replaceAmountValue = (text) => {
  return text.match(/^0+$/)
    ? '0'
    : text[0] && text[0].match(/\.|,/)
    ? '0.'
    : text.match(/^0\d+$/)
    ? text.substring(1)
    : text
        .replace(',', '.')
        .replace(/[^0-9.]/g, '')
        .replace(/^([^.]*\.)(.*)$/, (a, b, c) => b + c.replace(/\./g, '').substring(0, 2));
};

/**
 * @prettier
 */

import socketIOClient from 'socket.io-client';

import { URL } from '../config';
import Toast from '../../restaurant/ui-kit/toasts';
import audio from '../../assets/audio/sound.mp3';

const audioSound = new Audio(audio);

export const init = (restaurantId) => {
  const socket = socketIOClient(URL, {
    query: {
      restaurantId: restaurantId,
      glovo: true,
    },
    transports: ['websocket'],
  });

  socket.on('connect', () => {});

  return socket;
};

export const listenNewWebkassaChecks = (socket, restaurantId) => {
  socket.on(`webkassa-${restaurantId}`, (data) => {
    audioSound.play();

    Toast.info(`Получен новый чек Webkassa №${data.id}`);
  });
};

export const listenWoltChangedStatus = (
  socket,
  setNewOrders = () => {},
  restaurantId,
  status,
  setOpen,
) => {
  socket.on(`order-status-${restaurantId}`, (data) => {
    if (data.status === status) {
      Toast.info(`Статус заказа изменен №${data.orderId}`);
      setNewOrders((old) => old.filter((el) => el.id !== data.orderId));
      setOpen(false);
    }
  });
};

export const listenNewWebkassaChecksWithAdd = (
  socket,
  setNewWebkassaChecks = () => {},
  sort = true,
  restaurantId,
) => {
  socket.on(`webkassa-${restaurantId}`, (data) => {
    audioSound.play();

    Toast.info(`Получен новый чек Webkassa №${data.id}`);
    if (sort) {
      setNewWebkassaChecks((old) => [...old, backNewWebkassaCheck(data)]);
    } else {
      setNewWebkassaChecks((old) => [backNewWebkassaCheck(data), ...old]);
    }
  });
};

export const listenNewOrders = (socket, restaurantId) => {
  socket.on(`order-${restaurantId}`, (data) => {
    audioSound.play();

    Toast.info(`Получен новый заказ №${data.id}`);
  });
};

export const listenNewOrdersWithAdd = (
  socket,
  setNewOrders = () => {},
  sort = true,
  restaurantId,
) => {
  socket.on(`order-${restaurantId}`, (data) => {
    audioSound.play();

    Toast.info(`Получен новый заказ №${data?.orderPatternId}`);
    if (sort) {
      setNewOrders((old) => [...old, backNewOrder(data)]);
    } else {
      setNewOrders((old) => [backNewOrder(data), ...old]);
    }
  });
};

export const listenCancelOrders = (socket, restaurantId) => {
  socket.on(`cancel-${restaurantId}`, (data) => {
    audioSound.play();

    Toast.error(`Заказ отменен №${data.orderId}`);
  });
};

export const listenCancelOrdersWithRemove = (socket, setNewOrders = () => {}, restaurantId) => {
  socket.on(`cancel-${restaurantId}`, (data) => {
    audioSound.play();

    Toast.error(`Заказ отменен №${data.orderId}`);

    setNewOrders((old) => old.filter((el) => el.id !== data.orderId));

    return data;
  });
};

export const listenCancelOrdersWithAdding = (socket, setNewOrders = () => {}, restaurantId) => {
  socket.on(`cancel-${restaurantId}`, (data) => {
    audioSound.play();

    Toast.error(`Заказ отменен №${data.orderId}`);

    // const newOrder = {
    //   aggregator: { key: 'glovo' },
    //   id: data.id,
    //   orderBody: data,
    //   receiptDateTime: data.orderMetadata.receiptDateTime,
    // };

    // setNewOrders((old) => [...old, newOrder]);

    return data;
  });
};

const backNewOrder = (data) => {
  return {
    aggregator: { key: data.orderMetadata.aggregatorKey },
    id: data.id,
    orderBody: data,
    receiptDateTime: data.orderMetadata.receiptDateTime,
    orderId: data.orderMetadata.aggregatorOrderId,
    orderPatternId: data.orderPatternId,
  };
};

const backNewWebkassaCheck = (data) => {
  return {
    id: data.id,
    webkassaCheckNumber: data.webkassaCheckNumber || '-',
    isSuccess: data.isSuccess,
    receiptDateTime: data.receiptDateTime,
    externalCheckNumber: data.externalCheckNumber,
    orderRequest: data.orderRequest,
    printData: data.printData,
    operationType: data.operationType,
    deliveryOrder: data.deliveryOrder,
    aggregatorId: data.aggregatorId,
    isPossibleToReturn: data.isPossibleToReturn,
  };
};

/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';
import STRINGS from '../../../localization';

import { Skeleton } from '../../ui-kit';
import { getBeautyDateTimeString } from '../../utils/datetime';

import GlovoService from '../../../services/glovo';

const GlovoClosing = (props) => {
  const [closing, setClosing] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getClosing = async () => {
      setLoading(true);
      try {
        const response = await GlovoService.getClosing(props.restaurantId);
        setClosing(response.until);
      } catch {
        setClosing(undefined);
      }
      setLoading(false);
    };
    getClosing();
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton width="fit-content">
          <span>******************</span>
        </Skeleton>
      ) : (
        <>
          {closing !== undefined
            ? closing === ''
              ? STRINGS.screens.restaurants.qr
              : getBeautyDateTimeString(new Date(closing))
            : STRINGS.screens.modal.noData}
        </>
      )}
    </>
  );
};

export default GlovoClosing;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import ManagersFields from '../../components/modals/managers-fields';
import { Paper } from '../../ui-kit';
import '../styles.scss';
import Skelet from './skelet';
import { URL } from '../../../services/config';
import PERSON_PLACEHOLDER from '../../../assets/person-placeholder.png';
import { translateRole } from '../../utils/translate-role';

const View = (props) => {
  const { manager } = props;

  const STATUS_OPTIONS = Object.values(props.roles).map((el, key) => ({
    label: el.title,
    key: key,
  }));

  return (
    <>
      <>
        <ManagersFields
          fromView={true}
          restaurants={props.restaurants}
          restaurantsQuery={props.restaurantsQuery}
          setRestaurantsQuery={props.setRestaurantsQuery}
          roles={props.roles}
          onClose={() => props.setShowModal('')}
          isOpen={props.showModal === 'edit'}
          title={STRINGS.screens.managers.editManager}
          userData={props.manager}
          saveChanges={props.saveChanges}
        />
        {props.skeletVisible ? (
          <Skelet />
        ) : (
          <>
            <Head
              label={`${props.manager.firstName} ${props.manager.lastName}`}
              status={null}
              button={{
                size: 'medium',
                icon: false,
                label: STRINGS.screens.managers.editManager,
                onClick: () => props.setShowModal('edit'),
              }}
              breadCrumbs={[
                {
                  label: STRINGS.components.sidebar.manage,
                  link: '/',
                },
                {
                  label: STRINGS.screens.managers.managers,
                  link: '/managers',
                },
                {
                  label: props.id,
                },
              ]}
            />
            <Paper>
              <div className="manager-view-container">
                <div>
                  <div className="block">
                    <div className="picture">
                      {manager?.fileId ? (
                        <img className="image" src={`${URL}files/${manager?.fileId}`} />
                      ) : (
                        <img className="image" src={PERSON_PLACEHOLDER} />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="block">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.general}</span>
                    </div>
                    <div className="row">
                      <span>{STRINGS.screens.goods.id}:</span>
                      <span>{props?.id}</span>
                    </div>
                    <div className="row">
                      <span>{STRINGS.screens.users.number}:</span>
                      <span>{props.manager.phone}</span>
                    </div>
                    <div className="row">
                      <span>{STRINGS.screens.users.email}:</span>
                      <span>{props.manager.email}</span>
                    </div>
                    <div className="row">
                      <span>{STRINGS.screens.users.role}:</span>
                      <span>
                        {translateRole(
                          props.manager.role ||
                            STATUS_OPTIONS.find((el) => el.key === props.manager?.roleId - 1)
                              ?.label,
                        )}
                      </span>
                    </div>
                    {props.restaurant && (
                      <div className="row">
                        <span>{STRINGS.screens.users.restaurant}:</span>
                        <span>{`${props.restaurant?.address?.addressData[0].title}, ${props.restaurant?.address?.city?.cityData[0].name}`}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Paper>
          </>
        )}
      </>
    </>
  );
};
export default View;

/**
 * @prettier
 */

const initialState = {
  cancelledOrders: [],
};

const SET_ORDER = 'SET_ORDER';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER:
      return {
        cancelledOrders: [...state.cancelledOrders, action.payload],
      };
    default:
      return state;
  }
};

export const setOrder = (payload) => {
  return {
    type: SET_ORDER,
    payload,
  };
};

export default reducer;

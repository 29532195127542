/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';

import OrderView from './view';
import { useHistory, useParams } from 'react-router-dom';
import OrdersService from '../../../services/orders';
import { Toast } from '../../ui-kit';
import { translate } from '../../utils/translate-aggregator';
import STRINGS from '../../../localization';

const OrderContainer = () => {
  const history = useHistory();
  const [selectedOrder, setSelectedOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState('');
  const [showModal, setShowModal] = useState('');
  let { id } = useParams();

  const getOrder = async () => {
    setLoading(true);
    try {
      const resp = await OrdersService.getOrder(id);

      setSelectedOrder({ ...resp.data[0] });
      setLoading(false);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const cancelOrder = async () => {
    if (selectedOrder.status.includes('created') || selectedOrder.status.includes('ready')) {
      try {
        if (selectedOrder?.orderOperator === 'MOBILE_APP') {
          await OrdersService.cancelMobileOrder(selectedOrder.orderPatternId);
        } else {
          await OrdersService.cancel({ id: selectedOrder.orderPatternId });
        }

        Toast.success(STRINGS.notify.status);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      getOrder();
    }
  }, [id]);

  return (
    <OrderView
      history={history}
      isOpen={true}
      selectedOrder={selectedOrder}
      cancelOrder={cancelOrder}
      showModal={showModal}
      setShowModal={setShowModal}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      translateAggregator={translate}
      loading={loading}
    />
  );
};

export default OrderContainer;

/**
 * @prettier
 */

import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Form } from '../../../../main/ui-kit';
import { Icon } from '../../../../ui-kit';
import { CustomButton } from '../../../ui-kit';
import WebkassaReceipt from '../../webkassa-receipt';
import STRINGS from '../../../../localization';
import { setWebkassaCheck } from '../../../../store/webkassa-checks/webkassa-checks';
import { WEBKASSA_OPERATION_TYPES } from '../../../../constants/webkassa-check-statuses';

import colors from '../../../styles/colors';

import './styles.scss';
import WebkassaItem from '../../../ui-kit/webkassa-item';

const WebkassaDescription = (props) => {
  const componentRef = useRef();
  const dispatch = useDispatch();

  const webkassaChecks = useSelector((store) => store.webkassaChecks.webkassaChecks);

  const [openReceipt, setOpenReceipt] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const elData = JSON.parse(props.check.orderRequest);
  const totalPrice = elData.Payments?.reduce((acc, payment) => acc + payment.Sum, 0);
  const totalVat = elData.Positions?.reduce((acc, item) => acc + item.Tax, 0);
  const printData = props.check?.printData ? JSON.parse(props.check.printData) : null;

  useEffect(() => {
    const el = webkassaChecks.find((elem) => elem === props.check.id);

    if (el) {
      setIsCanceled(true);
    }
  }, []);

  return (
    <div className="rest-drawer" open={props.isOpen} onClose={props.onClose} title={props.title}>
      <div className="description">
        <Form
          className="form-description"
          onSubmit={() => {
            props.saveChanges({
              backgroundColorId: 2,
            });
          }}
        >
          <div className="header">
            <div>
              <span className="title">
                {props.check?.webkassaCheckNumber
                  ? `${STRINGS.components.webkassaDescriptionRestModal.checkNumber}${props.check?.webkassaCheckNumber}`
                  : `${STRINGS.components.webkassaDescriptionRestModal.checkIsNotFiscalized}`}
              </span>
              {printData ? (
                <button className="button-none" onClick={() => setOpenReceipt(!openReceipt)}>
                  <Icon name="printer" width={20} height={20} color={colors.color_brown._0} />
                </button>
              ) : (
                <></>
              )}
              {props.possibleCancel && !isCanceled && (
                <button
                  className="button-none cancel"
                  onClick={() => {
                    setIsCanceled(true);
                    dispatch(setWebkassaCheck(props.check.id));
                    props.handlerCancel(props.check);
                  }}
                >
                  <Icon name="cancel" width={20} height={20} color={colors.color_red._0} />
                </button>
              )}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => props.setOpen(false)}>
              <Icon name="cross" width={20} height={20} />
            </div>
          </div>
          {openReceipt && printData ? (
            <WebkassaReceipt check={printData} refReceipt={componentRef} />
          ) : (
            <div className="body">
              <div className="body-container">
                <div className="subtitle-wrapper">
                  <span className="subtitle">
                    {STRINGS.components.webkassaDescriptionRestModal.detailCheckTitle}
                  </span>
                </div>
                <div className="sub-container">
                  <div className="sub-subtitle-wrapper">
                    <span className="sub-subtitle">
                      {STRINGS.components.webkassaDescriptionRestModal.timeCheckTitle}
                    </span>
                  </div>
                  <span className="sub-time">
                    {props.check?.receiptDateTime &&
                      new Date(props.check?.receiptDateTime).toLocaleString()}
                  </span>
                  <div className="sub-subtitle-wrapper">
                    <span className="sub-subtitle">
                      {STRINGS.components.webkassaDescriptionRestModal.cashboxNumber}
                    </span>
                  </div>
                  <span className="sub-time">{elData.CashboxUniqueNumber}</span>
                  <div className="sub-subtitle-wrapper">
                    <span className="sub-subtitle">
                      {STRINGS.components.webkassaDescriptionRestModal.operationType}
                    </span>
                  </div>
                  <span className="sub-time">{WEBKASSA_OPERATION_TYPES[elData.OperationType]}</span>
                </div>
              </div>
              <div className="body-container">
                <div className="subtitle-wrapper">
                  <span className="subtitle">
                    {STRINGS.components.webkassaDescriptionRestModal.contentCheckTitle}
                  </span>
                </div>
                {elData?.Positions?.map((el, index) => (
                  <WebkassaItem key={`wk-item-${index}}`} el={el} />
                ))}
              </div>
              <div className="summary-container">
                <div className="price-wrapper">
                  <span className="price">
                    {STRINGS.components.webkassaDescriptionRestModal.total}
                  </span>
                </div>
                <div className="price-wrapper">
                  <span className="price">
                    {totalPrice} {STRINGS.components.descriptionRestaurantModal.currency}
                  </span>
                </div>
                <div className="price-wrapper">
                  <span className="price">
                    {STRINGS.components.webkassaDescriptionRestModal.vat}
                  </span>
                </div>
                <div className="price-wrapper">
                  <p className="price">
                    {totalVat} {STRINGS.components.descriptionRestaurantModal.currency}
                  </p>
                </div>
              </div>
            </div>
          )}
          {openReceipt && printData && (
            <ReactToPrint
              trigger={() => (
                <div className="footer-restaurant">
                  <CustomButton
                    label="Распечатать"
                    size="large"
                    typeButton="submit"
                    className="button-print"
                  />
                </div>
              )}
              content={() => componentRef.current}
            />
          )}
          {!openReceipt && elData.OperationType === 2 ? (
            props.check.isSuccess ? (
              new Date().getTime() - new Date(props.check.receiptDateTime) <= 3600000 * 24 &&
              props.check.isPossibleToReturn ? (
                <div className="footer-restaurant">
                  <CustomButton
                    label="Оформить возврат"
                    onClick={() => props.handleReturn(props.check)}
                    size="large"
                    typeButton="submit"
                  />
                </div>
              ) : (
                <></>
              )
            ) : (
              <div className="footer-restaurant">
                <CustomButton
                  label="Фискализировать еще раз"
                  onClick={() => props.handleFiscalization(props.check)}
                  size="large"
                  typeButton="submit"
                />
              </div>
            )
          ) : null}
        </Form>
      </div>
    </div>
  );
};

/**
 * Properties
 */

WebkassaDescription.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
WebkassaDescription.defaultProps = {
  languages: [],
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default WebkassaDescription;

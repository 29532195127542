import React, { useEffect, useState } from 'react';
import STRINGS from '../../../localization';
import StoplistsView from './stoplists-view';
import MenuService from '../../../services/menu';
import { Toast } from '../../ui-kit';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

const StoplistsContainer = () => {
  const SIZE = 15;

  const [page, setPage] = useState(1);

  const [bottomLoading, setBottomLoading] = useState(false);

  const { languages } = useSelector((store) => store.stuff);

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState('');

  const [showConfirm, setShowConfirm] = useState('');

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [selectedModificator, setSelectedModificator] = useState({});

  const [modificators, setModificators] = useState([]);

  let [selectedStoplistsCategory, setSelectedStoplistsCategory] = useState(0);

  const history = useHistory();

  const [isLast, setIsLast] = useState(false);

  let { id } = useParams();

  useEffect(async () => {
    setPage(1);
    getModificatorsStoplist({ page: 1, langId: langId, size: SIZE, restaurantId: id });
  }, [selectedStoplistsCategory]);

  const setStopFieldTitle = (selectedStoplistsCategory) => {
    switch (selectedStoplistsCategory) {
      case 0: return STRINGS.screens.stoplists.addModificatorStoplist;
      case 1: return STRINGS.screens.stoplists.addProductStoplist;
      case 2: return STRINGS.screens.stoplists.addComboStopList;
    }
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    setPage(page + 1);
    if (selectedStoplistsCategory === 0) {
      try {
        const resp = await MenuService.getModificatorsStoplist({ page: page + 1, langId: langId, size: SIZE, restaurantId: id });
        setModificators({ data: [...modificators?.data, ...resp?.data], isLast: resp.isLast });
      } catch (error) {
        Toast.error(STRINGS.notify.failure);
      }
    }
    if (selectedStoplistsCategory === 1) {
      try {
        const resp = await MenuService.getProductsStoplist({ page: page + 1, langId: langId, size: SIZE, restaurantId: id });
        setModificators({ data: [...modificators?.data, ...resp?.data], isLast: resp.isLast });
      } catch (error) {
        Toast.error(STRINGS.notify.failure);
      }
    }
    if (selectedStoplistsCategory === 2) {
      try {
        const resp = await MenuService.getCombosStoplist({ page: page + 1, langId: langId, size: SIZE, restaurantId: id });
        setModificators({ data: [...modificators?.data, ...resp?.data], isLast: resp.isLast });
      } catch (error) {
        Toast.error(STRINGS.notify.failure);
      }
    }
    setBottomLoading(false);
  };

  const getModificatorsStoplist = async (props) => {
     setSkeletVisible(true);
    if (selectedStoplistsCategory === 0) {
      try {
        const resp = await MenuService.getModificatorsStoplist(props);
        setModificators({ data: resp.data, isLast: resp.isLast });
      } catch (error) {
        Toast.error(STRINGS.notify.failure);
      }
    }
    if (selectedStoplistsCategory === 1) {
      try {
        const resp = await MenuService.getProductsStoplist(props);
        setModificators({ data: resp.data, isLast: resp.isLast });
      } catch (error) {
        Toast.error(STRINGS.notify.failure);
      }
    }
    if (selectedStoplistsCategory === 2) {
      try {
        const resp = await MenuService.getCombosStoplist(props);
        setModificators({ data: resp.data, isLast: resp.isLast});
      } catch (error) {
        Toast.error(STRINGS.notify.failure);
      }
    }
     setSkeletVisible(false);
  };

  const getModificatorsSuggestions = async (props) => {
    try {
      const resp = await MenuService.getModificatorsSuggestions(props);
      return resp.map((el) => ({
        title: el.posTitle,
        posId: el?.id,
        id: el?.id,
        value: el?.value,
        posTitle: el?.posTitle,
      }));
    } catch (error) {
       if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getProductsSuggestions = async (props) => {
    try {
      const resp = await MenuService.getProductsSuggestions(props);
      return resp.map((el) => ({
       title: el.posTitle,
        posId: el?.id,
        id: el?.id,
        value: el?.value,
        posTitle: el?.posTitle,
      }));
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getCombosSuggestions = async (props) => {
    try {
      const resp = await MenuService.getCombosSuggestions(props);
      return resp.map((el) => ({
       title: el.posTitle,
        posId: el?.id,
        id: el?.id,
        value: el?.value,
        posTitle: el?.posTitle,
      }));
    } catch (error) {
       if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const addModificators = async (props) => {
    setLoading(true);
    try {
      await MenuService.updateModificators(props);
      Toast.success(STRINGS.notify.success);
    } catch (error) {
       if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setLoading(false);
    setShowModal('');
    getModificatorsStoplist({ page: 1, langId: langId, size: 22, restaurantId: id });
  };

  const deleteModificator = async (props) => {
    setBottomLoading(true);
    try {
      await MenuService.updateModificators(props);
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
         Toast.success(STRINGS.notify.success);
      }
    }
    setBottomLoading(false);
    getModificatorsStoplist({ page: 1, langId: langId, size: 22, restaurantId: id });
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast && !skeletVisible) {
      getNextPage();
    }
  });

  return (
    <StoplistsView
      bottomLoading={bottomLoading}
      loading={loading}
      setLoading={setLoading}
      getProductsSuggestions={getProductsSuggestions}
      getCombosSuggestions={getCombosSuggestions}
      selectedStoplistsCategory={selectedStoplistsCategory}
      setStopFieldTitle={setStopFieldTitle}
      setSelectedStoplistsCategory={setSelectedStoplistsCategory}
      history={history}
      selectedModificator={selectedModificator}
      setSelectedModificator={setSelectedModificator}
      saveChanges={addModificators}
      deleteModificator={deleteModificator}
      getModificatorsSuggestions={getModificatorsSuggestions}
      restaurantId={id}
      modificators={modificators}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      skeletVisible={skeletVisible}
    />
  );
};

export default StoplistsContainer;

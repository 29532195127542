import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

export default class ComboService {
  static #API_ENDPOINTS = {
    combo: 'combo',
    definitionsAutocomplete: 'definitions/combo/autocomplete',
    substitude: 'combo/substitute/autocomplete',
    definitions: 'definitions/combo',
    languages: 'languages/',
    autocomplete: 'combo/autocomplete',
    exportProducts: 'mindbox/export-products',
    patchProductAvailibility: 'combo/products/availability',
    patchModificatorAvailibility: 'combo/modificators/availability',
    categories: 'product-categories/combo',
  };

  static API_SETTINGS = {
    size: 30,
    page: 1,
    langId: 1, //fix
    mainProductId: 1,
  };

  static async putSerialNumber(props) {
    try {
      const request = await api.put(
        `${URL}${this.#API_ENDPOINTS.combo}/${props.posId}/serial-number`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({ serialNumber: props.serialNumber }),
        },
      );

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.combo}/${
          props.posId
        }/serial-number`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async checkSerialNumberExisting(serialNumber) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.combo}/serial-number/${serialNumber}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${
          this.#API_ENDPOINTS.combo
        }/serial-number/${serialNumber}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async patchProductAvailibility(data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.patchProductAvailibility}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${
          this.#API_ENDPOINTS.patchModificatorAvailibility
        }`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async patchModificatorAvailibility(data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.patchModificatorAvailibility}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${
          this.#API_ENDPOINTS.patchModificatorAvailibility
        }`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getCombo(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.combo}?langId=${props.langId}&size=${props.size}&page=${
          props.page
        }&search=${props.search}${props?.categoryId ? `&categoryId=${props.categoryId}` : ''}${
          props.sort ? props.sort : ''
        }`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.combo}`, error);
      throw await error.response?.json();
    }
  }
  static async getCategories(langId) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.categories}?langId=${langId}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.categories}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async exportProducts() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.exportProducts}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.exportProducts}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getProductTitle(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.product}/${props.id}/title?langId=${props.langId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.goods}`, error);
      throw await error.response?.json();
    }
  }
  static async getComboById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.combo}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('File data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.combo}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateCategory(id, data) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.combo}/${id}/category`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.combo}/${id}/category`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getDefinitionById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.definitions}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('File data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.definitions}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getAutocomplete(search) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.autocomplete}?langId=${
          this.API_SETTINGS.langId
        }&mainProductId=${this.API_SETTINGS.mainProductId}&search=${search}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.goods}`, error);
      throw await error.response?.json();
    }
  }
  static async getDefinitionsAutocomplete(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.definitionsAutocomplete}?langId=${props.langId}&search=${
          props.search
        }`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      debug.success('Definitions data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${
          this.#API_ENDPOINTS.definitionsAutocomplete
        }?langId=${props.langId}&search=${props.search}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteProduct(id) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.product}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.product}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateCombo(id, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.combo}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.combo}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.combo}/${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(status),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }
      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.combo}/${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addGood(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.product}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.product}`, error);
      throw await error.response?.json();
    }
  }
  static async postDefinition(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.definitions}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Definition data retrieval result', request);
      const res = await request.json();
      return res.id;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.definitions}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async putDefinition(id, data) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.definitions}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Definition data retrieval result', request);
      const res = await request.json();
      return res;
    } catch (error) {
      debug.error(
        `Failed with PUT request by path: ${URL}${this.#API_ENDPOINTS.definitions}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

import React, { useState, useEffect } from 'react';

import RestaurantView from './view';
import { controlGlovoClosing } from '../helpers';
import STRINGS from '../../../localization';
import RestaurantsService from '../../../services/restaurants';
import ChocofoodService from '../../../services/chocofood';
import { Toast } from '../../ui-kit';
import {useDispatch, useSelector} from 'react-redux';
import CitiesService from '../../../services/cities';
import { useHistory, useParams } from 'react-router-dom';
import AggregatorsThunks from '../../../store/aggregators/thunks';

const RestaurantContainer = () => {
  const { languages, advantages, packages, tiers } = useSelector((store) => store.stuff);
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [selectedRestaurant, setSelectedRestaurant] = useState();
  const [skeletVisible, setSkeletVisible] = useState(false);
  const [showConfirm, setShowConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState('');
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState();
  const [city, setCity] = useState();
  const [cityQuery, setCityQuery] = useState('');
  const history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();

  const getAutocomplete = async () => {
    try {
      const resp = await CitiesService.getAutocomplete({ search: cityQuery, langId: langId });
      setCities(resp);
    } catch (error) {
      Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      setCities([]);
    }
  };

  const getRestaurantById = async () => {
    try {
      const data = await RestaurantsService.getRestaurantById(id);
      return data;
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
  };

  const updateRestaurants = async (f) => {
    setLoading(true);
    await controlGlovoClosing(f);
    let dataToSend = {
      tier: parseInt(f.tier,10),
      address: f.address,
      restaurantData: f.restaurantData,
      workingHours: f.workingHours,
      phone: f.phone?.length ? f.phone : null,
      email:f.email,
    };
    if (f?.id !== parseInt(f.unmodifiedRestaurant.id, 10)) {
      dataToSend = { ...dataToSend, id: f.id };
    }
    if (f.restaurantAdvantages) {
      dataToSend = Object.assign(dataToSend, { restaurantAdvantages: f.restaurantAdvantages });
    }
    if (f.workingHours) {
      dataToSend = Object.assign(dataToSend, { workingHours: f.workingHours });
    }
    if (f.ipAddress !== null) {
      dataToSend = { ...dataToSend, ipAddress: f.ipAddress };
    }
    if (f.printerName !== null) {
      dataToSend = { ...dataToSend, printerName: f.printerName };
    }
    if (f.packageId !== null) {
      dataToSend = { ...dataToSend, packageId: parseInt(f.packageId, 10) };
    }
    if (f.timezone !== '') {
      dataToSend = { ...dataToSend, timeZone: f.timezone };
    }
     if (f.chocofoodActive !== f.initChocofoodActive) {
    try {
      await ChocofoodService.putClosing({ isActive: f.chocofoodActive, restaurantId: f.id });
    } catch (error) {
      Toast.error(STRINGS.screens.restaurants.qo);
    }}
    try {
      await RestaurantsService.updateRestaurant({ id: f.unmodifiedRestaurant.id, data: dataToSend });
      setShowModal('');
      await getRestaurant(f.unmodifiedRestaurant.id);
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
    setShowModal('');
    setLoading(false);
  };

  const getCityById = async () => {
    if (cityId) {
      const resp = await CitiesService.getCityById(cityId);
      setCity(resp);
    }
  };

  const getRestaurant = async (id) => {
    setSkeletVisible(true);
    try {
      const data = await RestaurantsService.getRestaurantById(id);
      setSelectedRestaurant(data);
      setSkeletVisible(false);
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
      setSkeletVisible(false);
    }
  };
  useEffect(async () => {
    await getRestaurant(id);
    await dispatch(AggregatorsThunks.aggregatorsClosing(id));
  }, []);

  useEffect(() => {
    getAutocomplete();
  }, [cityQuery]);

  useEffect(() => {
    getCityById();
  }, [cityId]);

  return (
    <RestaurantView
      getRestaurantById={getRestaurantById}
      tiers={tiers}
      id={id}
      loading={loading}
      setLoading={setLoading}
      cities={cities}
      city={city}
      cityId={cityId}
      setCityId={setCityId}
      setCity={setCity}
      cityQuery={cityQuery}
      setCityQuery={setCityQuery}
      restaurant={selectedRestaurant}
      saveChanges={updateRestaurants}
      languages={languages}
      packages={packages}
      showModal={showModal}
      setShowModal={setShowModal}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      advantages={advantages}
      setSkeletVisible={setSkeletVisible}
      skeletVisible={skeletVisible}
      history={history}
    />
  );
};

export default RestaurantContainer;

/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import colors from '../../../styles/colors';
import STRINGS from '../../../localization';
import { Icon } from '../../../ui-kit';
import { Drawer } from '../../../main/ui-kit';
import { HRowItem } from '..';
import initials from '../../../main/utils/initials';
import { useWindowDimensions } from '../../../main/utils/hooks';
import { HRowIcon } from '../h-row-icon';
import AggregatorsThunks from '../../../store/aggregators/thunks';

import './styles.scss';
import { Loader } from '../../ui-kit';

const testUserData = {
  firstName: 'Aleksej',
  lastName: 'Glazunov',

  email: 'a.glazunov@tapston.com',

  avatar: null,
};

/**
 * @component Sidebar
 * @example
 * <Sidebar
 *  onClick={() => {}}
 *  sidebarVisible={false}
 * />
 */
const Sidebar = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);

  const restAddress = useSelector((store) => store.user?.restaurant?.address);
  const restaurantId = useSelector((store) => store.user.data.restaurantId);
  const { glovo, chocofood, wolt, yandex, loading } = useSelector((store) => store.aggregators);

  const [pathname, setPathname] = useState();

  const { width } = useWindowDimensions();
  const history = useHistory();

  history.listen((location) => {
    setPathname(location.pathname);
  });

  useEffect(async () => {
    setPathname(window.location.pathname);
    await dispatch(AggregatorsThunks.aggregatorsClosing(restaurantId));
  }, []);

  const SidebarView = () => {
    return (
      <div id="--sidebar-root">
        <Link to="/" className="sidebar-logo">
          <Icon name="logo-circle" width={55} height={56} />
        </Link>
        <div>
          <div className="restaurant-wrapper">
            <Icon name="restaurant-point" width={12} height={15} color={colors.color_brown._0} />
            <p className="restaurant">{STRINGS.components.sidebar.restaurant}</p>
          </div>
          <p className="restaurant-name">
            {restAddress?.city && restAddress.city?.cityData[0]?.name}{' '}
            {restAddress.addressData[0]?.title}
          </p>
          <div className="hl" />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.aggregator}
            to="/aggregators"
            active={pathname === '/aggregators'}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.currentOrders}
            to="/current-orders"
            active={pathname === '/current-orders'}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.readyOrders}
            to="/ready-orders"
            active={pathname === '/ready-orders'}
          />
          <HRowItem
            setSidebarVisible={props.onClick}
            name={STRINGS.components.sidebar.archive}
            to="/archive"
            active={pathname === '/archive'}
          />
          {loading ? (
            <div className="loader">
              <Loader color="dark" />
            </div>
          ) : (
            <>
              <HRowIcon
                setSidebarVisible={props.onClick}
                iconType="glovo"
                to="/aggregators-status"
                active={pathname === '/aggregators-status'}
                isActiveAggregator={glovo?.isOpen}
              />
              <HRowIcon
                setSidebarVisible={props.onClick}
                iconType="chocofood"
                to="/aggregators-status"
                active={pathname === '/aggregators-status'}
                isActiveAggregator={chocofood?.isOpen}
              />
              <HRowIcon
                setSidebarVisible={props.onClick}
                iconType="wolt"
                to="/aggregators-status"
                active={pathname === '/aggregators-status'}
                isActiveAggregator={wolt?.isOpen}
              />
              <HRowIcon
                setSidebarVisible={props.onClick}
                iconType="yandex"
                to="/aggregators-status"
                active={pathname === '/aggregators-status'}
                isActiveAggregator={yandex?.isOpen}
              />
            </>
          )}
          {props.roleId === 2 ? (
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.webkassaChecks}
              to="/webkassa-checks"
              active={pathname === '/webkassa-checks'}
            />
          ) : (
            <></>
          )}
        </div>
        <Link to="/profile" className="navigation-manager">
          <div className="avatar">
            <div
              style={testUserData.avatar ? { backgroundImage: `url('${URL}/${this.avatar}')` } : {}}
            >
              {!testUserData.avatar && (
                <span>{initials(user.data.firstName, user.data.lastName)}</span>
              )}
            </div>
          </div>
          <div className="user-data">
            <p className="name">
              {user.data.firstName} {user.data.lastName}
            </p>
            <p className="mail">{user.data.email}</p>
          </div>
        </Link>
      </div>
    );
  };

  if (width > 992) {
    return SidebarView();
  } else {
    return (
      <Drawer
        anchor="left"
        visible={props.sidebarVisible}
        onClick={() => props.onClick(!props.sidebarVisible)}
      >
        {SidebarView()}
      </Drawer>
    );
  }
};

/**
 * Properties
 */

Sidebar.propTypes = {
  onClick: PropTypes.func,
  sidebarVisible: PropTypes.bool,
};
Sidebar.defaultProps = {
  onClick: () => {},
  sidebarVisible: false,
};

export default Sidebar;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { Icon } from '..';

const SquareButton = (props) => {
  const renderClassName = () => {
    let str = 'square-button';
    if (props.size) {
      str += ` ${sizes[props.size]}`;
    }
    return str;
  };
  const renderButtonClassName = () => {
    let str = `${renderClassName()}`;
    if (props.type) {
      str += ` ${buttonConfigList[props.type]}`;
    }
    if (props.outline) {
      str += ' outline';
    }
    if (props.circle) {
      str += ' circle';
    }
    if (props.className) {
      str += ` ${props.className}`;
    }
    return str;
  };
  return (
    <button className={renderButtonClassName()} disabled={props.disabled} onClick={props.onClick}>
      {props.icon && <Icon name={props.icon} />}
      {props.value && <span>{props.value}</span>}
    </button>
  );
};

const buttonConfigList = {
  default: 'default',
  danger: 'danger',
  primary: 'primary',
  secondary: 'secondary',
};

/**
 * SIZES
 */

const sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

/**
 * Properties
 */

SquareButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['default', 'primary', 'secondary', 'disabled', 'danger']),

  icon: PropTypes.string,

  outline: PropTypes.bool,
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
SquareButton.defaultProps = {
  size: 'medium',
  type: 'primary',
  icon: null,
  outline: false,
  circle: false,
  disabled: false,
  className: '',
};

export default SquareButton;

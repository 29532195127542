import React from 'react';
import STRINGS from '../../../../../../localization';
import { Checkbox, Switch, TimePicker } from '../../../../../ui-kit';

const WorkingTime = ({ daysOfWeek, setDaysOfWeek, certainDays, setCertainDays, workingTimesValidation }) => {
  const handleDay = (dayNumber) => {
    setDaysOfWeek((prev) => prev.map(item => item.id === dayNumber ? { ...item, checked: !item.checked } : item));
  };

  const handleOpenTime = (date, dayNumber) => {
    setDaysOfWeek((prev) => prev.map(item => item.id === dayNumber ? { ...item, openTime: date.toISOString() } : item));
  };

  const handleCloseTime = (date, dayNumber) => {
    setDaysOfWeek((prev) => prev.map(item => item.id === dayNumber ? { ...item, closeTime: date.toISOString() } : item));
  };

  return (
    <div className="container_fullwidth">
      <div className="subtitle certain-day">
        <span>{STRINGS.screens.coupons.certainDate}  </span>
        <Switch checked={certainDays} onChange={() => { setCertainDays(!certainDays); }} />
      </div>
      {certainDays && (
        <>
          {daysOfWeek.map((item) => (
            <>
              <Checkbox label={item.day} onChange={() => handleDay(item.id)} checked={item.checked} />

              <TimePicker className="container_fullwidth_half_first" label={STRINGS.screens.coupons.ot} disabled={!item.checked} value={item.openTime} onChange={handleOpenTime} inputValue={item.openTime.slice(11, 16)} id={item.id} inputType={workingTimesValidation[item.id] ? 'default' : 'danger'}

              />
              <TimePicker className="container_fullwidth_half_first" label={STRINGS.screens.coupons.do} disabled={!item.checked} value={item.closeTime} onChange={handleCloseTime} inputValue={item.closeTime.slice(11, 16)} id={item.id} inputType={workingTimesValidation[item.id] ? 'default' : 'danger'}

              />
            </>)
          )}
        </>
      )}
    </div>);
};

export default WorkingTime;

/**
 * @prettier
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Input } from '..';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const AutocompleteStoplists = (props) => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [suggestions, setSuggestions] = useState([]);

  const [active, setActive] = useState(false);

  const [search, setSearch] = useState('');

  useEffect(async () => {
    setActive(false);
    if (search.length !== 0) {
      const data = await props.getSuggestions({
        langId: langId,
        page: 1,
        size: 50,
        search: search,
        restaurantId: props?.restaurantId,
      });
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
    setActive(true);
  }, [search]);

  const handleClickAwayEvent = () => {
    setActive(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayEvent}>
      <div className={`autocomplete ${props.className}`}>
        <Input
          fullWidth
          value={search}
          onChange={setSearch}
          onClick={() => {
            setActive(true);
          }}
          variant="outlined"
          label={props.label}
          size={props.size}
          maxLength={40}
          rightIcon="search"
          type={props.type}
          inputType={props.inputType}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
        {active &&
          suggestions?.filter((el) => !props.selected?.map((i) => i?.posId).includes(el?.posId))
            .length > 0 &&
          search.length > 0 && (
            <div className="suggestions">
              {suggestions
                ?.filter((el) => !props.selected?.map((i) => i?.posId).includes(el?.posId))
                .map((el) => (
                  <div
                    className="suggestion"
                    onClick={async () => {
                      let temp = [...props.selected];
                      temp.push(el);
                      setSearch('');
                      props.setSelected(temp);
                    }}
                  >
                    <span className="suggestion-id">{`${el?.id}`}</span>
                    <span className="suggestion-label">{`${el?.posTitle || el?.value}`}</span>
                  </div>
                ))}
            </div>
          )}
      </div>
    </ClickAwayListener>
  );
};
AutocompleteStoplists.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  data: PropTypes.array,
};
AutocompleteStoplists.defaultProps = {
  className: '',
  label: '',
  size: 'large',
  data: [],
};

export default AutocompleteStoplists;

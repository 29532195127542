/**
 * @prettier
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { YMaps, Map, Placemark, GeolocationControl, Clusterer } from 'react-yandex-maps';

const YandexMaps = (props) => {
  const [addressInput, setAddressInput] = useState();
  const [resultArray, setResultArray] = useState([]);
  const [result, setResult] = useState();

  return (
    <div>
      <YMaps>
        <div>
          <Map width="100%" defaultState={{ center: [53.9, 27.56667], zoom: 9 }}>
            <GeolocationControl />
            <Clusterer
              modules={['clusterer.addon.balloon']}
              options={{
                preset: 'islands#invertedVioletClusterIcons',
                groupByCoordinates: false,
              }}
              instanceRef={(ref) => {
                // if (ref) {
                //   setCluster(ref);
                // }
              }}
            >
              {/* {points.map((coordinates, index) => (
            <Placemark key={index} geometry={coordinates} />
          ))} */}
              <Placemark
                modules={['geoObject.addon.balloon']}
                geometry={[55.684758, 37]}
                properties={{
                  balloonContentHeader: 'Москва',
                  balloonContent: 'Выбранный город',
                }}
              />
            </Clusterer>
          </Map>
        </div>
      </YMaps>
    </div>
  );
};

YandexMaps.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  defaultValue: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  inputType: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger']),
  isValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  rightIcon: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconColor: PropTypes.string,
  onBlur: PropTypes.func,
  title: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

YandexMaps.defaultProps = {
  rightIcon: null,
  iconColor: '#111',
  iconHeight: 20,
  iconWidth: 20,
  label: '',
  type: 'text',
  maxLength: 256,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  placeholder: ' ',
  max: null,
  min: null,
  name: null,
  pattern: null,
  required: false,
  disabled: false,
  autocomplete: 'off',
  defaultValue: '',
  size: 'medium',
  inputType: 'primary',
  isValid: false,
  readOnly: false,
  title: null,
  className: '',
};

export default YandexMaps;

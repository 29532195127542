import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal} from '../../../ui-kit';
import { ALLERGENS } from '../../../../constants/allergens';
import './styles.scss';
import { generatePrice } from '../../../utils/generate-price';
import Loader from '../loader';
import { URL } from '../../../../services/config';
import STRINGS from '../../../../localization';
import GoodsService from '../../../../services/goods';
import { useSelector } from 'react-redux';
import NOPHOTO from '../../../../assets/nophoto.png';

const ProductShortView = (props) => {

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { categories } = useSelector((store) => store.stuff);

  const [substituteTitles, setSubstituteTitles] = useState();

  const [loading, setLoading] = useState(false);

  const [product, setProduct] = useState();

  useEffect(async () => {
    const getSubstituteTitles = async (idArray) => {
      const data = await Promise.all(
        idArray.map(async (id) => {
          const resp = await GoodsService.getProductTitle({ id, langId });
          return resp.title;
        }),
      );
      setSubstituteTitles(data);
    };
    if (props.selectedProduct && props.isOpen) {
      setLoading(true);
      try {
        const resp = await GoodsService.getProductById(props.selectedProduct.id);
        if (resp?.substituteProducts) {
          await getSubstituteTitles(resp?.substituteProducts?.map((el) => el.substituteProductId));
        }
        setProduct(resp);
      } catch (error) {}
      setLoading(false);
    }
  }, [props.selectedProduct]);

  const getProductAllergens = () => {
    return Array.isArray(product?.allergens) && product?.allergens.length > 0
      ? ALLERGENS.map((el) => el?.title).join(', ')
      : STRINGS.screens.goods.noAllergens;
  };

  return (
    <Modal onClose={props.onClose} maxWidth="md" open={props.isOpen} title={props.title}>
      {loading ? (
        <Loader />
      ) : (
        <div className="product-short-view">
          <div>
            <div className="block">
              <div className="picture">
                {product?.fileId ? (
                  <img className="image" src={`${URL}files/${product?.fileId}`} />
                ) : (
                  <img src={NOPHOTO} />
                )}
              </div>
            </div>
            <div className="block">
              <div className="subtitle">
                <span>{STRINGS.screens.goods.energyValueEXT}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.weight}</span>
                <span>{product?.weight || STRINGS.screens.modal.noData}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.energyValueKcal}</span>

                <span>{product?.energyValueKcal || STRINGS.screens.modal.noData}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.energy.proteins}</span>
                <span>{product?.nutrients?.proteins || STRINGS.screens.modal.noData}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.energy.fats}</span>
                <span>{product?.nutrients?.fats || STRINGS.screens.modal.noData}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.energy.carbs}</span>
                <span>{product?.nutrients?.carbohydrates || STRINGS.screens.modal.noData}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.energy.saturatedFats}</span>
                <span>{product?.nutrients?.saturatedFat || STRINGS.screens.modal.noData}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.energy.cellulose}</span>
                <span>{product?.nutrients?.cellulose || STRINGS.screens.modal.noData}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.energy.sugar}</span>
                <span>{product?.nutrients?.sugar || STRINGS.screens.modal.noData}</span>
              </div>
              <div className="row-fullwidth">
                <span>{STRINGS.screens.goods.energy.salt}</span>
                <span>{product?.nutrients?.salt || STRINGS.screens.modal.noData}</span>
              </div>
            </div>
          </div>
          <div>
            <div className="block">
              <div className="subtitle">
                <span>{STRINGS.screens.modal.general}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.goods.id}:</span>
                <span>{product?.posId}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.goods.category}:</span>
                <span>{categories.find((el) => el.key === product?.categoryId)?.label}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.goods.prices.default}:</span>
                <span>{generatePrice(product?.prices[0]?.amount)}</span>
              </div>
              <div className="row">
                <span>{STRINGS.screens.goods.prices.delivery}:</span>
                <span>{generatePrice(product?.prices[1]?.amount)}</span>
              </div>
            </div>
            <div className="block">
              <div className="subtitle">
                <span>{STRINGS.screens.goods.description}</span>
              </div>
              <div className="data">
                <span>
                  {product?.definition?.definitionData?.find((el) => el.languageId === langId)
                    ?.description || STRINGS.screens.modal.noData}
                </span>
              </div>
            </div>
            <div className="block">
              <div className="subtitle">
                <span>{STRINGS.screens.goods.allergensList}</span>
              </div>
              <div className="data">
                <span>{getProductAllergens()}</span>
              </div>
            </div>
            <div className="block">
              <div className="subtitle">
                <span>{STRINGS.screens.goods.substitute}</span>
              </div>
              <div className="data">
                <span>{substituteTitles?.join(', ') || STRINGS.screens.goods.noSubstitute}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

/**
 * Properties
 */

ProductShortView.propTypes = {
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func,
  feedbacks: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  loading: PropTypes.bool,
};
ProductShortView.defaultProps = {
  showModal: false,
  handleShowModal: () => {},
  feedbacks: [],
  handleChangeStatus: () => {},
  loading: false,
};

export default ProductShortView;

/**
 * @prettier
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Table } from '../../ui-kit';

import { Head } from '../../components';
import Skelet from './skelet';
import STRINGS from '../../../localization';
import './styles.scss';

const View = (props) => {
  return (
    <>
      <Head
        label={STRINGS.screens.orders.orders}
        button={false}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.orders.orders,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              head={() => (
                <tr className="summary_table_heading">
                  <th>{STRINGS.screens.stoplists.id}</th>
                  <th>{STRINGS.screens.stoplists.restaurantTitle}</th>
                  <th>{STRINGS.screens.stoplists.address}</th>
                  <th>{STRINGS.screens.stoplists.city}</th>
                </tr>
              )}
            >
              {props.restaurants?.data?.map((restaurant) => (
                <>
                  <Fragment key={restaurant.id}>
                    <tr
                      className="summary_table_body"
                      onClick={async (event) => {
                        props.history.push(`/orders-overview/${restaurant?.id}`, {
                          breadCrumbs: [
                            {
                              label: STRINGS.components.sidebar.manage,
                              link: '/',
                            },
                            {
                              label: STRINGS.screens.orders.orders,
                              link: '/orders-overview/',
                            },
                            {
                              label: `${STRINGS.screens.restaurants.restaurant} №${restaurant.id}`,
                            },
                          ],
                        });
                        event.stopPropagation();
                      }}
                    >
                      <td>{restaurant.id}</td>
                      <td>{restaurant.title || STRINGS.screens.modal.noTitle}</td>
                      <td>{restaurant.address}</td>
                      <td>{restaurant.city}</td>
                    </tr>
                  </Fragment>
                </>
              ))}
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

View.propTypes = {
  languages: PropTypes.array,
  stoplists: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableStoplist: PropTypes.object,
  setEditableStoplist: PropTypes.func,
  saveChanges: PropTypes.func,
};

View.defaultProps = {
  languages: [],
  stoplists: null,
  showModal: '',
  setShowModal: () => {},
  editableStoplist: {},
  setEditableStoplist: () => {},
  saveChanges: () => {},
};

export default View;

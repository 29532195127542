/**
 * @prettier
 */

import { api, debug } from '../index';
import { URL } from '../config';
import { WEBKASSA_CHECK_STATUSES } from '../../constants/webkassa-check-statuses';

/**
 * Webkassa Service functionality
 */
export default class WebkassaService {
  static #API_ENDPOINTS = {
    checks: 'webkassa/checks',
    returnMoney: (checkId) => `webkassa/checks/${checkId}/return`,
    fiscalizeCheck: (checkId) => `webkassa/checks/${checkId}/fiscalize`,
  };

  static async getWebkassaChecks(
    page = 1,
    size = 20,
    checkStatusId = 0,
    checkNumber = '',
    fromDate = '',
    toDate = '',
    restaurantId,
  ) {
    let queryString = `?page=${page}&size=${size}&restaurantId=${restaurantId}&checkNumber${checkNumber}&checkStatus=${WEBKASSA_CHECK_STATUSES[checkStatusId].value}`;

    if (checkNumber) {
      queryString += `&checkNumber=${checkNumber}`;
    }

    if (fromDate) {
      queryString += `&fromDate=${fromDate}`;
    }

    if (toDate) {
      queryString += `&toDate=${toDate}`;
    }

    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.checks}${queryString}`, {
        headers: {
          'content-type': 'application/json',
        },
      });
      const res = await request.json();
      debug.success('Webkassa checks data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.checks}`, error);
      throw await error.response?.json();
    }
  }

  static async returnMoneyOperation(checkId) {
    try {
      await api.post(`${URL}${this.#API_ENDPOINTS.returnMoney(checkId)}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Webkassa return money retrieval result');
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.returnMoney(checkId)}`,
        error,
      );

      throw await error.response?.json();
    }
  }

  static async fiscalizeCheck(checkId) {
    try {
      await api.post(`${URL}${this.#API_ENDPOINTS.fiscalizeCheck(checkId)}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Webkassa fiscalize check retrieval result');
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.fiscalizeCheck(checkId)}`,
        error,
      );

      throw await error.response?.json();
    }
  }
}

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Confirm, Table, Tooltip } from '../../ui-kit';
import ManagersFields from '../../components/modals/managers-fields';
import SkeletManagers from './skelet';
import { Head } from '../../components';

import STRINGS from '../../../localization';

import './styles.scss';
import { getBeautyPhoneString } from '../../utils/phone';
import { translateRole } from '../../utils/translate-role';

const ManagersView = (props) => {
  const STATUS_OPTIONS = Object.values(props.roles).map((el, key) => ({
    label: el.title,
    key: key,
  }));

  const renderManagerName = (firstName, lastName) => {
    if (firstName?.length && lastName?.length) {
      return `${firstName} ${lastName}`;
    }
    if (firstName?.length) {
      return firstName;
    }
    if (lastName?.length) {
      return lastName;
    }
    return STRINGS.screens.managers.qe;
  };

  return (
    <>
      <ManagersFields
        loading={props.loading}
        setLoading={props.setLoading}
        restaurants={props.restaurants}
        restaurantsQuery={props.restaurantsQuery}
        setRestaurantsQuery={props.setRestaurantsQuery}
        roles={props.roles}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.managers.editManager}
        userData={props.editableManager}
        saveChanges={props.saveChanges}
      />
      <ManagersFields
        loading={props.loading}
        setLoading={props.setLoading}
        restaurants={props.restaurants}
        restaurantsQuery={props.restaurantsQuery}
        setRestaurantsQuery={props.setRestaurantsQuery}
        register={true}
        roles={props.roles}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'addUser'}
        title={STRINGS.screens.managers.addManager}
        saveChanges={props.addManager}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={`${STRINGS.screens.users.question}`}
        subtitleBold={`${props.editableManager.firstName} ${props.editableManager.lastName}`}
        buttons={[
          {
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteManager(),
            type: 'danger',
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
            },
          },
        ]}
      />
      <Head
        label={STRINGS.screens.managers.managers}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.managers.addManager,
          onClick: () => props.setShowModal('addUser'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.managers.managers,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          {props.skeletVisible ? (
            <SkeletManagers />
          ) : (
            <Table
              head={() => (
                <tr className="summary_table_heading">
                  <th>{STRINGS.screens.users.id}</th>
                  <th>{STRINGS.screens.users.name}</th>
                  <th>{STRINGS.screens.users.email}</th>
                  <th>{STRINGS.screens.users.number}</th>
                  <th>{STRINGS.screens.users.role}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.managers?.managers?.map((item) => (
                  <tr
                    key={item.id}
                    className="summary_table_body"
                    onClick={async (event) => {
                      event.stopPropagation();
                      props.history.push(`managers/${item.id}/`, {
                        manager: {
                          id: item?.id,
                          firstName: item?.firstName,
                          lastName: item?.lastName,
                          email: item?.email,
                          phone: item?.phone,
                          address: item?.address,
                          roleId: item?.roleId,
                          role: item?.role,
                          restaurantId: item?.restaurantId,
                        },
                      });
                    }}
                  >
                    <td>{item.id}</td>
                    <td>{renderManagerName(item?.firstName, item?.lastName)}</td>
                    <td>{item?.email || STRINGS.screens.managers.qr}</td>
                    <td>{getBeautyPhoneString(item.phone) || STRINGS.screens.managers.qr}</td>
                    <td>
                      {translateRole(
                        item.role ||
                          STATUS_OPTIONS.find((el) => el.key === item?.roleId - 1)?.label,
                      )}
                    </td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setShowConfirm('delete');
                              props.setEditableManager(item);
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.edit}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setShowModal('edit');
                              props.setEditableManager({
                                id: item?.id,
                                firstName: item?.firstName,
                                lastName: item?.lastName,
                                email: item?.email,
                                phone: item?.phone,
                                address: item?.address,
                                roleId: item?.roleId,
                                role: item?.role,
                                restaurantId: item?.restaurantId,
                              });
                            }}
                          >
                            <Icon name="edit" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

ManagersView.propTypes = {
  roles: PropTypes.object,
  managers: PropTypes.object,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableManager: PropTypes.object,
  setEditableManager: PropTypes.func,
  saveChanges: PropTypes.func,
};

ManagersView.defaultProps = {
  roles: {},
  managers: null,
  showModal: '',
  setShowModal: () => {},
  editableManager: {},
  setEditableManager: () => {},
  saveChanges: () => {},
};

export default ManagersView;

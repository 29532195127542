/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';

import View from './view';
import { Toast } from '../../ui-kit';
import STRINGS from '../../../localization';
import DiscountsService from '../../../services/discounts';
import MainService from '../../../services/main';
import GoodsService from '../../../services/goods';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [discount, setDiscount] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState('');
  const [products, setProducts] = useState([]);
  const history = useHistory();
  let { id } = useParams();

  const getFileId = async (file) => {
    try {
      const resp = await MainService.postFile(file);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getFileById = async () => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getProductsAutocomplete = async (search) => {
    try {
      const resp = await GoodsService.getAutocomplete(search);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      return [];
    }
  };
  const getProductTitle = async (props) => {
    try {
      const resp = await GoodsService.getProductTitle(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const mapFilesId = async (arr) => {
    let filesId = [];
    for (const element of arr) {
      if (element?.file?.id) {
        filesId.push(element?.file?.id);
      } else {
        const fileId = await getFileId(element?.file);
        filesId.push(fileId?.id);
      }
    }
    return arr.map((el, index) => ({
      languageId: el.languageId,
      title: el.title,
      description: el.description,
      warning: el.warning,
      subtitle: el.subtitle,
      fileId: filesId[index],
    }));
  };
  const saveChanges = async (f) => {
    setLoading(true);
    const promotionData = await mapFilesId(f.promotionData);
    const dataToSend = {
      startDate: f.startDate,
      expirationDate: f.expirationDate,
      promotionData: promotionData,
    };
    try {
      await DiscountsService.updateDiscount({ data: dataToSend, id: f.id });
      setSkeletVisible(true);
      setDiscount((prev) => ({ ...prev, ...dataToSend }));
      setSkeletVisible(false);
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setTimeout(() => setLoading(false), 100);
  };
  const getDiscountById = async () => {
    setSkeletVisible(true);
    try {
      const resp = await DiscountsService.getDiscountById(id);
      if (resp) {
        const productTitles = await Promise.all(
          resp?.promotionProducts.map(async (el) => {
            const resp = await getProductTitle({ id: el.productId, langId: 1 });
            return resp?.title;
          }),
        );
        setProducts(productTitles);
        setDiscount(resp);
      }
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setSkeletVisible(false);
  };
  useEffect(() => {
    getDiscountById(true);
  }, [id]);

  return (
    <View
      langId={langId}
      products={products}
      discountId={id}
      discount={discount}
      history={history}
      showModal={showModal}
      saveChanges={saveChanges}
      setShowModal={setShowModal}
      getDiscountById={getDiscountById}
      setSkeletVisible={setSkeletVisible}
      skeletVisible={skeletVisible}
      loading={loading}
      setLoading={setLoading}
      getFileById={getFileById}
      getFileId={getFileId}
      getProductsAutocomplete={getProductsAutocomplete}
    />
  );
};

export default Container;

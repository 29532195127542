import React, { useState, useEffect } from 'react';
import View from './view';
import CouponsService from '../../../services/coupons';
import MainService from '../../../services/main';
import { Toast } from '../../ui-kit';
import { TYPES } from '../../../constants/coupon-types';
import { useHistory, useParams } from 'react-router-dom';
import STRINGS from '../../../localization/index';
import { useSelector } from 'react-redux';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { languages, backgroundColors } = useSelector((store) => store.stuff);

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [coupon, setCoupon] = useState(undefined);

  const [showModal, setShowModal] = useState('');

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  let { id } = useParams();

  const getCouponById = async () => {
    try {
      const resp = await CouponsService.getCouponById(id);
      return resp;
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
  };

  const mapFileId = async (file) => {
    if (file?.id) {
      return file?.id;
    } else {
      const id = await getFileId(file);
      return id?.id;
    }
  };

  const saveChanges = async (f) => {
    setLoading(true);
    if (f.couponCombos.length === 0 && f.couponProducts.length === 0) {
      Toast.error(STRINGS.screens.coupons.qq);
      setLoading(false);
    } else {
      const dataToSend = {
        expirationDate: f.expirationDate,
        price: parseInt(f.price, 10),
        code: f.code,
        type: f.type,
        discountType: f.discountType,
        count: f.count,
        unlimited: f.unlimited,
        backgroundColorId: f.backgroundColorId,
        fileId: await mapFileId(f.file),
        couponData: f.couponData,
        couponProducts: f.couponProducts || [],
        couponCombos: f.couponCombos || [],
        couponModificators: f.couponModificators || [],
      };
      if (f.discount?.length > 0) {
        Object.assign(dataToSend, { discount: parseInt(f.discount,10) });
      }
       if (f?.workingHours?.length) {
        Object.assign(dataToSend, { workingHours: f.workingHours });
      }
      try {
        await CouponsService.updateCoupon({ id: f.id, data: dataToSend });
        Toast.success(STRINGS.notify.success);
        const resp = await CouponsService.getCouponById(f.id);
        setCoupon(resp);
        setSkeletVisible(false);
      } catch (error) {
        Toast.error(STRINGS.notify.failure);
      }
      setLoading(false);
      setShowModal('');
      setTimeout(() => setLoading(false), 100);
    }
  };

  const getFileId = async (file) => {
    try {
      const resp = await MainService.postFile(file);
      return resp;
    } catch (error) {
      Toast.error(STRINGS.notify.fileFailure);
    }
  };

  const getFileById = async () => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      Toast.error(STRINGS.notify.imageFailure);
    }
  };

  const getCoupon = async (couponId) => {
    setSkeletVisible(true);
    try {
      const resp = await getCouponById(couponId);
      setCoupon(resp);
      setSkeletVisible(false);
    } catch (error) {
      setSkeletVisible(false);
    }
  };

  useEffect(() => {
    getCoupon(id);
  }, [id]);

  return (
    <View
      TYPES={TYPES}
      langId={langId}
      couponId={id}
      languages={languages}
      coupon={coupon}
      loading={loading}
      setLoading={setLoading}
      saveChanges={saveChanges}
      history={history}
      getCouponById={getCouponById}
      getFileById={getFileById}
      backgroundColors={backgroundColors}
      showModal={showModal}
      setShowModal={setShowModal}
      skeletVisible={skeletVisible}
    />
  );
};

export default Container;

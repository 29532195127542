/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useSelector } from 'react-redux';
import FeedbacksView from './feedback-view';
import ReviewsService from '../../../services/reviews-service';
import { Toast } from '../../ui-kit';
import STRINGS from '../../../localization';

const FeedbacksContainer = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [reviews, setReviews] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState('');
  const [selectedReview, setSelectedReview] = useState();
  const [showConfirm, setShowConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [bottomLoading, setBottomLoading] = useState(false);

  const [sort, setSort] = useState({ type: '', sort: '' });
  const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

  const getReviews = async (pageNumber = 1) => {
    try {
      setBottomLoading(true);
      const resp = await ReviewsService.getReviews({ page: pageNumber, langId, sort: sorts });
      if (pageNumber === 1) {
        setReviews({ isLast: resp.isLast, data: resp.data });
      } else {
        setReviews({ isLast: resp.isLast, data: [...reviews.data, ...resp.data] });
      }
      setSkeletVisible(false);
      setBottomLoading(false);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const processed = async (id, index) => {
    try {
      await ReviewsService.processed(id);
      let dataCopy = [...reviews.data];
      dataCopy[index].processed = true;
      setReviews({ isLast: reviews.isLast, data: dataCopy });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getReviewById = async (id) => {
    try {
      const resp = await ReviewsService.getReviewById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const applySort = (mode) => {
    setSort((prevState) => {
      return {
        sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
        type: mode,
      };
    });
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !reviews.isLast) {
      setPage(page + 1);
    }
  });

  useEffect(() => {
    getReviews(page);
  }, [page, langId]);

  useEffect(() => {
    setPage(1);
    getReviews();
  }, [sorts]);

  return (
    <FeedbacksView
      loading={loading}
      setLoading={setLoading}
      bottomLoading={bottomLoading}
      processed={processed}
      reviews={reviews}
      getReviewById={getReviewById}
      showModal={showModal}
      setShowModal={setShowModal}
      selectedReview={selectedReview}
      setSelectedReview={setSelectedReview}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      skeletVisible={skeletVisible}
      sort={sort}
      applySort={applySort}
    />
  );
};

export default FeedbacksContainer;

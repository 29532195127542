/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import { Icon } from '../../../ui-kit';
import { Loader } from '../../ui-kit';
import colors from '../../../styles/colors';
import { Description, DescriptionDefault, Order } from '../../components/modals';
import './styles.scss';

const ReadyOrdersView = (props) => {
  const changeOrderView = (id) => {
    props.setOrder(props.ordersData.find((el) => id === el.orderPatternId));
    props.setOpen(true);
  };

  return (
    <div className="wrapper">
      <div className="left" ref={props.scrollRef}>
        <p className="title">{STRINGS.components.readyOrdersButton.title}</p>
        <a className="sort-wrapper" onClick={props.handleSort}>
          <p className="sort-title">
            {props.sort
              ? STRINGS.components.readyOrdersButton.oldOrdersSort
              : STRINGS.components.readyOrdersButton.newOrdersSort}
          </p>
          <Icon name="arrow-down" width={20} height={20} color={colors.color_gray._6} />
        </a>
        {props.isLoadingGlobal ? (
          <Loader color="dark" />
        ) : (
          props.ordersData.map((el) => (
            <Order
              key={el.id}
              el={el}
              changeOrderView={changeOrderView}
              readyOrder={true}
              readyOrders={false}
              possibleCancel={true}
              handlerSend={props.handlerSend}
            />
          ))
        )}
      </div>
      <div className="right">
        {props.open ? (
          <Description
            key={props.order?.id}
            order={props.order}
            setOpen={props.setOpen}
            readyOrders={true}
            possibleCancel={true}
            handlerSend={props.handlerSend}
            handlerCancel={props.handlerCancel}
            isButtonLoading={props.isButtonLoading}
          />
        ) : (
          <DescriptionDefault setOpen={props.setOpen} />
        )}
      </div>
    </div>
  );
};

export default ReadyOrdersView;

import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Input } from '..';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const CommonStoplistAutocomplete = (props) => {
  const [suggestions, setSuggestions] = useState([]);

  const [active, setActive] = useState(false);

  const [search, setSearch] = useState('');

  useEffect(async () => {
    setActive(false);
    if (search.length !== 0) {
      const data = await props.getSuggestions(search);
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
    setActive(true);
  }, [search]);

  const handleClickAwayEvent = () => {
    setActive(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayEvent}>
      <div className={`autocomplete ${props.className}`}>
        <Input
          fullWidth
          value={search}
          onChange={setSearch}
          onClick={() => {
            setActive(true);
          }}
          variant="outlined"
          label={props.label}
          size={props.size}
          rightIcon="search"
          type={props.type}
          inputType={props.inputType}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
        {active &&
          suggestions?.filter((el) => !props.selected?.filter((k) => k.stopListed === true)?.map((i) => i?.posId).includes(el?.posId))
            .length > 0 &&
          search.length > 0 && (
            <div className="suggestions">
              {suggestions
                ?.filter((el) => !props.selected?.filter((k) => k.stopListed === true)?.map((i) => i?.posId).includes(el?.posId))
                .map((el) => (
                  <div
                    className="suggestion"
                    onClick={async () => {
                      let temp = [...props.selected];
                      temp.unshift({
                        posId: el.posId || el.id,
                        stopListed: true,
                        title: el.posTitle || el.title || el.value,
                      });
                      setSearch('');
                      props.setSelected(temp);
                    }}
                  >
                    <span className="suggestion-id">{`${el?.posId || el?.id}`}</span>
                    <span className="suggestion-label">{`${el?.value || el?.posTitle}`}</span>
                  </div>
                ))}
            </div>
          )}
      </div>
    </ClickAwayListener>
  );
};

export default CommonStoplistAutocomplete;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

/**
 * Yandex discounts Service functionality
 */
export default class YandexDiscountsService {
  static #API_ENDPOINTS = {
    yandexDiscounts: 'yandex-discounts/',
    yandexDiscountsRestaurants: 'yandex-discounts/crm/restaurants/',
  };

  /*
   * This method is used to update order
   * @param {{data: Object} {status: String}}
   */

  static async getYandexDiscounts(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.yandexDiscounts}?page=${props.page}&size=${
          props.size
        }&restaurantId=${props.restaurantId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      const res = await request.json();
      debug.success('Yandex discounts data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.yandexDiscounts}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getYandexDiscount(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.yandexDiscounts}${+id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });
      const res = await request.json();
      debug.success('Yandex discounts data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.yandexDiscounts}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async addYandexDiscounts(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.yandexDiscounts}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Yandex discounts data retrieval result', request);
      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.yandexDiscounts}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async deleteYandexDiscount(props) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.yandexDiscounts}${props?.id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Yandex discounts data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.yandexDiscounts}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async updateYandexDiscount({ id, data }) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.yandexDiscounts}${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Yandex discounts data retrieval result', request);

      if (request.status === 204) {
        return [];
      }

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.yandexDiscounts}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.yandexDiscounts}${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          isEnabled: status,
        }),
      });
      if (request.status === 204) {
        return [];
      }
      debug.success('Yandex discounts data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return [];
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${
          this.#API_ENDPOINTS.yandexDiscounts
        }/${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addYandexDiscountsForSeveralRestaurants(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.yandexDiscountsRestaurants}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Yandex discounts data retrieval result', request);
      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.yandexDiscountsRestaurants}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { Icon } from '..';
import colors from '../../styles/colors';
import MaskedInput from 'react-text-mask';

/**
 * @component Input
 * @example
 * <Input
 *  label="Phone *"
 *  size="large"
 *  value=""
 *  onChange={() => {}}
 *  inputType="primary"
 * />
 */
const InputIp = (properties) => {
  const renderClassName = () => {
    let str = 'input-ip-container';
    if (properties.inputType) {
      str += ` ${inputConfigList[properties.inputType].basicClass}`;
    }
    if (properties.size) {
      str += ` ${sizes[properties.size]}`;
    }
    if (properties.className) {
      str += ` ${properties.className}`;
    }
    return str;
  };
  const props = {
    value: properties.value,
    guide: false,
    placeholderChar: '\u2000',
    placeholder: ' ',
    mask: (value) => Array(value.length).fill(/[\d.]/),
    pipe: (value) => {
      if (value === '.' || value.endsWith('..')) {
        return false;
      }

      const parts = value.split('.');

      if (parts.length > 4 || parts.some((part) => part === '00' || part < 0 || part > 255)) {
        return false;
      }

      return value;
    },
    onChange: (event) => properties.onChange(event.target.value),
    onBlur: properties.onBlur,
    onFocus: properties.onFocus,
  };
  return (
    <div
      className={renderClassName()}
      title={properties.title}
      disabled={properties.disabled}
      onClick={properties.onClick}
    >
      <MaskedInput {...props} />
      <label>{properties.label}</label>
      {properties.isValid && (
        <div className="is-valid">
          <Icon name="check" width={14} height={14} color={colors.color_green._0} />
        </div>
      )}
      {properties.inputType === 'danger' && (
        <div className="is-danger">
          <Icon name="danger" width={20} height={20} color={colors.color_red._0} />
        </div>
      )}
      {properties.rightIcon && (
        <div className="right-icon">
          <Icon
            name={properties.rightIcon}
            width={properties.iconWidth}
            height={properties.iconHeight}
            color={inputConfigList[properties.inputType].iconColor}
            onClick={properties.iconClick}
          />
        </div>
      )}
    </div>
  );
};

/**
 * Styles
 */

const inputConfigList = {
  default: {
    basicClass: 'default',
    iconColor: colors.color_gray._4,
  },
  primary: {
    basicClass: 'primary',
    iconColor: colors.color_black._1,
  },
  secondary: {
    basicClass: 'secondary',
    iconColor: colors.color_green._0,
  },
  danger: {
    basicClass: 'danger',
    iconColor: colors.color_gray._4,
  },
};

/**
 * SIZES
 */

const sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

/**
 * Properties
 */

InputIp.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  defaultValue: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  inputType: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger']),
  isValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  rightIcon: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconColor: PropTypes.string,
  onBlur: PropTypes.func,
  title: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  suggestion: PropTypes.string,
};
InputIp.defaultProps = {
  rightIcon: null,
  iconColor: '#3F8CFE',
  iconHeight: 20,
  iconWidth: 20,
  label: '',
  type: 'text',
  maxLength: 120,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  placeholder: ' ',
  max: null,
  min: null,
  name: null,
  pattern: null,
  required: false,
  disabled: false,
  autocomplete: 'off',
  defaultValue: '',
  size: 'large',
  inputType: 'primary',
  isValid: false,
  readOnly: false,
  title: null,
  className: '',
};

export default InputIp;

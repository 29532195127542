/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import { Icon } from '../../../ui-kit';
import { CustomButton, Loader } from '../../ui-kit';
import {
  WebkassaDescription,
  WebkassaDescriptionDefault,
  WebkassaCheck,
} from '../../components/modals';
import { ModalRestaurant, InputDate } from '../../ui-kit';
import { Select, TimePicker } from '../../ui-kit';
import { getBeautyDateString, getBeautyDateTimeString } from '../../../main/utils/datetime';
import color from '../../styles/colors';

const WebkassaChecksView = (props) => {
  const changeCheckView = (id) => {
    props.setCheck(props.checksData.find((el) => id === el.id));
    props.setOpen(true);
  };

  return (
    <div className="wrapper">
      <div className="left" ref={props.scrollRef}>
        <p className="title">{STRINGS.components.webkassaChecksButton.title}</p>
        <div className="filter-wrapper">
          <a className="sort-wrapper-archive" onClick={() => props.setOpenModal(true)}>
            <Icon name="filterRestaurant" width={20} height={20} />
            <p className="sort-title">{STRINGS.components.archiveButton.filtersTitle}</p>
          </a>
          {props.checkFilters() && (
            <a className="filter-clear-icon" onClick={() => props.clearFilters()}>
              <Icon name="crossRestaurant" width={16} height={16} color={color.color_gray._9} />
            </a>
          )}
        </div>
        {props.isLoadingGlobal ? (
          <Loader color="dark" />
        ) : (
          props.checksData.map((el) => (
            <WebkassaCheck key={`wk-check-${el.id}`} el={el} changeCheckView={changeCheckView} />
          ))
        )}
      </div>
      <div className="right">
        {props.open ? (
          <WebkassaDescription
            check={props.check}
            setOpen={props.setOpen}
            handleReturn={props.handleReturn}
            handleFiscalization={props.handleFiscalization}
          />
        ) : (
          <WebkassaDescriptionDefault setOpen={props.setOpen} />
        )}
      </div>
      <ModalRestaurant
        maxWidth="xs"
        title="Фильтры чеков"
        open={props.openModal}
        onClose={() => props.setOpenModal(false)}
      >
        <p className="title-el">{STRINGS.components.modal.checkNumber}</p>
        <div className="text-field-box">
          <div className="modal-search-icon">
            <Icon name="searchRestaurant" width={15} height={16} color={color.color_gray._8} />
          </div>
          <input
            value={props.orderNumber}
            placeholder={STRINGS.components.header.search}
            onChange={(text) => props.setCheckNumber(text.target.value)}
          />
        </div>
        <p className="title-el">{STRINGS.components.modal.checkStatus || 'Статус чека'}</p>
        <Select
          label="Выберите статус чека"
          options={props.webkassaCheckStatuses}
          value={props.checkStatus}
          onChange={props.setCheckStatus}
        />
        <p className="title-el">{STRINGS.components.orderComponent.data}</p>
        <div className="pickers-wrapper">
          <InputDate
            label="Дата с"
            iconWidth={18}
            iconHeight={18}
            inputValue={props.dateFrom && getBeautyDateString(props.dateFrom)}
            onChange={props.setDateFrom}
            value={props.dateFrom}
          />
          <InputDate
            label="Дата по"
            iconWidth={18}
            iconHeight={18}
            inputValue={props.dateTo && getBeautyDateString(props.dateTo)}
            onChange={props.setDateTo}
            value={props.date}
          />
        </div>
        <p className="title-el">{STRINGS.components.orderComponent.time}</p>
        <div className="pickers-wrapper">
          <TimePicker
            label="Время с"
            onChange={props.setTimeFrom}
            value={props.timeFrom}
            inputValue={props.timeFrom && getBeautyDateTimeString(props.timeFrom)}
          />
          <TimePicker
            label="Время по"
            onChange={props.setTimeTo}
            value={props.timeTo}
            inputValue={props.timeTo && getBeautyDateTimeString(props.timeTo)}
          />
        </div>
        <div className="button-model-apply">
          <CustomButton
            label="Применить"
            disabled={props.isLoadingGlobal}
            onClick={props.handleSort}
            size="large"
            typeButton="submit"
            loading={props.loading}
          />
        </div>
      </ModalRestaurant>
    </div>
  );
};

export default WebkassaChecksView;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

export default class StoplistsService {
  static #API_ENDPOINTS = {
    stoplists: 'stop-lists/',
    addStoplists: 'stop-lists/',
    languages: 'languages/',
    modificatorsGlobal: 'stop-lists/global/modificators',
    productsGlobal: 'stop-lists/global/products',
    combosGlobal: 'stop-lists/global/combos',
  };

  static API_SETTINGS = {
    size: 30,
    page: 1,
    langId: 1, //fix
  };

  static async getStoplists({ langId = 1, size = 20, page = 1 }) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.stoplits}?langId=${langId}&size=${size}&page=${page}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Stoplists data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.goods}`, error);
      throw await error.response?.json();
    }
  }

  static async getProductsOptions(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.productsGlobal}?langId=${props.langId}&size=${
          props.size
        }&page=${props.page}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Stoplists data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.goods}`, error);
      throw await error.response?.json();
    }
  }

  static async getModificatorsOptions(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.modificatorsGlobal}?langId=${props.langId}&size=${
          props.size
        }&page=${props.page}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Stoplists data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.goods}`, error);
      throw await error.response?.json();
    }
  }

  static async getCombosOptions(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.combosGlobal}?langId=${props.langId}&size=${props.size}&page=${
          props.page
        }`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Stoplists data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.combosGlobal}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async updateStoplist(id, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.stoplists}${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.discount}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async patchGlobalStoplist(data) {
    try {
      const request = await api.patch(`${URL}stop-lists/global`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data.data),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.discount}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async addStoplist(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.addStoplist}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.addDiscount}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

/** @format */

import * as React from 'react';
import Typography from '@mui/material/Typography';
import AttributeGroup from './attribute-group';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 'auto',
  maxHeight: '90vh',
  overflow: 'scroll',
  display: 'flex',
  flexDirection: 'column',
};

export default function Product(props) {
  const { menu, productId } = props;

  const product = menu.getProduct(productId);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>{product.name}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        style={style}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div
            style={{
              width: '100%',
              maxWidth: 500,
              backgroundColor: 'white',
              padding: 12,
              overflowY: 'scroll',
            }}
          >
            <Typography variant="h3">
              {`${product.name} / ${product.price} тг / ${product.available ? '' : 'НЕДОСТУПЕН'}`}
            </Typography>
          </div>
          {product.attributes_groups.map((agId) => (
            <AttributeGroup menu={menu} agId={agId} key={agId} />
          ))}
        </>
      </Modal>
    </div>
  );
}

import React from 'react';
import useCategories from '../../../hooks/useCategories';
import { SecondaryTabs } from '../../ui-kit';
import './styles.scss';

export const CategoriesContainer = ({type, langId, selected = 0, setSelected, title = 'Menu'}) => {
  const { categories} = useCategories({ langId, type });
  return (
    <div className="menu-categories_container">
      <h2>{title}</h2>
      <SecondaryTabs data={categories} selected={selected} setSelected={setSelected} />
    </div>
  );
};

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import View from './view';
import ModificatorsService from '../../../services/modificators';
import { Toast } from '../../ui-kit';
import MainService from '../../../services/main';
import STRINGS from '../../../localization';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Container = () => {
  const { languages } = useSelector((store) => store.stuff);
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [showModal, setShowModal] = useState('');
  const [modificator, setModificator] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let { id } = useParams();

  const getModificatorById = async () => {
    try {
      const resp = await ModificatorsService.getModificatorById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getFileById = async (fId) => {
    try {
      const resp = MainService.getFileById(fId);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getFileId = async (file) => {
    if (file?.id) {
      return file.id;
    } else {
      try {
        const id = await MainService.postFile(file);
        return id?.id;
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
  };
  const saveChanges = async (id, f) => {
    setLoading(true);
    let data = {};
    if (f.definitionId) {
      data = Object.assign(data, { definitionId: f.definitionId });
    }
    if (!!f.file) {
      data = Object.assign(data, { fileId: await getFileId(f.file) });
    }
    if (!!f.glovoFile) {
      data = Object.assign(data, { glovoFileId: await getFileId(f.glovoFile) });
    }
    if (!!f.yandexFile) {
      data = Object.assign(data, { yandexFileId: await getFileId(f.yandexFile) });
    }
    if (!!f.chocofoodFile) {
      data = Object.assign(data, { chocofoodFileId: await getFileId(f.chocofoodFile) });
    }
    if (!!f.woltFile) {
      data = Object.assign(data, { woltFileId: await getFileId(f.woltFile) });
    }
    if (f.categoryId !== f.unmodifiedModificator?.categoryId) {
      try {
        await ModificatorsService.updateCategory(id, { categoryId: f.categoryId });
      } catch (e) {}
    }
    try {
      await ModificatorsService.updateModificator(id, data);
      Toast.success(STRINGS.notify.success);
      setModificator((prev) => ({ ...prev, definition: f.definition }));
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setTimeout(() => setLoading(false), 100);
  };

  const getModificator = async () => {
    setSkeletVisible(true);
    try {
      const resp = await ModificatorsService.getModificatorById(id);
      setModificator(resp);
      setSkeletVisible(false);
    } catch (error) {
      setSkeletVisible(false);
    }
  };

  useEffect(() => {
    getModificator(id);
  }, [id]);

  return (
    <View
      saveChanges={saveChanges}
      languages={languages}
      loading={loading}
      setLoading={setLoading}
      getModificatorById={getModificatorById}
      getFileById={getFileById}
      productId={id}
      showModal={showModal}
      setShowModal={setShowModal}
      modificator={modificator}
      history={history}
      setSkeletVisible={setSkeletVisible}
      skeletVisible={skeletVisible}
    />
  );
};

export default Container;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import color from '../../styles/colors';

import { Icon } from '../../ui-kit';

import STRINGS from '../../../localization';
import { useDispatch, useSelector } from 'react-redux';

const HeaderInput = (props) => {
  const dispatch = useDispatch();
  const search = useSelector((store) => store.search.value);
  const renderClassName = () => {
    let str = 'text-field-box';
    if (props.value.length > 0) {
      str += ' filled';
    }
    return str;
  };
  return (
    <>
      <div id="--header-search">
        <div className={renderClassName()}>
          <Icon name="search" width={26} height={26} color={color.color_gray._4} />
          <input
            onKeyPress={(e) => {
              if (e.nativeEvent.keyCode === 13) {
                props.search(props.value);
              }
            }}
            value={search}
            placeholder={STRINGS.components.header.search}
            onChange={(e) => dispatch({ type: 'APPLY_SEARCH', payload: e.target.value })}
          />
        </div>
      </div>
    </>
  );
};

/**
 * Properties
 */

HeaderInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  search: PropTypes.func,
};
HeaderInput.defaultProps = {
  value: '',
  onChange: () => {},
  search: () => {},
};

export default HeaderInput;

/**
 * @prettier
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import './styles.scss';

import { Modal } from '../../../../ui-kit';
import LocalizationActions from '../../../../store/localization/actions';
import STRINGS from '../../../../localization';
import LANGUAGES from '../../../../constants/available-languages';

/**
 * @component SelectLanguage
 * @example
 * <SelectLanguage isOpen={false} close={() => {}} />
 */
const SelectLanguage = (props) => {
  const selectedLanguage = useSelector((store) => store.localization.currentLanguage);

  const dispatch = useDispatch();

  const handleChangeLanguage = (languageCode) => {
    dispatch(LocalizationActions.setLanguage(languageCode));
    props.close();
  };

  return (
    <Modal
      maxWidth="xs"
      title={STRINGS.components.header.langSelection}
      open={props.isOpen}
      onClose={props.close}
    >
      <div id="--language-selector">
        {LANGUAGES.map((item, index) => (
          <button key={index} onClick={() => handleChangeLanguage(item.langShortName)}>
            <div className={item.langShortName || 'none'} />
            <span>{item.langFullName[selectedLanguage]}</span>
          </button>
        ))}
      </div>
    </Modal>
  );
};

/**
 * Properties
 */

SelectLanguage.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};
SelectLanguage.defaultProps = {
  isOpen: false,
  close: () => {},
};

export default SelectLanguage;

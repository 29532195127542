/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import './styles.scss';

const WebkassaItem = ({ el = {} }) => {
  return (
    <div className="item-container">
      <div className="wrapper">
        <div className="info-wrapper-element">
          <div className="info-container">
            <div className="title-wrapper">
              <span className="title">
                {el.PositionName} х {el.Count}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div />
        <div className="price-container">
          <span className="price">
            {el.Price * el.Count} {STRINGS.components.webkassaDescriptionRestModal.currency}
          </span>
        </div>
      </div>
    </div>
  );
};

export default WebkassaItem;

import React from 'react';
import STRINGS from '../../../localization';
import './styles.scss';
import { generatePrice } from '../../utils/generate-price';

const OrderItemsTable = ({ order }) => (
  <table className="order-items-table">
    <thead>
      <tr>
        <th>{STRINGS.screens.orders.qu}</th>
        <th>{STRINGS.screens.orders.qi}</th>
        <th>{STRINGS.screens.orders.qo}</th>
      </tr>
    </thead>
    {order.id &&
      order?.orderBody?.combo?.map((el) => (
        <tbody>
          <tr className="order-position">
            <td>
              {el?.name}
            </td>
            <td>
              {el?.price}{STRINGS.current.tg}
            </td>
            <td>
              {el?.quantity}
            </td>
          </tr>
          {order?.orderBody?.combo?.find((i) => i.posId === el.posId)
            ?.sideItems?.length > 0 && (
              <>
                <tr className="additional-row">
                  <td>
                    <span className="additional-label">
                      {STRINGS.screens.orders.qp}:
                    </span>
                  </td>
                </tr>
                {order?.orderBody?.combo
                  ?.find((i) => i.posId === el.posId)
                  .sideItems.map((item) => (
                    <tr className="additional-row">
                      <td>
                        <span className="additional">{item.name}</span>
                      </td>
                      <td>
                        <span className="additional">{generatePrice(item.priceImpact)}</span>
                      </td>
                      <td>
                        <span className="additional">{item.quantity}</span>
                      </td>
                    </tr>
                  ))}
              </>
            )}
        </tbody>
      ))}
    {order.id &&
      order?.orderBody?.products?.map((el) => (
        <tbody>
          <tr className="order-position">
            <td>
              {el?.name}
            </td>
            <td>
              {generatePrice(el?.price)}
            </td>
            <td>
              {el?.quantity}
            </td>
          </tr>
          {order?.orderBody?.products?.find((i) => i.id === el.id)
            ?.modificators?.length > 0 && (
              <>
                <tr>
                  <td>
                    <span className="additional">{STRINGS.screens.orders.qp}</span>
                  </td>
                </tr>
                {order?.orderBody?.products
                  ?.find((i) => i.id === el.id)
                  .modificators.map((item) => (
                    <tr>
                      <td>
                        <span className="additional">{item.name}</span>
                      </td>
                      <td>
                        <span className="additional">{generatePrice(item?.price)}</span>
                      </td>
                      <td>
                        <span className="additional">{item.quantity}</span>
                      </td>
                    </tr>
                  ))}
              </>
            )}
        </tbody>
      ))}
  </table>
);

export default OrderItemsTable;

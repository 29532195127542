/**
 * @prettier
 */

const initialState = {
  languages: [],
  roles: [],
  tiers: [],
  aggregators: [],
  categories: [],
  modificatorCategories: [],
  comboCategories: [],
  advantages: [],
  backgroundColors: [],
  packages: [],
  loading: true,
};

const SET_DATA = 'SET_DATA';

const reducer = (state = initialState, action) => {
  switch (action?.type) {
    case SET_DATA:
      return {
        languages: action.payload.languages,
        roles: action.payload.roles,
        tiers: action.payload.tiers,
        aggregators: action.payload.aggregators,
        categories: action.payload.categories,
        comboCategories: action.payload.comboCategories,
        modificatorCategories: action.payload.modificatorCategories,
        advantages: action.payload.advantages,
        backgroundColors: action.payload.backgroundColors,
        packages: action.payload.packages,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;

export const setData = (payload) => {
  return {
    type: SET_DATA,
    payload: payload,
  };
};

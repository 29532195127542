/**
 * @prettier
 */

import React, { useMemo } from 'react';

import DashboardView from './dashboard-view';
import AGGREGATOR_REPORT from '../../../assets/images/aggregator-report.png';
import KKM_REPORT from '../../../assets/images/daily-report.png';
import DAILY_REPORT from '../../../assets/images/kkm-report.png';
import { useHistory } from 'react-router-dom';

const DashboardContainer = () => {
  const history = useHistory();

  const CATEGORIES = [
    {
      id: 0,
      label: 'Статистика',
      subcategories: [
        {
          id: 1,
          title: 'Еженедельные отчёты по агрегаторам',
          image: AGGREGATOR_REPORT,
          navigateTo: () => history.push('/aggregators-weekly-report'),
        },
        {
          id: 2,
          title: 'Ежедневный отчёт по доставке',
          image: DAILY_REPORT,
          navigateTo: () => history.push('/aggregators-daily-report'),
        },
        {
          id: 3,
          title: 'Отчёт по ККМ',
          image: KKM_REPORT,
          navigateTo: () => history.push('aggregators-kkm-report'),
        },
        {
          id: 3,
          title: 'Отчёт по времени сборки заказа',
          image: KKM_REPORT,
          navigateTo: () => history.push('orders-ready-report'),
        },
        {
          id: 4,
          title: 'Отчет по мобильному приложению',
          image: AGGREGATOR_REPORT,
          navigateTo: () => history.push('/aggregators-mobile-report'),
        },
        {
          id: 5,
          title: 'Отчет по подаркам',
          image: AGGREGATOR_REPORT,
          navigateTo: () => history.push('/gifts-mobile-report'),
        },
        {
          id: 6,
          title: 'Отчет по поинтам',
          image: AGGREGATOR_REPORT,
          navigateTo: () => history.push('/points-mobile-report'),
        },
        {
          id: 7,
          title: 'Отчет по купонам',
          image: AGGREGATOR_REPORT,
          navigateTo: () => history.push('/coupons-mobile-report'),
        },
        {
          id: 8,
          title: 'Отчет по панч картам',
          image: AGGREGATOR_REPORT,
          navigateTo: () => history.push('/punch-cards-mobile-report'),
        },
      ],
    },
  ];

  return <DashboardView categories={CATEGORIES} />;
};

export default DashboardContainer;

import React, { useEffect, useState } from 'react';

import StoriesView from './stories-view';
import STRINGS from '../../../localization';
import { Toast } from '../../ui-kit';
import StoriesService from '../../../services/story';
import MainService from '../../../services/main';
import { useSelector } from 'react-redux';

const StoriesContainer = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { languages } = useSelector((store) => store.stuff);

  const [showModal, setShowModal] = useState('');

  const [editableStory, setEditableStory] = useState({});

  const [showConfirm, setShowConfirm] = useState('');

  const [sort, setSort] = useState({ type: '', sort: '' });

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [deletableStory, setDeletableStory] = useState({});

  const [backgroundColors, setBackgroundColors] = useState();

  const [stories, setStories] = useState([]);

  const [loading, setLoading] = useState(false);

  const [storyTypes, setStoryTypes] = useState([]);

  const [page, setPage] = useState(1);

  const [bottomLoading, setBottomLoading] = useState(false);

  const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

  const addStory = async (f) => {
    setLoading(true);
    const addFile = async (file) => {
      try {
        const resp = await MainService.postFile(file);
        return resp.id;
      } catch (error) {
        Toast.error(STRINGS.notify.filefailure);
        return null;
      }
    };

    if (f.file === null) {
      Toast.error(STRINGS.notify.fileRequired);
    } else {
      let dataToSend = {
        storyTypeId: f.storyTypeId,
        startDateTime: f.startDateTime,
        endDateTime: f.endDateTime,
        fileId: await addFile(f.file),
        isTabBarDark: f.isTabBarDark,
        backgroundColorId: f.backgroundColorId,
        storyData: f.storyData,
      };
      if (f.link) {
        dataToSend = Object.assign(dataToSend, { link: f.link });
      }
      try {
        await StoriesService.addStory(dataToSend);
        setShowModal('');
        setEditableStory({});
        getStories({
          langId: langId,
          page: page,
          size: 30,
          search: '',
          sort: sorts,
        });
        Toast.success(STRINGS.notify.success);
      } catch (err) {
        Toast.error(STRINGS.notify.qa);
      }
    }
    setLoading(false);
  };

  const handleDeleteStory = async () => {
    try {
      setShowConfirm('');
      setStories((old) => ({
        isLast: old.isLast,
        data: old.data?.filter((el) => el.id !== deletableStory?.id),
      }));
      await StoriesService.deleteStory({ id: deletableStory?.id });
      Toast.success(STRINGS.notify.qp);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.qp);
    }}
  };

  const getStories = async (props) => {
    try {
      const resp = await StoriesService.getStories(props);
      setStories({
        isLast: resp.isLast,
        data: resp.data?.map((el) => Object.assign(el, { open: false })),
      });
    } catch (error) {
      Toast.error(STRINGS.notify.listfailure);
    }
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await getStories({
        langId: langId,
        page: page + 1,
        size: 30,
        search: '',
        sort: sorts,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setStories({
        isLast: resp.isLast,
        data: [
          ...stories?.data,
          ...resp?.data?.map((el) => Object.assign(el, { open: false, loading: false })),
        ],
      });
    } catch (err) {
      Toast.error(STRINGS.notify.failure);
      setBottomLoading(false);
    }
  };

  const getBackgroundColors = async () => {
    const data = await MainService.getBackgroundColors();
    setBackgroundColors(data);
  };

  const getStoryTypes = async () => {
    const data = await StoriesService.getStoryTypes();
    setStoryTypes(data);
  };

  useEffect(async () => {
    setSkeletVisible(true);
    await getStories({
      langId: langId,
      page: page,
      size: 30,
      search: '',
      sort: sorts,
    });
    getStoryTypes();
    getBackgroundColors();
    setSkeletVisible(false);
  }, []);

  const getStoryById = async (id) => {
    try {
      const data = StoriesService.getStoryById(id);
      return data;
    } catch (err) {
      Toast.error(STRINGS.notify.failure);
    }
  };
  const applySort = (mode) => {
    setSort((prevState) => {
      return {
        sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
        type: mode,
      };
    });
  };
  return (
    <StoriesView
      loading={loading}
      setLoading={setLoading}
      langId={langId}
      sort={sort}
      applySort={applySort}
      storyTypes={storyTypes}
      backgroundColors={backgroundColors}
      stories={stories}
      setStories={setStories}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      handleDeleteStory={handleDeleteStory}
      editableStory={editableStory}
      setEditableStory={setEditableStory}
      deletableStory={deletableStory}
      setDeletableStory={setDeletableStory}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      addStory={addStory}
      skeletVisible={skeletVisible}
      getStoryById={getStoryById}
    />
  );
};

export default StoriesContainer;

export const useMenu = (menu) => {
  const _attributesAssociate = menu.attributes.reduce((acc, attribute) => {
    acc.set(attribute.id, attribute);
    return acc;
  }, new Map());

  const _attributeGroupsAssociate = menu.attribute_groups.reduce((acc, ag) => {
    acc.set(ag.id, ag);
    return acc;
  }, new Map());

  const _productsAssociate = menu.products.reduce((acc, product) => {
    acc.set(product.id, product);
    return acc;
  }, new Map());

  const categories = menu.collections.map((collection) => collection.sections[0]);

  const getAttribute = (id) => _attributesAssociate.get(id);
  const getAttributeGroup = (id) => _attributeGroupsAssociate.get(id);
  const getProduct = (id) => _productsAssociate.get(id);

  return {
    getAttribute,
    getAttributeGroup,
    getProduct,
    categories,
  };
};

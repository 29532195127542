/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';
import View from './view';
import STRINGS from '../../../localization/index';
import MainService from '../../../services/main';
import { Toast } from '../../ui-kit';
import GoodsService from '../../../services/goods';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PunchcardsService from '../../../services/punchcards';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { languages, backgroundColors } = useSelector((store) => store.stuff);
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [punchcard, setPunchcard] = useState();
  const [showModal, setShowModal] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  let { id } = useParams();

  const getPunchcardById = async (props) => {
    try {
      const resp = await PunchcardsService.getPunchcardById(props);
      return resp;
    } catch (err) {
      Toast.error(STRINGS.notify.failure);
    }
  };
  const mapFileId = async (file) => {
    if (file?.id) {
      return file?.id;
    } else {
      const id = await getFileId(file);
      return id?.id;
    }
  };
  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getProductTitle = async (props) => {
    try {
      const resp = await GoodsService.getProductById(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getFileId = async (file) => {
    try {
      const resp = await MainService.postFile(file);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const saveChanges = async (f) => {
    setLoading(true);
    let dataToSend = {};

    if (!!f?.couponFileId && f?.couponBackgroundColorId) {
      dataToSend = {
        ...f,
        fileId: await mapFileId(f?.fileId),
        couponFileId: await mapFileId(f?.couponFileId),
      };
    } else {
      dataToSend = {
        ...f,
        fileId: await mapFileId(f?.fileId),
      };
    }
    try {
      await PunchcardsService.updatePunchcard({ id, data: dataToSend });
      setShowModal('');
      Toast.success(STRINGS.notify.success);
      await getPunchcardById({ id });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setTimeout(() => setLoading(false), 100);
  };
  useEffect(async () => {
    setSkeletVisible(true);
    const resp = await getPunchcardById({ id });
    if (resp) {
      resp?.punchCardProducts.map((el) => {
        setProducts((prev) => [
          ...prev,
          { title: el?.definitions[0].title, amount: el.count, mission: el.mission },
        ]);
      }),
        resp?.punchCardModificators.map((el) => {
          setProducts((prev) => [
            ...prev,
            { title: el?.definitions[0].title, amount: el.count, mission: el.mission },
          ]);
        });
      resp?.punchCardCombo.map((el) => {
        setProducts((prev) => [
          ...prev,
          { title: el?.definitions[0].title, amount: el.count, mission: el.mission },
        ]);
      });
    }
    setPunchcard(resp);
    setSkeletVisible(false);
  }, [id]);

  return (
    <View
      loading={loading}
      setLoading={setLoading}
      langId={langId}
      products={products}
      punchcardId={id}
      punchcard={punchcard}
      backgroundColors={backgroundColors}
      getFileById={getFileById}
      history={history}
      getPunchcardById={getPunchcardById}
      setSkeletVisible={setSkeletVisible}
      skeletVisible={skeletVisible}
      languages={languages}
      showModal={showModal}
      saveChanges={saveChanges}
      setShowModal={setShowModal}
    />
  );
};

export default Container;

import React, { useEffect, useState } from 'react';

import ManagersView from './managers-view';
import RestaurantsService from '../../../services/restaurants';
import UsersService from '../../../services/users';
import { Toast } from '../../ui-kit';
import { useSelector } from 'react-redux';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useHistory } from 'react-router-dom';
import STRINGS from '../../../localization';

const ManagersContainer = () => {
  const history = useHistory();
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const search = useSelector((store) => store.search.value);
  const [managers, setManagers] = useState({});
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantsQuery, setRestaurantsQuery] = useState([]);
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState('');
  const [editableManager, setEditableManager] = useState({});
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [page, setPage] = useState(1);
  const [bottomLoading, setBottomLoading] = useState(false);

  const saveChanges = async (f) => {
    setLoading(true);
    let data = {
      firstName: f.firstName,
      lastName: f.lastName,
      email: f.email,
      phone: f.phone,
      address: f.address,
      roleId: f.roleId,
      stopListAccess: f.stopListAccess,
    };
    if (f.restaurantId) {
      Object.assign(data, { restaurantId: f.restaurantId });
    }
    try {
      const resp = await UsersService.updateManager({ id: f.id, data });
      setManagers((prev) => ({
        isLast: prev?.isLast,
        managers: managers.managers?.map((o) => {
          if (o.id === resp.id) {
            return {
              ...resp,
            };
          }
          return o;
        }),
      }));
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setLoading(false);
  };

  const addManager = async (f) => {
    setLoading(true);
    let data = {
      firstName: f.firstName,
      lastName: f.lastName,
      email: f.email,
      phone: f.phone,
      address: f.address,
      roleId: f.roleId,
      password: f.password,
    };
    if (f.restaurantId) {
      Object.assign(data, { restaurantId: f.restaurantId });
    }
    try {
      const resp = await UsersService.addManager(data);
      setManagers({
        isLast: managers?.isLast,
        managers: [
          ...managers?.managers,
          {
            ...resp,
          },
        ],
      });
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setLoading(false);
  };
  const handleDeleteManager = async () => {
    setShowConfirm('');
    try {
      await UsersService.deleteManager({ id: editableManager?.id });
      setManagers((prev) => ({
        isLast: prev.isLast,
        managers: prev.managers.filter((el) => el.id !== editableManager.id),
      }));
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.success);
      }
    }
  };
  const getRestaurants = async (props) => {
    if (restaurantsQuery.length > 0) {
      try {
        const resp = await RestaurantsService.getRestaurants(props);
        setRestaurants(resp.data.map((el) => ({ id: el.id, value: el.address })));
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    } else {
      setRestaurants([]);
    }
  };

  const getManagers = async (props) => {
    setSkeletVisible(true);
    try {
      const resp = await UsersService.getManagers(props);
      setManagers({
        isLast: resp.isLast,
        managers: [...resp?.data],
      });
      setSkeletVisible(false);
    } catch (error) {
      setSkeletVisible(false);
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  useEffect(() => {
    getRestaurants({
      page: 1,
      size: 5,
      langId: langId,
      search: restaurantsQuery,
      sort: '',
    });
  }, [restaurantsQuery]);

  useEffect(async () => {
    setPage(1);
    getManagers({ page: 1, size: 22, langId: langId, sort: '', search: search });
  }, [search]);

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await UsersService.getManagers({
        page: page + 1,
        size: 22,
        langId: langId,
        sort: '',
        search: search,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setManagers({
        isLast: resp.isLast,
        managers: [...managers?.managers, ...resp?.data],
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  return (
    <ManagersView
      loading={loading}
      setLoading={setLoading}
      restaurants={restaurants}
      restaurantsQuery={restaurantsQuery}
      setRestaurantsQuery={setRestaurantsQuery}
      managers={managers}
      history={history}
      showModal={showModal}
      setShowModal={setShowModal}
      editableManager={editableManager}
      setEditableManager={setEditableManager}
      saveChanges={saveChanges}
      handleDeleteManager={handleDeleteManager}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      addManager={addManager}
      skeletVisible={skeletVisible}
    />
  );
};

export default ManagersContainer;

import React, { useEffect, useState } from 'react';

import './styles.scss';

import { Icon } from '../../../../ui-kit';
import { Button, Form, Drawer, CommonStoplistAutocomplete, MainTabs } from '../../../ui-kit';
import Loader from '../loader';
import { useSelector } from 'react-redux';
import STRINGS from '../../../../localization';
import StoplistsService from '../../../../services/stoplists';
import ModificatorsService from '../../../../services/modificators';
import GoodsService from '../../../../services/goods';
import ComboService from '../../../../services/combo';

const SelectedOptionsList = ({ selected, setSelected }) =>
(<div className="stoplist-selected">
  {selected
    .filter((el) => el.stopListed === true)
    .map((el, index) => (
      <div
        key={index}
        onClick={() => {
          let copy = [...selected];
          copy.find((i) => el.posId === i.posId).stopListed = false;
          setSelected(copy);
        }}
      >
        <div className="cross">
          <Icon name="cross" width={14} height={14} />
        </div>
        <div>
          <span>{el.title}</span>
        </div>
      </div>
    ))}
</div>);

const CommonStoplistFields = (props) => {
  const SIZE = 5;

  const StoplistsCategories = [
    { label: STRINGS.screens.stoplists.qq, key: 0 },
    { label: STRINGS.screens.stoplists.qw, key: 1 },
    { label: STRINGS.screens.stoplists.qz, key: 2 },
  ];

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [modificatorsSelectedOptions, setModificatorsSelectedOptions] = useState([]);

  const [productsSelectedOptions, setProductsSelectedOptions] = useState([]);

  const [combosSelectedOptions, setCombosSelectedOptions] = useState([]);

  const [defaultModificatorsSelectedOptions, setDefaultModificatorsSelectedOptions] = useState([]);

  const [defaultProductsSelectedOptions, setDefaultProductsSelectedOptions] = useState([]);

  const [defaultCombosSelectedOptions, setDefaultCombosSelectedOptions] = useState([]);

  const [selectedStoplistsCategory, setSelectedStoplistsCategory] = useState(0);

  const initialize = () => {
    setModificatorsSelectedOptions([]);
    setProductsSelectedOptions([]);
    setSelectedStoplistsCategory(0);
  };

  const normalizeData = (data) => {
    return data.map((el) => ({
      posId: el.posId,
      stopListed: true,
      title: el.title || el.posTitle,
    }));
  };

  const getModificatorsSelectedOptions = async () => {
    try {
      const data = await StoplistsService.getModificatorsOptions({
        page: 1,
        size: SIZE,
        langId,
      });
      setDefaultModificatorsSelectedOptions(normalizeData(data.data));
      setModificatorsSelectedOptions(normalizeData(data.data));
    } catch {}
  };

  const getProductsSelectedOptions = async () => {
    try {
      const data = await StoplistsService.getProductsOptions({
        page: 1,
        size: SIZE,
        langId,
      });
      setDefaultProductsSelectedOptions(normalizeData(data.data));
      setProductsSelectedOptions(normalizeData(data.data));
    } catch { }
  };

  const getCombosSelectedOptions = async () => {
    try {
      const data = await StoplistsService.getCombosOptions({
        page: 1,
        size: SIZE,
        langId,
      });
      setCombosSelectedOptions(normalizeData(data.data));
      setDefaultCombosSelectedOptions(normalizeData(data.data));
    } catch { }
  };

  const getModificatorsOptions = async (search) => {
    try {
      const data = await ModificatorsService.getModificators({
        langId,
        page: 1,
        size: SIZE,
        search: search,
      });
      return data.data;
    } catch {}
  };

  const getProductsOptions = async (search) => {
    try {
      const data = await GoodsService.getGoods({
        langId,
        page: 1,
        size: SIZE,
        search: search,
      });
      return data.data;
    } catch {}
  };

  const getCombosOptions = async (search) => {
    try {
      const data = await ComboService.getCombo({
        langId,
        page: 1,
        size: SIZE,
        search: search,
      });
      return data.data;
    } catch { }
  };

  const onClose = () => {
    props.onClose();
    setTimeout(initialize, 300);
  };

  useEffect(() => {
    if (props.isOpen) {
      getModificatorsSelectedOptions();
      getProductsSelectedOptions();
      getCombosSelectedOptions();
    }
  }, [props.isOpen]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={onClose}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges({
              data: {
                modificators: modificatorsSelectedOptions
                  .filter(
                    (el) =>
                      !defaultModificatorsSelectedOptions.find((i) => el?.posId === i?.posId) ||
                      defaultModificatorsSelectedOptions.find(
                        (i) => el?.stopListed !== i?.stopListed,
                      ),
                  )
                  .map((el) => ({
                    stopListed: el.stopListed,
                    modificatorId: el.posId,
                  })),
                products: productsSelectedOptions
                  .filter(
                    (el) =>
                      !defaultProductsSelectedOptions.find((i) => el?.posId === i?.posId) ||
                      defaultProductsSelectedOptions.find((i) => el?.stopListed !== i?.stopListed),
                  )
                  .map((el) => ({
                    stopListed: el.stopListed,
                    productId: el.posId,
                  })),
                combos: combosSelectedOptions
                  .filter(
                    (el) =>
                      !defaultCombosSelectedOptions.find((i) => el?.posId === i?.posId) ||
                      defaultCombosSelectedOptions.find((i) => el?.stopListed !== i?.stopListed),
                  )
                  .map((el) => ({
                    stopListed: el.stopListed,
                    comboId: el.posId,
                  })),
              },
            });
            initialize();
          }}
        >
          <div className="header-nosteps">
            <div className="title">
              <span>{props.title}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              <MainTabs
                active={selectedStoplistsCategory}
                data={StoplistsCategories}
                onClick={setSelectedStoplistsCategory}
              />
              {selectedStoplistsCategory === 0 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.stoplists.qr}</span>
                  </div>
                  <CommonStoplistAutocomplete
                    label={STRINGS.screens.stoplists.qu}
                    getSuggestions={getModificatorsOptions}
                    setSelected={setModificatorsSelectedOptions}
                    selected={modificatorsSelectedOptions}
                    inputType="default"
                    className="container_fullwidth_one_first"
                  />
                  <SelectedOptionsList selected={modificatorsSelectedOptions} setSelected={setModificatorsSelectedOptions} />
                </div>
              )}
              {selectedStoplistsCategory === 1 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.stoplists.qt}</span>
                  </div>
                  <CommonStoplistAutocomplete
                    inputType="default"
                    label={STRINGS.screens.stoplists.qy}
                    getSuggestions={getProductsOptions}
                    setSelected={setProductsSelectedOptions}
                    selected={productsSelectedOptions}
                    className="container_fullwidth_one_first"
                  />
                  <SelectedOptionsList selected={productsSelectedOptions} setSelected={setProductsSelectedOptions} />
                </div>
              )}
              {selectedStoplistsCategory === 2 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.stoplists.ql}</span>
                  </div>
                  <CommonStoplistAutocomplete
                    inputType="default"
                    label={STRINGS.screens.stoplists.qf}
                    getSuggestions={getCombosOptions}
                    setSelected={setCombosSelectedOptions}
                    selected={combosSelectedOptions}
                    className="container_fullwidth_one_first"
                  />
                  <SelectedOptionsList selected={combosSelectedOptions} setSelected={setCombosSelectedOptions} />
                </div>
              )}
            </div>
          )}
          <div className="footer">
            <Button
              className="submit"
              label={STRINGS.screens.modal.save}
              size="large"
              type="primary"
              typeButton="submit"
              loading={props.loading}
            />
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default CommonStoplistFields;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import glovo from './glovo.png';
import wolt from './wolt.png';
import yandex from './yandex.png';
import chocofood from './chocofood.png';

import './styles.scss';

const restaurantIcons = {
  glovo: glovo,
  wolt: wolt,
  yandex: yandex,
  chocofood: chocofood,
};

export const HRowIcon = (props) => {
  const history = useHistory();

  return (
    <nav>
      <Link
        to={props.to}
        onClick={() => props.setSidebarVisible(false)}
        className="sidebar-row-link-restaurant"
        data-active={(() => {
          const link = props.to;
          const splited = link.split('/').filter((el) => el);
          const splitedHistory = history.location.pathname.split('/').filter((el) => el);
          if (splitedHistory[0] === splited[0]) {
            return true;
          }
        })()}
      >
        <div className="row">
          {props.iconType && (
            <img
              src={restaurantIcons[props.iconType]}
              className={`restaurants ${props.iconType} ${!props.isActiveAggregator && 'closed'}`}
              alt={props.iconType}
            />
          )}
        </div>
      </Link>
    </nav>
  );
};

/**
 * Properties
 */

HRowIcon.propTypes = {
  to: PropTypes.string,
  iconType: PropTypes.string,
  data: PropTypes.object,
  active: PropTypes.bool,
  isActiveAggregator: PropTypes.bool,
};
HRowIcon.defaultProps = {
  to: '/',
  iconType: '',
  data: null,
  active: false,
  isActiveAggregator: false,
};

/**
 * @prettier
 */
import React, { useState } from 'react';
import './../styles.scss';

import { Droppable } from 'react-beautiful-dnd';
import SelectedItem from './SelectedItem';

const SelectedSuggestions = (props) => {
  return (
    <div className="custom-select-container_search_selected">
      <Droppable droppableId={props.area?.id}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {props.area?.list.map((el, index) => (
              <SelectedItem {...props} el={el} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default SelectedSuggestions;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import Loader from '../loader';

import {
  Button,
  Form,
  Input,
  Drawer,
  CustomDropzone,
  Counter,
  TextArea,
  ColorSelector,
  Radio,
  CustomSelectDnd,
} from '../../../ui-kit';
import GoodsService from '../../../../services/goods';
import ComboService from '../../../../services/combo';
import ModificatorsService from '../../../../services/modificators';
import { Toast } from '../../../ui-kit';
import { validateObject } from '../../../utils/input-functions';
import STRINGS from '../../../../localization';

const ITEMS = 'ITEMS';
const POINTS = 'POINTS';
const PUNCH_CARD = 'PUNCH_CARD';
const COUPON = 'COUPON';

const INITPUNCHCARDDATA = [
  { languageId: 1, title: '', description: '', type: PUNCH_CARD },
  { languageId: 2, title: '', description: '', type: PUNCH_CARD },
  { languageId: 3, title: '', description: '', type: PUNCH_CARD },
];

const PunchcardFields = (props) => {
  const maxStep = 6;
  const [step, setStep] = useState(1);
  const [id, setId] = useState();

  const [selectedLanguage, setSelectedLanguage] = useState(1);

  const [selectedColor, setSelectedColor] = useState(1);
  const [selectedCouponColor, setSelectedCouponColor] = useState(1);

  const [punchCardType, setPunchCardType] = useState(POINTS);
  const [points, setPoints] = useState();
  const [numberOfPurchases, setNumberOfPurchases] = useState(1);

  const [file, setFile] = useState(null);
  const [couponFile, setCouponFile] = useState(null);

  const [punchCardData, setPunchCardData] = useState(INITPUNCHCARDDATA);

  const [products, setProducts] = useState();
  const [productsQuery, setProductsQuery] = useState('');

  const [combos, setCombos] = useState();
  const [combosQuery, setCombosQuery] = useState('');

  const [modificators, setModificators] = useState();
  const [modificatorsQuery, setModificatorsQuery] = useState('');

  const [validation, setValidation] = useState();

  // drag-and-drop zone
  const initiaAreas = {
    products: {
      itemArea: {
        id: 'itemArea',
        list: [],
      },
      toBuy: {
        id: 'toBuy',
        list: [],
      },
      toAchieve: {
        id: 'toAchieve',
        list: [],
      },
    },
    modificators: {
      itemArea: {
        id: 'itemArea',
        list: [],
      },
      toBuy: {
        id: 'toBuy',
        list: [],
      },
      toAchieve: {
        id: 'toAchieve',
        list: [],
      },
    },
    combos: {
      itemArea: {
        id: 'itemArea',
        list: [],
      },
      toBuy: {
        id: 'toBuy',
        list: [],
      },
      toAchieve: {
        id: 'toAchieve',
        list: [],
      },
    },
  };

  const [areas, setAreas] = useState(initiaAreas);

  const SCHEME = {
    points: (value) => {
      if (punchCardType === 'POINTS') {
        return value?.toString().match('^[0-9]*$') && value > 0 && !!value;
      } else {
        return true;
      }
    },
    numberOfPurchases: (value) => !!value,
    title0: (value) => value?.length > 0,
    title1: (value) => value?.length > 0,
    title2: (value) => value?.length > 0,
    description0: (value) => value?.length > 0,
    description1: (value) => value?.length > 0,
    description2: (value) => value?.length > 0,
    file: (value) => !!value,
  };

  const [isFocus, setIsFocus] = useState({
    points: true,
    numberOfPurchases: true,
    title0: true,
    title1: true,
    title2: true,
    description0: true,
    description1: true,
    description2: true,
    file: true,
  });

  const titles = punchCardData.reduce(
    (acc, el, key) => ({ ...acc, [`title${key}`]: el.title }),
    [],
  );

  const descriptions = punchCardData.reduce(
    (acc, el, key) => ({ ...acc, [`description${key}`]: el.description }),
    [],
  );

  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };

  const prepareFile = async (fileId) => {
    let resp = await props.getFileById(fileId);
    resp = Object.assign(resp, { preview: URL.createObjectURL(resp), id: fileId });
    return resp;
  };

  const preparePunchcardData = (punchCardDataPatched) => {
    return punchCardData.map((el, key) => ({
      languageId: el.languageId,
      title: punchCardDataPatched[key]?.title ? punchCardDataPatched[key]?.title : '',
      description: punchCardDataPatched[key]?.description
        ? punchCardDataPatched[key]?.description
        : '',
      type: punchCardDataPatched[key].type,
    }));
  };

  const mapData = async (punchcard) => {
    props.setLoading(true);
    try {
      setId(punchcard?.id);
      setPunchCardType(punchcard?.rewardType);
      setPunchCardData(preparePunchcardData(punchcard?.punchCardData));
      setPoints(punchcard?.rewardInPoints);
      setNumberOfPurchases(punchcard?.numberOfPurchases);
      setFile(await prepareFile(punchcard?.fileId));
      setSelectedColor(punchcard?.backgroundColorId || 1);
      setSelectedCouponColor(punchcard?.couponBackgroundColorId || 1);
      if (punchcard.couponFileId) {
        setCouponFile(await prepareFile(punchcard?.couponFileId));
      }
      setAllItems(punchcard);
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const initialize = () => {
    setStep(1);
    setId();
    setPunchCardData(INITPUNCHCARDDATA);
    setPoints();
    setAreas(initiaAreas);
    setNumberOfPurchases(1);
    setFile(null);
    setCouponFile(null);
    setIsFocus({
      points: true,
      numberOfPurchases: true,
      title0: true,
      title1: true,
      title2: true,
      description0: true,
      description1: true,
      description2: true,
      file: true,
    });
  };

  const setAllItems = (items) => {
    if (items.punchCardProducts.length) {
      setAreas((prev) => ({
        ...prev,
        products: {
          ...prev.products,
          toBuy: {
            ...prev.products.toBuy,
            list: items.punchCardProducts
              .filter((el) => el.mission === 'GOAL')
              .map((i) => ({
                posId: i.productId,
                number: i.count,
                mission: 'GOAL',
                title: i.definitions[0]?.title,
              })),
          },
          toAchieve: {
            ...prev.products.toAchieve,
            list: items.punchCardProducts
              .filter((el) => el.mission === 'AWARD')
              .map((i) => ({
                posId: i.productId,
                number: i.count,
                mission: 'AWARD',
                title: i.definitions[0]?.title,
              })),
          },
        },
      }));
    }
    if (items.punchCardModificators.length) {
      setAreas((prev) => ({
        ...prev,
        modificators: {
          ...prev.modificators,
          toBuy: {
            ...prev.modificators.toBuy,
            list: items.punchCardModificators
              .filter((el) => el.mission === 'GOAL')
              .map((i) => ({
                posId: i.modificatorId,
                number: i.count,
                mission: 'GOAL',
                title: i.definitions[0]?.title,
              })),
          },
          toAchieve: {
            ...prev.modificators.toAchieve,
            list: items.punchCardModificators
              .filter((el) => el.mission === 'AWARD')
              .map((i) => ({
                posId: i.modificatorId,
                number: i.count,
                mission: 'AWARD',
                title: i.definitions[0]?.title,
              })),
          },
        },
      }));
    }
    if (items.punchCardCombo.length) {
      setAreas((prev) => ({
        ...prev,
        combos: {
          ...prev.combos,
          toBuy: {
            ...prev.combos.toBuy,
            list: items.punchCardCombo
              .filter((el) => el.mission === 'GOAL')
              .map((i) => ({
                posId: i.comboId,
                number: i.count,
                mission: 'GOAL',
                title: i.definitions[0]?.title,
              })),
          },
          toAchieve: {
            ...prev.combos.toAchieve,
            list: items.punchCardCombo
              .filter((el) => el.mission === 'AWARD')
              .map((i) => ({
                posId: i.comboId,
                number: i.count,
                mission: 'AWARD',
                title: i.definitions[0]?.title,
              })),
          },
        },
      }));
    }
  };

  const getProducts = async () => {
    try {
      const resp = await GoodsService.getGoods({
        langId: props.langId,
        page: 1,
        size: 30,
        categoryId: 0,
        search: productsQuery,
      });
      setProducts(resp.data);
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
  };

  const getCombos = async () => {
    try {
      const resp = await ComboService.getCombo({
        langId: props.langId,
        page: 1,
        size: 30,
        categoryId: 0,
        search: combosQuery,
      });
      setCombos(resp.data);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getModificators = async () => {
    try {
      const resp = await ModificatorsService.getModificators({
        langId: props.langId,
        page: 1,
        size: 5,
        categoryId: 0,
        search: modificatorsQuery,
      });
      setModificators(resp.data);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const punchCardItems = (type) => {
    const itemsToBuy = areas[type].toBuy.list.map((item) => ({
      [`${type.slice(0, -1)}Id`]: item.posId,
      count: item.number,
      mission: 'GOAL',
    }));

    const itemsToAchieve = areas[type].toAchieve.list.map((item) => ({
      [`${type.slice(0, -1)}Id`]: item.posId,
      count: item.number,
      mission: 'AWARD',
    }));

    return [...itemsToBuy, ...itemsToAchieve];
  };

  const onSubmitData = () => {
    let resultObject = {};

    resultObject.numberOfPurchases = numberOfPurchases;
    resultObject.rewardType = punchCardType;
    if (punchCardType === POINTS) {
      resultObject.rewardInPoints = Number.parseInt(points);
    }

    resultObject.fileId = file;
    resultObject.backgroundColorId = selectedColor;

    if (punchCardType === ITEMS) {
      resultObject.couponFileId = couponFile;
      resultObject.couponBackgroundColorId = selectedCouponColor;
    }

    resultObject.punchCardData = punchCardData;

    //products
    resultObject.punchCardProducts = punchCardItems('products') || [];

    //modificators
    resultObject.punchCardModificators = punchCardItems('modificators') || [];

    //combos
    resultObject.punchCardCombo = punchCardItems('combos') || [];

    return resultObject;
  };

  useEffect(() => {
    setValidation(
      validateObject(
        Object.assign(titles, descriptions, {
          file,
          points,
          numberOfPurchases,
        }),
        SCHEME,
      ),
    );
  }, [punchCardData, file, points, numberOfPurchases]);

  useEffect(() => {
    getProducts();
  }, [productsQuery]);

  useEffect(() => {
    getCombos();
  }, [combosQuery]);

  useEffect(() => {
    getModificators();
  }, [modificatorsQuery]);

  useEffect(async () => {
    if (props.isOpen === true && props.selectedPunchcard?.id) {
      await mapData(await props.getPunchcardById({ id: props.selectedPunchcard?.id }));
    }
  }, [props.isOpen, props.selectedPunchcard]);

  useEffect(() => {
    if (props.isOpen === true && props?.loadedPunchcard) {
      mapData(props?.loadedPunchcard);
    }
  }, [props.isOpen, props.loadedPunchcard]);

  useEffect(() => {
    if (!props.isOpen) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges(onSubmitData());
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.general}</span>
                    </div>
                    <div className="subtitle">
                      <span>{STRINGS.screens.punchcards.rewardType}</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '10px',
                        gap: '20px',
                      }}
                    >
                      <div className="checkbox-certain-day">
                        <Radio
                          checked={punchCardType === POINTS}
                          onChange={(e) => {
                            e.preventDefault();
                            setPunchCardType(POINTS);
                            setPunchCardData((state) =>
                              state.map((item) => ({ ...item, type: PUNCH_CARD })),
                            );
                          }}
                          label={STRINGS.screens.punchcards.points}
                        />
                      </div>
                      <div className="checkbox-certain-day">
                        <Radio
                          checked={punchCardType === ITEMS}
                          onChange={(e) => {
                            e.preventDefault();
                            setPunchCardType(ITEMS);
                            setPunchCardData((state) =>
                              state.map((item) => ({ ...item, type: COUPON })),
                            );
                          }}
                          label={STRINGS.screens.punchcards.items}
                        />
                      </div>
                    </div>
                    {punchCardType === POINTS && (
                      <Input
                        className="container_fullwidth_first"
                        fullWidth
                        variant="outlined"
                        value={points}
                        onChange={setPoints}
                        type="number"
                        label={`${STRINGS.screens.punchcards.numberOfPoints}`}
                        inputType={
                          !isFocus?.points && !validation?.data?.points?.isValid
                            ? 'danger'
                            : 'default'
                        }
                        onFocus={() => changeFocus('points', true)}
                        onBlur={() => changeFocus('points', false)}
                      />
                    )}
                  </div>
                  <div className="container_fullwidth">
                    <Counter
                      className="counter"
                      number={numberOfPurchases}
                      label={STRINGS.screens.punchcards.qe}
                      setNumber={setNumberOfPurchases}
                    />
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  {/* punchcard color & file */}
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.punchcardBackgroundColor} *</span>
                    </div>
                    <ColorSelector
                      colors={props.backgroundColors}
                      value={selectedColor}
                      setSelectedColor={setSelectedColor}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.img} *</span>
                    </div>
                    <CustomDropzone
                      maxFiles={1}
                      files={file}
                      onChange={setFile}
                      dropzoneType={'default'}
                      name="image"
                    />
                  </div>
                  {/* coupon color & file */}
                  {punchCardType === ITEMS && (
                    <>
                      <div className="container_fullwidth">
                        <div className="subtitle">
                          <span>{STRINGS.screens.modal.punchcardCouponBackgroundColor} *</span>
                        </div>
                        <ColorSelector
                          colors={props.backgroundColors}
                          value={selectedCouponColor}
                          setSelectedColor={setSelectedCouponColor}
                        />
                      </div>
                      <div className="container_fullwidth">
                        <div className="subtitle">
                          <span>{STRINGS.screens.modal.couponImg} *</span>
                        </div>
                        <CustomDropzone
                          maxFiles={1}
                          files={couponFile}
                          onChange={setCouponFile}
                          dropzoneType={'default'}
                          name="image"
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {step === 3 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.chooseProducts}</span>
                  </div>
                  <CustomSelectDnd
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.modal.chooseProducts}`}
                    data={products}
                    value={productsQuery}
                    onChange={setProductsQuery}
                    areas={areas}
                    setAreas={setAreas}
                    inputType="default"
                    name="products"
                    isPunchCard={true}
                    punchCardType={punchCardType}
                  />
                </div>
              )}
              {step === 4 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.chooseModificators}</span>
                  </div>
                  <CustomSelectDnd
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.modal.chooseModificators}`}
                    data={modificators}
                    value={modificatorsQuery}
                    onChange={setModificatorsQuery}
                    areas={areas}
                    setAreas={setAreas}
                    inputType="default"
                    name="modificators"
                    isPunchCard={true}
                    punchCardType={punchCardType}
                  />
                </div>
              )}
              {step === 5 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.chooseCombo}</span>
                  </div>
                  <CustomSelectDnd
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.modal.chooseCombo}`}
                    data={combos}
                    value={combosQuery}
                    onChange={setCombosQuery}
                    areas={areas}
                    setAreas={setAreas}
                    inputType="default"
                    name="combos"
                    isPunchCard={true}
                    punchCardType={punchCardType}
                  />
                </div>
              )}
              {step === 6 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.langs}</span>
                  </div>
                  <div className="langs">
                    {props.languages?.map((language) => (
                      <Button
                        label={language.name}
                        value={language.id}
                        size="medium"
                        className="lang"
                        type={selectedLanguage === language.id ? 'primary' : 'default'}
                        data-active={selectedLanguage === language.id}
                        onClick={(e) => {
                          setSelectedLanguage(language.id);
                          setIsFocus({
                            [`title${language.id - 1}`]: true,
                            [`subtitle${language.id - 1}`]: true,
                            [`description${language.id - 1}`]: true,
                          });
                          e.preventDefault();
                        }}
                      >
                        <span className="day-label">{language.name}</span>
                      </Button>
                    ))}
                  </div>
                  <Input
                    className="container_fullwidth_one_first"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...punchCardData];
                      temp[selectedLanguage - 1].title = val;
                      setPunchCardData(temp);
                    }}
                    inputType={
                      !isFocus?.[`title${selectedLanguage - 1}`] &&
                      !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    value={punchCardData[selectedLanguage - 1].title}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
                    onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                  />
                  <TextArea
                    textareaType={
                      !isFocus?.[`description${selectedLanguage - 1}`] &&
                      !validation?.data?.[`description${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    className="container_fullwidth_one"
                    onChange={(val) => {
                      let temp = [...punchCardData];
                      temp[selectedLanguage - 1].description = val;
                      setPunchCardData(temp);
                    }}
                    value={punchCardData[selectedLanguage - 1].description}
                    label={`${STRINGS.screens.modal.description[selectedLanguage - 1]} *`}
                    multiline
                    rows={4}
                    onFocus={() => changeFocus(`description${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`description${selectedLanguage - 1}`, false)}
                  />
                </div>
              )}
            </div>
          )}
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                disabled={!validation.isValid}
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                type={validation.isValid ? 'primary' : 'disabled'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default PunchcardFields;

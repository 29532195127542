import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../../ui-kit';

import STRINGS from '../../../../localization';

import './styles.scss';
import { generatePrice } from '../../../utils/generate-price';
import Loader from '../loader';

import { URL } from '../../../../services/config';

import ComboService from '../../../../services/combo';
import { useSelector } from 'react-redux';
import NOPHOTO from '../../../../assets/nophoto.png';

const ProductShortView = (props) => {

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [loading, setLoading] = useState(false);

  const [combo, setCombo] = useState();

  useEffect(async () => {
    if (props.selectedProduct && props.isOpen) {
      setLoading(true);
      try {
        const resp = await ComboService.getComboById(props.selectedProduct.id);
        setCombo(resp);
      } catch (error) {}
      setLoading(false);
    }
  }, [props.selectedProduct]);

  return (
    <Modal onClose={props.onClose} maxWidth="sm" open={props.isOpen} title={props.title}>
      {loading ? (
        <Loader />
      ) : (
         <div className="combo-short-view">
              <div>
                <div className="block">
                  <div className="picture">
                    {combo?.fileId ? (
                      <img className="image" src={`${URL}files/${combo?.fileId}`} />
                    ) : (
                      <img className="image" src={NOPHOTO} />
                    )}
                  </div>
                </div>

                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.general}</span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.goods.id}:</span>
                    <span>{combo?.posId || STRINGS.screens.modal.noData}</span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.combo.qh}:</span>
                    <span>
                      {generatePrice(combo?.prices[0]?.amount || combo?.prices[0]?.amountBig)}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.combo.qj}:</span>
                    <span>
                      {generatePrice(combo?.prices[1]?.amount || combo?.prices[1]?.amountBig)}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.combo.qk}:</span>
                    <span>
                      {generatePrice(combo?.prices[2]?.amount || combo?.prices[2]?.amountBig)}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.combo.ql}:</span>
                    <span>
                      {generatePrice(combo?.prices[3]?.amount || combo?.prices[3]?.amountBig)}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.combo.qg}</span>
                    <span>
                      {combo?.comboMainProduct?.definition?.definitionData?.find(
                        (e) => e?.languageId === langId,
                      ).title || `${combo?.comboMainProduct?.posTitle}`}
                    </span>
                  </div>
                </div>
                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.combo.description}</span>
                  </div>
                  <div className="data">
                    <span>
                      {combo?.definition?.definitionData?.find(
                        (el) => el.languageId === langId,
                      ).description || STRINGS.screens.modal.noData}
                    </span>
                  </div>
                </div>
              </div>

            </div>
      )}
    </Modal>
  );
};

ProductShortView.propTypes = {
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func,
  feedbacks: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  loading: PropTypes.bool,
};
ProductShortView.defaultProps = {
  showModal: false,
  handleShowModal: () => {},
  feedbacks: [],
  handleChangeStatus: () => {},
  loading: false,
};

export default ProductShortView;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';
import { formatQuery } from '../../main/utils/formatQuery';

export default class MenuService {
  static #API_ENDPOINTS = {
    menu: 'menu',
    menuModificators: 'menu/modificators',
    sync: 'menu-updates/sync',
  };

  static API_SETTINGS = {
    size: 30,
    page: 1,
    langId: 1, //fix
    mainProductId: 1,
  };

  static async getMenu(props) {
    //props: restaurantId, langId, size, existing, categoryId, search
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${props.restaurantId}?langId=${
          props.langId
        }&page=${props.page}&size=${this.API_SETTINGS.size}&existing=${props.existing}${
          props.categoryId ? `&categoryId=${props.categoryId}` : ''
        }&search=${props.search}
        `,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${
          props.restaurantId
        }?langId=${this.API_SETTINGS.langId}&page=${props.page}&size=${
          this.API_SETTINGS.size
        }&existing=${props.existing}&categoryId=${props.categoryId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getModificatorsStoplist(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menuModificators}/${props.restaurantId}/stop-list?langId=${
          props.langId
        }&page=${props.page}&size=${props.size}
        `,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.menuModificators}/${
          props.restaurantId
        }/stop-list?langId=${props.langId}&page=${props.page}&size=${props.size}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getProductsStoplist(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menu}/products/${props.restaurantId}/stop-list?langId=${
          props.langId
        }&page=${props.page}&size=${props.size}
        `,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.menuModificators}/${
          props.restaurantId
        }/stop-list?langId=${props.langId}&page=${props.page}&size=${props.size}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getCombosStoplist(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menu}/combos/${props.restaurantId}/stop-list?langId=${
          props.langId
        }&page=${props.page}&size=${props.size}
        `,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.menu}/combos/${
          props.restaurantId
        }/stop-list?langId=${props.langId}&page=${props.page}&size=${props.size}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getModificatorsSuggestions(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menuModificators}/${
          props.restaurantId
        }/stop-list/autocomplete?langId=${props.langId}&search=${props.search}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.menuModificators}/${
          props.restaurantId
        }/stop-list/autocomplete?langId=${props.langId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getProductsSuggestions(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menu}/products/${
          props.restaurantId
        }/stop-list/autocomplete?langId=${props.langId}&search=${props.search}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.menu}/${
          props.restaurantId
        }/stop-list/autocomplete?langId=${props.langId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getCombosSuggestions(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menu}/combos/${
          props.restaurantId
        }/stop-list/autocomplete?langId=${props.langId}&search=${props.search}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.menu}/combos/${
          props.restaurantId
        }/stop-list/autocomplete?langId=${props.langId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateModificators(props) {
    try {
      const request = await api.patch(
        `${URL}${this.#API_ENDPOINTS.menuModificators}/${props.restaurantId}/stop-list`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(props.data),
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.menuModificators}/${
          props.restaurantId
        }/stop-list`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteProducts(props) {
    try {
      const request = await api.delete(
        `${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${props.restaurantId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(props.data),
        },
      );

      debug.success('Product data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.menu}/${
          props.restaurantId
        }`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addProducts(props) {
    try {
      const request = await api.post(
        `${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${props.restaurantId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(props.data),
        },
      );

      debug.success('Products data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.menu}/${props.restaurantId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async syncMenu() {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.sync}`, {
        headers: {
          'content-type': 'application/json',
        },
      });
      debug.success(request);
      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.sync}`, error);
      throw await error.response?.json();
    }
  }

  static async getRestaurantMenu(type, restaurantId, query) {
    try {
      const request = await api.get(`${URL}menu/${type}/${restaurantId}/?${formatQuery(query)}`, {
        headers: {
          'content-type': 'application/json',
        },
      });
      return request.json();
    } catch (error) {
      throw await error.response?.json();
    }
  }
  static async postRestaurantMenu(type, restaurantId, data) {
    try {
      const request = await api.post(`${URL}menu/${type}/${restaurantId}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return request.json();
    } catch (error) {
      throw await error.response?.json();
    }
  }
  static async deleteRestaurantMenu(type, restaurantId, data) {
    try {
      const request = await api.delete(`${URL}menu/${type}/${restaurantId}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return request.json();
    } catch (error) {
      throw await error.response?.json();
    }
  }
  static async getAggregarorMenu(type, restaurantId, aggregator, query) {
    try {
      const request = await api.get(
        `${URL}delivery-menu/${type}/${restaurantId}/${aggregator}/?${formatQuery(query)}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      return request.json();
    } catch (error) {
      throw await error.response?.json();
    }
  }
  static async postAggregatorMenu(type, restaurantId, aggregator, data) {
    try {
      const request = await api.post(`${URL}delivery-menu/${type}/${restaurantId}/${aggregator}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return request.json();
    } catch (error) {
      throw await error.response?.json();
    }
  }
  static async deleteAggregatorMenu(type, restaurantId, aggregator, data) {
    try {
      const request = await api.delete(
        `${URL}delivery-menu/${type}/${restaurantId}/${aggregator}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      return request.json();
    } catch (error) {
      throw await error.response?.json();
    }
  }
}

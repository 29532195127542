/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';
import STRINGS from '../../../localization';

import { Skeleton } from '../../ui-kit';

import GlovoService from '../../../services/glovo';
import ChocofoodService from '../../../services/chocofood';
import WoltService from '../../../services/wolt';
import YandexService from '../../../services/yandex';

export const RestaurantsStatus = ({ restaurantId }) => {
  const [openedGlovo, setOpenedGlovo] = useState(false);
  const [openedChoco, setOpenedChoco] = useState(false);
  const [openedWolt, setOpenedWolt] = useState(false);
  const [openedYandex, setOpenedYandex] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getClosing = async () => {
      setLoading(true);

      try {
        const time = await GlovoService.getClosing(restaurantId);

        if (time.until === '') {
          setOpenedGlovo(true);
        }
      } catch {
        setOpenedGlovo(false);
      }

      try {
        const chocoTime = await ChocofoodService.getClosing(restaurantId);

        if (chocoTime.is_active) {
          setOpenedChoco(true);
        }
      } catch {
        setOpenedChoco(false);
      }

      try {
        const woltClosing = await WoltService.getClosing(restaurantId);

        if (!woltClosing.isClosed) {
          setOpenedWolt(true);
        }
      } catch {
        setOpenedWolt(false);
      }

      try {
        const yandexClosing = await YandexService.getClosingCrm(restaurantId);

        if (yandexClosing.open) {
          setOpenedYandex(true);
        }
      } catch {
        setOpenedYandex(false);
      }

      setLoading(false);
    };
    getClosing();
  }, []);

  useEffect(() => {
    const timer = setInterval(async () => {
      setLoading(true);

      try {
        const time = await GlovoService.getClosing(restaurantId);

        if (time.until === '') {
          setOpenedGlovo(true);
        }
      } catch {
        setOpenedGlovo(false);
      }

      try {
        const chocoTime = await ChocofoodService.getClosing(restaurantId);

        if (chocoTime.is_active) {
          setOpenedChoco(true);
        }
      } catch {
        setOpenedChoco(false);
      }

      try {
        const woltClosing = await WoltService.getClosing(restaurantId);

        if (!woltClosing.isClosed) {
          setOpenedWolt(true);
        }
      } catch {
        setOpenedWolt(false);
      }

      try {
        const yandexClosing = await YandexService.getClosingCrm(restaurantId);

        if (yandexClosing.open) {
          setOpenedYandex(true);
        }
      } catch {
        setOpenedYandex(false);
      }

      setLoading(false);
    }, 600000);

    return () => clearInterval(timer);
  }, []);

  if (loading) {
    return (
      <>
        <td>
          <Skeleton width="fit-content">
            <span>******************</span>
          </Skeleton>
        </td>
        <td>
          <Skeleton width="fit-content">
            <span>******************</span>
          </Skeleton>
        </td>
        <td>
          <Skeleton width="fit-content">
            <span>******************</span>
          </Skeleton>
        </td>
        <td>
          <Skeleton width="fit-content">
            <span>******************</span>
          </Skeleton>
        </td>
      </>
    );
  }

  const getComputedTextColorByOpenedFlag = (isOpened) => ({
    color: isOpened ? '#74d658' : '#CB3636',
  });

  return (
    <>
      <td style={{ color: openedGlovo ? '#74d658' : '#CB3636' }}>
        {openedGlovo ? STRINGS.screens.restaurants.qr : STRINGS.screens.restaurants.qz}
      </td>
      <td style={{ color: openedChoco ? '#74d658' : '#CB3636' }}>
        {openedChoco ? STRINGS.screens.restaurants.qr : STRINGS.screens.restaurants.qz}
      </td>
      <td style={{ color: openedYandex ? '#74d658' : '#CB3636' }}>
        {openedYandex ? STRINGS.screens.restaurants.qr : STRINGS.screens.restaurants.qz}
      </td>
      <td style={{ color: openedWolt ? '#74d658' : '#CB3636' }}>
        {openedWolt ? STRINGS.screens.restaurants.qr : STRINGS.screens.restaurants.qz}
      </td>
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAggregatorMenu from '../../../../hooks/useAggregatorMenu';
import { Drawer, Input, Button, Icon, Toast } from '../../../ui-kit';
import { putImage } from '../../../utils/putImage';
import Separator from '../../separator';
import MenuService from '../../../../services/menu';
import { CategoriesContainer } from '../../CategoriesContainer';
import Loader from './loader';
import './styles.scss';

const MenuTable = ({ reduceInit, setReduceInit, existing, langId, restaurantId, type, handleItemClick, tableName, additionalData, excludedData, aggregator, categoryId, buttonName }) => {
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(1);

  const { menu, loading, callHardReload, bottomScrollListenerRef } = useAggregatorMenu({ page, setPage, langId, existing, search, restaurantId, type, aggregator, categoryId});

  const init = () => {
    setSearch('');
    setPage(1);
    callHardReload();
  };

  useEffect(() => {
    if (reduceInit) {
      init();
      setReduceInit(false);
    }
  }, [reduceInit]);

  return (
    <div className="restaurant-menu_table">
      <Separator indent={22} />
      <p>{tableName}</p>
      <Separator indent={26} />
      {loading.firstLoading ? (
      <Loader />
      ) : (
        <>
          <Input label="Поиск по товарам" value={search} onChange={setSearch} />
          <div className="menu-table_wrapper" data-loading={loading.opacityLoading} ref={bottomScrollListenerRef}>
            <table>
              <thead>
                <tr className="menu-table_head">
                  <th className="menu-cell_img" />
                  <th className="menu-cell_id">ID</th>
                  <th className="menu-cell_title">Название</th>
                  <th className="menu-cell_price">Цена</th>
                  <th className="menu-cell_action" />
                </tr>
              </thead>
              <tbody>
                {[...additionalData, ...menu.filter((el) => !excludedData.find((item) => item.posId === el.posId))].map(({ posId, title, posTitle, fileId, prices }, index) =>
                (
                  <tr className="menu-table_row" key={index} onClick={() => handleItemClick({ posId, title, posTitle, fileId, prices })}>
                    <td>
                      <img className="product-image" src={putImage(fileId)} />
                    </td>
                    <td>
                      {posId}
                    </td>
                    <td>
                      {`${posTitle} ${title ? `(${title})` : ''}`}
                    </td>
                    <td>
                      {prices?.default}
                    </td>
                    <td><div className="icon-wrapper"><Icon name={buttonName} width={22} height={22}/></div></td>
                  </tr>
                )
                )}
              </tbody>
            </table>
          </div>
        </>)}
    </div>
  );
};

const constructUpdatingMenuDTO = (type, array) => {
  if (type === 'products') {
    return {
        productIdList:
          array.map((item) => (item.posId)),
      };
  }
  if (type === 'combo') {
    return {
        comboIdList:
          array.map((item) => (item.posId)),
      };
  }
  if (type === 'modificators') {
    return {
        modificators:
          array.map((item) => ({ modificatorId: item.posId })),
      };
  }
};

const RestaurantMenu = ({ isOpen, onClose, restaurantId, type, aggregator }) => {

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [loading, setLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(0);

  const [productsToAdd, setProductsToAdd] = useState([]);

  const [productsToDelete, setProductsToDelete] = useState([]);

  const [reduceReload, setReduceReload] = useState(false);

  const handleAddItems = (item) => {
    if (productsToDelete.find((el) => el.posId === item.posId)) {
      setProductsToDelete((prev) =>
        prev.filter((el) => el.posId !== item.posId)
      );
      return;
    }
    setProductsToAdd((prev) => [...prev, item]);
  };

  const handleDeleteItems = (item) => {
    if (productsToAdd.find((el) => el.posId === item.posId)) {
      setProductsToAdd((prev) =>
        prev.filter((el) => el.posId !== item.posId)
      );
      return;
    }
    setProductsToDelete((prev) => [...prev, item]);
  };

  const checkIsCommitedChanges = () => {
    return productsToAdd.length === 0 && productsToDelete.length === 0;
  };

  const clearStates = () => {
    setProductsToAdd([]);
    setProductsToDelete([]);
    setReduceReload(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (productsToAdd.length) {
      const data = constructUpdatingMenuDTO(type, productsToAdd);
      try {
        await MenuService.postAggregatorMenu(type, restaurantId, aggregator, data);
      } catch { }
    }
    if (productsToDelete.length) {
      const data = constructUpdatingMenuDTO(type, productsToDelete);
      try {
        await MenuService.deleteAggregatorMenu(type, restaurantId, aggregator, data);
      } catch { }
    }
    clearStates();
    setLoading(false);
    Toast.success('Меню обновлено');
  };

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => clearStates(), 300);
    }
  }, [isOpen]);

  return (
    <Drawer
      visible={isOpen}
      open={isOpen}
      onClose={!loading && onClose}
    >
      <div className="container">
        <CategoriesContainer langId={langId} type={type} selected={selectedCategory} setSelected={setSelectedCategory} title={`Меню ${aggregator} для ресторана №${restaurantId}`}/>
        <div className="restaurant-menu_content-container">
          <MenuTable aggregator={aggregator} reduceInit={reduceReload} setReduceInit={setReduceReload} existing={false} langId={langId} restaurantId={restaurantId} type={type} tableName="Все товары" buttonVariant="add" additionalData={productsToDelete} excludedData={productsToAdd} handleItemClick={handleAddItems} categoryId={selectedCategory} buttonName="icon-add"/>
          <MenuTable aggregator={aggregator} reduceInit={reduceReload} setReduceInit={setReduceReload} existing={true} langId={langId} restaurantId={restaurantId} type={type} tableName="Выбранные товары" buttonVariant="delete" additionalData={productsToAdd} excludedData={productsToDelete} handleItemClick={handleDeleteItems} categoryId={selectedCategory} buttonName="cross"/>
        </div>
        <div className="restaurant-menu_footer">
          <div>
            <Button
              label="Отмена"
              size="large"
              type="default"
              disabled={loading}
              onClick={onClose}
            />
            <Button
              label="Сохранить"
              size="large"
              disabled={loading || checkIsCommitedChanges()}
              type={!checkIsCommitedChanges() ? 'primary' : 'disabled'}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default RestaurantMenu;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';

import View from './view';
import { Toast } from '../../ui-kit';
import ComboService from '../../../services/combo';
import GoodsService from '../../../services/goods';
import MainService from '../../../services/main';
import MenuService from '../../../services/menu';
import ModificatorsService from '../../../services/modificators';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import STRINGS from '../../../localization';
import { configureStatus } from '../../utils/configure-status';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { languages, comboCategories } = useSelector((store) => store.stuff);
  const [selectedComboCategory, setSelectedComboCategory] = useState(null);
  const search = useSelector((store) => store.search.value);
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState(1);
  const [selectedCombo, setSelectedCombo] = useState();
  const [sort, setSort] = useState({ type: '', sort: '' });
  const [showModal, setShowModal] = useState('');
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [combo, setCombo] = useState();
  const history = useHistory();
  const [exportLoading, setExportLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [page, setPage] = useState(1);
  const [bottomLoading, setBottomLoading] = useState(false);

  const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

  const changeCategory = (key) => {
    setPage(1);
    setCategoryId(key);
  };
  const exportProducts = async () => {
    setExportLoading(true);
    try {
      await GoodsService.exportProducts();
      Toast.success('Выгрузка прошла успешно');
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
    setExportLoading(false);
  };
  const getFileId = async (file) => {
    if (file?.id) {
      return file.id;
    } else {
      try {
        const id = await MainService.postFile(file);
        return id?.id;
      } catch (error) {
        Toast.error(STRINGS.notify.fileFailure);
      }
    }
  };
  const getModificatorsSuggestions = async (props) => {
    try {
      const resp = await MenuService.getModificatorsSuggestions(props);
      return resp.map((el) => ({ title: el.value, posId: el.id }));
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
  };
  const getModificatorById = async (id) => {
    try {
      const resp = await ModificatorsService.getModificatorById(id);
      return resp;
    } catch (err) {
      Toast.error(STRINGS.notify.failure);
    }
  };
  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      Toast.error(STRINGS.notify.imageFailure);
    }
  };
  const saveChanges = async (id, f) => {
    setLoading(true);
    let data = {
      definitionId: f.definitionId,
    };
    if (f.categoryId === 9) {
      data = Object.assign(data, { isDeal: f.isDeal });
    }
    if (f.file) {
      data = Object.assign(data, { fileId: await getFileId(f.file) });
    }
    if (f.glovoFile) {
      data = Object.assign(data, { glovoFileId: await getFileId(f.glovoFile) });
    }
    if (f.yandexFile) {
      data = Object.assign(data, { yandexFileId: await getFileId(f.yandexFile) });
    }
    if (f.chocofoodFile) {
      data = Object.assign(data, { chocofoodFileId: await getFileId(f.chocofoodFile) });
    }
    if (f.woltFile) {
      data = Object.assign(data, { woltFileId: await getFileId(f.woltFile) });
    }
    if (f.categoryId !== f.unmodifiedCombo?.categoryId) {
      try {
        await ComboService.updateCategory(id, { categoryId: f.categoryId });
      } catch (e) {}
    }
    try {
      await ComboService.updateCombo(id, data);
      Toast.success(STRINGS.notify.success);
    } catch (error) {}
    await getCombo({
      langId: langId,
      page: 1,
      size: 30,
      search: '',
      categoryId: selectedComboCategory,
    });
    setShowModal('');
    setTimeout(() => setLoading(false), 100);
  };

  const changeStatus = async (elem) => {
    try {
      await ComboService.updateStatus(elem.id, configureStatus(elem));
    } catch (error) {
      //Toast.error(STRINGS.notify.failure);
    }
    let temp = [...combo.data];
    temp.find((el) => el.posId === elem.id).status = configureStatus(elem).status;

    setCombo((old) => ({ isLast: old.isLast, data: temp }));
    Toast.success(STRINGS.notify.success);
  };
  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';
      const resp = await ComboService.getCombo({
        langId: langId,
        page: page + 1,
        size: 30,
        search: search,
        sort: sorts,
        categoryId: selectedComboCategory,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setCombo({
        isLast: resp.isLast,
        data: [...combo?.data, ...resp?.data],
      });
    } catch (err) {
      if (err.status) {
        Toast.error(STRINGS.notify.listFailure);
      }
      setBottomLoading(false);
    }
  };
  const getCombo = async (props) => {
    setSkeletVisible(true);
    try {
      const resp = await ComboService.getCombo(props);
      setCombo({
        isLast: resp.isLast,
        data: [...resp?.data],
      });
      setSkeletVisible(false);
    } catch (error) {
      setSkeletVisible(false);
      Toast.error(STRINGS.notify.failure);
    }
  };
  const getComboById = async (id) => {
    try {
      const resp = await ComboService.getComboById(id);
      return resp;
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
  };
  const getSubstitude = async (props) => {
    try {
      const resp = await GoodsService.getSubstitude(props);
      return resp;
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
  };
  const getModificators = async (props) => {
    try {
      const resp = await ModificatorsService.getModificators(props);
      return resp;
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
  };
  const handleDeleteProduct = async () => {
    await GoodsService.deleteProduct(selectedCombo?.id);
    setShowConfirm('');
    setCombo((prev) => ({
      isLast: prev.isLast,
      data: prev.data.filter((el) => el.id !== selectedCombo.id),
    }));
    Toast.success(STRINGS.notify.success);
  };
  const applySort = (mode) => {
    setSort((prevState) => {
      return {
        sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
        type: mode,
      };
    });
  };
  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  useEffect(() => {
    setPage(1);
    getCombo({
      langId: langId,
      page: 1,
      size: 30,
      search: search,
      sort: sorts,
      categoryId: selectedComboCategory,
    });
  }, [sort, search, selectedComboCategory]);

  return (
    <View
      setSelectedComboCategory={setSelectedComboCategory}
      selectedComboCategory={selectedComboCategory}
      exportLoading={exportLoading}
      exportProducts={exportProducts}
      getSubstitude={getSubstitude}
      getModificatorById={getModificatorById}
      getModificators={getModificators}
      selectedCombo={selectedCombo}
      setSelectedCombo={setSelectedCombo}
      getComboById={getComboById}
      getFileById={getFileById}
      langId={langId}
      getModificatorsSuggestions={getModificatorsSuggestions}
      categories={comboCategories}
      categoryId={categoryId}
      setCategoryId={setCategoryId}
      history={history}
      bottomLoading={bottomLoading}
      combo={combo}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      sort={sort}
      applySort={applySort}
      saveChanges={saveChanges}
      changeCategory={changeCategory}
      changeStatus={changeStatus}
      handleDeleteProduct={handleDeleteProduct}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      loading={loading}
      setLoading={setLoading}
      skeletVisible={skeletVisible}
    />
  );
};

export default Container;

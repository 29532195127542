/**
 * @prettier
 */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CustomSelect,
  Drawer,
  Form,
  Input,
  Select,
  Toast,
} from '../../../ui-kit';
import GoodsService from '../../../../services/goods';
import CouponService from '../../../../services/coupons';
import ComboService from '../../../../services/combo';
import ModificatorsService from '../../../../services/modificators';
import MainService from '../../../../services/main';
import './styles.scss';
import Loader from '../loader';
import { validateObject } from '../../../utils/input-functions';
import Separator from '../../separator';
import STRINGS from '../../../../localization';
import RestaurantsService from '../../../../services/restaurants';
import { useSelector } from 'react-redux';

const YandexDiscount = (props) => {
  const FOCUS = {
    expirationDate: true,
    title0: true,
    title1: true,
    title2: true,
    code: true,
    price: true,
    discount: true,
  };
  const listSize = 15;
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const maxStep = props?.isEdit ? 4 : 5;

  const [step, setStep] = useState(1);

  const [expirationDate, setExpirationDate] = useState();

  const [price, setPrice] = useState();

  const [restaurants, setRestaurants] = useState([]);

  const [code, setCode] = useState('');

  const [discount, setDiscount] = useState('');

  const [defaultState, setDefaultState] = useState(null);

  const [type, setType] = useState('GIFT');

  const [yandexDiscountProducts, setYandexDiscountProducts] = useState([]);

  const [yandexDiscountCombos, setYandexDiscountCombos] = useState([]);

  const [yandexDiscountModificators, setYandexDiscountModificators] = useState([]);

  const [products, setProducts] = useState();

  const [combos, setCombos] = useState();

  const [modificators, setModificators] = useState();

  const [productsQuery, setProductsQuery] = useState('');

  const [combosQuery, setCombosQuery] = useState('');

  const [modificatorsQuery, setModificatorsQuery] = useState('');

  const [codeError, setCodeError] = useState(false);

  const [validation, setValidation] = useState(true);

  const [name, setName] = useState('');

  const [forAll, setForAll] = useState(false);

  const [selectedRestaurants, setSelectedRestaurants] = useState([props.currentRestaurant]);

  const [query, setQuery] = useState('');

  const SCHEME = {
    name: (value) => value?.length > 0,
    discount: (value) => {
      if (type === 'FIXED' && !!value) {
        return true;
      }
      if (type === 'FIXED' && !value) {
        return false;
      }
      if (type === 'PERCENTAGE') {
        return !(!value || (!!value && +value >= 100));
      }
      return true;
    },
  };

  const [isFocus, setIsFocus] = useState(FOCUS);

  const initialize = () => {
    setStep(1);
    setExpirationDate();
    setPrice();
    setCode();
    setDiscount(type === 'GIFT');
    setName('');
    setSelectedRestaurants([props.currentRestaurant]);
    setType('GIFT');
    setDefaultState(null);
    setIsFocus(FOCUS);
    setCodeError(false);
    setProducts();
    setYandexDiscountProducts([]);
    setYandexDiscountCombos([]);
    setYandexDiscountModificators([]);
    setProductsQuery('');
  };

  useEffect(() => {
    const getRestaurantsByQuery = async (requestParams) => {
      const response = await RestaurantsService.getRestaurants(requestParams);
      if (response) {
        const responseCopy = response.data.map((el) => ({ ...el, posId: el.id }));
        setRestaurants(responseCopy);
      } else {
        setRestaurants([]);
      }
    };
    getRestaurantsByQuery({ langId, page: 1, size: listSize, search: query });
  }, [query]);

  const getDefaultCouponProducts = async (productIds) => {
    try {
      return await Promise.all(
        productIds.map(async (product) => {
          const resp = await GoodsService.getProductById(product?.posId || product?.productId);
          return {
            posId: resp?.posId,
            title: resp?.posTitle || resp?.title || product?.title,
          };
        }),
      );
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getDefaultCouponModificators = async (modificatorIds) => {
    try {
      return await Promise.all(
        modificatorIds.map(async (modificator) => {
          const resp = await ModificatorsService.getModificatorById(
            modificator?.posId || modificator?.modificatorId,
          );
          return {
            posId: resp?.posId,
            title: resp?.posTitle || resp?.title || modificator?.title,
          };
        }),
      );
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getDefaultCouponCombos = async (comboIds) => {
    try {
      return await Promise.all(
        comboIds.map(async (combo) => {
          const resp = await ComboService.getComboById(combo?.posId || combo?.comboId);
          return {
            posId: resp?.posId,
            title: resp?.posTitle || resp?.title || combo?.title,
            number: combo?.number,
          };
        }),
      );
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  useEffect(() => {
    const getProducts = async (params) => {
      try {
        const resp = await GoodsService.getGoods(params);
        setProducts(resp.data);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    };

    if (productsQuery?.length > 0) {
      getProducts({
        langId: props.langId,
        page: 1,
        size: 5,
        status: 'active',
        search: productsQuery,
      });
    }
  }, [productsQuery]);

  useEffect(() => {
    const getCombos = async (params) => {
      try {
        const resp = await ComboService.getCombo(params);
        setCombos(resp.data);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    };
    if (combosQuery?.length > 0) {
      getCombos({
        langId: props.langId,
        page: 1,
        size: 5,
        categoryId: 0,
        search: combosQuery,
      });
    }
  }, [combosQuery]);

  useEffect(() => {
    const getModificators = async (params) => {
      try {
        const resp = await ModificatorsService.getModificators(params);
        setModificators(resp.data);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    };

    if (modificatorsQuery?.length > 0) {
      getModificators({
        langId: props.langId,
        page: 1,
        size: 5,
        categoryId: 0,
        search: modificatorsQuery,
      });
    }
  }, [modificatorsQuery]);

  useEffect(() => {
    if (props.isOpen === true) {
      setValidation(
        validateObject(
          Object.assign({
            name,
            discount,
          }),
          SCHEME,
        ),
      );
    }
  }, [expirationDate, code, price, name, discount, codeError, props.isOpen]);

  const changeFocus = (field, focus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: focus }));
  };

  const mapData = async (coupon) => {
    setDefaultState(coupon);
    setName(coupon?.name);
    setDiscount(coupon?.discount);
    setType(props.TYPES.find((el) => el.id === coupon?.type)?.key);
    setYandexDiscountProducts(await getDefaultCouponProducts(coupon?.yandexDiscountProducts));
    setYandexDiscountCombos(await getDefaultCouponCombos(coupon?.yandexDiscountCombos));
    setYandexDiscountModificators(
      await getDefaultCouponModificators(coupon.yandexDiscountModificators),
    );
  };

  useEffect(() => {
    const loadSelectedCoupon = async (params) => {
      if (props.selectedCoupon && props.isOpen === true) {
        props.setLoading(true);
        await mapData(await props.getCouponById(params));
        props.setLoading(false);
      }
    };
    loadSelectedCoupon(props.selectedCoupon?.id);
  }, [props.selectedCoupon, props.isOpen]);

  useEffect(() => {
    const renderLoadedCoupon = async (params) => {
      if (props?.loadedCoupon && props.isOpen) {
        props.setLoading(true);
        await mapData(params);
        props.setLoading(false);
      }
    };
    renderLoadedCoupon(props?.loadedCoupon);
  }, [props?.loadedCoupon, props.isOpen]);

  useEffect(() => {
    if (code?.length > 0 && defaultState?.code !== code && props.isOpen === true) {
      const check = async (codeToCheck) => {
        try {
          const res = await CouponService.checkExisting(codeToCheck);
          setCodeError(res?.exist);
        } catch {}
      };
      check(code);
    }
  }, [code, props.isOpen]);

  useEffect(() => {
    if (props.isOpen === false) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  const isValidArrayAdd =
    (!!yandexDiscountProducts?.length ||
      !!yandexDiscountCombos?.length ||
      !!yandexDiscountModificators?.length) &&
    !!selectedRestaurants?.length;

  const isValidArrayRemove =
    !!yandexDiscountProducts?.length ||
    !!yandexDiscountCombos?.length ||
    !!yandexDiscountModificators?.length;

  const isValidArray = props?.isEdit ? isValidArrayRemove : isValidArrayAdd;

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => props.onClose()}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges({
              id: props?.id,
              forAll: forAll,
              type: type,
              groupRestaurants: selectedRestaurants,
              restaurantId: props.currentRestaurant.id,
              name: name,
              discount: +discount,
              yandexDiscountProducts: yandexDiscountProducts.reduce(
                (acc, el) => [
                  ...acc,
                  {
                    productId: el.posId,
                  },
                ],
                [],
              ),
              yandexDiscountCombos: yandexDiscountCombos.reduce(
                (acc, el) => [
                  ...acc,
                  {
                    comboId: el.posId,
                  },
                ],
                [],
              ),
              yandexDiscountModificators: yandexDiscountModificators.reduce(
                (acc, el) => [
                  ...acc,
                  {
                    modificatorId: el.posId,
                  },
                ],
                [],
              ),
            });
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    {!props?.isEdit && (
                      <Select
                        className="container_fullwidth_one_first"
                        options={props.TYPES}
                        value={type}
                        size="large"
                        onChange={setType}
                        label={`${STRINGS.screens.coupons.typeEXT} *`}
                        name="type"
                      />
                    )}

                    <Input
                      className="container_fullwidth_one"
                      label={STRINGS.screens.yandexDiscounts.discountName}
                      value={name}
                      onChange={setName}
                      inputType="default"
                      onFocus={() => changeFocus('firstname', true)}
                      onBlur={() => changeFocus('firstname', false)}
                    />
                    {type !== 'GIFT' && props.loadedCoupon?.type !== 'GIFT' && (
                      <Input
                        className="container_fullwidth_one"
                        fullWidth
                        type="number"
                        inputType={
                          !isFocus?.discount && !validation?.data?.discount?.isValid
                            ? 'danger'
                            : 'default'
                        }
                        variant="outlined"
                        value={discount}
                        onChange={setDiscount}
                        label={
                          type === 'FIXED' || props.loadedCoupon?.type === 'FIXED'
                            ? STRINGS.screens.coupons.qd
                            : STRINGS.screens.coupons.qf
                        }
                        onFocus={() => changeFocus('discount', true)}
                        onBlur={() => changeFocus('discount', false)}
                        name="discount"
                      />
                    )}
                  </div>
                </>
              )}
              {step === 2 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.coupons.qg}</span>
                  </div>
                  <CustomSelect
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.coupons.qh}`}
                    data={products}
                    value={productsQuery}
                    multiple={false}
                    onChange={setProductsQuery}
                    inputType="default"
                    selectedOptions={yandexDiscountProducts}
                    setSelectedOptions={setYandexDiscountProducts}
                    name="products"
                  />
                </div>
              )}
              {step === 3 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.coupons.qj}</span>
                  </div>
                  <CustomSelect
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.coupons.qk}`}
                    data={combos}
                    value={combosQuery}
                    multiple={false}
                    onChange={setCombosQuery}
                    selectedOptions={yandexDiscountCombos}
                    inputType="default"
                    setSelectedOptions={setYandexDiscountCombos}
                    name="combos"
                  />
                </div>
              )}
              {step === 4 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.coupons.ql}</span>
                  </div>
                  <CustomSelect
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.coupons.qz}`}
                    data={modificators}
                    multiple={false}
                    value={modificatorsQuery}
                    onChange={setModificatorsQuery}
                    selectedOptions={yandexDiscountModificators}
                    inputType="default"
                    setSelectedOptions={setYandexDiscountModificators}
                    name="modificators"
                  />
                </div>
              )}
              {step === 5 && !props?.isEdit && (
                <div className="container_fullwidth">
                  <Checkbox
                    className="check"
                    label="Отправить во все рестораны"
                    checked={forAll}
                    onChange={() => setForAll((prev) => !prev)}
                  />
                  {!forAll && (
                    <CustomSelect
                      className="container_fullwidth_one_first"
                      label={STRINGS.screens.restaurants.qq}
                      data={restaurants}
                      value={query}
                      inputType="default"
                      onChange={setQuery}
                      selectedOptions={selectedRestaurants}
                      setSelectedOptions={setSelectedRestaurants}
                      multiple={false}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          <Separator indent={80} />
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                className="button"
                disabled={!(validation.isValid && isValidArray)}
                label={STRINGS.screens.modal.save}
                size="large"
                type={validation.isValid && isValidArray ? 'primary' : 'default'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default YandexDiscount;

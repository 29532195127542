import { Icon } from '../../../ui-kit';
import './styles.scss';
import FadeLoader from 'react-spinners/FadeLoader';

const SplashPage = () => {
    return (
        <div className="splash-page">
            <Icon name="logo" width={300} height={80} />
            <div className="spinner">
                <FadeLoader
                    color="black"
                    loading={true}
                    css={{
                        opacity: '0.2',
                    }}
                />
            </div>

        </div>

    );
}

export default SplashPage;
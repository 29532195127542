/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../loader';
import { Button, Form, Input, Drawer } from '../../../ui-kit';
import YandexMap from '../../yandex-map';
import STRINGS from '../../../../localization';
import { validateObject } from '../../../utils/input-functions';
import { COORDINATES_REGEX } from './../../../../constants/regExp';

const CityFields = (props) => {
  const maxStep = 2;

  const [id, setId] = useState();

  const [isModalOpen, setIsModalOpen] = useState('');

  const [coordinates, setCoordinates] = useState('');

  const [titleLocation, setTitleLocation] = useState('');

  const [selectedLanguage, setSelectedLanguage] = useState(1);

  const [step, setStep] = useState(1);

  const [cityData, setCityData] = useState([
    { languageId: 1, name: '' },
    { languageId: 2, name: '' },
    { languageId: 3, name: '' },
  ]);

  const titles = cityData.reduce((acc, el, key) => ({ ...acc, [`title${key}`]: el.name }), []);

  const SCHEME = {
    coordinates: (value) => COORDINATES_REGEX.test(value),
    title0: (value) => value.length > 0,
    title1: (value) => value.length > 0,
    title2: (value) => value.length > 0,
  };

  const prepareCityData = (cityDataPatched) => {
    return cityData.map((el, key) => ({
      languageId: el?.languageId,
      name: cityDataPatched[key]?.name ? cityDataPatched[key]?.name : '',
    }));
  };
  const [validation, setValidation] = useState();

  const [isFocus, setIsFocus] = useState({
    coordinates: true,
    title0: true,
    title1: true,
    title2: true,
  });

  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };

  const onClose = () => {
    setIsModalOpen('');
  };

  useEffect(() => {
    setValidation(validateObject(Object.assign(titles, { coordinates }), SCHEME));
  }, [cityData, coordinates]);

  useEffect(async () => {
    if (props.selectedCity?.id && props.isOpen === true) {
      props.setLoading(true);
      const city = await props.getCityById(props.selectedCity?.id);
      setId(props.selectedCity?.id);
      if (city?.lng && city?.lat) {
        setCoordinates([city?.lat, city?.lng].join(' '));
      }
      setCityData(prepareCityData(city?.cityData));
    }
    props.setLoading(false);
  }, [props.selectedCity, props.isOpen]);

  const initialize = () => {
    setStep(1);
    setCoordinates('');
    setIsFocus({
      coordinates: true,
      title0: true,
      title1: true,
      title2: true,
    });
    setCityData([
      { languageId: 1, name: '' },
      { languageId: 2, name: '' },
      { languageId: 3, name: '' },
    ]);
  };

  useEffect(() => {
    if (props.isOpen === false) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => {
        setStep(1);
        props.onClose();
      }}
    >
      <YandexMap
        setCoordinates={setCoordinates}
        coordinates={coordinates}
        titleLocation={titleLocation}
        setTitleLocation={setTitleLocation}
        isOpen={isModalOpen === 'map'}
        onClose={onClose}
        kind="locality"
        title={STRINGS.screens.cities.geo}
        onBlur={() => setIsModalOpen('')}
      />
      <div className="modal">
        <Form
          onSubmit={() =>
            props.saveChanges({
              id: id,
              cityData: cityData,
              lng: coordinates.split(' ')[0],
              lat: coordinates.split(' ')[1],
            })
          }
        >
          <div className="header-nosteps">
            <div className="title">
              <span>{props.title}</span>
            </div>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <div className="body">
              <div className="container_fullwidth">
                <div className="subtitle">
                  <span>{STRINGS.screens.cities.geo} *</span>
                </div>
                <Input
                  fullWidth
                  variant="outlined"
                  label={STRINGS.screens.cities.searchGeo}
                  value={coordinates}
                  onChange={setCoordinates}
                  inputType={
                    !isFocus.coordinates && !validation?.data?.coordinates?.isValid
                      ? 'danger'
                      : 'default'
                  }
                  dataIconPointer={true}
                  rightIcon="location"
                  maxLength={40}
                  iconClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen('map');
                  }}
                  onFocus={() => changeFocus('coordinates', true)}
                  onBlur={() => changeFocus('coordinates', false)}
                />
              </div>
              <div className="container_fullwidth">
                <div className="subtitle">
                  <span>{STRINGS.screens.modal.langs}</span>
                </div>
                <div className="langs">
                  {props.languages?.map((language) => (
                    <Button
                      label={language.name}
                      value={language.id}
                      size="medium"
                      className="lang"
                      type={selectedLanguage === language.id ? 'primary' : 'default'}
                      data-active={selectedLanguage === language.id}
                      onClick={(e) => {
                        setSelectedLanguage(language.id);
                        setIsFocus({
                          [`title${language.id - 1}`]: true,
                        });
                        e.preventDefault();
                      }}
                    >
                      <span className="day-label">{language.name}</span>
                    </Button>
                  ))}
                </div>
                <Input
                  className="container_fullwidth_one_first"
                  fullWidth
                  onChange={(val) => {
                    let temp = [...cityData];
                    temp[selectedLanguage - 1].name = val;
                    setCityData(temp);
                  }}
                  value={cityData[selectedLanguage - 1].name}
                  label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
                  inputType={
                    !isFocus?.[`title${selectedLanguage - 1}`] &&
                    !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                      ? 'danger'
                      : 'default'
                  }
                  onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                  onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                />
              </div>
            </div>
          )}
          <div className="footer">
            <Button
              disabled={!validation?.isValid}
              className="button"
              label={STRINGS.screens.modal.save}
              size="large"
              type={!validation?.isValid ? 'disabled' : 'primary'}
              typeButton="submit"
              loading={props.loading}
            />
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

CityFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
CityFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default CityFields;

/**
 * @prettier
 */

import React from 'react';

import { Icon, Confirm, Table, Tooltip, SecondaryTabs, Button } from '../../ui-kit';
import Fields from '../../components/modals/category-fields';
import Skelet from './skelet';
import { Head } from '../../components';
import { allCategories } from '../../../constants/categories';
import NOPHOTO from '../../../assets/nophoto.png';
import STRINGS from '../../../localization';
import { translateStatus } from '../../../main/utils/translate-status';
import './styles.scss';
import { URL } from '../../../services/config';

const View = (props) => {
  return (
    <>
      <Fields
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.categories.AJ}
        selectedCategory={props.selectedCategory}
        saveChanges={props.editCategory}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Fields
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'add'}
        title={STRINGS.screens.categories.AK}
        saveChanges={props.addCategory}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={`${STRINGS.screens.categories.AI}`}
        subtitleBold={props.selectedCategory?.title}
        buttons={[
          {
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteCategory(),
          },
          {
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
            },
          },
        ]}
      />

      <Head
        label={STRINGS.screens.categories.AA}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.categories.AB,
          onClick: () => props.setShowModal('add'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.categories.AC,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          <div className="toolbar">
            <SecondaryTabs
              data={allCategories}
              selected={props.selectedTab}
              setSelected={props.setSelectedTab}
            />
          </div>
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              head={() => (
                <tr className="summary_table_heading">
                  <th />
                  <th>{STRINGS.screens.categories.AO}</th>
                  <th>{STRINGS.screens.categories.AD}</th>
                  <th>{STRINGS.screens.categories.AE}</th>
                  <th>{STRINGS.screens.coupons.status}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.categories?.map((item, key) => (
                  <tr className="summary_table_body unselectable">
                    <td>
                      {item?.fileId ? (
                        <img
                          className="image"
                          style={{ width: '100px' }}
                          src={`${URL}files/${item?.fileId}`}
                        />
                      ) : (
                        <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                      )}
                    </td>
                    <td>{key + 1}</td>
                    <td>{item?.id}</td>
                    <td>{item?.title}</td>
                    <td>
                      <Button
                        className="outline"
                        size="large"
                        label={translateStatus(item?.status)?.status}
                        type={translateStatus(item?.status)?.button}
                        typeButton="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.changeStatus({ id: item?.id, status: item?.status });
                        }}
                      />
                    </td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setSelectedCategory({
                                id: item?.id,
                                title: item.title,
                              });
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.edit}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={() => {
                              props.setSelectedCategory({ id: item?.id });
                              props.setShowModal('edit');
                            }}
                          >
                            <Icon name="edit" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default View;

/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import './styles.scss';
import colors from '../../../styles/colors';

import { SelectLanguage } from '..';
import { Icon } from '../../../ui-kit';

import { debug } from '../../../services';
import CurrentUserThunks from '../../../store/current-user/thunks';

/**
 * @component Header
 * @example
 * <Header
 *  onClick={() => {}}
 *  burgerState={false}
 * />
 */
const Header = (props) => {
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const selectedLanguage = useSelector((store) => store.localization.currentLanguage);

  const renderClassName = () => {
    return `language-image ${selectedLanguage}`;
  };

  const logOut = () => {
    dispatch(CurrentUserThunks.logout());
  };

  useEffect(() => {
    let delayDebounceFn = null;

    delayDebounceFn = setTimeout(async () => {
      debug.info('Header input text', searchValue);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return (
    <>
      <header id="--header-root">
        <div className="burger">
          <button onClick={() => props.onClick(!props.burgerState)}>
            <Icon name="burger" width={22} height={22} color={colors.color_gray._4} />
          </button>
        </div>
        <div className="content-restaurant">
          <div className="actions">
            <button
              className="language"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              <div className={renderClassName()} />
            </button>
            <button className="bell" onClick={() => null}>
              <div className="bell-image">
                <div className="bell-circle">
                  <p className="circle-text">12</p>
                </div>
              </div>
            </button>
            <div className="vl" />
            <button className="logout" onClick={logOut}>
              <Icon name="logout" width={22} height={22} color={colors.color_brown._0} />
            </button>
          </div>
        </div>
      </header>
      <SelectLanguage isOpen={openDialog} close={() => setOpenDialog(false)} />
    </>
  );
};

/**
 * Properties
 */

Header.propTypes = {
  onClick: PropTypes.func,
  burgerState: PropTypes.bool,
};
Header.defaultProps = {
  onClick: () => {},
  burgerState: false,
};

export default Header;

import React from 'react';
import colors from '../../styles/colors';
import { Icon, Confirm, Table, Tooltip, Button } from '../../ui-kit';
import DiscountFields from '../../components/modals/discount-fields';
import Skelet from './skelet';
import { translateStatus } from '../../../main/utils/translate-status';
import { Head } from '../../components';
import NOPHOTO from '../../../assets/nophoto.png';

import STRINGS from '../../../localization';
import { getBeautyDateString } from '../../utils/datetime';
import './styles.scss';
import { URL } from '../../../services/config';

const DiscountsView = (props) => {
  const renderClassNameSort = (type) => {
    let str = 'summary_table_heading_sort';
    if (type === props.sort.type) {
      str += ' summary_table_heading_sort_active';
    }
    return str;
  };

  return (
    <>
      <DiscountFields
        mindboxPromotions={props.mindboxPromotions}
        langId={props.langId}
        getProductsAutocomplete={props.getProductsAutocomplete}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'editDiscount'}
        title={STRINGS.screens.discounts.editDiscount}
        selectedDiscount={props.selectedDiscount}
        getDiscountById={props.getDiscountById}
        getFileById={props.getFileById}
        saveChanges={props.saveChanges}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={`${STRINGS.screens.discounts.question}`}
        subtitleBold={props.selectedDiscount?.title}
        buttons={[
          {
            type: 'danger',
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteDiscount(),
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              setTimeout(() => props.setSelectedDiscount(), 100);
              props.setShowConfirm('');
            },
          },
        ]}
      />

      <Head
        label={STRINGS.screens.discounts.discounts}
        button={false}
        secondButton={{
          size: 'medium',
          icon: false,
          label: STRINGS.screens.discounts.import,
          outline: true,
          loading: props.importLoading,
          onClick: props.importPromotions,
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.discounts.discounts,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              head={() => (
                <tr className="summary_table_heading">
                  <th />
                  <th>{STRINGS.screens.discounts.title}</th>
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>{STRINGS.screens.discounts.startDate}</span>
                      <span
                        onClick={() => props.applySort('StartDate')}
                        className={renderClassNameSort('StartDate')}
                      >
                        <div
                          className={
                            props.sort.sort === 'desc' && props.sort.type === 'StartDate'
                              ? 'summary_table_heading_arrow'
                              : 'summary_table_heading_arrow_active'
                          }
                        >
                          <Icon
                            width={16}
                            height={16}
                            color={colors.color_gray._3}
                            name="arrow-bottom"
                          />
                        </div>
                      </span>
                    </div>
                  </th>
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>{STRINGS.screens.discounts.expirationDate}</span>
                      <span
                        onClick={() => props.applySort('ExpirationDate')}
                        className={renderClassNameSort('ExpirationDate')}
                      >
                        <div
                          className={
                            props.sort.sort === 'desc' && props.sort.type === 'ExpirationDate'
                              ? 'summary_table_heading_arrow'
                              : 'summary_table_heading_arrow_active'
                          }
                        >
                          <Icon
                            width={16}
                            height={16}
                            color={colors.color_gray._3}
                            name="arrow-bottom"
                          />
                        </div>
                      </span>
                    </div>
                  </th>
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>{STRINGS.screens.modal.status}</span>
                      <span
                        onClick={() => props.applySort('Status')}
                        className={renderClassNameSort('Status')}
                      >
                        {props.sort.sort === 'desc' && props.sort.type === 'Status'
                          ? 'Z - a'
                          : 'A - z'}
                      </span>
                    </div>
                  </th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.discounts?.data?.map((item) => (
                  <tr
                    key={item?.id}
                    className="summary_table_body"
                    onClick={async (event) => {
                      event.stopPropagation();
                      props.history.push(`promotions/${item.id}/`, {
                        title: item?.title,
                        status: item?.status,
                      });
                    }}
                  >
                    <td>
                      {item?.fileId ? (
                        <img
                          className="image"
                          style={{ width: '100px' }}
                          src={`${URL}files/${item?.fileId}`}
                        />
                      ) : (
                        <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                      )}
                    </td>
                    <td>{item?.title}</td>
                    <td>{getBeautyDateString(new Date(item?.startDate))}</td>
                    <td>{getBeautyDateString(new Date(item?.expirationDate))}</td>
                    <td>
                      <Button
                        className="outline"
                        size="large"
                        label={translateStatus(item?.status).status}
                        type={translateStatus(item?.status).button}
                        typeButton="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.changeStatus({ id: item?.id, status: item?.status });
                        }}
                      />
                    </td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(event) => {
                              event.stopPropagation();
                              props.setSelectedDiscount({ id: item?.id, title: item.title });
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.edit}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={async (event) => {
                              event.stopPropagation();
                              props.setSelectedDiscount({ id: item?.id });
                              props.setShowModal('editDiscount');
                            }}
                          >
                            <Icon name="edit" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default DiscountsView;

import React from 'react';
import './styles.scss';

const Block = ({ title, children }) => (
  <div className="block">
    <div className="subtitle">
      <span>{title}</span>
    </div>
    {children}
  </div>
);

export default Block;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../';
import './styles.scss';

/**
 * @component Button
 * @example
 * <Button
 *  size="medium"
 *  type="primary"
 *  outline={true}
 *  label="Primary"
 *  onClick={() => {}}
 * />
 */
const CustomButton = (props) => {
  return (
    <div className="wrapper">
      <button
        onClick={props.onClick}
        disabled={props.disabled || props.loading}
        className={props.className ? props.className : 'button-submit'}
        type={props.typeButton}
      >
        {props.disabled ? <Loader /> : props.label}
      </button>
    </div>
  );
};

/**
 * Properties
 */

CustomButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'disabled']),
  outline: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,

  icon: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    side: PropTypes.oneOf(['left', 'right']),
  }),

  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  typeButton: PropTypes.string,
  className: PropTypes.string,
};

CustomButton.defaultProps = {
  size: 'large',
  type: 'primary',
  outline: false,
  label: null,
  onClick: () => {},
  icon: {
    name: null,
    side: 'left',
  },
  loading: false,
  disabled: false,
  typeButton: 'button',
  className: '',
};

export default CustomButton;

import React, { useEffect, useState } from 'react';

import View from './view';
import { Toast } from '../../ui-kit';
import AggregatorService from '../../../services/aggregator';
import ChocofoodService from '../../../services/chocofood';
import WoltService from '../../../services/wolt';
import YandexService from '../../../services/yandex';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import STRINGS from '../../../localization';

const MenuAggregatorContainer = () => {
  const SIZE = 15;

  const history = useHistory();

  const [sendToAggregatorResponse, setSendToAggregatorResponse] = useState();

  const { languages, aggregators, categories } = useSelector((store) => store.stuff);

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [sendMenuToAggregatorLoading, setSendMenuToAggregatorLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [toggleEdit, setToggleEdit] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState();

  const [bottomLoading, setBottomLoading] = useState(false);

  const [autocomplete, setAutocomplete] = useState([]);

  const [selectedAggregatorCategory, setSelectedAggregatorCategory] = useState('products');

  const [page, setPage] = useState(1);

  const [aggregatorId, setAggregatorId] = useState('');

  const [isLast, setIsLast] = useState(false);

  const [showModal, setShowModal] = useState('');

  const [showConfirm, setShowConfirm] = useState('');

  const [skeletVisible, setSkeletVisible] = useState(false);

  const [existingMenu, setExistingMenu] = useState();

  const CATEGORIES = categories?.reduce((acc, el) => [...acc, { label: el.title, key: el.id }], []);

  let { id } = useParams();

  const [aggregatorName, setAggregatorName] = useState('');

  const [isAggregatorId, setIsAggregatorId] = useState(false);

  const aggregatorsList = { ['glovo']: 1, ['chocofood']: 2, ['yandex']: 3, ['wolt']: 4 };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await getMenu({
        langId: langId,
        page: page + 1,
        size: SIZE,
        existing: true,
        category: selectedAggregatorCategory,
        restaurantId: parseInt(id, 10),
        aggregator: aggregatorName,
        search: '',
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setExistingMenu({
        isLast: resp.isLast,
        data: [...existingMenu?.data, ...resp?.data],
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  const sendMenuToAggregator = async () => {
    setSendMenuToAggregatorLoading(true);
    try {
      const resp = await AggregatorService.sendToAggregator({
        aggregatorName: aggregatorName,
        restaurantId: id,
      });
      setSendToAggregatorResponse(resp);
      return resp;
    } catch (error) { }
    setSendMenuToAggregatorLoading(false);
  };

  const uploadToAggregator = async (f) => {
    setLoading(true);
    setSendMenuToAggregatorLoading(true);
    try {
      await AggregatorService.getAggregators();
      let dataToSend = {
        restaurantId: id,
        aggregatorName: aggregatorName,
        data: f,
      };
      let dataToChocofood = {
        data: {
          restaurantIds: f.restaurants.map((el) => el.restaurantId),
          forAll: f.forAll,
        },
        restaurantId: id,
      };
      let dataToWolt = {
        data: {
          forAll: f.forAll,
          restaurants: f.restaurants.map((el) => ({restaurantId: el.restaurantId})),
        },
        restaurantId: id,
      };
      let dataToYandex = {
        data: {
          forAll: f.forAll,
          restaurants: f.restaurants.map((el) => ({restaurantId: el.restaurantId})),
        },
        restaurantId: id,
      };
      if (aggregatorName === 'chocofood') {
        await ChocofoodService.sendMenu(dataToChocofood);
      }
      if (aggregatorName === 'glovo') {
        await AggregatorService.uploadToGlovo(dataToSend);
      }
      if (aggregatorName === 'wolt') {
         await WoltService.sendMenu(dataToWolt);
        //await AggregatorService.uploadToAggregator({aggregatorName: aggregatorName,restaurantId: id});
      }
      if (aggregatorName === 'yandex') {
         await YandexService.sendMenu(dataToYandex);
      }
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
      }
    }
    setSendMenuToAggregatorLoading(false);
    setLoading(false);
    setShowModal('');
  };

  const getMenu = async (props) => {
    try {
      const resp = await AggregatorService.getMenu(props);
      return resp;
    } catch {
    }
  };

  const getAggregatorsId = async (props) => {
      try {
        const resp = await AggregatorService.getAggregatorsId(props);
        if (resp) {
          setAggregatorId(resp?.idInAggregator);
          setIsAggregatorId(true);
        } else {
          setAggregatorId('');
          setIsAggregatorId(false);
        }
      } catch (error) {
        if (error.message === 'Id not found') {
          await postAggregatorsId();
          const resp = await AggregatorService.getAggregatorsId(props);
        }
      }
  };

  const postAggregatorsId = async () => {
    try {
      setAggregatorId(aggregatorId.trim());
      await AggregatorService.postAggregatorsId({
        id: aggregatorsList[aggregatorName],
        restaurantId: parseInt(id, 10),
        data: { idInAggregator: aggregatorId.trim() },
      });
      setIsAggregatorId(true);
    } catch (err) { }
  };
  const putAggregatorsId = async () => {
    try {
      setAggregatorId(aggregatorId.trim());
      await AggregatorService.putAggregatorsId({
        id: aggregatorsList[aggregatorName],
        restaurantId: parseInt(id, 10),
        data: { idInAggregator: aggregatorId.trim() },
      });
    } catch (err) {}
  };

  const handleDeleteProduct = async () => {
    const dataName = `${selectedAggregatorCategory.includes('products') ? 'product' : selectedAggregatorCategory
      }IdList`;
    try {
      await AggregatorService.deleteProducts({
        category: selectedAggregatorCategory,
        restaurantId: id,
        aggregator: aggregatorName,
        data: {
          [dataName]: [selectedProduct.id],
        },
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setExistingMenu((prev) => ({
      isLast: prev.isLast,
      data: prev.data.filter((el) => el.posId !== selectedProduct.id),
    }));
    setShowConfirm('');
  };

  const addAndDeleteProducts = async (f) => {
    setLoading(true);
    const dataName = `${selectedAggregatorCategory.includes('products') ? 'product' : selectedAggregatorCategory
      }IdList`;
    if (f.productsToDelete.length > 0) {
      try {
        await AggregatorService.deleteProducts({
          category: selectedAggregatorCategory,
          restaurantId: id,
          data: {
            [dataName]: [...f.productsToDelete.map((el) => el.posId)],
          },
          aggregator: aggregatorName,
        });
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
    if (f.productsToAdd.length > 0) {
      try {
        await AggregatorService.addProducts({
          category: selectedAggregatorCategory,
          restaurantId: id,
          data: {
            [dataName]: f.productsToAdd.map((el) => el.posId),
          },
          aggregator: aggregatorName,
        });
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
    Toast.success(STRINGS.notify.success);
    setPage(1);
    setSkeletVisible(true);
    const menu = await getMenu({
      category: selectedAggregatorCategory,
      langId: langId,
      page: 1,
      size: SIZE,
      existing: true,
      restaurantId: parseInt(id,10),
      aggregator: aggregatorName,
      search: '',
    });
    selectedAggregatorCategory === 'combo' && setShowModal('');
    setExistingMenu({ isLast: menu.isLast, data: menu.data });
    setLoading(false);
    setSkeletVisible(false);
  };

  useEffect(async () => {
    setSkeletVisible(true);
    setToggleEdit(false);
    if (aggregatorName && selectedAggregatorCategory) {
        await getAggregatorsId({ id: aggregatorsList[aggregatorName], restaurantId: parseInt(id,10) });
        if (aggregatorName.length > 0) {
          setPage(1);
          const menu = await getMenu({
            category: selectedAggregatorCategory,
            langId: langId,
            page: 1,
            size: SIZE,
            existing: true,
            restaurantId: parseInt(id, 10),
            aggregator: aggregatorName,
            search: '',
          });
          if (menu) {
            setExistingMenu({ isLast: menu.isLast, data: menu.data });
          } else {
            setExistingMenu({ isLast: true, data: [] });
          }
        }
    }
    setSkeletVisible(false);
  }, [aggregatorName, selectedAggregatorCategory]);

  useEffect(() => {
    if (aggregators.length > 0) {
      setAggregatorName(aggregators.find((el) => el.id === 1).key);
    }
  }, [aggregators]);

  return (
    <View
      history={history}
      sendToAggregatorResponse={sendToAggregatorResponse}
      toggleEdit={toggleEdit}
      postAggregatorsId={postAggregatorsId}
      putAggregatorsId={putAggregatorsId}
      setToggleEdit={setToggleEdit}
      sendMenuToAggregatorLoading={sendMenuToAggregatorLoading}
      sendMenuToAggregator={sendMenuToAggregator}
      restaurantId={id}
      saveChanges={addAndDeleteProducts}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      autocomplete={autocomplete}
      setAutocomplete={setAutocomplete}
      aggregatorName={aggregatorName}
      setAggregatorName={setAggregatorName}
      CATEGORIES={CATEGORIES}
      aggregators={aggregators}
      getMenu={getMenu}
      isAggregatorId={isAggregatorId}
      aggregatorId={aggregatorId}
      setAggregatorId={setAggregatorId}
      bottomLoading={bottomLoading}
      existingMenu={existingMenu}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      selectedAggregatorCategory={selectedAggregatorCategory}
      setSelectedAggregatorCategory={setSelectedAggregatorCategory}
      handleDeleteProduct={handleDeleteProduct}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      loading={loading}
      setLoading={loading}
      skeletVisible={skeletVisible}
      uploadToAggregator={uploadToAggregator}
    />
  );
};

export default MenuAggregatorContainer;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Confirm } from '../../ui-kit';
import StoryFields from '../../components/modals/story-fields';
import { Head } from '../../components';
import STRINGS from '../../../localization';
import Card from './card';
import './styles.scss';
import StoryHolder from './storyholder';

const StoriesView = (props) => {
  const handleCardClick = async (item) => {
    let temp = [...props.stories?.data];
    let selected = temp.find((el) => el?.id === item?.id);
    selected.loading = true;
    await props.setStories((old) => ({ isLast: old.isLast, data: temp }));
    const data = await props.getStoryById(item?.id);
    selected = Object.assign(selected, data);
    selected.loading = false;
    selected.open = !selected.open;
    await props.setStories((old) => ({ isLast: old.isLast, data: temp }));
  };
  return (
    <>
      <StoryFields
        loading={props.loading}
        setLoading={props.setLoading}
        backgroundColors={props.backgroundColors}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'add'}
        title={STRINGS.screens.stories.addStory}
        saveChanges={props.addStory}
        languages={props.languages}
        storyTypes={props.storyTypes}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.stories.delete}
        subtitleBold={props.deletableStory?.title}
        buttons={[
          {
            type: 'danger',

            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteStory(),
          },
          {
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            type: 'default',

            onClick: () => {
              setTimeout(() => props.setEditableStory(), 100);
              props.setShowConfirm('');
            },
          },
        ]}
      />

      <Head
        label={STRINGS.screens.stories.stories}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.stories.addStory,
          onClick: () => props.setShowModal('add'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.stories.stories,
          },
        ]}
      />
      <div>
        {props.skeletVisible ? (
          <StoryHolder text="Загрузка..." />
        ) : (
          <>
            {props.stories?.data?.length === 0 ? (
              <StoryHolder text="Пусто" mode="empty" />
            ) : (
              <div className="cards">
                {props.stories?.data?.map((story) => (
                  <Card
                    story={story}
                    handleCardClick={handleCardClick}
                    setDeletableStory={props.setDeletableStory}
                    setShowConfirm={props.setShowConfirm}
                    setShowModal={props.setShowModal}
                    langId={props.langId}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

StoriesView.propTypes = {
  languages: PropTypes.array,
  cities: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableCity: PropTypes.object,
  setEditableCity: PropTypes.func,
  saveChanges: PropTypes.func,
};

StoriesView.defaultProps = {
  languages: [],
  cities: null,
  showModal: '',
  setShowModal: () => {},
  editableCity: {},
  setEditableCity: () => {},
  saveChanges: () => {},
};

export default StoriesView;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Skeleton, Status } from '../../ui-kit';

import './styles.scss';
import { Link } from 'react-router-dom';

/**
 * @component Head
 * @example
 * <Head
 *  label="Арендодатели"
 *  breadCrumbs=[
 *    {
 *      label: Manage,
 *      link: '/'
 *    },
 *    {
 *      label: 'Landlords'
 *    }
 *  ]
 * />
 */
const Head = (props) => {
  const renderBreadCrumb = () => {
    const newCrumbArr = [];
    const SEPARATOR_SYMBOL = '/';
    for (let i = 0; i < props.breadCrumbs.length; i += 1) {
      if (i === props.breadCrumbs.length - 1) {
        newCrumbArr.push(props.breadCrumbs[i]);
      } else {
        newCrumbArr.push(props.breadCrumbs[i]);
        newCrumbArr.push({ label: SEPARATOR_SYMBOL });
      }
    }
    return newCrumbArr.map((item, index) => {
      if (index === newCrumbArr.length - 1) {
        return (
          <span key={index} className="current">
            {item.label}
          </span>
        );
      }
      if (index % 2 === 1) {
        return (
          <span key={index} className="separator">
            {SEPARATOR_SYMBOL}
          </span>
        );
      }
      return (
        <Link key={index} to={item.link} className="past">
          {item.label}
        </Link>
      );
    });
  };

  return (
    <div id="--head-root">
      <div>
        {props.skelet ? (
          <Skeleton width={props.label ? 'max-content' : 240}>
            {props.label && <h1>{props.label}</h1>}
          </Skeleton>
        ) : (
          <div className="head-title">
            <h1>{props.label}</h1>
            <Status value={props.status} />
          </div>
        )}

        <div className="buttons">
          {props.button && (
            <>
              {props.skelet ? (
                <Skeleton width="max-content">
                  <Button type={props.loading && 'disabled'} {...props.button} />
                </Skeleton>
              ) : (
                <Button
                  {...props.button}
                  size="medium"
                  type={props.loading ? 'disabled' : 'primary'}
                />
              )}
            </>
          )}
          {props.secondButton && (
            <>
              {props.skelet ? (
                <Skeleton width="max-content">
                  <Button type={props.loading && 'disabled'} {...props.secondButton} />
                </Skeleton>
              ) : (
                <Button {...props.secondButton} />
              )}
            </>
          )}
        </div>
      </div>
      {props.breadCrumbs && (
        <>
          {props.skelet ? (
            <Skeleton width="max-content">
              <div className="breadcrumbs">{renderBreadCrumb()}</div>
            </Skeleton>
          ) : (
            <div className="breadcrumbs">{renderBreadCrumb()}</div>
          )}
        </>
      )}
    </div>
  );
};

/**
 * Properties
 */

Head.propTypes = {
  /**
   * Body header text
   */
  label: PropTypes.string,

  /**
   * Buttons props
   */
  button: PropTypes.any,

  /**
   * Displaying breadcrumbs
   */
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      link: PropTypes.string,
    }),
  ),

  /**
   * Disable buttons in case of loading
   */
  loading: PropTypes.bool,

  /**
   * Displaying the skeleton of a component
   */
  skelet: PropTypes.bool,
};
Head.defaultProps = {
  label: null,
  button: {},
  breadCrumbs: [],
  loading: false,
  skelet: false,
};

export default Head;

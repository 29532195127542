import GlovoService from '../../../services/glovo';
import ChocofoodService from '../../../services/chocofood';
import WoltService from '../../../services/wolt';
import YandexService from '../../../services/yandex';
import STRINGS from '../../../localization';
import {useDispatch, useSelector} from 'react-redux';
import AggregatorsThunks from '../../../store/aggregators/thunks';

import './closeAggregator.scss';

export const CloseAggregator = ({aggregatorName, restaurantId}) => {
    const dispatch = useDispatch();
    const {glovo, chocofood, wolt, yandex} = useSelector((store) => store.aggregators);

    const toDateOffset = (date) => {
        var tzo = -date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };

        return (
            date.getFullYear() +
            '-' +
            pad(date.getMonth() + 1) +
            '-' +
            pad(date.getDate()) +
            'T' +
            pad(date.getHours()) +
            ':' +
            pad(date.getMinutes()) +
            ':' +
            pad(date.getSeconds()) +
            dif +
            pad(tzo / 60) +
            ':' +
            pad(tzo % 60)
        );
    };

    const closeGlovo = async () => {
        try {
            const current = new Date();
            current.setDate(current.getDate() + 1);

            const dateOffset = toDateOffset(current);
            await GlovoService.changeClosing(restaurantId, {until: dateOffset});
            await dispatch(AggregatorsThunks.glovoClosing(restaurantId));
        } catch (err) {
        }
    };

    const openGlovo = async () => {
        try {
            await GlovoService.deleteClosing(restaurantId);
            await dispatch(AggregatorsThunks.glovoClosing(restaurantId));
        } catch (err) {
        }
    };

    const closeChoco = async () => {
        try {
            await ChocofoodService.putClosing({
                restaurantId: restaurantId,
                isActive: false,
            });
            await dispatch(AggregatorsThunks.chocofoodClosing(restaurantId));
        } catch (err) {
        }
    };

    const openChoco = async () => {
        try {
            await ChocofoodService.putClosing({
                restaurantId: restaurantId,
                isActive: true,
            });
            await dispatch(AggregatorsThunks.chocofoodClosing(restaurantId));
        } catch (err) {
        }
    };

    const closeWolt = async () => {
        try {
            await WoltService.putClosing({
                restaurantId: restaurantId,
                isActive: 'OFFLINE',
            });
            await dispatch(AggregatorsThunks.woltClosing(restaurantId));
        } catch (err) {
        }
    };

    const openWolt = async () => {
        try {
            await WoltService.putClosing({
                restaurantId: restaurantId,
                isActive: 'ONLINE',
            });
            await dispatch(AggregatorsThunks.woltClosing(restaurantId));
        } catch (err) {
        }
    };

    const closeYandex = async () => {
        try {
            await YandexService.setClosing({
                restaurantId: restaurantId,
                isActive: false,
            });
            await dispatch(AggregatorsThunks.yandexClosing(restaurantId));
        } catch (err) {}
    };

    const openYandex = async () => {
        try {
            await YandexService.setClosing({
                restaurantId: restaurantId,
                isActive: true,
            });
            await dispatch(AggregatorsThunks.yandexClosing(restaurantId));
        } catch (err) {}
    };

    const buttonsData = [
        {
            opened: glovo?.isOpen,
            title: 'glovo',
            callbackOpen: openGlovo,
            callbackClose: closeGlovo,
        },
        {
            opened: chocofood?.isOpen,
            title: 'chocofood',
            callbackOpen: openChoco,
            callbackClose: closeChoco,
        },
        {
            opened: wolt?.isOpen,
            title: 'wolt',
            callbackOpen: openWolt,
            callbackClose: closeWolt,
        },
        {
            opened: yandex?.isOpen,
            title: 'yandex',
            callbackOpen: openYandex,
            callbackClose: closeYandex,
        },
    ];

    return (
        <div className="close-wrapper">
            <p className="close-title">{`Изменить статус для ${aggregatorName.toUpperCase()}`}</p>
            {
                buttonsData.map(buttonItem => {
                    if (aggregatorName === buttonItem.title) {
                        return (
                            <div className="wrapper">
                                <button
                                    onClick={buttonItem.callbackOpen}
                                    disabled={buttonItem.opened}
                                    className={buttonItem.opened ? 'button-aggregator-disabled' : 'button-aggregator-on'}
                                    type={buttonItem.typeButton}
                                >
                                    {STRINGS.components.aggregatorButton.open}
                                </button>
                                <button
                                    onClick={buttonItem.callbackClose}
                                    disabled={!buttonItem.opened}
                                    className={buttonItem.opened ? 'button-aggregator-off' : 'button-aggregator-disabled'}
                                    type={buttonItem.typeButton}
                                >
                                    {STRINGS.components.aggregatorButton.close}
                                </button>
                            </div>
                        );
                    }
                })
            }
        </div>
    );
};

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

export default class AggregatorService {
  static #API_ENDPOINTS = {
    menu: 'delivery-menu',
    aggregators: 'delivery-aggregators',
    weeklyReports: 'delivery-aggregators/reports/weekly',
    dailyReports: 'delivery-aggregators/reports/daily',
    kkmReports: 'delivery-aggregators/reports/kkm',
    ordersReadyReports: 'delivery-aggregators/reports/orders-ready',
    mobileReports: 'delivery-aggregators/reports/mobile',
    giftsMobileReports: 'delivery-aggregators/reports/mobile/gifts',
    pointsMobileReports: 'delivery-aggregators/reports/mobile/points',
    couponsMobileReports: 'delivery-aggregators/reports/mobile/coupons',
    punchCardsMobileReports: 'delivery-aggregators/reports/mobile/punch-cards',
  };

  static API_SETTINGS = {
    size: 30,
    page: 1,
    langId: 1, //fix
    mainProductId: 1,
  };

  static async processFile(res, filename) {
    const blob = await res.blob();

    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    await link.setAttribute('download', `${filename}`);
    await document.body.appendChild(link);
    await link.click();
    link.parentNode.removeChild(link);

    window.URL.revokeObjectURL(blobUrl);
  }

  static async getMenu(props) {
    //props: restaurantId, langId, size, existing, categoryId, search
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${props.restaurantId}/${
          props.aggregator
        }?langId=${props.langId}&page=${props.page}&size=${props.size}&existing=${props.existing}${
          props.categoryId ? `&categoryId=${props.categoryId}` : ''
        }&search=${props.search}
        `,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Menu data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${
          props.restaurantId
        }/${props.aggregator}?langId=${props.langId}&page=${props.page}&size=${
          this.API_SETTINGS.size
        }&existing=${props.existing}${
          props.categoryId ? `&categoryId=${props.categoryId}` : ''
        }&search=${props.search}
        `,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async deleteProducts(props) {
    try {
      const request = await api.delete(
        `${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${props.restaurantId}/${
          props.aggregator
        }`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(props.data),
        },
      );

      debug.success('Product data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${
          props.restaurantId
        }/${props.aggregator}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async sendToAggregator(props) {
    try {
      const request = await api.get(
        `${URL}${props.aggregatorName}/crm/${props.restaurantId}/menu`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${props.aggregatorName}/${props.restaurantId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async uploadToAggregator(props) {
    let link = `${URL}${props.aggregatorName}/menu/${props.restaurantId}`;
    try {
      const request = await api.post(link, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${props.aggregatorName}/${props.restaurantId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async uploadToGlovo(props) {
    let link = `${URL}${props.aggregatorName}-v2/menu/${props.restaurantId}/send`;
    try {
      const request = await api.post(link, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(props.data),
      });

      debug.success('Data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${props.aggregatorName}/${props.restaurantId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addProducts(props) {
    try {
      const request = await api.post(
        `${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${props.restaurantId}/${
          props.aggregator
        }`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(props.data),
        },
      );

      debug.success('Products data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.menu}/${props.category}/${
          props.restaurantId
        }/${props.aggregator}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getAggregators() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.aggregators}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      const res = await request.json();

      debug.success('Aggregators retrieval result', res);
      return res;
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.aggregators}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getAggregatorsId(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.aggregators}/${props.id}/restaurants/${props.restaurantId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const res = await request.json();

      debug.success('Aggregators retrieval result', res);
      return res;
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.aggregators}${
          props.id
        }/restaurants/${props.restaurantId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async postAggregatorsId(props) {
    try {
      const request = await api.post(
        `${URL}${this.#API_ENDPOINTS.aggregators}/${props.id}/restaurants/${props.restaurantId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(props.data),
        },
      );

      const res = await request.json();

      debug.success('Aggregators retrieval result', res);
      return res;
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.aggregators}${
          props.id
        }/restaurants/${props.restaurantId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async putAggregatorsId(props) {
    try {
      const request = await api.put(
        `${URL}${this.#API_ENDPOINTS.aggregators}/${props.id}/restaurants/${props.restaurantId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(props.data),
        },
      );

      const res = await request.json();

      debug.success('Aggregators retrieval result', res);
      return res;
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.aggregators}${
          props.id
        }/restaurants/${props.restaurantId}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getWeeklyReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.weeklyReports
        }/?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {},
      );

      debug.success('Aggregators weekly retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.weeklyReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getDailyReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.dailyReports
        }?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'content-type': 'text/xlsx',
          },
        },
      );
      debug.success('Aggregators daily retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.dailyReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getKKMReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.kkmReports
        }?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {},
      );
      debug.success('Aggregators kkm retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.kkmReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getOrdersReadyReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.ordersReadyReports
        }?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {},
      );
      debug.success('Orders ready retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.ordersReadyReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getMobileReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.mobileReports
        }?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {},
      );
      debug.success('Mobile report retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.mobileReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getGiftsMobileReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.giftsMobileReports
        }?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {},
      );
      debug.success('Gifts mobile retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.giftsMobileReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getPointsMobileReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.pointsMobileReports
        }?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {},
      );
      debug.success('Point mobile retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.pointsMobileReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getCouponsMobileReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.couponsMobileReports
        }?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {},
      );
      debug.success('Coupon mobile retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.couponsMobileReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getPunchCardsMobileReports({ langId, startDate, endDate }) {
    try {
      const request = await api.post(
        `${URL}${
          this.#API_ENDPOINTS.punchCardsMobileReports
        }?langId=${langId}&startDate=${startDate}&endDate=${endDate}`,
        {},
      );
      debug.success('Punch cards retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.punchCardsMobileReports}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Icon } from '../';
import colors from '../../styles/colors';

const Select = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  const renderClassName = () => {
    let str = 'select-container';
    if (props.selectType) {
      str += ` ${selectConfigList[props.selectType].basicClass}`;
    }
    if (props.size) {
      str += ` ${sizes[props.size]?.className}`;
    }
    if (props.className) {
      str += ` ${props.className}`;
    }
    return str;
  };

  return (
    <div className={renderClassName()} disabled={props.disabled}>
      <select
        value={props.value}
        onChange={handleChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      >
        <option disabled>
          {props.label}
        </option>
        {props.options?.map((item, i) => (
          <option value={item.key}  key={`select-option-${i}`}>
            {item.label}
          </option>
        ))}
      </select>
      <label>{props.label}</label>
      <div className="icons">
        <div className="arrow">
          <Icon name="arrow-down" width={16} height={16} color={colors.color_gray._4} />
        </div>
        {props.isValid && (
          <div className="is-valid">
            <Icon name="check" width={14} height={14} color={colors.color_green._0} />
          </div>
        )}
      </div>
    </div>
  );
};

const selectConfigList = {
  default: {
    basicClass: 'default',
  },
  primary: {
    basicClass: 'primary',
  },
  secondary: {
    basicClass: 'secondary',
  },
  danger: {
    basicClass: 'danger',
  },
};

const sizes = {
  small: {
    className: 'small',
  },
  medium: {
    className: 'medium',
  },
  large: {
    className: 'large',
  },
};

Select.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  selectType: PropTypes.oneOf(['default', 'primary', 'secondary', 'disabled']),
  options: PropTypes.array,
  isValid: PropTypes.bool,
  className: PropTypes.string,
};
Select.defaultProps = {
  label: '',
  onChange: () => {},
  disabled: false,
  value: 'null',
  size: 'medium',
  selectType: 'default',
  options: [],
  isValid: false,
  className: '',
};

export default Select;

import React, { useEffect, useState } from 'react';

import StoplistsView from './stoplists-view';
import StoplistsService from '../../../services/stoplists';
import RestaurantsService from '../../../services/restaurants';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Toast } from '../../ui-kit';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import STRINGS from '../../../localization';

const StoplistsContainer = () => {
  const listSize = 15;
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const search = useSelector((store) => store.search.value);
  const sorts = '';
  const [showModal, setShowModal] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [page, setPage] = useState(1);
  const [restaurants, setRestaurants] = useState([]);
  const [isLast, setIsLast] = useState(false);
  const [bottomLoading, setBottomLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    const getList = async (props) => {
      setSkeletVisible(true);
      const resp = await getRestaurants(props);
      setRestaurants({
        isLast: resp.isLast,
        data: [...resp?.data],
      });
      setSkeletVisible(false);
    };
    setPage(1);
    getList({
      page: 1,
      size: listSize,
      langId: langId,
      search: search,
      sort: sorts,
    });
  }, [search]);

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await getRestaurants({
        page: page + 1,
        size: listSize,
        langId: langId,
        search: search,
        sort: sorts,
      });

      setPage((old) => old + 1);
      setRestaurants({
        isLast: resp.isLast,
        data: [...restaurants?.data, ...resp?.data],
      });
    } catch {}
    setBottomLoading(false);
  };

  const saveChanges = async (data) => {
    setLoading(true);
    try {
      await StoplistsService.patchGlobalStoplist(data);
      Toast.success(STRINGS.notify.qo);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.qo);
      }
    }
    setLoading(false);
    setShowModal('');
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  const getRestaurants = async (props) => {
    try {
      const resp = await RestaurantsService.getRestaurants(props);
      return resp;
    } catch (e) {
      Toast.error(STRINGS.notify.failure);
    }
  };
  return (
    <StoplistsView
      restaurants={restaurants}
      skeletVisible={skeletVisible}
      history={history}
      setShowModal={setShowModal}
      showModal={showModal}
      saveChanges={saveChanges}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default StoplistsContainer;

/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import './styles.scss';

const SideItem = ({ side = {} }) => {
  return (
    <div className="detail-wrapper top-4">
      <span className="detail-side">{side.name}</span>
      <span className="detail-side">
        {side.priceImpact || 0} {STRINGS.components.receipt.tg}
      </span>
    </div>
  );
};

export default SideItem;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './styles.scss';
import colors from '../../styles/colors';

import STRINGS from '../../../localization';

import { Icon } from '../../ui-kit';
import { Drawer } from '../../ui-kit';
import { HRowItem, HRowDropdownItem } from '..';

import initials from '../../utils/initials';
import { useWindowDimensions } from '../../utils/hooks';

const Sidebar = (props) => {
  const user = useSelector((store) => store.user.data);
  const { stopListAccess } = useSelector((store) => store.user.data);
  const [pathname, setPathname] = useState();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const history = useHistory();

  history.listen((location) => {
    setPathname(location.pathname);
  });
  useEffect(() => {
    dispatch({ type: 'APPLY_SEARCH', payload: '' });
  }, [pathname]);
  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);
  const SidebarView = () => {
    return (
      <div id="--sidebar-root">
        <Link to="/" className="sidebar-logo">
          <Icon name="logo" width={172} height={36} color={colors.color_gray._4} />
        </Link>
        {stopListAccess ? (
          <div>
            <p>{STRINGS.components.sidebar.manage}</p>
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.restaurants}
              to="/restaurants"
              icon="restaurants"
              active={pathname === '/restaurants'}
            />
          </div>
        ) : (
          <div>
            <p>{STRINGS.components.sidebar.manage}</p>
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.dashboard}
              to="/dashboard"
              icon="dashboard"
              active={pathname === '/dashboard'}
              //data={12}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.users}
              to="/users"
              icon="users"
              active={pathname === '/users'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.managers}
              to="/managers"
              icon="managers"
              active={pathname === '/managers'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.roles}
              to="/roles"
              icon="case"
              active={pathname === '/roles'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.cities}
              to="/cities"
              icon="city"
              active={pathname === '/cities'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.discounts}
              to="/promotions"
              icon="discounts"
              active={pathname === '/promotions'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.coupons}
              to="/coupons"
              icon="coupons"
              active={pathname === '/coupons'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.punchcards}
              to="/punchcards"
              icon="punchcards"
              active={pathname === '/punchcards'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.restaurants}
              to="/restaurants"
              icon="restaurants"
              active={pathname === '/restaurants'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.statusRestaurants}
              to="/status-restaurants"
              icon="restaurants"
              active={pathname === '/status-restaurants'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.feedbacks}
              to="/reviews"
              icon="feedbacks"
              active={pathname === '/reviews'}
            />
            <HRowItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.orders}
              to="/orders-overview"
              icon="orders"
              active={pathname === '/orders-overview/'}
            />
            <HRowDropdownItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.stories}
              icon="background"
              linkList={[
                {
                  name: STRINGS.components.sidebar.stories,
                  to: '/stories',
                  active: pathname === '/stories',
                },
                {
                  name: STRINGS.components.sidebar.storyTypes,
                  to: '/story-types',
                  active: pathname === '/story-types',
                },
              ]}
            />
            <HRowDropdownItem
              setSidebarVisible={props.onClick}
              name={STRINGS.components.sidebar.menu}
              icon="menu"
              linkList={[
                {
                  name: STRINGS.components.sidebar.categories,
                  to: '/categories',
                  active: pathname === '/categories',
                },
                {
                  name: STRINGS.components.sidebar.goods,
                  to: '/products',
                  active: pathname === '/products',
                },
                {
                  name: STRINGS.components.sidebar.combo,
                  to: '/combo',
                  active: pathname === '/combo',
                },
                {
                  name: STRINGS.components.sidebar.ingredients,
                  to: '/ingredients',
                  active: pathname === '/ingredients',
                },
                {
                  name: STRINGS.components.sidebar.pointsshop,
                  to: '/pointsshop',
                  active: pathname === '/pointsshop',
                },
                {
                  name: STRINGS.components.sidebar.stoplists,
                  to: '/stoplists-overview',
                  active: pathname === '/stoplists',
                },
              ]}
            />
          </div>
        )}
        <Link to="/profile" className="navigation-manager">
          <div className="avatar">
            <div style={user?.avatar ? { backgroundImage: `url('${URL}/${this.avatar}')` } : {}}>
              {!user?.avatar && <span>{initials(user?.firstName, user?.lastName)}</span>}
            </div>
          </div>
          <div className="user-data">
            <p className="name">
              {user?.firstName} {user?.lastName}
            </p>
            <p className="mail">{user?.email}</p>
          </div>
        </Link>
      </div>
    );
  };

  if (width > 992) {
    return SidebarView();
  } else {
    return (
      <Drawer
        anchor="left"
        visible={props.sidebarVisible}
        onClick={() => props.onClick(!props.sidebarVisible)}
      >
        {SidebarView()}
      </Drawer>
    );
  }
};

Sidebar.propTypes = {
  onClick: PropTypes.func,
  sidebarVisible: PropTypes.bool,
};
Sidebar.defaultProps = {
  onClick: () => {},
  sidebarVisible: false,
};

export default Sidebar;

/**
 * @prettier
 */

import React from 'react';

import { Spinner } from '../../ui-kit';
import './styles.scss';

const Loader = () => (
  <div className="story-loader">
    <Spinner />
  </div>
);

export default Loader;

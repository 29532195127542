/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import OrdersService from '../../../services/orders';
import ArchiveView from './archive-view';
import { init, listenNewOrders, listenCancelOrdersWithAdding } from '../../../services/socket';
import AggregatorService from '../../../services/aggregator';

const ArchiveContainer = () => {
  const user = useSelector((store) => store.user);

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rebuild, setRebuild] = useState(false);
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);
  const [aggregators, setAggregators] = useState([{ id: 0, name: 'Все агрегаторы', key: '' }]);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [order, setOrder] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');
  const [delivery, setDelivery] = useState('');

  const [page, setPage] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [ordersData, setOrdersData] = useState([]);

  useEffect(() => {
    getData();
  }, [rebuild]);

  useEffect(async () => {
    const deliveries = await AggregatorService.getAggregators();
    setAggregators((old) => [...old, ...deliveries]);
  }, []);

  useEffect(() => {
    const restaurantId = user.data.restaurantId;

    const socketConnect = init(restaurantId);

    listenNewOrders(socketConnect, restaurantId);

    listenCancelOrdersWithAdding(socketConnect, setOrdersData, restaurantId);

    return () => socketConnect.disconnect();
  }, []);

  const scrollRef = useBottomScrollListener(() => {
    if (!isLast) {
      getData();
    }
  });

  const clearFilters = () => {
    setRebuild(!rebuild);

    setPage(0);
    setOrdersData([]);
    setDateFrom('');
    setDateTo('');
    setOrderNumber('');
    setTimeFrom('');
    setTimeTo('');
    setDelivery('');
  };

  const checkFilters = () => {
    if (
      dateFrom === '' &&
      dateTo === '' &&
      orderNumber === '' &&
      timeFrom === '' &&
      timeTo === '' &&
      delivery === ''
    ) {
      return false;
    }
    return true;
  };

  const getData = async () => {
    try {
      if (page === 0) {
        setIsLoadingGlobal(true);
      }

      const fromDateArg = generateDate(dateFrom, timeFrom);
      const toDateArg = generateDate(dateTo, timeTo);

      const res = await OrdersService.getDeliveryMultipleStatuses(
        page + 1,
        20,
        ['picked', 'canceled'],
        1,
        orderNumber,
        fromDateArg !== '' ? fromDateArg.toISOString() : '',
        toDateArg !== '' ? toDateArg.toISOString() : '',
        delivery,
        user.data.restaurantId,
      );

      setOrdersData((old) => [...old, ...res.data]);
      setIsLast(res.isLast);
      setPage((prev) => prev + 1);

      if (page === 0) {
        setIsLoadingGlobal(false);
      }
    } catch (err) {}
  };

  const generateDate = (date, time) => {
    let arg = date;

    if (date !== '' && time !== '') {
      arg.setHours(time.getHours());
      arg.setMinutes(time.getMinutes());
    } else if (time !== '') {
      arg = time;
    }

    return arg;
  };

  const handleSort = async () => {
    setOrdersData([]);
    setPage(0);
    setRebuild(!rebuild);
    setOpenModal(false);
  };

  return (
    <ArchiveView
      // Props
      open={open}
      order={order}
      orderNumber={orderNumber}
      dateFrom={dateFrom}
      dateTo={dateTo}
      timeFrom={timeFrom}
      timeTo={timeTo}
      delivery={delivery}
      aggregators={aggregators}
      ordersData={ordersData}
      scrollRef={scrollRef}
      isLoadingGlobal={isLoadingGlobal}
      openModal={openModal}
      // Methods
      checkFilters={checkFilters}
      clearFilters={clearFilters}
      handleSort={handleSort}
      setOpenModal={setOpenModal}
      setOpen={setOpen}
      setOrderNumber={setOrderNumber}
      setOrder={setOrder}
      setDateFrom={setDateFrom}
      setDateTo={setDateTo}
      setTimeFrom={setTimeFrom}
      setTimeTo={setTimeTo}
      setDelivery={setDelivery}
    />
  );
};

export default ArchiveContainer;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';

import ModificatorsView from './modificators-view';
import { Toast } from '../../ui-kit';
import STRINGS from '../../../localization';
import ModificatorsService from '../../../services/modificators';
import MainService from '../../../services/main';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { translateStatus } from '../../../main/utils/translate-status';
import { configureStatus } from '../../../main/utils/configure-status';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { languages, modificatorCategories } = useSelector((store) => store.stuff);
  const [loading, setLoading] = useState(false);
  const [selectedModificatorCategory, setSelectedModificatorCategory] = useState(null);
  const [selectedModificator, setSelectedModificator] = useState();
  const [sort, setSort] = useState({ type: '', sort: '' });
  const [showModal, setShowModal] = useState('');
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(false);
  const [modificators, setModificators] = useState();
  const history = useHistory();
  const [isLast, setIsLast] = useState(false);
  const [page, setPage] = useState(1);
  const [bottomLoading, setBottomLoading] = useState(false);
  const search = useSelector((store) => store.search.value);
  const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

  const getFileId = async (file) => {
    if (file?.id) {
      return file.id;
    } else {
      try {
        const id = await MainService.postFile(file);
        return id?.id;
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
  };

  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const applySort = (mode) => {
    setSort((prevState) => {
      return {
        sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
        type: mode,
      };
    });
  };
  const saveChanges = async (id, f) => {
    setLoading(true);
    let data = {
      posTitle: f.posTitle,
      posTitle2: f.posTitle2,
      definitionId: f.definitionId,
    };
    if (f.file) {
      data = Object.assign(data, { fileId: await getFileId(f.file) });
    }
    if (f.glovoFile) {
      data = Object.assign(data, { glovoFileId: await getFileId(f.glovoFile) });
    }
    if (f.yandexFile) {
      data = Object.assign(data, { yandexFileId: await getFileId(f.yandexFile) });
    }
    if (f.chocofoodFile) {
      data = Object.assign(data, { chocofoodFileId: await getFileId(f.chocofoodFile) });
    }
    if (f.woltFile) {
      data = Object.assign(data, { woltFileId: await getFileId(f.woltFile) });
    }
    if (f.categoryId !== f.unmodifiedModificator?.categoryId) {
      try {
        await ModificatorsService.updateCategory(id, { categoryId: f.categoryId });
      } catch (e) {}
    }
    try {
      await ModificatorsService.updateModificator(id, data);
      Toast.success(STRINGS.notify.success);
      await getModificators({
        langId: langId,
        page: 1,
        size: 30,
        search: search,
        categoryId: selectedModificatorCategory,
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setTimeout(() => setLoading(false), 100);
  };
  const changeStatus = async (elem) => {
    try {
      await ModificatorsService.updateStatus(elem.id, configureStatus(elem));
      let temp = [...modificators.data];
      temp.find((el) => el.posId === elem.id).status = configureStatus(elem).status;
      setModificators((old) => ({ isLast: old.isLast, data: temp }));
      Toast.success(STRINGS.notify.status);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';
      const resp = await ModificatorsService.getModificators({
        langId: langId,
        page: page + 1,
        size: 30,
        search: '',
        sort: sorts,
        categoryId: selectedModificatorCategory,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setModificators({
        isLast: resp.isLast,
        data: [...modificators?.data, ...resp?.data],
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };

  const getModificators = async (props) => {
    setSkeletVisible(true);
    try {
      const resp = await ModificatorsService.getModificators(props);
      setModificators({
        isLast: resp.isLast,
        data: [...resp?.data],
      });
      setSkeletVisible(false);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setSkeletVisible(false);
    }
  };
  const getModificatorById = async (id) => {
    try {
      const resp = await ModificatorsService.getModificatorById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getDefinitionId = async (props) => {
    setLoading(true);
    try {
      const id = await ModificatorsService.postDefinition(props.definition);
      setLoading(false);
      return id;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setLoading(false);
      return props.prev;
    }
  };
  const getDefinitions = async (props) => {
    try {
      const resp = await ModificatorsService.getDefinitionsAutocomplete(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getDefinitionById = async (id) => {
    try {
      const resp = await ModificatorsService.getDefinitionById(id);
      return resp?.definitionData;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const handleDeleteModificator = async () => {
    setShowConfirm('');
    await ModificatorsService.deleteProduct(selectedModificator?.id);
    setModificators((prev) => ({
      isLast: prev.isLast,
      data: prev.data.filter((el) => el.id !== selectedModificator.id),
    }));
    Toast.success(STRINGS.notify.success);
  };
  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  useEffect(async () => {
    setPage(1);
    await getModificators({
      langId: langId,
      page: 1,
      size: 30,
      search: search,
      sort: sorts,
      categoryId: selectedModificatorCategory,
    });
  }, [sort, search, selectedModificatorCategory]);

  return (
    <ModificatorsView
      categories={modificatorCategories}
      selectedModificatorCategory={selectedModificatorCategory}
      setSelectedModificatorCategory={setSelectedModificatorCategory}
      selectedModificator={selectedModificator}
      setSelectedModificator={setSelectedModificator}
      getModificatorById={getModificatorById}
      getFileById={getFileById}
      getDefinitions={getDefinitions}
      getDefinitionById={getDefinitionById}
      getDefinitionId={getDefinitionId}
      history={history}
      bottomLoading={bottomLoading}
      modificators={modificators}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      sort={sort}
      applySort={applySort}
      translateStatus={translateStatus}
      saveChanges={saveChanges}
      changeStatus={changeStatus}
      handleDeleteModificator={handleDeleteModificator}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      loading={loading}
      setLoading={setLoading}
      skeletVisible={skeletVisible}
    />
  );
};

export default Container;

import React from 'react';
import SyncLoader from 'react-spinners/PulseLoader';
import Colors from '../../styles/colors';

const Spinner = () => (
  <SyncLoader
    color={Colors.color_gray._3}
    speedMultiplier={0.4}
  />
);

export default Spinner;

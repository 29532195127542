/**
 * @prettier
 */

import React from 'react';
import { useSelector } from 'react-redux';

import { AggregatorsStatusView } from './aggregators-status-view';

const AggregatorsStatusContainer = () => {
  const { glovo, chocofood, wolt, yandex } = useSelector((store) => store.aggregators);

  return <AggregatorsStatusView aggregators={{ glovo, chocofood, wolt, yandex }} />;
};

export default AggregatorsStatusContainer;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

export default class StoriesService {
  static #API_ENDPOINTS = {
    stories: 'stories',
    storyTypes: 'stories/types',
  };

  static API_SETTINGS = {
    size: 30,
    langId: 1,
  };
  static async getStories(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.stories}/crm?langId=${props.langId}&page=${props.page}&size=${
          props.size
        }${props.sort}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Stories data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.cities}`, error);
      throw await error.response?.json();
    }
  }
  static async getStoryTypes(filterParams = '') {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.storyTypes}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Stories data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }
      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.cities}`, error);
      throw await error.response?.json();
    }
  }
  static async addStory(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.stories}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Story data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.stories}`, error);
      throw await error.response?.json();
    }
  }
  static async addStoryType(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.storyTypes}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Story data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.storyTypes}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async editStoryType({ id: id, data: data }) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.storyTypes}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Story data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.storyTypes}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteStory(props) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.stories}/${props.id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Story data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.stories}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteStoryType(props) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.storyTypes}/${props?.id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Story types data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.storyTypes}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getStoryById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.stories}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Story data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.stories}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

// Used to avoid changing the old logic in the
// 'Select' component of the 'restaurant' module ('ui-kit' folder),
// where field 'id' was used for value in input

export const WEBKASSA_CHECK_STATUSES = [
  { id: 0, name: 'Любой статус', key: 'wk-status-any', value: 'any' },
  { id: 1, name: 'Напечатанные', key: 'wk-status-success', value: 'success' },
  { id: 2, name: 'Ожидающие повторного отправления', key: 'wk-status-failed', value: 'failed' },
];

export const WEBKASSA_OPERATION_TYPES = {
  1: 'Покупка',
  2: 'Продажа',
  3: 'Возврат продажи',
  4: 'Возврат покупки',
};

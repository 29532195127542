/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { Paper } from '../index';

/**
 * @component Table
 * @example
 * <Table />
 */
const Table = (props) => {
  return (
    <Paper
      className={`table-container_aside ${props.loading ? 'loading' : ''}`}
      additionalClass={`table-container ${props.secondary ? 'secondary' : ''} `}
    >
      <table className="table-container_table">
        <thead>{props.head()}</thead>
        <tbody>{props.children}</tbody>
      </table>
    </Paper>
  );
};

/**
 * Properties
 */

Table.propTypes = {
  head: PropTypes.func,
  children: PropTypes.element,
  secondary: PropTypes.bool,
  loading: PropTypes.bool,
};
Table.defaultProps = {
  head: () => {},
  children: null,
  secondary: false,
  loading: false,
};

export default Table;

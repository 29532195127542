/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

import './styles.scss';

import { Icon } from '../../../ui-kit';

import Skeleton from '../../../main/ui-kit/skeleton';

/**
 * @component Modal
 * @example
 * <Modal
 *  onClose={() => {}}
 *  open={false}
 *  title="title"
 *  loading={false}
 * />
 */
const ModalRestaurant = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth={props.maxWidth}
      onClose={!props.loading && props.onClose}
      open={props.open}
    >
      <div className="modal-root">
        {props.title && (
          <div className="header-restaurant">
            {props.skelet ? (
              <Skeleton width="fit-content">
                <h2>{props.title || '*********************'}</h2>
              </Skeleton>
            ) : (
              <h2 className="restaurant-model-header">{props.title}</h2>
            )}
            <button onClick={props.onClose}>
              <Icon width={24} height={24} name="crossRestaurant" />
            </button>
          </div>
        )}
        <>{props.children}</>
        {props.loading && <div className="loading">{/* <Loader color="light" /> */}</div>}
      </div>
    </Dialog>
  );
};

ModalRestaurant.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']).isRequired,
  skelet: PropTypes.bool,
};

ModalRestaurant.defaultProps = {
  onClose: () => {},
  open: false,
  title: null,
  loading: false,
  skelet: false,
};

export default ModalRestaurant;

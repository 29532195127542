/**
 * @prettier
 */

import React, { Fragment } from 'react';
import STRINGS from '../../../localization';
import GlovoClosing from './glovoClosing';
import { Head } from '../../components';
import { useSelector } from 'react-redux';
import { Table, Tooltip, Icon, Confirm, Button } from '../../ui-kit';
import RestaurantFields from '../../components/modals/restaurant-fields';
import './styles.scss';
import { translateStatus } from '../../utils/translate-status';
import Skelet from './skelet';

const RestaurantsView = (props) => {
  const { stopListAccess } = useSelector((store) => store.user.data);
  const handleChangeStatus = async (restaurant) => {
    let temp = [...props.restaurants.data];
    temp.find((el) => el.id === restaurant.id).statusLoading === true;
    props.setRestaurants((prev) => ({ isLast: prev.isLast, data: [...temp] }));
    props.changeStatus({
      id: restaurant?.id,
      status: restaurant?.status,
    });
  };

  const renderClassNameSort = (type) => {
    let str = 'summary_table_heading_sort';
    if (type === props.sort.type) {
      str += ' summary_table_heading_sort_active';
    }
    return str;
  };
  return (
    <>
      <RestaurantFields
        modalType={props.showModal}
        selectedRestaurant={props.selectedRestaurant}
        getRestaurantById={props.getRestaurantById}
        languages={props.languages}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'editRestaurant'}
        advantages={props.advantages}
        setAdvantages={props.setAdvantages}
        title={STRINGS.screens.restaurants.editRestaurant}
        saveChanges={props.saveChanges}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <RestaurantFields
        modalType={props.showModal}
        getRestaurantById={props.getRestaurantById}
        languages={props.languages}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'addRestaurant'}
        advantages={props.advantages}
        setAdvantages={props.setAdvantages}
        title={STRINGS.screens.restaurants.addRestaurant}
        saveChanges={props.addRestaurant}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={`${STRINGS.screens.restaurants.leave}`}
        buttons={[
          {
            type: 'danger',
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteRestaurant(),
            loading: props.isLoadingButtonDelete,
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              setTimeout(() => props.setSelectedRestaurant(), 100);
              props.setShowConfirm('');
            },
          },
        ]}
      />

      <Head
        label={STRINGS.screens.restaurants.title}
        button={
          !stopListAccess
            ? {
                size: 'medium',
                icon: { name: 'plus', side: 'left' },
                label: STRINGS.screens.restaurants.addRestaurant,
                onClick: () => props.setShowModal('addRestaurant'),
              }
            : false
        }
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.restaurants.title,
          },
        ]}
      />

      <div className="summary_table">
        {props.skeletVisible ? (
          <Skelet />
        ) : (
          <Table
            loading={props.bottomLoading}
            head={() => (
              <tr className="summary_table_heading">
                <th>{STRINGS.screens.restaurants.id}</th>
                <th>{STRINGS.screens.restaurants.name}</th>
                <th>{STRINGS.screens.restaurants.qe}</th>
                <th>{STRINGS.screens.restaurants.address}</th>
                <th>{STRINGS.screens.restaurants.city}</th>
                <th className="summary_table_heading_cell">
                  <div>
                    <span>{STRINGS.screens.restaurants.status}</span>
                    <span
                      onClick={() => props.applySort('Status')}
                      className={renderClassNameSort('Status')}
                    >
                      {props.sort.sort === 'desc' && props.sort.type === 'Status'
                        ? 'Z - a'
                        : 'A - z'}
                    </span>
                  </div>
                </th>
                <th />
              </tr>
            )}
          >
            {props.restaurants?.data
              ?.map((el) => Object.assign({ statusLoading: false }, el))
              ?.map((restaurant) => (
                <>
                  <Fragment key={restaurant.id}>
                    <tr
                      className="summary_table_body"
                      onClick={async (event) => {
                        props.history.push(`restaurants/${restaurant.id}/`, {
                          title: restaurant?.title,
                          status: restaurant?.status,
                        });
                        event.stopPropagation();
                        await props.getRestaurantById(restaurant.id);
                      }}
                    >
                      <td>{restaurant?.id}</td>
                      <td>{restaurant?.title || STRINGS.screens.modal.noTitle}</td>
                      <td>
                        <GlovoClosing restaurantId={restaurant.id} />
                      </td>
                      <td>{restaurant?.address}</td>
                      <td>{restaurant?.city}</td>
                      <td>
                        <Button
                          className="outline"
                          size="medium"
                          label={translateStatus(restaurant?.status).status}
                          type={translateStatus(restaurant?.status).button}
                          typeButton="submit"
                          loading={restaurant.statusLoading}
                          disabled={restaurant.statusLoading || stopListAccess}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleChangeStatus(restaurant);
                          }}
                        />
                      </td>
                      <td className="summary_table_edit">
                        {!stopListAccess && (
                          <div className="summary_table_edit_wrapper">
                            <Tooltip title={STRINGS.components.tooltips.delete}>
                              <div
                                className="summary_table_iconWrapper"
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  props.setSelectedRestaurant({
                                    id: restaurant?.id,
                                  });
                                  props.setShowConfirm('delete');
                                }}
                              >
                                <Icon name="trash-outline" height={24} width={24} />
                              </div>
                            </Tooltip>
                            <Tooltip title={STRINGS.components.tooltips.edit}>
                              <div
                                className="summary_table_iconWrapper"
                                onClick={async (event) => {
                                  event.stopPropagation();
                                  props.setSelectedRestaurant({
                                    id: restaurant?.id,
                                  });
                                  props.setShowModal('editRestaurant');
                                }}
                              >
                                <Icon name="edit" height={24} width={24} />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                </>
              ))}
          </Table>
        )}
      </div>
    </>
  );
};

export default RestaurantsView;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../loader';
import PointsStoreService from '../../../../services/points-store';
import { validateObject } from '../../../utils/input-functions';

import './styles.scss';

import { Icon } from '../../../../ui-kit';
import {
  Button,
  Form,
  Input,
  Drawer,
  AutocompleteStore,
  CustomDropzone,
  ColorSelector,
  TextArea,
  Tooltip,
} from '../../../ui-kit';
import STRINGS from '../../../../localization';

import { useSelector } from 'react-redux';
import { URI } from '../../../../services/config';
import MainService from '../../../../services/main';
import NOPHOTO from '../../../../assets/nophoto.png';

const PointsStoreFields = (props) => {
  const { languages, backgroundColors } = useSelector((store) => store.stuff);
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [step, setStep] = useState(1);
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [defaultState, setDefaultState] = useState(null);
  const [id, setId] = useState();
  const [file, setFile] = useState(null);
  const [productsToDelete, setProductsToDelete] = useState({});
  const [productsToAdd, setProductsToAdd] = useState({});
  const [comboToDelete, setComboToDelete] = useState({});
  const [comboToAdd, setComboToAdd] = useState({});
  const [levelProducts, setLevelProducts] = useState({});
  const [levelCombo, setLevelCombo] = useState({});
  const [amount, setAmount] = useState('');
  const maxStep = 4;

  const [validation, setValidation] = useState();
  const [priceLevelData, setPriceLevelData] = useState([
    { languageId: 1, title: '' },
    { languageId: 2, title: '' },
    { languageId: 3, title: '' },
  ]);
  const SCHEME = {
    amount: (value) => !!value,
    file: (value) => !!value,
    title0: (value) => value.length > 0,
    title1: (value) => value.length > 0,
    title2: (value) => value.length > 0,
  };

  const titles = priceLevelData.reduce(
    (acc, el, key) => ({ ...acc, [`title${key}`]: el.title }),
    [],
  );

  const preparePriceLevelData = (priceLevelDataPatched) => {
    if (priceLevelDataPatched?.length) {
      return priceLevelData.map((el, key) => ({
        languageId: el?.languageId,
        title: priceLevelDataPatched[key]?.title || '',
      }));
    } else {
      return [
        { languageId: 1, title: '' },
        { languageId: 2, title: '' },
        { languageId: 3, title: '' },
      ];
    }
  };
  const [isFocus, setIsFocus] = useState({
    amount: true,
    file: true,
    title0: true,
    title1: true,
    title2: true,
  });

  useEffect(() => {
    setValidation(validateObject(Object.assign(titles, { amount, file }), SCHEME));
  }, [amount, file, priceLevelData]);
  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };
  const initialize = () => {
    setStep(1);
    setId(null);
    setAmount(null);
    setLevelProducts({});
    setLevelCombo({});
    setProductsToDelete({});
    setComboToDelete({});
    setProductsToAdd({});
    setComboToAdd({});
    setFile(null);
    setPriceLevelData([
      { languageId: 1, title: '' },
      { languageId: 2, title: '' },
      { languageId: 3, title: '' },
    ]);
    setIsFocus({
      amount: true,
      file: true,
      title0: true,
      title1: true,
      title2: true,
    });
  };

  const mapData = async (level) => {
    setDefaultState(level);
    setId(level?.id);
    setAmount(level?.amount);
    setLevelProducts(level?.products);
    setFile(await prepareFile(level?.fileId));
    setLevelCombo(level?.combo);
    setPriceLevelData(preparePriceLevelData(level?.priceLevelData));
  };
  const prepareFile = async (fileId) => {
    if (fileId) {
      try {
        let resp = await MainService.getFileById(fileId);
        resp = Object.assign(resp, { preview: URL.createObjectURL(resp), id: fileId });
        return resp;
      } catch (error) {}
    } else {
      return null;
    }
  };
  const getLevel = async (levelId) => {
    try {
      const resp = await PointsStoreService.getLevelById(levelId);
      return resp;
    } catch (err) {}
  };
  const prepareItemData = (itemDataPatched) => {
    return [
      { languageId: 1, description: '' },
      { languageId: 2, description: '' },
      { languageId: 3, description: '' },
    ].map((el, key) => ({
      languageId: el.languageId,
      description: itemDataPatched[key]?.description || '',
    }));
  };

  useEffect(() => {
    const loadData = async () => {
      if (props.isOpen === true) {
        props.setLoading(true);
        const selectedLevel = await getLevel(props.selectedLevel?.id);
        const productResp = await props.getLevelProducts({
          langId: langId,
          priceLevelId: props.selectedLevel?.id,
        });
        await mapData({
          id: props.selectedLevel?.id,
          amount: selectedLevel?.amount,
          fileId: selectedLevel?.fileId,
          products: productResp
            .filter((e) => e.hasOwnProperty('productId'))
            ?.map((el) => ({
              productId: el?.productId,
              posTitle: el?.title,
              posId: el?.productId,
              itemData: prepareItemData(el?.descriptionData ?? []),
              title: el?.title,
              fileId: el?.fileId,
              expanded: false,
              selectedLanguage: 1,
              backgroundColorId: el?.backgroundColorId || 1,
            }))
            .reduce(
              (acc, el) => ({
                ...acc,
                [el.posId]: el,
              }),
              0,
            ),
          priceLevelData: selectedLevel?.priceLevelData,
          combo: productResp
            ?.filter((e) => e.hasOwnProperty('comboId'))
            .map((el) => ({
              comboId: el?.comboId,
              posTitle: el?.title,
              fileId: el?.fileId,
              posId: el?.comboId,
              itemData: prepareItemData(el?.descriptionData ?? []),
              title: el?.title,
              expanded: false,
              selectedLanguage: 1,
              backgroundColorId: el?.backgroundColorId || 1,
            }))
            .reduce(
              (acc, el) => ({
                ...acc,
                [el.posId]: el,
              }),
              0,
            ),
        });
        props.setLoading(false);
      }
    };
    loadData();
  }, [props.selectedLevel]);

  useEffect(() => {
    if (!props.isOpen) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  return (
    <Drawer anchor="right" visible={props.isOpen} open={props.isOpen} onClose={props.onClose}>
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges({
              defaultState: defaultState,
              levelProducts: Object.values(levelProducts),
              levelCombo: Object.values(levelCombo),
              amount: amount,
              id: props.selectedLevel?.id,
              productsToAdd: Object.values(productsToAdd),
              productsToDelete: Object.values(productsToDelete),
              comboToAdd: Object.values(comboToAdd),
              comboToDelete: Object.values(comboToDelete),
              file: file,
              priceLevelData: priceLevelData,
            });
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
              {!props.loading && <span>{defaultState ? ` ${defaultState?.amount}` : ''}</span>}
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{`${STRINGS.screens.points.pointsAmount} *`}</span>
                    </div>
                    <Input
                      value={amount}
                      onChange={setAmount}
                      className="container_fullwidth_one_first"
                      fullWidth
                      type="number"
                      variant="outlined"
                      label={STRINGS.screens.points.amount}
                      size="large"
                      maxLength={6}
                      inputType={
                        !isFocus?.amount && !validation?.data?.amount?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('amount', true)}
                      onBlur={() => changeFocus('amount', false)}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{`${STRINGS.screens.modal.img} *`}</span>
                    </div>
                    <CustomDropzone maxFiles={1} files={file} onChange={setFile} />
                  </div>
                </>
              )}
              {step === 2 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.langs}</span>
                  </div>
                  <div className="langs">
                    {languages?.map((language) => (
                      <Button
                        label={language.name}
                        value={language.id}
                        size="medium"
                        className="lang"
                        type={selectedLanguage === language.id ? 'primary' : 'default'}
                        data-active={selectedLanguage === language.id}
                        onClick={(e) => {
                          setSelectedLanguage(language.id);
                          setIsFocus({
                            [`title${language.id - 1}`]: true,
                          });
                          e.preventDefault();
                        }}
                      >
                        <span className="day-label">{language.name}</span>
                      </Button>
                    ))}
                  </div>
                  <Input
                    className="container_fullwidth_one_first"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...priceLevelData];
                      temp[selectedLanguage - 1].title = val;
                      setPriceLevelData(temp);
                    }}
                    value={priceLevelData[selectedLanguage - 1].title}
                    label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
                    inputType={
                      !isFocus?.[`title${selectedLanguage - 1}`] &&
                      !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                  />
                </div>
              )}
              {step === 3 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.points.qw}</span>
                    </div>
                    <AutocompleteStore
                      label={STRINGS.screens.points.qt}
                      getSuggestions={props.getProducts}
                      setSelectedProducts={setProductsToAdd}
                      selectedProducts={productsToAdd}
                      setProductsToDelete={setProductsToDelete}
                      productsToDelete={productsToDelete}
                      className="container_fullwidth_one_first"
                      inputType="default"
                    />
                    <ul className="product-list">
                      {Object.values(productsToAdd).map((el) => (
                        <>
                          <div className="product">
                            <div
                              className="main"
                              onClick={() => {
                                let temp = { ...productsToAdd };
                                temp[el.posId].expanded = !temp[el.posId].expanded;
                                setProductsToAdd(temp);
                              }}
                            >
                              <div className="arrow" data-expanded={el.expanded}>
                                <Icon name="arrow-down" width={16} />
                              </div>
                              <Tooltip title={STRINGS.components.tooltips.delete}>
                                <div
                                  className="cross"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let temp = { ...productsToAdd };
                                    delete temp[el.posId];
                                    setProductsToAdd(temp);
                                  }}
                                >
                                  <Icon name="cross" width={16} />
                                </div>
                              </Tooltip>
                              <div className="photo">
                                {el?.fileId ? (
                                  <img className="image" src={`${URI}files/${el?.fileId}`} />
                                ) : (
                                  <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                                )}
                              </div>
                              <span className="id">{el.posId}</span>
                              <span className="title">{el.title}</span>
                            </div>
                            {el.expanded && (
                              <div className="aside">
                                <div className="color">
                                  <span>{STRINGS.screens.points.qe}</span>
                                  <ColorSelector
                                    colors={backgroundColors}
                                    value={el.backgroundColorId}
                                    setSelectedColor={(e) => {
                                      let temp = { ...productsToAdd };
                                      temp[el.posId].backgroundColorId = e;
                                      setProductsToAdd(temp);
                                    }}
                                  />
                                </div>
                                <div className="description">
                                  <div>
                                    <span>{STRINGS.screens.points.qr}</span>
                                    <div className="language-switcher">
                                      {languages.map((i) => (
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            let temp = { ...productsToAdd };
                                            temp[el.posId].selectedLanguage = e.target.value;
                                            setProductsToAdd(temp);
                                          }}
                                          value={i.id}
                                          data-active={i.id === el.selectedLanguage}
                                        >
                                          {i.iso2}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                  <TextArea
                                    textareaType="default"
                                    className="container_fullwidth_one"
                                    onChange={(val) => {
                                      let temp = { ...productsToAdd };
                                      temp[el.posId].itemData[el.selectedLanguage - 1].description =
                                        val;
                                      setProductsToAdd(temp);
                                    }}
                                    value={el.itemData[el.selectedLanguage - 1]?.description}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                      {Object.values(levelProducts).map((el) => (
                        <>
                          <div className="product">
                            <div
                              className="main"
                              onClick={() => {
                                let temp = { ...levelProducts };
                                temp[el.posId].expanded = !temp[el.posId].expanded;
                                setLevelProducts(temp);
                              }}
                            >
                              <div className="arrow" data-expanded={el.expanded}>
                                <Icon name="arrow-down" width={16} />
                              </div>
                              <Tooltip title={STRINGS.components.tooltips.delete}>
                                <div
                                  className="cross"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let temp = { ...levelProducts };
                                    delete temp[el.posId];
                                    setLevelProducts(temp);
                                    setProductsToDelete({ ...productsToDelete, [el.posId]: el });
                                  }}
                                >
                                  <Icon name="cross" width={16} />
                                </div>
                              </Tooltip>
                              <div className="photo">
                                {el?.fileId ? (
                                  <img className="image" src={`${URI}files/${el?.fileId}`} />
                                ) : (
                                  <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                                )}
                              </div>
                              <span className="id">{el.posId}</span>
                              <span className="title">{el.title}</span>
                            </div>
                            {el.expanded && (
                              <div className="aside">
                                <div className="color">
                                  <span>{STRINGS.screens.points.qe}</span>
                                  <ColorSelector
                                    colors={backgroundColors}
                                    value={el.backgroundColorId}
                                    setSelectedColor={(e) => {
                                      let temp = { ...levelProducts };
                                      temp[el.posId].backgroundColorId = e;
                                      setLevelProducts(temp);
                                    }}
                                  />
                                </div>
                                <div className="description">
                                  <div>
                                    <span>{STRINGS.screens.points.qr}</span>
                                    <div className="language-switcher">
                                      {languages.map((i) => (
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            let temp = { ...levelProducts };
                                            temp[el.posId].selectedLanguage = e.target.value;
                                            setLevelProducts(temp);
                                          }}
                                          value={i.id}
                                          data-active={i.id === el.selectedLanguage}
                                        >
                                          {i.iso2}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                  <TextArea
                                    textareaType="default"
                                    className="container_fullwidth_one"
                                    onChange={(val) => {
                                      let temp = { ...levelProducts };
                                      temp[el.posId].itemData[el.selectedLanguage - 1].description =
                                        val;
                                      setLevelProducts(temp);
                                    }}
                                    value={el.itemData[el.selectedLanguage - 1]?.description}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                    </ul>
                    <div className="space" />
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.points.qq}</span>
                    </div>
                    <AutocompleteStore
                      label={STRINGS.screens.points.qy}
                      getSuggestions={props.getCombo}
                      setSelectedProducts={setComboToAdd}
                      selectedProducts={comboToAdd}
                      setProductsToDelete={setComboToDelete}
                      productsToDelete={comboToDelete}
                      className="container_fullwidth_one_first"
                      inputType="default"
                    />
                    <ul className="product-list">
                      {Object.values(comboToAdd).map((el) => (
                        <>
                          <div className="product">
                            <div
                              className="main"
                              onClick={() => {
                                let temp = { ...comboToAdd };
                                temp[el.posId].expanded = !temp[el.posId].expanded;
                                setComboToAdd(temp);
                              }}
                            >
                              <div className="arrow" data-expanded={el.expanded}>
                                <Icon name="arrow-down" width={16} />
                              </div>
                              <Tooltip title={STRINGS.components.tooltips.delete}>
                                <div
                                  className="cross"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let temp = { ...comboToAdd };
                                    delete temp[el.posId];
                                    setComboToAdd(temp);
                                  }}
                                >
                                  <Icon name="cross" width={16} />
                                </div>
                              </Tooltip>
                              <div className="photo">
                                {el?.fileId ? (
                                  <img className="image" src={`${URI}files/${el?.fileId}`} />
                                ) : (
                                  <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                                )}
                              </div>
                              <span className="id">{el.posId}</span>
                              <span className="title">{el.title}</span>
                            </div>
                            {el.expanded && (
                              <div className="aside">
                                <div className="color">
                                  <span>{STRINGS.screens.points.qe}</span>
                                  <ColorSelector
                                    colors={backgroundColors}
                                    value={el.backgroundColorId}
                                    setSelectedColor={(e) => {
                                      let temp = { ...comboToAdd };
                                      temp[el.posId].backgroundColorId = e;
                                      setComboToAdd(temp);
                                    }}
                                  />
                                </div>
                                <div className="description">
                                  <div>
                                    <span>{STRINGS.screens.points.qr}</span>
                                    <div className="language-switcher">
                                      {languages.map((i) => (
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            let temp = { ...comboToAdd };
                                            temp[el.posId].selectedLanguage = e.target.value;
                                            setComboToAdd(temp);
                                          }}
                                          value={i.id}
                                          data-active={i.id == el.selectedLanguage}
                                        >
                                          {i.iso2}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                  <TextArea
                                    textareaType="default"
                                    className="container_fullwidth_one"
                                    onChange={(val) => {
                                      let temp = { ...comboToAdd };
                                      temp[el.posId].itemData[el.selectedLanguage - 1].description =
                                        val;
                                      setComboToAdd(temp);
                                    }}
                                    value={el.itemData[el.selectedLanguage - 1]?.description}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                      {Object.values(levelCombo).map((el) => (
                        <>
                          <div className="product">
                            <div
                              className="main"
                              onClick={() => {
                                let temp = { ...levelCombo };
                                temp[el.posId].expanded = !temp[el.posId].expanded;
                                setLevelCombo(temp);
                              }}
                            >
                              <div className="arrow" data-expanded={el.expanded}>
                                <Icon name="arrow-down" width={16} />
                              </div>
                              <Tooltip title={STRINGS.components.tooltips.delete}>
                                <div
                                  className="cross"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let temp = { ...levelCombo };
                                    delete temp[el.posId];
                                    setLevelCombo(temp);
                                    setComboToDelete({ ...comboToDelete, [el.posId]: el });
                                  }}
                                >
                                  <Icon name="cross" width={16} />
                                </div>
                              </Tooltip>
                              <div className="photo">
                                {el?.fileId ? (
                                  <img className="image" src={`${URI}files/${el?.fileId}`} />
                                ) : (
                                  <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                                )}
                              </div>
                              <span className="id">{el.posId}</span>
                              <span className="title">{el.title}</span>
                            </div>
                            {el.expanded && (
                              <div className="aside">
                                <div className="color">
                                  <span>{STRINGS.screens.points.qe}</span>
                                  <ColorSelector
                                    colors={backgroundColors}
                                    value={el.backgroundColorId}
                                    setSelectedColor={(e) => {
                                      let temp = { ...levelCombo };
                                      temp[el.posId].backgroundColorId = e;
                                      setLevelCombo(temp);
                                    }}
                                  />
                                </div>
                                <div className="description">
                                  <div>
                                    <span>{STRINGS.screens.points.qr}</span>
                                    <div className="language-switcher">
                                      {languages.map((i) => (
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            let temp = { ...levelCombo };
                                            temp[el.posId].selectedLanguage = e.target.value;
                                            setLevelCombo(temp);
                                          }}
                                          value={i.id}
                                          data-active={i.id == el.selectedLanguage}
                                        >
                                          {i.iso2}
                                        </button>
                                      ))}
                                    </div>
                                  </div>
                                  <TextArea
                                    textareaType="default"
                                    className="container_fullwidth_one"
                                    onChange={(val) => {
                                      let temp = { ...levelCombo };
                                      temp[el.posId].itemData[el.selectedLanguage - 1].description =
                                        val;
                                      setLevelCombo(temp);
                                    }}
                                    value={el.itemData[el.selectedLanguage - 1]?.description}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                    </ul>
                    <div className="space" />
                  </div>
                </>
              )}
            </div>
          )}

          <div className="footer" style={{ marginBottom: 0 }}>
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step !== maxStep ? (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                loading={props.loading}
                onClick={(e) => {
                  e.preventDefault();
                  setStep((pr) => pr + 1);
                }}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                disabled={!validation?.isValid}
                type={!validation?.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

PointsStoreFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
PointsStoreFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default PointsStoreFields;

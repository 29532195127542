import STRINGS from '../localization';

export const reasonTypes = {
  dirty_restaurant: STRINGS.screens.reviews.qe,
  bad_service: STRINGS.screens.reviews.qr,
  bad_products: STRINGS.screens.reviews.qt,
  order_problems: STRINGS.screens.reviews.qy,
  mobile_app_problems: STRINGS.screens.reviews.qu,
  lost_thing: STRINGS.screens.reviews.qi,
};

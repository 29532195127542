/**
 * @prettier
 */

const initialState = {
  loading: false,
  failure: null,
  authorized: false,
  data: null,
  role: 0,
  restaurant: null,
};

const LOGIN_LOADING = 'LOGIN_LOADING';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const SET_RESTAURANT = 'SET_RESTAURANT';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        loading: true,
        failure: null,
        authorized: false,
        data: null,
        restaurant: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        failure: null,
        authorized: true,
        data: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        loading: false,
        failure: action.payload,
        authorized: false,
        data: null,
        restaurant: null,
      };
    case SET_RESTAURANT:
      return { ...state, restaurant: action.payload };
    default:
      return state;
  }
};

export default reducer;

export const loginLoading = () => ({
  type: LOGIN_LOADING,
});

export const loginFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload,
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const setRestaurant = (payload) => ({
  type: SET_RESTAURANT,
  payload,
});

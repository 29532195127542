/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

/**
 * Roles Service functionality
 * @getRoles
 * @getPermissions
 * @updateRole
 */
export default class RolesService {
  static #API_ENDPOINTS = {
    roles: 'roles',
    permissions: 'permissions',
  };

  /*
   * This method is used to get roles
   * @param {{lang: String}}
   * @returns {Promise<Object>}
   */
  static async getRoles(lang = 'ru') {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.roles}?lang=${lang}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('User data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.roles}`, error);
      throw await error.response?.json();
    }
  }

  /*
   * This method is used to get permissions
   * @param {{lang: String}}
   * @returns {Promise<Object>}
   */
  static async getPermissions(lang = 'ru') {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.permissions}?lang=${lang}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('User data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.permissions}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  /*
   * This method is used to update role
   * @param {{data: Object} {roleId: String}}
   */
  static async updateRole(roleId, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.roles}/${roleId}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('User data retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.roles}/${roleId}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  /*
   * This method is used to add new role
   * @param {{data: Object}}
   */
  static async addRole(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.roles}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('User data retrieval result', request);

      return request;
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.roles}`, error);
      throw await error.response?.json();
    }
  }

  /*
   * This method is used to delete role
   * @param {{roleId: String}}
   */
  static async deleteRole(roleId) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.roles}/${roleId}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('User data retrieval result', request);

      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.roles}/${roleId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

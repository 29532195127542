import STRINGS from '../../localization';
import GlovoService from '../../services/glovo';

export const controlGlovoClosing = async (f) => {
    if (f.glovoClosed === true && f.glovoClosing) {
      try {
        await GlovoService.setGlovoClosing({
          id: f.id,
          date: new Date(f.glovoClosing).toISOString(),
        });
      } catch {}
    }
    if (f.glovoClosed === false && f.glovoClosingUnavailable === false) {
      try {
        await GlovoService.deleteGlovoClosing(f.id);
      } catch {}
    }
  };

export const renderUserName = (firstName, lastName) => {
    if (firstName?.length && lastName?.length) {
      return `${firstName} ${lastName}`;
    }
    if (firstName?.length) {
      return firstName;
    }
    if (lastName?.length) {
      return lastName;
    }
    return STRINGS.screens.users.qw;
  };

/**
 * @prettier
 */

import React from 'react';
import DataSendMenuPreview from '../../components/dataSendMenuPreview/Index';
import './styles.scss';

const View = () => {
  return <DataSendMenuPreview />;
};

export default View;

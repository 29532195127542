/**
 * @prettier
 */

import { api, debug } from '../index';
import { URL } from '../config';

/**
 * Orders Service functionality
 */
export default class OrdersService {
  static #API_ENDPOINTS = {
    orders: 'orders/',
    delivery: 'orders/delivery',
    mobile: 'orders/mobile',
    sideItem: 'products/',
    sideItems: 'combo/side-items/titles',
  };

  /*
   * This method is used to update order
   * @param {{data: Object} {status: String}}
   */
  static async updateOrder(orderId, data, aggregatorKey) {
    try {
      const request = await api.put(
        `${URL}${this.#API_ENDPOINTS.orders}${aggregatorKey}/${orderId}/status`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );

      debug.success('User data retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${
          this.#API_ENDPOINTS.orders
        }${aggregatorKey}/${orderId}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async cancelOrder(orderId, data = {}) {
    try {
      console.log(orderId);
      const request = await api.post(
        `${URL}${this.#API_ENDPOINTS.delivery}/${orderId}/cancellation`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );

      debug.success('Order cancel retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${
          this.#API_ENDPOINTS.delivery
        }/${orderId}/cancellation`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async cancelMobileOrder(orderId, data = {}) {
    try {
      const request = await api.post(
        `${URL}${this.#API_ENDPOINTS.mobile}/${orderId}/cancellation`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );

      debug.success('Order cancel retrieval result', request);
      return request;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${
          this.#API_ENDPOINTS.delivery
        }/${orderId}/cancellation`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getDelivery(page, size, status, langId = 1, orderParam = 'asc', restaurantId) {
    try {
      const request = await api.get(
        `${URL}${
          this.#API_ENDPOINTS.delivery
        }?langId=${langId}&page=${page}&size=${size}&status=${status}&sortByReceiptDateTime=${orderParam}&restaurantId=${restaurantId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      const res = await request.json();
      debug.success('Delivery data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.delivery}`, error);
      throw await error.response?.json();
    }
  }
  static async getOrders(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.delivery}?langId=${props.langId}&page=${props.page}&size=${
          props.size
        }&restaurantId=${props.restaurantId}&sort=${props.sort}&${props.statuses}&${props.filter}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      const res = await request.json();
      debug.success('Delivery data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.delivery}`, error);
      throw await error.response?.json();
    }
  }

  static async getOrder(id, page = 1, size = 10) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.delivery}?orderPatternId=${id}&page=${page}&size=${size}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      const res = await request.json();
      debug.success('Order data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.delivery}`, error);
      throw await error.response?.json();
    }
  }

  static async cancel(props) {
    try {
      const request = await api.post(
        `${URL}${this.#API_ENDPOINTS.delivery}/${props.id}/cancellation`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      const res = await request.json();
      debug.success('Delivery data retrieval result', res);
      return res;
    } catch (error) {
      throw await error.response?.json();
    }
  }
  static async getSideItem(itemId, langId = 1) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.sideItem}${itemId}/title?langId=${langId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      const res = await request.json();
      debug.success('Side data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.sideItem}`, error);
      throw await error.response?.json();
    }
  }

  static async getSideItems(items = [], langId = 1) {
    const itemsArr = items.join('&sideItems[]=');
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.sideItems}?langId=${langId}&sideItems[]=${itemsArr}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      const res = await request.json();
      debug.success('Side data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.sideItems}`, error);
      throw await error.response?.json();
    }
  }

  static async getDeliveryMultipleStatuses(
    page = 1,
    size = 20,
    statuses = [],
    langId = 1,
    orderId = '',
    fromDate = '',
    toDate = '',
    aggregatorId = '',
    restaurantId,
  ) {
    const statusesArr = statuses.join('&statuses[]=');

    let queryString = `?langId=${langId}&statuses[]=${statusesArr}&page=${page}&size=${size}&fromDate=${fromDate}&toDate=${toDate}&restaurantId=${restaurantId}&sortByReceiptDateTime=desc`;

    if (orderId !== '') {
      queryString += `&orderId=${orderId}`;
    }

    if (aggregatorId !== '') {
      queryString += `&aggregatorId=${aggregatorId}`;
    }

    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.delivery}${queryString}`, {
        headers: {
          'content-type': 'application/json',
        },
      });
      const res = await request.json();
      debug.success('Order data retrieval result', res);
      return res;
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.delivery}`, error);
      throw await error.response?.json();
    }
  }
}

/**
 * @prettier
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Icon } from '../../../ui-kit';
import { Input } from '../../ui-kit';
import { ClickAwayListener } from '@material-ui/core';

const CustomSelect = (props) => {
  const [active, setActive] = useState(false);

  const filterOptions = (data) => {
    if (data.length > 0 && !props.isPunchCard) {
      if (typeof data[0].posId !== 'undefined') {
        return data?.filter((el) => !props.selectedOptions.find((i) => i?.posId === el?.posId));
      } else {
        return data?.filter((el) => !props.selectedOptions.find((i) => i?.id == el?.id));
      }
    } else return data;
  };
  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <div
        className={`custom-select-container_search ${props.className}`}
        onClick={() => {
          setActive(false);
        }}
      >
        <Input
          className="custom-select-container_search_input"
          fullWidth
          value={props.value}
          onChange={(e) => {
            props.onChange(e);
            setActive(true);
          }}
          onClick={() => {
            setActive((prev) => !prev);
          }}
          variant="outlined"
          label={props.label}
          size={props.size}
          maxLength={40}
          rightIcon="search"
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          inputType={props.inputType}
        />
        {active && props.data?.length > 0 && props.value && (
          <div className="custom-select-container_search_suggestions">
            {filterOptions(props.data).map((el, index) => (
              <div
                key={index}
                className="custom-select-container_search_suggestions_suggestion"
                onClick={() => {
                  let temp = props.data?.map((i) => ({ ...i, number: 1 }));
                  setActive(false);
                  props.setSelectedOptions([
                    ...props.selectedOptions,
                    temp.find((t) => t.posId === el.posId),
                  ]);
                  props.onChange('');
                }}
              >
                <span className="selected-label-id">{el.posId || el?.id}</span>
                <span className="selected-label">{el.posTitle || el?.title}</span>
              </div>
            ))}
          </div>
        )}
        <div className="custom-select-container_search_selected">
          {props.selectedOptions?.map((el, index) => (
            <div className="custom-select-container_search_selected_selected">
              <div
                className="custom-select-container_search_selected_selected_delete"
                onClick={() => {
                  let temp = [...props.selectedOptions];
                  temp[index].number = 0;
                  props.setSelectedOptions(temp.filter((el) => el.number > 0));
                }}
              >
                <Icon name="cross" width={14} height={14} />
              </div>
              <span className="selected-label-id">{el.posId || el?.id}</span>
              <span className="selected-label">{el.posTitle || el?.title}</span>
              {props.multiple && (
                <div className="control_container_select">
                  <div className="decrease_container">
                    <button
                      className="decrease_button"
                      onClick={(e) => {
                        e.preventDefault();
                        let temp = [...props.selectedOptions];
                        temp[index].number -= 1;
                        props.setSelectedOptions(temp.filter((el) => el.number > 0));
                      }}
                    >
                      <span className="decrease_label">
                        <Icon width={12} height={12} name="minus" />
                      </span>
                    </button>
                  </div>
                  <div className="number_container">
                    <span className="decrease_label">{el.number}</span>
                  </div>
                  <div className="increase_container">
                    <button
                      className="increase_button"
                      onClick={(e) => {
                        e.preventDefault();
                        let temp = [...props.selectedOptions];
                        temp[index].number += 1;
                        props.setSelectedOptions(temp.filter((el) => el.number > 0));
                      }}
                    >
                      <span className="increase_label">
                        <Icon width={12} height={12} name="plus" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </ClickAwayListener>
  );
};

CustomSelect.propTypes = {
  multiple: PropTypes.bool,
  selected: PropTypes.array,
  label: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  title: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  isPunchCard: PropTypes.bool,
};

CustomSelect.defaultProps = {
  multiple: true,
  selectedProducts: [],
  label: '',
  size: 'large',
  options: [],
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  title: null,
  className: '',
  isPunchCard: false,
};

export default CustomSelect;

import React from 'react';
import PropTypes from 'prop-types';

const Holder = ({text, mode = 'loading'}) => (
  <div className={mode === 'empty' ? 'story-empty' : 'story-loading'}>
    <h1>{text}</h1>
  </div>
);

Holder.propTypes = {
  mode: PropTypes.oneOf(['empty, loading']),
  text: PropTypes.string,
};

export default Holder;

/**
 * @prettier
 */

import React from 'react';
import STRINGS from '../../../localization';

import './styles.scss';

const WebkassaReceipt = (props) => {
  return (
    <div className="wk-receipt-body">
      <div className="receipt-wrapper" ref={props.refReceipt}>
        <div className="receipt-header">
          <p>{STRINGS.components.webkassaReceipt.organisation}</p>
          <p>{STRINGS.components.webkassaReceipt.identicationNumber}</p>
          <div className="column-block">
            <p>{STRINGS.components.webkassaReceipt.vatNumber}</p>
            <p>{STRINGS.components.webkassaReceipt.vatSeries}</p>
          </div>
          <hr />
          <p>
            {STRINGS.components.webkassaReceipt.shift} {props.check.ShiftNumber}
          </p>
          <p>
            {STRINGS.components.webkassaReceipt.checkOrderNumber} {props.check.OrderNumber}
          </p>
          <p className="left-sided-item">
            {STRINGS.components.webkassaReceipt.checkNumber} {props.check.Number}
          </p>
          <p className="left-sided-item">
            {STRINGS.components.webkassaReceipt.cashier} {props.check.EmployeeName}
          </p>
          <p className="left-sided-item">{props.check.OperationTypeText.toUpperCase()}</p>
          <hr />
        </div>
        <div className="receipt-body">
          {props.check.Positions.map((position) => (
            <>
              <p className="left-sided-item item-name">
                {position.PositionCode}. {position.PositionName}
              </p>
              <p className="left-sided-item item-count">
                {STRINGS.components.webkassaReceipt.currencyMultiplier} {position.Price.toFixed(2)}
              </p>
              <div className="column-block item-cost">
                <p>{STRINGS.components.webkassaReceipt.price}</p>
                <p>{position.Sum.toFixed(2)}</p>
              </div>
            </>
          ))}
          <hr />
        </div>
        <div className="wk-receipt-footer">
          {props.check.Payments.map((payment, index) => {
            <div className="column-block" key={`wk-payment-${index}`}>
              <p>{payment.PaymentTypeName}:</p>
              <p>{payment.Sum.toFixed(2)}</p>
            </div>;
          })}
          <div className="column-block">
            <p>{STRINGS.components.webkassaReceipt.total}</p>
            <p>{props.check.Total.toFixed(2)}</p>
          </div>
          <div className="column-block">
            <p>{STRINGS.components.webkassaReceipt.vat}</p>
            <p>{props.check.Tax.toFixed(2)}</p>
          </div>
          <hr />
          <p className="left-sided-item">
            {STRINGS.components.webkassaReceipt.fiscalNumber} {props.check.Number}
          </p>
          <p className="left-sided-item">
            {STRINGS.components.webkassaReceipt.time} {props.check.RegistratedOn}
          </p>
          <p className="left-sided-item">{props.check.Address}</p>
          <p className="left-sided-item">
            {STRINGS.components.webkassaReceipt.fiscalOperator} {props.check.Ofd.Name}
          </p>
          <p className="left-sided-item">
            {STRINGS.components.webkassaReceipt.link} {props.check.Ofd.Host}
          </p>
          <hr />
          <p>{STRINGS.components.webkassaReceipt.fiscalCheckTitle}</p>
          <img
            src={`https://chart.googleapis.com/chart?cht=qr&chl=${encodeURIComponent(
              props.check.TicketUrl,
            )}&chs=160x160&chld=L|0`}
            className="qr-code img-thumbnail img-responsive"
          />
          <p>
            {STRINGS.components.webkassaReceipt.fiscalOperatorNumber}{' '}
            {props.check.CashboxIdentityNumber}
          </p>
          <p>
            {STRINGS.components.webkassaReceipt.cashboxRegNumber}{' '}
            {props.check.CashboxRegistrationNumber}
          </p>
          <p>
            {STRINGS.components.webkassaReceipt.cashboxNumber} {props.check.CashboxUniqueNumber}
          </p>
          <p>{STRINGS.components.webkassaReceipt.webkassaLink}</p>
        </div>
      </div>
    </div>
  );
};

export default WebkassaReceipt;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../loader';
import RestaurantsService from '../../../../services/restaurants';
import STRINGS from '../../../../localization';
import { useSelector } from 'react-redux';
import { Button, Form, Checkbox, Drawer, CustomSelect } from '../../../ui-kit';
import './styles.scss';
import Separator from '../../separator';

const SendMenu = (props) => {
  const listSize = 15;
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [forAll, setForAll] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurants, setSelectedRestaurants] = useState([props.currentRestaurant]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const getRestaurantsByQuery = async (requestParams) => {
      const response = await RestaurantsService.getRestaurants(requestParams);
      if (response) {
        const responseCopy = response.data.map((el) => ({ ...el, posId: el.id }));
        setRestaurants(responseCopy);
      } else {
        setRestaurants([]);
      }
    };
    getRestaurantsByQuery({ langId, page: 1, size: listSize, search: query });
  }, [query]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <Form
        className="send-menu-modal"
        onSubmit={() =>
          props.saveChanges({
            forAll,
            restaurants: selectedRestaurants.map((el) => ({
              restaurantId: parseInt(el.id || el.posId, 10),
            })),
          })
        }
      >
        <header>
          <h1>{props.title}</h1>
        </header>
        {props.loading ? (
          <Loader />
        ) : (
          <body>
            <Checkbox
              className="check"
              label="Отправить во все рестораны"
              checked={forAll}
              onChange={() => setForAll((prev) => !prev)}
            />
            {!forAll && (
              <CustomSelect
                className="container_fullwidth_one_first"
                label={STRINGS.screens.restaurants.qq}
                data={restaurants}
                value={query}
                inputType="default"
                onChange={setQuery}
                selectedOptions={selectedRestaurants}
                setSelectedOptions={setSelectedRestaurants}
                multiple={false}
              />
            )}

          </body>
        )}

        <footer>
          <Button label="Отправить" typeButton="submit" loading={props.loading} />
        </footer>
      </Form>
    </Drawer>
  );
};

SendMenu.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
SendMenu.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default SendMenu;

import React, { useEffect, useState } from 'react';
import STRINGS from '../../../localization';
import UsersView from './users-view';
import { Toast } from '../../ui-kit';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UsersService from '../../../services/users';

const UsersContainer = () => {
  const history = useHistory();
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const search = useSelector((store) => store.search.value);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [bottomLoading, setBottomLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState('');
  const [editableUser, setEditableUser] = useState();
  const [showConfirm, setShowConfirm] = useState('');
  const [sort, setSort] = useState({ type: '', sort: '' });
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

  const translateStatus = (status) => {
    if (status) {
      return { button: 'secondary', text: STRINGS.components.statuses.active };
    } else {
      return { button: 'danger', text: STRINGS.components.statuses.disabled };
    }
  };
  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });
  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await UsersService.getUsers({
        langId: langId,
        page: page + 1,
        size: 30,
        sort: sorts,
        search: search,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setUsers({
        isLast: resp.isLast,
        data: [...users?.data, ...resp?.data],
      });
    } catch (err) {
      if (err.status) {
        Toast.error(STRINGS.notify.listFailure);
      }
      setBottomLoading(false);
    }
  };
  const saveChanges = async (e) => {
    setLoading(true);
    try {
      await UsersService.updateUser(editableUser, e);
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
    setPage(1);
    getUsers({
      langId: langId,
      page: 1,
      size: 30,
      sort: sorts,
      search: search,
    });
    setShowModal('');
    setEditableUser();
    setLoading(false);
  };
  const getUsers = async (props) => {
    setSkeletVisible(true);
    try {
      const resp = await UsersService.getUsers(props);
      setUsers({ isLast: resp.isLast, data: resp.data });
    } catch (error) {}
    setSkeletVisible(false);
  };

  const changeStatus = async (user) => {
    try {
      await UsersService.updateStatus(user?.id, { active: !user?.active });
      let temp = [...users.data];
      temp.find((el) => el.id === user?.id).active = !user.active;
      setUsers((old) => ({ isLast: old.isLast, data: [...temp] }));
      Toast.success(STRINGS.notify.status);
    } catch (error) {
      Toast.error(STRINGS.notify.statusFailure);
    }
  };

  useEffect(() => {
    setPage(1);
    getUsers({
      langId: langId,
      page: 1,
      size: 30,
      sort: sorts,
      search: search,
    });
  }, [sort, search]);

  const applySort = (mode) => {
    setSort((prevState) => {
      return {
        sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
        type: mode,
      };
    });
  };

  return (
    <UsersView
      history={history}
      loading={loading}
      setLoading={setLoading}
      users={users}
      showModal={showModal}
      setShowModal={setShowModal}
      editableUser={editableUser}
      setEditableUser={setEditableUser}
      saveChanges={saveChanges}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      sort={sort}
      applySort={applySort}
      skeletVisible={skeletVisible}
      changeStatus={changeStatus}
      translateStatus={translateStatus}
      showChangeStatus={showChangeStatus}
      setShowChangeStatus={setShowChangeStatus}
    />
  );
};

export default UsersContainer;

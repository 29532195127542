/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import TitleComponent from './title-component';
import './styles.scss';

const ProductWishes = ({ modificators = [], isAdd = true }) => {
  return (
    modificators.length > 0 && (
      <div className="details-container">
        <p className={`detail-title ${isAdd ? 'add' : 'remove'}`}>
          {isAdd
            ? STRINGS.components.descriptionRestaurantModal.additionOrderTitle
            : STRINGS.components.descriptionRestaurantModal.remove}
        </p>
        {modificators.map((comp) => (
          <TitleComponent key={comp.name} compData={comp} />
        ))}
      </div>
    )
  );
};

export default ProductWishes;

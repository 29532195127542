import LocalizedStrings from 'react-localization';

import ru from './languages/ru';
import en from './languages/en';
import kk from './languages/kaz';

const STRINGS = new LocalizedStrings({
  ru,
  en,
  kk,
});

export default STRINGS;

/**
 * @prettier
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Icon, Input } from '..';
import ClickAwayListener from '@mui/material/ClickAwayListener';
const CustomDropdown = (props) => {
  const [active, setActive] = useState(false);

  const handleClickAwayEvent = () => {
    setActive(false);
  };
  const renderRowArrowType = () => {
    let str = 'expand-more';
    if (active) {
      str = 'expand-less';
    } else {
      str = 'expand-more';
    }
    return str;
  };

  return (
    <div className={`custom-dropdown-container_search ${props.className}`}>
      <ClickAwayListener onClickAway={handleClickAwayEvent}>
        <div>
          <Input
            className="custom-dropdown-container_search_input"
            fullWidth
            variant="outlined"
            readOnly={true}
            label={props.label}
            onClick={() => {
              const temp = active;
              setActive(!temp);
            }}
            size={props.size}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            inputType={props.inputType}
            rightIcon={renderRowArrowType()}
          />
          {active && props.data.filter((el) => !el.number).length > 0 && (
            <div className="custom-dropdown-container_search_suggestions">
              {props.data
                .filter((el) => !el.number)
                .map((el) => (
                  <div
                    className="custom-dropdown-container_search_suggestions_suggestion"
                    onClick={() => {
                      let temp = [...props.data];
                      temp.find((i) => i.id === el.id).number = 1;
                      props.setData(temp);
                      setActive(false);
                    }}
                    onBlur={() => setActive(false)}
                  >
                    <span className="custom-dropdown-container_search_suggestions_suggestion-label">
                      {el.title}
                    </span>
                    <div className="control_container">
                      {el.number === 0 ? (
                        <></>
                      ) : (
                        <Icon
                          name="cross"
                          width={14}
                          height={14}
                          onClick={() => {
                            let temp = [...props.data];
                            temp.find((i) => i.id === el.id).number = 0;
                            props.setData(temp);
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div className="custom-dropdown-container_search_selected">
            {[...props.data?.filter((el) => !!el.number)]?.map((el) => (
              <div className="custom-dropdown-container_search_selected_selected">
                <div
                  className="custom-dropdown-container_search_selected_selected_delete"
                  onClick={() => {
                    let temp = [...props.data];
                    temp.find((i) => i.id === el.id).number = 0;
                    props.setData(temp);
                  }}
                >
                  <Icon name="cross" width={14} height={14} />
                </div>
                <span className="custom-dropdown-container_search_suggestions_suggestion-label">
                  {el.title}
                </span>
                {props.multiple && (
                  <div className="control_container_dropdown">
                    <div className="decrease_container">
                      <button
                        className="decrease_button"
                        onClick={(e) => {
                          e.preventDefault();
                          let temp = [...props.data];
                          temp.find((i) => i.id === el.id).number -= 1;
                          props.setData(temp);
                        }}
                      >
                        <span className="decrease_label">
                          <Icon width={12} height={12} name="minus" />
                        </span>
                      </button>
                    </div>
                    <div className="number_container">
                      <span className="decrease_label">{el.number}</span>
                    </div>
                    <div className="increase_container">
                      <button
                        className="increase_button"
                        onClick={(e) => {
                          e.preventDefault();
                          let temp = [...props.data];
                          temp.find((i) => i.id === el.id).number += 1;
                          props.setData(temp);
                        }}
                      >
                        <span className="increase_label">
                          <Icon width={12} height={12} name="plus" />
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

CustomDropdown.propTypes = {
  multiple: PropTypes.bool,
  selected: PropTypes.array,
  label: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  title: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

CustomDropdown.defaultProps = {
  multiple: true,
  selectedProducts: [],
  label: '',
  size: 'large',
  options: [],
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  title: null,
  className: '',
};

export default CustomDropdown;

/**
 * @prettier
 */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useOutsideAlerter } from '../../utils/hooks';

import { Icon } from '../index';

import './styles.scss';

/**
 * @component ChipInput
 * @example
 * <ChipInput />
 */
const ChipInput = (props) => {
  const [isFocused, setFocused] = useState(false);
  const [dropdownList, setDropdownList] = useState(props.dropdownList);
  const blockRef = useRef(null);
  const [enteredChipsArray, setEnteredChipsArray] = useState(
    props.enteredChips?.map((item) => ({ title: item.title, quantity: 1 })),
  );

  useEffect(() => {
    setDropdownList(props.dropdownList);
  }, [props.dropdownList]);

  const handleRemoveChip = (removing) => {
    const val = enteredChipsArray.find((item) => item.title.includes(removing));
    if (props.multiple) {
      if (val.quantity > 1) {
        val.quantity--;
        setEnteredChipsArray([...enteredChipsArray]);
      } else {
        setEnteredChipsArray(enteredChipsArray.filter((item) => item !== val));
      }
    } else {
      setEnteredChipsArray(enteredChipsArray.filter((item) => item !== val));
    }
  };

  const handleInputChange = (e) => {
    props.setCurrentInput(e.target.value);
  };

  const handleInputFocus = () => {
    setFocused(true);
  };

  const clearInput = () => {
    props.setCurrentInput('');
  };

  const handleBlockClick = () => {
    setFocused(true);
  };

  const handleInputSelect = (adding) => {
    if (props.multiple) {
      if (!enteredChipsArray.map((el) => el.title).includes(adding.title)) {
        setEnteredChipsArray([...enteredChipsArray, { title: adding.title, quantity: 1 }]);
      } else {
        enteredChipsArray.find((item) => item.title.includes(adding.title)).quantity++;
        setEnteredChipsArray([...enteredChipsArray]);
      }
      setDropdownList(props.dropdownList);
    } else {
      if (!enteredChipsArray.map((el) => el.title).includes(adding.title)) {
        setEnteredChipsArray([...enteredChipsArray, { title: adding.title, quantity: 1 }]);
        clearInput();
      }
      setDropdownList(props.dropdownList);
    }
  };

  useOutsideAlerter(blockRef, () => {
    setFocused(false);
  });

  return (
    <div
      className={`chip_input ${props.className}`}
      ref={blockRef}
      onClick={handleBlockClick}
      name={props.name}
    >
      <div
        className={
          isFocused ? 'chip_input_wrapper_focused chip_input_wrapper' : 'chip_input_wrapper'
        }
      >
        <div className="chip_input_wrapper_chips">
          {enteredChipsArray.map((elem, index) => (
            <div
              onClick={() => handleRemoveChip(elem.title)}
              className="chip_input_wrapper_chips_chip"
              key={index}
            >
              <span className="chip_input_wrapper_chips_chip_text">{elem.title}</span>
              {props.multiple && (
                <span className="chip_input_wrapper_chips_chip_text">x{elem.quantity}</span>
              )}
              <Icon width={18} height={18} name="cross" />
            </div>
          ))}
        </div>
        <div className="chip_input_wrapper_input">
          <input
            style={{ display: 'none' }}
            name={props.name}
            value={JSON.stringify(enteredChipsArray)}
          />
          <input
            readOnly
            className="chip_input_wrapper_input_field"
            placeholder={props.inputPlaceholder}
            ref={props.inputRef}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
      <div
        className={
          isFocused && !props.multiple
            ? dropdownList.filter((el) => !enteredChipsArray?.includes(el.title))?.length > 0
              ? 'chip_input_select'
              : 'chip_input_select_close'
            : isFocused
            ? 'chip_input_select'
            : 'chip_input_select_close'
        }
      >
        {!props.multiple
          ? dropdownList
              .filter((el) => !enteredChipsArray.map((el) => el.title)?.includes(el.title))
              .map((item) => (
                <div
                  className="chip_input_item"
                  key={item.id}
                  onClick={(e) => {
                    handleInputSelect(item);
                  }}
                >
                  {item.title}
                </div>
              ))
          : dropdownList.map((item) => (
              <div
                className="chip_input_item"
                key={item.id}
                onClick={(e) => {
                  handleInputSelect(item);
                }}
              >
                {item.title}
              </div>
            ))}
      </div>
    </div>
  );
};

/**
 * Properties
 */

ChipInput.propTypes = {
  className: PropTypes.string,
  enteredChips: PropTypes.array,
  setEnteredChips: PropTypes.func,
  currentInput: PropTypes.string,
  setCurrentInput: PropTypes.func,
  inputRef: PropTypes.object,
  dropdownList: PropTypes.array,
  inputPlaceholder: PropTypes.string,
  multiple: PropTypes.bool,
};

ChipInput.defaultProps = {
  className: '',
  enteredChips: [],
  setEnteredChips: () => {},
  currentInput: '',
  setCurrentInput: () => {},
  inputRef: null,
  dropdownList: [],
  inputPlaceholder: '',
  multiple: false,
};

export default ChipInput;

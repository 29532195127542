/**
 * @prettier
 */

import React from 'react';

import { Icon } from '../../../ui-kit';
import { Paper } from '../../ui-kit';
import { Skeleton } from '../../ui-kit';
import './styles.scss';

const SkeletRestaurants = () => (
  <Paper>
    <div className="restaurant-view-container">
      <div>
        <div className="block">
          <div className="subtitle">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="menu">
            <Skeleton className="picture" />
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <div className="icon">
              <Icon name="arrow-right" width={26} height={26} />
            </div>
          </div>
          <div className="menu">
            <Skeleton className="picture" />
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <div className="icon">
              <Icon name="arrow-right" width={26} height={26} />
            </div>
          </div>
          <div className="menu">
            <Skeleton className="picture" />
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <div className="icon">
              <Icon name="arrow-right" width={26} height={26} />
            </div>
          </div>
          <div className="menu">
            <Skeleton className="picture" />
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <div className="icon">
              <Icon name="arrow-right" width={26} height={26} />
            </div>
          </div>
        </div>
        <div className="block">
          <div className="subtitle">
            <span>
              <Skeleton width="fit-content">
                <span>************************</span>
              </Skeleton>
            </span>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
        </div>
        <div className="block">
          <table className="skelet-table">
            <thead>
              <th>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </th>
              <th>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </th>
              <th>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </th>
              <th>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </th>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5, 6, 7].map((el) => (
                <tr>
                  <td>
                    <Skeleton width="fit-content">
                      <span>************</span>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="fit-content">
                      <span>************</span>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="fit-content">
                      <span>************</span>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="fit-content">
                      <span>************</span>
                    </Skeleton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <div className="block">
          <div className="subtitle">
            <span>
              <Skeleton width="fit-content">
                <span>************************</span>
              </Skeleton>
            </span>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>

            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
        </div>
        <div className="block">
          <div className="subtitle">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
        </div>
        <div className="block">
          <div className="subtitle">
            <span>
              <Skeleton width="fit-content">
                <span>************************</span>
              </Skeleton>
            </span>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
        </div>
      </div>
    </div>
  </Paper>
);

export default SkeletRestaurants;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import View from './view';

const Container = () => {
  return <View />;
};

export default Container;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../../ui-kit';
import { Confirm, Table, Tooltip, Button, MainTabs } from '../../ui-kit';
import { translateStatus } from '../../utils/translate-status';
import ComboFields from '../../components/modals/combo-fields';
import Skelet from './skelet';
import colors from '../../styles/colors';
import { Head } from '../../components';
import { URL } from '../../../services/config';
import NOPHOTO from '../../../assets/nophoto.png';
import STRINGS from '../../../localization';
import { generatePrice } from '../../utils/generate-price';
import './styles.scss';

const ProductsView = (props) => {
  const renderClassNameSort = (type) => {
    let str = 'summary_table_heading_sort';
    if (type === props.sort.type) {
      str += ' summary_table_heading_sort_active';
    }
    return str;
  };
  return (
    <>
      <ComboFields
        getModificatorById={props.getModificatorById}
        getModificators={props.getModificators}
        langId={props.langId}
        loading={props.loading}
        setLoading={props.setLoading}
        getSubstitude={props.getSubstitude}
        getFileById={props.getFileById}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.combo.editCombo}
        selectedCombo={props.selectedCombo}
        getComboById={props.getComboById}
        saveChanges={props.saveChanges}
        languages={props.languages}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.combo.deleteQuestion}
        subtitleBold={props.selectedCombo?.title}
        buttons={[
          {
            type: 'danger',
            label: STRINGS.screens.roles.modal.delete,
            onClick: props.handleDeleteProduct,
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              setTimeout(() => props.setSelectedCombo(), 100);
              props.setShowConfirm('');
            },
          },
        ]}
      />
      <Confirm
        visible={props.showConfirm === 'export'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.combo.exportQuestion}
        buttons={[
          {
            label: STRINGS.screens.roles.modal.export,
            onClick: () => {
              props.setShowConfirm('');
              props.exportProducts();
            },
          },
          {
            label: STRINGS.screens.roles.modal.cancel,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
            },
          },
        ]}
      />
      <Head
        label={STRINGS.screens.combo.combo}
        button={{
          icon: false,
          label: STRINGS.screens.combo.export,
          loading: props.exportLoading,
          outline: true,
          onClick: () => props.setShowConfirm('export'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.combo.combo,
          },
        ]}
      />
      <div>
        <MainTabs
          active={props.selectedComboCategory}
          data={props.categories.map((el) => ({ label: el.label, key: el.id }))}
          onClick={props.setSelectedComboCategory}
        />
        <div className="summary_table">
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <>
              <Table
                loading={props.bottomLoading}
                head={() => (
                  <tr className="summary_table_heading">
                    <th />
                    <th className="summary_table_heading_cell">
                      <div>
                        <span>Id</span>
                        <span
                          onClick={() => props.applySort('PosId')}
                          className={renderClassNameSort('PosId')}
                        >
                          <div
                            className={
                              props.sort.sort === 'desc' && props.sort.type === 'PosId'
                                ? 'summary_table_heading_arrow'
                                : 'summary_table_heading_arrow_active'
                            }
                          >
                            <Icon
                              width={16}
                              height={16}
                              color={colors.color_gray._3}
                              name="arrow-bottom"
                            />
                          </div>
                        </span>
                      </div>
                    </th>
                    <th>
                      <span>{STRINGS.screens.goods.qg}</span>
                    </th>
                    <th>
                      <span>{STRINGS.screens.goods.microsTitle}</span>
                    </th>
                    <th className="summary_table_heading_cell">{STRINGS.screens.goods.title}</th>
                    <th>{STRINGS.screens.goods.prices.default}</th>
                    <th className="summary_table_heading_cell">
                      <div>
                        <span>{STRINGS.screens.goods.status}</span>
                        <span
                          onClick={() => props.applySort('Status')}
                          className={renderClassNameSort('Status')}
                        >
                          {props.sort.sort === 'desc' && props.sort.type === 'Status'
                            ? 'Z - a'
                            : 'A - z'}
                        </span>
                      </div>
                    </th>
                    <th />
                  </tr>
                )}
              >
                <>
                  {props.combo?.data?.map((item) => (
                    <tr
                      key={item.posId}
                      className="summary_table_body"
                      onClick={async (event) => {
                        props.history.push(`combo/${item.posId}/`, {
                          title: item?.title,
                          status: item?.status,
                        });
                        event.stopPropagation();
                      }}
                    >
                      <td>
                        {item?.fileId ? (
                          <img
                            className="image"
                            style={{ width: '100px' }}
                            src={`${URL}files/${item?.fileId}`}
                          />
                        ) : (
                          <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                        )}
                      </td>
                      <td>{item.posId}</td>
                      <td>{item.serialNumber}</td>
                      <td>{item.posTitle}</td>
                      <td>{item.title}</td>
                      <td>{generatePrice(item.prices.default)}</td>

                      <td>
                        <Button
                          className="outline"
                          size="large"
                          label={translateStatus(item?.status).status}
                          type={translateStatus(item?.status).button}
                          typeButton="submit"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.changeStatus({ id: item?.posId, status: item?.status });
                          }}
                        />
                      </td>
                      <td className="summary_table_edit">
                        <div className="summary_table_edit_wrapper">
                          <Tooltip title={STRINGS.components.tooltips.delete}>
                            <div
                              className="summary_table_iconWrapper"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.setSelectedCombo({ id: item?.posId, title: item?.title });
                                props.setShowConfirm('delete');
                              }}
                            >
                              <Icon name="trash-outline" width={24} height={24} />
                            </div>
                          </Tooltip>
                          <Tooltip title={STRINGS.components.tooltips.edit}>
                            <div
                              className="summary_table_iconWrapper"
                              onClick={async (event) => {
                                event.stopPropagation();
                                props.setSelectedCombo({ id: item?.posId });
                                props.setShowModal('edit');
                              }}
                            >
                              <Icon name="edit" width={24} height={24} />
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

ProductsView.propTypes = {
  languages: PropTypes.array,
  goods: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableProduct: PropTypes.object,
  setEditableProduct: PropTypes.func,
  saveChanges: PropTypes.func,
};

ProductsView.defaultProps = {
  languages: [],
  goods: null,
  showModal: '',
  setShowModal: () => {},
  editableProduct: {},
  setEditableProduct: () => {},
  saveChanges: () => {},
};

export default ProductsView;

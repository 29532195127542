/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../../ui-kit';
import { Table, Tooltip, Confirm } from '../../ui-kit';
import PointsStoreFields from '../../components/modals/points-store-fields';
import SkeletManagers from './skelet';
import { Head } from '../../components';
import NOPHOTO from '../../../assets/nophoto.png';
import { URL } from '../../../services/config';

import STRINGS from '../../../localization';
import './styles.scss';

const PointsView = (props) => {
  return (
    <>
      <PointsStoreFields
        title={STRINGS.screens.points.addLevel}
        loading={props.loading}
        setLoading={props.setLoading}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'add'}
        getLevelProducts={props.getLevelProducts}
        getProducts={props.getProducts}
        saveChanges={props.addLevel}
        getCombo={props.getCombo}
      />
      <PointsStoreFields
        title={STRINGS.screens.points.editLevel}
        loading={props.loading}
        setLoading={props.setLoading}
        saveChanges={props.saveChanges}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        getLevelProducts={props.getLevelProducts}
        selectedLevel={props.selectedLevel}
        getProducts={props.getProducts}
        getCombo={props.getCombo}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.points.confirmDelete}
        subtitleBold={props.selectedLevel?.amount}
        buttons={[
          {
            type: 'danger',
            label: STRINGS.screens.roles.modal.delete,
            onClick: props.handleDeleteLevel,
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
            },
          },
        ]}
      />
      <Head
        label={STRINGS.screens.points.points}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.points.addLevel,
          onClick: () => props.setShowModal('add'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.points.points,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          <div className="stoplists-toolbar">
            {/* <div className="buttons">
              {Categories?.map((el) => (
                <button
                  data-active={props.selectedCategory === el?.key}
                  onClick={() => props.setSelectedCategory(el?.key)}
                >
                  {el?.label}
                </button>
              ))}
            </div> */}
          </div>
          {props.skeletVisible ? (
            <SkeletManagers />
          ) : (
            <Table
              head={() => (
                <tr className="summary_table_heading">
                  <th />
                  <th>{STRINGS.screens.points.id}</th>
                  <th>{STRINGS.screens.points.levelTitle}</th>
                  <th>{STRINGS.screens.points.amount}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.levels?.map((item) => (
                  <tr key={item.id} className="summary_table_body unselectable">
                    <td>
                      {item?.fileId ? (
                        <img
                          className="image"
                          style={{ width: '100px' }}
                          src={`${URL}files/${item?.fileId}`}
                        />
                      ) : (
                        <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                      )}
                    </td>
                    <td>{item.id}</td>
                    <td>
                      {item?.priceLevelData.find((el) => el.languageId === props.langId).title}
                    </td>
                    <td>{item.amount}</td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={() => {
                              props.setSelectedLevel({ id: item?.id, amount: item?.amount });
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.edit}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={async (event) => {
                              event.stopPropagation();
                              props.setSelectedLevel({
                                id: item?.id,
                                amount: item?.amount,
                                fileId: item?.fileId,
                              });
                              props.setShowModal('edit');
                            }}
                          >
                            <Icon name="edit" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

PointsView.propTypes = {
  languages: PropTypes.array,
  levels: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableLevel: PropTypes.object,
  setEditableLevel: PropTypes.func,
  saveChanges: PropTypes.func,
};

PointsView.defaultProps = {
  languages: [],
  points: null,
  showModal: '',
  setShowModal: () => {},
  editableLevel: {},
  setEditableLevel: () => {},
  saveChanges: () => {},
};

export default PointsView;

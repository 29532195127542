import React, { useEffect, useState } from 'react';

import './styles.scss';

import { Icon } from '../../../../ui-kit';
import { Button, Form, Drawer, AutocompleteStoplists } from '../../../ui-kit';
import Loader from '../loader';

import STRINGS from '../../../../localization';

const StoplistFields = (props) => {
  const [selectedModificators, setSelectedModificators] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [selectedCombos, setSelectedCombos] = useState([]);

  const [selectedStoplistsCategory, setSelectedStoplistsCategory] = useState(0);

  useEffect(() => {
    setSelectedStoplistsCategory(props.selectedStoplistsCategory);
  }, [props.selectedStoplistsCategory]);

  const initialize = () => {
    setSelectedModificators([]);
    setSelectedProducts([]);
    setSelectedCombos([]);
  };

  const onClose = () => {
    props.onClose();
    setTimeout(() => {
      initialize();
    }, 300);
  };

  const renderStopListField = (category) => {
    switch (category) {
      case 0:
        return (
          <div className="container_fullwidth">
            <div className="subtitle">
              <span>{STRINGS.screens.stoplists.qi}</span>
            </div>
            <AutocompleteStoplists
              restaurantId={props.restaurantId}
              label={STRINGS.screens.stoplists.qu}
              getSuggestions={props.getModificatorsSuggestions}
              setSelected={setSelectedModificators}
              selected={selectedModificators}
              inputType="default"
              className="container_fullwidth_one_first"
            />
            {selectedModificators?.length > 0 && (
              <div className="selected">
                {selectedModificators.map((el) => (
                  <div
                    onClick={() => {
                      setSelectedModificators((prev) => prev.filter((i) => i.posId !== el.posId));
                    }}
                  >
                    <div className="cross">
                      <Icon name="cross" width={14} height={14} />
                    </div>
                    <div>
                      <span>{el.title}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      case 1:
        return (
          <div className="container_fullwidth">
            <div className="subtitle">
              <span>{STRINGS.screens.stoplists.qo}</span>
            </div>
            <AutocompleteStoplists
              inputType="default"
              restaurantId={props.restaurantId}
              label={STRINGS.screens.stoplists.qy}
              getSuggestions={props.getProductsSuggestions}
              setSelected={setSelectedProducts}
              selected={selectedProducts}
              className="container_fullwidth_one_first"
            />
            {selectedProducts?.length > 0 && (
              <div className="selected">
                {selectedProducts.map((el) => (
                  <div
                    onClick={() => {
                      setSelectedProducts((prev) => prev.filter((i) => i.posId !== el.posId));
                    }}
                  >
                    <div className="cross">
                      <Icon name="cross" width={14} height={14} />
                    </div>
                    <div>
                      <span>{el.title}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className="container_fullwidth">
            <div className="subtitle">
              <span>{STRINGS.screens.stoplists.qn}</span>
            </div>
            <AutocompleteStoplists
              inputType="default"
              restaurantId={props.restaurantId}
              label={STRINGS.screens.stoplists.qf}
              getSuggestions={props.getCombosSuggestions}
              setSelected={setSelectedCombos}
              selected={selectedCombos}
              className="container_fullwidth_one_first"
            />
            {selectedCombos?.length > 0 && (
              <div className="selected">
                {selectedCombos.map((el) => (
                  <div
                    onClick={() => {
                      setSelectedCombos((prev) => prev.filter((i) => i.posId !== el.posId));
                    }}
                  >
                    <div className="cross">
                      <Icon name="cross" width={14} height={14} />
                    </div>
                    <div>
                      <span>{el.title}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={onClose}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges({
              data: {
                modificators: selectedModificators.map((el) => ({
                  stopListed: true,
                  modificatorId: el.posId,
                })),
                products: selectedProducts.map((el) => ({
                  stopListed: true,
                  productId: el.posId,
                })),
                combos: selectedCombos.map((el) => ({
                  stopListed: true,
                  comboId: el.posId,
                })),
              },
              restaurantId: props.restaurantId,
            });
            initialize();
          }}
        >
          <div className="header-nosteps">
            <div className="title">
              <span>{props.title}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">{renderStopListField(selectedStoplistsCategory)}</div>
          )}
          <div className="footer">
            <div />
            <Button
              disabled={
                !selectedModificators.length && !selectedProducts.length && !selectedCombos.length
              }
              className="submit"
              label={STRINGS.screens.modal.save}
              size="large"
              type={
                !selectedModificators.length && !selectedProducts.length && !selectedCombos.length
                  ? 'disabled'
                  : 'primary'
              }
              typeButton="submit"
              loading={props.loading}
            />
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default StoplistFields;

/**
 * @prettier
 */

import React from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '../../../ui-kit';
import { ComboReceipt, ProductReceipt } from '../../ui-kit';
import STRINGS from '../../../localization';

import './styles.scss';

const Receipt = (props) => {
  const restAddress = useSelector((store) => store.user?.restaurant?.address);
  return (
    <div className="receipt-container">
      <div className="receipt-wrapper" ref={props.refReceipt}>
        <Icon
          name={`receiptLogo-${props.order.orderMetadata.aggregatorKey}`}
          width={195}
          height={65}
        />
        <div className="receipt-add-info">
          <p>
            {restAddress.city?.cityData[0]?.name} {restAddress.addressData[0]?.title}
          </p>
        </div>
        <div className="receipt-order-number">
          {props.archive && (props.status === 'canceled' || props.status === 'failed') && (
            <p className="receipt-status">{STRINGS.components.statuses[props.status]}</p>
          )}
          <p>
            {STRINGS.components.receipt.numberOrder}/{STRINGS.components.receipt.courierCode}
          </p>
          <p className="receipt-number">
            {props.order?.orderPatternId}/{props.order?.orderMetadata?.pickUpCode}
          </p>
          <p>{STRINGS.components.receipt.orderCode}</p>
          <p className="receipt-order-code">{props.order?.orderMetadata?.orderCode}</p>
        </div>
        <div className="receipt-date-time">
          <div>
            <p className="receipt-date-time-title">{STRINGS.components.receipt.dateOrder}</p>
            <p className="receipt-data">
              {props.order?.orderMetadata?.receiptDateTime &&
                new Date(props.order?.orderMetadata?.receiptDateTime)
                  .toLocaleDateString()
                  .toString()}
            </p>
          </div>
          <div className="receipt-time">
            <p className="receipt-date-time-title">{STRINGS.components.receipt.timeOrder}</p>
            <p className="receipt-data">
              {props.order?.orderMetadata?.receiptDateTime &&
                new Date(props.order?.orderMetadata?.receiptDateTime)
                  .toLocaleTimeString()
                  .toString()}
            </p>
          </div>
        </div>
        <div className="receipt-structure-wrapper">
          <p>{STRINGS.components.receipt.position}</p>
          <div className="receipt-structure">
            <p className="receipt-count-pd">{STRINGS.components.receipt.count}</p>
            <p>{STRINGS.components.receipt.price}</p>
          </div>
        </div>
        {props.order?.combo?.map((combo) => (
          <ComboReceipt key={combo.posId} combo={combo} />
        ))}
        {props.order?.products?.map((product) => (
          <ProductReceipt key={product.id} product={product} />
        ))}
        <div className="receipt-footer">
          <div>
            <p>{STRINGS.components.receipt.positionCount}</p>
            <p>{STRINGS.components.receipt.sumOrder}</p>
          </div>
          <div className="receipt-footer-right">
            <p>{props.order?.orderMetadata?.amount}</p>
            <p>
              {props.order?.orderMetadata?.price} {STRINGS.components.receipt.tg}
            </p>
          </div>
        </div>
        <div className="receipt-result-price">
          <p>{STRINGS.components.receipt.total}</p>
          <p>
            {props.order?.orderMetadata?.price} {STRINGS.components.receipt.tg}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Receipt;

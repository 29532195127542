/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import STRINGS from '../../../../localization/index';
import { Modal, Button, TextArea, Input } from '../../../ui-kit';
import { validateObject } from '../../../utils/input-functions';
import { useSelector } from 'react-redux';

import './styles.scss';

const CreateDefinition = (props) => {
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const { languages } = useSelector((store) => store.stuff);
  const [definitionData, setDefinitionData] = useState([
    { languageId: 1, title: '', description: '' },
    { languageId: 2, title: '', description: '' },
    { languageId: 3, title: '', description: '' },
  ]);
  const titles = definitionData.reduce(
    (acc, el, key) => ({ ...acc, [`title${key}`]: el?.title }),
    [],
  );
  const descriptions = definitionData.reduce(
    (acc, el, key) => ({ ...acc, [`description${key}`]: el?.description }),
    [],
  );
  const SCHEME = {
    title0: (value) => value?.length > 0,
    title1: (value) => value?.length > 0,
    title2: (value) => value?.length > 0,
    description0: (value) => value?.length > 0,
    description1: (value) => value?.length > 0,
    description2: (value) => value?.length > 0,
  };
  const [validation, setValidation] = useState();
  const [isFocus, setIsFocus] = useState({
    title0: true,
    title1: true,
    title2: true,
    description0: true,
    description1: true,
    description2: true,
  });
  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };
  const prepareDefinitionData = (dataPatched) => {
    return definitionData.map((el, key) => ({
      languageId: el.languageId,
      title: dataPatched[key]?.title || '',
      description: dataPatched[key]?.description || '',
    }));
  };
  useEffect(() => {
    if (props.definition?.length) {
      setDefinitionData(prepareDefinitionData(props.definition));
    }
  }, [props.definition]);

  useEffect(() => {
    setValidation(validateObject(Object.assign(titles, descriptions), SCHEME));
  }, [definitionData]);

  return (
    <Modal maxWidth="sm" title={props.title} open={props.open} onClose={() => props.onClose()}>
      <div className="create-definition">
        <aside>
          <div className="container_fullwidth">
            <div className="definitions-buttons">
              <div className="langs">
                {languages?.map((language) => (
                  <Button
                    label={language.name}
                    value={language.id}
                    size="medium"
                    className="lang"
                    type={selectedLanguage === language.id ? 'primary' : 'default'}
                    data-active={selectedLanguage === language.id}
                    onClick={(e) => {
                      setSelectedLanguage(language.id);
                      e.preventDefault();
                    }}
                  >
                    <span className="day-label">{language?.name}</span>
                  </Button>
                ))}
              </div>
            </div>
            <Input
              className="container_fullwidth_one_first"
              fullWidth
              onChange={(val) => {
                let temp = [...definitionData];
                temp[selectedLanguage - 1].title = val;
                setDefinitionData(temp);
              }}
              inputType={
                !isFocus?.[`title${selectedLanguage - 1}`] &&
                !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                  ? 'danger'
                  : 'default'
              }
              value={definitionData[selectedLanguage - 1]?.title}
              variant="outlined"
              label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
              size="large"
              maxLength={40}
              onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
              onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
            />
            <TextArea
              textareaType={
                !isFocus?.[`description${selectedLanguage - 1}`] &&
                !validation?.data?.[`description${selectedLanguage - 1}`]?.isValid
                  ? 'danger'
                  : 'default'
              }
              className="container_fullwidth_one"
              onChange={(val) => {
                let temp = [...definitionData];
                temp[selectedLanguage - 1].description = val;
                setDefinitionData(temp);
              }}
              value={definitionData[selectedLanguage - 1]?.description}
              label={`${STRINGS.screens.modal.description[selectedLanguage - 1]} *`}
              multiline
              rows={4}
              onFocus={() => changeFocus(`description${selectedLanguage - 1}`, true)}
              onBlur={() => changeFocus(`description${selectedLanguage - 1}`, false)}
            />
          </div>
        </aside>
        <div className="footer">
          <Button
            disabled={selectedLanguage === 1}
            className="button"
            label={STRINGS.screens.modal.back}
            size="large"
            type="default"
            typeButton="submit"
            onClick={(e) => {
              e.preventDefault();
              setSelectedLanguage((prevState) => (prevState > 1 ? prevState - 1 : prevState));
            }}
          />
          {selectedLanguage === languages?.length ? (
            <Button
              className="button"
              label={STRINGS.screens.modal.save}
              size="large"
              disabled={!validation.isValid}
              type={!validation.isValid ? 'disabled' : 'primary'}
              loading={props.loading}
              onClick={() => {
                props.saveChanges({ definitionData: definitionData });
              }}
            />
          ) : (
            <Button
              className="button"
              label={STRINGS.screens.modal.next}
              size="large"
              disabled={
                !validation?.data[`title${selectedLanguage - 1}`].isValid &&
                !validation?.data[`description${selectedLanguage - 1}`].isValid
              }
              type={
                validation?.data[`title${selectedLanguage - 1}`].isValid &&
                validation?.data[`description${selectedLanguage - 1}`].isValid
                  ? 'primary'
                  : 'disabled'
              }
              onClick={(e) => {
                e.preventDefault();
                setSelectedLanguage((prevState) => prevState + 1);
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
CreateDefinition.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedUserId: PropTypes.number,
  onEdit: PropTypes.func,
  loading: PropTypes.bool,
};
CreateDefinition.defaultProps = {
  open: false,
  onClose: () => {},
  selectedUserId: null,
  onEdit: () => {},
  loading: false,
};

export default CreateDefinition;

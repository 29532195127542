/**
 * @prettier
 */
import React from 'react';
import STRINGS from '../../../../localization';
import '../styles.scss';
import { Button } from '../../../ui-kit';

const CategoryItem = ({ title, image, navigateTo }) => {
  return (
    <div className="dashboard-category-item">
      <img src={image} alt="#" style={{ width: '70px', height: '70px' }} />
      <span
        className="dashboard-categoty-item-text"
        style={{
          color: 'gray',
          fontSize: '16px',
          padding: '.9rem',
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        {title}
      </span>
      <Button label={STRINGS.screens.stories.AA} onClick={navigateTo} />
    </div>
  );
};

export default CategoryItem;

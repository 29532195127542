import React, {useEffect, useState} from 'react';
import View from './view';
import YandexDiscountsService from '../../../services/yandexDiscounts';
import MainService from '../../../services/main';
import {Toast} from '../../ui-kit';
import {TYPES} from '../../../constants/coupon-types';
import {useHistory, useParams} from 'react-router-dom';
import STRINGS from '../../../localization/index';
import {useSelector} from 'react-redux';

const YandexDiscountContainer = () => {
    const langId = useSelector((store) => store.localization.currentLanguage.id);

    const { languages, backgroundColors } = useSelector((store) => store.stuff);

    const [skeletVisible, setSkeletVisible] = useState(true);

    const [coupon, setCoupon] = useState(undefined);

    const [showModal, setShowModal] = useState('');

    const [loading, setLoading] = useState(false);

    const history = useHistory();

    let { id } = useParams();

    const getCouponById = async () => {
        try {
            return await YandexDiscountsService.getYandexDiscount(id);
        } catch (error) {
            Toast.error(STRINGS.notify.failure);
        }
    };

    const saveChanges = async (f) => {
        setLoading(true);
            const dataToSend = {
                name: f.name,
                discount: f.discount,
                yandexDiscountProducts: f.yandexDiscountProducts || [],
                yandexDiscountCombos: f.yandexDiscountCombos || [],
                yandexDiscountModificators: f.yandexDiscountModificators || [],
            };
            try {
                await YandexDiscountsService.updateYandexDiscount({ id: f.id, data: dataToSend });
                Toast.success(STRINGS.notify.success);
                const resp = await YandexDiscountsService.getYandexDiscount(f.id);
                setCoupon(resp);
                setSkeletVisible(false);
            } catch (error) {
                Toast.error(STRINGS.notify.failure);
            }
            setLoading(false);
            setShowModal('');
            setTimeout(() => setLoading(false), 100);
    };

    const getFileById = async () => {
        try {
            return MainService.getFileById(id);
        } catch (error) {
            Toast.error(STRINGS.notify.imageFailure);
        }
    };

    const getCoupon = async (couponId) => {
        setSkeletVisible(true);
        try {
            const resp = await getCouponById(couponId);
            setCoupon(resp);
            setSkeletVisible(false);
        } catch (error) {
            setSkeletVisible(false);
        }
    };

    useEffect(() => {
        getCoupon(id);
    }, [id]);

    return (
        <View
            TYPES={TYPES}
            langId={langId}
            couponId={id}
            languages={languages}
            yandexDiscount={coupon}
            loading={loading}
            setLoading={setLoading}
            saveChanges={saveChanges}
            history={history}
            getCouponById={getCouponById}
            getFileById={getFileById}
            backgroundColors={backgroundColors}
            showModal={showModal}
            setShowModal={setShowModal}
            skeletVisible={skeletVisible}
        />
    );
};

export default YandexDiscountContainer;
/**
 * @prettier
 */

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MyDrawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';

import './styles.scss';

const useStyles = makeStyles({
  list: {
    width: 'auto',
  },
});

/**
 * @component Drawer
 * @example
 * <Drawer
 *  onClick={() => {}}
 *  visible={false}
 *  anchor="left"
 * />
 */
const Drawer = (props) => {
  const classes = useStyles();
  return (
    <div>
      <MyDrawer
        anchor={props.anchor}
        open={props.visible}
        onClose={props.onClose ? props.onClose : props.onClick}
      >
        <div
          className={clsx(classes.list, {
            [classes.fullList]: undefined,
          })}
          role="presentation"
        >
          {props.children}
        </div>
      </MyDrawer>
    </div>
  );
};

/**
 * Properties
 */

Drawer.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
  children: PropTypes.element,
  anchor: PropTypes.oneOf(['left', 'right']),
  onClose: PropTypes.func,
};
Drawer.defaultProps = {
  onClick: () => {},
  visible: true,
  children: null,
  anchor: 'right',
  onClose: null,
};

export default Drawer;

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ColorSelector = (props) => {
  return (
    <div className="color-selector_container">
      <div className="title">
        <span>{props.label}</span>
      </div>
      <div className="colors">
        {props.colors.map((el) => (
          <button
            value={el?.id}
            name={props.name}
            type="button"
            data-type={el}
            data-active={props.value === el?.id}
            style={{ backgroundColor: el.hex, opacity: '50%', borderColor: el.hex }}
            onClick={() => props.setSelectedColor(el?.id)}
          >
            {props.value === el?.id && <div className="color-selected" />}
          </button>
        ))}
      </div>
    </div>
  );
};

ColorSelector.propTypes = {
  className: PropTypes.string,
  value: '',
  name: PropTypes.string,
  label: PropTypes.string,
  colors: PropTypes.array,
  selected: PropTypes.bool,
};

ColorSelector.defaultProps = {
  className: '',
  value: {},
  name: '',
  label: '',
  colors: [],
  selected: false,
};

export default ColorSelector;

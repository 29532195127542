/**
 * @prettier
 */

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AuthorizationView from './authorization-view';
import LocalizationActions from '../../store/localization/actions';
import MainService from '../../services/main';
import { validateObject } from '../../main/utils/input-functions';
import CurrentUserThunks from '../../store/current-user/thunks';

const SCHEME = {
  email: (value) => value.length > 0,
  password: (value) => value?.length >= 4,
};

const AuthorizationContainer = () => {
  const [emailFocus, setEmailFocus] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({ isValid: false, data: {} });
  const { languages } = useSelector((store) => store.stuff);
  const imagesBox = useRef();

  const dispatch = useDispatch();

  const selectedLanguage = useSelector((store) => store.localization.currentLanguage);
  const authorization = () => {
    setLoading(true);
    dispatch(CurrentUserThunks.authentication({ email, password }, () => setLoading(false)));
  };
  const slide = (first) => {
    if (imagesBox.current) {
      first.removeAttribute('style');
      const before = [...imagesBox.current.children].find((htmlEl) =>
        htmlEl.classList.contains('show'),
      );
      if (before) {
        before.classList.remove('show');
        const next = before.nextElementSibling;
        if (next) {
          next.classList.add('show');
        } else {
          first.classList.add('show');
        }
      } else {
        first.classList.add('show');
      }
    }
  };

  const handleChangeLanguage = (languageCode) => {
    dispatch(LocalizationActions.setLanguage(languageCode));
  };

  useEffect(() => {
    let _slide = setInterval(() => {});
    const promise = new Promise((resolve) => {
      const first = imagesBox.current.children[0];
      first.style.opacity = 1;
      setTimeout(() => first.classList.add('show'), 100);
      resolve(first);
    });

    promise.then((first) => {
      _slide = setInterval(() => slide(first), 8000);
    });

    return () => {
      clearInterval(_slide);
    };
  }, []);

  useEffect(() => {
    if (!window.localStorage.getItem('@localization/current_language')) {
      dispatch(LocalizationActions.setLanguage(languages[0]));
    }
  }, []);

  const onChange = (e) => {
    setValidation(validateObject(e, SCHEME));
  };

  return (
    <AuthorizationView
      languages={languages}
      password={password}
      setPassword={setPassword}
      email={email}
      setEmail={setEmail}
      validation={validation}
      authorization={authorization}
      loading={loading}
      imagesBox={imagesBox}
      selectedLanguage={selectedLanguage}
      emailFocus={emailFocus}
      passwordFocus={passwordFocus}
      setEmailFocus={setEmailFocus}
      setPasswordFocus={setPasswordFocus}
      handleChangeLanguage={handleChangeLanguage}
      onChange={onChange}
    />
  );
};

export default AuthorizationContainer;

import React from 'react';
import { Icon, Tooltip } from '../../ui-kit';
import Loader from './loader';
import { getBeautyDateTimeString } from '../../utils/datetime';
import { URL } from '../../../services/config';
import NOPHOTO from '../../../assets/nophoto.png';
import STRINGS from '../../../localization';
import Row from '../../components/Row';
import ColorRound from '../../components/ColorRound';
import './styles.scss';

const Card = ({story, setDeletableStory, setShowConfirm, handleCardClick, langId}) => {

    const Header = () => (
        <div className="card-header">
            <div className="prop">
                <span className="prop-title">ID:</span>
                <span className="prop-value">{story?.id}</span>
            </div>
            <Tooltip title={STRINGS.components.tooltips.delete}>
                <div
                    className="summary_table_iconWrapper"
                    onClick={() => {
                        setDeletableStory({
                            id: story?.id,
                            title: story?.title,
                        });
                        setShowConfirm('delete');
                    }}
                >
                    <div className="icon">
                        <Icon name="trash-outline" color="black" width={20} height={20} />
                    </div>
                </div>
            </Tooltip>
        </div>
    );

    const Footer = () => {
        const generateIconClassName = () => {
            if (story?.open) {
                return 'up';
            } else {
                return 'down';
            }
        };
        return (
            <div className="card-footer" onClick={() => handleCardClick(story)}>
                <span>{!story?.open ? STRINGS.screens.stories.AA : STRINGS.screens.stories.AB}</span>
                <div className={`icon-${generateIconClassName()}`}>
                    <Icon name="arrow-down" width={20} height={20} color="#0a6cff" />
                </div>
            </div>

        );
    };

    const ShortBody = () => (
        <div className="card-description">
            <Row title={STRINGS.screens.stories.AC} data={story?.title} indent={8} />
            <Row title={STRINGS.screens.stories.AF} data={story?.storyTypeTitle} indent={8} />
        </div>
    );

    const FullBody = () => (
        <div className="card-description-detailed">
            <Row title={STRINGS.screens.stories.AC} data={story?.title} indent={26} />
            <Row title={STRINGS.screens.stories.AD} data={story?.storyData.find((i) => i.languageId === langId)?.subtitle} indent={26} />
            <Row title={STRINGS.screens.stories.AE} data={story?.storyData.find((i) => i.languageId === langId)?.buttonText} indent={26} />
            <Row title={STRINGS.screens.stories.AF} data={story.storyTypeTitle} indent={26} />
            <Row title={STRINGS.screens.stories.AG} data={getBeautyDateTimeString(new Date(story?.startDateTime))} indent={26} />
            <Row title={STRINGS.screens.stories.AH} data={getBeautyDateTimeString(new Date(story?.endDateTime))} indent={26} />
            <Row title={STRINGS.screens.stories.AI} data={story?.link} indent={26} />
            <Row
                title={STRINGS.screens.stories.AJ}
                specific={
                    <ColorRound
                        color={
                            story?.backgroundColor?.hex
                        }
                    />
                }
            />
        </div>
    );

    return (
        <div className="card">
            {!story?.loading && story?.open && (
            <>
                <Header />
                <FullBody />
                <Footer  />
            </>
            )}
            {!story?.loading && !story?.open && (
            <>
                <div className="card-photo">
                    {story?.fileId ? (
                        <img className="image" src={`${URL}files/${story?.fileId}`} />
                    ) : (
                        <img className="image" src={NOPHOTO} />
                    )}
                </div>
                <Header />
                <ShortBody  />
                <Footer />
            </>
            )}
            {story?.loading && (<Loader />)}
        </div>
    );
};

export default Card;

import React from 'react';
import Spinner from '../../../ui-kit/spinner';
import './styles.scss';

const ModalLoader = () => (
<div className="menu-table_loader">
    <Spinner />
</div>);


export default ModalLoader;

/**
 * @prettier
 */
import React from 'react';

import {
  Aggregators,
  CurrentOrders,
  ReadyOrders,
  Archive,
  WebkassaChecks,
  AggregatorsStatus,
} from '../restaurant/pages';

const RESTAURANT_ROUTES = [
  {
    path: '/aggregators',
    component: <Aggregators />,
  },
  {
    path: '/aggregators-status',
    component: <AggregatorsStatus />,
  },
  {
    path: '/current-orders',
    component: <CurrentOrders />,
  },
  {
    path: '/ready-orders',
    component: <ReadyOrders />,
  },
  {
    path: '/archive',
    component: <Archive />,
  },
  {
    path: '/webkassa-checks',
    component: <WebkassaChecks />,
  },
];

export default RESTAURANT_ROUTES;

/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '..';

import './styles.scss';

const Counter = (props) => {
  return (
    <div
      className={`counter_container_search_selected_selected ${props.className} ${
        props.disabled === true && 'disabled'
      }`}
    >
      <span className="label">{props.label}</span>
      <div className="control_container">
        <div className="decrease_container">
          <button
            className="decrease_button"
            onClick={(e) => {
              e.preventDefault();
              const temp = props.number - 1;
              if (temp >= props.min) {
                props.setNumber(temp);
              }
            }}
          >
            <span className="decrease_label">
              <Icon width={12} height={12} name="minus" />
            </span>
          </button>
        </div>
        <div className="number_container">
          <span className="decrease_label">{props.number}</span>
        </div>
        <div className="increase_container">
          <button
            className="increase_button"
            onClick={(e) => {
              e.preventDefault();
              const temp = props.number + 1;
              if (temp <= props.max) {
                props.setNumber(temp);
              }
            }}
          >
            <span className="increase_label">
              <Icon width={12} height={12} name="plus" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

/**
 * Styles
 */

// const inputConfigList = {
//   default: {
//     basicClass: 'default',
//     iconColor: colors.color_gray._4,
//   },
//   primary: {
//     basicClass: 'primary',
//     iconColor: colors.color_black._1,
//   },
//   secondary: {
//     basicClass: 'secondary',
//     iconColor: colors.color_green._0,
//   },
//   danger: {
//     basicClass: 'danger',
//     iconColor: colors.color_gray._4,
//   },
// };

/**
 * SIZES
 */

// const sizes = {
//   small: 'small',
//   medium: 'medium',
//   large: 'large',
// };

/**
 * Properties
 */

Counter.propTypes = {
  disabled: PropTypes.bool,
  number: PropTypes.number,
  defaultValue: PropTypes.number,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
};
Counter.defaultProps = {
  disabled: false,
  number: 1,
  defaultValue: 0,
  type: 'text',
  max: 6,
  min: 1,
};
export default Counter;

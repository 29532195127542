/**
 * @prettier
 */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Drawer,
  ColorSelector,
  InputDate,
  CustomDropzone,
  CustomSelect,
  Select,
  Counter,
  Checkbox,
  Radio,
} from '../../../ui-kit';
import GoodsService from '../../../../services/goods';
import CouponService from '../../../../services/coupons';
import ComboService from '../../../../services/combo';
import ModificatorsService from '../../../../services/modificators';
import MainService from '../../../../services/main';
import './styles.scss';
import { Toast } from '../../../ui-kit';
import Loader from '../loader';
import { getBeautyDateString } from '../../../utils/datetime';
import { validateObject } from '../../../utils/input-functions';
import Separator from '../../separator';
import STRINGS from '../../../../localization';
import WorkingTime from './components/WorkingTime';

const CouponFields = (props) => {
  const FOCUS = {
    expirationDate: true,
    title0: true,
    title1: true,
    title2: true,
    file: true,
    code: true,
    price: true,
    discount: true,
    countOfGeneratedCoupons: true,
  };

  const INITIAL_WORKING_TIME = [
    {
      id: 1,
      day: STRINGS.general.days.monday,
      checked: false,
      openTime: '',
      closeTime: '',
    },
    {
      id: 2,
      day: STRINGS.general.days.tuesday,
      checked: false,
      openTime: '',
      closeTime: '',
    },
    {
      id: 3,
      day: STRINGS.general.days.wednesday,
      checked: false,
      openTime: '',
      closeTime: '',
    },
    {
      id: 4,
      day: STRINGS.general.days.thursday,
      checked: false,
      openTime: '',
      closeTime: '',
    },
    {
      id: 5,
      day: STRINGS.general.days.friday,
      checked: false,
      openTime: '',
      closeTime: '',
    },
    {
      id: 6,
      day: STRINGS.general.days.saturday,
      checked: false,
      openTime: '',
      closeTime: '',
    },
    {
      id: 7,
      day: STRINGS.general.days.sunday,
      checked: false,
      openTime: '',
      closeTime: '',
    },
  ];

  const [daysOfWeek, setDaysOfWeek] = useState(INITIAL_WORKING_TIME);

  const maxStep = 6;

  const [step, setStep] = useState(1);

  const [id, setId] = useState();

  const [count, setCount] = useState(1);

  const [selectedLanguage, setSelectedLanguage] = useState(1);

  const [expirationDate, setExpirationDate] = useState();

  const [price, setPrice] = useState();

  const [unlimited, setUnlimited] = useState(false);

  const [certainDays, setCertainDays] = useState(false);

  const [discountType, setDiscountType] = useState('percent');

  const [code, setCode] = useState('');

  const [countOfGeneratedCoupons, setCountOfGeneratedCoupons] = useState(0);

  const [discount, setDiscount] = useState('');

  const [couponData, setCouponData] = useState([
    { languageId: 1, title: '' },
    { languageId: 2, title: '' },
    { languageId: 3, title: '' },
  ]);

  const [defaultState, setDefaultState] = useState(null);

  const [type, setType] = useState('common');

  const [file, setFile] = useState(null);

  const [selectedColor, setSelectedColor] = useState(1);

  const [couponProducts, setCouponProducts] = useState([]);

  const [couponCombos, setCouponCombos] = useState([]);

  const [couponModificators, setCouponModificators] = useState([]);

  const [products, setProducts] = useState();

  const [combos, setCombos] = useState();

  const [modificators, setModificators] = useState();

  const [productsQuery, setProductsQuery] = useState('');

  const [combosQuery, setCombosQuery] = useState('');

  const [modificatorsQuery, setModificatorsQuery] = useState('');

  const [codeError, setCodeError] = useState(false);

  const [validation, setValidation] = useState();

  const workingHours = (arrayOfDays) => {
    const findResultItems = arrayOfDays.filter((item) => {
      if (item.checked === true && item.openTime !== '' && item.closeTime !== '') {
        return item;
      }
    });
    const resultArrayOfDays = findResultItems.map((item) => {
      return { day: item.id, openTime: item.openTime, closeTime: item.closeTime };
    });
    return resultArrayOfDays;
  };

  const SCHEME = {
    expirationDate: (value) => !!value,
    title0: (value) => value?.length > 0,
    title1: (value) => value?.length > 0,
    title2: (value) => value?.length > 0,
    file: (value) => !!value,
    countOfGeneratedCoupons: (value) => {
      if (type !== 'generateBase') {
        return true;
      }
      return +value > 0;
    },
    price: (value) => {
      return +value >= 0 && Number.isNaN(Number.parseFloat(value)) === false;
    },
    discount: (value) => {
      if (!value) {
        return false;
      } else if (discountType === 'percent' && !!value && +value <= 100) {
        return true;
      } else if (discountType === 'value' && !!value && +value <= +price) {
        return true;
      } else {
        return false;
      }
    },
    code: (value) => {
      if (type === 'common' || type === 'personal') {
        return !!value && codeError === false;
      } else {
        return true;
      }
    },
    workingTimes: (value) => (certainDays ? !!value : true),
  };

  const [isFocus, setIsFocus] = useState(FOCUS);

  const titles = couponData.reduce((acc, el, key) => ({ ...acc, [`title${key}`]: el.title }), []);

  const workingTimesValidation = daysOfWeek.reduce(
    (res, { id, openTime, closeTime, checked }) => ({
      ...res,
      [id]: (() => {
        if (checked) {
          if (openTime.length > 0 && closeTime.length > 0) {
            return Date.parse(openTime) < Date.parse(closeTime);
          } else {
            return false;
          }
        } else {
          return true;
        }
      })(),
    }),
    {},
  );

  const initialize = () => {
    setDaysOfWeek(INITIAL_WORKING_TIME);
    setUnlimited(false);
    setCount(1);
    setId();
    setStep(1);
    setExpirationDate();
    setPrice();
    setCode();
    setFile(null);
    setDiscount();
    setType('common');
    setDefaultState(null);
    setCountOfGeneratedCoupons(0);
    setIsFocus(FOCUS);
    setCouponData([
      { languageId: 1, title: '' },
      { languageId: 2, title: '' },
      { languageId: 3, title: '' },
    ]);
    setCodeError(false);
    setProducts();
    setSelectedColor(1);
    setCouponProducts([]);
    setCouponCombos([]);
    setCouponModificators([]);
    setSelectedLanguage(1);
    setProductsQuery('');
  };

  const getDefaultCouponProducts = async (productIds) => {
    try {
      const selectedOptions = await Promise.all(
        productIds.map(async (product) => {
          const resp = await GoodsService.getProductById(product?.posId || product?.productId);
          return {
            posId: resp?.posId,
            title: resp?.posTitle || resp?.title || product?.title,
            number: product?.number,
          };
        }),
      );
      return selectedOptions;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getDefaultCouponModificators = async (modificatorIds) => {
    try {
      const selectedOptions = await Promise.all(
        modificatorIds.map(async (modificator) => {
          const resp = await ModificatorsService.getModificatorById(
            modificator?.posId || modificator?.modificatorId,
          );
          return {
            posId: resp?.posId,
            title: resp?.posTitle || resp?.title || modificator?.title,
            number: modificator?.number,
          };
        }),
      );
      return selectedOptions;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getDefaultCouponCombos = async (comboIds) => {
    try {
      const selectedOptions = await Promise.all(
        comboIds.map(async (combo) => {
          const resp = await ComboService.getComboById(combo?.posId || combo?.comboId);
          return {
            posId: resp?.posId,
            title: resp?.posTitle || resp?.title || combo?.title,
            number: combo?.number,
          };
        }),
      );
      return selectedOptions;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const prepareCouponData = (couponDataPatched) => {
    return couponData.map((el, key) => ({
      languageId: el.languageId,
      title: couponDataPatched[key]?.title || '',
    }));
  };

  const getFileById = async (fileId) => {
    try {
      const resp = await MainService.getFileById(fileId);
      URL.createObjectURL(resp);
      setFile(Object.assign(resp, { preview: URL.createObjectURL(resp), id: fileId }));
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  useEffect(() => {
    const getProducts = async (params) => {
      try {
        const resp = await GoodsService.getGoods(params);
        setProducts(resp.data);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    };

    if (productsQuery?.length > 0) {
      getProducts({
        langId: props.langId,
        page: 1,
        size: 5,
        status: 'active',
        search: productsQuery,
      });
    }
  }, [productsQuery]);

  useEffect(() => {
    const getCombos = async (params) => {
      try {
        const resp = await ComboService.getCombo(params);
        setCombos(resp.data);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    };
    if (combosQuery?.length > 0) {
      getCombos({
        langId: props.langId,
        page: 1,
        size: 5,
        categoryId: 0,
        search: combosQuery,
      });
    }
  }, [combosQuery]);

  useEffect(() => {
    const getModificators = async (params) => {
      try {
        const resp = await ModificatorsService.getModificators(params);
        setModificators(resp.data);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    };

    if (modificatorsQuery?.length > 0) {
      getModificators({
        langId: props.langId,
        page: 1,
        size: 5,
        categoryId: 0,
        search: modificatorsQuery,
      });
    }
  }, [modificatorsQuery]);

  useEffect(() => {
    if (props.isOpen === true) {
      setValidation(
        validateObject(
          Object.assign(titles, {
            expirationDate,
            code,
            price,
            countOfGeneratedCoupons,
            discount,
            file,
            workingTimes: !Object.values(workingTimesValidation).some((el) => el === false),
          }),
          SCHEME,
        ),
      );
    }
  }, [
    countOfGeneratedCoupons,
    type,
    couponData,
    expirationDate,
    code,
    price,
    discount,
    file,
    codeError,
    props.isOpen,
    daysOfWeek,
  ]);

  const changeFocus = (field, focus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: focus }));
  };

  const mapData = async (coupon) => {
    setDefaultState(coupon);
    setId(coupon?.id);
    setExpirationDate(new Date(coupon?.expirationDate));
    setCode(coupon?.code);
    setPrice(coupon?.price);
    setCount(coupon?.count || 1);
    setUnlimited(coupon?.unlimited);
    setCountOfGeneratedCoupons(coupon.countOfGeneratedCoupons || 0);
    setDiscount(coupon?.discount);
    setDiscountType(coupon.discountType);
    setType(props.TYPES.find((el) => el.id === coupon?.type)?.key);
    setSelectedColor(coupon?.backgroundColorId);
    setCouponData(prepareCouponData(coupon?.couponData));
    setCouponProducts(await getDefaultCouponProducts(coupon?.products));
    setCouponCombos(await getDefaultCouponCombos(coupon?.combo));
    setCouponModificators(await getDefaultCouponModificators(coupon?.modificators));
    await getFileById(coupon?.fileId);
    if (coupon?.couponWorkingTimes.length) {
      const mappedData = coupon.couponWorkingTimes.reduce(
        (acc, el) => ({ ...acc, [el.day]: el }),
        {},
      );
      setCertainDays(true);
      setDaysOfWeek((old) => {
        return old.map((el) => ({
          ...el,
          openTime: mappedData[el.id] ? mappedData[el.id]?.openTime : '',
          closeTime: mappedData[el.id] ? mappedData[el.id].closeTime : '',
          checked: mappedData[el.id] ? true : false,
        }));
      });
    }
  };

  useEffect(() => {
    const loadSelectedCoupon = async (params) => {
      if (props.selectedCoupon && props.isOpen === true) {
        props.setLoading(true);
        await mapData(await props.getCouponById(params));
        props.setLoading(false);
      }
    };
    loadSelectedCoupon(props.selectedCoupon?.id);
  }, [props.selectedCoupon, props.isOpen]);

  useEffect(() => {
    const renderLoadedCoupon = async (params) => {
      if (props?.loadedCoupon && props.isOpen) {
        props.setLoading(true);
        await mapData(params);
        props.setLoading(false);
      }
    };
    renderLoadedCoupon(props?.loadedCoupon);
  }, [props?.loadedCoupon, props.isOpen]);

  useEffect(() => {
    if (code?.length > 0 && defaultState?.code !== code && props.isOpen === true) {
      const check = async (codeToCheck) => {
        try {
          const res = await CouponService.checkExisting(codeToCheck);
          setCodeError(res?.exist);
        } catch {}
      };
      check(code);
    }
  }, [code, props.isOpen]);

  useEffect(() => {
    if (props.isOpen === false) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => props.onClose()}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges({
              defaultState,
              count,
              id,
              unlimited,
              expirationDate: expirationDate.toISOString(),
              price,
              discount,
              countOfGeneratedCoupons,
              discountType,
              backgroundColorId: selectedColor,
              workingHours: workingHours(daysOfWeek),
              couponData,
              couponProducts: couponProducts.reduce(
                (acc, el) => [
                  ...acc,
                  {
                    number: el.number,
                    productId: el.posId,
                  },
                ],
                [],
              ),
              couponCombos: couponCombos.reduce(
                (acc, el) => [
                  ...acc,
                  {
                    number: el.number,
                    comboId: el.posId,
                  },
                ],
                [],
              ),
              couponModificators: couponModificators.reduce(
                (acc, el) => [
                  ...acc,
                  {
                    number: el.number,
                    modificatorId: el.posId,
                  },
                ],
                [],
              ),
              code,
              type,
              file,
            });
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.coupons.typeEXT}</span>
                    </div>
                    <Select
                      className="container_fullwidth_one_first"
                      options={props.TYPES}
                      value={type}
                      size="large"
                      onChange={setType}
                      label={`${STRINGS.screens.coupons.typeEXT} *`}
                      name="type"
                    />
                  </div>
                  {type === 'generateBase' && (
                    <div className="container_fullwidth">
                      <Input
                        label={STRINGS.screens.coupons.qntyGenerateBase}
                        value={countOfGeneratedCoupons}
                        onChange={setCountOfGeneratedCoupons}
                        variant="outlined"
                        className="container_fullwidth_one"
                        type="number"
                        name="countOfGeneratedCoupons"
                        onFocus={() => changeFocus('countOfGeneratedCoupons', true)}
                        onBlur={() => changeFocus('countOfGeneratedCoupons', false)}
                        inputType={
                          !isFocus?.countOfGeneratedCoupons &&
                          !validation?.data?.countOfGeneratedCoupons?.isValid
                            ? 'danger'
                            : 'default'
                        }
                      />
                    </div>
                  )}
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.general}</span>
                    </div>
                    <InputDate
                      size="large"
                      className="container_fullwidth_one_first"
                      disablePast
                      label={`${STRINGS.screens.coupons.expirationDateETX} *`}
                      onChange={setExpirationDate}
                      inputValue={expirationDate && getBeautyDateString(expirationDate)}
                      value={expirationDate}
                      inputType={
                        !isFocus?.expirationDate && !validation?.data?.expirationDate?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('expirationDate', true)}
                      onBlur={() => changeFocus('expirationDate', false)}
                      name="expirationDate"
                    />
                    <Input
                      className="container_fullwidth_one"
                      fullWidth
                      variant="outlined"
                      value={code}
                      onChange={setCode}
                      type="number"
                      label={`${STRINGS.screens.coupons.codeEXT} *`}
                      inputType={
                        !isFocus?.code && !validation?.data?.code?.isValid ? 'danger' : 'default'
                      }
                      onFocus={() => changeFocus('code', true)}
                      onBlur={() => changeFocus('code', false)}
                      name="code"
                    />
                    {codeError && (
                      <div className="error">
                        <span>{STRINGS.screens.coupons.qw}</span>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '15px',
                        gap: '20px',
                      }}
                    >
                      <div className="checkbox-certain-day">
                        <Radio
                          checked={discountType === 'percent'}
                          onChange={(e) => {
                            e.preventDefault();
                            setDiscountType('percent');
                          }}
                          label={STRINGS.screens.coupons.percent}
                        />
                      </div>
                      <div className="checkbox-certain-day">
                        <Radio
                          checked={discountType === 'value'}
                          onChange={(e) => {
                            e.preventDefault();
                            setDiscountType('value');
                          }}
                          label={STRINGS.screens.coupons.value}
                        />
                      </div>
                    </div>
                    <Input
                      className="container_fullwidth_one"
                      fullWidth
                      variant="outlined"
                      value={price}
                      type="number"
                      onChange={setPrice}
                      label={`${STRINGS.screens.coupons.qs} *`}
                      inputType={
                        !isFocus?.price && !validation?.data?.price?.isValid ? 'danger' : 'default'
                      }
                      name="price"
                      onFocus={() => changeFocus('price', true)}
                      onBlur={() => changeFocus('price', false)}
                    />
                    <Input
                      className="container_fullwidth_one"
                      fullWidth
                      type="number"
                      inputType={
                        !isFocus?.discount && !validation?.data?.discount?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      variant="outlined"
                      value={discount}
                      onChange={setDiscount}
                      label={
                        discountType === 'value'
                          ? STRINGS.screens.coupons.qd
                          : STRINGS.screens.coupons.qf
                      }
                      onFocus={() => changeFocus('discount', true)}
                      onBlur={() => changeFocus('discount', false)}
                      name="discount"
                    />
                  </div>
                  <div className="container_fullwidth">
                    <Counter
                      max={100}
                      disabled={type === 'common' && unlimited}
                      label={STRINGS.screens.coupons.qp}
                      className="counter"
                      number={count}
                      setNumber={setCount}
                      name="count"
                    />
                    {type === 'common' && (
                      <div className="checkbox-unlimited-container">
                        <Checkbox
                          checked={unlimited}
                          onChange={() => {
                            setUnlimited(!unlimited);
                          }}
                          label={STRINGS.screens.coupons.qa}
                        />
                      </div>
                    )}
                  </div>
                  <WorkingTime
                    certainDays={certainDays}
                    setCertainDays={setCertainDays}
                    daysOfWeek={daysOfWeek}
                    setDaysOfWeek={setDaysOfWeek}
                    workingTimesValidation={workingTimesValidation}
                  />
                </>
              )}
              {step === 2 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.backgroundColor} *</span>
                    </div>
                    <ColorSelector
                      colors={props.backgroundColors}
                      value={selectedColor}
                      setSelectedColor={setSelectedColor}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.img} *</span>
                    </div>
                    <CustomDropzone
                      maxFiles={1}
                      files={file}
                      onChange={setFile}
                      dropzoneType={'default'}
                      name="image"
                    />
                  </div>
                </>
              )}
              {step === 3 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.coupons.qg}</span>
                  </div>
                  <CustomSelect
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.coupons.qh}`}
                    data={products}
                    value={productsQuery}
                    onChange={setProductsQuery}
                    inputType="default"
                    selectedOptions={couponProducts}
                    setSelectedOptions={setCouponProducts}
                    name="products"
                  />
                </div>
              )}
              {step === 4 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.coupons.qj}</span>
                  </div>
                  <CustomSelect
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.coupons.qk}`}
                    data={combos}
                    value={combosQuery}
                    onChange={setCombosQuery}
                    selectedOptions={couponCombos}
                    inputType="default"
                    setSelectedOptions={setCouponCombos}
                    name="combos"
                  />
                </div>
              )}
              {step === 5 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.coupons.ql}</span>
                  </div>
                  <CustomSelect
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.coupons.qz}`}
                    data={modificators}
                    value={modificatorsQuery}
                    onChange={setModificatorsQuery}
                    selectedOptions={couponModificators}
                    inputType="default"
                    setSelectedOptions={setCouponModificators}
                    name="modificators"
                  />
                </div>
              )}
              {step === 6 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.langs}</span>
                  </div>
                  <div className="langs">
                    {props.languages?.map((language) => (
                      <Button
                        label={language.name}
                        value={language.id}
                        size="medium"
                        className="lang"
                        type={selectedLanguage === language.id ? 'primary' : 'default'}
                        data-active={selectedLanguage === language.id}
                        onClick={(e) => {
                          setSelectedLanguage(language.id);
                          setIsFocus({
                            [`title${language.id - 1}`]: true,
                          });
                          e.preventDefault();
                        }}
                        id={`btn${language.id}`}
                      >
                        <span className="day-label">{language.name}</span>
                      </Button>
                    ))}
                  </div>
                  <Input
                    className="container_fullwidth_one_first"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...couponData];
                      temp[selectedLanguage - 1].title = val;
                      setCouponData(temp);
                    }}
                    inputType={
                      !isFocus?.[`title${selectedLanguage - 1}`] &&
                      !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    value={couponData[selectedLanguage - 1].title}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
                    onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                    name={`title${selectedLanguage - 1}`}
                  />
                </div>
              )}
            </div>
          )}
          <Separator indent={80} />
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                disabled={!validation.isValid}
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                type={!validation.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default CouponFields;

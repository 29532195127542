/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Confirm, Table, Tooltip } from '../../ui-kit';
import StoryTypeFields from '../../components/modals/story-type-fields';
import Skelet from './skelet';
import { Head } from '../../components';
import { URL } from '../../../services/config';
import STRINGS from '../../../localization';
import './styles.scss';

const StoryTypesView = (props) => {
  return (
    <>
      <StoryTypeFields
        serialNumbers={props.serialNumbers}
        getFileById={props.getFileById}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.stories.editStoryType}
        selectedStoryType={props.selectedStoryType}
        saveChanges={props.saveChanges}
        languages={props.languages}
      />
      <StoryTypeFields
        serialNumbers={props.serialNumbers}
        getFileById={props.getFileById}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'add'}
        title={STRINGS.screens.stories.addStoryType}
        saveChanges={props.addStoryType}
        languages={props.languages}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.stories.deleteType}
        subtitleBold={props.selectedStoryType?.title}
        buttons={[
          {
            type: 'danger',
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteStoryType(),
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
            },
          },
        ]}
      />

      <Head
        label={STRINGS.screens.stories.storyTypes}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.stories.create,
          onClick: () => props.setShowModal('add'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.stories.storyTypes,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              head={() => (
                <tr className="summary_table_heading">
                  <th></th>
                  <th>{STRINGS.screens.stories.id}</th>
                  <th>{STRINGS.screens.stories.type}</th>
                  <th>{STRINGS.screens.stories.serial}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.storyTypes?.map((item) => (
                  <tr key={item?.id} className="summary_table_body unselectable">
                    <td>
                      <img
                        className="image"
                        style={{ width: '100px' }}
                        src={item?.file && `${URL}files/${item?.file?.id}`}
                      />
                    </td>
                    <td>{item?.id}</td>
                    <td>{item?.storyTypeData[0].title}</td>
                    <td>{item?.serialNumber}</td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setSelectedStoryType({
                                id: item.id,
                                title: item?.storyTypeData[0].title,
                                serialNumber: item?.serialNumber,
                              });
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.edit}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={() => {
                              props.setSelectedStoryType(item);
                              props.setShowModal('edit');
                            }}
                          >
                            <Icon name="edit" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

StoryTypesView.propTypes = {
  languages: PropTypes.array,
  cities: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableCity: PropTypes.object,
  setEditableCity: PropTypes.func,
  saveChanges: PropTypes.func,
};

StoryTypesView.defaultProps = {
  languages: [],
  cities: null,
  showModal: '',
  setShowModal: () => {},
  editableCity: {},
  setEditableCity: () => {},
  saveChanges: () => {},
};

export default StoryTypesView;

/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import ProductWishesReceipt from '../product-wishes-receipt';

import './styles.scss';

const ProductReceipt = ({ product = {} }) => {
  return (
    <div>
      <div className="receipt-ingredients-wrapper">
        <p>{product.name}</p>
        <div className="receipt-ingredients">
          <p className="receipt-count-pr">{product.quantity}</p>
          <p>
            {product.price} {STRINGS.components.receipt.tg}
          </p>
        </div>
      </div>
      {product.modificatorsToAdd?.length > 0 &&
        product.modificatorsToAdd?.map((mod) => (
          <ProductWishesReceipt key={mod.name} mod={mod} isAdd={true} />
        ))}
      {product.modificatorsToPutAway?.length > 0 &&
        product.modificatorsToPutAway?.map((mod) => (
          <ProductWishesReceipt key={mod.name} mod={mod} isAdd={false} />
        ))}
    </div>
  );
};

export default ProductReceipt;

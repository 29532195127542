/**
 * @prettier
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TimePicker as MuiTimePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createTheme } from '@mui/material/styles';

import colors from '../../styles/colors';
import './styles.scss';

import { Input } from '../';

const defaultMaterialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.color_black._1,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: colors.color_black._1,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: colors.color_black._1,
      },
      thumb: {
        backgroundColor: colors.color_black._0,
        borderColor: colors.color_black._0,
      },
      noPoint: {
        backgroundColor: colors.color_black._0,
      },
    },
  },
});

/**
 * @component TimePicker
 * @example
 * <TimePicker />
 */
const TimePicker = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    props.onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiTimePicker
          ampm={false}
          value={props.value}
          onChange={handleDateChange}
          open={isOpen}
          autoOk
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          okLabel={null}
          cancelLabel={null}
          orientation="portrait"
          TextFieldComponent={({ onClick }) => {
            return (
              <div className="time-picker" onClick={onClick}>
                <Input
                  defaultValue={props.inputValue}
                  size={props.size}
                  label={props.label}
                  inputType={props.inputType}
                  disabled={props.disabled}
                  leftIcon="schedule"
                  iconWidth={20}
                  iconHeight={20}
                />
              </div>
            );
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

/**
 * Properties
 */

TimePicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  inputType: PropTypes.oneOf(['default', 'primary', 'secondary', 'disabled']),
  inputValue: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
TimePicker.defaultProps = {
  inputValue: '',
  value: null,
  onChange: () => {},
  disabled: false,
  label: '',
  size: 'medium',
  inputType: 'default',
};

export default TimePicker;

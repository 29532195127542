/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../../ui-kit';
import { translateStatus } from '../../../main/utils/translate-status';
import { Confirm, Table, Tooltip, Button, MainTabs } from '../../ui-kit';
import ModificatorsFields from '../../components/modals/modificators-fields';
import Skelet from './skelet';
import colors from '../../styles/colors';
import NOPHOTO from '../../../assets/nophoto.png';
import { Head } from '../../components';
import { URL } from '../../../services/config';
import { generatePrice } from '../../utils/generate-price';

import STRINGS from '../../../localization';
import './styles.scss';

const ModificatorsView = (props) => {
  const renderClassNameSort = (type) => {
    let str = 'summary_table_heading_sort';
    if (type === props.sort.type) {
      str += ' summary_table_heading_sort_active';
    }
    return str;
  };
  return (
    <>
      <ModificatorsFields
        getFileById={props.getFileById}
        getDefinitions={props.getDefinitions}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.ingredients.editIngredient} //edit
        selectedModificator={props.selectedModificator}
        getModificatorById={props.getModificatorById}
        saveChanges={props.saveChanges}
        languages={props.languages}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.ingredients.deleteQuestion}
        subtitleBold={props.selectedModificator?.title}
        buttons={[
          {
            type: 'danger',
            label: STRINGS.screens.roles.modal.delete,
            onClick: props.handleDeleteModificator,
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              setTimeout(() => props.setSelectedModificator(), 100);
              props.setShowConfirm('');
            },
          },
        ]}
      />
      <Head
        label={STRINGS.screens.ingredients.ingredients}
        button={false}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.ingredients.ingredients,
          },
        ]}
      />
      <div>
        <div className="summary_table">
          <MainTabs
            active={props.selectedModificatorCategory}
            data={props.categories.map((el) => ({ label: el.title, key: el.id }))}
            onClick={props.setSelectedModificatorCategory}
          />
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              loading={props.bottomLoading}
              head={() => (
                <tr className="summary_table_heading">
                  <th />
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>Id</span>
                      <span
                        onClick={() => props.applySort('PosId')}
                        className={renderClassNameSort('PosId')}
                      >
                        <div
                          className={
                            props.sort.sort === 'desc' && props.sort.type === 'PosId'
                              ? 'summary_table_heading_arrow'
                              : 'summary_table_heading_arrow_active'
                          }
                        >
                          <Icon
                            width={16}
                            height={16}
                            color={colors.color_gray._3}
                            name="arrow-bottom"
                          />
                        </div>
                      </span>
                    </div>
                  </th>
                  <th className="summary_table_heading_cell">{STRINGS.screens.goods.qg}</th>
                  <th className="summary_table_heading_cell">
                    {STRINGS.screens.ingredients.title}
                  </th>
                  <th>
                    <span>{STRINGS.screens.goods.microsTitle}</span>
                  </th>
                  <th>{STRINGS.screens.goods.prices.default}</th>
                  <th>{STRINGS.screens.goods.prices.delivery}</th>
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>{STRINGS.screens.ingredients.status}</span>
                      <span
                        onClick={() => props.applySort('Status')}
                        className={renderClassNameSort('Status')}
                      >
                        {props.sort.sort === 'desc' && props.sort.type === 'Status'
                          ? 'Z - a'
                          : 'A - z'}
                      </span>
                    </div>
                  </th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.modificators?.data?.map((item) => (
                  <tr
                    key={item.id}
                    className="summary_table_body"
                    onClick={async (event) => {
                      props.history.push(`ingredients/${item.posId}/`, {
                        title: item?.title,
                        status: item?.status,
                      });
                      event.stopPropagation();
                    }}
                  >
                    <td>
                      {item?.fileId ? (
                        <img
                          className="image"
                          style={{ width: '100px' }}
                          src={`${URL}files/${item?.fileId}`}
                        />
                      ) : (
                        <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                      )}
                    </td>
                    <td>{item.posId}</td>
                    <td>{item.serialNumber || STRINGS.screens.modal.noData}</td>
                    <td>{item.title || STRINGS.screens.modal.noData}</td>
                    <td>{item.posTitle || STRINGS.screens.modal.noData}</td>
                    <td>{generatePrice(item.prices.default)}</td>
                    <td>{generatePrice(item.prices.delivery)}</td>
                    <td>
                      <Button
                        className="outline"
                        size="large"
                        label={translateStatus(item?.status).status}
                        type={translateStatus(item?.status).button}
                        typeButton="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.changeStatus({ id: item?.posId, status: item?.status });
                        }}
                      />
                    </td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setSelectedModificator({ id: item?.posId, title: item?.title });
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.edit}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={async (event) => {
                              event.stopPropagation();
                              props.setSelectedModificator({ id: item?.posId });
                              props.setShowModal('edit');
                            }}
                          >
                            <Icon name="edit" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

ModificatorsView.propTypes = {
  languages: PropTypes.array,
  goods: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableModificator: PropTypes.object,
  setEditableModificator: PropTypes.func,
  saveChanges: PropTypes.func,
};

ModificatorsView.defaultProps = {
  languages: [],
  goods: null,
  showModal: '',
  setShowModal: () => {},
  editableModificator: {},
  setEditableModificator: () => {},
  saveChanges: () => {},
};

export default ModificatorsView;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';

import PointsView from './points-view';
import PointsStoreService from '../../../services/points-store';
import GoodsService from '../../../services/goods';
import { useSelector } from 'react-redux';
import { Toast } from '../../ui-kit';
import MainService from '../../../services/main';
import STRINGS from '../../../localization';

const PointsContainer = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [showModal, setShowModal] = useState('');
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const mapFileId = async (file) => {
    if (file?.id) {
      return file?.id;
    } else {
      const id = await getFileId(file);
      return id?.id;
    }
  };
  const getFileId = async (file) => {
    try {
      const resp = await MainService.postFile(file);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const addLevel = async (f) => {
    setLoading(true);
    try {
      const resp = await PointsStoreService.addLevel({
        data: {
          amount: Number.parseInt(f.amount),
          fileId: await mapFileId(f.file),
          priceLevelData: f.priceLevelData,
        },
      });
      if (resp) {
        await Promise.all(
          f.productsToAdd.map(async (el) => {
            await PointsStoreService.addProduct({
              data: {
                productId: el.posId,
                priceLevelId: resp?.id,
                itemData: el?.itemData,
                backgroundColorId: el?.backgroundColorId,
              },
            });
          }),
          f.comboToAdd.map(async (el) => {
            await PointsStoreService.addProduct({
              data: {
                comboId: el.posId,
                priceLevelId: resp?.id,
                itemData: el?.itemData,
                backgroundColorId: el?.backgroundColorId,
              },
            });
          }),
        );
      }
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.success);
      }
    }
    const resp = await getLevels();
    setLevels(resp);
    setShowModal('');
    setSelectedLevel({});
    setLoading(false);
  };

  const saveChanges = async (f) => {
    setLoading(true);
    let priceLevel = { priceLevelData: f.priceLevelData };
    if (f.defaultState.amount !== f.amount) {
      priceLevel = Object.assign(priceLevel, { amount: Number.parseInt(f.amount) });
    }
    if (f.file) {
      priceLevel = Object.assign(priceLevel, {
        fileId: Number.parseInt(await mapFileId(f.file)),
      });
    }
    if (Object.keys(priceLevel).length > 0) {
      try {
        await PointsStoreService.editLevel({
          id: f?.id,
          data: priceLevel,
        });
      } catch (error) {}
    }
    try {
      await Promise.all(
        f.levelProducts.map(async (el) => {
          await PointsStoreService.patchProduct({
            data: {
              productId: el.posId,
              priceLevelId: f?.id,
              itemData: el?.itemData,
              backgroundColorId: el?.backgroundColorId,
            },
          });
        }),
        f.levelCombo.map(async (el) => {
          await PointsStoreService.patchProduct({
            data: {
              comboId: el.posId,
              priceLevelId: f?.id,
              itemData: el?.itemData,
              backgroundColorId: el?.backgroundColorId,
            },
          });
        }),
        f.productsToAdd.map(async (el) => {
          await PointsStoreService.addProduct({
            data: {
              productId: el.posId,
              priceLevelId: f?.id,
              itemData: el?.itemData,
              backgroundColorId: el?.backgroundColorId,
            },
          });
        }),
        f.productsToDelete.map(async (el) => {
          await PointsStoreService.deleteProduct({
            productId: el.posId,
            priceLevelId: f?.id,
            itemData: el?.itemData,
            backgroundColorId: el?.backgroundColorId,
          });
        }),
        f.comboToAdd.map(async (el) => {
          await PointsStoreService.addProduct({
            data: {
              comboId: el.posId,
              priceLevelId: f?.id,
              itemData: el?.itemData,
              backgroundColorId: el?.backgroundColorId,
            },
          });
        }),
        f.comboToDelete.map(async (el) => {
          await PointsStoreService.deleteProduct({
            comboId: el.posId,
            priceLevelId: f?.id,
            itemData: el?.itemData,
            backgroundColorId: el?.backgroundColorId,
          });
        }),
      );
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.success);
      }
    }
    const resp = await getLevels();
    setLevels(resp);
    setShowModal('');
    setSelectedLevel({});
    setTimeout(() => setLoading(false), 100);
  };

  const handleDeleteLevel = async () => {
    setShowConfirm('');
    setLevels((prev) => prev.filter((el) => el.id !== selectedLevel.id));
    try {
      await PointsStoreService.deleteLevel(selectedLevel?.id);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getLevels = async () => {
    try {
      const resp = await PointsStoreService.getPriceLevels();
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getProducts = async (props) => {
    let data = [];
    try {
      const resp = await GoodsService.getProductsPointsStore(props);
      data = [...data, ...resp.data];
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    return data;
  };
  const getCombo = async (props) => {
    let data = [];
    try {
      const resp = await GoodsService.getComboPointsStore(props);
      data = [...data, ...resp.data];
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    return data;
  };
  const getLevelProducts = async (props) => {
    try {
      const resp = await PointsStoreService.getProducts(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  useEffect(() => {
    const getList = async () => {
      const resp = await getLevels();
      setLevels(resp);
      setSkeletVisible(false);
    };
    getList();
  }, []);

  return (
    <PointsView
      langId={langId}
      setSelectedCategory={setSelectedCategory}
      selectedCategory={selectedCategory}
      handleDeleteLevel={handleDeleteLevel}
      loading={loading}
      setLoading={setLoading}
      saveChanges={saveChanges}
      selectedLevel={selectedLevel}
      setSelectedLevel={setSelectedLevel}
      getLevelProducts={getLevelProducts}
      levels={levels}
      showModal={showModal}
      getProducts={getProducts}
      getCombo={getCombo}
      setShowModal={setShowModal}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      addLevel={addLevel}
      skeletVisible={skeletVisible}
    />
  );
};

export default PointsContainer;

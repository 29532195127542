/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from '../loader';
import { Button, Form, Radio, Drawer, SingleSelect, Toast } from '../../../ui-kit';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import STRINGS from '../../../../localization';
import { groupByEnum } from '../../../../constants/aggregators';

import CitiesService from '../../../../services/cities';
import RestauransService from '../../../../services/restaurants';
import { DAY } from '../../../../constants/aggregators';
import { formatISOdate } from '../../../utils/datetime';

const ReportFields = (props) => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const [cities, setCities] = useState([]);
  const [cityQuery, setCityQuery] = useState('');
  const [cityId, setCityId] = useState(null);

  const [restaurants, setRestaurants] = useState([]);
  const [restaurantsQuery, setRestaurantsQuery] = useState('');
  const [restaurantId, setRestaurantId] = useState(null);

  const [reportIntervals, setReportIntervals] = useState([]);

  const [intervalId, setIntervalId] = useState('');
  const [groupBy, setGroupBy] = useState(groupByEnum.restaurant);

  const clearLocalData = () => {
    setCities([]);
    setCityQuery('');
    setCityId(null);
    setRestaurantId([]);
    setRestaurantsQuery('');
    setRestaurantId(null);
    setIntervalId('');
    setGroupBy(groupByEnum.restaurant);
  };

  const handleInterval = (event) => {
    setIntervalId(event.target.value);
  };

  useEffect(async () => {
    if (props.isOpen === true) {
      props.setLoading(true);
      props.setLoading(false);
    }
  }, [props.selectedCity, props.isOpen]);

  const initialize = () => {};

  useEffect(() => {
    if (props.isOpen === false) {
      setTimeout(() => initialize(), 100);
    }
    if (props.reportIntervals) {
      setReportIntervals(
        props?.reportIntervals.map((el) => ({
          intervalId: el.intervalId,
          date: el.dayDate,
        })),
      );
    }
  }, [props.isOpen]);

  useEffect(() => {
    const getCitiesByQuery = async () => {
      if (cityQuery?.length > 0) {
        try {
          const resp = await CitiesService.getAutocomplete({ search: cityQuery, langId: langId });
          setCities(resp);
        } catch (error) {
          setCities([]);
        }
      }
    };
    getCitiesByQuery();
  }, [cityQuery]);

  useEffect(() => {
    const getRestaurants = async () => {
      if (restaurantsQuery.length > 0) {
        try {
          const resp = await RestauransService.getRestaurants({
            page: 1,
            size: 11,
            langId: langId,
            search: restaurantsQuery,
          });
          setRestaurants(resp.data.map((el) => ({ id: el.id, value: el.address })));
        } catch (error) {
          if (error?.status) {
            Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
          }
        }
      } else {
        setRestaurants([]);
      }
    };
    getRestaurants();
  }, [restaurantsQuery]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
    >
      <div className="modal">
        <Form
          onSubmit={() =>
            props.saveChanges({
              restaurantId: restaurantId || '',
              cityId: cityId || '',
              groupBy: groupBy,
              intervalId: intervalId || '',
            })
          }
        >
          <div className="header-nosteps">
            <div className="title">
              <span>{props.title}</span>
            </div>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <div className="body">
              {props.type === DAY && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.aggregators.choosePeriod}</span>
                    </div>
                    <FormControl fullWidth>
                      <InputLabel
                        id="imple-select-label"
                        variant="outlined"
                        focused={false}
                        disabled
                      ></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={intervalId}
                        onChange={handleInterval}
                      >
                        {!!reportIntervals &&
                          reportIntervals.map((el) => (
                            <MenuItem value={el.intervalId}>{formatISOdate(el.date)}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="container_fullwidth" style={{ justifyContent: 'flex-start' }}>
                    <div className="subtitle">
                      <span>{STRINGS.screens.aggregators.groupBy}</span>
                    </div>
                    <div className="checkbox-certain-day" style={{ marginRight: 15 }}>
                      <Radio
                        checked={groupBy === groupByEnum.restaurant}
                        onChange={(e) => {
                          e.preventDefault();
                          setGroupBy(groupByEnum.restaurant);
                        }}
                        label={STRINGS.screens.aggregators.groupByRestaurant}
                      />
                    </div>
                    <div className="checkbox-certain-day">
                      <Radio
                        checked={groupBy === groupByEnum.region}
                        onChange={(e) => {
                          e.preventDefault();
                          setGroupBy(groupByEnum.region);
                        }}
                        label={STRINGS.screens.aggregators.groupByRegion}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="container_fullwidth">
                <div className="subtitle">
                  <span>{STRINGS.screens.aggregators.city}</span>
                </div>
                <SingleSelect
                  className="container_fullwidth_one"
                  label={`${STRINGS.screens.aggregators.chooseCity}`}
                  data={cities}
                  result={cityId}
                  setResult={setCityId}
                  dataQuery={cityQuery}
                  setDataQuery={setCityQuery}
                  rightIcon="search"
                />
              </div>
              {groupBy === groupByEnum.restaurant && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.aggregators.restaurant}</span>
                  </div>
                  <SingleSelect
                    className="container_fullwidth_one"
                    label={`${STRINGS.screens.aggregators.chooseRestaurant}`}
                    data={restaurants}
                    result={restaurantId}
                    setResult={setRestaurantId}
                    dataQuery={restaurantsQuery}
                    setDataQuery={setRestaurantsQuery}
                    rightIcon="search"
                  />
                </div>
              )}
            </div>
          )}
          <div className="footer">
            <Button
              className="button"
              label={STRINGS.screens.modal.clear}
              size="large"
              type="default"
              typeButton="submit"
              onClick={(e) => {
                e.preventDefault();
                clearLocalData();
                props.clearAll();
              }}
              loading={props.loading}
            />
            <Button
              disabled={
                !(cityId || restaurantId || intervalId || groupBy !== groupByEnum.restaurant)
              }
              className="button"
              label={STRINGS.screens.modal.save}
              size="large"
              type={
                cityId || restaurantId || intervalId || groupBy !== groupByEnum.restaurant
                  ? 'primary'
                  : 'disabled'
              }
              typeButton="submit"
              loading={props.loading}
            />
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

ReportFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
ReportFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
};

export default ReportFields;

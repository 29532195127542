/**
 * @prettier
 */

import React from 'react';

import { Loader, ProductWishes } from '../';
import SideItem from './side-item';
import STRINGS from '../../../localization';

import './styles.scss';

const Combo = ({ combo = {} }) => {
  return (
    <div className="item-container">
      <div className="wrapper">
        <div className="info-wrapper-element">
          <div className="info-container">
            <div className="title-wrapper">
              <span className="title">
                {combo.name} х {combo.quantity} [{combo.isBig ? 'L' : 'M'}]
              </span>
              <div>
                <span className="title">
                  {combo.price} {STRINGS.components.receipt.tg}
                </span>
              </div>
            </div>
            <ProductWishes modificators={combo.mainProductModificatorsToAdd} isAdd={true} />
            <ProductWishes modificators={combo.mainProductModificatorsToPutAway} isAdd={false} />
            {combo.sideItems.length > 0 ? (
              <div className="details-container top-12">
                {combo.sideItems.map((side) => (
                  <SideItem key={side} side={side} />
                ))}
              </div>
            ) : (
              <Loader color="dark" className="w-loader-center" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Combo;

/**
 * @prettier
 */
import React from 'react';
import './../styles.scss';

import { Icon } from '../../../ui-kit';
import { Draggable } from 'react-beautiful-dnd';

const SelectedItem = (props) => {
  return (
    <Draggable draggableId={props.el.posTitle} index={props.index}>
      {(provided) => (
        <div
          className="custom-select-container_search_selected_selected"
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div
            className="custom-select-container_search_selected_selected_delete"
            onClick={() => {
              let temp = [...props.area.list];
              temp[props.index].number = 0;
              props.setAreas((prev) => ({
                ...prev,
                [props.name]: {
                  ...prev[props.name],
                  [props.area.id]: {
                    ...prev[props.name][props.area.id],
                    list: temp.filter((el) => el.number > 0),
                  },
                },
              }));
            }}
          >
            <Icon name="cross" width={14} height={14} />
          </div>
          <span className="selected-label-id">{props.el.posId || props.el?.id}</span>
          <span className="selected-label">{props.el.posTitle || props.el?.title}</span>
          {props.multiple && (
            <div className="control_container_select">
              <div className="decrease_container">
                <button
                  className="decrease_button"
                  onClick={(e) => {
                    e.preventDefault();
                    let temp = [...props.area.list];
                    temp[props.index].number -= 1;
                    props.setAreas((prev) => ({
                      ...prev,
                      [props.name]: {
                        ...prev[props.name],
                        [props.area.id]: {
                          ...prev[props.name][props.area.id],
                          list: temp.filter((el) => el.number > 0),
                        },
                      },
                    }));
                  }}
                >
                  <span className="decrease_label">
                    <Icon width={12} height={12} name="minus" />
                  </span>
                </button>
              </div>
              <div className="number_container">
                <span className="decrease_label">{props.el.number}</span>
              </div>
              <div className="increase_container">
                <button
                  className="increase_button"
                  onClick={(e) => {
                    e.preventDefault();
                    let temp = [...props.area.list];
                    temp[props.index].number += 1;
                    props.setAreas((prev) => ({
                      ...prev,
                      [props.name]: {
                        ...prev[props.name],
                        [props.area.id]: {
                          ...prev[props.name][props.area.id],
                          list: temp.filter((el) => el.number > 0),
                        },
                      },
                    }));
                  }}
                >
                  <span className="increase_label">
                    <Icon width={12} height={12} name="plus" />
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default SelectedItem;

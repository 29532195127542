/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import ProductsView from './products-view';
import { Toast } from '../../ui-kit';
import GoodsService from '../../../services/goods';
import MainService from '../../../services/main';
import MenuService from '../../../services/menu';
import ModificatorsService from '../../../services/modificators';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import STRINGS from '../../../localization';
import { configureStatus } from '../../utils/configure-status';

const ProductsContainer = () => {
  const listSize = 8;

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { languages, categories } = useSelector((store) => store.stuff);

  const search = useSelector((store) => store.search.value);

  const [loading, setLoading] = useState(false);

  const [categoryId, setCategoryId] = useState(1);

  const [selectedProduct, setSelectedProduct] = useState();

  const [sort, setSort] = useState({ type: '', sort: '' });

  const [showModal, setShowModal] = useState('');

  const [showConfirm, setShowConfirm] = useState('');

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [products, setProducts] = useState();

  const history = useHistory();

  const [exportLoading, setExportLoading] = useState(false);

  const [page, setPage] = useState(1);

  const [bottomLoading, setBottomLoading] = useState(false);

  const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

  const [syncMenuLoading, setSyncMenuLoading] = useState(false);

  const getProducts = async (props) => {
    try {
      const resp = await GoodsService.getGoods(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  useEffect(() => {
    const getList = async (props) => {
      setPage(1);
      const resp = await getProducts(props);
      setProducts({
        isLast: resp?.isLast,
        data: [...resp?.data],
      });
      setSkeletVisible(false);
    };
    getList({
      langId: langId,
      page: 1,
      size: listSize,
      categoryId: categoryId,
      search: search,
      sort: sorts,
    });
  }, [sort, search, categoryId]);

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';
      const resp = await getProducts({
        langId: langId,
        page: page + 1,
        size: listSize,
        categoryId: categoryId,
        search: search,
        sort: sorts,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setProducts({
        isLast: resp.isLast,
        data: [...products?.data, ...resp?.data],
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };

  const applySort = (mode) => {
    setSort((prevState) => {
      return {
        sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
        type: mode,
      };
    });
  };
  useBottomScrollListener(() => {
    if (!bottomLoading && !products.isLast) {
      getNextPage();
    }
  });

  const saveChanges = async (id, f) => {
    setLoading(true);
    let data = {
      weight: f.weight ? parseInt(f.weight, 10) : 0,
      definitionId: f.definitionId,
      energyValueKcal: parseInt(f.energyValueKcal, 10),
      allergens: f.allergens,
      categoryId: f.categoryId,
      substituteProducts: f.substituteProducts,
      productModificators: f.productModificators,
      nutrients: {
        proteins: f.nutrients?.proteins,
        fats: f.nutrients?.fats,
        carbohydrates: f.nutrients?.carbohydrates,
        saturatedFat: f.nutrients?.saturatedFat,
        cellulose: f.nutrients?.cellulose,
        sugar: f.nutrients?.cellulose,
        salt: f.nutrients?.salt,
      },
    };
    if (f.file) {
      data = Object.assign(data, { fileId: await getFileId(f.file) });
    }
    if (f.glovoFile) {
      data = Object.assign(data, { glovoFileId: await getFileId(f.glovoFile) });
    }
    if (f.yandexFile) {
      data = Object.assign(data, { yandexFileId: await getFileId(f.yandexFile) });
    }
    if (f.chocofoodFile) {
      data = Object.assign(data, { chocofoodFileId: await getFileId(f.chocofoodFile) });
    }
    if (f.woltFile) {
      data = Object.assign(data, { woltFileId: await getFileId(f.woltFile) });
    }
    try {
      await GoodsService.updateGood(id, data);
      Toast.success(STRINGS.notify.success);
      await getProducts({
        langId: langId,
        page: 1,
        size: listSize,
        categoryId: categoryId,
        search: search,
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setTimeout(() => setLoading(false), 100);
  };

  const exportProducts = async () => {
    setExportLoading(true);
    try {
      await GoodsService.exportProducts();
      Toast.success(STRINGS.screens.goods.qp);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setExportLoading(false);
  };

  const getFileId = async (file) => {
    if (file?.id) {
      return file.id;
    } else {
      try {
        const id = await MainService.postFile(file);
        return id?.id;
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
  };
  const getModificatorsSuggestions = async (props) => {
    try {
      const resp = await MenuService.getModificatorsSuggestions(props);
      return resp.map((el) => ({ title: el.value, posId: el.id }));
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const syncMenu = async () => {
    try {
      setSyncMenuLoading(true);
      await MenuService.syncMenu();
      setSyncMenuLoading(false);
      Toast.success(STRINGS.notify.syncSuccess);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.syncSuccess);
      }
      setSyncMenuLoading(false);
    }
  };

  const getModificatorById = async (id) => {
    try {
      const resp = await ModificatorsService.getModificatorById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const changeStatus = async (elem) => {
    try {
      await GoodsService.updateStatus(elem.id, configureStatus(elem));
      let temp = [...products.data];
      temp.find((el) => el.posId === elem.id).status = configureStatus(elem).status;
      setProducts((old) => ({ isLast: old.isLast, data: temp }));
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getProductById = async (id) => {
    try {
      const resp = await GoodsService.getProductById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getSubstitude = async (props) => {
    try {
      const resp = await GoodsService.getSubstitude(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getModificators = async (props) => {
    try {
      const resp = await ModificatorsService.getModificators(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const handleDeleteProduct = async () => {
    await GoodsService.deleteProduct(selectedProduct?.id);
    setShowConfirm('');
    setProducts((prev) => ({
      isLast: prev.isLast,
      data: prev.data.filter((el) => el.id !== selectedProduct.id),
    }));
    Toast.success(STRINGS.notify.success);
  };

  return (
    <ProductsView
      syncMenuLoading={syncMenuLoading}
      syncMenu={syncMenu}
      exportLoading={exportLoading}
      exportProducts={exportProducts}
      getSubstitude={getSubstitude}
      getModificatorById={getModificatorById}
      getModificators={getModificators}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      getProductById={getProductById}
      langId={langId}
      getModificatorsSuggestions={getModificatorsSuggestions}
      CATEGORIES={categories}
      categoryId={categoryId}
      setCategoryId={setCategoryId}
      history={history}
      bottomLoading={bottomLoading}
      products={products}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      sort={sort}
      applySort={applySort}
      saveChanges={saveChanges}
      changeStatus={changeStatus}
      handleDeleteProduct={handleDeleteProduct}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      loading={loading}
      setLoading={setLoading}
      skeletVisible={skeletVisible}
    />
  );
};

export default ProductsContainer;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import STRINGS from '../../../../localization';

const DescriptionDefault = (props) => {
  return (
    <div className="drawer" open={props.isOpen} onClose={props.onClose} title={props.title}>
      <div className="description">
        <div className="description-image" />
        <p className="description-choose">
          {STRINGS.components.descriptionDefaultRestaurantModal.title}
        </p>
        <p className="description-info">
          {STRINGS.components.descriptionDefaultRestaurantModal.description}
        </p>
      </div>
    </div>
  );
};

/**
 * Properties
 */

DescriptionDefault.propTypes = {
  languages: PropTypes.array,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};

DescriptionDefault.defaultProps = {
  languages: [],
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default DescriptionDefault;

/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';
import MaskInput from 'react-maskinput';

import './styles.scss';
import { Icon } from '../';
import colors from '../../styles/colors';

/**
 * @component Input
 * @example
 * <Input
 *  label="Phone *"
 *  size="large"
 *  value=""
 *  onChange={() => {}}
 *  inputType="primary"
 * />
 */
const Input = (props) => {
  const renderClassName = () => {
    let str = 'input-container';
    if (props.inputType) {
      str += ` ${inputConfigList[props.inputType].basicClass}`;
    }
    if (props.size) {
      str += ` ${sizes[props.size]}`;
    }
    if (props.className) {
      str += ` ${props.className}`;
    }
    return str;
  };
  return (
    <div
      className={renderClassName()}
      title={props.title}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.type === 'phone' ? (
        <MaskInput
          mask={'+0 (000) 000-00-00'}
          size={20}
          maskChar="_"
          alwaysShowMask={false}
          className={props.type === 'password' ? 'password' : ''}
          onChange={(e) => props.onChange(e.target.value)}
          defaultValue={props.defaultValue}
          value={props.value}
          maxLength={props.maxLength}
          placeholder={props.placeholder}
          max={props.max}
          min={props.min}
          name={props.name}
          onKeyPress={props.onKeyPress}
          pattern={props.pattern}
          required={props.required}
          disabled={props.disabled}
          readOnly={props.readOnly}
          onClick={props.onClick}
          onBlur={(e) => props.onBlur(e.target.value)}
          autoComplete={props.autocomplete}
          onFocus={props.onFocus}
        />
      ) : (
        <input
          className={props.type === 'password' ? 'password' : ''}
          onChange={(e) => props.onChange(e.target.value)}
          defaultValue={props.defaultValue}
          value={props.value}
          type={props.type}
          maxLength={props.maxLength}
          placeholder={props.placeholder}
          max={props.max}
          min={props.min}
          name={props.name}
          onKeyPress={props.onKeyPress}
          pattern={props.pattern}
          required={props.required}
          disabled={props.disabled}
          readOnly={props.readOnly}
          onClick={props.onClick}
          onBlur={(e) => props.onBlur(e.target.value)}
          autoComplete={props.autocomplete}
          onFocus={props.onFocus}
        />
      )}

      <label>{props.label}</label>
      {props.isValid && (
        <div className="is-valid">
          <Icon name="check" width={14} height={14} color={colors.color_green._0} />
        </div>
      )}
      {props.inputType === 'danger' && (
        <div className="is-danger">
          <Icon name="danger" width={20} height={20} color={colors.color_red._0} />
        </div>
      )}
      {props.rightIcon && (
        <div
          className="right-icon"
          onClick={props.iconClick}
          data-icon-pointer={props.dataIconPointer}
        >
          <Icon
            name={props.rightIcon}
            width={props.iconWidth}
            height={props.iconHeight}
            color={inputConfigList[props.inputType].iconColor}
          />
        </div>
      )}
    </div>
  );
};

/**
 * Styles
 */

const inputConfigList = {
  default: {
    basicClass: 'default',
    iconColor: colors.color_gray._4,
  },
  primary: {
    basicClass: 'primary',
    iconColor: colors.color_black._1,
  },
  secondary: {
    basicClass: 'secondary',
    iconColor: colors.color_green._0,
  },
  danger: {
    basicClass: 'danger',
    iconColor: colors.color_gray._4,
  },
};

/**
 * SIZES
 */

const sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

/**
 * Properties
 */

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  defaultValue: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  inputType: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger']),
  isValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  rightIcon: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconColor: PropTypes.string,
  onBlur: PropTypes.func,
  title: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  suggestion: PropTypes.string,
};
Input.defaultProps = {
  rightIcon: null,
  iconColor: '#3F8CFE',
  iconHeight: 20,
  iconWidth: 20,
  label: '',
  type: 'text',
  maxLength: 64,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  placeholder: ' ',
  max: null,
  min: null,
  name: null,
  pattern: null,
  required: false,
  disabled: false,
  autocomplete: 'off',
  defaultValue: '',
  size: 'large',
  inputType: 'default',
  isValid: false,
  readOnly: false,
  title: null,
  className: '',
};

export default Input;

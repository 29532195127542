/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

export default class ReviewsService {
  static #API_ENDPOINTS = {
    reviews: 'reviews',
  };

  static API_SETTINGS = {
    size: 30,
    langId: 1,
  };
  static async getReviews(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.reviews}?langId=${props.langId}&page=${props.page}&size=${
          this.API_SETTINGS.size
        }${props.sort}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Reviews data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.cities}`, error);
      throw await error.response?.json();
    }
  }

  static async getReviewById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.reviews}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Review data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.stories}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async processed(id) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.reviews}/${id}/processed`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Review data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }
      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PUT request by path: ${URL}${this.#API_ENDPOINTS.reviews}/${id}/processed`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

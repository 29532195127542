/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';

import StoriesView from './story-types-view';
import { Toast } from '../../ui-kit';
import STRINGS from '../../../localization';
import StoriesService from '../../../services/story';
import { useSelector } from 'react-redux';
import MainService from '../../../services/main';

const StoryTypesContainer = () => {
  const { languages } = useSelector((store) => store.stuff);
  const [showModal, setShowModal] = useState('');
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [selectedStoryType, setSelectedStoryType] = useState({});
  const [storyTypes, setStoryTypes] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const getFileId = async (file) => {
    if (file?.id) {
      return file.id;
    } else {
      try {
        const id = await MainService.postFile(file);
        return id?.id;
      } catch (error) {
        Toast.error(STRINGS.notify.fileFailure);
      }
    }
  };
  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      Toast.error(STRINGS.notify.imageFailure);
    }
  };
  const addStoryType = async (f) => {
    if (f.file === null) {
      Toast.error(STRINGS.notify.fileRequired);
    } else {
      setShowModal('');
      if (!serialNumbers.includes(f.serialNumber)) {
        const dataToSend = {
          fileId: await getFileId(f.file),
          storyTypeData: f.storyTypeData,
          serialNumber: Number.parseInt(f.serialNumber),
        };
        try {
          await StoriesService.addStoryType(dataToSend);
          setSelectedStoryType({});
          getStoryTypes();
          Toast.success(STRINGS.notify.success);
        } catch (error) {
          Toast.error(STRINGS.notify.failure);
        }
      } else {
        Toast.error(STRINGS.notify.serialMatch);
      }
    }
  };
  const editStoryType = async (f) => {
    let dataToSend = {
      fileId: await getFileId(f.file),
      storyTypeData: f.storyTypeData,
    };
    if (!serialNumbers.includes(f.serialNumber)) {
      dataToSend = Object.assign(dataToSend, { serialNumber: f.serialNumber });
    }
    try {
      await StoriesService.editStoryType({ data: dataToSend, id: f.id });
      setShowModal('');
      setSelectedStoryType({});
      getStoryTypes();
      Toast.success(STRINGS.notify.success);
    } catch (err) {
      Toast.error(STRINGS.notify.failure);
    }
  };

  const handleDeleteStoryType = async () => {
    try {
      setShowConfirm('');
      setStoryTypes((old) => old.filter((el) => el.id !== selectedStoryType?.id));
      setSerialNumbers((old) => old.filter((el) => el !== selectedStoryType?.serialNumber));
      await StoriesService.deleteStoryType({ id: selectedStoryType?.id });
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (!error.includes('204')) {
        Toast.error(STRINGS.notify.failure);
      }
    }
  };
  const getStoryTypes = async () => {
    setSkeletVisible(true);
    try {
      const data = await StoriesService.getStoryTypes();
      setSerialNumbers(data.map((el) => el.serialNumber));
      setStoryTypes(data);
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
    setSkeletVisible(false);
  };

  useEffect(() => {
    getStoryTypes();
  }, []);

  return (
    <StoriesView
      serialNumbers={serialNumbers}
      getFileById={getFileById}
      storyTypes={storyTypes}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      handleDeleteStoryType={handleDeleteStoryType}
      selectedStoryType={selectedStoryType}
      setSelectedStoryType={setSelectedStoryType}
      saveChanges={editStoryType}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      addStoryType={addStoryType}
      skeletVisible={skeletVisible}
    />
  );
};

export default StoryTypesContainer;

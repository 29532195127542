/**
 * @prettier
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Input } from '..';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const AutocompleteStore = (props) => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { backgroundColors } = useSelector((store) => store.stuff);
  const [suggestions, setSuggestions] = useState([]);
  const [active, setActive] = useState(false);

  const [search, setSearch] = useState('');

  useEffect(async () => {
    setActive(false);
    if (search.length !== 0) {
      const data = await props.getSuggestions({
        langId: langId,
        page: 1,
        size: 30,
        search: search,
      });
      setSuggestions(
        data
          .map((el) => ({
            posId: el.posId,
            fileId: el.fileId,
            title: el.title,
            backgroundColorId: backgroundColors[0].id,
            selectedLanguage: 1,
            itemData: [
              { languageId: 1, description: '' },
              { languageId: 2, description: '' },
              { languageId: 3, description: '' },
            ],
            expanded: false,
          }))
          .reduce(
            (acc, el) => ({
              ...acc,
              [el.posId]: el,
            }),
            0,
          ),
      );
    } else {
      setSuggestions([]);
    }
    setActive(true);
  }, [search]);

  const handleClickAwayEvent = () => {
    setActive(false);
  };
  const handleSuggestionClick = (el) => {
    setActive(false);
    setSearch('');
    if (!props.selectedProducts.hasOwnProperty([el.posId])) {
      props.setSelectedProducts({ ...props.selectedProducts, [el.posId]: el });
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayEvent}>
      <div className={`autocomplete ${props.className}`}>
        <Input
          fullWidth
          value={search}
          onChange={setSearch}
          onClick={() => {
            setActive(true);
          }}
          variant="outlined"
          label={props.label}
          size={props.size}
          maxLength={40}
          rightIcon="search"
          inputType={props.inputType}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
        {active && search.length > 0 && (
          <div className="suggestions">
            {Object.values(Object.assign(suggestions, props.productsToDelete)).map((el) => (
              <div
                className="suggestion"
                onClick={() => {
                  handleSuggestionClick(el);
                }}
              >
                <span className="suggestion-id">{el?.posId}</span>
                <span className="suggestion-label">{`${el?.posTitle || el?.title}`}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
AutocompleteStore.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  data: PropTypes.array,
};
AutocompleteStore.defaultProps = {
  className: '',
  label: '',
  size: 'large',
  data: [],
};

export default AutocompleteStore;

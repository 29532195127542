import React, { useEffect, useState } from 'react';
import Loader from '../loader';

import {
  Button,
  Form,
  Input,
  Drawer,
  WeekPicker,
  SingleSelect,
  TextArea,
  Select,
  SquareButton,
  InputIp,
  DateTimePicker,
  Switch,
} from '../../../ui-kit';
import './styles.scss';
import { useSelector } from 'react-redux';
import YandexMap from '../../yandex-map';
import GlovoService from '../../../../services/glovo';
import ChocofoodService from '../../../../services/chocofood';
import RestaurantsService from '../../../../services/restaurants';
import CitiesService from '../../../../services/cities';
import Advantages from '../advantages-selector';
import STRINGS from '../../../../localization';
import Separator from '../../separator';
import { validateObject } from '../../../utils/input-functions';
import { getBeautyDateTimeString } from '../../../utils/datetime';
import {COORDINATES_REGEX, IP_REGEX, MAIL_REGEX, SIMPLE_MAIL_REGEX} from './../../../../constants/regExp';
import { PHONE_REGEX } from '../../../../constants/regExp';

const INIT_ADDRESS_DATA = [
  { languageId: 1, title: '' },
  { languageId: 2, title: '' },
  { languageId: 3, title: '' },
];

const INIT_RESTAURANT_DATA = [
  { languageId: 1, title: '', description: '' },
  { languageId: 2, title: '', description: '' },
  { languageId: 3, title: '', description: '' },
];

const INIT_FOCUS = {
  id: true,
  expirationDate: true,
  title0: true,
  title1: true,
  title2: true,
  address0: true,
  address1: true,
  address2: true,
  description0: true,
  description1: true,
  description2: true,
  phone: true,
  email:true,
  coordinates: true,
  ipAddress: true,
  cityId: true,
  printerName: true,
  packageId: true,
  timezone: true,
  tier: true,
};

const ControlGlovoClosing = (properties) => {
  const [closingLoading, setClosingLoading] = useState(true);

  const getGlovoClosing = async (restId) => {
    try {
      const resp = await GlovoService.getClosing(restId);
      if (resp?.until === null) {
        properties.setGlovoClosed(false);
      }
      if (resp?.until) {
        properties.setGlovoClosed(true);
        properties.setGlovoClosing(resp.until);
      }
    } catch {
      properties.setGlovoClosingUnavailable(true);
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (properties.isOpen === true) {
        setClosingLoading(true);
        await getGlovoClosing(properties.selectedRestaurant?.id || properties.loadedRestaurant?.id);
        setClosingLoading(false);
      }
    };
    getData();
  }, [properties.isOpen, properties.selectedRestaurant, properties.LoadedRestaurant]);

  return (
    <>
      {closingLoading ? (
        <span className="loading-label">Загрузка...</span>
      ) : (
        <div className="glovo-closing-container">
          <Switch
            disabled={properties.glovoClosingUnavailable}
            checked={properties.glovoClosed}
            onChange={properties.setGlovoClosed}
            label={STRINGS.screens.restaurants.qt}
          />
          {properties.glovoClosed && (
            <DateTimePicker
              className="container_fullwidth_one"
              fullWidth
              disablePast={true}
              size="large"
              value={properties.glovoClosing}
              onChange={properties.setGlovoClosing}
              inputValue={
                properties.glovoClosing
                  ? getBeautyDateTimeString(new Date(properties.glovoClosing))
                  : ''
              }
              label={STRINGS.screens.restaurants.qe}
            />
          )}
        </div>
      )}
    </>
  );
};

const RestaurantFields = (props) => {

  const [uniqueIdError, setUniqueIdError] = useState(false);

  const [unmodifiedRestaurant, setUnmodifiedRestaurant] = useState(undefined);

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const maxStep = props.selectedRestaurant || props.loadedRestaurant ? 5 : 2;

  const { packages, tiers } = useSelector((store) => store.stuff);

  const [step, setStep] = useState(1);

  const [phone, setPhone] = useState('');

  const [coordinates, setCoordinates] = useState('');

  const [addressData, setAddressData] = useState(INIT_ADDRESS_DATA);

  const [ipAddress, setIpAddress] = useState('');

  const [printerName, setPrinterName] = useState('');

  const [packageId, setPackageId] = useState(packages[0].posId);

  const [timezone, setTimezone] = useState('');

  const [tier, setTier] = useState(tiers[0].tier);

  const [selectedAdvantages, setSelectedAdvantages] = useState([]);

  const [workingHours, setWorkingHours] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState('');

  const [selectedLanguage, setSelectedLanguage] = useState(1);

  const [id, setId] = useState('');

  const [cityQuery, setCityQuery] = useState('');

  const [cities, setCities] = useState([]);

  const [cityId, setCityId] = useState();

  const [restaurantData, setRestaurantData] = useState(INIT_RESTAURANT_DATA);

  const [validation, setValidation] = useState();

  const [glovoClosing, setGlovoClosing] = useState('');

  const [glovoClosed, setGlovoClosed] = useState(false);

  const [glovoClosingUnavailable, setGlovoClosingUnavailable] = useState(false);

  const [chocofoodActive, setChocofoodActive] = useState(false);

  const [initChocofoodActive, setInitChocofoodActive] = useState(false);

  const [email, setEmail] = useState('');

  const SCHEME = {
    id: (value) => !!value?.length && uniqueIdError === false,
    title0: (value) => value?.length > 0,
    title1: (value) => value?.length > 0,
    title2: (value) => value?.length > 0,
    address0: (value) => value?.length > 0,
    address1: (value) => value?.length > 0,
    address2: (value) => value?.length > 0,
    description0: (value) => value?.length > 0,
    description1: (value) => value?.length > 0,
    description2: (value) => value?.length > 0,
    phone: (value) => PHONE_REGEX.test(value) || value === '',
    email: (value) => SIMPLE_MAIL_REGEX.test(value),
    coordinates: (value) => COORDINATES_REGEX.test(value),
    ipAddress: (value) => value ? IP_REGEX.test(value) : true,
    cityId: (value) => !!value,
    printerName: () => true,
    packageId: () => true,
    timezone: () => true,
    tier: () => true,
  };

  const [isFocus, setIsFocus] = useState(INIT_FOCUS);

  const changeFocus = (field, focus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: focus }));
  };

  const titles = restaurantData.reduce(
    (acc, el, key) => ({ ...acc, [`title${key}`]: el.title }),
    [],
  );

  const addresses = addressData.reduce(
    (acc, el, key) => ({ ...acc, [`address${key}`]: el.title }),
    [],
  );

  const descriptions = restaurantData.reduce(
    (acc, el, key) => ({ ...acc, [`description${key}`]: el.description }),
    [],
  );

  useEffect(() => {
    setValidation(
      validateObject(
        Object.assign(titles, descriptions, addresses, {
          id,
          coordinates,
          cityId,
          ipAddress,
          printerName,
          packageId,
          timezone,
          tier,
          email,
        }),
        SCHEME,
      ),
    );
  }, [
    id,
    restaurantData,
    addressData,
    coordinates,
    cityId,
    ipAddress,
    printerName,
    packageId,
    timezone,
    tier,
    uniqueIdError,
    unmodifiedRestaurant,
    email,
  ]);

  const onClose = () => {
    setIsModalOpen('');
  };

  const getSelectedAdvantages = () => {
    if (selectedAdvantages) {
      return selectedAdvantages.reduce((acc, el) => [...acc, { advantageId: el }], []);
    } else {
      return [];
    }
  };

  const prepareRestaurantData = (restaurantDataPatched) => {
    return restaurantData.map((el) => ({
      languageId: el.languageId,
      title: restaurantDataPatched?.find((i) => i?.languageId === el.languageId)?.title ?? '',
      description:
        restaurantDataPatched?.find((i) => i?.languageId === el.languageId)?.description ?? '',
    }));
  };

  const prepareAddressData = (addressDataPatched) => {
    return addressData.map((el) => ({
      languageId: el.languageId,
      title: addressDataPatched?.find((i) => i?.languageId === el.languageId)?.title ?? '',
    }));
  };

  const mapData = async (data, isReady = false) => {
    try {
      props.setLoading(true);
      setStep(1);
      const rest = isReady ? data : await props.getRestaurantById(data);
      setUnmodifiedRestaurant(rest);
      setId(`${rest?.id}`);
      setTier(rest?.tier || tiers[0].tier);
      setPhone(rest?.phone);
      setEmail(rest?.email);
      setCoordinates(`${rest?.address?.lng} ${rest?.address?.lat}`);
      setAddressData(prepareAddressData(rest?.address?.addressData));
      setWorkingHours(
        rest?.workingHours.length >= 7
          ? rest?.workingHours?.reduce(
            (acc, el) => [
              ...acc,
              {
                serviceTypeId: el.serviceTypeId,
                day: el.day,
                openTime: el.openTime,
                closeTime: el.closeTime,
                operatingMode: el.operatingMode,
              },
            ],
            [],
          )
          : [null, null, null, null, null, null, null].map((el, key) => ({
            serviceTypeId: 1,
            day: key + 1,
            openTime: new Date(new Date().setHours(0, 0, 0, 0)),
            closeTime: new Date(new Date().setHours(0, 0, 0, 0)),
            operatingMode: 'DAY',
          })),
      );
      setPackageId(rest?.packageId || packages[0].posId);
      setIpAddress(rest?.ipAddress);
      setPrinterName(rest?.printerName);
      setTimezone(rest?.timeZone);
      setRestaurantData(prepareRestaurantData(rest?.restaurantData));
      setSelectedAdvantages(rest?.restaurantAdvantages?.map((el) => el.advantageId));
      setCityId(rest?.address?.cityId);
      setCityQuery(rest?.address?.city?.cityData?.find((el) => el.languageId === langId).name);
      const chocoActive = await ChocofoodService.getClosing(props.loadedRestaurant?.id || props.selectedRestaurant?.id);
      setChocofoodActive(chocoActive?.is_active);
      setInitChocofoodActive(chocoActive?.is_active);
    } catch (e) { }
    props.setLoading(false);
  };

  useEffect(() => {
    const getCitiesByQuery = async () => {
      if (cityQuery?.length > 0) {
        try {
          const resp = await CitiesService.getAutocomplete({ search: cityQuery, langId: langId });
          setCities(resp);
        } catch (error) {
          setCities([]);
        }
      }
    };
    getCitiesByQuery();
  }, [cityQuery]);

  const initialize = () => {
    setUniqueIdError(false);
    setUnmodifiedRestaurant(undefined);
    setStep(1);
    setId();
    setPhone('');
    setCoordinates('');
    setAddressData(INIT_ADDRESS_DATA);
    setWorkingHours([]);
    setPackageId(packages[0].posId);
    setIpAddress('');
    setPrinterName('');
    setTimezone('');
    setRestaurantData(INIT_RESTAURANT_DATA);
    setSelectedAdvantages([]);
    setCityId();
    setEmail('');
    setTier(tiers[0].tier);
    setGlovoClosingUnavailable(false);
    setGlovoClosing();
    setGlovoClosed(false);
    setIsFocus(INIT_FOCUS);
    setChocofoodActive(false);
    setInitChocofoodActive(false);
  };

  useEffect(() => {
    if (props.isOpen === true && props.selectedRestaurant?.id !== undefined) {
      mapData(props.selectedRestaurant.id);
    }
  }, [props.selectedRestaurant?.id, props.isOpen]);

  useEffect(() => {
    if (props?.loadedRestaurant?.id !== undefined) {
      mapData(props?.loadedRestaurant, true);
    }
  }, [props.loadedRestaurant?.id, props.isOpen]);

  useEffect(() => {
    if (props.city) {
      props.setCityQuery(props.city?.cityData[0]?.name);
    }
  }, [props.city]);

  useEffect(() => {
    if (props.isOpen === false) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  useEffect(() => {
    const checkUniqueId = async () => {
      setUniqueIdError(false);
      if (id !== undefined && id !== '' && parseInt(id, 10) !== unmodifiedRestaurant?.id) {
        try {
          await RestaurantsService.getRestaurantById(id);
          setUniqueIdError(true);
        } catch { }
      }
    };
    if (props.isOpen === true) {
      checkUniqueId();
    }
  }, [id, props.isOpen]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
    >
      <Advantages
        setOpen={setIsModalOpen}
        advantages={props.advantages}
        selectedAdvantages={selectedAdvantages}
        setSelectedAdvantages={setSelectedAdvantages}
        isOpen={isModalOpen === 'advantages'}
        onClose={onClose}
        title={STRINGS.screens.restaurants.chooseTags}
        onBlur={() => setIsModalOpen('')}
      />
      <YandexMap
        setCoordinates={setCoordinates}
        coordinates={coordinates}
        isOpen={isModalOpen === 'map'}
        onClose={onClose}
        title={STRINGS.screens.restaurants.coordinates}
        onBlur={() => setIsModalOpen('')}
        zoom={14}
        kind="house"
        fullAddress
      />
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges({
              unmodifiedRestaurant,
              glovoClosed,
              glovoClosing,
              glovoClosingUnavailable,
              id: parseInt(id, 10),
              phone: phone,
              email:email,
              tier: tier,
              cityId: parseInt(cityId, 10),
              address: {
                lat: parseFloat(coordinates.split(' ')[1]),
                lng: parseFloat(coordinates.split(' ')[0]),
                cityId: parseInt(cityId, 10),
                addressData: [...addressData],
              },
              timezone,
              packageId,
              printerName,
              ipAddress,
              restaurantData: restaurantData,
              workingHours: [...workingHours],
              restaurantAdvantages: getSelectedAdvantages(),
              chocofoodActive,
              initChocofoodActive,
            });
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.general}</span>
                    </div>
                    <Input
                      label={`${STRINGS.screens.restaurants.id} *`}
                      className="container_fullwidth_one_first"
                      fullWidth
                      type="number"
                      size="large"
                      value={id}
                      onChange={setId}
                      inputType={
                        !isFocus.id && !validation?.data?.id?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('id', true)}
                      onBlur={() => changeFocus('id', false)}
                    />
                    {uniqueIdError && (
                      <div className="error">
                        <span>{STRINGS.screens.restaurants.qy}</span>
                      </div>
                    )}
                    <Input
                      className="container_fullwidth_one"
                      fullWidth
                      variant="outlined"
                      label={`${STRINGS.screens.restaurants.coordinates} *`}
                      inputType={
                        !isFocus.coordinates && !validation?.data?.coordinates?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      value={coordinates}
                      onChange={setCoordinates}
                      size="large"
                      dataIconPointer={true}
                      rightIcon="location"
                      maxLength={40}
                      iconClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen('map');
                      }}
                      onFocus={() => changeFocus('coordinates', true)}
                      onBlur={() => changeFocus('coordinates', false)}
                    />
                    <SingleSelect
                      className="container_fullwidth_one"
                      label={`${STRINGS.screens.restaurants.qu} *`}
                      data={cities}
                      result={cityId}
                      setResult={setCityId}
                      dataQuery={cityQuery}
                      setDataQuery={setCityQuery}
                      rightIcon="search"
                      inputType={
                        !isFocus?.cityId && !validation?.data?.cityId?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('cityId', true)}
                      onBlur={() => changeFocus('cityId', false)}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.contact}</span>
                    </div>
                    <Input
                      required={false}
                      className="container_fullwidth_one_first"
                      type="phone"
                      label={`${STRINGS.screens.restaurants.phone}`}
                      onBlur={() => changeFocus('phone', false)}
                      onFocus={() => changeFocus('phone', true)}
                      inputType={
                        !isFocus.phone && !validation?.data?.phone?.isValid ? 'danger' : 'default'
                      }
                      value={phone}
                      onChange={setPhone}
                    />
                    <div className="subtitle" style={{marginTop:'16px'}}>
                      <span>{STRINGS.screens.modal.email}</span>
                    </div>
                    <Input
                        required={false}
                        className="container_fullwidth_one_first"
                        type="email"
                        label={`${STRINGS.screens.modal.email}`}
                        onBlur={() => changeFocus('email', false)}
                        onFocus={() => changeFocus('email', true)}
                        inputType={
                          !isFocus.email && !validation?.data?.email?.isValid ? 'danger' : 'default'
                        }
                        value={email}
                        onChange={setEmail}
                    />
                  </div>
                </>
              )}
              {(props.selectedRestaurant || props.loadedRestaurant) && step === 3 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.restaurants.tags}</span>
                  </div>
                  {props.advantages && (
                    <div className="advantages_container">
                      {selectedAdvantages?.map((el) => (
                        <div className="advantage_container">
                          <Button
                            className="advantage"
                            size="small"
                            type="primary"
                            label={props.advantages?.find((i) => i.id === el).value}
                            key={el}
                            outline={true}
                            onClick={() => {
                              !selectedAdvantages?.find((role) => role === el)
                                ? setSelectedAdvantages([...selectedAdvantages, el])
                                : setSelectedAdvantages(
                                  selectedAdvantages.filter((item) => item !== el),
                                );
                            }}
                          />
                        </div>
                      ))}
                      <SquareButton
                        type="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsModalOpen('advantages');
                        }}
                        size="medium"
                        icon="plus"
                      />
                    </div>
                  )}
                </div>
              )}
              {step === 4 && (props.selectedRestaurant || props.loadedRestaurant) && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.restaurants.workingHours}</span>
                  </div>
                  <WeekPicker
                    label={STRINGS.screens.restaurants.chooseServiceType}
                    data={workingHours}
                    workingHours={workingHours}
                    setWorkingHours={setWorkingHours}
                  />
                </div>
              )}
              {step === 5 && (props.selectedRestaurant || props.loadedRestaurant) && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.other}</span>
                  </div>
                  <Select
                    className="container_fullwidth_one_first"
                    label={`${STRINGS.screens.modal.packageType}`}
                    value={packageId}
                    onChange={setPackageId}
                    size="large"
                    options={packages.map((el) => ({ label: el?.posTitle, key: el?.posId }))}
                    selectType={
                      !isFocus?.packageId && !validation?.data?.packageId?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    onFocus={() => changeFocus('packageId', true)}
                    onBlur={() => changeFocus('packageId', false)}
                  />
                  <Select
                    className="container_fullwidth_one"
                    label={`${STRINGS.screens.modal.tiers}`}
                    value={tier}
                    onChange={setTier}
                    size="large"
                    options={tiers.map((el) => ({ label: el?.title, key: el?.tier }))}
                    selectType={
                      !isFocus?.tier && !validation?.data?.tier?.isValid ? 'danger' : 'default'
                    }
                    onFocus={() => changeFocus('tier', true)}
                    onBlur={() => changeFocus('tier', false)}
                  />
                  <InputIp
                    className="container_fullwidth_one"
                    fullWidth
                    value={ipAddress}
                    onChange={setIpAddress}
                    inputType={
                      !isFocus?.ipAddress && !validation?.data?.ipAddress?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    variant="outlined"
                    label={`${STRINGS.screens.modal.ipAddress}`}
                    size="large"
                    onFocus={() => changeFocus('ipAddress', true)}
                    onBlur={() => changeFocus('ipAddress', false)}
                  />
                  <Input
                    value={printerName}
                    onChange={setPrinterName}
                    className="container_fullwidth_one"
                    fullWidth
                    inputType={
                      !isFocus?.printerName && !validation?.data?.printerName?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    variant="outlined"
                    label={`${STRINGS.screens.modal.printerName}`}
                    size="large"
                    maxLength={40}
                    onFocus={() => changeFocus('printerName', true)}
                    onBlur={() => changeFocus('printerName', false)}
                  />
                  <Input
                    value={timezone}
                    onChange={setTimezone}
                    className="container_fullwidth_one"
                    fullWidth
                    inputType={
                      !isFocus?.timezone && !validation?.data?.timezone?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    variant="outlined"
                    label={`${STRINGS.screens.modal.timezone}`}
                    size="large"
                    maxLength={40}
                    onFocus={() => changeFocus('timezone', true)}
                    onBlur={() => changeFocus('timezone', false)}
                  />
                  <div className="container_fullwidth_one">
                    <Switch
                      checked={chocofoodActive}
                      onChange={setChocofoodActive}
                      label={STRINGS.screens.restaurants.qi}
                    />
                    <ControlGlovoClosing
                      glovoClosing={glovoClosing}
                      setGlovoClosing={setGlovoClosing}
                      glovoClosed={glovoClosed}
                      setGlovoClosed={setGlovoClosed}
                      glovoClosingUnavailable={glovoClosingUnavailable}
                      setGlovoClosingUnavailable={setGlovoClosingUnavailable}
                      selectedRestaurant={props.selectedRestaurant}
                      loadedRestaurant={props.loadedRestaurant}
                      isOpen={props.isOpen}
                    />
                  </div>
                </div>
              )}
              {step === 2 ? (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span className="subtitle">{STRINGS.screens.modal.langs}</span>
                  </div>
                  <div className="langs">
                    {props.languages?.map((language) => (
                      <Button
                        label={language.name}
                        value={language.id}
                        size="medium"
                        className="lang"
                        type={selectedLanguage === language.id ? 'primary' : 'default'}
                        data-active={selectedLanguage === language.id}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedLanguage(language.id);
                        }}
                      >
                        <span className="day-label">{language.name}</span>
                      </Button>
                    ))}
                  </div>
                  <Input
                    className="container_fullwidth_one_first"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...restaurantData];
                      temp[selectedLanguage - 1].title = val;
                      setRestaurantData(temp);
                    }}
                    inputType={
                      !isFocus?.[`title${selectedLanguage - 1}`] &&
                        !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    value={restaurantData[selectedLanguage - 1].title}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
                    onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                  />
                  <Input
                    className="container_fullwidth_one"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...addressData];
                      temp[selectedLanguage - 1].title = val;
                      setAddressData(temp);
                    }}
                    inputType={
                      !isFocus?.[`address${selectedLanguage - 1}`] &&
                        !validation?.data?.[`address${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    value={addressData[selectedLanguage - 1].title}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.address[selectedLanguage - 1]} *`}
                    size="large"
                    maxLength={50}
                    onFocus={() => changeFocus(`address${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`address${selectedLanguage - 1}`, false)}
                  />
                  <TextArea
                    textareaType={
                      !isFocus?.[`description${selectedLanguage - 1}`] &&
                        !validation?.data?.[`description${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    className="container_fullwidth_one"
                    onChange={(val) => {
                      let temp = [...restaurantData];
                      temp[selectedLanguage - 1].description = val;
                      setRestaurantData(temp);
                    }}
                    value={restaurantData[selectedLanguage - 1].description}
                    label={`${STRINGS.screens.modal.description[selectedLanguage - 1]} *`}
                    multiline
                    rows={4}
                    onFocus={() => changeFocus(`description${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`description${selectedLanguage - 1}`, false)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          <Separator indent={80} />
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                disabled={!validation.isValid}
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                type={!validation.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default RestaurantFields;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import colors from '../../styles/colors';
import './styles.scss';

const WZCheckboxView = withStyles({
  root: {
    color: colors.color_gray._1,
    '&$checked': {
      color: colors.color_primary._4,
    },
  },
  checked: {},
})((props) => {
  return (
    <button
      className={`checkbox ${props.className}`}
      disabled={props.disabled}
      onClick={(e) => {
        e.preventDefault();
        props.onChange();
      }}
    >
      <Checkbox
        props
        color="default"
        indeterminate={props.indeterminate}
        classes={props.classes}
        checked={props.checked}
        disabled={props.disabled}
      />
      {props.label && <span className="label">{props.label}</span>}
    </button>
  );
});

/**
 * @component Checkbox
 * @example
 * <Checkbox
 *  disabled={false}
 *  onChange={() => {}}
 *  checked={false}
 *  label="Female"
 * />
 */
const WZCheckbox = (props) => {
  return (
    <WZCheckboxView
      indeterminate={props.indeterminate}
      disabled={props.disabled}
      onChange={() => props.onChange(!props.checked)}
      checked={props.checked}
      label={props.label}
    />
  );
};

/**
 * Properties
 */

WZCheckbox.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
};
WZCheckbox.defaultProps = {
  disabled: false,
  onChange: () => {},
  checked: false,
  label: null,
  className: '',
};

export default WZCheckbox;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

import './styles.scss';
import { Button } from '../';

/**
 * @component Confirm
 * @example
 * <Confirm
 *  visible={false}
 *  buttons={[
 *    {
 *      label: 'Cancel',
 *      onClick: () => {},
 *    }
 *  ]}
 * />
 */
const Confirm = (props) => {
  return (
    <Dialog maxWidth="xs" open={props.visible} onClose={props.onClose}>
      <div className="confirm-root">
        {props.title && <h2>{props.title}</h2>}
        {props.subtitle && (
          <>
            <p className="confirm-text">
              {props.subtitle} <b>{props.subtitleBold}</b>
              {props.isQuestion && '?'}
            </p>
          </>
        )}
        {props.buttons?.length > 0 && (
          <>
            {props.buttons.length === 2 && (
              <div className="buttons">
                {props.buttons.map((item, index) => (
                  <React.Fragment key={`confirm-button-${index}`}>
                    <Button {...item} />
                  </React.Fragment>
                ))}
              </div>
            )}
            {props.buttons.length === 1 &&
              props.buttons.map((item, index) => (
                <div className="single" key={`alert-button-${index}`}>
                  <Button {...item} />
                </div>
              ))}
          </>
        )}
      </div>
    </Dialog>
  );
};

/**
 * Properties
 */

Confirm.propTypes = {
  isQuestion: PropTypes.bool,
  visible: PropTypes.bool,
  buttons: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
};
Confirm.defaultProps = {
  isQuestion: true,
  buttons: [],
  visible: false,
  title: null,
  subtitle: null,
  onClose: () => {},
};

export default Confirm;

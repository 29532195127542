/**
 * @prettier
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { Icon } from '../index';

/**
 * @component Dropdown
 * @example
 * <Dropdown title="title">{children}</Dropdown>
 */
const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="dropdown">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`dropdown_title ${isOpen ? 'dropdown_title_open' : ''}`}
      >
        <span>{props.title}</span>
        <div className={isOpen ? 'dropdown_arrow_open' : 'dropdown_arrow'}>
          <Icon name="arrow-down" height={20} width={20} />
        </div>
      </div>
      {isOpen && <div className="dropdown_children">{props.children}</div>}
    </div>
  );
};

/**
 * Properties
 */

Dropdown.propTypes = {
  title: PropTypes.string,
};

Dropdown.defaultProps = {
  title: '',
};

export default Dropdown;

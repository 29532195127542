/**
 * @prettier
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Input } from '..';

import { DragDropContext } from 'react-beautiful-dnd';
import { ClickAwayListener } from '@material-ui/core';
import SelectedSuggestions from './components/SelectedSuggestions';
import ToBuy from './components/ToBuy';
import ToAchieve from './components/ToAchieve';

const CustomSelectDnd = (props) => {
  const [active, setActive] = useState(false);

  const onDragEnd = ({ source, destination }) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index)
      return null;

    // Set start and end variables
    const start = props.areas[props.name][source.droppableId];
    const end = props.areas[props.name][destination.droppableId];

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item

      const newList = start.list.filter((item, idx) => idx !== source.index);

      // // Then insert the item at the right location
      newList.push(start.list[source.index]);

      // // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      };

      // // Update the state
      props.setAreas((state) => ({
        ...state,
        [props.name]: {
          ...state[props.name],
          [newCol.id]: newCol,
        },
      }));
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter((item, idx) => idx !== source.index);

      // Create a new start column
      const newStartCol = {
        id: start.id,
        list: newStartList,
      };
      // Make a new end list array
      const newEndList = end.list;

      if (end.list.filter((el) => el.posId === start.list[source.index].posId)[0]) {
        // If droppableItem already exists in destination area, we just do nothing
      } else {
        // Insert the item into the end list
        newEndList.push(start.list[source.index]);
      }

      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList,
      };

      // Update the state
      props.setAreas((state) => ({
        ...state,
        [props.name]: {
          ...state[props.name],
          [newStartCol.id]: newStartCol,
          [newEndCol.id]: newEndCol,
        },
      }));
      return null;
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <div
        className={`custom-select-container_search ${props.className}`}
        onClick={() => {
          setActive(false);
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Input
            className="custom-select-container_search_input"
            fullWidth
            value={props.value}
            onChange={(e) => {
              props.onChange(e);
              setActive(true);
            }}
            onClick={() => {
              setActive((prev) => !prev);
            }}
            variant="outlined"
            label={props.label}
            size={props.size}
            maxLength={40}
            rightIcon="search"
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            inputType={props.inputType}
          />
          {active && props.data?.length > 0 && props.value && (
            <div className="custom-select-container_search_suggestions">
              {props.data.map((el, index) => (
                <div
                  key={index}
                  className="custom-select-container_search_suggestions_suggestion"
                  onClick={() => {
                    let temp = props.data?.map((i) => ({ ...i, number: 1 }));
                    setActive(false);
                    props.setAreas((prev) => ({
                      ...prev,
                      [props.name]: {
                        ...prev[props.name],
                        itemArea: {
                          ...prev[props.name].itemArea,
                          list: [
                            ...prev[props.name].itemArea.list,
                            temp.find((t) => t.posId === el.posId),
                          ],
                        },
                      },
                    }));
                    props.onChange('');
                  }}
                >
                  <span className="selected-label-id">{el.posId || el?.id}</span>
                  <span className="selected-label">{el.posTitle || el?.title}</span>
                </div>
              ))}
            </div>
          )}
          <SelectedSuggestions
            {...props}
            area={props.areas[props.name].itemArea}
            setAreas={props.setAreas}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '20px',
              alignItems: 'center',
              marginTop: '30px',
              width: '100%',
              height: '50vh',
            }}
          >
            <ToBuy {...props} area={props.areas[props.name].toBuy} setAreas={props.setAreas} />
            <ToAchieve
              {...props}
              area={props.areas[props.name].toAchieve}
              setAreas={props.setAreas}
            />
          </div>
        </DragDropContext>
      </div>
    </ClickAwayListener>
  );
};

CustomSelectDnd.propTypes = {
  multiple: PropTypes.bool,
  selected: PropTypes.array,
  label: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  title: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  isPunchCard: PropTypes.bool,
};

CustomSelectDnd.defaultProps = {
  multiple: true,
  selectedProducts: [],
  label: '',
  size: 'large',
  options: [],
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  title: null,
  className: '',
  isPunchCard: false,
};

export default CustomSelectDnd;

/**
 * @prettier
 */

import React from 'react';
import { Head } from '../../components';
import STRINGS from '../../../localization';
import DashboardCategory from './components/DashboardCategory';

import './styles.scss';

const DashboardView = (props) => {
  return (
    <div>
      <Head label={STRINGS.screens.dashboard.main} button={false} />
      <div className="categories-container">
        {!!props.categories &&
          props.categories?.map((el) => {
            return (
              <DashboardCategory key={el.id} label={el.label} subcategories={el.subcategories} />
            );
          })}
      </div>
    </div>
  );
};

export default DashboardView;

/**
 * @prettier
 */
import { setData } from './stuff';
import { Toast } from '../../main/ui-kit';
import {
  getLanguages,
  getTiers,
  getCategories,
  getAdvantages,
  getAggregators,
  getModificatorCategories,
  getComboCategories,
  getBackgroundColors,
  getPackages,
  getRoles,
} from './getters';

/**
 * Redux thunks for current user.
 */
class StuffThunks {
  static setData(langId) {
    return async (dispatch) => {
      try {
        const resp = await Promise.all([
          getLanguages(),
          getRoles(),
          getTiers(),
          getCategories(langId),
          getComboCategories(langId),
          getAdvantages(langId),
          getAggregators(),
          getModificatorCategories(langId),
          getBackgroundColors(),
          getPackages(),
        ]);
        dispatch(
          setData(resp.reduce((acc, current) => ({ ...acc, [current.key]: current.data }), {})),
        );
      } catch (error) {
        Toast.error('Произошла ошибка при получении данных');
      }
    };
  }
}

export default StuffThunks;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';
export default class CategoriesService {
  static #API_ENDPOINTS = {
    categories: 'product-categories',
  };

  static async getCategories(params) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.categories}/${params.key}?langId=${params.langId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.categories}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.categories}/${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(status),
      });

      debug.success('Coupon data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.coupons}/${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateСategory(id, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.categories}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.categories}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteCategory(id) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.categories}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Category data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.cities}`, error);
      throw await error.response?.json();
    }
  }
  static async addCategory(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.categories}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Categories data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.categories}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getCategoryById(id, langId) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.categories}/${id}?langId=${langId}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Category data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }
      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.categories}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import RestauransService from './services/restaurants';
import { Header, Sidebar } from './main/components';
import { Header as HeaderRestaurant, Sidebar as SidebarRestaurant } from './restaurant/components';
import MAIN_ROUTES from './routes/mainRoutes';
import RESTAURANT_ROUTES from './routes/restaurantRoutes';
import LocalizationActions from './store/localization/actions';
import { useComponentWillMount } from './main/utils/hooks';
import AuthorizationContainer from './pages/authorization';
import StuffThunks from './store/stuff/thunks';
import SplashPage from './main/pages/splash-page';

const App = () => {
  const [isOpenedRestaurant, setIsOpenedRestaurant] = useState(true);

  const dispatch = useDispatch();
  const [sidebarVisible, setSidebarVisible] = useState();
  const user = useSelector((store) => store.user);
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { loading } = useSelector((store) => store.stuff);

  useComponentWillMount(async () => {
    dispatch(StuffThunks.setData(langId));
    dispatch(LocalizationActions.initial());
  });

  const checkRestaurantStatusForChangeColor = async () => {
    if (user?.restaurant?.id) {
      const data = await RestauransService.getRestaurantById(user?.restaurant?.id);

      if (data.status === 'active') {
        setIsOpenedRestaurant(true);

      } else {
        setIsOpenedRestaurant(false);
      }
    }
  };

  useEffect(async () => {
    dispatch(StuffThunks.setData(langId));

    await checkRestaurantStatusForChangeColor();
  }, [langId]);

  setInterval(async () => {
    await checkRestaurantStatusForChangeColor();
  }, 300000);

  const MainLayout = () => (
    <Router>
      <div id="main">
        <Sidebar onClick={setSidebarVisible} sidebarVisible={sidebarVisible} />
        <Switch>
          {MAIN_ROUTES.map((page, index) => (
            <Route path={page.path} exact={page.exact} key={`route-key-${index}`}>
              <div className="root-container">
                <Header
                  onClick={setSidebarVisible}
                  burgerState={sidebarVisible}
                  searchVisible={page.searchVisible}
                />
                <div className="root-content">{page.component}</div>
              </div>
            </Route>
          ))}
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </div>
    </Router>
  );

  const RestaurantLayout = () => (
    <Router>
      <div id="main">
        <SidebarRestaurant onClick={setSidebarVisible} sidebarVisible={sidebarVisible} roleId={user.data.roleId} />
        <Switch>
          {RESTAURANT_ROUTES.map((page, index) => (
            page.path === '/webkassa-checks' && user.data.roleId !== 2
            ? <></>
            : <Route path={page.path} key={`route-key-${index}`}>
              <div className="root-container-restaurant" style={isOpenedRestaurant ? {} : { backgroundColor: '#CB3636' }}>
                <HeaderRestaurant onClick={setSidebarVisible} burgerState={sidebarVisible} />
                <div className={page.path === '/aggregators' && 'root-content'}>
                  {page.component}
                </div>
              </div>
            </Route>
          ))}
          <Redirect from="*" to="/aggregators" />
        </Switch>
      </div>
    </Router>
  );

  const MainPrivateRoute = ({ component: Component }) => (
    <Route render={(props) => <Component {...props} />} />
  );

  const RestaurantPrivateRoute = ({ component: Component }) => (
    <Route render={(props) => <Component {...props} />} />
  );

  return loading ? (
    <SplashPage />
  ) : (
    <Router>
      <Switch>
        {user?.authorized && user.data.restaurantId && (
          <RestaurantPrivateRoute path="/" component={RestaurantLayout} />
        )}
        {user?.authorized === true ? (
          <MainPrivateRoute path="/" component={MainLayout} />
        ) : (
          <>
            <Route path="/" exact render={() => <AuthorizationContainer />} />
            <Redirect from="*" to="/" />
          </>
        )}
      </Switch>
    </Router>
  );
};

export default App;

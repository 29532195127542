/**
 * @prettier
 */

import React from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import CouponFields from '../../components/modals/coupon-fields';
import { Paper } from '../../ui-kit';
import '../styles.scss';
import Loader from './loader';
import Block from '../../components/Block';
import Row from '../../components/Row';
import ColorRound from '../../components/ColorRound';
import NOPHOTO from '../../../assets/nophoto.png';
import { URL } from '../../../services/config';
import { getBeautyDateString } from '../../utils/datetime';
import { generatePrice } from '../../utils/generate-price';

const CouponViewTableBody = ({ items }) => {
  return items.map((el) => (
    <tr>
      <td>{el?.title}</td>
      <td>
        <div className="number">{el?.number || STRINGS.screens.modal.noData}</div>
      </td>
    </tr>
  ));
};

const View = (props) => {
  const { coupon } = props;

  return (
    <>
      <CouponFields
        langId={props.langId}
        TYPES={props.TYPES}
        backgroundColors={props.backgroundColors}
        languages={props.languages}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        loadedCoupon={props.coupon}
        title={STRINGS.screens.coupons.editCoupon}
        loading={props.loading}
        setLoading={props.setLoading}
        getCouponById={props.getCouponById}
        getFileById={props.getFileById}
        saveChanges={props.saveChanges}
      />
      <Head
        label={
          coupon?.couponData.find((el) => el.languageId === props.langId)?.title ||
          props.history?.location?.state?.title
        }
        button={{
          size: 'medium',
          icon: false,
          label: STRINGS.screens.coupons.editCoupon,
          onClick: () => props.setShowModal('edit'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.coupons.coupons,
            link: '/coupons',
          },
          {
            label: props.couponId,
          },
        ]}
        status={props.history?.location?.state?.status}
      />
      {props.skeletVisible ? (
        <Loader />
      ) : (
        <Paper>
          <div className="coupon-view-container">
            <div>
              <Block>
                <div className="picture">
                  {coupon?.fileId ? (
                    <img className="image" src={`${URL}files/${coupon?.fileId}`} />
                  ) : (
                    <img src={NOPHOTO} />
                  )}
                </div>
              </Block>
              <Block title={STRINGS.screens.discounts.productsList}>
                <table>
                  <thead>
                    <tr>
                      <th>{STRINGS.screens.coupons.qi}</th>
                      <th>{STRINGS.screens.coupons.qo}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <CouponViewTableBody
                      items={[...coupon?.products, ...coupon?.combo, ...coupon?.modificators]}
                    />
                  </tbody>
                </table>
              </Block>
            </div>
            <div>
              <Block title={STRINGS.screens.modal.general}>
                <Row
                  title={STRINGS.screens.coupons.expirationDateETX}
                  data={getBeautyDateString(new Date(coupon?.expirationDate))}
                />
                <Row title={STRINGS.screens.coupons.codeEXT} data={coupon?.code} />
                <Row
                  title={STRINGS.screens.coupons.qx}
                  data={
                    coupon?.unlimited
                      ? STRINGS.screens.coupons.qc
                      : coupon?.count || STRINGS.screens.modal.noData
                  }
                />
                <Row title={STRINGS.screens.coupons.price} data={generatePrice(coupon?.price)} />
                <Row
                  title={STRINGS.screens.coupons.discount}
                  data={
                    coupon?.discountType === 'value'
                      ? generatePrice(coupon?.discount)
                      : `${coupon.discount}%`
                  }
                />
                <Row
                  title={STRINGS.screens.coupons.typeEXT}
                  data={props.TYPES.find((el) => el.id === coupon?.type)?.label}
                />
                <Row
                  title={STRINGS.screens.coupons.couponActivity}
                  arrayOfData={coupon?.couponWorkingTimes}
                />
                <Row
                  title={STRINGS.screens.coupons.qv}
                  specific={
                    <ColorRound
                      color={
                        props.backgroundColors.find((el) => el.id === coupon.backgroundColorId)?.hex
                      }
                    />
                  }
                />
              </Block>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};
export default View;

/**
 * @prettier
 */

/**
 * Used for get initials
 * @param {String} name
 * @param {String} lastName
 * @returns {String}
 */
const initials = (name, lastName) => {
  return `${name?.split('')[0].toLocaleUpperCase()}${lastName?.split('')[0].toLocaleUpperCase()}`;
};

export default initials;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Form,
  Input,
  Drawer,
  DateTimePicker,
  Select,
  Switch,
  ColorSelector,
  CustomDropzone,
} from '../../../ui-kit';
import Loader from '../loader';
import STRINGS from '../../../../localization';
import { useSelector } from 'react-redux';
import { getBeautyDateTimeString } from '../../../utils/datetime';
import { validateObject } from '../../../utils/input-functions';
import Separator from '../../separator';

const StoryFields = (props) => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const STORY_DATA_TEMPLATE = [
    { languageId: 1, title: '', subtitle: '', buttonText: '' },
    { languageId: 2, title: '', subtitle: '', buttonText: '' },
    { languageId: 3, title: '', subtitle: '', buttonText: '' },
  ];
  const FOCUS_INIT = {
    startDateTime: true,
    endDateTime: true,
    title0: true,
    title1: true,
    title2: true,
    subtitle0: true,
    subtitle1: true,
    subtitle2: true,
    buttonText0: true,
    buttonText1: true,
    buttonText2: true,
    file: true,
    storyTypeId: true,
  };
  const maxStep = 3;
  const [step, setStep] = useState(1);
  const [id, setId] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [isTabBarDark, setIsTabBarDark] = useState(false);
  const [link, setLink] = useState('');
  const [colorId, setColorId] = useState(1);
  const [file, setFile] = useState(null);
  const [storyTypeId, setStoryTypeId] = useState();
  const [storyData, setStoryData] = useState(STORY_DATA_TEMPLATE);
  const [typeOptions, setTypeOptions] = useState();

  const getTypeOptions = (langId) => {
    const typeOptions = props.storyTypes.reduce(
      (acc, el) => [...acc, { label: el.storyTypeData[langId].title, key: el.id }],
      [],
    );
    return typeOptions;
  };

  const initialize = () => {
    setStep(1);
    setStoryData(STORY_DATA_TEMPLATE);
    setLink('');
    setColorId(1);
    setFile(null);
    setStoryTypeId(1);
    setStartDateTime();
    setEndDateTime();
    setSelectedLanguage(1);
    setId();
    setIsFocus(FOCUS_INIT);
  };

  const SCHEME = {
    startDateTime: (value) => !!value,
    endDateTime: (value) => !!value,
    file: (value) => !!value,
    storyTypeId: (value) => !!value,
  };

  const [validation, setValidation] = useState();

  const [isFocus, setIsFocus] = useState(FOCUS_INIT);

  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };
  useEffect(() => {
    setValidation(
      validateObject(
        Object.assign({
          startDateTime,
          endDateTime,
          file,
          storyTypeId,
        }),
        SCHEME,
      ),
    );
  }, [storyData, file, startDateTime, endDateTime, storyTypeId]);

  useEffect(() => {
    if (props.isOpen) {
      setTypeOptions(getTypeOptions(langId));
    }
    if (!props.isOpen) {
      initialize();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (typeOptions?.length) {
      setStoryTypeId(parseInt(typeOptions[0]?.key, 10));
    }
  }, [typeOptions]);
  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <div className="modal">
        <Form
          onSubmit={() =>
            props.saveChanges({
              id: id,
              storyData: storyData,
              isTabBarDark: isTabBarDark,
              link: link,
              backgroundColorId: colorId,
              file: file,
              storyTypeId: parseInt(storyTypeId, 10),
              startDateTime: startDateTime.toISOString(),
              endDateTime: endDateTime.toISOString(),
            })
          }
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.stories.qk}</span>
                    </div>
                    <DateTimePicker
                      className="container_fullwidth_half_first"
                      fullWidth
                      disablePast={true}
                      size="large"
                      value={startDateTime}
                      onChange={setStartDateTime}
                      inputValue={
                        startDateTime ? getBeautyDateTimeString(new Date(startDateTime)) : ''
                      }
                      maxDate={endDateTime ? endDateTime : null}
                      label={STRINGS.screens.stories.ql}
                      inputType={
                        !isFocus.startDateTime && !validation?.data?.startDateTime?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('startDateTime', true)}
                      onBlur={() => changeFocus('startDateTime', false)}
                    />
                    <DateTimePicker
                      className="container_fullwidth_half_first"
                      fullWidth
                      disablePast={true}
                      size="large"
                      value={endDateTime}
                      minDate={startDateTime || new Date()}
                      onChange={setEndDateTime}
                      inputValue={endDateTime ? getBeautyDateTimeString(new Date(endDateTime)) : ''}
                      label={STRINGS.screens.stories.qz}
                      inputType={
                        !isFocus.endDateTime && !validation?.data?.endDateTime?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('endDateTime', true)}
                      onBlur={() => changeFocus('endDateTime', false)}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.stories.qx}</span>
                    </div>
                    <Select
                      className="container_fullwidth_one_first"
                      label={`${STRINGS.screens.stories.chooseType} *`}
                      size="large"
                      options={getTypeOptions(1)}
                      value={storyTypeId}
                      onChange={setStoryTypeId}
                      inputType={
                        !isFocus.storyTypeId && !validation?.data?.storyTypeId?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('storyTypeId', true)}
                      onBlur={() => changeFocus('storyTypeId', false)}
                    />
                    {getTypeOptions(1).length === 0 && (
                      <div className="error">
                        <span>{STRINGS.screens.stories.noTypesWarnings}</span>
                      </div>
                    )}
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.stories.link}</span>
                    </div>
                    <Input
                      maxLength={255}
                      className="container_fullwidth_one_first"
                      inputType="default"
                      value={link}
                      onChange={setLink}
                      label={STRINGS.screens.stories.enterLink}
                    />
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.img} *</span>
                    </div>
                    <CustomDropzone maxFiles={1} files={file} onChange={setFile} />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.stories.chooseColor}</span>
                    </div>
                    <ColorSelector
                      colors={props.backgroundColors}
                      value={colorId}
                      setSelectedColor={setColorId}
                    />
                  </div>
                  <div className="container_fullwidth" style={{ marginTop: '-10px' }}>
                    <div className="subtitle">
                      <span>{STRINGS.screens.stories.darkMode}</span>
                      <Switch checked={isTabBarDark} onChange={setIsTabBarDark} />
                    </div>
                  </div>
                </>
              )}
              {step === 3 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.langs}</span>
                  </div>
                  <div className="langs">
                    {props.languages?.map((language) => (
                      <Button
                        label={language.name}
                        value={language.id}
                        size="medium"
                        className="lang"
                        type={selectedLanguage === language.id ? 'primary' : 'default'}
                        data-active={selectedLanguage === language.id}
                        onClick={(e) => {
                          setSelectedLanguage(language.id);
                          e.preventDefault();
                        }}
                      >
                        <span className="day-label">{language.name}</span>
                      </Button>
                    ))}
                  </div>
                  <Input
                    className="container_fullwidth_one_first"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...storyData];
                      temp[selectedLanguage - 1].title = val;
                      setStoryData(temp);
                    }}
                    maxLength={30}
                    inputType={'default'}
                    value={storyData[selectedLanguage - 1].title}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.title[selectedLanguage - 1]}`}
                    onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                  />
                  <Input
                    className="container_fullwidth_one"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...storyData];
                      temp[selectedLanguage - 1].subtitle = val;
                      setStoryData(temp);
                    }}
                    inputType={'default'}
                    value={storyData[selectedLanguage - 1].subtitle}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.subtitle[selectedLanguage - 1]}`}
                    onFocus={() => changeFocus(`subtitle${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`subtitle${selectedLanguage - 1}`, false)}
                  />
                  <Input
                    fullWidth
                    className="container_fullwidth_one"
                    value={storyData[selectedLanguage - 1].buttonText}
                    onChange={(val) => {
                      let temp = [...storyData];
                      temp[selectedLanguage - 1].buttonText = val;
                      setStoryData(temp);
                    }}
                    inputType="default"
                    variant="outlined"
                    label={STRINGS.screens.modal.buttonText[selectedLanguage - 1]}
                    onFocus={() => changeFocus(`buttonText${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`buttonText${selectedLanguage - 1}`, false)}
                  />
                </div>
              )}
            </div>
          )}
          <Separator indent={80} />
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                disabled={!validation.isValid}
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                type={!validation.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

/**
 * Properties
 */

StoryFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
StoryFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default StoryFields;

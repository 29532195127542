/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

/**
 * Users Service functionality
 * @getCouponData
 * @updateCoupon
 * @addCoupon
 */
export default class PointsStoreService {
  static #API_ENDPOINTS = {
    priceLevels: 'price-levels',
    store: 'points-store',
  };

  static API_SETTINGS = {
    size: 30,
  };
  static async getPriceLevels() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.priceLevels}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Price levels data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.priceLevels}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getLevelById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.priceLevels}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Price levels data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.priceLevels}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getProducts(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.store}/crm?langId=${props.langId}&priceLevelId=${
          props.priceLevelId
        }`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Price levels data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.store}/mobile?langId=${
          props.langId
        }&page=${props.page}&size=${props.size}&priceLevelId=${props.priceLevelId}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addProduct(props) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.store}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(props.data),
      });

      debug.success('Store data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.store}`, error);
      throw await error.response?.json();
    }
  }
  static async patchProduct(props) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.store}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(props.data),
      });

      debug.success('Store data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.store}`, error);
      throw await error.response?.json();
    }
  }
  static async addLevel(props) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.priceLevels}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(props.data),
      });

      debug.success('Store data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.priceLevels}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async editLevel(data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.priceLevels}/${data?.id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data?.data),
      });

      debug.success('Store data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.priceLevels}/${data?.id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteLevel(id) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.priceLevels}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Level data retrieval result', request);

      return request.json();
    } catch (error) {
      if (error.status) {
        debug.error(
          `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.priceLevels}/${id}`,
          error,
        );
        throw await error.response?.json();
      } else {
        return { status: 204 };
      }
    }
  }
  static async deleteProduct(data) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.store}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Store data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.store}`, error);
      throw await error.response?.json();
    }
  }
}

import MenuService from '../../services/menu';
import { useState, useEffect } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

const useRestaurantMenu = ({ langId, size = 15, existing = false, restaurantId, type, search = '', categoryId}) => {

  const [page, setPage] = useState(1);

  const [isLastPage, setIsLastPage] = useState(false);

  const [menu, setMenu] = useState([]);

  const [loading, setLoading] = useState({ firstLoading: true, opacityLoading: false });

  const [hardReload, setHardReload] = useState(false);

  const callHardReload = () => {
    setHardReload(true);
  };

  const generateQuery = () => {
    if (type === 'products' || categoryId !== 0) {
      return { langId, page: page, size, existing, search, categoryId };
    }
    return { langId, page: page, size, existing, search };
  };

  const getMenu = async () => {
    setHardReload(false);
    if (hardReload) {
      setPage(1);
      setLoading({ ...loading, firstLoading: true });
    } else {
      setLoading({ ...loading, opacityLoading: true });
    }

    const { isLast, data } = await MenuService.getRestaurantMenu(type, restaurantId, generateQuery());

    setIsLastPage(isLast);

    if (page === 0 || page === 1) {
      setMenu(data);
    } else {
      setMenu((old) => [...old, ...data]);
    }

    setLoading({ firstLoading: false, opacityLoading: false });
  };

  useEffect(() => {
    setPage(1);
    getMenu();
  }, [search, categoryId]);

  const bottomScrollListenerRef = useBottomScrollListener(() => {
    if (!loading.firstLoading && !loading.opacityLoading && !isLastPage) { setPage(page === 0 ? 2 : page + 1); }
  }, {triggerOnNoScroll: true});

  useEffect(() => {
    if (page === 0) {
      setPage(1);
    } else {
      getMenu();
    }
  }, [page]);

  useEffect(() => {
    if (hardReload) {
      getMenu();
    }
  }, [hardReload]);

  return { menu, isLastPage, loading, callHardReload, bottomScrollListenerRef };
};

export default useRestaurantMenu;

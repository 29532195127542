/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';

import View from './view';
import STRINGS from '../../../localization';
import { Toast } from '../../ui-kit';
import { useSelector } from 'react-redux';
import CategoriesService from '../../../services/categories';
import { configureStatus } from '../../../main/utils/configure-status';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { languages } = useSelector((store) => store.stuff);
  const [showModal, setShowModal] = useState('');
  const [selectedTab, setSelectedTab] = useState('');
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState();

  const editCategory = async (f) => {
    let dataToSend = {};
    if (Number.parseInt(f.initialEntity.serialNumber, 10) !== f.serialNumber) {
      dataToSend = { ...dataToSend, serialNumber: f.serialNumber };
    }
    if (f.initialEntity.key !== f.key) {
      dataToSend = { ...dataToSend, key: f.key };
    }
    if (f.initialEntity.categoryData !== f.categoryData) {
      dataToSend = { ...dataToSend, categoryData: f.categoryData };
    }
    if (f.initialEntity.type !== f.type) {
      dataToSend = { ...dataToSend, type: f.type };
    }
    try {
      await CategoriesService.updateСategory(selectedCategory?.id, dataToSend);
      getCategories({ langId: langId, key: selectedTab });
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
  };

  const addCategory = async (f) => {
    delete f.initialEntity;
    try {
      let resp = await CategoriesService.addCategory(f);
      resp.title = f.categoryData?.find((el) => el.languageId === langId)?.title;
      setCategories((old) => [resp, ...old]);
      Toast.success(STRINGS.notify.qy);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
  };

  const handleDeleteCategory = async () => {
    try {
      setShowConfirm('');
      setCategories(categories.filter((el) => el.id !== selectedCategory.id));
      await CategoriesService.deleteCategory(selectedCategory.id);
    } catch (err) {
      if (err.status) {
        Toast.error(STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.qu);
      }
    }
  };

  const getCategories = async (params) => {
    setSkeletVisible(true);
    try {
      const resp = await CategoriesService.getCategories(params);
      setCategories(resp);
      setSkeletVisible(false);
    } catch (err) {
      if (err.status) {
        Toast.error(STRINGS.notify[err?.status] || STRINGS.notify.failure);
      }
    }
  };

  const changeStatus = async (elem) => {
    try {
      await CategoriesService.updateStatus(elem?.id, configureStatus(elem));
      let temp = [...categories];
      temp.find((el) => el?.id === elem?.id).status = configureStatus(elem).status;
      setCategories(temp);
      Toast.success(STRINGS.notify.status);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  useEffect(() => {
    getCategories({ langId: langId, key: selectedTab });
  }, [selectedTab]);

  return (
    <View
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      categories={categories}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      handleDeleteCategory={handleDeleteCategory}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      addCategory={addCategory}
      editCategory={editCategory}
      skeletVisible={skeletVisible}
      changeStatus={changeStatus}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default Container;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import WebkassaChecksView from './webkassa-cheks-view';
import {
  init,
  listenNewWebkassaChecks,
  listenNewWebkassaChecksWithAdd,
} from '../../../services/socket';
import WebkassaService from '../../../services/webkassa';
import { WEBKASSA_CHECK_STATUSES } from '../../../constants/webkassa-check-statuses';

const WebkassaChecksContainer = () => {
  const user = useSelector((store) => store.user);

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rebuild, setRebuild] = useState(false);
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);
  const [checkStatusId, setCheckStatusId] = useState(WEBKASSA_CHECK_STATUSES[0].id);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [check, setCheck] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');

  const [page, setPage] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [checksData, setChecksData] = useState([]);

  useEffect(() => {
    getData();
  }, [rebuild]);

  // useEffect(async () => {
  //   const deliveries = await WebkassaService.getWebkassaChecks();
  // }, []);

  useEffect(() => {
    const restaurantId = user.data.restaurantId;

    const socketConnect = init(restaurantId);

    listenNewWebkassaChecks(socketConnect, restaurantId);

    listenNewWebkassaChecksWithAdd(socketConnect, setChecksData, true, restaurantId);

    return () => socketConnect.disconnect();
  }, []);

  const scrollRef = useBottomScrollListener(() => {
    if (!isLast) {
      getData();
    }
  });

  const clearFilters = () => {
    setRebuild(!rebuild);

    setPage(0);
    setChecksData([]);
    setDateFrom('');
    setDateTo('');
    setCheckNumber('');
    setTimeFrom('');
    setTimeTo('');
  };

  const checkFilters = () => {
    if (
      dateFrom === '' &&
      dateTo === '' &&
      checkNumber === '' &&
      timeFrom === '' &&
      timeTo === ''
    ) {
      return false;
    }
    return true;
  };

  const getData = async () => {
    try {
      if (page === 0) {
        setIsLoadingGlobal(true);
      }

      const fromDateArg = generateDate(dateFrom, timeFrom);
      const toDateArg = generateDate(dateTo, timeTo);

      const res = await WebkassaService.getWebkassaChecks(
        page + 1,
        20,
        checkStatusId,
        checkNumber,
        fromDateArg !== '' ? fromDateArg.toISOString() : '',
        toDateArg !== '' ? toDateArg.toISOString() : '',
        user.data.restaurantId,
      );

      setChecksData((old) => [...old, ...res.data]);
      setIsLast(res.isLast);
      setPage((prev) => prev + 1);

      if (page === 0) {
        setIsLoadingGlobal(false);
      }

      setTimeout(() => {
        console.log('checksData: ', checksData);
      }, 3000);
    } catch (err) {}
  };

  const generateDate = (date, time) => {
    let arg = date;

    if (date !== '' && time !== '') {
      arg.setHours(time.getHours());
      arg.setMinutes(time.getMinutes());
    } else if (time !== '') {
      arg = time;
    }

    return arg;
  };

  const handleSort = async () => {
    setChecksData([]);
    setPage(0);
    setRebuild(!rebuild);
    setOpenModal(false);
  };

  const handleReturn = async (checkProp) => {
    try {
      const res = await WebkassaService.returnMoneyOperation(checkProp.id);
    } catch (error) {}
  };

  const handleFiscalization = async (checkProp) => {
    try {
      const res = await WebkassaService.fiscalizeCheck(checkProp.id);
    } catch (error) {}
  };

  return (
    <WebkassaChecksView
      // Props
      open={open}
      check={check}
      checkNumber={checkNumber}
      dateFrom={dateFrom}
      dateTo={dateTo}
      timeFrom={timeFrom}
      timeTo={timeTo}
      checkStatus={checkStatusId}
      webkassaCheckStatuses={WEBKASSA_CHECK_STATUSES}
      checksData={checksData}
      scrollRef={scrollRef}
      isLoadingGlobal={isLoadingGlobal}
      openModal={openModal}
      // Methods
      checkFilters={checkFilters}
      clearFilters={clearFilters}
      handleSort={handleSort}
      setOpenModal={setOpenModal}
      setOpen={setOpen}
      setCheckNumber={setCheckNumber}
      setCheck={setCheck}
      setDateFrom={setDateFrom}
      setDateTo={setDateTo}
      setTimeFrom={setTimeFrom}
      setTimeTo={setTimeTo}
      setCheckStatus={setCheckStatusId}
      handleFiscalization={handleFiscalization}
      handleReturn={handleReturn}
    />
  );
};

export default WebkassaChecksContainer;

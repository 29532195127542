import React from 'react';
import './styles.scss';

const ColorRound = ({color}) => (
  <div
    className="color-round"
    style={{
      backgroundColor: color,
    }} />
);

ColorRound.defaultProps = {
  color: '#ffffff',
};

export default ColorRound;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Input } from '..';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const SingleSelect = (props) => {
  const [isActive, setIsActive] = useState(false);
  const handleClickAwayEvent = () => {
    setIsActive(false);
  };
  return (
    <div className={`single-select_search ${props.className}`}>
      <ClickAwayListener onClickAway={handleClickAwayEvent}>
        <div>
          <Input
            className="single-select_search_input"
            fullWidth
            value={props.dataQuery}
            onChange={(e) => {
              props.setDataQuery(e);
              setIsActive(true);
            }}
            onClick={() => setIsActive(!isActive)}
            variant="outlined"
            label={props.label}
            rightIcon={props.rightIcon}
            size={props.size}
            maxLength={40}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            inputType={props.inputType}
          />
          {props.dataQuery && !!props.data.length && isActive && (
            <div className="suggestions">
              {props.data?.map((el) => (
                <div
                  onClick={() => {
                    props.setDataQuery(el.value);
                    props.setResult(el.id);
                    setIsActive(false);
                  }}
                >
                  <span>{el.value}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

SingleSelect.propTypes = {
  multiple: PropTypes.bool,
  selected: PropTypes.array,
  label: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  title: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

SingleSelect.defaultProps = {
  multiple: true,
  selectedProducts: [],
  label: '',
  size: 'large',
  options: [],
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  title: null,
  className: '',
};

export default SingleSelect;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import convertPxToRem from '../../styles/rem';

/**
 * @component Skeleton
 * @example
 * <Skeleton width={40} value="some text"/>
 */
const Skeleton = (props) => {
  return (
    <div
      style={{
        maxWidth: typeof props.width === 'number' ? convertPxToRem(props.width) : props.width,
      }}
      className={`skeleton ${props.className}`}
    >
      {props.children ? props.children : <span>{props.value}</span>}
    </div>
  );
};

/**
 * Properties
 */

Skeleton.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  className: PropTypes.string,
};
Skeleton.defaultProps = {
  width: 'auto',
  value: '&nbsp;',
  className: '',
};

export default Skeleton;

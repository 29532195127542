import React, { useState } from 'react';
import STRINGS from '../../localization';

import { Button, Form, Input, Paper } from '../../main/ui-kit';
import './styles.scss';

const AuthorizationView = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(true);
  const paperClassRender = () => {
    let str = 'auth-box';
    if (props.loading) {
      str += ' disabled';
    }
    return str;
  };

  const languageClassRender = (sn) => {
    let str = 'language-basic';
    if (sn) {
      str += ` ${sn}`;
    }
    if (props.selectedLanguage.iso3 === sn) {
      str += ' lang-selected';
    }
    return str;
  };
  return (
    <div id="--auth-root">
      <div className="container">
        <Paper className={paperClassRender()}>
          <div className="auth">
            <h1>{STRINGS.screens.authorization.auth}</h1>
            <div>
              <Form onChange={props.onChange} onSubmit={(data) => props.authorization(data)}>
                <p>{STRINGS.screens.authorization.fill}</p>
                <div className="fileds">
                  <Input
                    label={STRINGS.screens.authorization.email}
                    inputType={
                      !props.emailFocus || props.validation.data?.email?.isValid
                        ? 'default'
                        : 'danger'
                    }
                    size="large"
                    name="email"
                    maxLength={120}
                    defaultValue={props.email}
                    value={props.email}
                    onChange={props.setEmail}
                    isValid={props.validation.data?.emil?.isValid}
                    focus={props.emailFocus}
                    onBlur={() => props.setEmailFocus(true)}
                  />
                  <Input
                    autocomplete="new-password"
                    label={STRINGS.screens.authorization.password}
                    inputType={
                      !props.passwordFocus || props.validation.data?.password?.isValid
                        ? 'default'
                        : 'danger'
                    }
                    size="large"
                    type={passwordVisible ? 'password' : 'visibility'}
                    rightIcon={
                      passwordVisible && props.password
                        ? 'crossed-visibility'
                        : props.password && 'visibility'
                    }
                    iconClick={() => setPasswordVisible((prev) => !prev)}
                    name="password"
                    value={props.password}
                    onChange={props.setPassword}
                    maxLength={32}
                    defaultValue={props.password}
                    isValid={props.validation.data?.password?.isValid}
                    focus={props.passwordFocus}
                    onBlur={() => props.setPasswordFocus(true)}
                  />
                </div>
                <div className="enter">
                  <Button
                    label={STRINGS.screens.authorization.go}
                    size="large"
                    type={props.loading || !props.validation.isValid ? 'disabled' : 'primary'}
                    loading={props.loading}
                    typeButton="submit"
                    disabled={props.loading || !props.validation.isValid}
                  />
                </div>
              </Form>
            </div>
          </div>
        </Paper>
        <div id="--auth-languages">
          {props.languages?.map((item, index) => (
            <button
              key={`language-${index}`}
              className="language-box"
              onClick={() => props.handleChangeLanguage(item)}
            >
              <div className={languageClassRender(item.iso3)} />
            </button>
          ))}
        </div>
      </div>
      <div className="slider-root">
        <div ref={props.imagesBox} className="slider">
          <figure />
          <figure />
          <figure />
        </div>
        <div className="gradient">
          <aside>
            <h1>{STRINGS.screens.authorization.welcome}</h1>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default AuthorizationView;

/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import { Loader } from '../../ui-kit';
import { AggregatorButton } from '../../components';

import './styles.scss';

const AggregatorsView = (props) => {
  return (
    <>
      {props.isLoading ? (
        <div className="loader">
          <Loader color="dark" />
        </div>
      ) : (
        <>
          <p className="title">{STRINGS.components.aggregatorButton.title}</p>
          {/* <div className="content">
            <AggregatorButton
              opened={props.openedRestaurant}
              openRestaurant={() => {}}
              callbackOpen={props.openEverything}
              callbackClose={props.closeEverything}
            />
          </div>
          <div className="hl" /> */}
          {props.buttonsData.map((el) => (
            <AggregatorButton
              key={el.title}
              opened={el.opened}
              openRestaurant={() => {}}
              title={el.title}
              callbackOpen={el.callbackOpen}
              callbackClose={el.callbackClose}
            />
          ))}
        </>
      )}
    </>
  );
};

export default AggregatorsView;

import STRINGS from '../localization';

export const days = [
    { label: STRINGS.general.days.monday, key: 1 },
    { label: STRINGS.general.days.tuesday, key: 2 },
    { label: STRINGS.general.days.wednesday, key: 3 },
    { label: STRINGS.general.days.thursday, key: 4 },
    { label: STRINGS.general.days.friday, key: 5 },
    { label: STRINGS.general.days.saturday, key: 6 },
    { label: STRINGS.general.days.sunday, key: 7 },
  ];

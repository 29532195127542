/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';
import fpPromise from '@fingerprintjs/fingerprintjs';
import { store } from '../../store/index';
import CurrentUserThunks from '../../store/current-user/thunks';

/**
 * Auth Service functionality
 * @requestUserToken
 * @setAuthToken
 * @getAuthToken
 * @getRefreshToken
 * @removeAuthTokens
 */
export default class AuthService {
  static #LOCAL_STORAGE_KEYS = {
    token: '@authentication/auth_token',
    refreshToken: '@authentication/auth_refresh_token',
    fingerprint: '@authentication/fingerprint',
  };

  static #API_ENDPOINTS = {
    login: 'auth/login',
    refresh: 'auth/crm/refresh',
  };
  static getFingerprint() {
    const fingerprint = fpPromise
      .load()
      .then((fp) => fp.get())
      .then((result) => {
        return result.visitorId;
      });
    return fingerprint;
  }
  static async requestUserToken(email, password) {
    const fingerprint = fpPromise
      .load()
      .then((fp) => fp.get())
      .then((result) => {
        return result.visitorId;
      });
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.login}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          fingerprint: await this.getFingerprint(),
        }),
      });
      debug.success('Authentication request result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      const requestData = await request.json();

      if (requestData) {
        return requestData;
      }

      //return Promise.reject('No token');
    } catch (globalError) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.login}`,
        globalError,
      );
      throw await globalError.response?.json();
    }
  }
  static async requestUserRefreshToken() {
    const token = await AuthService.getRefreshToken();
    try {
      const request = await fetch(`${URL}${this.#API_ENDPOINTS.refresh}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          fingerprint: await this.getFingerprint(),
        }),
      });

      debug.success('Authentication request result', request);

      //if refreshToken also died, we make force logout from account
      if (request.status === 403) {
        store.dispatch(CurrentUserThunks.logout());
        return;
      }
      return request.json();
    } catch (globalError) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.login}`,
        globalError,
      );
      throw await globalError.response?.json();
    }
  }

  static async setAuthToken({ token, refreshToken }) {
    try {
      if (!!token) {
        window.localStorage.setItem(this.#LOCAL_STORAGE_KEYS.token, token);
      }
      if (!!refreshToken) {
        window.localStorage.setItem(this.#LOCAL_STORAGE_KEYS.refreshToken, refreshToken);
      }
      return Promise.resolve(true);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Get auth token from localstorage
   * @return {Promise<false|String>}
   */
  static getAuthToken() {
    try {
      const token = window.localStorage.getItem(this.#LOCAL_STORAGE_KEYS.token);
      if (token) {
        return token;
      }
      return Promise.reject(false);
    } catch (error) {
      throw error;
    }
  }
  static getRefreshToken() {
    try {
      const token = window.localStorage.getItem(this.#LOCAL_STORAGE_KEYS.refreshToken);
      if (token) {
        return token;
      }
      return Promise.reject(false);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Remove tokens from localstorage
   * @return {Promise<unknown>}
   */
  static async removeAuthTokens() {
    try {
      window.localStorage.removeItem(this.#LOCAL_STORAGE_KEYS.token);
      return Promise.resolve(true);
    } catch (error) {
      throw error;
    }
  }
}

/**
 * @prettier
 */

import React from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import NOPHOTO from '../../../assets/nophoto.png';
import { Paper } from '../../ui-kit';
import '../styles.scss';
import PunchcardFields from '../../components/modals/punchcard-fields';
import Skelet from './skelet';
import { URL } from '../../../services/config';
import { getBeautyDateString } from '../../utils/datetime';
import ColorRound from '../../components/ColorRound';

const View = (props) => {
  const { punchcard } = props;
  return (
    <>
      <PunchcardFields
        languages={props.languages}
        langId={props.langId}
        backgroundColors={props.backgroundColors}
        getFileById={props.getFileById}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.punchcards.editPunchcard}
        loadedPunchcard={props.punchcard}
        getPunchcardById={props.getPunchcardById}
        loading={props.loading}
        setLoading={props.setLoading}
        saveChanges={props.saveChanges}
      />
      <Head
        label={props.history?.location?.state?.title}
        button={{
          size: 'medium',
          icon: false,
          label: STRINGS.screens.punchcards.editPunchcard,
          onClick: () => props.setShowModal('edit'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.components.sidebar.punchcards,
            link: '/punchcards',
          },
          {
            label: props.punchcardId,
          },
        ]}
        status={props.history?.location?.state?.status}
      />

      {props.skeletVisible || Object.keys(punchcard).length === 0 ? (
        <Skelet />
      ) : (
        <Paper>
          <div className="punchcard-view-container">
            <div>
              <div className="block">
                <div className="picture">
                  {punchcard?.fileId ? (
                    <img className="image" src={`${URL}files/${punchcard?.fileId}`} />
                  ) : (
                    <img src={NOPHOTO} />
                  )}
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.discounts.productsList}:</span>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>{STRINGS.screens.punchcards.qq}</th>
                      <th>{STRINGS.screens.punchcards.qw}</th>
                      <th>{STRINGS.screens.punchcards.mission}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props?.products.map((el) => (
                      <tr>
                        <td>{el.title || STRINGS.screens.modal.noTitle}</td>
                        <td style={{ paddingLeft: '15%' }}>
                          <div className="number">{el.amount}</div>
                        </td>
                        <td>
                          {el.mission === 'GOAL'
                            ? STRINGS.screens.punchcards.buy
                            : STRINGS.screens.punchcards.achieve}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.modal.general}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.punchcards.createdAt}:</span>
                  <span>
                    {getBeautyDateString(new Date(punchcard?.createdAt)) ||
                      STRINGS.screens.modal.noData}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.punchcards.rewardIn}:</span>
                  <span>
                    {punchcard?.rewardType === 'ITEMS'
                      ? STRINGS.screens.punchcards.items
                      : STRINGS.screens.punchcards.points}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.punchcards.points}:</span>
                  <span>{punchcard?.rewardInPoints || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.punchcards.numberOfPurchases}:</span>
                  <span>{punchcard?.numberOfPurchases || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.punchcards.backgroundColor}:</span>
                  <ColorRound
                    color={
                      props.backgroundColors.find((el) => el.id === punchcard.backgroundColorId)
                        ?.hex
                    }
                  />
                </div>
                {punchcard.rewardType === 'ITEMS' && (
                  <div className="row">
                    <span>{STRINGS.screens.punchcards.couponBackgroundColor}:</span>
                    <ColorRound
                      color={
                        props.backgroundColors.find(
                          (el) => el.id === punchcard.couponBackgroundColorId,
                        )?.hex
                      }
                    />
                  </div>
                )}
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.punchcards.description}</span>
                </div>
                <div className="data">
                  <span>
                    {punchcard?.punchCardData?.find((el) => el.languageId === props.langId)
                      .description || STRINGS.screens.modal.noData}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};
export default View;

/**
 * @prettier
 */

import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Form } from '../../../../main/ui-kit';
import { Icon } from '../../../../ui-kit';
import { CustomButton, Combo, Product } from '../../../ui-kit';
import { Receipt } from '../..';
import STRINGS from '../../../../localization';
import { setOrder } from '../../../../store/orders/orders';

import colors from '../../../styles/colors';

import './styles.scss';

const Description = (props) => {
  const componentRef = useRef();
  const dispatch = useDispatch();

  const ordersCancelled = useSelector((store) => store.orders.cancelledOrders);

  const [openReceipt, setOpenReceipt] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const elData = props.readyOrders ? props.order?.orderBody : props.order;
  const metadataPath = props.readyOrders ? elData?.orderMetadata : elData?.orderMetadata;

  useEffect(() => {
    const el = ordersCancelled.find((el) => el === props.order.orderPatternId);
    if (el) {
      setIsCanceled(true);
    }
  }, []);

  return (
    <div className="rest-drawer" open={props.isOpen} onClose={props.onClose} title={props.title}>
      <div className="description">
        <Form
          className="form-description"
          onSubmit={() => {
            props.saveChanges({
              backgroundColorId: 2,
            });
          }}
        >
          <div className="header">
            <div>
              <span className="title">
                {STRINGS.components.descriptionRestaurantModal.numberOrder}
                {props.order?.orderPatternId}
              </span>
              <button className="button-none" onClick={() => setOpenReceipt(!openReceipt)}>
                <Icon name="printer" width={20} height={20} color={colors.color_brown._0} />
              </button>
              {/* {props.possibleCancel && !isCanceled && (
                <button
                  className="button-none cancel"
                  onClick={() => {
                    setIsCanceled(true);
                    dispatch(setOrder(props.order.orderPatternId));
                    props.handlerCancel(props.order);
                  }}
                >
                  <Icon name="cancel" width={20} height={20} color={colors.color_red._0} />
                </button>
              )} */}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => props.setOpen(false)}>
              <Icon name="cross" width={20} height={20} />
            </div>
          </div>
          {openReceipt ? (
            <Receipt
              order={elData}
              refReceipt={componentRef}
              archive={props.archive}
              status={props.order?.status}
            />
          ) : (
            <div className={!props.readyOrders ? 'body' : 'body without'}>
              <div className="body-container">
                <div className="subtitle-wrapper">
                  <span className="subtitle">
                    {STRINGS.components.descriptionRestaurantModal.detailOrderTitle}
                  </span>
                </div>
                <div className="sub-container">
                  <div className="sub-subtitle-wrapper">
                    <span className="sub-subtitle">
                      {STRINGS.components.descriptionRestaurantModal.timeOrderTitle}
                    </span>
                  </div>
                  <span className="sub-time">
                    {metadataPath?.receiptDateTime &&
                      new Date(metadataPath?.receiptDateTime).toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="body-container">
                <div className="subtitle-wrapper">
                  <span className="subtitle">
                    {STRINGS.components.descriptionRestaurantModal.contentOrderTitle}
                  </span>
                </div>
                {elData?.combo.length > 0 &&
                  elData?.combo?.map((combo) => <Combo key={combo.posId} combo={combo} />)}
                {elData?.products?.map((el) => (
                  <Product key={el.orderPatternId} el={el} />
                ))}
              </div>
              <div className="summary-container">
                <div className="price-wrapper">
                  <span className="price">
                    {STRINGS.components.descriptionRestaurantModal.total}
                  </span>
                </div>
                <div className="price-wrapper">
                  <span className="price">
                    {metadataPath?.price} {STRINGS.components.descriptionRestaurantModal.currency}
                  </span>
                </div>
              </div>
            </div>
          )}
          {openReceipt && (
            <ReactToPrint
              trigger={() => (
                <div className="footer-restaurant">
                  <CustomButton
                    label="Распечатать"
                    size="large"
                    typeButton="submit"
                    className="button-print"
                  />
                </div>
              )}
              content={() => componentRef.current}
            />
          )}
          {props.buttonVisible && !openReceipt ? (
            !isCanceled ? (
              <div className="footer-restaurant">
                <CustomButton
                  label="Заказ готов к выдаче"
                  disabled={props.isButtonLoading}
                  onClick={() => props.handlerSend(props.order, 'ready')}
                  size="large"
                  typeButton="submit"
                />
              </div>
            ) : (
              <div className="footer-restaurant">
                <CustomButton
                  label="Заказ отменяется"
                  disabled={isCanceled}
                  onClick={() => null}
                  size="large"
                  typeButton="submit"
                  className="button-cancel"
                />
              </div>
            )
          ) : null}
        </Form>
      </div>
    </div>
  );
};

/**
 * Properties
 */

Description.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
Description.defaultProps = {
  languages: [],
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default Description;

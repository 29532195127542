/**
 * @prettier
 */

import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@mui/material/styles';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Input } from '..';
import './styles.scss';
import colors from '../../styles/colors';

const localeMap = {
  ru: ruLocale,
};
class RuLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'LLLL', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const localeUtilsMap = {
  ru: RuLocalizedUtils,
};

const localeFormatMap = {
  ru: 'd MMM yyyy hh:mm',
};

const defaultMaterialTheme = createTheme({});

/**
 * @component InputDate
 * @example
 * <InputDate
 *   label="Дата регистрации"
 *   inputType="default"
 *   size="large"
 *   maxDate={new Date()}
 *   inputValue={getBeautyDateString(lastContact)}
 *   value={new Date()}
 *   onChange={() => {}}
 * />
 */
const InputDateTime = (props) => {
  const handleDateChange = (date) => {
    props.onChange(date);
  };

  return (
    <MuiPickersUtilsProvider utils={localeUtilsMap.ru} locale={localeMap.ru}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <DateTimePicker
          value={props.value}
          onChange={handleDateChange}
          disabled={props.disabled}
          disablePast={props.disablePast}
          disableFuture={props.disableFuture}
          maxDate={props.maxDate}
          minDate={props.minDate}
          autoOk
          okLabel={null}
          className={props.className}
          cancelLabel={null}
          ampm={false}
          format={localeFormatMap.ru}
          TextFieldComponent={({ onClick }) => {
            return (
              <div className={`date-picker ${props.className}`} onClick={onClick}>
                {props.component ? (
                  props.component()
                ) : (
                  <Input
                    value={props.inputValue}
                    readOnly
                    placeholder={props.placeholder}
                    size={props.size}
                    label={props.label}
                    inputType={props.inputType}
                    disabled={props.disabled}
                    rightIcon="calendar"
                    iconWidth={props.iconWidth}
                    iconHeight={props.iconHeight}
                    name={props.name}
                  />
                )}
              </div>
            );
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

/**
 * Properties
 */

InputDateTime.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  maxDate: PropTypes.object,
  className: PropTypes.string,
  minDate: PropTypes.object,
  label: PropTypes.string,
  inputType: PropTypes.oneOf(['default', 'primary', 'secondary', 'disabled']),
  inputValue: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  component: PropTypes.any,
};
InputDateTime.defaultProps = {
  inputValue: '',
  className: '',
  value: null,
  onChange: () => {},
  disabled: false,
  disablePast: null,
  disableFuture: null,
  maxDate: undefined,
  minDate: undefined,
  label: '',
  size: 'medium',
  inputType: 'default',
  component: null,
};

export default InputDateTime;

/**
 * @prettier
 */

import React from 'react';

import { Paper } from '../../ui-kit';
import { Skeleton } from '../../ui-kit';
import './styles.scss';

const Skelet = () => (
  <Paper>
    <div className="combo-view-container">
      <div>
        <div className="block">
          <Skeleton className="picture" />
        </div>
        <div className="block">
          <div className="row row-space-between">
            <Skeleton width="fit-content">
              <span>******************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>******</span>
            </Skeleton>
          </div>
        </div>
        <div className="block">
          <div className="row">
            <Skeleton width="fit-content">
              <span>******************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>***</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>******************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>***********</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>******************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>***********</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>******************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>***********</span>
            </Skeleton>
          </div>
        </div>
        <div className="block">
          <div className="subtitle">
            <Skeleton width="fit-content">
              <span>***************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>******************************</span>
            </Skeleton>
          </div>
        </div>
      </div>
      <div>
        <div className="block content-table">
          <div className="subtitle">
            <Skeleton width="fit-content">
              <span>***************</span>
            </Skeleton>
          </div>
          <div className="row row-space-between">
            <div className="row">
              <Skeleton width="fit-content">
                <span>******</span>
              </Skeleton>
              <Skeleton width="fit-content">
                <span>******</span>
              </Skeleton>
            </div>
            <div className="row">
              <Skeleton width="fit-content">
                <span>*********</span>
              </Skeleton>
              <Skeleton width="fit-content">
                <span>*********</span>
              </Skeleton>
            </div>
          </div>
          <table className="skelet-table">
            <thead>
              <th />
              <th>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </th>
              <th>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </th>
              <th>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </th>
              <th>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </th>
            </thead>
            <tbody>
              {[1, 2, 3, 4].map((el) => (
                <tr>
                  <td>
                    <Skeleton width="fit-content">
                      <span>*************</span>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="fit-content">
                      <span>****</span>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="fit-content">
                      <span>****</span>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="fit-content">
                      <span>****</span>
                    </Skeleton>
                  </td>
                  <td>
                    <Skeleton width="fit-content">
                      <span>****</span>
                    </Skeleton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Paper>
);

export default Skelet;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * @component Form
 * @example
 * <Form
 *  className="some class"
 *  onSubmit={() => {}}
 *  onChange={() => {}}
 * />
 */
const Form = (props) => {
  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = Object.fromEntries(new FormData(form));
    props.onSubmit(formData);
  };

  const onChange = (e) => {
    const inputsData = Object.fromEntries(new FormData(e.currentTarget));
    props.onChange(inputsData);
  };

  return (
    <form
      className={props.className}
      onChange={onChange}
      onSubmit={onSubmit}
      autoComplete={props.autocomplete}
    >
      {props.children}
    </form>
  );
};

/**
 * Properties
 */

Form.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  autocomplete: PropTypes.string,
};
Form.defaultProps = {
  className: null,
  onSubmit: () => {},
  onChange: () => {},
  autocomplete: 'off',
};

export default Form;

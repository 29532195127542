/**
 * @prettier
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import STRINGS from '../../../localization';

import { Icon, Confirm, Table, Tooltip } from '../../ui-kit';
import { RolesFields } from '../../components/modals';
import RolesSkelet from './skelet';
import { Head } from '../../components';

import './styles.scss';

const RolesView = (props) => (
  <>
    <RolesFields
      isOpen={props.showModal === 'edit'}
      onClose={() => props.setShowModal('')}
      title={props.roles?.[props.selectedRole]?.title}
      selectedRoleTitle={props.roles?.[props.selectedRole]?.title}
      setNameRole={props.setNameRole}
      checkCheckbox={props.checkCheckbox}
      checkedCheckboxes={props.checkedCheckboxes}
      saveChanges={props.saveChanges}
      nameRole={props.nameRole}
      isLoadingButtonSave={props.isLoadingButtonSave}
      loading={props.loading}
      setLoading={props.setLoading}
    />
    <RolesFields
      isOpen={props.showModal === 'addRole'}
      onClose={() => props.setShowModal('')}
      title={STRINGS.screens.roles.addRole}
      setNameRole={props.setNameRole}
      checkCheckbox={props.checkCheckbox}
      checkedCheckboxes={props.checkedCheckboxes}
      saveChanges={props.addNewRole}
      nameRole={props.nameRole}
      isLoadingButtonSave={props.isLoadingButtonSave}
      loading={props.loading}
      setLoading={props.setLoading}
    />
    <Confirm
      visible={props.showConfirm === 'delete'}
      title={STRINGS.screens.roles.modal.confirmAction}
      onClose={() => props.setShowConfirm('')}
      subtitle={STRINGS.screens.roles.modal.question}
      buttons={[
        {
          type: 'danger',
          label: STRINGS.screens.roles.modal.delete,
          onClick: () => props.handleDeleteRole(),
          loading: props.isLoadingButtonDelete,
        },
        {
          type: 'default',
          label: STRINGS.screens.roles.modal.leave,
          outline: true,
          onClick: () => {
            props.setShowConfirm('');
            props.setSelectedRole('');
          },
        },
      ]}
    />

    <Head
      label={STRINGS.screens.roles.title}
      button={{
        icon: { name: 'plus', side: 'left' },
        label: STRINGS.screens.roles.addRole,
        size: 'medium',
        onClick: () => props.setShowModal('addRole'),
      }}
      breadCrumbs={[
        {
          label: STRINGS.components.sidebar.manage,
          link: '/',
        },
        {
          label: STRINGS.screens.roles.title,
        },
      ]}
    />

    <div className="summary_table" disabled={props.disabledContent}>
      {props.skeletVisible ? (
        <RolesSkelet />
      ) : (
        <Table
          head={() => (
            <tr className="summary_table_heading">
              <th>{STRINGS.screens.roles.role}</th>
              <th>{STRINGS.screens.roles.accesses}</th>
              <th />
            </tr>
          )}
        >
          <>
            {Object?.keys(props.roles)?.map((role) => (
              <Fragment key={role}>
                <tr className={'roles_table_body_row roles_table_body'}>
                  <td onClick={() => props.handleOpenRole(role)} className="roles_table_role">
                    {props.roles[role]?.title}
                  </td>
                  <td onClick={() => props.handleOpenRole(role)}>
                    <p className="roles_table_permissions_text">
                      {Object?.keys(props.roles[role]?.rolePermissions)?.join(', ')}
                    </p>
                  </td>
                  <td className="roles_table_edit">
                    <div className="roles_table_edit_wrapper">
                      <Tooltip title={STRINGS.components.tooltips.delete}>
                        <div
                          className="roles_table_iconWrapper"
                          onClick={() => {
                            props.setShowConfirm('delete');
                            props.setSelectedRole(role);
                          }}
                        >
                          <Icon name="trash-outline" width={24} height={24} />
                        </div>
                      </Tooltip>
                      <Tooltip title={STRINGS.components.tooltips.edit}>
                        <div
                          className="roles_table_iconWrapper"
                          onClick={() => props.openEditModal(role)}
                        >
                          <Icon name="edit" height={24} width={24} />
                        </div>
                      </Tooltip>

                      <Tooltip title={STRINGS.components.tooltips.open}>
                        <div
                          className="roles_table_arrow roles_table_iconWrapper"
                          data-active={props.openRoles.includes(role)}
                          onClick={() => props.handleOpenRole(role)}
                        >
                          <Icon name="arrow-down" height={24} width={24} />
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
                {props.openRoles.includes(role) && (
                  <tr className="roles_table_body roles_table_body_row">
                    <td colSpan="3" className="roles_table_permissions_td">
                      <div className="roles_table_permissions">
                        {Object?.keys(props.roles[role]?.rolePermissions)?.map(
                          (rolePermissions) => (
                            <div key={rolePermissions} className="roles_table_permissions_item">
                              <p className="roles_table_permissions_title">{rolePermissions}</p>
                              {Object?.keys(
                                props.roles[role]?.rolePermissions?.[rolePermissions],
                              )?.map((access) => (
                                <p
                                  key={access}
                                  className="roles_card_access"
                                >{`${props.roles[role]?.rolePermissions?.[rolePermissions]?.[access]}`}</p>
                              ))}
                            </div>
                          ),
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </>
        </Table>
      )}
    </div>
  </>
);

RolesView.propTypes = {
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  setShowConfirm: PropTypes.func,
  roles: PropTypes.object,
  selectedRole: PropTypes.string,
  setSelectedRole: PropTypes.func,
  handleDeleteRole: PropTypes.func,
  nameRole: PropTypes.string,
  setNameRole: PropTypes.func,
  saveChanges: PropTypes.func,
  checkedCheckboxes: PropTypes.array,
  setCheckedCheckboxes: PropTypes.func,
  checkCheckbox: PropTypes.func,
  addNewRole: PropTypes.func,
  onCloseModal: PropTypes.func,
  openEditModal: PropTypes.func,
  disabledContent: PropTypes.bool,
  isLoadingButtonDelete: PropTypes.bool,
  isLoadingButtonSave: PropTypes.bool,
};

RolesView.defaultProps = {
  showModal: '',
  setShowModal: () => {},
  setShowConfirm: () => {},
  roles: null,
  selectedRole: '',
  setSelectedRole: () => {},
  handleDeleteRole: () => {},
  nameRole: '',
  setNameRole: () => {},
  saveChanges: () => {},
  checkedCheckboxes: [],
  setCheckedCheckboxes: () => {},
  checkCheckbox: () => {},
  addNewRole: () => {},
  onCloseModal: () => {},
  openEditModal: () => {},
  disabledContent: false,
  isLoadingButtonDelete: false,
  isLoadingButtonSave: false,
};

export default RolesView;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Loader from '../loader';
import { validateObject } from '../../../utils/input-functions';
import {
  Button,
  Form,
  Drawer,
  CustomDropzone,
  DefinitionsModificators,
  Select,
} from '../../../ui-kit';
import { useSelector } from 'react-redux';

import STRINGS from '../../../../localization';

const ModificatorsFields = (props) => {
  const [step, setStep] = useState(1);
  const [unmodifiedModificator, setUnmodifiedModificator] = useState({});
  const [definitionId, setDefinitionId] = useState(1);
  const [definition, setDefinition] = useState({});
  const [posId, setPosId] = useState();
  const { modificatorCategories } = useSelector((store) => store.stuff);
  const [categoryId, setCategoryId] = useState(0);
  const [validation, setValidation] = useState({});
  const [file, setFile] = useState(null);
  const [glovoFile, setGlovoFile] = useState(null);
  const [yandexFile, setYandexFile] = useState(null);
  const [chocofoodFile, setChocofoodFile] = useState(null);
  const [woltFile, setWoltFile] = useState(null);

  const prepareFile = async (fileId) => {
    if (fileId) {
      let resp = await props.getFileById(fileId);
      resp = Object.assign(resp, { preview: URL.createObjectURL(resp), id: fileId });
      return resp;
    } else {
      return null;
    }
  };

  const mapData = async (mod) => {
    try {
      setUnmodifiedModificator(mod);
      setPosId(mod?.posId);
      setCategoryId(mod?.categoryId);
      setDefinitionId(mod?.definitionId);
      setFile(await prepareFile(mod?.fileId));
      setGlovoFile(await prepareFile(mod?.glovoFileId));
      setYandexFile(await prepareFile(mod?.yandexFileId));
      setChocofoodFile(await prepareFile(mod?.chocofoodFileId));
      setWoltFile(await prepareFile(mod?.woltFileId));
    } catch (error) {}
  };

  const initialize = () => {
    if (!props.loadedModificator) {
      setStep(1);
      setGlovoFile(null);
      setYandexFile(null);
      setChocofoodFile(null);
      setWoltFile(null);
      setUnmodifiedModificator({});
      setPosId();
      setCategoryId(0);
      setDefinitionId(1);
      setFile(null);
    } else {
      setStep(1);
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (props.isOpen === true) {
        props.setLoading(true);
        await mapData(await props.getModificatorById(props.selectedModificator?.id));
        props.setLoading(false);
      }
    };
    getData();
  }, [props?.selectedModificator]);

  useEffect(() => {
    if (props?.loadedModificator) {
      mapData(props?.loadedModificator);
    }
  }, [props?.loadedModificator]);

  useEffect(() => {
    setValidation(
      validateObject(
        Object.assign({
          definitionId,
        }),
        SCHEME,
      ),
    );
  }, [definitionId]);

  useEffect(() => {
    if (!props.isOpen) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };
  const maxStep = 2;
  const SCHEME = {
    definitionId: (value) => Number.isInteger(value),
  };
  const [isFocus, setIsFocus] = useState({
    definitionId: true,
  });
  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => props.onClose()}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges(posId, {
              unmodifiedModificator,
              file,
              glovoFile,
              yandexFile,
              chocofoodFile,
              woltFile,
              definitionId: definitionId,
              definition,
              categoryId: Number.parseInt(categoryId),
            });
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.goods.definition} *</span>
                    </div>
                    <DefinitionsModificators
                      className="container_fullwidth_one_first"
                      label={`${STRINGS.screens.goods.chooseDefinition}`}
                      definitionId={definitionId}
                      setDefinitionId={setDefinitionId}
                      definition={definition}
                      setDefinition={setDefinition}
                      inputType={
                        !isFocus?.definitionId && !validation?.data?.definitionId?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('definitionId', true)}
                      onBlur={() => changeFocus('definitionId', false)}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.goods.category} *</span>
                    </div>
                    <Select
                      className="container_fullwidth_one_first"
                      size="large"
                      label={STRINGS.screens.goods.qf}
                      value={categoryId}
                      onChange={setCategoryId}
                      options={modificatorCategories.map((el) => ({
                        label: el?.title,
                        key: el?.id,
                      }))}
                    />
                  </div>
                </>
              )}
              {step === 2 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.imgs}</span>
                  </div>
                  <div className="img-container">
                    <CustomDropzone maxFiles={1} files={file} onChange={setFile} />
                    <span>{STRINGS.screens.combo.qq}</span>
                  </div>
                  <div className="img-container">
                    <CustomDropzone maxFiles={1} files={glovoFile} onChange={setGlovoFile} />
                    <span>{STRINGS.screens.combo.qw}</span>
                  </div>
                  <div className="img-container">
                    <CustomDropzone maxFiles={1} files={yandexFile} onChange={setYandexFile} />
                    <span>{STRINGS.screens.combo.qe}</span>
                  </div>
                  <div className="img-container">
                    <CustomDropzone
                      maxFiles={1}
                      files={chocofoodFile}
                      onChange={setChocofoodFile}
                    />
                    <span>{STRINGS.screens.combo.qr}</span>
                  </div>
                  <div className="img-container">
                    <CustomDropzone maxFiles={1} files={woltFile} onChange={setWoltFile} />
                    <span>{STRINGS.screens.combo.qt}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                //disabled={!validation.isValid}
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                //type={!validation.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

ModificatorsFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
ModificatorsFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default ModificatorsFields;

import React, { useState } from 'react';
import MapsService from '../../../services/ymaps';
import { Map, Placemark } from 'react-yandex-maps';
import STRINGS from '../../../localization';
import { usePosition } from 'use-position';

import './styles.scss';

import { Input, Modal, Button } from '../../ui-kit';

const YandexMap = (props) => {
  const [addressInput, setAddressInput] = useState();

  const [resultArray, setResultArray] = useState([]);

  const [coordinates, setCoordinates] = useState('');

  const { latitude, longitude } = usePosition();

  const handleCoordinates = () => {
    if (coordinates.length > 0) {
      return coordinates.split(' ');
    }
    if (props.coordinates.length > 0) {
      return props.coordinates.split(' ').reverse();
    }

    if (!!latitude && !!longitude) {
      return [latitude, longitude];
    }
    return [48, 68];
  };

  const getResults = async (geocode) => {
    if (geocode) {
      const data = geocode ? await MapsService.getResults(geocode, props.kind) : [];
      setResultArray(data);
    } else {
      setResultArray([]);
    }
  };

  const onSubmit = () => {
    props.setCoordinates(coordinates);
    props.onClose();
  };

  const handleMapResult = (el) => {
    setCoordinates(el?.GeoObject?.Point?.pos.split(' ').reverse().join(' '));
    setAddressInput(
      el.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted
        .split(',')
        .slice(1)
        .join(',')
        .trim(),
    );
    setResultArray([]);
  };


  const prepareSuggestionTitle = (el) => {
    if (props.fullAddress) {
      return el.GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted
        .split(',')
        .slice(1)
        .join(',')
        .trim();
    } else {
      return el.GeoObject.name;
    }
  };

  return (
    <Modal
      maxWidth="md"
      title={props.title}
      open={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      onBlur={props.onBlur}
    >
      <div className="search-input">
        <Input
          fullWidth
          value={addressInput}
          placeholder={STRINGS.screens.cities.mapPlaceholder}
          size="large"
          inputType="default"
          rightIcon="search"
          maxLength={40}
          onChange={(e) => {
            setAddressInput(e);
            getResults(e);
          }}
        />

        {resultArray?.response?.GeoObjectCollection?.featureMember?.length > 0 && (
          <div className="search-input-suggestions">
            {resultArray?.response?.GeoObjectCollection?.featureMember.map((el) => (
              <div onClick={() => handleMapResult(el)}>
                <span>{prepareSuggestionTitle(el)}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="map">
        <Map
          width="100%"
          height="500px"
          state={{
            center: handleCoordinates(),
            zoom: 9,
          }}
        >

          <Placemark
            modules={['geoObject.addon.balloon']}
            geometry={handleCoordinates()}
          />
        </Map>
      </div>
      <div className="footer">
        <Button
          label={STRINGS.screens.modal.choose}
          onClick={onSubmit}
          loading={!coordinates.length > 0}
          draggable={true}
        />
      </div>
    </Modal>
  );
};


export default YandexMap;

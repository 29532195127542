/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

/**
 * Current user Service functionality
 * @get
 */
export default class CurrentUserService {
  static #API_ENDPOINTS = {
    user: 'users/staff',
  };

  static async get() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.user}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('The result of obtaining information about the current user', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.user}`, error);
      throw await error.response?.json();
    }
  }
}

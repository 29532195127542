/**
 * @prettier
 */

import React, { Fragment } from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import { Table } from '../../ui-kit';
import Skelet from './skelet';
import { RestaurantsStatus } from './restaurantsStatus';

import './styles.scss';

export const StatusRestaurantsView = (props) => {
  return (
    <>
      <Head
        label={STRINGS.screens.restaurants.statusTitle}
        button={false}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.restaurants.statusTitle,
          },
        ]}
      />
      <div className="summary_table">
        {props.skeletVisible ? (
          <Skelet />
        ) : (
          <Table
            loading={props.bottomLoading}
            head={() => (
              <tr className="summary_table_heading">
                <th>{STRINGS.screens.restaurants.name}</th>
                <th>{STRINGS.screens.restaurants.address}</th>
                <th>{STRINGS.screens.restaurants.city}</th>
                <th>{STRINGS.screens.restaurants.statusGlovo}</th>
                <th>{STRINGS.screens.restaurants.statusChoko}</th>
                <th>{STRINGS.screens.restaurants.statusYandex}</th>
                <th>{STRINGS.screens.restaurants.statusWolt}</th>
              </tr>
            )}
          >
            {props.restaurants?.data
              ?.map((el) => Object.assign({ statusLoading: false }, el))
              ?.map((restaurant) => (
                <>
                  <Fragment key={restaurant.id}>
                    <tr
                      className="summary_table_body"
                      onClick={async (event) => {
                        props.history.push(`restaurants/${restaurant.id}/`, {
                          title: restaurant?.title,
                          status: restaurant?.status,
                        });
                        event.stopPropagation();
                        await props.getRestaurantById(restaurant.id);
                      }}
                    >
                      <td>{restaurant?.title || STRINGS.screens.modal.noTitle}</td>
                      <td>{restaurant?.address}</td>
                      <td>{restaurant?.city}</td>
                      <RestaurantsStatus restaurantId={restaurant.id} />
                    </tr>
                  </Fragment>
                </>
              ))}
          </Table>
        )}
      </div>
    </>
  );
};

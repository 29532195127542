/** @format */
import './styles.css';
import { useMenu } from './data/use-menu.hook';
import Categories from './components/tabs';
import { useHistory, useParams } from 'react-router-dom';

const DataSendMenuPreview = () => {
  const history = useHistory();
  const Menu = useMenu(history.location.state.data);

  return (
    <div className="DataSendMenuPreview">
      <Categories menu={Menu}></Categories>
    </div>
  );
};
export default DataSendMenuPreview;

/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { Input } from '../index';
import Mask from 'react-input-mask';

/**
 * @component InputMask
 * @example
 * <InputMask />
 */
const InputMask = (props) => {
  const [isFocus, setIsFocus] = useState(false);
  const [input, setInput] = useState(null);

  useEffect(() => {
    if (isFocus) {
      input?.focus();
    }
  }, [isFocus]);

  return (
    <div onClick={() => setIsFocus(true)} className="input_mask">
      <Mask
        className="input_mask_mask"
        value={props.value}
        onChange={props.onChange}
        mask={props.mask}
        onBlur={() => setIsFocus(false)}
        inputRef={setInput}
      />
      <Input
        onBlur={() => setIsFocus(false)}
        label={props.label}
        className={`${props.className} ${isFocus ? 'input_mask_input_focus' : 'input_mask_input'}`}
        value={props.value}
      />
    </div>
  );
};

/**
 * Properties
 */

InputMask.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  mask: PropTypes.string,
  onChange: PropTypes.func,
};
InputMask.defaultProps = {
  value: '',
  className: '',
  label: '',
  mask: '',
  onChange: () => {},
};

export default InputMask;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { Icon } from '../../../ui-kit';
import colors from '../../styles/colors';

/**
 * @component Select
 * @example
 * <Select
 *  label="Обращение"
 *  onChange={() => {}}
 *  selectType="primary"
 *  size="large"
 *  options={[{label: 'Mr', key: 1}]}
 *  isValid
 * />
 */
const Select = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  const renderClassName = () => {
    let str = 'select-container-restaurant';
    if (props.selectType) {
      str += ` ${selectConfigList[props.selectType].basicClass}`;
    }
    if (props.size) {
      str += ` ${sizes[props.size]?.className}`;
    }
    if (props.className) {
      str += ` ${props.className}`;
    }
    return str;
  };

  return (
    <div className={renderClassName()} disabled={props.disabled}>
      <select
        value={props.value}
        disabled={props.disabled}
        onChange={handleChange}
        className="select-option"
      >
        <option value="null" disabled defaultValue>
          {props.label}
        </option>
        {props.options?.map((item, i) => (
          <option value={item.id} id={`select-option-${item.id}`}>
            {item.name}
          </option>
        ))}
      </select>
      <div className="icons">
        <div className="arrow">
          <Icon name="arrow-down" width={24} height={24} color={colors.color_brown._0} />
        </div>
        {props.isValid && (
          <div className="is-valid">
            <Icon name="check" width={14} height={14} color={colors.color_green._0} />
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Styles
 */

const selectConfigList = {
  default: {
    basicClass: 'default',
  },
  primary: {
    basicClass: 'primary',
  },
  secondary: {
    basicClass: 'secondary',
  },
};

/**
 * SIZES
 */

const sizes = {
  small: {
    className: 'small',
  },
  medium: {
    className: 'medium',
  },
  large: {
    className: 'large',
  },
};

/**
 * Properties
 */

Select.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  selectType: PropTypes.oneOf(['default', 'primary', 'secondary', 'disabled']),
  options: PropTypes.array,
  isValid: PropTypes.bool,
  className: PropTypes.string,
};
Select.defaultProps = {
  label: '',
  onChange: () => {},
  disabled: false,
  value: 'null',
  size: 'medium',
  selectType: 'primary',
  options: [],
  isValid: false,
  className: '',
};

export default Select;

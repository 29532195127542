/**
 * @prettier
 */
import {
  aggregatorsSuccess,
  aggregatorsFailure,
  aggregatorsLoadingStart,
  aggregatorsLoadingEnd,
} from './aggregatorsReducer';

import GlovoService from '../../services/glovo';
import ChocofoodService from '../../services/chocofood';
import WoltService from '../../services/wolt';
import YandexService from '../../services/yandex';

/**
 * Redux thunks for current user.
 */
class AggregatorsThunks {
  static glovoClosing(restaurantId) {
    return async (dispatch) => {
      try {
        const glovoTime = await GlovoService.getClosing(restaurantId);

        await dispatch(
          aggregatorsSuccess({
            glovo: {
              isOpen: !glovoTime.until,
              closedUpdatedAt: glovoTime.closedUpdatedAt,
            },
          }),
        );
      } catch (err) {
        dispatch(aggregatorsFailure({ glovo: { isOpen: false } }));
      }
    };
  }

  static chocofoodClosing(restaurantId) {
    return async (dispatch) => {
      try {
        const chocoTime = await ChocofoodService.getClosing(restaurantId);

        await dispatch(
          aggregatorsSuccess({
            chocofood: {
              isOpen: chocoTime.is_active,
              closedUpdatedAt: chocoTime.closedUpdatedAt,
            },
          }),
        );
      } catch (err) {
        dispatch(aggregatorsFailure({ chocofood: { isOpen: false } }));
      }
    };
  }

  static woltClosing(restaurantId) {
    return async (dispatch) => {
      try {
        const woltClosing = await WoltService.getClosing(restaurantId);

        await dispatch(
          aggregatorsSuccess({
            wolt: {
              isOpen: !woltClosing.isClosed,
              closedUpdatedAt: woltClosing.closedUpdatedAt,
            },
          }),
        );
      } catch (err) {
        dispatch(aggregatorsFailure({ wolt: { isOpen: false } }));
      }
    };
  }

  static yandexClosing(restaurantId) {
    return async (dispatch) => {
      try {
        const yandexClosing = await YandexService.getClosingCrm(restaurantId);

        await dispatch(
          aggregatorsSuccess({
            yandex: {
              isOpen: yandexClosing.open,
              closedUpdatedAt: yandexClosing.closedUpdatedAt,
            },
          }),
        );
      } catch (err) {
        dispatch(aggregatorsFailure({ yandex: { isOpen: false } }));
      }
    };
  }

  static aggregatorsClosing(restaurantId) {
    return async (dispatch) => {
      dispatch(aggregatorsLoadingStart());
      await dispatch(this.glovoClosing(restaurantId));
      await dispatch(this.chocofoodClosing(restaurantId));
      await dispatch(this.woltClosing(restaurantId));
      await dispatch(this.yandexClosing(restaurantId));
      dispatch(aggregatorsLoadingEnd());
    };
  }
}

export default AggregatorsThunks;

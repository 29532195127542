import React from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import {Confirm, Paper} from '../../ui-kit';
import './styles.scss';
import Loader from './loader';
import { generatePrice } from '../../utils/generate-price';
import { getBeautyDateTimeString } from '../../utils/datetime';
import Block from '../../components/Block';
import Row from '../../components/Row';
import OrderItemsTable from './OrderItemsTable';

const OrderView = (props) => {
  const { selectedOrder, loading } = props;

  return (
    <>
        <Confirm
            visible={props.showConfirm === 'cancel'}
            title={STRINGS.screens.roles.modal.confirmAction}
            onClose={() => props.setShowConfirm('')}
            subtitle={STRINGS.screens.orders.delete}
            subtitleBold={props.selectedOrder?.orderPatternId}
            buttons={[
                {
                    label: STRINGS.screens.roles.modal.delete,
                    onClick: () => {props.setShowConfirm(''); props.cancelOrder();},
                },
                {
                    label: STRINGS.screens.roles.modal.leave,
                    outline: true,
                    onClick: () => {
                        props.setShowConfirm('');
                    },
                },
            ]}
        />
      <Head
        label={
          selectedOrder?.orderPatternId
            ? `${STRINGS.screens.orders.order} №${selectedOrder.orderPatternId}`
            : STRINGS.screens.orders.order
        }
        status={selectedOrder?.status}
        button={selectedOrder?.status === 'created' ? {
          size: 'medium',
          label: STRINGS.screens.orders.cancel,
          onClick: () => props.setShowConfirm('cancel'),
        } : null}
        breadCrumbs={[
          ...props.history.location.state?.breadCrumbs,
          { label: `${STRINGS.screens.orders.order} №${selectedOrder?.orderPatternId || ''}` },
        ]}
      />
      {loading ? (
        <Loader />
      ) : (
        <Paper>
          <div className="order-view-container">
            <div>
              <Block title={STRINGS.screens.orders.clientInfo}>
                <Row title={STRINGS.screens.orders.qe} data={STRINGS.screens.modal.noData} />
              </Block>
              <Block title={STRINGS.screens.orders.orderInfo}>
                <Row title={STRINGS.screens.orders.qr} data={props.history.location.state?.restaurantId} />
                <Row title={STRINGS.screens.orders.orderPatternId} data={selectedOrder.orderPatternId} />
                <Row title={STRINGS.screens.orders.aggregatorId} data={selectedOrder.orderId} />
                <Row title={STRINGS.screens.orders.qt} data={selectedOrder?.orderBody?.orderMetadata?.amount} />
                <Row title={STRINGS.screens.orders.aggregator} data={props.translateAggregator(selectedOrder?.aggregator?.key)?.title || 'mobile'} />
              </Block>
            </div>
            <div>
              <Block title={STRINGS.screens.orders.details}>
                <Row title={STRINGS.screens.orders.sum} data={generatePrice(selectedOrder?.orderBody?.orderMetadata?.price)} />
                <Row title={STRINGS.screens.orders.qy} data={selectedOrder?.receiptDateTime
                      && getBeautyDateTimeString(new Date(selectedOrder?.receiptDateTime))} />
              </Block>
              {selectedOrder?.orderBody !== null && (
                <Block title={STRINGS.screens.orders.includes}>
                  <OrderItemsTable order={selectedOrder}/>
                </Block>
              )}
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};

export default OrderView;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';

import DiscountsView from './discounts-view';
import { Toast } from '../../ui-kit';
import STRINGS from '../../../localization';
import DiscountsService from '../../../services/discounts';
import MainService from '../../../services/main';
import GoodsService from '../../../services/goods';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { configureStatus } from '../../../main/utils/configure-status';

const DiscountsContainer = () => {
  const [mindboxPromotions, setMindboxPromotions] = useState([]);
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { languages } = useSelector((store) => store.stuff);
  const history = useHistory();
  const search = useSelector((store) => store.search.value);
  const [page, setPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [bottomLoading, setBottomLoading] = useState(false);
  const [showModal, setShowModal] = useState('');
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [discounts, setDiscounts] = useState();
  const [selectedDiscount, setSelectedDiscount] = useState();
  const [sort, setSort] = useState({ type: '', sort: '' });
  const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

  const importPromotions = async () => {
    setImportLoading(true);
    setSkeletVisible(true);
    try {
      await DiscountsService.importStocks();
      setPage(1);
      getDiscounts({
        page: 1,
        size: 22,
        langId: langId,
        search: search,
        sort: sorts,
      });
      Toast.success(STRINGS.notify.qi);
    } catch (error) {
      Toast.success(STRINGS.notify.qi);
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setSkeletVisible(false);
    setImportLoading(false);
  };
  const getFileId = async (file) => {
    try {
      const resp = await MainService.postFile(file);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const mapFilesId = async (arr) => {
    let filesId = [];
    for (const element of arr) {
      if (element?.file?.id) {
        filesId.push(element?.file?.id);
      } else {
        const id = await getFileId(element?.file);
        filesId.push(id?.id);
      }
    }
    return await arr.map((el, index) => ({
      languageId: el.languageId,
      title: el.title,
      description: el.description,
      warning: el.warning,
      subtitle: el.subtitle,
      fileId: filesId[index],
    }));
  };

  const saveChanges = async (f) => {
    setLoading(true);
    const promotionData = await mapFilesId(f.promotionData);
    const dataToSend = {
      startDate: f.startDate,
      expirationDate: f.expirationDate,
      promotionData: promotionData,
    };
    try {
      await DiscountsService.updateDiscount({ data: dataToSend, id: f.id });
      Toast.success(STRINGS.notify.success);
      await getDiscounts({
        page: 1,
        size: 22,
        langId: langId,
        search: search,
        sort: sorts,
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setTimeout(() => setLoading(false), 100);
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await DiscountsService.getDiscounts({
        page: page + 1,
        langId: langId,
        size: 22,
        search: search,
        sort: sorts,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setDiscounts({
        isLast: resp.isLast,
        data: [...discounts?.data, ...resp?.data],
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  const getDiscounts = async (props) => {
    setSkeletVisible(true);
    try {
      const resp = await DiscountsService.getDiscounts(props);
      setDiscounts({
        isLast: resp.isLast,
        data: [...resp?.data],
      });
      setSkeletVisible(false);
    } catch (error) {
      setSkeletVisible(false);
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getDiscountById = async (id) => {
    try {
      const resp = await DiscountsService.getDiscountById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getProductsAutocomplete = async (searchString) => {
    const resp = await GoodsService.getAutocomplete(searchString);
    return resp;
  };

  const handleDeleteDiscount = async () => {
    try {
      setShowConfirm('');
      setDiscounts((old) => ({
        isLast: old.isLast,
        data: old.data?.filter((el) => el.id !== selectedDiscount?.id),
      }));
      await DiscountsService.deleteDiscount({ id: selectedDiscount?.id });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.qe);
      }
    }
  };
  const changeStatus = async (elem) => {
    try {
      await DiscountsService.updateStatus(elem.id, configureStatus(elem));
      let temp = [...discounts.data];
      temp.find((el) => el.id === elem.id).status = configureStatus(elem).status;
      setDiscounts((old) => ({ isLast: old.isLast, data: temp }));
      Toast.success(STRINGS.notify.status);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const applySort = (mode) => {
    setSort((prevState) => {
      return {
        sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
        type: mode,
      };
    });
  };

  useEffect(() => {
    setPage(1);
    getDiscounts({
      page: 1,
      size: 22,
      langId: langId,
      search: search,
      sort: sorts,
    });
  }, [search, sorts]);

  return (
    <DiscountsView
      importLoading={importLoading}
      importPromotions={importPromotions}
      mindboxPromotions={mindboxPromotions}
      loading={loading}
      setLoading={setLoading}
      langId={langId}
      history={history}
      sort={sort}
      applySort={applySort}
      changeStatus={changeStatus}
      selectedDiscount={selectedDiscount}
      setSelectedDiscount={setSelectedDiscount}
      getDiscountById={getDiscountById}
      getFileById={getFileById}
      getProductsAutocomplete={getProductsAutocomplete}
      discounts={discounts}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      saveChanges={saveChanges}
      handleDeleteDiscount={handleDeleteDiscount}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      // addDiscount={addDiscount}
      skeletVisible={skeletVisible}
    />
  );
};

export default DiscountsContainer;

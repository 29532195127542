/**
 * @prettier
 */

import React, { Fragment } from 'react';

import { Icon, Table, Skeleton, Button } from '../../ui-kit';
import NOPHOTO from '../../../assets/nophoto.png';

import STRINGS from '../../../localization';

import './styles.scss';

const Skelet = () => (
  <div className="summary_table">
    <Table
      head={() => (
        <tr className="summary_table_heading">
          <th>
            <Skeleton width="fit-content">
              <span>********************</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>*************</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>*************</span>
            </Skeleton>
          </th>
          <th />
        </tr>
      )}
    >
      <>
        {new Array(30).fill(null).map((item, index) => (
          <Fragment key={index.toString()}>
            <tr className="summary_table_body">
              <td>
                <Skeleton width="fit-content">
                  <span>*****************</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>************************</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>************************</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>***************</span>
                </Skeleton>
              </td>
            </tr>
          </Fragment>
        ))}
      </>
    </Table>
  </div>
);

export default Skelet;

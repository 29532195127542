/**
 * @prettier
 */

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import './styles.scss';

import { Modal } from '../../../ui-kit';

import LocalizationActions from '../../../../store/localization/actions';

import STRINGS from '../../../../localization';

/**
 * @component SelectLanguage
 * @example
 * <SelectLanguage isOpen={false} close={() => {}} />
 */
const SelectLanguage = (props) => {
  const dispatch = useDispatch();
  const { languages } = useSelector((store) => store.stuff);
  const handleChangeLanguage = (languageCode) => {
    dispatch(LocalizationActions.setLanguage(languageCode));
    props.close();
  };

  return (
    <Modal
      maxWidth="xs"
      title={STRINGS.components.header.langSelection}
      open={props.isOpen}
      onClose={props.close}
    >
      <div id="--language-selector">
        {languages?.map((item, index) => (
          <button key={index} onClick={() => handleChangeLanguage(item)}>
            <div className={item?.iso3 || 'none'} />
            <span>{item?.name}</span>
          </button>
        ))}
      </div>
    </Modal>
  );
};

/**
 * Properties
 */

SelectLanguage.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};
SelectLanguage.defaultProps = {
  isOpen: false,
  close: () => {},
};

export default SelectLanguage;

/**
 * @prettier
 */

const initialState = {
  value: '',
};

const APPLY_SEARCH = 'APPLY_SEARCH';

const reducer = (state = initialState, action) => {
  switch (action?.type) {
    case APPLY_SEARCH:
      return {
        value: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

export const setSearch = (payload) => {
  return {
    type: APPLY_SEARCH,
    payload: payload,
  };
};
export const hi = (payload) => {
  return {
    type: APPLY_SEARCH,
    payload: payload,
  };
};

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

/**
 * Users Service functionality
 * @getUserData
 * @updateUser
 * @addUser
 */
export default class UsersService {
  static #API_ENDPOINTS = {
    users: 'users/mobile/',
    managers: 'users/staff/',
    user: 'users/',
    addUser: 'auth/phone',
    addManager: 'auth/register',
    updateManager: 'users/staff',
  };

  static async getUsers(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.users}?page=${props.page}&search=${props.search}&langId=${
          props.langId
        }&size=${props.size}${props.sort}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('User data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.users}`, error);
      throw await error.response?.json();
    }
  }
  static async getUserById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.users}${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('User data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.users}${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getManagers(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.managers}?page=${props.page}&search=${props.search}&langId=${
          props.langId
        }&size=${props.size}${props.sort}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('User data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.users}`, error);
      throw await error.response?.json();
    }
  }
  static async updateUser(id, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.users}${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('User data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.users}${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.users}${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(status),
      });

      debug.success('User data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.users}${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addUser(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.addUser}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('User data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.addUser}`, error);
      throw await error.response?.json();
    }
  }
  static async addManager(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.addManager}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Manager data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.addManager}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateManager(props) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.updateManager}/${props.id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(props.data),
      });

      debug.success('Manager data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.updateManager}/${props.id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteManager(props) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.updateManager}/${props.id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Manager data retrieval result', request);

      // return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.updateManager}/${
          props.id
        }`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

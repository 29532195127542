/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import './styles.scss';

import { Icon } from '../../../ui-kit';

const CustomDropzone = (props) => {
  const handleDelete = () => {
    props.onChange(null);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
    multiple: props.multiple,
    maxFiles: props.maxFiles,
    onDrop: (acceptedFiles) => {
      props.onChange(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }),
      );
    },
  });

  return (
    <>
      <div
        disabled={props.disabled}
        className={`cust-uploading-files ${props.dropzoneType} ${props.className}`}
        style={props.files === null ? {} : { backgroundColor: 'white' }}
      >
        {props.files === null ? (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className="container-description">
              <div className="span-color-gray">
                <Icon name="plus" width={16} height={16} color="black" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="buttons-container" onClick={() => handleDelete(props.files.path)}>
              {!props.isDelete && !props.files.other ? (
                <Icon
                  className="button delete"
                  name="cross"
                  width={14}
                  height={14}
                  color={'black'}
                />
              ) : null}
            </div>
            <div className="file-information-container">
              <div className="file-container">
                <div className="image-container">
                  <div
                    style={
                      props.files.type === 'image/jpeg' || 'image/png' || 'image/svg+xml'
                        ? { backgroundImage: `url(${props.files?.preview})` }
                        : {}
                    }
                    className="image"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

/**
 * Properties
 */

CustomDropzone.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  files: PropTypes.object,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  skelet: PropTypes.bool,
};
CustomDropzone.defaultProps = {
  className: '',
  title: null,
  multiple: true,
  maxFiles: 3,
  onChange: () => {},
  files: {},
  accept: 'image/*',
  disabled: false,
  skelet: false,
};

export default CustomDropzone;

const ru = {
  general: {
    days: {
      monday: 'Понедельник',
      tuesday: 'Вторник',
      wednesday: 'Среда',
      thursday: 'Четверг',
      friday: 'Пятница',
      saturday: 'Суббота',
      sunday: 'Воскресенье',
      clockRound: 'Круглосуточно',
    },
    service: {
      hall: 'Зал',
      drive: 'Драйв',
      delivery: 'Доставка',
    },
    shortNameOfDays: {
      monday: 'Пн',
      tuesday: 'Вт',
      wednesday: 'Ср',
      thursday: 'Чт',
      friday: 'Пт',
      saturday: 'Сб',
      sunday: 'Вс',
    },
    gender: {
      male: 'Мужской',
      female: 'Женский',
    },
    allergens: {
      gluten: 'Глютен',
      sesame: 'Кунжут',
      milk: 'Молоко',
      egg: 'Яйцо',
    },
    roles: {
      admin: 'Администратор',
      manager: 'Менеджер',
      contentManager: 'Контент-менеджер',
      qq: 'Ограниченный доступ к ресторану',
      qw: 'Пароль должен содержать не менее',
      qe: 'знаков.',
    },
  },
  notify: {
    importSuccess: 'Импорт завершен',
    syncSuccess: 'Синхронизация меню завершена',
    success: 'Изменения сохранены',
    failure: 'Произошла ошибка',
    status: 'Статус изменен',
    statusFailure: 'Произошла ошибка при смене статуса',
    listFailure: 'Произошла ошибка при подгрузке таблицы',
    filefailure: 'Произошла ошибка при загрузке файла на сервер',
    imageFailure: 'Произошла ошибка при загрузке изображения',
    fileRequired: 'Необходимо загрузить изображение',
    serialMatch: 'Такой порядковый номер уже существует',
    400: 'Произошла ошибка со статусом 400.',
    401: 'Ошибка авторизации 401. Попробуйте перезайти в аккаунт.',
    403: 'Доступ запрещен',
    500: 'Произошла серверная ошибка 500. Повторите попытку позже',
    200: 'Изменения сохранены',
    201: 'Изменения сохранены',
    204: 'Изменения сохранены',
    413: 'Ошибка 413. Размер файла превышает допустимые значения',
    undefined: 'Произошла ошибка.',
    qq: 'Купон удален',
    qw:'Купон создан',
    punchDeleted: 'Панч-карта удалена',
    punchCreated: 'Панч-карта создана',
    qe:'Акция удалена',
    qr: 'Город удален',
    qt: 'Город добавлен',
    qy: 'Категория создана',
    qu: 'Категория удалена',
    qi: 'Импорт акций завершен',
    qo: 'Глобальный стоп-лист обновлен',
    qp: 'История удалена',
    qa: 'История добавлена',
  },
  current: {
    tg: 'тг',
  },

  utils: {
    today: 'Сегодня',
    yesterday: 'Вчера',
  },

  components: {
    header: {
      langSelection: 'Выбор языка',
      search: 'Поиск',
      _search: 'Найти',
      empty: 'Ничего не найдено',
    },
    select: {
      empty: 'Не указано',
    },
    date: {
      date: 'ДД/ММ/ГГГГ',
    },
    webkassaReceipt: {
      organisation: 'QSR TOO',
      identicationNumber: 'БИН 110640022629',
      shift: 'Смена ',
      checkOrderNumber: 'Порядковый номер чека №',
      checkNumber: 'Чек №',
      cashier: 'Кассир',
      vat: 'В т.ч. НДС 12%',
      fiscalNumber: 'Фискальный признак:',
      fiscalOperator: 'Оператор фискальных данных:',
      link: 'Для проверки чека зайдите на сайт',
      fiscalCheckTitle: 'ФИСКАЛЬНЫЙ ЧЕК',
      fiscalOperatorNumber: 'ИНК ОФД',
      cashboxRegNumber: 'Код ККМ КГД (РНМ):',
      cashboxNumber: 'ЗНМ',
      webkassaLink: 'WEBKASSA.KZ',
      currencyMultiplier: '1 Шт х',
      price: 'Стоимость',
      total: 'ИТОГО:',
      time: 'Время:',
      vatSeries: 'НДС Серия 60001',
      vatNumber: '№ 0078759',
    },
    sidebar: {
      combo: 'Комбо',
      storyTypes: 'Типы историй',
      stories: 'Истории',
      stoplists: 'Стоп-листы',
      orders: 'Заказы',
      pointsshop: 'Магазин поинтов',
      cities: 'Города',
      menu: 'Меню',
      goods: 'Товары',
      ingredients: 'Ингредиенты',
      feedbacks: 'Обратная связь',
      manage: 'Менеджмент',
      managers: 'Сотрудники',
      discounts: 'Акции',
      dashboard: 'Дашборд',
      users: 'Пользователи',
      roles: 'Доступы',
      categories: 'Категории',
      coupons: 'Купоны',
      punchcards: 'Панч-карты',
      restaurants: 'Рестораны',
      statusRestaurants: 'Статусы ресторанов',
      arendators: 'Арендаторы',
      landlords: 'Арендодатели',
      development: 'Разработка',
      storybook: 'Storybook',
      buttons: 'Buttons',
      cards: 'Cards',
      selectionControls: 'Selection Controls',
      textFields: 'Text Fields',
      datePicker: 'Date picker',
      dropdownInput: 'Dropdown input',
      rentalObjects: 'Объекты аренды',
      skelet: 'Skelet',
      toasts: 'Toasts',
      drawer: 'Drawer',
      icons: 'Icons',
      modals: 'Modals',
      dropzone: 'Dropzone',
      restaurant: 'Ресторан:',
      aggregator: 'Агрегатор',
      currentOrders: 'Текущие заказы',
      readyOrders: 'Готовые к выдаче',
      archive: 'Архив',
      webkassaChecks: 'Чеки Webkassa',
      qq: 'Открыт',

    },
    gallery: {
      look: 'Просмотреть',
      showMore: 'Показать еще',
      empty: 'Изображений пока нет',
    },
    tooltips: {
      delete: 'Удалить',
      edit: 'Редактировать',
      users: 'Пользователи',
      open: 'Просмотреть',
    },
    aggregatorButton: {
      title: 'Агрегатор',
      close: 'Закрыть ресторан',
      open: 'Открыть ресторан',
      aggGlovoName: 'Glovo',
      aggChocoName: 'Чокофуд',
      aggWoltName: 'Вольт',
      aggYandexName: 'Яндекс',
      aggDelivioName: 'Деливио',
      closedInfo: 'ресторан сейчас закрыт',
      openInfo: 'ресторан сейчас открыт',
    },
    webkassaChecksButton: {
      title: 'Чеки Webkassa',
      newOrdersSort: 'Новые чеки',
      oldOrdersSort: 'Старые чеки',
      checkNumber: 'Чек №',
      success: 'Напечатан',
      failed: 'Ожидание повторной отправки',
      orderTime: 'Время чека:',
      total: 'Итого:',
      status: 'Статус:',
      checkFiscalNumber: 'Фискальный признак:',
      operationType: 'Тип операции:',
      aggregator: 'Агрегатор:',
    },
    currentOrdersButton: {
      title: 'Текущие заказы',
      newOrdersSort: 'Новые заказы',
      oldOrdersSort: 'Старые заказы',
      oderNumber: 'Заказ №',
      ready: 'Выдан',
      service: 'Сервис:',
      sum: 'Сумма:',
      position: 'Позиций:',
      orderTime: 'Время заказа:',
      orderCode: 'Код заказа:',
      externalCode: 'Внешний:',
      courier: 'Курьер прибудет:',
      client: 'Клиент прибудет',
      paymentType: 'Тип оплаты:',
      paymentTypeNameCard: 'карта',
      paymentTypeNameCash: 'наличные',
    },
    archiveOrder: {
      status: 'Статус:',
      message: 'Причина:',
    },
    statuses: {
      created: 'Создан',
      failed: 'Ошибочный',
      ready: 'Готов',
      picked: 'Отдан',
      canceled: 'Отменен',
      active: 'Активен',
      disabled: 'Отключен',
      noprocessed: 'Не обработано',
      processed: 'Обработано',
      used: 'Использован',
    },
    modal: {
      number: 'Номер заказа',
      checkNumber: 'Номер чека',
    },
    descriptionRestaurantModal: {
      numberOrder: 'Заказ №',
      detailOrderTitle: 'Детали заказа',
      timeOrderTitle: 'Время заказа',
      commentsOrderTitle: 'Комментарии к заказу',
      contentOrderTitle: 'Состав заказа',
      additionOrderTitle: 'Дополнительно:',
      remove: 'Убрать:',
      currency: 'Тг',
      total: 'Итого:',
    },
    webkassaDescriptionRestModal: {
      checkNumber: 'Признак чека: ',
      checkIsNotFiscalized: 'Чек не фискализирован',
      detailCheckTitle: 'Детали чека',
      timeCheckTitle: 'Время чека',
      contentCheckTitle: 'Состав чека',
      additionCheckTitle: 'Дополнительно:',
      cellTitle: 'Продажа',
      returnTitle: 'Возврат',
      cashboxNumber: 'Номер кассы:',
      operationType: 'Тип операции:',
      total: 'Итого:',
      vat: 'В т.ч. НДС 12%:',
      currency: 'Тг',
      checkId: 'Id чека в системе:',
    },
    receipt: {
      numberOrder: 'Номер заказа',
      dateOrder: 'Дата заказа:',
      timeOrder: 'Время заказа:',
      position: 'Позиция',
      count: 'Кол-во',
      price: 'Цена',
      positionCount: 'Количество позиций',
      sumOrder: 'Сумма заказа',
      total: 'Итого',
      tg: 'тг',
      courierCode: 'Кур. код',
      orderCode: 'Код заказа',
    },
    orderComponent: {
      data: 'Дата',
      time: 'Время',
    },
    wkDescriptionDefaultRestaurantModal: {
      title: 'Выберите чек',
      description: 'Нажмите на чек, чтобы посмотреть его детальную информацию.',
    },
    descriptionDefaultRestaurantModal: {
      title: 'Выберите заказ',
      description: 'Нажмите на заказ, чтобы посмотреть его детальную информацию, комментарии и состав.',
    },
    readyOrdersButton: {
      title: 'Готовые к выдаче',
      newOrdersSort: 'Новые заказы',
      oldOrdersSort: 'Старые заказы',
      oderNumber: 'Заказ №',
      ready: 'Готов',
      service: 'Сервис:',
      sum: 'Сумма:',
      position: 'Позиций:',
      orderTime: 'Время заказа:',
    },
    archiveButton: {
      title: 'Архив выданных заказов',
      filtersTitle: 'Фильтры',
    },
  },
  screens: {
    yandexDiscounts: {
      id: 'Идентификатор',
      position: 'Позиция',
      addYandexDiscounts: 'Добавить яндекс скидку',
      typeProduct: 'Тип продукта',
      editYandexDiscounts: 'Редатикровать яндекс скидку',
      type: 'Тип',
      name: 'Название',
      discount: 'Скидка',
      status: 'Cтатус',
      delete: 'Вы действительно хотите удалить ядекс акцию',
      yandexDiscounts: 'Яндекс скидки',
      discountName: 'Название',
    },
    authorization: {
      email: 'E-mail',
      auth: 'Авторизация',
      login: 'Логин',
      password: 'Пароль',
      go: 'Войти',
      fill: 'Заполните всю необходимую информацию ниже, чтобы войти в систему.',
      welcome: 'Добро пожаловать в Burger King!',
      solution: 'Лучшее решение для вашего бизнеса.',
    },
    users: {
      carNumber: 'Номер автомобиля',
      birthDate: 'Дата рождения',
      id: 'Идентификатор',
      additional: 'Дополнительная информация',
      socials: 'Социальные сети',
      vk: 'Вконтакте',
      fb: 'Facebook',
      google: 'Google',
      contact: 'Контактные данные',
      personalData: 'Личные данные',
      sex: 'Пол',
      plate: 'Номер автомобиля',
      status: 'Статус',
      invite: 'Код приглашения',
      edit: 'Редактировать пользователя',
      title: 'Пользователи',
      addUser: 'Добавить пользователя',
      name: 'Имя',
      lastName: 'Фамилия',
      email: 'Email',
      street: 'Адрес',
      number: 'Номер телефона',
      password: 'Пароль',
      role: 'Роль',
      restaurant: 'Ресторан',
      dateCreate: 'Дата создания',
      save: 'Сохранить',
      question: 'Вы действительно хотите удалить пользователя',
      qq: 'Загрузка...',
      qw: 'Пользователь',
      qe: 'Не указано',
    },
    dashboard: {
      main: 'Главная',
      statistics: 'Статистика',
      period: 'Период',
      restaurant: 'Ресторан',
      address: 'Адрес',
      city: 'Город',
      allOrdersCount: 'Общее количество заказов',
      averageAmount: 'Средний чек, тг',
      successOrdersCount: 'Количество успешных заказов',
      revenue: 'Выручка, тг',
      allOrdersYesterday: 'Заказы (вчера)',
      revenueYesterday: 'Выручка, тг (вчера)',
      allOrdersWeekAgo: 'Заказы (неделю назад)',
      allRevenueWeekAgo: 'Выручка, тг (неделю назад)',
      totalAllToLastWeekInPercent: 'Изменения к прошлой неделе, %',
      result: 'Итого: ',
    },
    stoplists: {
      stoplists: 'Стоп-листы',
      stoplist: 'Стоп-лист',
      addModificatorStoplist: 'Добавить модификаторы в стоп-лист',
      addProductStoplist: 'Добавить товары в стоп-лист',
      addComboStopList: 'Добавить комбо в стоп-лист',
      addStoplist: 'Добавить в стоп-лист',
      id: 'Идентификатор',
      restaurantTitle: 'Название ресторана',
      address: 'Адрес ресторана',
      deleteQuestion: 'Вы действительно хотите удалить элемент из стоп-листа',
      city: 'Город',
      qq: 'Модификаторы',
      qw: 'Товары',
      qz: 'Комбо',
      qe: 'Глобальный стоп-лист',
      qr: 'Модификаторы в составе глобального стоп-листа',
      qt: 'Товары в составе глобального стоп-листа',
      ql: 'Комбо в составе глобального стоп-листа',
      qy: 'Поиск по товарам',
      qu: 'Поиск по модификаторам',
      qf: 'Поиск по комбо',
      qi: 'Модификаторы в составе стоп-листа',
      qo: 'Товары в составе стоп-листа',
      qn: 'Комбо в составе стоп-листа',
    },
    managers: {
      managers: 'Сотрудники',
      addManager: 'Добавить сотрудника',
      editManager: 'Редактировать сотрудника',
      chooseRestaurant: 'Адрес ресторана',
      chooseRole: 'Выбрать роль',
      role: 'Роль',
      phone: 'Номер телефона',
      authData: 'Данные для входа',
      qq: 'Специальный доступ к стоп-листам',
      qw: 'Пароль должен содержать не менее 6 символов',
      qe: 'Сотрудник',
      qr: 'Не указано',
    },
    discounts: {
      import: 'Импортировать акции',
      mindboxPromotion: 'Акция из Mindbox',
      warning: 'Предупреждение',
      title: 'Название',
      subtitle: 'Подзаголовок',
      description: 'Описание',
      discounts: 'Акции',
      addDiscount: 'Добавить акцию',
      editDiscount: 'Редактировать акцию',
      question: 'Вы действительно хотите удалить акцию',
      getByIdError: 'Произошла ошибка при загрузке акции',
      expirationDate: 'Дата окончания',
      startDate: 'Дата начала',
      productsList: 'Cписок товаров-участников',
      expiration: 'Действительна до',
      start: 'Начало',
      promotionDescription: 'Описание акции',
      qq: 'Дата начала действия акции',
      qw: 'Дата окончания действия акции',
    },

    points: {
      addLevel: 'Добавить уровень',
      editLevel: 'Редактировать уровень',
      points: 'Магазин поинтов',
      id: 'Идентификатор',
      amount: 'Сумма',
      confirmDelete: 'Вы действительно хотите удалить уровень с суммой',
      addProducts: 'Добавить продукты',
      levelTitle: 'Название уровня',
      levelPosition: 'Позиция уровня',
      pointsAmount: 'Количество поинтов',
      qq: 'Комбо-товары в составе уровня',
      qw: 'Товары в составе уровня',
      qe: 'Цвет фона',
      qr: 'Описание',
      qt: 'Поиск по товарам',
      qy: 'Поиск по комбо-товарам',
    },

    goods: {
      sync: 'Синхронизация меню из POS',
      noSubstitute: 'Нет товаров-заменителей',
      weigth: 'Вес (г)',
      energyValueKcal: 'Энергетическая ценность (Ккал)',
      allProducts: 'Все продукты',
      noChoosedProducts:'Нет выбранных товаров',
      enterProducts: 'Введите название продукта',
      products: 'Продукты',
      exportQuestion: 'Вы действительно хотите отправить товары в Mindbox',
      microsTitle: 'Micros',
      export: 'Отправить товары в Mindbox',
      noModificators:'Нет выбранных ингредиентов',
      productView: 'Просмотр товара',
      definition: 'Публичное наименование',
      description: 'Описание товара',
      chooseDefinition: 'Выбрать наименование',
      createDefinition: 'Создать наименование',
      editDefinition: 'Редактировать наименование',
      category: 'Категория',
      energyValueKj: 'Энергетическая ценность (кДж)',
      energyValue: 'Пищевая ценность',
      energyValueEXT: 'Пищевая ценность (на 1 порцию)',
      macroNutrients: 'Макронутриенты',
      allergens: 'Аллергены',
      allergensList: 'Список аллергенов',
      noAllergens: 'Нет выбранных аллергенов',
      energy: {
        saturatedFats: 'Насыщенные жиры (г)',
        fiber: 'Клетчатка (г)',
        sugar: 'Сахар (г)',
        salt: 'Соль (г)',
        proteins: 'Белки (г)',
        fats: 'Жиры (г)',
        carbs: 'Углеводы (г)',
        cellulose: 'Клетчатка (г)',
      },
      prices: {
        price: 'Цена',
        default: 'Цена в зале',
        delivery: 'Цена на доставке',
      },
      substitute: 'Товары-заменители',
      weight: 'Вес (г)',
      goods: 'Товары',
      good: 'Товар',
      picture: 'Картинка',
      title: 'Наименование',
      price: 'Цена',
      points: 'Поинты',
      id: 'Идентификатор',
      status: 'Статус',
      addGood: 'Добавить товар',
      addGoods: 'Добавить товары',
      enterGoods: 'Введите название товара',
      chooseGoods: 'Выбрать товары',
      choosedProducts: 'Выбранные товары',
      editGood: 'Редактировать товар',
      deleteQuestion: 'Вы действительно хотите удалить товар',
      chooseIngredients: 'Выберите ингредиенты',
      syncQuestion: 'Вы действительно хотите синхронизировать меню из POS? Время ожидания около 2 мин.',
      qq: 'Цена основная',
      qw: 'Цена на доставку',
      qe: 'Цена основная (Запад)',
      qr: 'Цена на доставку (Запад)',
      qt: 'Эта позиция уже используется другим продуктом.',
      qy: 'ID',
      qu: 'Наименование',
      qi: 'Доставка',
      qo: 'Приложение',
      qp: 'Выгрузка прошла успешно',
      qa: 'Позиция в таблице',
      qs: 'Основные',
      qd: 'Модификаторы',
      qf: 'Категория',
      qg: 'Позиция',
      qh: 'Поиск по товарам',
      qj: 'Выбор аллергенов',
    },

    combo: {
      combo: 'Комбо',
      exportQuestion: 'Вы действительно хотите выгрузить комбо',
      microsTitle: 'Наименование Micros',
      export: 'Экспорт комбо',
      noModificators:'Нет выбранных ингредиентов',
      productView: 'Просмотр комбо',
      definition: 'Публичное наименование',
      description: 'Описание комбо',
      chooseDefinition: 'Выбрать наименование',
      createDefinition: 'Создать наименование',
      category: 'Категория',
      prices: {
        price: 'Цена',
        default: 'Цена в зале',
        delivery: 'Цена на доставке',
      },
      energyValueKcal: 'Энергетическая ценность (ккал)',
      substitute: 'Товары-заменители',
      weight: 'Вес (г)',
      goods: 'Товары',
      good: 'Товар',
      picture: 'Картинка',
      title: 'Наименование',
      price: 'Цена',
      points: 'Поинты',
      id: 'Идентификатор',
      status: 'Статус',
      addCombo: 'Добавить комбо',
      enterCombo: 'Введите название комбо',
      chooseCombo: 'Выбрать комбо',
      editCombo: 'Редактировать комбо',
      deleteQuestion: 'Вы действительно хотите удалить комбо',
      qq: 'Общее',
      qw: 'Глово',
      qe: 'Яндекс Еда',
      qr: 'Chocofood',
      qt: 'Wolt',
      qy: 'Позиция в таблице:',
      qu: 'Эта позиция уже используется другим продуктом.',
      qi: '(Название из Mindbox)',
      qo: 'Содержимое комбо',
      qp: 'Позиция',
      qa: 'Основной в приложении',
      qs: 'Основной на доставку',
      qd: 'Доступен в приложении',
      qf: 'Доступен на доставку',
      qg: 'Основной продукт:',
      qh: 'Цена основная',
      qj: 'Цена на доставку',
      qk: 'Цена основная (Запад)',
      ql: 'Цена на доставку (Запад)',
      qz: 'Комбо-предложение',
      qx: 'Да',
      qv: 'Нет',

    },

    menu: {
      AA: 'Отправить меню в',
      menu: 'Меню',
      addMenu: 'Добавить меню',
      editMenu: 'Редактировать меню',
      qq: 'Товары в наличии',
      qw: 'Поиск товара',
      qe: 'Удалено',
      qr: 'Все товары',
    },
    stories: {
      AA: 'Подробнее',
      AB: 'Свернуть',
      AC: 'Заголовок',
      AD: 'Подзаголовок',
      AE: 'Кнопка',
      AF: 'Тип истории',
      AG: 'Опубликовано',
      AH: 'Действует до',
      AI: 'Cсылка',
      AJ: 'Цвет',
      type: 'Тип',
      id: 'Идентификатор',
      serial: 'Порядковый номер',
      storyTypeId: 'Тип истории',
      title: 'Заголовок',
      subtitle: 'Подзаголовок',
      buttonText: 'Текст на кнопке',
      startDateTime:'Начало',
      endDateTime: 'Окончание',
      link: 'Ссылка',
      isTabBarDark: 'Темный интерфейс',
      stories: 'Истории',
      chooseColor: 'Цвет истории',
      storyTypes: 'Типы историй',
      addStory: 'Добавить историю',
      editStory: 'Редактировать историю',
      addStoryType: 'Создать тип',
      editStoryType: 'Редактировать тип',
      chooseType: 'Выберите тип',
      enterSerial: 'Введите порядковый номер',
      uniqueSerialWarning: 'Порядковый номер должен быть уникальным',
      noTypesWarnings: 'Не создано ни одного типа. Перейдите во вкладку "Типы историй" и создайте тип чтобы продолжить.',
      create: 'Создать новый тип',
      delete: 'Вы действительно хотите удалить историю',
      deleteType: 'Вы действительно хотите удалить тип',
      notypeswarning: 'Еще не создано ни одного типа истории, создать историю невозможно. Перейдите в раздел "Типы историй", чтобы создать тип',
      enterLink: 'Введите ссылку',
      darkMode: 'Темный интерфейс',
      qk: 'Настройки времени активности истории *',
      ql: 'Активна с *',
      qz: 'Активна по *',
      qx: 'Тип истории *',
    },
    aggregators: {
      aggregators: 'Агрегаторы',
      aggregatorsReports: 'Отчёты по агрегаторам',
      dailyReport: 'Ежедневный отчёт',
      weeklyReport: 'Еженедельный отчет',
      kkmReport: 'Отчёт по ККМ',
      ordersReadyReport: 'Отчет по времени сборки заказов',
      kanals: 'Каналы доставки',
      sendMenuToAggregatorQuestion: 'Вы действительно хотите отправить меню в агрегатор',
      sendMenuToAggregator: 'Отправить меню в агрегатор',
      choosePeriod: 'Отчётный период',
      addFilters: 'Добавить фильтры',
      city: 'Город',
      groupBy: 'Группировать по:',
      groupByRestaurant: 'По ресторанам',
      groupByRegion: 'По городам',
      chooseCity: 'Выберите город',
      restaurant: 'Ресторан',
      chooseRestaurant: 'Выберите ресторан',
      qq: 'Выбранные товары',
      qw: 'Нет выбранных товаров',
      qr: 'Поиск товара',
      qt: 'Нет товаров',
      qy: 'Общая база товаров',
      qu: 'Назад',
      qi: 'Меню отправлено',
      qo: 'Не удалось загрузить товары',
      qp: 'Товары',
      qa: 'Комбо',
      qs: 'Редактирование меню',
      qd: 'Меню агрегаторов',
      qf: 'Невозможно получить данные',
      qg: 'Детальный просмотр меню',
      qh: 'Категория',
      qj: 'Текущий ресторан',
      qk: 'Id агрегатора:',
    },
    cities: {
      status: 'статус',
      id: 'Идентификатор',
      name: 'Название',
      geo: 'Координаты',
      searchGeo: 'Поиск координат',
      timezone: 'Часовой пояс',
      editCity: 'Редактировать город',
      addCity: 'Добавить город',
      mapPlaceholder: 'Ввод адреса для карты',
      cities: 'Города',
      city: 'Город',
      save: 'Сохранить',
      deleteQuestion: 'Вы действительно хотите удалить город',
      restaurants: 'Рестораны',
    },
    categories: {
      AA: 'Категории',
      AB: 'Добавить категорию',
      AC: 'Категории',
      AD: 'Идентификатор',
      AE: 'Название',
      AF: {
        AFA: 'Товары',
        AFB: 'Комбо',
        AFC: 'Модификаторы',
      },
      AG: 'Общая информация',
      AH: 'Добавить категорию',
      AI: 'Вы действительно хотите удалить категорию',
      AJ: 'Редактирование категории',
      AK: 'Создание категории',
      AL: 'Порядковый номер',
      AM: 'Kлюч',
      AN: 'Тип',
      AO: 'Позиция',
    },
    ingredients: {
      typeIngredients: 'Поиск по модификаторам',
      description: 'Описание ингредиента',
      goods: 'Товары',
      ingredients: 'Модификаторы',
      picture: 'Картинка',
      title: 'Название',
      price: 'Цена',
      energy: {
        proteins: 'Белки',
        fats: 'Жиры',
        carbs: 'углеводы',
      },
      id: 'Идентификатор',
      status: 'Статус',
      addIngredient: 'Добавить ингредиент',
      editIngredient: 'Редактировать ингредиент',
      deleteQuestion: 'Вы действительно хотите удалить ингредиент',
      chooseGoods: 'Выберите товары',
    },
    coupons: {
      id: 'Id',
      type: 'Тип',
      typeEXT: 'Тип купона',
      qntyGenerateBase:'Введите количество купонов для генерации',
      title: 'Название',
      code: 'Код',
      codeEXT: 'Код купона',
      codesEXT: 'Коды купонов',
      couponView: 'Просмотр купона',
      price: 'Цена',
      discount: 'Скидка',
      coupons: 'Купоны',
      addCoupon: 'Создать купон',
      editCoupon: 'Редактировать купон',
      primaryPhoto: 'Заглавное фото',
      question: 'Вы действительно хотите удалить купон',
      expirationDate: 'Действует до',
      expirationDateETX: 'Дата окончания действия купона',
      menuChip: 'Выберите меню',
      certainDate: 'Действие по определенным дням',
      discountType: 'Выберите величину, применяемую для скидки: ',
      couponActivity: 'Активность купона по дням',
      value: 'Скидка в тг',
      percent: 'Скидка в %',
      ot: 'От:',
      do: 'До:',
      status: 'Статус',
      qq: 'Необходимо добавить один продукт',
      qw: 'Купон с таким кодом уже существует',
      qe: 'Общий',
      qr: 'Персональный',
      qt: 'Магазин поинтов',
      qy: 'Приветственный бонус',
      qu: 'Подарок',
      qgb: 'Сгенерированные',
      qi: 'Позиция',
      qo: 'Количество',
      qp: 'Число использований купона',
      qa: 'Неограниченное использование',
      qs: 'Цена купона без учета скидки, тг',
      qd: 'Скидка, тг',
      qf: 'Скидка, %',
      qg: 'Товары в составе купона',
      qh: 'Поиск по товарам',
      qj: 'Комбо-товары в составе купона',
      qk: 'Поиск по комбо-товарам',
      ql: 'Модификаторы в составе купона',
      qz: 'Поиск по модификаторам',
      qx: 'Число использований',
      qc: 'Неограничено',
      qv: 'Фон купона',
    },
    punchcards: {
      createdAt: 'Создано',
      id: 'Идентификатор',
      title: 'Название',
      rewardType: 'Выберите тип награды',
      rewardIn: 'Тип награды',
      items: 'Товары',
      toBuy: 'Цель по покупкам: ',
      toAchieve: 'Ожидаемая награда: ',
      description: 'Описание',
      warning: 'Предупреждение',
      points: 'Поинты',
      numberOfPoints: 'Количество поинтов',
      numberOfPurchases: 'Число покупок',
      punchcards: 'Панчкарты',
      addPunchcard: 'Добавить панч-карту',
      editPunchcard: 'Редактировать панч-карту',
      backgroundColor: 'Фон панч-карты',
      couponBackgroundColor: 'Фон выдаваемого купона',
      products: 'Список товаров',
      question: 'Вы действительно хотите удалить панчкарту',
      status: 'Статус',
      date: 'Дата создания',
      qq: 'Позиция',
      mission: 'Миссия',
      buy: 'Купить',
      achieve: 'Получить',
      qw: 'Количество',
      qe: 'Число покупок',
    },
    roles: {
      title: 'Роли',
      addRole: 'Добавить новую роль',
      role: 'Роль',
      accesses: 'Доступы',
      roleName: 'Название роли',
      modal: {
        send: 'Отправить',
        cancel: 'Отмена',
        export: 'Экспорт',
        sync: 'Синхронизировать',
        confirmAction: 'Подтвердите действие',
        question: 'Вы действительно хотите удалить роль',
        delete: 'Удалить',
        leave: 'Оставить',
        save: 'Сохранить',
      },
    },
    restaurants: {
      restaurant: 'Ресторан',
      packageType: 'Тип пакета',
      coordinates: 'Координаты ресторана',
      id: 'Идентификатор',
      status: 'Статус',
      description: 'Описание',
      photos: 'Фотографии:',
      title: 'Рестораны',
      yandexDiscounts: 'Яндекс скидки',
      statusTitle: 'Статусы ресторанов',
      name: 'Название',
      city: 'Город',
      address: 'Адрес',
      menu: 'Меню',
      stoplist: 'Стоп-лист',
      orders: 'Заказы',
      location: 'Местоположение',
      sections: 'Разделы',
      aggregators: 'Агрегаторы',
      aggregatorsReport: 'Отчёт ККМ по доставке',
      phone: 'Номер телефона',
      workingHours: 'Время работы',
      deliveryWay: 'Способы доставки',
      addRestaurant: 'Создать ресторан',
      editRestaurant: 'Редактировать ресторан',
      leave: 'Вы действительно хотите удалить ресторан',
      tags: 'Теги',
      serviceType: 'Сервис',
      chooseTags: 'Выберите теги',
      chooseServiceType: 'Выберите сервис',
      statusGlovo: 'Статус Glovo',
      statusChoko: 'Статус Chocofood',
      statusYandex: 'Статус Yandex',
      statusWolt: 'Статус Wolt',
      qq: 'Введите название ресторана',
      qw: 'Закрытие',
      qe: 'Время закрытия Glovo',
      qr: 'Открыто',
      qz: 'Закрыто',
      qt: 'Ограничить работу Glovo',
      qy: 'Ресторан с таким ID уже существует',
      qu: 'Город',
      qi: 'Ограничить работу Chocofood',
      qo: 'Произошла ошибка при изменении статуса ресторана в Chocofood',
      lastUpdate: 'Последнее обновление',
    },
    reviews: {
      status: 'Статус',
      id: 'Идентификатор',
      restaurant: 'Ресторан',
      phoneNumber: 'Телефон',
      feedbackView: 'Просмотр отзыва',
      reason: 'Причины обращения',
      email:'E-mail',
      name: 'Пользователь',
      publicationDate: 'Дата',
      feedbacks: 'Обратная связь',
      view: 'Просмотреть',
      delete: 'Удалить',
      processed: 'Обработано',
      setProcessed: 'Пометить как обработанное',
      qq: 'Нет сообщения',
      qw: 'Нет данных',
      qe: 'Грязно в ресторане',
      qr: 'Не понравилось обслуживание в ресторане',
      qt: 'Не понравилась продукция',
      qy: 'Проблемы с заказом',
      qu: 'Вопросы по работе мобильного приложения',
      qi: 'Забыли вещи в ресторане',
    },
    modal: {
      chooseDate: 'Выберите период',
      cancel: 'Отмена',
      chooseModificators: 'Выберите модификаторы',
      tiers: 'Ценовой регион',
      createDefinition: 'Создать наименование',
      search: 'Поиск',
      title_def: 'Название',
      description_def: 'Описание',
      clear: 'Очистить',
      edit: 'Редактировать',
      noTitle: 'Без названия',
      noData: 'Нет данных',
      timezone: 'Часовой пояс',
      printerName:'Название принтера',
      ipAddress: 'IP-адрес',
      packageType: 'Тип пакета',
      price: 'Цена',
      id: 'Идентификатор',
      'id_short': 'ID',
      other: 'Разное',
      step: 'Шаг',
      backgroundColor: 'Цвет фона купона',
      punchcardBackgroundColor: 'Цвет фона панч-карты',
      punchcardCouponBackgroundColor: 'Цвет фона выдаваемого купона',
      posId: 'Идентификатор в POS',
      posTitle: 'Название из POS',
      posTitle2: 'Название 2 из POS',
      img: 'Изображение',
      couponImg: 'Изображение купона',
      imgs: 'Изображения',
      birthDate: 'Дата рождения',
      error: 'Произошла ошибка',
      general: 'Общая информация',
      additional: 'Дополнительная информация',
      personalData: 'Личные данные',
      contact: 'Контактные данные',
      email:'Электронная почта',
      dataForEnter: 'Данные для входа',
      socials: 'Социальные сети',
      basic: 'Общая информация',
      registerData: 'Данные для входа',
      langs: 'Языки',
      couponColor: 'Цвет фона купона',
      purchaseNumber: 'Число покупок',
      menu: 'Выберите меню',
      advantages: 'Преимущества',
      workingTime: 'Время работы',
      picture: 'Изображение',
      energy: 'Энергетическая ценность',
      macro: 'Макронутриенты',
      addEnergy: 'Дополнительные элементы',
      allergen: 'Аллергены',
      ingredients: 'Ингредиенты',
      category: 'Категория',
      products: 'Товары',
      status: 'Статус',
      chooseProducts: 'Выбрать товары',
      chooseCombo: 'Выбрать комбо',
      title: ['Название', 'Тақырып', 'Title'],
      subtitle: ['Подзаголовок', 'Субтитр', 'Subtitle'],
      warning: ['Предупреждение', 'Ескерту', 'Warning'],
      description: ['Описание', 'Сипаттама', 'Description'],
      buttonText: ['Текст на кнопке', 'Түйме мәтіні', 'Button text'],
      address: ['Адрес', 'Мекенжай', 'Address'],
      startDate: 'Дата начала',
      startTime: 'Время начала',
      start: 'Начало',
      end: 'Конец',
      expirationDate: 'Дата окончания',
      expirationTime: 'Время окончания',
      couponsCodes:'Сгенерированные коды',
      next: 'Далее',
      back: 'Назад',
      save: 'Сохранить',
      choose: 'Выбрать',
      qq: 'Загрузка...',
    },
    orders: {
      id: 'Идентификатор',
      aggregatorId: 'ID в агрегаторе',
      aggregator: 'Агрегатор',
      sum: 'Сумма',
      receiptDate: 'Дата получения',
      status: 'Cтатус',
      orders: 'Заказы',
      order: 'Заказ',
      cancel: 'Отменить заказ',
      orderInfo: 'Информация о заказе',
      clientInfo: 'Данные клиента',
      details: 'Детали заказа',
      comment: 'Комментарий к заказу',
      includes: 'Cостав заказа',
      delete: 'Вы действительно хотите удалить заказ',
      qq: 'Актуальные',
      qw: 'Архив',
      qe: 'Имя клиента',
      qr: 'Ресторан',
      qt: 'Число товаров',
      qy: 'Дата выдачи',
      qu: 'Название',
      qi: 'Цена',
      qo: 'Кол-во',
      qp: 'Дополнительно',
      qa: 'Сообщение',
    },
  },
};

export default ru;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import Prompt from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import colors from '../../styles/colors';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'colors.color_black._8',
    fontWeight: 300,
    fontSize: 12,
  },
  arrow: {
    color: colors.color_black._8,
  },
}))(Prompt);

/**
 * @component Tooltip
 * @example
 * <Tooltip />
 */
const Tooltip = (props) => {
  return (
    <CustomTooltip
      enterDelay={props.enterDelay}
      leaveDelay={props.leaveDelay}
      placement={props.placement}
      title={props.title}
      arrow={props.arrow}
    >
      {props.children}
    </CustomTooltip>
  );
};

/**
 * Properties
 */

Tooltip.propTypes = {
  title: PropTypes.string,
  arrow: PropTypes.bool,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
    'left-start',
    'left',
    'left-end',
  ]),
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,
};
Tooltip.defaultProps = {
  title: '',
  arrow: true,
  enterDelay: 100,
  leaveDelay: 0,
};

export default Tooltip;

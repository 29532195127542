/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Table, Button } from '../../ui-kit';
import { translateStatusReviews } from '../../../main/utils/translate-status';
import FeedbackFields from '../../components/modals/feedback-fields';
import Skelet from './skelet';
import colors from '../../styles/colors';
import { Head } from '../../components';
import { getBeautyDateTimeString } from '../../utils/datetime';

import STRINGS from '../../../localization';

import './styles.scss';

const FeedbacksView = (props) => {
  const renderClassNameSort = (type) => {
    let str = 'summary_table_heading_sort';
    if (type === props.sort.type) {
      str += ' summary_table_heading_sort_active';
    }
    return str;
  };
  return (
    <>
      <FeedbackFields
        loading={props.loading}
        setLoading={props.setLoading}
        getReviewById={props.getReviewById}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'view'}
        setShowModal={props.setShowModal}
        title={STRINGS.screens.reviews.feedbacks} //edit
        selectedReview={props.selectedReview}
        processed={props.processed}
      />
      <Head
        label={STRINGS.screens.reviews.feedbacks}
        button={false}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.reviews.feedbacks,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              loading={props.bottomLoading}
              head={() => (
                <tr className="summary_table_heading">
                  <th>{STRINGS.screens.reviews.id}</th>
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>{STRINGS.screens.reviews.name}</span>
                      <span
                        onClick={() => props.applySort('Name')}
                        className={renderClassNameSort('Name')}
                      >
                        {props.sort.sort === 'desc' && props.sort.type === 'Name'
                          ? 'Z - a'
                          : 'A - z'}
                      </span>
                    </div>
                  </th>
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>{STRINGS.screens.reviews.email}</span>
                      <span
                        onClick={() => props.applySort('Email')}
                        className={renderClassNameSort('Email')}
                      >
                        {props.sort.sort === 'desc' && props.sort.type === 'Email'
                          ? 'Z - a'
                          : 'A - z'}
                      </span>
                    </div>
                  </th>
                  <th>{STRINGS.screens.reviews.phoneNumber}</th>
                  <th className="summary_table_heading_cell">
                    <div>
                      <span>{STRINGS.screens.reviews.publicationDate}</span>
                      <span
                        onClick={() => props.applySort('PublicationDate')}
                        className={renderClassNameSort('PublicationDate')}
                      >
                        <div
                          className={
                            props.sort.sort === 'desc' && props.sort.type === 'PublicationDate'
                              ? 'summary_table_heading_arrow'
                              : 'summary_table_heading_arrow_active'
                          }
                        >
                          <Icon
                            width={16}
                            height={16}
                            color={colors.color_gray._3}
                            name="arrow-bottom"
                          />
                        </div>
                      </span>
                    </div>
                  </th>
                  <th>{STRINGS.screens.reviews.status}</th>
                </tr>
              )}
            >
              <>
                {props.reviews?.data?.map((item, index) => (
                  <tr
                    key={index}
                    className="summary_table_body"
                    onClick={async () => {
                      await props.setSelectedReview({ id: item.id, index });
                      props.setShowModal('view');
                    }}
                  >
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{getBeautyDateTimeString(new Date(item.publicationDate))}</td>
                    <td>
                      <Button
                        className="outline"
                        size="large"
                        label={
                          item.processed
                            ? translateStatusReviews('processed').status
                            : translateStatusReviews('noprocessed').status
                        }
                        type={
                          item.processed
                            ? translateStatusReviews('processed').button
                            : translateStatusReviews('noprocessed').button
                        }
                        typeButton="submit"
                        onClick={async (e) => {
                          e.stopPropagation();
                          !item.processed && props.processed(item.id, index);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

FeedbacksView.propTypes = {
  discounts: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableDiscount: PropTypes.object,
  setEditableDiscount: PropTypes.func,
  saveChanges: PropTypes.func,
};

FeedbacksView.defaultProps = {
  discounts: null,
  showModal: '',
  setShowModal: () => {},
  editableDiscount: {},
  setEditableDiscount: () => {},
  saveChanges: () => {},
};

export default FeedbacksView;

/**
 * @prettier
 */
import { resetRedux } from '../';
import { loginLoading, loginSuccess, loginFailure, setRestaurant } from './current-user';

import AuthService from '../../services/auth';
import RestauransService from '../../services/restaurants';
import CurrentUserService from '../../services/current-user';

import { Toast } from '../../main/ui-kit';

/**
 * Redux thunks for current user.
 */
class CurrentUserThunks {
  static authentication({ email, password }, loadingDisable) {
    return async (dispatch) => {
      try {
        const response = await AuthService.requestUserToken(email, password);

        await AuthService.setAuthToken({
          token: response?.accessToken,
          refreshToken: response?.refreshToken,
        });

        if (response.user?.restaurantId) {
          const res = await RestauransService.getRestaurantById(response.user.restaurantId);
          dispatch(setRestaurant(res));
        }
        dispatch(loginSuccess(response.user));

        loadingDisable();
      } catch (error) {
        Toast.error('Авторизоваться не удалось');
        dispatch(loginFailure(error));
        loadingDisable();
      }
    };
  }

  static logout() {
    return async (dispatch) => {
      try {
        await AuthService.removeAuthTokens();
        dispatch(resetRedux());
      } catch (error) {}
    };
  }

  static get() {
    return async (dispatch) => {
      dispatch(loginLoading());
      try {
        const response = await CurrentUserService.get();
        dispatch(loginSuccess(response));
      } catch (error) {
        await AuthService.removeAuthTokens();
        dispatch(loginFailure(error));
      }
    };
  }

  static restaurantInfo(restaurantId) {
    return async (dispatch) => {
      try {
        const response = await RestauransService.getRestaurantById(restaurantId);
        dispatch(setRestaurant(response));
      } catch (err) {}
    };
  }
}

export default CurrentUserThunks;

import STRINGS from '../../localization/index';

export const translateStatus = (status) => {
    switch (status) {
      case 'created':
        return { status: STRINGS.components.statuses.created, button: 'default' };
      case 'used':
        return { status: STRINGS.components.statuses.used, button: 'default' };
      case 'active':
        return { status: STRINGS.components.statuses.active, button: 'secondary' };
      case 'disabled':
        return { status: STRINGS.components.statuses.disabled, button: 'danger' };
    }
};

export const translateStatusOrders = (status) => {
    switch (status) {
      case 'created':
        return { status: STRINGS.components.statuses.created, button: 'primary' };
      case 'failed':
        return { status: STRINGS.components.statuses.failed, button: 'danger' };
      case 'picked':
        return { status: STRINGS.components.statuses.picked, button: 'default' };
      case 'ready':
        return { status: STRINGS.components.statuses.ready, button: 'secondary' };
      case 'canceled':
        return { status: STRINGS.components.statuses.canceled, button: 'default' };
    }
};

export const translateEnabledStatus = (status) => {
    if (status) {return { status: STRINGS.components.statuses.active, button: 'primary' };}
    return { status: STRINGS.components.statuses.disabled, button: 'danger' };
};

export const translateStatusReviews = (status) => {
    switch (status) {
      case 'noprocessed':
        return { status: STRINGS.components.statuses.noprocessed, button: 'secondary' };
      case 'processed':
        return { status: STRINGS.components.statuses.processed, button: 'default' };
    }
};

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';

import OrdersView from './orders-view';
import { Toast } from '../../ui-kit';
import { translate } from '../../utils/translate-aggregator';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { orderCategories } from '../../../constants/categories';
import { useHistory, useParams } from 'react-router-dom';
import OrdersService from '../../../services/orders';
import { useSelector } from 'react-redux';
import STRINGS from '../../../localization';

const OrdersContainer = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [page, setPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [bottomLoading, setBottomLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(orderCategories[0]);
  const history = useHistory();
  let { id } = useParams();

  const [orders, setOrders] = useState();
  const [showModal, setShowModal] = useState('');
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(false);
  const [sort, setSort] = useState({ type: 'ReceiptDateTime', sort: 'desc' });
  const sorts = sort.type ? `&sortBy${sort.type}=${sort.sort}` : '';

  const handleDeleteOrder = () => {
    setOrders((prevState) => prevState.filter((item) => item.id !== selectedOrder.id));
    setShowConfirm('');
    setSelectedOrder({});
  };

  const filterOrders = () => {
    const dayInMS = 86400000;
    const currentDate = new Date();
    if (selectedCategory?.key.includes('active')) {
      return new URLSearchParams({
        fromDate: new Date(currentDate.getTime() - dayInMS * 3).toISOString(),
      }).toString();
    } else {
      if (selectedCategory?.key.includes('archieve')) {
        return new URLSearchParams({
          toDate: new Date(currentDate.getTime() - dayInMS * 3).toISOString(),
        }).toString();
      } else {
        return '';
      }
    }
  };

  const getOrders = async (props) => {
    try {
      const resp = await OrdersService.getOrders(props);

      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const changeStatus = async (elem) => {
    if (elem.status.includes('created') || elem.status.includes('ready')) {
      try {
        if (elem?.orderOperator === 'MOBILE_APP') {
          await OrdersService.cancelMobileOrder(elem.id);
        } else {
          await OrdersService.cancel({ id: elem.id });
        }

        let temp = [...orders?.data];

        temp.find((el) => el.orderPatternId === elem.id).status = 'canceled';
        setOrders((old) => ({ isLast: old.isLast, data: temp }));
        Toast.success(STRINGS.notify.status);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await OrdersService.getOrders({
        page: page + 1,
        size: 30,
        langId: langId,
        sort: sorts,
        statuses: selectedCategory?.statuses,
        restaurantId: id,
        filter: filterOrders(),
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setOrders({
        isLast: resp.isLast,
        data: [...orders.data, ...resp?.data],
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };
  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  useEffect(() => {
    const getList = async (props) => {
      setSkeletVisible(true);
      setPage(1);
      const resp = await getOrders(props);
      if (resp) {
        setOrders({
          isLast: resp?.isLast,
          data: [...resp?.data],
        });
        setSkeletVisible(false);
      }
    };
    getList({
      page: 1,
      size: 30,
      langId: langId,
      sort: sorts,
      statuses: selectedCategory?.statuses,
      restaurantId: id,
      filter: filterOrders(),
    });
  }, [selectedCategory, sorts]);

  const applySort = (mode) => {
    setSort((prevState) => {
      return {
        sort: prevState.sort === 'asc' && prevState.type === mode ? 'desc' : 'asc',
        type: mode,
      };
    });
  };
  return (
    <OrdersView
      restaurantId={id}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      //----------------------------------------------------------------
      orders={orders}
      handleDeleteOrder={handleDeleteOrder}
      selectedOrder={selectedOrder}
      setSelectedOrder={setSelectedOrder}
      //----------------------------------------------------------------
      history={history}
      showModal={showModal}
      setShowModal={setShowModal}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      skeletVisible={skeletVisible}
      changeStatus={changeStatus}
      translateAggregator={translate}
      sort={sort}
      applySort={applySort}
      bottomLoading={bottomLoading}
    />
  );
};

export default OrdersContainer;

import React, { useEffect, useState } from 'react';

import View from './view';
import { ALLERGENS } from '../../../constants/allergens';
import GoodsService from '../../../services/goods';
import MainService from '../../../services/main';
import ModificatorsService from '../../../services/modificators';
import { Toast } from '../../ui-kit';
import MenuService from '../../../services/menu';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import STRINGS from '../../../localization';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { languages, categories } = useSelector((store) => store.stuff);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [skeletVisible, setSkeletVisible] = useState(true);
  const [modificators, setModificators] = useState([]);
  const [product, setProduct] = useState();
  const [showModal, setShowModal] = useState('');
  const CATEGORIES = categories?.reduce((acc, el) => [...acc, { label: el.title, key: el.id }], []);
  const [serialNumber, setSerialNumber] = useState('');
  const [positionExisting, setPositionExisting] = useState(false);
  const [initialPosition, setInitialPosition] = useState();
  const history = useHistory();
  let { id } = useParams();

  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getFileId = async (file) => {
    if (file?.id) {
      return file.id;
    } else {
      try {
        const id = await MainService.postFile(file);
        return id?.id;
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
  };
  const saveChanges = async (id, f) => {
    setLoading(true);
    let data = {
      weight: f.weight ? Number.parseInt(f.weight) : 0,
      definitionId: f.definitionId,
      energyValueKcal: Number.parseInt(f.energyValueKcal),
      allergens: f.allergens,
      categoryId: f.categoryId,
      substituteProducts: f.substituteProducts,
      productModificators: f.productModificators,
      nutrients: {
        proteins: f.nutrients?.proteins,
        fats: f.nutrients?.fats,
        carbohydrates: f.nutrients?.carbohydrates,
        saturatedFat: f.nutrients?.saturatedFat,
        cellulose: f.nutrients?.cellulose,
        sugar: f.nutrients?.cellulose,
        salt: f.nutrients?.salt,
      },
    };
    if (!!f.file) {
      data = Object.assign(data, { fileId: await getFileId(f.file) });
    }
    if (!!f.glovoFile) {
      data = Object.assign(data, { glovoFileId: await getFileId(f.glovoFile) });
    }
    if (!!f.yandexFile) {
      data = Object.assign(data, { yandexFileId: await getFileId(f.yandexFile) });
    }
    if (!!f.chocofoodFile) {
      data = Object.assign(data, { chocofoodFileId: await getFileId(f.chocofoodFile) });
    }
    if (!!f.woltFile) {
      data = Object.assign(data, { woltFileId: await getFileId(f.woltFile) });
    }
    try {
      await GoodsService.updateGood(id, data);
      setSkeletVisible(true);
      getProduct(id);
      if (f.productModificators.length > 0) {
        setModificators(
          await getModificatorList(
            f.productModificators.map((el) => ({
              id: el.modificatorId,
              availableMobile: el.availableMobile,
              availableDelivery: el.availableDelivery,
            })),
          ),
        );
      }
      setSkeletVisible(false);
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setTimeout(() => setLoading(false), 100);
  };
  const getModificatorsSuggestions = async (props) => {
    try {
      const resp = await MenuService.getModificatorsSuggestions(props);
      return resp.map((el) => ({ title: el.value, posId: el.id }));
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getProductById = async (id) => {
    try {
      const resp = await GoodsService.getProductById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getSubstitude = async (props) => {
    try {
      const resp = await GoodsService.getSubstitude(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getModificators = async (props) => {
    try {
      const resp = await ModificatorsService.getModificators(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getModificatorById = async (id) => {
    try {
      const resp = await ModificatorsService.getModificatorById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const patchModificatorAvailibility = async (data, type) => {
    try {
      let temp = [...modificators];
      temp.find((el) => el.id == data.modificatorId)[type] = data[type];
      setModificators(temp);
      await GoodsService.patchModificatorAvailibility(data);
    } catch (e) {}
  };
  const getModificatorList = async (modificators) => {
    const modificatorTitles = await Promise.all(
      modificators.map(async (mod) => {
        const resp = await getModificatorById(mod.id);
        return {
          title:
            resp?.definition?.find((el) => el.languageId === 1)?.title ||
            `${resp?.posTitle} (Наименование Micros)`,
          availableDelivery: mod.availableDelivery,
          availableMobile: mod.availableMobile,
          isAdditional: mod.isAdditional,
          id: mod?.id,
        };
      }),
    );
    return modificatorTitles;
  };
  
  useEffect(async () => {
    const putSerialNumber = async (props) => {
      try {
        await GoodsService.putSerialNumber(props);
      } catch (error) {}
    };
    const checkSerialNumberExisting = async (sn) => {
      try {
        const resp = await GoodsService.checkSerialNumberExisting(sn);
        return resp?.exists;
      } catch (error) {}
    };
    setPositionExisting(false);
    if (serialNumber?.length) {
      const setPosition = async () => {
        if (Number.parseInt(serialNumber) !== initialPosition) {
          const exists = await checkSerialNumberExisting(serialNumber);
          if (exists === true) {
            setPositionExisting(exists);
            putSerialNumber({ posId: product.posId, serialNumber: initialPosition });
          } else {
            putSerialNumber({ posId: product.posId, serialNumber: Number.parseInt(serialNumber) });
          }
        }
      };
      setPosition();
    }
  }, [serialNumber]);

  const getProduct = async (productId) => {
    setSkeletVisible(true);
    try {
      const resp = await getProductById(productId);
      setProduct(resp);
      setSerialNumber(resp?.serialNumber);
      setInitialPosition(resp?.serialNumber);
      if (resp?.productModificators.length > 0) {
        setModificators(
          await getModificatorList(
            resp?.productModificators.map((el) => ({
              id: el.modificatorId,
              availableMobile: el.availableMobile,
              availableDelivery: el.availableDelivery,
              isAdditional: el.isAdditional,
            })),
          ),
        );
      }
      setSkeletVisible(false);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setSkeletVisible(false);
    }
  };

  useEffect(() => {
    getProduct(id);
  }, [id]);

  return (
    <View
      patchModificatorAvailibility={patchModificatorAvailibility}
      saveChanges={saveChanges}
      positionExisting={positionExisting}
      setPositionExisting={setPositionExisting}
      toggleEdit={toggleEdit}
      setToggleEdit={setToggleEdit}
      serialNumber={serialNumber}
      setSerialNumber={setSerialNumber}
      getModificatorById={getModificatorById}
      getFileById={getFileById}
      getModificators={getModificators}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      getModificatorsSuggestions={getModificatorsSuggestions}
      getSubstitude={getSubstitude}
      CATEGORIES={CATEGORIES}
      loading={loading}
      setLoading={setLoading}
      ALLERGENS={ALLERGENS}
      langId={langId}
      productId={id}
      product={product}
      history={history}
      getProductById={getProductById}
      setSkeletVisible={setSkeletVisible}
      skeletVisible={skeletVisible}
      modificators={modificators}
    />
  );
};

export default Container;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { Modal, Button } from '../../../ui-kit';

const Advantages = (props) => {
  return (
    <Modal
      maxWidth="xs"
      title={props.title}
      open={props.isOpen}
      onClose={props.onClose}
      onBlur={props.onBlur}
    >
      <div className="advantages-selector">
        {props.advantages.map((el) => (
          <Button
            size="small"
            type="primary"
            label={el.value}
            key={el.id}
            outline={!props.selectedAdvantages?.find((item) => item === el.id)}
            onClick={() => {
              !props.selectedAdvantages?.find((item) => item === el.id)
                ? props.setSelectedAdvantages([...props.selectedAdvantages, el.id])
                : props.setSelectedAdvantages(
                    props.selectedAdvantages?.filter((item) => item !== el.id),
                  );
            }}
          />
        ))}
      </div>
    </Modal>
  );
};

Advantages.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
Advantages.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
};

export default Advantages;

/**
 * @prettier
 */

import React from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import { Paper } from '../../ui-kit';
import '../styles.scss';
import Loader from './loader';
import Block from '../../components/Block';
import Row from '../../components/Row';
import YandexDiscountFields from '../../components/modals/yandex-discount-fields';

const CouponViewTableBody = ({ items }) => {
  return (
    <>
      {items.yandexDiscountProducts?.map((el) => (
        <tr>
          <td>{el?.title}</td>
          <td>
            <div className="product">Продукт</div>
          </td>
        </tr>
      ))}
      {items.yandexDiscountCombos?.map((el) => (
        <tr>
          <td>{el?.title}</td>
          <td>
            <div className="product">Комбо</div>
          </td>
        </tr>
      ))}
      {items.yandexDiscountModificators?.map((el) => (
        <tr>
          <td>{el?.title}</td>
          <td>
            <div className="product">Модификатор</div>
          </td>
        </tr>
      ))}
    </>
  );
};

const View = (props) => {
  const { yandexDiscount } = props;

  return (
    <>
      <YandexDiscountFields
        id={yandexDiscount?.id}
        currentRestaurant={{
          title: STRINGS.screens.aggregators.qj,
          id: parseInt(props.restaurantId, 10),
          posId: parseInt(props.restaurantId, 10),
          current: true,
          number: 1,
        }}
        isEdit={true}
        type={props.type}
        langId={props.langId}
        TYPES={props.TYPES}
        loadedCoupon={yandexDiscount}
        getCouponById={props.getCouponById}
        backgroundColors={props.backgroundColors}
        languages={props.languages}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'editYandexDiscount'}
        title={STRINGS.screens.yandexDiscounts.editYandexDiscounts}
        saveChanges={props.saveChanges}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Head
        label={props.history?.location?.state?.title}
        button={{
          size: 'medium',
          icon: false,
          label: STRINGS.screens.yandexDiscounts.editYandexDiscounts,
          onClick: () => props.setShowModal('editYandexDiscount'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: `${STRINGS.screens.restaurants.title}`,
            link: '/restaurants',
          },
          {
            label: props.couponId,
          },
        ]}
        status={props.history?.location?.state?.status}
      />
      {props.skeletVisible ? (
        <Loader />
      ) : (
        <Paper>
          <div className="coupon-view-container">
            <div>
              <Block title={STRINGS.screens.discounts.productsList}>
                <table>
                  <thead>
                    <tr>
                      <th>{STRINGS.screens.yandexDiscounts.position}</th>
                      <th style={{ textAlign: 'center' }}>
                        {STRINGS.screens.yandexDiscounts.typeProduct}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <CouponViewTableBody items={yandexDiscount} />
                  </tbody>
                </table>
              </Block>
            </div>
            <div>
              <Block title={STRINGS.screens.modal.general}>
                <Row title={STRINGS.screens.yandexDiscounts.name} data={yandexDiscount?.name} />
                <Row title={STRINGS.screens.yandexDiscounts.type} data={yandexDiscount?.type} />
                {yandexDiscount?.type !== 'GIFT' && (
                  <Row
                    title={STRINGS.screens.yandexDiscounts.discount}
                    data={`${yandexDiscount?.discount} ${
                      yandexDiscount?.type === 'PERCENTAGE' ? '%' : 'тг'
                    }`}
                  />
                )}
                <Row
                  title={STRINGS.screens.yandexDiscounts.status}
                  data={yandexDiscount?.status ? 'Aктивный' : 'Неактивный'}
                />
              </Block>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};
export default View;

/**
 * @prettier
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import './styles.scss';
import { Icon } from '../';
import colors from '../../styles/colors';

/**
 * @component TextArea
 * @example
 * <TextArea
 *  onChange={() => {}}
 *  value="Some text",
 *  textareaType="primary",
 *  rows={12}
 *  label="label"
 * />
 */
const TextArea = (props) => {
  return useMemo(() => {
    const renderClassName = () => {
      let str = 'textarea-container';
      if (props.textareaType) {
        str += ` ${textareaConfigList[props.textareaType].basicClass}`;
      }
      return str;
    };
    return (
      <div
        className={`${renderClassName()} ${props.className}`}
        name={props.name}
        disabled={props.disabled}
      >
        {props.autosize ? (
          <TextareaAutosize
            defaultValue={props.defaultValue}
            value={props.value}
            aria-label="minimum height"
            onChange={(e) => props.onChange(e.target.value)}
            rowsmin={props.rowsmin}
            className={`${props.className} autosize`}
            placeholder={props.placeholder}
            disabled={props.disabled}
            maxLength={props.maxLength}
            onBlur={(e) => props.onBlur(e.target.value)}
            name={props.name}
          />
        ) : (
          <textarea
            defaultValue={props.defaultValue}
            className={props.className}
            value={props.value}
            aria-label="minimum height"
            onChange={(e) => props.onChange(e.target.value)}
            rowsmin={1}
            placeholder={props.placeholder}
            rows={props.rows}
            disabled={props.disabled}
            maxLength={props.maxLength}
            onBlur={(e) => props.onBlur(e.target.value)}
            name={props.name}
          />
        )}
        {props.isValid && (
          <div className="is-valid">
            <Icon name="check" width={14} height={14} color={colors.color_green._0} />
          </div>
        )}
        {!props.isValid && props.focus && (
          <div className="is-danger">
            <Icon name="danger" width={20} height={20} color={colors.color_red._0} />
          </div>
        )}
        <label>{props.label}</label>
      </div>
    );
  }, [props]);
};

/**
 * Styles
 */

const textareaConfigList = {
  default: {
    basicClass: 'default',
  },
  primary: {
    basicClass: 'primary',
  },
  secondary: {
    basicClass: 'secondary',
  },
  danger: {
    basicClass: 'danger',
  },
};

/**
 * Properties
 */

TextArea.propTypes = {
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  textareaType: PropTypes.oneOf(['default', 'primary', 'secondary', 'disabled', 'danger']),
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  autosize: PropTypes.bool,
  rowsmin: PropTypes.number,
  onBlur: PropTypes.func,
  isValid: PropTypes.bool,
  className: PropTypes.string,
};
TextArea.defaultProps = {
  defaultValue: '',
  onChange: () => {},
  onBlur: () => {},
  value: '',
  placeholder: ' ',
  textareaType: 'default',
  rows: 6,
  className: '',
  disabled: false,
  label: null,
  maxLength: 1000,
  autosize: false,
  rowsmin: 1,
  isValid: false,
};

export default TextArea;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import OrdersService from '../../../services/orders';
import ReadyOrdersView from './ready-orders-view';
import {
  init,
  listenNewOrders,
  listenCancelOrdersWithRemove,
  listenWoltChangedStatus,
} from '../../../services/socket';
import { aggregators } from '../../../constants/aggregators';

const ReadyOrdersContainer = () => {
  const user = useSelector((store) => store.user);

  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(null);
  const [sort, setSort] = useState(false);
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [ordersData, setOrdersData] = useState([]);

  useEffect(() => {
    getData();
  }, [sort]);

  useEffect(() => {
    const restaurantId = user.data.restaurantId;

    const socketConnect = init(restaurantId);

    listenNewOrders(socketConnect, restaurantId);

    listenCancelOrdersWithRemove(socketConnect, setOrdersData, restaurantId);

    listenWoltChangedStatus(socketConnect, setOrdersData, restaurantId, 'picked', setOpen);

    return () => socketConnect.disconnect();
  }, [sort]);

  const scrollRef = useBottomScrollListener(() => {
    if (!isLast) {
      getData();
    }
  });

  const getData = async () => {
    const orderParam = sort ? 'asc' : 'desc';

    try {
      if (page === 0) {
        setIsLoadingGlobal(true);
      }

      const res = await OrdersService.getDelivery(
        page + 1,
        20,
        'ready',
        1,
        orderParam,
        user.data.restaurantId,
      );
      setOrdersData((old) => [...old, ...res.data]);
      setIsLast(res.isLast);
      setPage((prev) => prev + 1);

      if (page === 0) {
        setIsLoadingGlobal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSort = () => {
    setOrdersData([]);
    setPage(0);
    setSort(!sort);
  };

  const handlerSend = async (order, status) => {
    try {
      setButtonLoading(true);
      const resp = await OrdersService.updateOrder(
        order.orderPatternId,
        { status: status },
        order?.aggregator?.key ? aggregators[order.aggregator.key] : 'mobile',
      );
      if (resp) {
        const orders = ordersData.filter((el) => order !== el);
        setOrdersData(orders);
        setOpen(false);
      }
      setButtonLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlerCancel = async (order) => {
    try {
      setButtonLoading(true);
      let resp;

      if (order?.orderOperator === 'MOBILE_APP') {
        resp = await OrdersService.cancelMobileOrder(order.orderPatternId);
      } else {
        resp = await OrdersService.cancelOrder(order.orderPatternId);
      }

      if (resp) {
        const orders = ordersData.filter((el) => order !== el);
        setOrdersData(orders);
        setOpen(false);
      }
      setButtonLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ReadyOrdersView
      open={open}
      order={order}
      sort={sort}
      ordersData={ordersData}
      scrollRef={scrollRef}
      isLoadingGlobal={isLoadingGlobal}
      isButtonLoading={isButtonLoading}
      handleSort={handleSort}
      getData={getData}
      setOpen={setOpen}
      setOrder={setOrder}
      handlerSend={handlerSend}
      handlerCancel={handlerCancel}
    />
  );
};

export default ReadyOrdersContainer;

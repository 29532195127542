/**
 * @prettier
 */

import React from 'react';

import { Confirm, Table, Button, Tooltip, Icon } from '../../ui-kit';
import Skelet from './skelet';
import { Head } from '../../components';

import STRINGS from '../../../localization';
import { translateEnabledStatus } from '../../utils/translate-status';

import './styles.scss';
import YandexDiscountFields from '../../components/modals/yandex-discount-fields';

const YandexDiscountsView = (props) => {
  return (
    <>
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.yandexDiscounts.delete}
        subtitleBold={props.selectedOrder?.id}
        buttons={[
          {
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteOrder(),
          },
          {
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
              setTimeout(() => props.setSelectedOrder({}), 100);
            },
          },
        ]}
      />
      <YandexDiscountFields
        currentRestaurant={{
          title: STRINGS.screens.aggregators.qj,
          id: parseInt(props.restaurantId, 10),
          posId: parseInt(props.restaurantId, 10),
          current: true,
          number: 1,
        }}
        type={props.type}
        langId={props.langId}
        TYPES={props.TYPES}
        getCouponById={props.getCouponById}
        backgroundColors={props.backgroundColors}
        languages={props.languages}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'addYandexDiscount'}
        title={STRINGS.screens.yandexDiscounts.addYandexDiscounts}
        saveChanges={props.addYandexDiscounts}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Head
        label={STRINGS.screens.yandexDiscounts.yandexDiscounts}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.yandexDiscounts.addYandexDiscounts,
          onClick: () => props.setShowModal('addYandexDiscount'),
        }}
        breadCrumbs={
          props.history.location.state?.breadCrumbs || [
            {
              label: STRINGS.components.sidebar.manage,
              link: '/',
            },
            {
              label: STRINGS.screens.orders.orders,
              link: '/orders-overview/',
            },
            {
              label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurantId}`,
              link: `/orders-overview/${props.restaurantId}`,
            },
          ]
        }
      />
      <div>
        <div className="summary_table">
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              loading={props.bottomLoading}
              head={() => (
                <tr className="summary_table_heading">
                  <th>{STRINGS.screens.yandexDiscounts.id}</th>
                  <th>{STRINGS.screens.yandexDiscounts.name}</th>
                  <th>{STRINGS.screens.yandexDiscounts.type}</th>
                  <th>{STRINGS.screens.yandexDiscounts.discount}</th>
                  <th>{STRINGS.screens.yandexDiscounts.status}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.orders?.data?.map((yandexDiscount) => (
                  <tr
                    key={yandexDiscount.id}
                    className="summary_table_body"
                    onClick={async (event) => {
                      event.stopPropagation();
                      props.history.push(`/yandex-discount/${yandexDiscount?.id}`, {
                        breadCrumbs: [
                          {
                            label: STRINGS.components.sidebar.manage,
                            link: '/',
                          },
                          {
                            label: STRINGS.screens.orders.orders,
                            link: '/orders-overview/',
                          },
                          {
                            label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurantId}`,
                            link: `/orders-overview/${props.restaurantId}`,
                          },
                        ],
                        restaurantId: props.restaurantId,
                      });
                    }}
                  >
                    <td>{yandexDiscount.id}</td>
                    <td>{yandexDiscount.name}</td>
                    <td>{yandexDiscount.type}</td>
                    <td>{`${yandexDiscount?.type !== 'GIFT' ? yandexDiscount?.discount : ''} ${
                      yandexDiscount?.type === 'PERCENTAGE'
                        ? '%'
                        : yandexDiscount?.type === 'FIXED'
                        ? 'тг'
                        : ''
                    }`}</td>
                    <td>
                      <Button
                        className="outline"
                        size="large"
                        label={translateEnabledStatus(yandexDiscount?.isEnabled).status}
                        type={translateEnabledStatus(yandexDiscount?.isEnabled)?.button}
                        typeButton="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.changeStatus(yandexDiscount.id, !yandexDiscount?.isEnabled);
                        }}
                      />
                    </td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setCurrentItemId(yandexDiscount?.id);
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default YandexDiscountsView;

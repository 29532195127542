/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Confirm, Table, Tooltip, Button, MainTabs } from '../../ui-kit';
import CouponFields from '../../components/modals/coupon-fields';
import Skelet from './skelet';
import { Head } from '../../components';
import { translateStatus } from '../../utils/translate-status';
import STRINGS from '../../../localization';
import NOPHOTO from '../../../assets/nophoto.png';
import { URL } from '../../../services/config';

import './styles.scss';

const CouponsView = (props) => {
  console.log(props, 'coupons');
  return (
    <>
      <CouponFields
        type={props.type}
        langId={props.langId}
        TYPES={props.TYPES}
        backgroundColors={props.backgroundColors}
        languages={props.languages}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'editCoupon'}
        selectedCoupon={props.selectedCoupon}
        title={STRINGS.screens.coupons.editCoupon}
        getCouponById={props.getCouponById}
        getFileById={props.getFileById}
        saveChanges={props.saveChanges}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <CouponFields
        type={props.type}
        langId={props.langId}
        TYPES={props.TYPES}
        getCouponById={props.getCouponById}
        backgroundColors={props.backgroundColors}
        languages={props.languages}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'addCoupon'}
        title={STRINGS.screens.coupons.addCoupon}
        saveChanges={props.addCoupon}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={`${STRINGS.screens.coupons.question}`}
        subtitleBold={props.selectedCoupon?.title}
        buttons={[
          {
            type: 'danger',
            label: STRINGS.screens.roles.modal.delete,
            onClick: props.handleDeleteCoupon,
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              setTimeout(() => props.setSelectedCoupon(), 100);
              props.setShowConfirm('');
            },
          },
        ]}
      />

      <Confirm
        visible={props.showGeneratedBaseCoupons}
        title={STRINGS.screens.coupons.codesEXT}
        subtitle={`${props.generatedCouponsCodes}`}
        isQuestion={false}
        buttons={[
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.cancel,
            outline: true,
            onClick: () => {
              props.setShowGeneratedBaseCoupons(false);
            },
          },
        ]}
      />
      <Head
        label={STRINGS.screens.coupons.coupons}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.coupons.addCoupon,
          onClick: () => props.setShowModal('addCoupon'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.coupons.coupons,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          <MainTabs active={props.type} data={props.TYPES} onClick={props.setType} />
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <>
              <Table
                head={() => (
                  <tr className="summary_table_heading">
                    <th />
                    <th>{STRINGS.screens.coupons.code}</th>
                    <th>{STRINGS.screens.coupons.title}</th>
                    <th>{STRINGS.screens.coupons.price}</th>
                    <th>{STRINGS.screens.coupons.discount}</th>
                    <th>{STRINGS.screens.coupons.status}</th>
                    <th />
                  </tr>
                )}
              >
                <>
                  {props.coupons?.data.map((item, index) => (
                    <tr
                      key={item.id}
                      className="summary_table_body"
                      onClick={async (event) => {
                        props.history.push(`coupons/${item.id}/`, {
                          title: item?.title,
                          status: item?.status,
                          type: item?.type,
                        });
                        event.stopPropagation();
                      }}
                    >
                      <td>
                        {item?.fileId ? (
                          <img
                            className="image"
                            style={{ width: '100px' }}
                            src={`${URL}files/${item?.fileId}`}
                          />
                        ) : (
                          <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                        )}
                      </td>
                      <td>{item.code}</td>
                      <td>{item.title}</td>
                      <td>
                        {item.price} {STRINGS.current.tg}
                      </td>
                      <td>
                        {item.discount} {item.discountType === 'value' ? STRINGS.current.tg : '%'}
                      </td>
                      <td>
                        <Button
                          className="outline"
                          size="large"
                          label={translateStatus(item?.status)?.status}
                          type={translateStatus(item?.status)?.button}
                          typeButton="submit"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.changeStatus({ id: item?.id, status: item?.status });
                          }}
                        />
                      </td>
                      <td className="summary_table_edit">
                        <div className="summary_table_edit_wrapper">
                          <Tooltip title={STRINGS.components.tooltips.delete}>
                            <div
                              className="summary_table_iconWrapper"
                              id={`removal${index}`}
                              onClick={async (e) => {
                                e.stopPropagation();
                                props.setSelectedCoupon({ id: item?.id, title: item?.title });
                                props.setShowConfirm('delete');
                              }}
                            >
                              <Icon name="trash-outline" width={24} height={24} />
                            </div>
                          </Tooltip>
                          <Tooltip title={STRINGS.components.tooltips.edit}>
                            <div
                              id={`edit${index}`}
                              className="summary_table_iconWrapper"
                              onClick={async (e) => {
                                e.stopPropagation();
                                props.setSelectedCoupon({ id: item?.id });
                                props.setShowModal('editCoupon');
                              }}
                            >
                              <Icon name="edit" width={24} height={24} />
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              </Table>
            </>
          )}
        </div>
      </div>
    </>
  );
};

CouponsView.propTypes = {
  coupons: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableCoupon: PropTypes.object,
  setEditableCoupon: PropTypes.func,
  saveChanges: PropTypes.func,
};

CouponsView.defaultProps = {
  coupons: null,
  showModal: '',
  setShowModal: () => {},
  editableCoupon: {},
  setEditableCoupon: () => {},
  saveChanges: () => {},
};

export default CouponsView;

import React from 'react';
import STRINGS from '../../../localization';
import './styles.scss';

const Row = ({title, data, indent, specific, arrayOfData}) => {
  const idToDayConvert = (id) => {
    switch(id) {
      case 1: return STRINGS.general.days.monday;
      case 2: return STRINGS.general.days.tuesday;
      case 3: return STRINGS.general.days.wednesday;
      case 4: return STRINGS.general.days.thursday;
      case 5: return STRINGS.general.days.friday;
      case 6: return STRINGS.general.days.saturday;
      case 7: return STRINGS.general.days.sunday;
    }
  }
  return (
  <div className="block-row">
    <span className="block-row-title" style={{lineHeight: `${indent}px`}}>{title}:&ensp;</span>
    {specific ? (specific) : (arrayOfData ? null : <span className="block-row-data">{data ?? STRINGS.screens.modal.noData}</span>)}
    {arrayOfData && (
      <div className='block-column-data'>
        {arrayOfData.map((item) => (
        <span className='block-column-item' key={item.day}>{`${idToDayConvert(item.day)}: ${item.openTime.slice(11,16)} - ${item.closeTime.slice(11,16)}`}</span>
        ))}
      </div>
    )}
  </div>)
};

Row.defaultProps = {
  indent: 34,
};

export default Row;

import React, { useEffect, useState } from 'react';

import RolesView from './roles-view';
import RolesService from '../../../services/roles';
import STRINGS from '../../../localization';

import { Toast } from '../../ui-kit';

const RolesContainer = () => {
  const [showConfirm, setShowConfirm] = useState('');

  const [roles, setRoles] = useState(null);

  const [selectedRole, setSelectedRole] = useState('');

  const [nameRole, setNameRole] = useState('');

  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [openRoles, setOpenRoles] = useState([]);

  const [showModal, setShowModal] = useState('');

  const [loading, setLoading] = useState();

  const [disabledContent, setDisabledContent] = useState(false);

  const [isLoadingButtonDelete, setIsLoadingButtonDelete] = useState(false);

  const [isLoadingButtonSave, setIsLoadingButtonSave] = useState(false);

  const [roleTitle, setRoleTitle] = useState('');

  const getRoles = async () => {
    setDisabledContent(true);
    try {
      const data = await RolesService.getRoles('ru');
      setRoles(data);
      setDisabledContent(false);
    } catch (error){
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setDisabledContent(false);
    }
    setSkeletVisible(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  const onCloseModal = () => {
    setShowModal('');
    setShowConfirm('');
    setSelectedRole('');
    setCheckedCheckboxes([]);
  };

  const formatDataToSendToServer = () => {
    return {
      title: nameRole ? nameRole : roles?.[selectedRole]?.title,
      permissionIds: checkedCheckboxes.map((item) => ({ permissionId: Number(item) })),
    };
  };

  const saveChanges = async () => {
    setIsLoadingButtonSave(true);
    try {
      await RolesService.updateRole(selectedRole, formatDataToSendToServer());
      getRoles();
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setIsLoadingButtonSave(false);
    onCloseModal();
  };

  const checkCheckbox = (id) => {
    setCheckedCheckboxes((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((item) => item !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const addNewRole = async () => {
    setIsLoadingButtonSave(true);
    try {
      await RolesService.addRole(formatDataToSendToServer());
      Toast.success(STRINGS.components.toasts.role.success);
      getRoles();
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setIsLoadingButtonSave(false);
    onCloseModal();
  };

  const openEditModal = (roleId) => {
    setShowModal('edit');
    setSelectedRole(roleId);

    const permissionsKeys = Object.keys(roles?.[roleId]?.rolePermissions);
    setCheckedCheckboxes(
      permissionsKeys
        .map((item) => {
          return Object.keys(roles?.[roleId]?.rolePermissions?.[item]);
        })
        .flat(),
    );
  };

  const handleDeleteRole = async () => {
    setIsLoadingButtonDelete(true);
    try {
      await RolesService.deleteRole(selectedRole);
      setIsLoadingButtonDelete(false);
      Toast.success(STRINGS.notify.success);
      getRoles();
    } catch (error) {
      setIsLoadingButtonDelete(false);
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    onCloseModal();
  };

  useEffect(() => {
    setRoleTitle((prevState) => {
      return roles?.[selectedRole]?.title ?? prevState;
    });
  }, [selectedRole]);

  const handleOpenRole = (id) => {
    setOpenRoles((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((item) => item !== id);
      }
      return [...prevState, id];
    });
  };

  return (
    <RolesView
      showModal={showModal}
      setShowModal={setShowModal}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      roles={roles}
      selectedRole={selectedRole}
      setSelectedRole={setSelectedRole}
      handleDeleteRole={handleDeleteRole}
      nameRole={nameRole}
      setNameRole={setNameRole}
      saveChanges={saveChanges}
      checkedCheckboxes={checkedCheckboxes}
      setCheckedCheckboxes={setCheckedCheckboxes}
      checkCheckbox={checkCheckbox}
      addNewRole={addNewRole}
      onCloseModal={onCloseModal}
      openEditModal={openEditModal}
      disabledContent={disabledContent}
      isLoadingButtonDelete={isLoadingButtonDelete}
      isLoadingButtonSave={isLoadingButtonSave}
      roleTitle={roleTitle}
      skeletVisible={skeletVisible}
      openRoles={openRoles}
      handleOpenRole={handleOpenRole}
    />
  );
};

export default RolesContainer;

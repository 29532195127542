import STRINGS from '../../localization/index';

export const translateRole = (role) => {
    switch (role) {
      case 'Admin':
        return STRINGS.general.roles.admin;
      case 'Manager':
        return STRINGS.general.roles.manager;
      case 'Content-manager':
        return STRINGS.general.roles.contentManager;
      default:
        return role;
    }
};

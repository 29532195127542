/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

export default class ModifiatorsService {
  static #API_ENDPOINTS = {
    modificator: 'modificators',
    definitionsAutocomplete: 'definitions/modificators/autocomplete',
    definitions: 'definitions/modificators',
    languages: 'languages/',
    autocomplete: 'modificators/substitute/autocomplete',
    categories: 'product-categories/modificators',
  };

  static API_SETTINGS = {
    size: 30,
    page: 1,
    langId: 1, //fix
    mainProductId: 1,
  };

  static async getModificators(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.modificator}?langId=${props.langId}&size=${props.size}&page=${
          props.page
        }&search=${props.search}${!!props.categoryId ? `&categoryId=${props.categoryId}` : ''}${
          props.sort ? props.sort : ''
        }`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Modificator data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.modificator}`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async getCategories(langId) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.categories}?langId=${langId}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Discount data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.goods}`, error);
      throw await error.response?.json();
    }
  }

  static async getModificatorById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.modificator}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('File data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.modificator}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getDefinitionById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.definitions}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Definition data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.definitions}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getDefinitionsAutocomplete(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.definitionsAutocomplete}?langId=${props.langId}&search=${
          props.search
        }&page=${props.page}$size=${props.size}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      debug.success('Definitions data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.definitionsAutocomplete}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteModificator(id) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.modificator}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.modificator}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateModificator(id, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.modificator}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.modificator}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.modificator}/${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(status),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.modificator}/${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateCategory(id, data) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.modificator}/${id}/category`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${
          this.#API_ENDPOINTS.modificator
        }/${id}/category`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async postDefinition(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.definitions}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Definition data retrieval result', request);
      const res = await request.json();
      return res.id;
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.definitions}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async putDefinition(id, data) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.definitions}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Definition data retrieval result', request);
      const res = await request.json();
      return res;
    } catch (error) {
      debug.error(
        `Failed with PUT request by path: ${URL}${this.#API_ENDPOINTS.definitions}${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import './styles.scss';

const ProductWishesReceipt = ({ mod = {}, isAdd = true }) => {
  return (
    <div className="receipt-cond-wrapper">
      <p>
        {isAdd ? 'Добавить' : 'Без'} {mod.name}
      </p>
      <div className="receipt-cond">
        <p className="receipt-count-pr">{mod.quantity}</p>
        <p>
          {mod.price} {STRINGS.components.receipt.tg}
        </p>
      </div>
    </div>
  );
};

export default ProductWishesReceipt;

/**
 * @prettier
 */

import React from 'react';

import { Paper } from '../../ui-kit';
import { Skeleton } from '../../ui-kit';
import './styles.scss';

const Skelet = () => (
  <Paper>
    <div className="coupon-view-container">
      <div>
        <div className="block">
          <Skeleton className="picture"></Skeleton>
        </div>
        {/* <div className="block">
          <div className="subtitle">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <table>
            <tbody>
              {[1, 2, 3].map(() => (
                <tr>
                  <td>
                    <Skeleton width="fit-content">
                      <span>************************</span>
                    </Skeleton>
                  </td>
                  <td>
                    <div className="number"></div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
      </div>
      <div>
        <div className="block">
          <div className="subtitle">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="row">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
        </div>
        <div className="block">
          <div className="subtitle">
            <Skeleton width="fit-content">
              <span>************************</span>
            </Skeleton>
          </div>
          <div className="data">
            <Skeleton width="fit-content">
              <span>**********************************************************************</span>
            </Skeleton>
          </div>
        </div>
      </div>
    </div>
  </Paper>
);

export default Skelet;

/**
 * @prettier
 */
import React, { useEffect, useState } from 'react';

import View from './view';
import UsersService from '../../../services/users';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import STRINGS from '../../../localization';
import { Toast } from '../../ui-kit';
import RolesService from '../../../services/roles';
import RestauransService from '../../../services/restaurants';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { languages } = useSelector((store) => store.stuff);
  const [loading, setLoading] = useState(false);
  const [skeletVisible, setSkeletVisible] = useState(false);
  const [showModal, setShowModal] = useState('');
  const [roles, setRoles] = useState({});
  const [role, setRole] = useState(null);
  const [manager, setManager] = useState({});
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantsQuery, setRestaurantsQuery] = useState([]);
  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    setManager(history.location.state.manager);
  }, [history.location.state]);

  const saveChanges = async (f) => {
    setLoading(true);
    let data = {
      firstName: f.firstName,
      lastName: f.lastName,
      email: f.email,
      phone: f.phone,
      address: f.address,
      roleId: f.roleId,
    };
    if (!!f.restaurantId) {
      Object.assign(data, { restaurantId: f.restaurantId });
    }
    try {
      const resp = await UsersService.updateManager({ id: f.id, data });
      setManager(resp);
      setShowModal('');
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getRoles = async () => {
    const data = await RolesService.getRoles('ru');
    setRoles(data);
  };

  useEffect(async () => {
    if (restaurantsQuery.length > 0) {
      await getRestaurants({
        page: 1,
        size: 5,
        langId: langId,
        search: restaurantsQuery,
        sort: '',
      });
    } else {
      setRestaurants([]);
    }
  }, [restaurantsQuery]);
  const getRestaurants = async (props) => {
    try {
      const resp = await RestauransService.getRestaurants(props);
      setRestaurants(resp.data.map((el) => ({ id: el.id, value: el.address })));
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  useEffect(async () => {
    getRoles();
  }, []);

  return (
    <View
      saveChanges={saveChanges}
      id={id}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      loading={loading}
      setLoading={setLoading}
      langId={langId}
      manager={manager}
      history={history}
      setSkeletVisible={setSkeletVisible}
      skeletVisible={skeletVisible}
      roles={roles}
      role={Object.values(roles).find((key, index) => index + 1 === role)?.title}
      restaurants={restaurants}
      restaurantsQuery={restaurantsQuery}
      setRestaurantsQuery={setRestaurantsQuery}
    />
  );
};

export default Container;

/**
 * @prettier
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import './styles.scss';
import colors from '../../styles/colors';

import { HeaderInput, SelectLanguage } from '..';
import { Icon } from '../../ui-kit';

import { debug } from '../../../services';
import CurrentUserThunks from '../../../store/current-user/thunks';

const Header = (props) => {
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const selectedLanguage = useSelector((store) => store.localization.currentLanguage?.iso3);

  const renderClassName = () => {
    return `language-image ${selectedLanguage}`;
  };

  const logOut = () => {
    dispatch(CurrentUserThunks.logout());
  };

  useEffect(() => {
    let delayDebounceFn = null;

    delayDebounceFn = setTimeout(async () => {
      debug.info('Header input text', searchValue);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return (
    <>
      <header id="--header-root">
        <div className="burger">
          <button onClick={() => props.onClick(!props.burgerState)}>
            <Icon name="burger" width={22} height={22} color={colors.color_gray._4} />
          </button>
        </div>
        <div className="content-header">
          <div>
            {props.searchVisible === true && (
              <div className="search">
                <HeaderInput value={searchValue} onChange={setSearchValue} />
              </div>
            )}
            <div />
          </div>
          <div className="actions">
            <button
              className="language"
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              <div className={renderClassName()} />
            </button>
            <button className="logout" onClick={logOut}>
              <Icon name="logout" width={22} height={22} color={colors.color_gray._4} />
            </button>
          </div>
        </div>
      </header>
      <SelectLanguage isOpen={openDialog} close={() => setOpenDialog(false)} />
    </>
  );
};

Header.propTypes = {
  onClick: PropTypes.func,
  burgerState: PropTypes.bool,
};
Header.defaultProps = {
  onClick: () => {},
  burgerState: false,
};

export default Header;

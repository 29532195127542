/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Loader from '../loader';
import { useSelector } from 'react-redux';
import {
  Button,
  Form,
  Drawer,
  CustomDropzone,
  DefinitionsCombo,
  Select,
  Toast,
  Switch,
} from '../../../ui-kit';

import STRINGS from '../../../../localization';
import ComboService from '../../../../services/combo';

const ComboFields = (props) => {
  const [unmodifiedCombo, setUnmodifiedCombo] = useState({});
  const { comboCategories } = useSelector((store) => store.stuff);
  const maxStep = 2;
  const [step, setStep] = useState(1);
  const [definitionId, setDefinitionId] = useState();
  const [definition, setDefinition] = useState({});
  const [categoryId, setCategoryId] = useState(null);
  const [posId, setPosId] = useState();
  const [posTitle, setPosTitle] = useState();
  const [posTitle2, setPosTitle2] = useState();
  const [status, setStatus] = useState('created');
  const [price, setPrice] = useState([]);
  const [file, setFile] = useState(null);
  const [glovoFile, setGlovoFile] = useState(null);
  const [yandexFile, setYandexFile] = useState(null);
  const [chocofoodFile, setChocofoodFile] = useState(null);
  const [woltFile, setWoltFile] = useState(null);
  const [isDeal, setIsDeal] = useState(false);

  const prepareFile = async (fileId) => {
    if (fileId) {
      let resp = await props.getFileById(fileId);
      resp = Object.assign(resp, { preview: URL.createObjectURL(resp), id: fileId });
      return resp;
    } else {
      return null;
    }
  };

  const mapData = async (combo) => {
    props.setLoading(true);
    try {
      setIsDeal(combo?.isDeal);
      setUnmodifiedCombo(combo);
      setCategoryId(combo?.categoryId);
      setPosId(combo?.posId);
      setStatus(combo?.status || 'created');
      setPrice(combo?.prices?.map((el) => el?.amount) || [0, 0]);
      setPosTitle(combo?.posTitle);
      setPosTitle2(combo?.posTitle2);
      setDefinitionId(combo?.definitionId);
      setFile(await prepareFile(combo?.fileId));
      setGlovoFile(await prepareFile(combo?.glovoFileId));
      setYandexFile(await prepareFile(combo?.yandexFileId));
      setChocofoodFile(await prepareFile(combo?.chocofoodFileId));
      setWoltFile(await prepareFile(combo?.woltFileId));
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const initialize = () => {
    if (!props.loadedCombo) {
      setStep(1);
      setUnmodifiedCombo({});
      setPosId();
      setCategoryId(null);
      setStatus('created');
      setPrice([]);
      setPosTitle();
      setPosTitle2();
      setDefinitionId();
      setFile(null);
      setGlovoFile(null);
      setYandexFile(null);
      setChocofoodFile(null);
      setWoltFile(null);
      setIsDeal(false);
    } else {
      setStep(1);
    }
  };

  const getComboById = async (id) => {
    props.setLoading(true);
    try {
      const resp = await ComboService.getComboById(id);
      await mapData(resp);
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
      Toast.error(STRINGS.notify.failure);
    }
  };

  useEffect(() => {
    if (props.isOpen === true) {
      getComboById(props.selectedCombo?.id);
    }
  }, [props?.selectedCombo]);

  useEffect(() => {
    if (props?.loadedCombo) {
      mapData(props?.loadedCombo);
    }
  }, [props?.loadedCombo]);

  useEffect(() => {
    if (!props.isOpen) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => props.onClose()}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges(posId, {
              isDeal,
              unmodifiedCombo,
              file,
              glovoFile,
              yandexFile,
              chocofoodFile,
              woltFile,
              definitionId: definitionId,
              definition: definition,
              categoryId: Number.parseInt(categoryId),
            });
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.goods.definition} *</span>
                    </div>
                    <DefinitionsCombo
                      className="container_fullwidth_one_first"
                      label={`${STRINGS.screens.goods.chooseDefinition}`}
                      definitionId={definitionId}
                      setDefinitionId={setDefinitionId}
                      definition={definition}
                      inputType="default"
                      setDefinition={setDefinition}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.combo.category} *</span>
                    </div>
                    <Select
                      className="container_fullwidth_one_first"
                      size="large"
                      label={STRINGS.screens.combo.category}
                      value={categoryId}
                      onChange={setCategoryId}
                      options={comboCategories?.map((el) => ({
                        label: el?.label,
                        key: el?.id,
                      }))}
                    />
                    {categoryId == '9' && (
                      <Switch
                        checked={isDeal}
                        onChange={setIsDeal}
                        label={STRINGS.screens.combo.qz}
                      />
                    )}
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.imgs}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone maxFiles={1} files={file} onChange={setFile} />
                      <span>{STRINGS.screens.combo.qq}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone maxFiles={1} files={glovoFile} onChange={setGlovoFile} />
                      <span>{STRINGS.screens.combo.qw}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone maxFiles={1} files={yandexFile} onChange={setYandexFile} />
                      <span>{STRINGS.screens.combo.qe}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone
                        maxFiles={1}
                        files={chocofoodFile}
                        onChange={setChocofoodFile}
                      />
                      <span>{STRINGS.screens.combo.qr}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone maxFiles={1} files={woltFile} onChange={setWoltFile} />
                      <span>{STRINGS.screens.combo.qt}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

ComboFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
ComboFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default ComboFields;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import STRINGS from '../../../localization';

const Status = (props) => {
  const renderClassName = (str) => {
    if (props.value) {
      str += ` ${props.value}`;
    }
    return str;
  };

  const getStatus = () => {
    if (props.value === 'active') {
      return 'Активный';
    }
    if (props.value === 'disabled') {
      return 'Отключен';
    }
    if (props.value === 'created') {
      return 'Создан';
    }
    if (props.value === 'failed') {
      return 'Ошибочный';
    }
    if (props.value === 'ready') {
      return 'Готов';
    }
    if (props.value === 'picked') {
      return 'Выдан';
    }
    if (props.value === 'canceled') {
      return 'Отменен';
    }
  };

  return (
    <div className="status-container">
      <div className={renderClassName('dot')} />
      <div className="status-wrapper">
        <span className={renderClassName('status')}>{getStatus()}</span>
      </div>
    </div>
  );
};

Status.propTypes = {
  type: PropTypes.string,
};
Status.defaultProps = {
  type: 'active',
};

export default Status;

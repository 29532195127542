import React, { useEffect, useState } from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import ProductsFields from '../../components/modals/products-fields';
import { Paper, Checkbox, Icon } from '../../ui-kit';
import '../styles.scss';
import Skelet from './skelet';
import GoodsService from '../../../services/goods';
import { URL } from '../../../services/config';
import NOPHOTO from '../../../assets/nophoto.png';
import { generatePrice } from '../../utils/generate-price';
import { useSelector } from 'react-redux';

const View = (props) => {
  const { product } = props;
  const { categories } = useSelector((store) => store.stuff);
  const [substituteTitles, setSubstituteTitles] = useState();

  const ModificatorTypes = [
    { label: STRINGS.screens.goods.qs, key: 0 },
    { label: STRINGS.screens.goods.qd, key: 1 },
  ];

  const [selectedModificatorType, setSelectedModificatorType] = useState(0);
  const getProductAllergens = () => {
    return Array.isArray(product?.allergens) && product?.allergens.length > 0
      ? props.ALLERGENS.map((el) => el?.title).join(', ')
      : STRINGS.screens.goods.noAllergens;
  };

  useEffect(() => {
    const getSubstituteTitles = async (idArray) => {
      const data = await Promise.all(
        idArray.map(async (id) => {
          const resp = await GoodsService.getProductTitle({ id, langId: props.langId });
          return resp.title;
        }),
      );
      setSubstituteTitles(data);
    };
    if (product?.substituteProducts) {
      getSubstituteTitles(product?.substituteProducts?.map((el) => el.substituteProductId));
    }
  }, [product?.substituteProducts]);

  return (
    <>
      <ProductsFields
        getModificatorById={props.getModificatorById}
        getModificators={props.getModificators}
        langId={props.langId}
        loading={props.loading}
        setLoading={props.setLoading}
        getSubstitude={props.getSubstitude}
        getFileById={props.getFileById}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.goods.editGood}
        loadedProduct={product}
        getProductById={props.getProductById}
        saveChanges={props.saveChanges}
        CATEGORIES={props.CATEGORIES}
        languages={props.languages}
      />
      <Head
        label={
          !props.skeletVisible ? product?.definition?.definitionData?.find((el) => el.languageId === props.langId)?.title || STRINGS.screens.modal.noTitle :
          STRINGS.screens.modal.qq
        }
        button={{
          size: 'medium',
          icon: false,
          label: STRINGS.screens.goods.editGood,
          onClick: () => props.setShowModal('edit'),
        }}
        status={props.history?.location?.state?.status}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.goods.goods,
            link: '/products',
          },
          {
            label: props.productId,
          },
        ]}
      />
      {props.skeletVisible || Object.keys(product).length === 0 ? (
        <Skelet />
      ) : (
        <Paper>
          <div className="product-view-container">
            <div>
              <div className="block">
                <div className="picture">
                  {props.product?.fileId ? (
                    <img className="image" src={`${URL}files/${props.product?.fileId}`} />
                  ) : (
                    <img src={NOPHOTO} />
                  )}
                </div>
              </div>
              <div className="block">
                <div className="aggregator-id-container">
                  <div className="text">
                    <span className="label">{STRINGS.screens.goods.qa}:</span>
                    <input
                      type="number"
                      className="input-id"
                      value={props.serialNumber}
                      onChange={async (e) => {
                        if (e.target.value.length <= 3) {
                          props.setSerialNumber(e.target.value);
                        }
                      }}
                      max="999"
                      data-invalid={props.positionExisting === true}
                      min="1"
                    />
                  </div>
                </div>
                <div>
                  {props.positionExisting === true &&
                    props.serialNumber !== product?.serialNumber && (
                      <span className="error">{STRINGS.screens.goods.qt}</span>
                    )}
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.goods.energyValueEXT}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.weight}</span>
                  <span>{product?.weight || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.energyValueKcal}</span>

                  <span>{product?.energyValueKcal || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.energy.proteins}</span>
                  <span>{product?.nutrients?.proteins || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.energy.fats}</span>
                  <span>{product?.nutrients?.fats || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.energy.carbs}</span>
                  <span>{product?.nutrients?.carbohydrates || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.energy.saturatedFats}</span>
                  <span>{product?.nutrients?.saturatedFat || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.energy.cellulose}</span>
                  <span>{product?.nutrients?.cellulose || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.energy.sugar}</span>
                  <span>{product?.nutrients?.sugar || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row-fullwidth">
                  <span>{STRINGS.screens.goods.energy.salt}</span>
                  <span>{product?.nutrients?.salt || STRINGS.screens.modal.noData}</span>
                </div>
              </div>
            </div>
            <div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.modal.general}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.id}:</span>
                  <span>{product?.posId}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.category}:</span>
                  <span>{categories.find((el) => el.key === product.categoryId).label}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.qq}:</span>
                  <span>
                    {generatePrice(product?.prices[0]?.amount || product?.prices[0]?.amountBig)}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.qw}:</span>
                  <span>
                    {generatePrice(product?.prices[1]?.amount || product?.prices[1]?.amountBig)}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.qe}:</span>
                  <span>
                    {generatePrice(product?.prices[2]?.amount || product?.prices[2]?.amountBig)}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.qr}:</span>
                  <span>
                    {generatePrice(product?.prices[3]?.amount || product?.prices[3]?.amountBig)}
                  </span>
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.goods.description}</span>
                </div>
                <div className="data">
                  <span>
                    {product?.definition?.definitionData?.find(
                      (el) => el.languageId === props.langId,
                    ).description || STRINGS.screens.modal.noData}
                  </span>
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.goods.allergensList}</span>
                </div>
                <div className="data">
                  <span>{getProductAllergens()}</span>
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.goods.substitute}</span>
                </div>
                <div className="data">
                  <span>
                    {substituteTitles?.join(', ') || STRINGS.screens.goods.noSubstitute}
                  </span>
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.ingredients.ingredients}</span>
                </div>
                <div className="data">
                  <div className="buttons">
                    {ModificatorTypes?.map((el) => (
                      <button
                        data-active={selectedModificatorType === el?.key}
                        onClick={() => setSelectedModificatorType(el?.key)}
                      >
                        {el?.label}
                      </button>
                    ))}
                  </div>
                  <div className="ingredients-table">
                    <table>
                      <thead>
                        <tr>
                          <th>{STRINGS.screens.goods.qy}</th>
                          <th>{STRINGS.screens.goods.qu}</th>
                          <th>{STRINGS.screens.goods.qo}</th>
                          <th>{STRINGS.screens.goods.qi}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.modificators
                          ?.filter((el) => el.isAdditional == selectedModificatorType)
                          .map((el) => (
                            <tr>
                              <td>
                                <span className="numbers">{el.id}</span>
                              </td>
                              <td>{el?.title}</td>
                              <td>
                                <Checkbox
                                  checked={el?.availableMobile === true}
                                  onChange={() => {
                                    props.patchModificatorAvailibility(
                                      {
                                        availableMobile: !el?.availableMobile,
                                        availableDelivery: el?.availableDelivery,
                                        productId: Number.parseInt(props?.productId),
                                        modificatorId: Number.parseInt(el?.id),
                                      },
                                      'availableMobile',
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Checkbox
                                  checked={el?.availableDelivery === true}
                                  onChange={() => {
                                    props.patchModificatorAvailibility(
                                      {
                                        availableMobile: el?.availableMobile,
                                        availableDelivery: !el?.availableDelivery,
                                        productId: Number.parseInt(props?.productId),
                                        modificatorId: Number.parseInt(el?.id),
                                      },
                                      'availableDelivery',
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      )}
    </>

  );
};
export default View;

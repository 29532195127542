/**
 * @prettier
 */

const initialState = {
  currentLanguage: '',
};

const LOCALIZATION_SET_CURRENT_LANGUAGE = 'LOCALIZATION_SET_CURRENT_LANGUAGE';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCALIZATION_SET_CURRENT_LANGUAGE:
      return {
        currentLanguage: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

export const localizationSetCurrentLanguage = (payload) => ({
  type: LOCALIZATION_SET_CURRENT_LANGUAGE,
  payload,
});

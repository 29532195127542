/**
 * @prettier
 */

import React from 'react';

import { Icon, Confirm, Table, Tooltip, Button } from '../../ui-kit';
import PunchcardFields from '../../components/modals/punchcard-fields';
import Skelet from './skelet';
import { Head } from '../../components';
import NOPHOTO from '../../../assets/nophoto.png';
import { translateStatus } from '../../../main/utils/translate-status';
import { URL } from '../../../services/config';
import STRINGS from '../../../localization';

import './styles.scss';

const View = (props) => {
  return (
    <>
      <PunchcardFields
        languages={props.languages}
        langId={props.langId}
        backgroundColors={props.backgroundColors}
        getFileById={props.getFileById}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'editPunchcard'}
        title={STRINGS.screens.punchcards.editPunchcard}
        selectedPunchcard={props.selectedPunchcard}
        getPunchcardById={props.getPunchcardById}
        loading={props.loading}
        setLoading={props.setLoading}
        saveChanges={props.saveChanges}
      />
      <PunchcardFields
        languages={props.languages}
        langId={props.langId}
        backgroundColors={props.backgroundColors}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'addPunchcard'}
        title={STRINGS.screens.punchcards.addPunchcard}
        loading={props.loading}
        setLoading={props.setLoading}
        saveChanges={props.addPunchcard}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.punchcards.question}
        subtitleBold={`${props.selectedPunchcard?.title}`}
        buttons={[
          {
            type: 'danger',
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeletePunchcard(),
          },
          {
            type: 'default',
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
              setTimeout(() => props.setSelectedPunchcard(), 100);
            },
          },
        ]}
      />

      <Head
        label={STRINGS.screens.punchcards.punchcards}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.punchcards.addPunchcard,
          onClick: () => props.setShowModal('addPunchcard'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.punchcards.punchcards,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              head={() => (
                <tr className="summary_table_heading">
                  <th />
                  <th>{STRINGS.screens.punchcards.id}</th>
                  <th>{STRINGS.screens.punchcards.title}</th>
                  <th>{STRINGS.screens.punchcards.rewardIn}</th>
                  <th>{STRINGS.screens.punchcards.numberOfPurchases}</th>
                  <th>{STRINGS.screens.punchcards.status}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.punchcards?.data?.map((item) => (
                  <tr
                    key={item.id}
                    className="summary_table_body"
                    onClick={async (event) => {
                      event.stopPropagation();
                      props.history.push(`punchcards/${item.id}/`, {
                        title: item?.title,
                        status: item?.status,
                      });
                    }}
                  >
                    <td>
                      {item?.fileId ? (
                        <img
                          className="image"
                          style={{ width: '100px' }}
                          src={`${URL}files/${item?.fileId}`}
                        />
                      ) : (
                        <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                      )}
                    </td>
                    <td>{item?.id}</td>
                    <td>{item?.title}</td>
                    <td>
                      {item?.rewardType === 'ITEMS'
                        ? STRINGS.screens.punchcards.items
                        : STRINGS.screens.punchcards.points}
                    </td>
                    <td>{item?.numberOfPurchases}</td>
                    <td>
                      <Button
                        className="outline"
                        size="large"
                        label={translateStatus(item?.status).status}
                        type={translateStatus(item?.status).button}
                        typeButton="submit"
                        onClick={(e) => {
                          e.stopPropagation();
                          props.changeStatus({ id: item?.id, status: item?.status });
                        }}
                      />
                    </td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setShowConfirm('delete');
                              props.setSelectedPunchcard({ id: item?.id, title: item?.title });
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.edit}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setShowModal('editPunchcard');
                              props.setSelectedPunchcard({ id: item?.id });
                            }}
                          >
                            <Icon name="edit" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

View.propTypes = {};

View.defaultProps = {};

export default View;

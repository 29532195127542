/** @format */

import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

export default function AttributeGroup(props) {
  const { menu, agId } = props;

  const ag = menu.getAttributeGroup(agId);

  return (
    <List
      sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {ag?.name || 'NOT FOUND'}
        </ListSubheader>
      }
    >
      {ag?.attributes.map((attributeId) => {
        const attribute = menu.getAttribute(attributeId);
        return (
          <ListItemButton key={attributeId}>
            <ListItemText
              primary={`${attribute.name} / ${attribute.price_impact} тг / ${
                attribute.available ? '' : 'НЕДОСТУПЕН'
              }`}
            />
          </ListItemButton>
        );
      })}
    </List>
  );
}

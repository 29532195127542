/**
 * @prettier
 */

import React, { Fragment } from 'react';
import STRINGS from '../../../localization';
import { Table, Icon, Skeleton, Button } from '../../ui-kit';

import './styles.scss';

const SkeletRestaurants = () => (
  <div className="summary_table">
    <Table
      head={() => (
        <tr className="summary_table_heading">
          <th>
            <Skeleton width="fit-content">
              <span>******************</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>************</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>*************</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>*************</span>
            </Skeleton>
          </th>
          <th>
            <Skeleton width="fit-content">
              <span>*************</span>
            </Skeleton>
          </th>
          <th />
        </tr>
      )}
    >
      <>
        {new Array(30).fill(null).map((item, index) => (
          <Fragment key={index.toString()}>
            <tr className="summary_table_body">
              <td>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>*********************************************</span>
                </Skeleton>
              </td>
              <td>
                <Skeleton width="fit-content">
                  <span>***********</span>
                </Skeleton>
              </td>
              <td>
                <td>
                  <Skeleton width="fit-content">
                    <Button className="outline" size="large" label="Вшыфид" typeButton="submit" />
                  </Skeleton>
                </td>
              </td>
              <td className="summary_table_edit">
                <div className="summary_table_edit_wrapper">
                  <Skeleton width="fit-content" className="roles_table_iconWrapper">
                    <div className="roles_table_iconWrapper">
                      <Icon name="trash-outline" width={24} height={24} />
                    </div>
                  </Skeleton>
                  <Skeleton width="fit-content" className="roles_table_iconWrapper">
                    <div className="roles_table_iconWrapper">
                      <Icon name="edit-users" width={24} height={24} />
                    </div>
                  </Skeleton>
                </div>
              </td>
            </tr>
          </Fragment>
        ))}
      </>
    </Table>
  </div>
);

export default SkeletRestaurants;

import React, { useEffect, useState } from 'react';

import CitiesView from './cities-view';
import STRINGS from '../../../localization';
import { Toast } from '../../ui-kit';
import { useSelector } from 'react-redux';
import CitiesService from '../../../services/cities';

const CitiesContainer = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { languages } = useSelector((store) => store.stuff);

  const [showModal, setShowModal] = useState('');

  const [selectedCity, setSelectedCity] = useState({});

  const [showConfirm, setShowConfirm] = useState('');

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [deletableCity, setDeletableCity] = useState({});

  const [cities, setCities] = useState([]);

  const [restaurants, setRestaurants] = useState([]);

  const [loading, setLoading] = useState([]);

  const addCity = async (f) => {
    setLoading(true);
    const dataToSend = {
      lat: Number.parseFloat(f.lat),
      lng: Number.parseFloat(f.lng),
      cityData: f.cityData,
    };
    try {
      await CitiesService.addCity(dataToSend);
      getCities();
      Toast.success(STRINGS.notify.qt);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
    setSelectedCity({});
    setLoading(false);
  };

  const editCity = async (f) => {
    setLoading(true);
    const dataToSend = {
      lat: Number.parseFloat(f.lat),
      lng: Number.parseFloat(f.lng),
      cityData: f.cityData,
    };
    try {
      await CitiesService.updateCity(f.id, dataToSend);
      setShowModal('');
      setSelectedCity({});
      getCities();
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal(false);
    setLoading(false);
  };

  const handleDeleteCity = async () => {
    try {
      setShowConfirm('');
      setCities(cities.filter((el) => el.id !== deletableCity.id));
      await CitiesService.deleteCity(deletableCity.id);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.qr);
      }
    }
  };

  const getCities = async () => {
    setSkeletVisible(true);
    try {
      const resp = await CitiesService.getCities();
      setCities(resp);
      setSkeletVisible(false);
    } catch (err) {
      setSkeletVisible(false);
    }
  };

  const getCityById = async (id) => {
    try {
      const resp = await CitiesService.getCityById(id);
      return resp;
    } catch (err) {}
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <CitiesView
      loading={loading}
      setLoading={setLoading}
      langId={langId}
      getCityById={getCityById}
      restaurants={restaurants?.data}
      cities={cities}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      handleDeleteCity={handleDeleteCity}
      selectedCity={selectedCity}
      setSelectedCity={setSelectedCity}
      deletableCity={deletableCity}
      setDeletableCity={setDeletableCity}
      saveChanges={editCity}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      addCity={addCity}
      skeletVisible={skeletVisible}
    />
  );
};

export default CitiesContainer;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import STRINGS from '../../../localization';
import './styles.scss';

const AggregatorButton = (props) => {
  return (
    <div
      className="container-aggregator"
      style={props.opened ? {} : { backgroundColor: '#CB3636' }}
    >
      {props.title && (
        <div className="aggregator-title">
          <p className="agg-name">{props.title}</p>
          <span className="dot-yellow" />
          <p className="agg-info">
            {props.opened
              ? STRINGS.components.aggregatorButton.openInfo
              : STRINGS.components.aggregatorButton.closedInfo}
          </p>
        </div>
      )}
      {/*<div className="wrapper">*/}
      {/*  <button*/}
      {/*    onClick={props.callbackOpen}*/}
      {/*    disabled={props.opened}*/}
      {/*    className={props.opened ? 'button-aggregator-disabled' : 'button-aggregator-on'}*/}
      {/*    type={props.typeButton}*/}
      {/*  >*/}
      {/*    {STRINGS.components.aggregatorButton.open}*/}
      {/*  </button>*/}
      {/*  <button*/}
      {/*    onClick={props.callbackClose}*/}
      {/*    disabled={!props.opened}*/}
      {/*    className={props.opened ? 'button-aggregator-off' : 'button-aggregator-disabled'}*/}
      {/*    type={props.typeButton}*/}
      {/*  >*/}
      {/*    {STRINGS.components.aggregatorButton.close}*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  );
};

/**
 * Properties
 */

AggregatorButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'disabled']),
  outline: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,

  icon: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    side: PropTypes.oneOf(['left', 'right']),
  }),

  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  typeButton: PropTypes.string,
  className: PropTypes.string,
};

AggregatorButton.defaultProps = {
  size: 'large',
  type: 'primary',
  outline: false,
  label: null,
  onClick: () => {},
  icon: {
    name: null,
    side: 'left',
  },
  loading: false,
  disabled: false,
  typeButton: 'button',
  className: '',
};

export default AggregatorButton;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

/**
 * Users Service functionality
 * @getDiscountData
 * @updateDiscount
 * @addDiscount
 */
export default class DiscountsService {
  static #API_ENDPOINTS = {
    discounts: 'promotions',
    languages: 'languages/',
    mindbox: 'promotions/mindbox',
    importStocks: 'mindbox/import-stocks',
  };

  static API_SETTINGS = {
    size: 30,
    page: 1,
    langId: 1, //fix
  };

  static async getDiscounts(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.discounts}/crm?langId=${props.langId}&size=${
          props.size
        }&page=${props.page}&search=${props.search}${props.sort}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Discount data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.discounts}`, error);
      throw await error.response?.json();
    }
  }
  static async getDiscountsMindbox() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.mindbox}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Discount data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.mindbox}`, error);
      throw await error.response?.json();
    }
  }
  static async importStocks() {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.importStocks}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          sinceDateTimeUtc: '2019-03-27T10:01:10.817Z',
          includeNonActive: true,
          includeNonExecutable: true,
        }),
      });

      debug.success('Discount data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.importStocks}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateDiscount({ id: id, data: data }) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.discounts}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Discount data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.discount}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addDiscount(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.discounts}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Discount data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.discounts}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getDiscountById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.discounts}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('File data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.goods}`, error);
      throw await error.response?.json();
    }
  }
  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.discounts}/${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(status),
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.discounts}/${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteDiscount({ id: id }) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.discounts}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Product data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.discounts}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

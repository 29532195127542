/**
 * @prettier
 */

import React from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import { Icon } from '../../../ui-kit';
import { Button, Paper, CustomTime } from '../../ui-kit';
import RestaurantFields from '../../components/modals/restaurant-fields';
import '../styles.scss';
import Skelet from './skelet';
import { days } from '../../../constants/week';
import { useSelector } from 'react-redux';
import { SERVICE_VALUES } from '../../../constants/service-types';
import { getBeautyTimeString } from '../../utils/datetime';
import RestaurantsService from '../../../services/restaurants';

const RestaurantView = (props) => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { stopListAccess } = useSelector((store) => store.user.data);
  const { aggregators } = useSelector((store) => store.stuff);
  const rest = props.restaurant;
  const findTime = (serviceTypeId, day) => {
    const { workingHours } = rest;
    if (
      workingHours?.filter((i) => i.serviceTypeId === serviceTypeId).find((j) => j?.day === day)
        ?.operatingMode === 'DAY_AND_NIGHT'
    ) {
      return STRINGS.general.days.clockRound;
    } else {
      return `${getBeautyTimeString(
        workingHours?.filter((i) => i.serviceTypeId === serviceTypeId).find((j) => j?.day === day)
          ?.openTime
          ? new Date(
              workingHours
                ?.filter((i) => i.serviceTypeId === serviceTypeId)
                .find((j) => j?.day === day)?.openTime,
            )
          : new Date(0, 0, 0, 0, 0),
      )} - ${getBeautyTimeString(
        workingHours?.filter((i) => i.serviceTypeId === serviceTypeId).find((j) => j?.day === day)
          ?.closeTime
          ? new Date(
              workingHours
                ?.filter((i) => i.serviceTypeId === serviceTypeId)
                .find((j) => j?.day === day)?.closeTime,
            )
          : new Date(0, 0, 0, 0, 0),
      )}`;
    }
  };
  return (
    <>
      <RestaurantFields
        modalType={props.showModal}
        loadedRestaurant={props.restaurant}
        loading={props.loading}
        setLoading={props.setLoading}
        languages={props.languages}
        onClose={() => props.setShowModal('')}
        getRestaurantById={props.getRestaurantById}
        isOpen={props.showModal === 'edit'}
        advantages={props.advantages}
        setAdvantages={props.setAdvantages}
        title={STRINGS.screens.restaurants.editRestaurant}
        saveChanges={props.saveChanges}
        cities={props.cities}
        cityQuery={props.cityQuery}
        setCityQuery={props.setCityQuery}
        city={props.city}
        setCity={props.setCity}
        cityId={props.cityId}
        setCityId={props.setCityId}
      />
      <>
        <Head
          label={
            props.restaurant?.restaurantData?.find((i) => i.languageId === langId)?.title ||
            props.history?.location?.state?.title ||
            STRINGS.screens.modal.noTitle
          }
          button={{
            size: 'medium',
            icon: false,
            disabled: stopListAccess,
            label: STRINGS.screens.restaurants.editRestaurant,
            onClick: () => props.setShowModal('edit'),
          }}
          status={props.history?.location?.state?.status}
          breadCrumbs={[
            {
              label: STRINGS.components.sidebar.manage,
              link: '/',
            },
            {
              label: STRINGS.screens.restaurants.title,
              link: '/restaurants',
            },
            {
              label: `Ресторан №${props.restaurant?.id || ''}`,
            },
          ]}
        />
        {props.skeletVisible || !props.restaurant ? (
          <Skelet />
        ) : (
          <Paper>
            <div className="restaurant-view-container">
              <div>
                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.restaurants.sections}</span>
                  </div>
                  {!stopListAccess && (
                    <div
                      className="menu"
                      onClick={async () => {
                        props.history.push(`/restaurants/${props.id}/menu/`);
                        await props.getRestaurantById(props.id);
                      }}
                    >
                      <div className="picture">
                        <Icon name="restaurant-menu" />
                      </div>
                      <h2>{STRINGS.screens.restaurants.menu}</h2>
                      <div className="icon">
                        <Icon name="arrow-right" width={26} height={26} />
                      </div>
                    </div>
                  )}

                  {!stopListAccess && (
                    <div
                      className="menu"
                      onClick={async () => {
                        props.history.push(`/restaurants/${props.id}/aggregators/`);
                      }}
                    >
                      <div className="picture">
                        <Icon
                          name="restaurant-aggregators"
                          width="32"
                          height="32"
                          color="#888888"
                        />
                      </div>
                      <h2>{STRINGS.screens.restaurants.aggregators}</h2>
                      <div className="icon">
                        <Icon name="arrow-right" width={26} height={26} />
                      </div>
                    </div>
                  )}
                  {/* <div
                    className="menu"
                    // onClick={async () => {
                    //   props.history.push(`/aggregators-kkm-report`);
                    // }}
                  >
                    <div className="picture">
                      <Icon name="restaurant-orders" width="36" height="36" color="#888888" />
                    </div>
                    <h2>{STRINGS.screens.restaurants.aggregatorsReport}</h2>
                    <div className="icon">
                      <Icon name="arrow-right" width={26} height={26} />
                    </div>
                  </div> */}
                  <div
                    className="menu"
                    onClick={async () => {
                      props.history.push(`/restaurants/${props.id}/stop-list/`, {
                        breadCrumbs: [
                          {
                            label: STRINGS.components.sidebar.manage,
                            link: '/',
                          },
                          {
                            label: STRINGS.screens.restaurants.title,
                            link: '/restaurants',
                          },
                          {
                            label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurant?.id}`,
                            link: `/restaurants/${props.id}`,
                          },
                          { label: STRINGS.screens.stoplists.stoplist },
                        ],
                      });
                    }}
                  >
                    <div className="picture">
                      <Icon name="restaurant-stoplists" width="32" height="32" color="#888888" />
                    </div>
                    <h2>{STRINGS.screens.restaurants.stoplist}</h2>
                    <div className="icon">
                      <Icon name="arrow-right" width={26} height={26} />
                    </div>
                  </div>
                  {!stopListAccess && (
                    <div
                      className="menu"
                      onClick={async () => {
                        props.history.push(`/restaurants/${props.id}/orders/`, {
                          breadCrumbs: [
                            {
                              label: STRINGS.components.sidebar.manage,
                              link: '/',
                            },
                            {
                              label: STRINGS.screens.restaurants.title,
                              link: '/restaurants',
                            },
                            {
                              label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurant?.id}`,
                              link: `/restaurants/${props.id}`,
                            },
                            { label: STRINGS.screens.orders.orders },
                          ],
                        });
                      }}
                    >
                      <div className="picture">
                        <Icon name="restaurant-orders" width="36" height="36" color="#888888" />
                      </div>
                      <h2>{STRINGS.screens.restaurants.orders}</h2>
                      <div className="icon">
                        <Icon name="arrow-right" width={26} height={26} />
                      </div>
                    </div>
                  )}
                  {!stopListAccess && (
                    <div
                      className="menu"
                      onClick={async () => {
                        props.history.push(`/restaurants/${props.id}/yandex-discounts/`, {
                          breadCrumbs: [
                            {
                              label: STRINGS.components.sidebar.manage,
                              link: '/',
                            },
                            {
                              label: STRINGS.screens.restaurants.title,
                              link: '/restaurants',
                            },
                            {
                              label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurant?.id}`,
                              link: `/restaurants/${props.id}`,
                            },
                            { label: STRINGS.screens.yandexDiscounts.yandexDiscounts },
                          ],
                        });
                      }}
                    >
                      <div className="picture">
                        <Icon
                          name="restaurant-yandex-discounts"
                          width="36"
                          height="36"
                          color="#888888"
                        />
                      </div>
                      <h2>{STRINGS.screens.restaurants.yandexDiscounts}</h2>
                      <div className="icon">
                        <Icon name="arrow-right" width={26} height={26} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.restaurants.tags}</span>
                  </div>
                  {props.advantages ? (
                    <div className="advantages">
                      {rest?.restaurantAdvantages
                        .map((el) => el.advantageId)
                        .map((el) => (
                          <div className="advantage" style={{ pointerEvents: 'none' }}>
                            <Button
                              size="small"
                              type="primary"
                              label={props.advantages?.find((i) => i.id === el)?.value}
                              key={el}
                              outline={true}
                            />
                          </div>
                        ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="block">
                  <div className="working-hours">
                    <table>
                      <thead>
                        <tr>
                          <th>{STRINGS.screens.restaurants.workingHours}</th>
                          {SERVICE_VALUES.map((el) => (
                            <th>{el.label}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {days.map((el) => (
                          <tr>
                            <td>{el.label}</td>
                            <td>{findTime(1, el?.key)}</td>
                            <td>{findTime(2, el?.key)}</td>
                            <td>{findTime(3, el?.key)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.restaurants.location}</span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.restaurants.city}:</span>
                    <span>
                      {rest?.address?.city?.cityData[0]?.name || STRINGS.screens.modal.noData}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.restaurants.address}:</span>
                    <span>
                      {rest?.address?.addressData?.reduce(
                        (acc, el) => [...acc, el?.title],
                        [''],
                      )[1] || STRINGS.screens.modal.noData}
                    </span>
                  </div>

                  <div className="row">
                    <div className="icon">
                      <Icon name="location" width="17" height="17" color="black" />
                    </div>
                    <span className="info">{`${rest?.address?.lng} ${rest?.address?.lat}`}</span>
                  </div>
                </div>
                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.contact}</span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.restaurants.phone}:</span>
                    <span>{rest?.phone || STRINGS.screens.modal.noData}</span>
                  </div>
                </div>
                <div className="block">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.other}</span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.restaurants.packageType}:</span>
                    <span>
                      {props.packages?.find((el) => el?.posId === rest?.packageId)?.posTitle ||
                        STRINGS.screens.modal.noData}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.modal.tiers}:</span>
                    <span>
                      {props.tiers?.find((el) => el?.tier === rest?.tier)?.title ||
                        STRINGS.screens.modal.noData}
                    </span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.modal.ipAddress}:</span>
                    <span>{rest?.ipAddress || STRINGS.screens.modal.noData}</span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.modal.printerName}:</span>
                    <span>{rest?.printerName || STRINGS.screens.modal.noData}</span>
                  </div>
                  <div className="row">
                    <span>{STRINGS.screens.modal.timezone}:</span>
                    <span>{rest?.timeZone || STRINGS.screens.modal.noData}</span>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        )}
      </>
    </>
  );
};
export default RestaurantView;

/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../../ui-kit';
import { Confirm, Table, Tooltip, SecondaryTabs } from '../../ui-kit';
import Skelet from './skelet';
import { Head } from '../../components';
import ProductShortView from '../../components/modals/product-short-view';
import ComboShortView from '../../components/modals/combo-short-view';
import { URL } from '../../../services/config';
import { menuCategories } from '../../../constants/categories';
import NOPHOTO from '../../../assets/nophoto.png';
import { generatePrice } from '../../utils/generate-price';
import STRINGS from '../../../localization';
import './styles.scss';
import RestaurantMenu from '../../components/modals/RestaurantMenu';

const ProductsView = (props) => {
  return (
    <>
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={STRINGS.screens.goods.deleteQuestion}
        subtitleBold={props.selectedProduct?.title}
        buttons={[
          {
            label: STRINGS.screens.roles.modal.delete,
            onClick: props.handleDeleteProduct,
          },
          {
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
            },
          },
        ]}
      />
      <RestaurantMenu
        isOpen={props.showModal === 'add'}
        restaurantId={props.restaurantId}
        showModal={props.showModal}
        onClose={() => props.setShowModal('')}
        type={props.selectedMenuCategory}
      />
      <ProductShortView
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'products-view'}
        selectedProduct={props.selectedProduct}
        title={props.selectedProduct?.title}
      />
      <ComboShortView
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'combo-view'}
        selectedProduct={props.selectedProduct}
        title={props.selectedProduct?.title}
      />

      <Head
        label={STRINGS.screens.menu.menu}
        button={{
          icon: false,
          label: STRINGS.screens.menu.editMenu,
          onClick: () => {
            props.setShowModal('add');
          },
        }}
        filter={true}
        selectedFilter={props.selectedCategory}
        setSelectedFilter={props.setSelectedCategory}
        options={props.CATEGORIES}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.restaurants.title,
            link: '/restaurants',
          },
          {
            label: `${STRINGS.screens.restaurants.restaurant} №${props.restaurantId}`,
            link: `/restaurants/${props.restaurantId}/`,
          },
          {
            label: `${STRINGS.screens.menu.menu}`,
            link: `/restaurants/${props.restaurantId}/`,
          },
        ]}
      />
      <div>
        <div className="summary_table">
          <div className="toolbar">
            <SecondaryTabs
              data={menuCategories}
              selected={props.selectedMenuCategory}
              setSelected={props.setSelectedMenuCategory}
            />
          </div>
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              loading={props.bottomLoading}
              head={() => (
                <tr className="summary_table_heading">
                  <th />
                  <th>{STRINGS.screens.goods.id}</th>
                  <th>{STRINGS.screens.goods.title}</th>
                  <th>{STRINGS.screens.goods.prices.default}</th>
                  <th>{STRINGS.screens.goods.prices.delivery}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.existingMenu?.data?.map((item) => (
                  <tr key={item.posId} className="summary_table_body unselectable">
                    <td>
                      {item?.fileId ? (
                        <img
                          className="image"
                          style={{ width: '100px' }}
                          src={`${URL}files/${item?.fileId}`}
                        />
                      ) : (
                        <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                      )}
                    </td>
                    <td>{item.posId}</td>
                    <td>{item.posTitle || item.title}</td>
                    <td>{generatePrice(item.prices.default)}</td>
                    <td>{generatePrice(item.prices.delivery)}</td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.open}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={() => {
                              props.setSelectedProduct({
                                id: item?.posId,
                                title: item?.posTitle,
                              });
                              props.setShowModal(`${props.selectedMenuCategory}-view`);
                            }}
                          >
                            <Icon name="visibility" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={() => {
                              props.setSelectedProduct({
                                id: item?.posId,
                                title: item?.posTitle,
                              });
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

ProductsView.propTypes = {
  languages: PropTypes.array,
  goods: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableProduct: PropTypes.object,
  setEditableProduct: PropTypes.func,
  saveChanges: PropTypes.func,
};

ProductsView.defaultProps = {
  languages: [],
  goods: null,
  showModal: '',
  setShowModal: () => {},
  editableProduct: {},
  setEditableProduct: () => {},
  saveChanges: () => {},
};

export default ProductsView;

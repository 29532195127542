/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import { configureStatus } from '../../../main/utils/configure-status';
import View from './view';
import STRINGS from '../../../localization/index';
import MainService from '../../../services/main';
import { useHistory } from 'react-router-dom';
import { Toast } from '../../ui-kit';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useSelector } from 'react-redux';

import PunchcardsService from '../../../services/punchcards';

const PunchcardsContainer = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [isLast, setIsLast] = useState(false);
  const [bottomLoading, setBottomLoading] = useState(false);
  const { languages, backgroundColors } = useSelector((store) => store.stuff);
  const [loading, setLoading] = useState(false);
  const [punchcards, setPunchcards] = useState();
  const [showModal, setShowModal] = useState('');
  const [selectedPunchcard, setSelectedPunchcard] = useState({});
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(true);

  //edit Punchcard
  const saveChanges = async (f) => {
    setLoading(true);
    setShowModal('');

    let dataToSend = {};

    if (!!f?.couponFileId && f?.couponBackgroundColorId) {
      dataToSend = {
        ...f,
        fileId: await mapFileId(f?.fileId),
        couponFileId: await mapFileId(f?.couponFileId),
      };
    } else {
      dataToSend = {
        ...f,
        fileId: await mapFileId(f?.fileId),
      };
    }
    try {
      const updatedResponse = await PunchcardsService.updatePunchcard({
        id: selectedPunchcard?.id,
        data: dataToSend,
      });
      Toast.success(STRINGS.notify.success);
      setPage(1);
      const resp = await getPunchcards({ langId: langId, page: 1, size: 22 });
      setPunchcards({ isLast: resp.isLast, data: resp.data });
    } catch (error) {
      if (error) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setTimeout(() => setLoading(false), 100);
  };

  //add Punchcard
  const addPunchcard = async (f) => {
    setLoading(true);

    let dataToSend = {};
    const fileId = await getFileId(f?.fileId);

    if (!!f?.couponFileId && f?.couponBackgroundColorId) {
      const couponFileId = await getFileId(f?.couponFileId);
      dataToSend = {
        ...f,
        fileId: fileId?.id,
        couponFileId: couponFileId?.id,
      };
    } else {
      dataToSend = {
        ...f,
        fileId: fileId?.id,
      };
    }

    try {
      await PunchcardsService.addPunchcard(dataToSend);
      Toast.success(STRINGS.notify.punchCreated);
      setPage(1);
      const resp = await getPunchcards({ langId: langId, page: 1, size: 22 });
      setPunchcards({ isLast: resp?.isLast, data: resp?.data });
    } catch (error) {
      if (error) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setLoading(false);
    setShowModal('');
  };

  //punchCard actions
  const getPunchcards = async (props) => {
    try {
      const resp = await PunchcardsService.getPunchcards(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getPunchcardById = async (props) => {
    try {
      const resp = await PunchcardsService.getPunchcardById(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const handleDeletePunchcard = async () => {
    try {
      setShowConfirm('');
      setPunchcards((old) => ({
        isLast: old.isLast,
        data: old.data?.filter((el) => el.id !== selectedPunchcard?.id),
      }));
      await PunchcardsService.deletePunchcard({ id: selectedPunchcard?.id });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.punchDeleted);
      }
    }
  };

  //Files
  const getFileId = async (file) => {
    try {
      const resp = await MainService.postFile(file);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const mapFileId = async (file) => {
    if (file?.id) {
      return file?.id;
    } else {
      const id = await getFileId(file);
      return id?.id;
    }
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await PunchcardsService.getPunchcards({
        page: page + 1,
        size: 22,
        langId: langId,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setPunchcards({
        isLast: resp?.isLast,
        data: [...punchcards?.data, ...resp?.data],
      });
    } catch (error) {
      if (error) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };

  const changeStatus = async (elem) => {
    try {
      await PunchcardsService.updateStatus(elem?.id, configureStatus(elem));
      let temp = [...punchcards.data];
      temp.find((el) => el?.id === elem?.id).status = configureStatus(elem).status;
      setPunchcards((old) => ({ isLast: old.isLast, data: temp }));
      Toast.success(STRINGS.notify.status);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  //UseEffects
  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  useEffect(() => {
    const getPunchs = async () => {
      setSkeletVisible(true);
      const resp = await getPunchcards({ page: page, size: 22, langId: langId });
      setPunchcards({ isLast: resp?.isLast, data: resp?.data });
      setSkeletVisible(false);
    };
    getPunchs();
  }, []);

  return (
    <View
      history={history}
      backgroundColors={backgroundColors}
      getFileById={getFileById}
      langId={langId}
      languages={languages}
      punchcards={punchcards}
      showModal={showModal}
      getPunchcardById={getPunchcardById}
      setShowModal={setShowModal}
      selectedPunchcard={selectedPunchcard}
      setSelectedPunchcard={setSelectedPunchcard}
      saveChanges={saveChanges}
      handleDeletePunchcard={handleDeletePunchcard}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      addPunchcard={addPunchcard}
      skeletVisible={skeletVisible}
      changeStatus={changeStatus}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default PunchcardsContainer;

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

/**
 * Users Service functionality
 * @getDiscountData
 * @updateDiscount
 * @addDiscount
 */
export default class CitiesService {
  static #API_ENDPOINTS = {
    cities: 'cities',
    autocomplete: 'cities/autocomplete',
    languages: 'languages/',
  };

  static API_SETTINGS = {
    size: 30,
    langId: 1,
  };
  static async getCities(filterParams = '') {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.cities}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Cities data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.cities}`, error);
      throw await error.response?.json();
    }
  }
  static async updateCity(id, data) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.cities}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Cities data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.cities}`, error);
      throw await error.response?.json();
    }
  }
  static async deleteCity(id) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.cities}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Cities data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.cities}`, error);
      throw await error.response?.json();
    }
  }
  static async addCity(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.cities}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('City data retrieval result', request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.cities}`, error);
      throw await error.response?.json();
    }
  }
  static async getAutocomplete(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.autocomplete}?langId=${props.langId}&search=${props.search}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      debug.success('Cities data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.goods}`, error);
      throw await error.response?.json();
    }
  }
  static async getCityById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.cities}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('City data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.cities}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

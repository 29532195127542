/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import { TYPES } from '../../../constants/coupon-types';
import CouponsView from './coupons-view';
import { Toast } from '../../ui-kit';
import CouponsService from '../../../services/coupons';
import MainService from '../../../services/main';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useHistory } from 'react-router-dom';
import STRINGS from '../../../localization/index';
import { configureStatus } from '../../utils/configure-status';
import { useSelector } from 'react-redux';

const CouponsContainer = () => {
  const listSize = 15;

  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { languages, backgroundColors } = useSelector((store) => store.stuff);

  const history = useHistory();

  const [page, setPage] = useState(1);

  const [isLast, setIsLast] = useState(false);

  const [bottomLoading, setBottomLoading] = useState(false);

  const [coupons, setCoupons] = useState();

  const [showModal, setShowModal] = useState('');

  const [showConfirm, setShowConfirm] = useState('');

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [loading, setLoading] = useState();

  const [type, setType] = useState('common');

  const [selectedCoupon, setSelectedCoupon] = useState();

  const [showGeneratedBaseCoupons, setShowGeneratedBaseCoupons] = useState(false);

  const [generatedCoupons, setGeneratedCoupons] = useState();

  const mapFileId = async (file) => {
    if (file?.id) {
      return file?.id;
    } else {
      const id = await getFileId(file);
      return id?.id;
    }
  };

  const getFileId = async (file) => {
    try {
      const resp = await MainService.postFile(file);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const addCoupon = async (f) => {
    setLoading(true);
    if (f.couponCombos.length === 0 && f.couponProducts.length === 0) {
      Toast.error(STRINGS.screens.coupons.qq);
      setLoading(false);
    } else {
      let dataToSend = {
        expirationDate: f.expirationDate,
        price: Number.parseInt(f.price),
        code: f.code,
        type: f.type,
        discountType: f.discountType,
        backgroundColorId: f.backgroundColorId,
        ...(f.type === 'generateBase'
          ? { countOfGeneratedCoupons: parseInt(f.countOfGeneratedCoupons) }
          : {}),
        fileId: await mapFileId(f.file),
        couponData: f.couponData,
        couponProducts: f.couponProducts || [],
        couponCombos: f.couponCombos || [],
        count: f.count,
        unlimited: f.unlimited,
        couponModificators: f.couponModificators || [],
      };
      if (f.discount?.length > 0) {
        Object.assign(dataToSend, { discount: Number.parseInt(f.discount) });
      }
      if (f?.workingHours?.length) {
        Object.assign(dataToSend, { workingHours: f.workingHours });
      }
      try {
        const resp = await CouponsService.addCoupon(dataToSend);
        Toast.success(STRINGS.notify.qw);
        const couponsCodes = resp.couponGeneratedCodes.join(', ');
        f.type === 'generateBase' && setGeneratedCoupons(couponsCodes);
        setCoupons({
          isLast: coupons?.isLast,
          data: [
            ...coupons?.data,
            {
              ...resp,
              title: resp?.couponData?.find((e) => e.languageId === langId)?.title,
            },
          ],
        });
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
      setLoading(false);
      setShowModal('');
      setShowGeneratedBaseCoupons(true);
    }
  };

  const saveChanges = async (f) => {
    setLoading(true);
    if (f.couponCombos.length === 0 && f.couponProducts.length === 0) {
      Toast.error(STRINGS.screens.coupons.qq);
      setLoading(false);
    } else {
      let dataToSend = {
        fileId: await mapFileId(f.file),
        couponData: f.couponData,
        couponProducts: f.couponProducts || [],
        couponCombo: f.couponCombos || [],
        couponModificators: f.couponModificators || [],
        price: Number.parseInt(f.price),
        backgroundColorId: f.backgroundColorId,
        type: f.type,
        discountType: f.discountType,
        expirationDate: f.expirationDate,
        code: f.code,
        count: f.count,
        unlimited: f.unlimited,
      };
      if (f.discount?.length > 0) {
        Object.assign(dataToSend, { discount: Number.parseInt(f.discount) });
      }
      if (f?.workingHours?.length) {
        Object.assign(dataToSend, { workingHours: f.workingHours });
      }
      try {
        const resp = await CouponsService.updateCoupon({ id: f.id, data: dataToSend });
        setCoupons((prev) => ({
          isLast: prev?.isLast,
          data: coupons.data.map((o) => {
            if (o.id === resp.id) {
              return {
                ...resp,
                title: resp?.couponData?.find((e) => e.languageId === langId)?.title,
              };
            }
            return o;
          }),
        }));
        Toast.success(STRINGS.notify.success);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
      setLoading(false);
      setShowModal('');
      setTimeout(() => setLoading(false), 100);
    }
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await getCoupons({
        page: page + 1,
        size: listSize,
        langId: langId,
        type: type,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setCoupons({
        isLast: resp?.isLast,
        data: [...coupons?.data, ...resp?.data],
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  const getCoupons = async (props) => {
    try {
      const resp = await CouponsService.getCoupons(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getCouponById = async (id) => {
    try {
      const resp = await CouponsService.getCouponById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const handleDeleteCoupon = async () => {
    try {
      setShowConfirm('');
      setCoupons((old) => ({
        isLast: old?.isLast,
        data: old.data?.filter((el) => el.id !== selectedCoupon?.id),
      }));
      await CouponsService.deleteCoupon({ id: selectedCoupon?.id });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      } else {
        Toast.success(STRINGS.notify.qq);
      }
    }
  };

  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const changeStatus = async (elem) => {
    try {
      await CouponsService.updateStatus(elem?.id, configureStatus(elem));
      let temp = [...coupons.data];
      temp.find((el) => el?.id === elem?.id).status = configureStatus(elem).status;
      setCoupons((old) => ({ isLast: old.isLast, data: temp }));
      Toast.success(STRINGS.notify.status);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  useEffect(() => {
    const getList = async (props) => {
      setSkeletVisible(true);
      setPage(1);
      const resp = await getCoupons(props);
      if (resp) {
        setCoupons({
          isLast: resp?.isLast,
          data: [...resp?.data],
        });

        setSkeletVisible(false);
      }
    };
    getList({ page: 1, size: listSize, langId: langId, type: type });
  }, [type]);

  return (
    <CouponsView
      langId={langId}
      type={type}
      setType={setType}
      TYPES={TYPES}
      changeStatus={changeStatus}
      selectedCoupon={selectedCoupon}
      setSelectedCoupon={setSelectedCoupon}
      getFileById={getFileById}
      history={history}
      coupons={coupons}
      addCoupon={addCoupon}
      backgroundColors={backgroundColors}
      getCouponById={getCouponById}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      generatedCouponsCodes={generatedCoupons}
      showGeneratedBaseCoupons={showGeneratedBaseCoupons}
      setShowGeneratedBaseCoupons={setShowGeneratedBaseCoupons}
      saveChanges={saveChanges}
      loading={loading}
      setLoading={setLoading}
      handleDeleteCoupon={handleDeleteCoupon}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      skeletVisible={skeletVisible}
    />
  );
};

export default CouponsContainer;

/**
 * @prettier
 */

const initialState = {
  loading: false,
  glovo: { isOpen: false },
  chocofood: { isOpen: false },
  wolt: { isOpen: false },
  yandex: { isOpen: false },
};

const AGGREGATORS_LOADING_START = 'AGGREGATORS_LOADING_START';
const AGGREGATORS_SUCCESS = 'AGGREGATORS_SUCCESS';
const AGGREGATORS_FAILURE = 'AGGREGATORS_FAILURE';
const AGGREGATORS_LOADING_END = 'AGGREGATORS_LOADING_END';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AGGREGATORS_LOADING_START:
      return {
        loading: true,
      };
    case AGGREGATORS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case AGGREGATORS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case AGGREGATORS_LOADING_END:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;

export const aggregatorsLoadingStart = () => ({
  type: AGGREGATORS_LOADING_START,
});

export const aggregatorsLoadingEnd = () => ({
  type: AGGREGATORS_LOADING_END,
});

export const aggregatorsFailure = (payload) => ({
  type: AGGREGATORS_FAILURE,
  payload,
});

export const aggregatorsSuccess = (payload) => ({
  type: AGGREGATORS_SUCCESS,
  payload,
});

import STRINGS from './localized-strings';

class Localization {

  static #LOCALIZATION_KEYS = {
    CURRENT_LANGUAGE: '@localization/current_language',
  };

  static LANGUAGES = {
    en: 'en',
    ru: 'ru',
    kk: 'kk',
  };

  static async getLanguageFromAsyncStorage() {
    try {
      const language = await window.localStorage.getItem(this.#LOCALIZATION_KEYS.CURRENT_LANGUAGE);

      if (!language) {
        return Promise.reject(null);
      }
      return JSON.parse(language);
    } catch {
    }
  }

  static async setLanguageToAsyncStorage(language) {
    try {
      window.localStorage.setItem(this.#LOCALIZATION_KEYS.CURRENT_LANGUAGE, JSON.stringify(language));
    } catch (error) {
    }
  }

  static async clearLanguageInAsyncStorage() {
    try {
      window.localStorage.removeItem(this.#LOCALIZATION_KEYS.CURRENT_LANGUAGE);
    } catch (error) {
    }
  }

  static async setLanguage(language) {
    if (this.LANGUAGES[language?.iso2]) {
      try {
        await this.setLanguageToAsyncStorage(language);
        STRINGS.setLanguage(language?.iso2);
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  }
}

export default Localization;

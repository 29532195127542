export const configureStatus = (elem) => {
    switch (elem.status) {
      case 'created':
        return { status: 'active' };
      case 'disabled':
        return { status: 'active' };
      default:
        return { status: 'disabled' };
    }
  };

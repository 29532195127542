import STRINGS from '../localization/index';

export const TYPES = [
    { id: 1, key: 'common', label: STRINGS.screens.coupons.qe },
    { id: 2, key: 'personal', label: STRINGS.screens.coupons.qr },
    { id: 3, key: 'personalPurchased', label: STRINGS.screens.coupons.qt},
    { id: 4, key: 'welcomeGift', label: STRINGS.screens.coupons.qy },
    { id: 5, key: 'birthdayGift', label: STRINGS.screens.coupons.qu},
    { id: 6, key: 'generateBase', label: STRINGS.screens.coupons.qgb},
  ];

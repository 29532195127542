import React from 'react';
import './styles.scss';

/**
 *
 * @param {data} data
 * @param {selected} selected
 * @param {setSelected} setSelected
 */

const SecondaryTabs = ({data, selected, setSelected, loading}) => {
  return (
    <div className="secondary-tabs">
      {data?.map((el, index) => (
        <button
          key={index}
          data-active={selected === el?.key}
          onClick={() => setSelected(el?.key)}
          loading={loading}
        >
          {el?.label}
        </button>
      ))}
    </div>
  );
};

export default SecondaryTabs;

/**
 * @prettier
 */

import React, { Fragment } from 'react';

import STRINGS from '../../../localization';
import { Icon, Table, Skeleton } from '../../ui-kit';

import './styles.scss';
import colors from '../../styles/colors';

const RolesSkelet = () => (
  <Table
    head={() => (
      <tr className="roles_table_heading">
        <th>
          <Skeleton width="fit-content">
            <span>{STRINGS.screens.roles.role}</span>
          </Skeleton>
        </th>
        <th>
          <Skeleton width="fit-content">
            <span>{STRINGS.screens.roles.accesses}</span>
          </Skeleton>
        </th>
        <th />
      </tr>
    )}
  >
    <>
      {new Array(30).fill(null).map((item, index) => (
        <Fragment key={index.toString()}>
          <tr className="roles_table_body_row roles_table_body">
            <td className="roles_table_role">
              <Skeleton width="fit-content">
                <span>*************</span>
              </Skeleton>
            </td>
            <td>
              <Skeleton width="fit-content">
                <span>*********************************</span>
              </Skeleton>
            </td>
            <td className="roles_table_edit">
              <div className="roles_table_edit_wrapper">
                <Skeleton width="fit-content" className="roles_table_iconWrapper">
                  <div className="roles_table_iconWrapper">
                    <Icon name="delete" color={colors.color_basic._1} height={24} width={24} />
                  </div>
                </Skeleton>
                <Skeleton width="fit-content" className="roles_table_iconWrapper">
                  <div className="roles_table_iconWrapper">
                    <Icon name="edit" color={colors.color_basic._1} height={24} width={24} />
                  </div>
                </Skeleton>
                <Skeleton width="fit-content" className="roles_table_iconWrapper">
                  <div className="roles_table_iconWrapper">
                    <Icon name="users" color={colors.color_basic._1} height={24} width={24} />
                  </div>
                </Skeleton>
                <Skeleton width="fit-content" className="roles_table_iconWrapper">
                  <div className="roles_table_iconWrapper">
                    <Icon name="arrow-down" color={colors.color_basic._1} height={24} width={24} />
                  </div>
                </Skeleton>
              </div>
            </td>
          </tr>
        </Fragment>
      ))}
    </>
  </Table>
);

export default RolesSkelet;

/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import ProductWishesReceipt from '../product-wishes-receipt';

import './styles.scss';

const ComboReceipt = ({ combo = {} }) => {
  return (
    <div>
      <div className="receipt-ingredients-wrapper">
        <p>
          {combo.name} [{combo.isBig ? 'L' : 'M'}]
        </p>
        <div className="receipt-ingredients">
          <p className="receipt-count-pr">{combo.quantity}</p>
          <p>
            {combo.price} {STRINGS.components.receipt.tg}
          </p>
        </div>
      </div>
      {combo.mainProductModificatorsToAdd?.length > 0 &&
        combo.mainProductModificatorsToAdd?.map((mod) => (
          <ProductWishesReceipt key={mod.name} mod={mod} isAdd={true} />
        ))}
      {combo.mainProductModificatorsToPutAway?.length > 0 &&
        combo.mainProductModificatorsToPutAway?.map((mod) => (
          <ProductWishesReceipt key={mod.name} mod={mod} isAdd={false} />
        ))}
      {combo.sideItems?.length > 0 &&
        combo.sideItems?.map((side) => (
          <div key={side.name} className="receipt-cond-wrapper">
            <p>[K] {side.name}</p>
            <p>
              {side.priceImpact} {STRINGS.components.receipt.tg}
            </p>
          </div>
        ))}
    </div>
  );
};

export default ComboReceipt;

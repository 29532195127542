import React from 'react';

import {
  Users,
  User,
  Roles,
  Dashboard,
  Managers,
  Promotions,
  Points,
  Ingredients,
  Punchcards,
  Combo,
  ComboView,
  Cities,
  Products,
  ProductView,
  Coupons,
  Stoplists,
  Manager,
  StoplistsOverview,
  OrdersOverview,
  IngredientView,
  Restaurants,
  Feedbacks,
  DiscountView,
  Restaurant,
  DataSendMenuPreview,
  Stories,
  Orders,
  StoryTypes,
  Order,
  Menu,
  Aggregators,
  CouponView,
  Punchcard,
  Categories,
  AggregatorDailyReports,
  AggregatorWeeklyReports,
  AggregatorsKKMReport,
  AggregatorsMobileReport,
  PointsMobileReportContainer,
  CouponsMobileReportContainer,
  GiftsMobileReportContainer,
  PunchCardsMobileReportContainer,
  YandexDiscounts,
  YandexDiscount,
  ReadyOrdersReportContainer,
} from '../main/pages';

const MAIN_ROUTES = [
  {
    path: '/restaurants',
    component: <Restaurants />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/status-restaurants',
    component: <Restaurants />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/restaurants/:id',
    component: <Restaurant />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/orders/:id',
    component: <Order />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/restaurants/:id/yandex-discounts',
    component: <YandexDiscounts />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/yandex-discount/:id',
    component: <YandexDiscount />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/restaurants/:id/menu',
    component: <Menu />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/restaurants/:id/aggregators',
    component: <Aggregators />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/aggregators-daily-report',
    component: <AggregatorDailyReports />,
    exact: true,
    searchVisible: false,
  },
   {
    path: '/aggregators-weekly-report',
    component: <AggregatorWeeklyReports />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/aggregators-mobile-report',
    component: <AggregatorsMobileReport />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/gifts-mobile-report',
    component: <GiftsMobileReportContainer />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/points-mobile-report',
    component: <PointsMobileReportContainer />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/coupons-mobile-report',
    component: <CouponsMobileReportContainer />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/punch-cards-mobile-report',
    component: <PunchCardsMobileReportContainer />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/orders-ready-report',
    component: <ReadyOrdersReportContainer />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/aggregators-kkm-report',
    component: <AggregatorsKKMReport />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/restaurants/:id/stop-list',
    component: <Stoplists />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/restaurants/:id/orders',
    component: <Orders />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/stoplists-overview',
    component: <StoplistsOverview />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/orders-overview',
    component: <OrdersOverview />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/stoplists-overview/:id',
    component: <Stoplists />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/orders-overview/:id',
    component: <Orders />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/orders-overview/:restaurantId/orders/:id',
    component: <Order />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/dashboard',
    component: <Dashboard />,
    searchVisible: false,
  },
  {
    path: '/cities',
    component: <Cities />,
    searchVisible: true,
  },
  {
    path: '/users',
    component: <Users />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/users/:id',
    component: <User />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/managers',
    component: <Managers />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/managers/:id',
    component: <Manager />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/reviews',
    component: <Feedbacks />,
    searchVisible: false,
  },
  {
    path: '/promotions',
    component: <Promotions />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/categories',
    component: <Categories />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/coupons',
    component: <Coupons />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/coupons/:id',
    component: <CouponView />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/promotions/:id',
    component: <DiscountView />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/punchcards',
    component: <Punchcards />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/punchcards/:id',
    component: <Punchcard />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/products',
    component: <Products />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/products/:id',
    component: <ProductView />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/combo',
    component: <Combo />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/combo/:id',
    component: <ComboView />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/ingredients',
    component: <Ingredients />,
    exact: true,
    searchVisible: true,
  },
  {
    path: '/ingredients/:id',
    component: <IngredientView />,
    exact: true,
    searchVisible: false,
  },
  {
    path: '/stories',
    component: <Stories />,
    searchVisible: false,
  },
  {
    path: '/story-types',
    component: <StoryTypes />,
    searchVisible: false,
  },
  {
    path: '/pointsshop',
    component: <Points />,
    searchVisible: false,
  },
  {
    path: '/orders',
    component: <Orders />,
    searchVisible: true,
  },
  {
    path: '/roles',
    component: <Roles />,
    searchVisible: false,
  },
  {
    path: '/restaurants/:id/aggregators/dataSendMenuPreview',
    component: <DataSendMenuPreview />,
    exact: true,
  },
];

export default MAIN_ROUTES;

import React, { useEffect } from 'react';
import ModificatorsFields from '../../components/modals/modificators-fields';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import { Paper } from '../../ui-kit';
import '../styles.scss';
import Skelet from './skelet';
import NOPHOTO from '../../../assets/nophoto.png';
import { URL } from '../../../services/config';
import { generatePrice } from '../../utils/generate-price';
import { useSelector } from 'react-redux';

const View = (props) => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { modificator } = props;

  return (
    <>
      <ModificatorsFields
        getFileById={props.getFileById}
        getDefinitions={props.getDefinitions}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.ingredients.editIngredient}
        loadedModificator={props.modificator}
        getModificatorById={props.getModificatorById}
        saveChanges={props.saveChanges}
        languages={props.languages}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Head
        label={
          !props.skeletVisible
            ? modificator?.definition?.find((el) => el.languageId === langId)
                ?.title || STRINGS.screens.modal.noTitle
            : STRINGS.screens.modal.qq
        }
        status={props.history?.location?.state?.status}
        button={{
          size: 'medium',
          label: STRINGS.screens.ingredients.editIngredient,
          onClick: () => props.setShowModal('edit'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.ingredients.ingredients,
            link: '/ingredients',
          },
          {
            label: props.productId,
          },
        ]}
      />
      {props.skeletVisible || Object.keys(modificator).length === 0 ? (
        <Skelet />
      ) : (
        <Paper>
          <div className="modificator-view-container">
            <div>
              <div className="block">
                <div className="picture">
                  {modificator?.fileId ? (
                    <img className="image" src={`${URL}files/${modificator?.fileId}`} />
                  ) : (
                    <img src={NOPHOTO} />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.modal.general}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.modal.id}:</span>
                  <span>{modificator?.posId || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.prices.default}:</span>
                  <span>{generatePrice(modificator.prices.find(({priceType}) => priceType === 'default').amount)}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.prices.delivery}:</span>
                  <span>{generatePrice(modificator.prices.find(({priceType}) => priceType === 'delivery').amount)}</span>
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.ingredients.description}</span>
                </div>
                <div className="data">
                  <span>
                    {modificator?.definition?.definitionData?.find((el) => el.languageId === langId)
                      .description || STRINGS.screens.modal.noData}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};
export default View;

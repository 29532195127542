import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Table, Tooltip, Button } from '../../ui-kit';
import UserFields from '../../components/modals/user-fields';

import STRINGS from '../../../localization';

import './styles.scss';
import { getBeautyPhoneString } from '../../utils/phone';
import { Head } from '../../components';
import SkeletUsers from './skelet';
import { renderUserName } from '../helpers';

const UsersView = (props) => {
  const renderClassNameSort = (type) => {
    let str = 'summary_table_heading_sort';
    if (type === props.sort.type) {
      str += ' summary_table_heading_sort_active';
    }
    return str;
  };

  return (
    <>
      <UserFields
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        setIsOpen={props.setShowModal}
        title={STRINGS.screens.users.edit}
        editableUser={props.editableUser}
        saveChanges={props.saveChanges}
        loading={props.loading}
        setLoading={props.setLoading}
      />
      <Head
        label={STRINGS.screens.users.title}
        button={false}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.users.title,
          },
        ]}
      />

      <div className="summary_table">
        {props.skeletVisible ? (
          <SkeletUsers />
        ) : (
          <Table
            head={() => (
              <tr className="summary_table_heading">
                <th>ID</th>
                <th className="summary_table_heading_cell">
                  <div>
                    <span>{STRINGS.screens.users.name}</span>
                    <span
                      onClick={() => props.applySort('FirstName')}
                      className={renderClassNameSort('FirstName')}
                    >
                      {props.sort.sort === 'desc' && props.sort.type === 'FirstName'
                        ? 'Z - a'
                        : 'A - z'}
                    </span>
                  </div>
                </th>
                <th>
                  <div className="summary_table_heading_cell">
                    <span>{STRINGS.screens.users.email}</span>
                    <span
                      onClick={() => props.applySort('Email')}
                      className={renderClassNameSort('Email')}
                    >
                      {props.sort.sort === 'desc' && props.sort.type === 'Email'
                        ? 'Z - a'
                        : 'A - z'}
                    </span>
                  </div>
                </th>
                <th className="summary_table_heading_cell">
                  <div>
                    <span>{STRINGS.screens.users.number}</span>
                  </div>
                </th>
                <th className="summary_table_heading_cell">
                  <div>
                    <span>{STRINGS.screens.users.status}</span>
                  </div>
                </th>
                <th />
              </tr>
            )}
          >
            <>
              {props.users?.data?.map((item) => (
                <tr
                  key={item?.id}
                  className="summary_table_body"
                  onClick={async (event) => {
                    event.stopPropagation();
                    props.history.push(`users/${item.id}/`, {
                      name: renderUserName(item?.firstName, item?.lastName),
                      active: item?.active,
                    });
                  }}
                >
                  <td>{item?.id}</td>
                  <td>{renderUserName(item?.firstName, item?.lastName)}</td>
                  <td>{item?.email || STRINGS.screens.users.qe}</td>
                  <td>{getBeautyPhoneString(item?.phone)}</td>
                  <td>
                    <Button
                      className="outline"
                      size="large"
                      label={props.translateStatus(item?.active).text}
                      type={props.translateStatus(item?.active).button}
                      typeButton="submit"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.changeStatus({ id: item?.id, active: item?.active });
                      }}
                    />
                  </td>
                  <td className="summary_table_edit">
                    <div className="summary_table_edit_wrapper">
                      <Tooltip title={STRINGS.components.tooltips.edit}>
                        <div
                          className="summary_table_iconWrapper"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.setShowModal('edit');
                            props.setLoading(false);
                            props.setEditableUser(item.id);
                          }}
                        >
                          <Icon name="edit" width={24} height={24} />
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          </Table>
        )}
      </div>
    </>
  );
};

UsersView.propTypes = {
  users: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableUser: PropTypes.number,
  setEditableUser: PropTypes.func,
  saveChanges: PropTypes.func,
  skeletVisible: PropTypes.bool,
};

UsersView.defaultProps = {
  users: null,
  showModal: '',
  setShowModal: () => {},
  editableUser: null,
  setEditableUser: () => {},
  saveChanges: () => {},
  skeletVisible: true,
};

export default UsersView;

/**
 * @prettier
 */

const LANGUAGES = [
  {
    langShortName: 'ru',
    langFullName: {
      ru: 'Русский',
      en: 'Russian',
      kaz: 'Орыс',
    },
  },
  {
    langShortName: 'en',
    langFullName: {
      ru: 'Английский',
      en: 'English',
      kaz: 'Ағылшын',
    },
  },
  {
    langShortName: 'kaz',
    langFullName: {
      ru: 'Казахский',
      en: 'Kazakh',
      kaz: 'Қазақша',
    },
  },
];

export default LANGUAGES;

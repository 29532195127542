/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, YMURL } from '../config';

export default class MapsService {
  static API_SETTINGS = {
    size: 30,
    apikey: '182926af-d139-4bdf-b2f3-250e98aee203',
    format: 'json',
  };

  static async getResults(geocode = '', kind = undefined) {
    try {
      const request = await api.get(
        `${YMURL}?format=${this.API_SETTINGS.format}&geocode=${geocode}${
          kind ? `&kind=${kind}` : ''
        }&apikey=${this.API_SETTINGS.apikey}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );
      debug.success('Request data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(`Failed with GET request by path: ${YMURL}`, error);
      throw await error.response?.json();
    }
  }
}

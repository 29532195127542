/**
 * @prettier
 */

import React from 'react';

import { Icon, Table, Skeleton } from '../../ui-kit';

import STRINGS from '../../../localization';

import './styles.scss';
import colors from '../../styles/colors';

const SkeletManagers = () => (
  <div className="summary_table">
    <Table
      head={() => (
        <tr className="summary_table_heading">
          <th className="summary_table_heading_cell">
            <div>
              <Skeleton width="fit-content">
                <span>*****************</span>
              </Skeleton>
            </div>
          </th>
          <th className="summary_table_heading_cell">
            <div>
              <Skeleton width="fit-content">
                <span>********</span>
              </Skeleton>
            </div>
          </th>
          <th>
            <div className="summary_table_heading_cell">
              <Skeleton width="fit-content">
                <span>***********</span>
              </Skeleton>
            </div>
          </th>
          <th>
            <div className="summary_table_heading_cell">
              <Skeleton width="fit-content">
                <span>******************</span>
              </Skeleton>
            </div>
          </th>
          <th>
            <div className="summary_table_heading_cell">
              <Skeleton width="fit-content">
                <span>********</span>
              </Skeleton>
            </div>
          </th>
          <th />
        </tr>
      )}
    >
      <>
        {new Array(30).fill(null).map((item, index) => (
          <tr key={index.toString()} className="summary_table_body">
            <td>
              <Skeleton width="fit-content">
                <span>***</span>
              </Skeleton>
            </td>
            <td>
              <Skeleton width="fit-content">
                <span>*******************</span>
              </Skeleton>
            </td>
            <td>
              <Skeleton width="fit-content">
                <span>***********************</span>
              </Skeleton>
            </td>
            <td>
              <Skeleton width="fit-content">
                <span>*****************</span>
              </Skeleton>
            </td>
            <td>
              <Skeleton width="fit-content">
                <span>**********</span>
              </Skeleton>
            </td>
            <td className="summary_table_edit">
              <div className="summary_table_edit_wrapper">
                <Skeleton width="fit-content" className="roles_table_iconWrapper">
                  <div className="roles_table_iconWrapper">
                    <Icon
                      name="trash-outline"
                      color={colors.color_basic._1}
                      width={24}
                      height={24}
                    />
                  </div>
                </Skeleton>
                <Skeleton width="fit-content" className="roles_table_iconWrapper">
                  <div className="roles_table_iconWrapper">
                    <Icon name="edit-users" color={colors.color_basic._1} width={24} height={24} />
                  </div>
                </Skeleton>
              </div>
            </td>
          </tr>
        ))}
      </>
    </Table>
  </div>
);

export default SkeletManagers;

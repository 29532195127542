export const translate = (aggregator) => {
    switch (aggregator) {
      case 'glovo':
        return { title: 'Glovo' };
      case 'yandex':
        return { title: 'Yandex' };
      case 'chocofood':
        return { title: 'Chocofood' };
      case 'wolt':
        return { title: 'Wolt' };
    }
  };

/**
 * @prettier
 */

/**
 * HTTP statuses
 */
export const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  DELETED: 204,
  NOT_AUTHORIZED: 401,
};

// Production API
export const URL = 'https://mobile.qsr.kz/';
export const URI = 'https://mobile.qsr.kz/';

// Stage API
// export const URL = 'https://sandmob.qsr.kz/';
// export const URI = 'https://sandmob.qsr.kz/';

// export const URL = 'http://127.0.0.1:8080/';
// export const URI = 'http://127.0.0.1:8080/';

export const YMURL = 'https://geocode-maps.yandex.ru/1.x';

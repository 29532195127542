import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import './styles.scss';
import colors from '../../styles/colors';

const WZSwitchView = withStyles({
  switchBase: {
    color: colors.color_gray._0,
    '&$checked': {
      color: colors.color_primary._4,
    },
    '&$checked + $track': {
      backgroundColor: colors.color_gray._2,
    },
  },
  checked: {},
  track: { backgroundColor: colors.color_gray._2 },
})((props) => {
  return (
    <button className="toggle" disabled={props.disabled} onClick={props.onChange}>
      <Switch
        color="primary"
        classes={props.classes}
        checked={props.checked}
        disabled={props.disabled}
        label={props.label}
      />
      {props.label && <label className="switch-label">{props.label}</label>}
    </button>
  );
});

const WZSwitch = (props) => {
  return (
    <WZSwitchView
      className={props.className}
      checked={props.checked}
      onChange={() => props.onChange(!props.checked)}
      disabled={props.disabled}
      label={props.label}
    />
  );
};

WZSwitch.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
WZSwitch.defaultProps = {
  className: '',
  checked: false,
  onChange: () => {},
  disabled: false,
};

export default WZSwitch;

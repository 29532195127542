import AggregatorService from '../../services/aggregator';
import ModificatorsService from '../../services/modificators';
import CouponsService from '../../services/coupons';
import RestaurantsService from '../../services/restaurants';
import GoodsService from '../../services/goods';
import ComboService from '../../services/combo';
import MainService from '../../services/main';
import RolesService from '../../services/roles';

export const getLanguages = async () => {
    try {
        const data = await MainService.getLanguages();
        return ({
            key: 'languages',
            data,
        });
    } catch (error) {
        return [];
    }
};
export const getRoles = async () => {
    try {
      const data = await RolesService.getRoles('ru');
      return ({
          key: 'roles',
          data,
      });
    } catch (error) {
      return [];
    }
  };
export const getTiers = async () => {
    try {
        const data = await MainService.getTiers();
        return ({
            key: 'tiers',
            data,
        });
    } catch (error) {
        return [];
    }
};
export const getComboCategories = async (langId) => {
    try {
        const data = await ComboService.getCategories(langId);
        return ({
            key: 'comboCategories',
            data: data.reduce((acc, el) => [...acc, { label: el.title, id: el.id }], [
                { id: null, label: 'Все' },
              ]),
        });
    } catch (error) {
        return [];
    }
};
export const getCategories = async (langId) => {
    try {
        const data = await GoodsService.getCategories(langId);
        return ({
            key: 'categories',
            data: data.reduce((acc, el) => [...acc, { label: el.title, key: el.id, fileId: el.fileId }], [
                { key: null, label: 'Без категории' },
              ]),
        });
    } catch (error) {
        return [];
    }
};
export const getAdvantages = async (langId) => {
    try {
        const data = await RestaurantsService.getAdvantages(langId);
        return ({
            key: 'advantages',
            data,
        });
    } catch (error) {
        return [];
    }
};
export const getAggregators = async () => {
    try {
        const data = await AggregatorService.getAggregators();
        return ({
            key: 'aggregators',
            data,
        });
    } catch (err) {
        return [];
    }
};
export const getModificatorCategories = async (langId) => {
    try {
        const data = await ModificatorsService.getCategories(langId);
        data.unshift({
            id: null,
            title: 'Все',
        });
        return ({
            key: 'modificatorCategories',
            data,
        });
    } catch (error) {
        return [];
    }
};
export const getBackgroundColors = async () => {
    try {
        const data = await CouponsService.getBackgroundColors();
        return ({
            key: 'backgroundColors',
            data,
        });
    } catch (err) {
        return [];
    }
};
export const getPackages = async () => {
    try {
        const data = await RestaurantsService.getPackages();
        return ({
            key: 'packages',
            data,
        });
    } catch (err) {
        return [];
    }
};
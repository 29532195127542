/**
 * @prettier
 */

const initialState = {
  webkassaChecks: [],
};

const SET_WK_CHECK = 'SET_WK_CHECK';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WK_CHECK:
      return {
        webkassaChecks: [...state.webkassaChecks, action.payload],
      };
    default:
      return state;
  }
};

export const setWebkassaCheck = (payload) => {
  return {
    type: SET_WK_CHECK,
    payload,
  };
};

export default reducer;

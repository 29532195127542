import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import GoodsService from '../../../../services/goods';
import { Toast } from '../../../ui-kit';
import Loader from '../loader';
import { Button, Form, Input, Drawer, TextArea, InputDate, CustomDropzone } from '../../../ui-kit';
import { getBeautyDateString } from '../../../utils/datetime';
import { validateObject } from '../../../utils/input-functions';
import Separator from '../../separator';
import STRINGS from '../../../../localization';
import { useSelector } from 'react-redux';

const DiscountFields = (props) => {
  const { languages } = useSelector((store) => store.stuff);
  const [id, setId] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [promotionData, setPromotionData] = useState([
    { languageId: 1, title: '', subtitle: '', warning: '', description: '', file: null },
    { languageId: 2, title: '', subtitle: '', warning: '', description: '', file: null },
    { languageId: 3, title: '', subtitle: '', warning: '', description: '', file: null },
  ]);
  const [startDate, setStartDate] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [promotionProducts, setPromotionProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsQuery, setProductsQuery] = useState('');

  const getSelectedLanguageName = () => {
    return languages.find((el) => el.id === selectedLanguage).name;
  };
  const titles = promotionData.reduce(
    (acc, el, key) => ({ ...acc, [`title${key}`]: el.title }),
    [],
  );
  const subtitles = promotionData.reduce(
    (acc, el, key) => ({ ...acc, [`subtitle${key}`]: el.subtitle }),
    [],
  );
  const warnings = promotionData.reduce(
    (acc, el, key) => ({ ...acc, [`warning${key}`]: el.warning }),
    [],
  );
  const descriptions = promotionData.reduce(
    (acc, el, key) => ({ ...acc, [`description${key}`]: el.description }),
    [],
  );
  const files = promotionData.reduce((acc, el, key) => ({ ...acc, [`file${key}`]: el.file }), []);
  const SCHEME = {
    startDate: (value) => !!value,
    expirationDate: (value) => !!value,
    title0: (value) => value?.length > 0,
    title1: (value) => value?.length > 0,
    title2: (value) => value?.length > 0,
    subtitle0: (value) => value?.length > 0,
    subtitle1: (value) => value?.length > 0,
    subtitle2: (value) => value?.length > 0,
    warning0: (value) => value?.length > 0,
    warning1: (value) => value?.length > 0,
    warning2: (value) => value?.length > 0,
    description0: (value) => value?.length > 0,
    description1: (value) => value?.length > 0,
    description2: (value) => value?.length > 0,
    file0: (value) => !!value,
    file1: (value) => !!value,
    file2: (value) => !!value,
  };
  const [validation, setValidation] = useState();
  const [isFocus, setIsFocus] = useState({
    startDate: true,
    expirationDate: true,
    title0: true,
    title1: true,
    title2: true,
    subtitle0: true,
    subtitle1: true,
    subtitle2: true,
    warning0: true,
    warning1: true,
    warning2: true,
    description0: true,
    description1: true,
    description2: true,
    file0: true,
    file1: true,
    file2: true,
  });
  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };
  useEffect(() => {
    setValidation(
      validateObject(
        Object.assign(
          titles,
          subtitles,
          warnings,
          descriptions,
          files,
          { startDate },
          { expirationDate },
        ),
        SCHEME,
      ),
    );
  }, [promotionData, startDate, expirationDate]);

  const preparePromotionData = async (promotionDataPatched) => {
    let files = [];
    let patchedFilesId = [
      {
        languageId: 1,
        fileId: promotionDataPatched.find((el) => el.languageId === 1).fileId
          ? promotionDataPatched.find((el) => el.languageId === 1).fileId
          : null,
      },
      {
        languageId: 2,
        fileId: promotionDataPatched.find((el) => el.languageId === 2).fileId
          ? promotionDataPatched.find((el) => el.languageId === 2).fileId
          : null,
      },
      {
        languageId: 3,
        fileId: promotionDataPatched.find((el) => el.languageId === 3).fileId
          ? promotionDataPatched.find((el) => el.languageId === 3).fileId
          : null,
      },
    ];
    for (const element of patchedFilesId) {
      if (element.fileId) {
        let resp = await props.getFileById(element.fileId);
        resp = Object.assign(resp, { preview: URL.createObjectURL(resp), id: element.fileId });
        files.push(resp);
      } else {
        files.push(null);
      }
    }
    return promotionData.map((el, key) => ({
      languageId: el.languageId,
      title: promotionDataPatched[key].title ? promotionDataPatched[key].title : '',
      subtitle: promotionDataPatched[key].subtitle ? promotionDataPatched[key].subtitle : '',
      warning: promotionDataPatched[key].warning ? promotionDataPatched[key].warning : '',
      description: promotionDataPatched[key].description
        ? promotionDataPatched[key].description
        : '',
      file: files[el.languageId - 1],
    }));
  };
  const mapData = async (discount) => {
    try {
      setId(discount?.id);
      setStartDate(new Date(discount?.startDate));
      setExpirationDate(new Date(discount?.expirationDate));
      setPromotionData(await preparePromotionData(discount?.promotionData));
    } catch (error) {}
  };
  useEffect(async () => {
    if (productsQuery.length > 0 && props.isOpen) {
      try {
        const resp = await GoodsService.getGoods({
          langId: props.langId,
          page: 1,
          size: 30,
          categoryId: 0,
          search: productsQuery,
        });
        setProducts(resp.data);
      } catch (error) {
        Toast.error(STRINGS.screens.discounts.getByIdError);
      }
    }
  }, [productsQuery]);

  useEffect(() => {
    const getData = async () => {
      if (props.isOpen === true) {
        props.setLoading(true);
        await mapData(await props.getDiscountById(props.selectedDiscount?.id));
        props.setLoading(false);
      } else {
        props.setLoading(false);
      }
    };
    getData();
  }, [props.selectedDiscount]);

  useEffect(async () => {
    if (props?.loadedDiscount) {
      await mapData(props?.loadedDiscount);
    }
  }, [props?.loadedDiscount]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges({
              id: id,
              startDate: startDate.toISOString(),
              expirationDate: expirationDate.toISOString(),
              promotionData: promotionData,
              promotionProducts: promotionProducts.reduce(
                (acc, el) => [
                  ...acc,
                  {
                    productId: el.posId,
                  },
                ],
                [],
              ),
            });
          }}
        >
          <div className="header-nosteps">
            <div className="title">
              <span>{props.title}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.general}</span>
                  </div>
                  <InputDate
                    size="large"
                    className="container_fullwidth_half_first"
                    disablePast
                    maxDate={expirationDate}
                    label={`${STRINGS.screens.discounts.qq} *`}
                    onChange={setStartDate}
                    inputValue={startDate && getBeautyDateString(startDate)}
                    value={startDate}
                    inputType={
                      !isFocus.startDate && !validation?.data?.startDate?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    onFocus={() => changeFocus('startDate', true)}
                    onBlur={() => changeFocus('startDate', false)}
                  />
                  <InputDate
                    size="large"
                    className="container_fullwidth_half_first"
                    disablePast
                    iconHeight={30}
                    minDate={startDate}
                    inputValue={expirationDate && getBeautyDateString(expirationDate)}
                    label={`${STRINGS.screens.discounts.qw} *`}
                    onChange={setExpirationDate}
                    value={expirationDate}
                    inputType={
                      !isFocus?.expirationDate && !validation?.data?.expirationDate?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    onFocus={() => changeFocus('expirationDate', true)}
                    onBlur={() => changeFocus('expirationDate', false)}
                  />
                </div>
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.langs}</span>
                  </div>
                  <div className="langs">
                    {languages?.map((language) => (
                      <Button
                        label={language?.name}
                        value={language?.id}
                        size="medium"
                        className="lang"
                        type={selectedLanguage === language.id ? 'primary' : 'default'}
                        data-active={selectedLanguage === language.id}
                        onClick={(e) => {
                          setSelectedLanguage(language.id);
                          setIsFocus({
                            [`title${language.id - 1}`]: true,
                            [`subtitle${language.id - 1}`]: true,
                            [`warning${language.id - 1}`]: true,
                            [`description${language.id - 1}`]: true,
                          });
                          e.preventDefault();
                        }}
                      >
                        <span className="day-label">{language.name}</span>
                      </Button>
                    ))}
                  </div>
                  <Input
                    className="container_fullwidth_one_first"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...promotionData];
                      temp[selectedLanguage - 1].title = val;
                      setPromotionData(temp);
                    }}
                    inputType={
                      !isFocus?.[`title${selectedLanguage - 1}`] &&
                      !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    value={promotionData[selectedLanguage - 1].title}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
                    size="large"
                    maxLength={120}
                    onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                  />
                  <Input
                    className="container_fullwidth_one"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...promotionData];
                      temp[selectedLanguage - 1].subtitle = val;
                      setPromotionData(temp);
                    }}
                    inputType={
                      !isFocus?.[`subtitle${selectedLanguage - 1}`] &&
                      !validation?.data?.[`subtitle${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    value={promotionData[selectedLanguage - 1].subtitle}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.subtitle[selectedLanguage - 1]} *`}
                    size="large"
                    maxLength={40}
                    onFocus={() => changeFocus(`subtitle${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`subtitle${selectedLanguage - 1}`, false)}
                  />
                  <Input
                    className="container_fullwidth_one"
                    fullWidth
                    inputType={
                      !isFocus?.[`warning${selectedLanguage - 1}`] &&
                      !validation?.data?.[`warning${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    onChange={(val) => {
                      let temp = [...promotionData];
                      temp[selectedLanguage - 1].warning = val;
                      setPromotionData(temp);
                    }}
                    value={promotionData[selectedLanguage - 1].warning}
                    variant="outlined"
                    label={`${STRINGS.screens.modal.warning[selectedLanguage - 1]} *`}
                    onFocus={() => changeFocus(`warning${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`warning${selectedLanguage - 1}`, false)}
                  />
                  <TextArea
                    textareaType={
                      !isFocus?.[`description${selectedLanguage - 1}`] &&
                      !validation?.data?.[`description${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    className="container_fullwidth_one"
                    onChange={(val) => {
                      let temp = [...promotionData];
                      temp[selectedLanguage - 1].description = val;
                      setPromotionData(temp);
                    }}
                    value={promotionData[selectedLanguage - 1].description}
                    label={`${STRINGS.screens.modal.description[selectedLanguage - 1]} *`}
                    multiline
                    rows={4}
                    onFocus={() => changeFocus(`description${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`description${selectedLanguage - 1}`, false)}
                  />
                  <Separator indent={16} />
                  <div className="subtitle">
                    <span>{`${STRINGS.screens.modal.img} (${getSelectedLanguageName()}) *`}</span>
                  </div>
                  <CustomDropzone
                    onChange={(val) => {
                      let temp = [...promotionData];
                      temp[selectedLanguage - 1].file = val;
                      setPromotionData(temp);
                    }}
                    dropzoneType={'default'}
                    files={promotionData[selectedLanguage - 1].file}
                  />

                </div>

            </div>
          )}
          <>
          <Separator indent={80} />
          <div className="footer">
            <Button
              disabled={!validation?.isValid}
              className="button"
              label={STRINGS.screens.modal.save}
              size="large"
              type={!validation?.isValid ? 'disabled' : 'primary'}
              typeButton="submit"
              loading={props.loading}
            />
          </div>
          </>
        </Form>
      </div>
    </Drawer>
  );
};

DiscountFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
DiscountFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default DiscountFields;

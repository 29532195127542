/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import { Icon } from '../../ui-kit';
import './styles.scss';
import colors from '../../styles/colors';

/**
 * @component HRowItem
 * @example
 * <HRowItem
 *  to="/"
 *  icon="dashboard"
 * />
 */
const HRowItem = (props) => {
  const history = useHistory();
  const renderBasicContent = () => (
    <div className="row">
      {props.icon && <Icon name={props.icon} color={colors.color_gray._4} width={24} height={24} />}
      {props.name && <span>{props.name}</span>}
    </div>
  );

  return (
    <nav>
      <Link
        to={props.to}
        onClick={() => props.setSidebarVisible(false)}
        className="sidebar-row-link"
        data-active={(() => {
          const link = props.to;
          const splited = link.split('/').filter((el) => el);
          const splitedHistory = history.location.pathname.split('/').filter((el) => el);
          if (splitedHistory[0] === splited[0]) {
            return true;
          }
        })()}
      >
        {props.data ? (
          <>
            {renderBasicContent()}
            <div className="analytics-box">
              <span>{props.data > 999 ? '+999' : props.data}</span>
            </div>
          </>
        ) : (
          renderBasicContent()
        )}
      </Link>
    </nav>
  );
};

/**
 * Properties
 */

HRowItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  setSidebarVisible: PropTypes.func,
  data: PropTypes.object,
  active: PropTypes.bool,
};
HRowItem.defaultProps = {
  to: '/',
  icon: null,
  name: null,
  data: null,
  setSidebarVisible: () => {},
  active: false,
};

export default HRowItem;

/**
 * @prettier
 */

export default {
  color_white: {
    _0: '#fff',
    _1: '#fafdff',
    _2: '#eeeeee',
  },
  color_gray: {
    _0: '#f4f5f6',
    _1: '#e9eaeC',
    _2: '#b1b6be',
    _3: '#a6acb5',
    _4: '#7d8592',
    _5: '#666e7a',
    _6: '#BBA391',
  },
  color_black: {
    _0: '#2c2c2c',
    _1: '#232323',
    _2: '#292929',
  },
  color_red: {
    _0: '#f44336',
    _1: 'rgba(244, 67, 54, 0.6)',
    _2: 'rgba(244, 67, 54, 0.2)',
    _3: 'rgba(244, 67, 54, 0.1)',
  },
  color_yellow: {
    _0: '#ffd000',
    _1: 'rgba(255, 208, 0, 0.3)',
    _2: 'rgba(255, 208, 0, 0.2)',
  },
  color_green: {
    _0: '#4caf50',
    _1: 'rgba(76, 175, 80, 0.2)',
    _2: 'rgba(76, 175, 80, 0.1)',
    _3: 'rgba(76, 175, 80, 0.8)',
    _4: '#39a73c',
    _5: '#1b8737',
  },
  color_primary: {
    _0: '#376fd0',
    _1: '#264d91',
    _2: 'rgba(55, 111, 208, 0.5)',
    _3: 'rgba(55, 111, 208, 0.04)',
  },
  color_secondary: {
    _0: '#3f8cff',
    _1: '#0041a3',
    _2: '#85b6ff',
    _3: 'rgba(71, 130, 218, 0.04)',
  },
  color_basic: {
    _0: '#4a5059',
    _1: '#3f8cff',
    _2: '#c5d5f1',
  },
  color_brown: {
    _0: '#502314',
  },
};

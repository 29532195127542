/**
 * @prettier
 */

import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import STRINGS from '../../../../localization';
import SelectedItem from './SelectedItem';

const ToBuy = (props) => {
  return (
    <div style={{ width: '100%', height: '46%', paddingTop: 10 }}>
      <span style={{ fontWeight: 'bold', fontSize: 16, paddingLeft: 10 }}>
        {STRINGS.screens.punchcards.toBuy}
      </span>
      <Droppable droppableId={props.area?.id}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              backgroundColor: '#f4f5f6',
              height: '100%',
              borderRadius: 4,
              overflowY: 'scroll',
            }}
          >
            {props.area?.list.map((el, index) => (
              <SelectedItem {...props} el={el} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default ToBuy;

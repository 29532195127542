/**
 * @prettier
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ComboService from '../../../services/combo';
import CreateDefinition from '../../components/modals/create-definition';
import './styles.scss';
import STRINGS from '../../../localization';
import { Input, Icon } from '..';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const DefinitionsCombo = (props) => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [isModalOpen, setIsModalOpen] = useState('');
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [active, setActive] = useState(false);

  const handleClickAwayEvent = () => {
    setActive(false);
  };
  const onClose = () => {
    setIsModalOpen(false);
  };
  const addDefinition = async (f) => {
    setLoading(true);
    try {
      const response = await ComboService.postDefinition(f);
      props.setDefinitionId(response);
    } catch (error) {}
    setQuery('');
    setActive(false);
    setIsModalOpen(false);
    setLoading(false);
  };
  const editDefinition = async (f) => {
    setLoading(true);
    const data = {
      definitionData: f.definitionData.map((item) => ({
        description: item.description,
        languageId: item.languageId,
        title: item.title,
      })),
    };
    try {
      const response = await ComboService.putDefinition(props.definitionId, data);
      props.setDefinition(response?.definitionData);
    } catch (error) {}
    setQuery('');
    setActive(false);
    setIsModalOpen(false);
    setLoading(false);
  };

  const getDefinitionById = async (id) => {
    try {
      const resp = await ComboService.getDefinitionById(id);
      props.setDefinition(resp?.definitionData);
    } catch (error) {}
  };
  const getDefinitions = async (properties) => {
    setActive(false);
    try {
      const resp = await ComboService.getDefinitionsAutocomplete(properties);
      setSuggestions(resp);
      setActive(true);
    } catch (error) {
      setActive(true);
    }
  };
  useEffect(async () => {
    if (query?.length) {
      getDefinitions({
        langId: 1,
        search: query,
      });
    } else {
      setSuggestions([]);
    }
  }, [query]);

  useEffect(() => {
    if (props.definitionId && props.definitionId !== 0) {
      getDefinitionById(props.definitionId);
    } else {
      props.setDefinition({});
    }
  }, [props.definitionId]);

  return (
    <ClickAwayListener onClickAway={handleClickAwayEvent}>
      <div className={`definition ${props.className}`}>
        <CreateDefinition
          setOpen={setIsModalOpen}
          open={isModalOpen === 'create-definition'}
          onClose={onClose}
          saveChanges={addDefinition}
          title={STRINGS.screens.goods.createDefinition}
          loading={loading}
        />
        <CreateDefinition
          setOpen={setIsModalOpen}
          open={isModalOpen === 'edit-definition'}
          onClose={onClose}
          saveChanges={editDefinition}
          title={STRINGS.screens.goods.editDefinition}
          loading={loading}
          definition={props.definition}
        />
        <Input
          fullWidth
          value={query}
          onChange={(e) => {
            setQuery(e);
          }}
          onClick={() => {
            const temp = active;
            setActive(!temp);
          }}
          variant="outlined"
          label={STRINGS.screens.modal.search}
          size={props.size}
          maxLength={40}
          inputType={props.inputType}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          rightIcon="search"
        />
        {active && (
          <div className="suggestions">
            <div
              className="suggestion"
              onClick={() => {
                setIsModalOpen('create-definition');
              }}
            >
              <span className="suggestion-label-blue">
                <Icon name="plus" width={12} height={12} color="#376fd0" />{' '}
                {STRINGS.screens.modal.createDefinition}
              </span>
            </div>

            {suggestions?.map((el) => (
              <div
                className="suggestion"
                onClick={async () => {
                  setQuery('');
                  props.setDefinitionId(el?.id);
                  setActive(false);
                }}
              >
                <span className="suggestion-label">{el.value}</span>
              </div>
            ))}
          </div>
        )}
        {props.definition?.length > 0 && (
          <>
            <div className="choosed">
              <div>
                <span>{STRINGS.screens.modal.title_def}:</span>
                <span>{props.definition?.find((el) => el?.languageId === langId)?.title}</span>
              </div>
              <div>
                <span>{STRINGS.screens.modal.description_def}:</span>
                <span>
                  {props.definition?.find((el) => el?.languageId === langId)?.description}
                </span>
              </div>
            </div>
            <div className="actions">
              <div className="btn-delete">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    props.setDefinitionId(null);
                    props.setDefinition(null);
                  }}
                >
                  {STRINGS.screens.modal.clear}
                </button>
                <Icon name="trash" width="16" height="16" />
              </div>
              <div className="btn-edit">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen('edit-definition');
                  }}
                >
                  {STRINGS.screens.modal.edit}
                </button>
                <Icon name="trash" width="16" height="16" />
              </div>
            </div>
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

DefinitionsCombo.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  data: PropTypes.array,
};

DefinitionsCombo.defaultProps = {
  className: '',
  label: '',
  size: 'large',
  data: [],
};

export default DefinitionsCombo;

import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const defaultConfig = {
  position: 'top-right',
  autoClose: 1700,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

/**
 * @component Toast
 * @example
 * Toast.success('Image success loaded');
 */
class Toast {
  static info(value) {
    return toast.info(value, defaultConfig);
  }

  static success(value) {
    return toast.success(value, defaultConfig);
  }

  static error(value) {
    toast.error(value, defaultConfig);
  }
}

/**
 * Properties
 */

Toast.propTypes = {
  value: PropTypes.string,
};
Toast.defaultProps = {
  value: '',
};

export default Toast;

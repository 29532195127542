/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Confirm, Table, Tooltip, MainTabs } from '../../ui-kit';
import StoplistFields from '../../components/modals/stoplist-fields';
import Skelet from './skelet';
import { Head } from '../../components';

import STRINGS from '../../../localization';
import { generatePrice } from '../../utils/generate-price';
import NOPHOTO from '../../../assets/nophoto.png';
import { URL } from '../../../services/config';
import './styles.scss';

const StoplistsView = (props) => {
  const StoplistsCategories = [
    { label: STRINGS.screens.stoplists.qq, key: 0 },
    { label: STRINGS.screens.stoplists.qw, key: 1 },
    { label: STRINGS.screens.stoplists.qz, key: 2 },
  ];
  return (
    <>
      <StoplistFields
        loading={props.loading}
        setLoading={props.setLoading}
        saveChanges={props.saveChanges}
        restaurantId={props.restaurantId}
        isOpen={props.showModal === 'addStoplist'}
        getModificatorsSuggestions={props.getModificatorsSuggestions}
        getProductsSuggestions={props.getProductsSuggestions}
        getCombosSuggestions={props.getCombosSuggestions}
        title={props.setStopFieldTitle(props.selectedStoplistsCategory)}
        onClose={() => props.setShowModal('')}
        languages={props.languages}
        stoplists={props.stoplists}
        products={props.products}
        productsQuery={props.productsQuery}
        setProductsQuery={props.setProductsQuery}
        restaurants={props.restaurants}
        restaurantsQuery={props.restaurantsQuery}
        setRestaurantsQuery={props.setRestaurantsQuery}
        selectedStoplistsCategory={props.selectedStoplistsCategory}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={`${STRINGS.screens.stoplists.deleteQuestion}`}
        buttons={[
          {
            label: STRINGS.screens.roles.modal.delete,
            type: 'danger',
            onClick: () => {
              props.deleteModificator(props.selectedModificator);
              props.setShowConfirm('');
            },
          },
          {
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            type: 'default',
            onClick: () => {
              props.setShowConfirm('');
              setTimeout(() => props.setSelectedModificator(), 100);
            },
          },
        ]}
      />

      <Head
        label={STRINGS.screens.stoplists.stoplists}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: props.setStopFieldTitle(props.selectedStoplistsCategory),
          onClick: () => props.setShowModal('addStoplist'),
        }}
        breadCrumbs={props.history.location.state?.breadCrumbs}
      />

      <div>
        <div className="summary_table">
          <MainTabs
            active={props.selectedStoplistsCategory}
            data={StoplistsCategories}
            onClick={props.setSelectedStoplistsCategory}
          />
          {props.skeletVisible ? (
            <Skelet />
          ) : (
            <Table
              loading={props.bottomLoading}
              head={() => (
                <tr className="summary_table_heading">
                  <th />
                  <th>{STRINGS.screens.goods.id}</th>
                  <th>{STRINGS.screens.goods.title}</th>
                  {props.selectedStoplistsCategory !== 0 && (
                    <th>
                      <span>{STRINGS.screens.goods.microsTitle}</span>
                    </th>
                  )}
                  <th>{STRINGS.screens.goods.prices.default}</th>
                  <th>{STRINGS.screens.goods.prices.delivery}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.modificators?.data?.map((item) => (
                  <tr key={item.id} className="summary_table_body unselectable">
                    <td>
                      {item?.fileId ? (
                        <img
                          className="image"
                          style={{ width: '100px' }}
                          src={`${URL}files/${item?.fileId}`}
                        />
                      ) : (
                        <img className="image" style={{ width: '100px' }} src={NOPHOTO} />
                      )}
                    </td>
                    <td>{item.posId}</td>
                    <td>{item.title || STRINGS.screens.modal.noData}</td>
                    {props.selectedStoplistsCategory !== 0 && (
                      <td>{item.posTitle || STRINGS.screens.modal.noData}</td>
                    )}
                    <td>{generatePrice(item.prices.default)}</td>
                    <td>{generatePrice(item.prices.delivery)}</td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={() => {
                              props.setSelectedModificator({
                                data: {
                                  modificators: [
                                    {
                                      modificatorId: item.posId,
                                      stopListed: false,
                                    },
                                  ],
                                  products: [
                                    {
                                      productId: item.posId,
                                      stopListed: false,
                                    },
                                  ],
                                  combos: [
                                    {
                                      comboId: item.posId,
                                      stopListed: false,
                                    },
                                  ],
                                },
                                restaurantId: props.restaurantId,
                              });
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

StoplistsView.propTypes = {
  languages: PropTypes.array,
  stoplists: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableStoplist: PropTypes.object,
  setEditableStoplist: PropTypes.func,
  saveChanges: PropTypes.func,
};

StoplistsView.defaultProps = {
  languages: [],
  stoplists: null,
  showModal: '',
  setShowModal: () => {},
  editableStoplist: {},
  setEditableStoplist: () => {},
  saveChanges: () => {},
};

export default StoplistsView;

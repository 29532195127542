/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

/**
 * Users Service functionality
 * @getCouponData
 * @updateCoupon
 * @addCoupon
 */
export default class CouponsService {
  static #API_ENDPOINTS = {
    coupons: 'coupons',
    backgroundColors: 'background-colors',
    languages: 'languages/',
  };

  static API_SETTINGS = {
    size: 30,
  };
  static async getCoupons(props) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.coupons}/crm?langId=${props.langId}&size=${props.size}&page=${
          props.page
        }&type=${props.type}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Coupon data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.coupons}/crm?langId=${
          props.langId
        }&size=${props.size}&page=${props.page}&type=${props.type}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateCoupon({ id, data }) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.coupons}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Coupon data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.coupons}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addCoupon(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.coupons}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success('Coupon data retrieval result', request);
      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.coupons}`, error);
      throw await error.response?.json();
    }
  }
  static async checkExisting(code) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.coupons}/code/${code}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Coupon data retrieval result', request);
      return request.json();
    } catch (error) {
      debug.error(`Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.coupons}`, error);
      throw await error.response?.json();
    }
  }
  static async getCouponById(id) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.coupons}/crm/${id}?langId=1`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Coupon data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.coupons}/crm/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.coupons}/${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(status),
      });

      debug.success('Coupon data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.coupons}/${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deleteCoupon(props) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.coupons}/${props?.id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Coupon data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.coupons}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getBackgroundColors() {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.backgroundColors}`, {
        headers: {
          'content-type': 'application/json',
        },
      });
      debug.success('Coupon data retrieval result', request);
      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }
      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.backgroundColors}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

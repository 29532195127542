/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Input, Drawer, Select, CustomDropzone } from '../../../ui-kit';
import STRINGS from '../../../../localization';
import { validateObject } from '../../../utils/input-functions';
import { useSelector } from 'react-redux';
import { Toast } from '../../../ui-kit';
import Loader from '../loader';
import CategoriesService from '../../../../services/categories';
import MainService from '../../../../services/main';

const Fields = (props) => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const INIT_FOCUS_VALUE = {
    serialNumber: true,
    key: true,
    type: true,
    title0: true,
    title1: true,
    title2: true,
    file: true,
  };
  const OPTIONS = [
    { key: 'PRODUCT', label: 'PRODUCT' },
    { key: 'COMBO', label: 'COMBO' },
    { key: 'MODIFICATOR', label: 'MODIFICATOR' },
    { key: 'COMBO_DEAL', label: 'COMBO_DEAL' },
  ];
  const INIT_CATEGORY_DATA = [
    { languageId: 1, title: '' },
    { languageId: 2, title: '' },
    { languageId: 3, title: '' },
  ];
  const maxStep = 2;
  const { languages } = useSelector((store) => store.stuff);
  const [id, setId] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [step, setStep] = useState(1);
  const [categoryData, setCategoryData] = useState(INIT_CATEGORY_DATA);
  const [type, setType] = useState(OPTIONS[0].key);
  const [key, setKey] = useState('');
  const [file, setFile] = useState(null);
  const titles = categoryData.reduce((acc, el, key) => ({ ...acc, [`title${key}`]: el.title }), []);
  const [serialNumber, setSerialNumber] = useState('');

  const initialize = () => {
    setSelectedLanguage(1);
    setStep(1);
    setSerialNumber('');
    setType(OPTIONS[0].key);
    setKey('');
    setFile(null);
    setCategoryData(INIT_CATEGORY_DATA);
    setIsFocus(INIT_FOCUS_VALUE);
  };
  const getFileById = async (id) => {
    try {
      const resp = await MainService.getFileById(id);
      URL.createObjectURL(resp);
      setFile(Object.assign(resp, { preview: URL.createObjectURL(resp), id }));
    } catch (error) {
      Toast.error(STRINGS.notify.imageFailure);
    }
  };
  useEffect(() => {
    if (props.isOpen === false) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  const SCHEME = {
    serialNumber: (value) => !!value,
    key: (value) => value?.length > 0,
    type: (value) => !!value,
    title0: (value) => value?.length > 0,
    title1: (value) => value?.length > 0,
    title2: (value) => value?.length > 0,
    file: (value) => !!value,
  };
  const getCategoryById = async (id) => {
    try {
      const resp = await CategoriesService.getCategoryById(id, langId);
      return resp;
    } catch (err) {}
  };
  const prepareCategoryData = (categoryDataPatched) => {
    return categoryData.map((el, key) => ({
      languageId: el?.languageId,
      title: categoryDataPatched[key]?.title ? categoryDataPatched[key]?.title : '',
    }));
  };
  const [validation, setValidation] = useState();
  const [isFocus, setIsFocus] = useState(INIT_FOCUS_VALUE);

  const changeFocus = (field, isFocus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: isFocus }));
  };
  const mapFileId = async (file) => {
    if (file?.id) {
      return file?.id;
    } else {
      const id = await getFileId(file);
      return id?.id;
    }
  };

  const [initialEntity, setIntiialEntity] = useState({});

  const getFileId = async (file) => {
    try {
      const resp = await MainService.postFile(file);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const mapData = async (cat) => {
    setIntiialEntity(cat);
    setId(props.selectedCategory?.id);
    setKey(cat?.key);
    setSerialNumber(cat?.serialNumber);
    setType(OPTIONS.find((el) => el.key.includes(cat?.type))?.key || OPTIONS[0].key);
    setCategoryData(prepareCategoryData(cat?.categoryData));
    if (cat?.fileId) {
      await getFileById(cat.fileId);
    }
  };

  useEffect(() => {
    const loadSelectedCategory = async (params) => {
      if (props.selectedCategory && props.isOpen === true) {
        props.setLoading(true);
        await mapData(await getCategoryById(params));
        props.setLoading(false);
      }
    };
    loadSelectedCategory(props.selectedCategory?.id, langId);
  }, [props.selectedCategory, props.isOpen]);

  useEffect(() => {
    if (!props.isOpen) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  useEffect(() => {
    setValidation(validateObject(Object.assign(titles, { key, serialNumber, type, file }), SCHEME));
  }, [categoryData, key, serialNumber, type, file]);

  // useEffect(async () => {
  //   if (props.selectedCategory?.id) {
  //     const category = await getCategoryById(props.selectedCategory?.id);
  //     setId(props.selectedCategory?.id);
  //     setKey(props.selectedCategory?.key);
  //     setCategoryData(prepareCategoryData(category?.categoryData));
  //     setSerialNumber(props.selectedCategory?.serialNumber);
  //     setKey(props.selectedCategory?.key);
  //   }
  // }, [props.selectedCategory]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={() => {
        setStep(1);
        props.onClose();
      }}
    >
      <div className="modal">
        <Form
          onSubmit={async () => {
            props.setLoading(true);
            props.saveChanges({
              initialEntity,
              key,
              type,
              categoryData,
              serialNumber: parseInt(serialNumber, 10),
              fileId: await mapFileId(file),
            });
            props.setLoading(false);
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.categories.AG}</span>
                    </div>
                    <Input
                      fullWidth
                      className="container_fullwidth_one_first"
                      variant="outlined"
                      label={`${STRINGS.screens.categories.AL} *`}
                      value={serialNumber}
                      inputType={
                        !isFocus.serialNumber && !validation?.data?.serialNumber?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onChange={setSerialNumber}
                      type="number"
                      onFocus={() => changeFocus('serialNumber', true)}
                      onBlur={() => changeFocus('serialNumber', false)}
                    />
                    <Input
                      fullWidth
                      className="container_fullwidth_one"
                      variant="outlined"
                      label={`${STRINGS.screens.categories.AM} *`}
                      value={key}
                      onChange={setKey}
                      inputType={
                        !isFocus.key && !validation?.data?.key?.isValid ? 'danger' : 'default'
                      }
                      onFocus={() => changeFocus('key', true)}
                      onBlur={() => changeFocus('key', false)}
                    />
                    <Select
                      fullWidth
                      className="container_fullwidth_one"
                      variant="outlined"
                      label={`${STRINGS.screens.categories.AN} *`}
                      size="large"
                      options={OPTIONS}
                      value={type}
                      onChange={setType}
                      inputType={
                        !isFocus.type && !validation?.data?.type?.isValid ? 'danger' : 'default'
                      }
                      onFocus={() => changeFocus('type', true)}
                      onBlur={() => changeFocus('type', false)}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.img} *</span>
                    </div>
                    <CustomDropzone
                      maxFiles={1}
                      files={file}
                      onChange={setFile}
                      dropzoneType={'default'}
                    />
                  </div>
                </>
              )}
              {step === 2 && (
                <div className="container_fullwidth">
                  <div className="subtitle">
                    <span>{STRINGS.screens.modal.langs}</span>
                  </div>
                  <div className="langs">
                    {languages?.map((language, index) => (
                      <Button
                        key={index}
                        label={language.name}
                        value={language.id}
                        size="medium"
                        className="lang"
                        loading={props.skeletVisible}
                        type={selectedLanguage === language.id ? 'primary' : 'default'}
                        data-active={selectedLanguage === language.id}
                        onClick={(e) => {
                          setSelectedLanguage(language.id);
                          setIsFocus({
                            [`title${language.id - 1}`]: true,
                          });
                          e.preventDefault();
                        }}
                      >
                        <span className="day-label">{language.name}</span>
                      </Button>
                    ))}
                  </div>
                  <Input
                    className="container_fullwidth_one_first"
                    fullWidth
                    onChange={(val) => {
                      let temp = [...categoryData];
                      temp[selectedLanguage - 1].title = val;
                      setCategoryData(temp);
                    }}
                    value={categoryData[selectedLanguage - 1].title}
                    label={`${STRINGS.screens.modal.title[selectedLanguage - 1]} *`}
                    inputType={
                      !isFocus?.[`title${selectedLanguage - 1}`] &&
                      !validation?.data?.[`title${selectedLanguage - 1}`]?.isValid
                        ? 'danger'
                        : 'default'
                    }
                    onFocus={() => changeFocus(`title${selectedLanguage - 1}`, true)}
                    onBlur={() => changeFocus(`title${selectedLanguage - 1}`, false)}
                  />
                </div>
              )}
            </div>
          )}
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                disabled={!validation.isValid}
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                type={!validation.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

/**
 * Properties
 */

Fields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
Fields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default Fields;

import React, { useEffect, useState } from 'react';

import ProductsView from './products-view';
import { Toast } from '../../ui-kit';

import MenuService from '../../../services/menu';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import STRINGS from '../../../localization';

const MenuContainer = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const { languages, categories } = useSelector((store) => store.stuff);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState();
  const [selectedMenuCategory, setSelectedMenuCategory] = useState('products');
  const [selectedProduct, setSelectedProduct] = useState();
  const [bottomLoading, setBottomLoading] = useState(false);
  const [autocomplete, setAutocomplete] = useState([]);
  const [page, setPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const [showModal, setShowModal] = useState('');
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(false);
  const [existingMenu, setExistingMenu] = useState();
  const [selectedCategory, setSelectedCategory] = useState(1);
  const CATEGORIES = categories?.reduce((acc, el) => [...acc, { label: el.title, key: el.id }], []);
  let { id } = useParams();

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const menu = await getMenu({
        langId: langId,
        page: page + 1,
        size: 22,
        category: selectedMenuCategory,
        existing: true,
        restaurantId: parseInt(id,10),
        categoryId: 0,
        search: '',
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setExistingMenu({
        isLast: menu.isLast,
        data: [...existingMenu?.data, ...menu?.data],
      });
    } catch (err) {
      Toast.error(STRINGS.notify.failure);
      setBottomLoading(false);
    }
  };

  const getMenu = async (props) => {
    try {
      const resp = await MenuService.getMenu(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const handleDeleteProduct = async () => {
    try {
      await MenuService.deleteProducts({
        category: selectedMenuCategory,
        restaurantId: id,
        data: {
          products: [{ productId: selectedProduct.id }],
        },
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }

    setExistingMenu((prev) => ({
      isLast: prev.isLast,
      data: prev.data.filter((el) => el.posId !== selectedProduct.id),
    }));
    setShowConfirm('');
  };

  const addAndDeleteProducts = async (f) => {
    setLoading(true);
    if (f.productsToAdd.length > 0) {
      try {
        if (selectedMenuCategory === 'products') {
          await MenuService.addProducts({
            category: selectedMenuCategory,
            restaurantId: id,
            data: {
              products: f.productsToAdd.map((el) => ({ productId: el.posId })),
            },
          });
        }
        if (selectedMenuCategory === 'combo') {
          await MenuService.addProducts({
            category: selectedMenuCategory,
            restaurantId: id,
            data: {
              combos: f.productsToAdd.map((el) => ({ comboId: el.posId })),
            },
          });
        }
        Toast.success(STRINGS.notify.success);
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
    if (f.productsToDelete.length > 0) {
      try {
        if (selectedMenuCategory === 'products') {
          await MenuService.deleteProducts({
            category: selectedMenuCategory,
            restaurantId: id,
            data: {
              products: f.productsToDelete.map((el) => ({ productId: el.posId })),
            },
          });
        }
        if (selectedMenuCategory === 'combo') {
          await MenuService.deleteProducts({
            category: selectedMenuCategory,
            restaurantId: id,
            data: {
              combos: f.productsToDelete.map((el) => ({ comboId: el.posId })),
            },
          });
        }
      } catch (error) {
        if (error?.status) {
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
    setPage(1);
    setSkeletVisible(true);
    const menu = await getMenu({
      langId: langId,
      page: 1,
      size: 22,
      category: selectedMenuCategory,
      existing: true,
      restaurantId: parseInt(id,10),
      search: '',
    });
    setExistingMenu({ isLast: menu.isLast, data: menu.data });
    setLoading(false);
    setSkeletVisible(false);
  };

  useBottomScrollListener(() => {
    if (!bottomLoading && !isLast) {
      getNextPage();
    }
  });

  useEffect(async () => {
    setSkeletVisible(true);
    setPage(1);
    const menu = await getMenu({
      langId: langId,
      page: 1,
      size: 22,
      category: selectedMenuCategory,
      existing: true,
      restaurantId: parseInt(id,10),
      categoryId: 0,
      search: '',
    });
    setExistingMenu({ isLast: menu.isLast, data: menu.data });
    setSkeletVisible(false);
  }, [selectedMenuCategory]);

  return (
    <ProductsView
      saveChanges={addAndDeleteProducts}
      restaurantId={id}
      category={category}
      setCategory={setCategory}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
      autocomplete={autocomplete}
      setAutocomplete={setAutocomplete}
      CATEGORIES={CATEGORIES}
      getMenu={getMenu}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      bottomLoading={bottomLoading}
      existingMenu={existingMenu}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      handleDeleteProduct={handleDeleteProduct}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      loading={loading}
      setLoading={loading}
      selectedMenuCategory={selectedMenuCategory}
      setSelectedMenuCategory={setSelectedMenuCategory}
      skeletVisible={skeletVisible}
    />
  );
};

export default MenuContainer;

/**
 * @prettier
 */

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import colors from '../../../styles/colors';
import { Icon } from '../../../../ui-kit';
import STRINGS from '../../../../localization';
import './styles.scss';
import { WEBKASSA_OPERATION_TYPES } from '../../../../constants/webkassa-check-statuses';

const WebkassaCheck = (props) => {
  const ref = useRef();
  const webkassaChecks = useSelector((store) => store.webkassaChecks.webkassaChecks);
  const aggregators = useSelector((store) => store.stuff.aggregators);

  const [isPlaying, setIsPlaying] = useState(false);
  const [key, setKey] = useState(0);
  const [isCanceled, setIsCanceled] = useState(false);

  const playChange = () => {
    setIsPlaying(!isPlaying);

    setKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const el = webkassaChecks.find((el) => el === props.el.id);

    if (el) {
      setIsCanceled(true);
    }
  }, []);

  const pressHandler = () => {
    let qwe;

    ref.current.classList.toggle('color-delay');

    const arr = [...ref.current.classList];

    if (arr.includes('color-delay')) {
      ref.current.parentElement.classList.add('delete-order');
      props.handlerSend(props.el, 'picked');
    }

    clearTimeout(qwe);

    return [false];
  };

  useEffect(() => {
    const el = webkassaChecks.find((el) => el === props.el.id);
    if (el) {
      setIsCanceled(true);
    }
  }, []);

  return (
    <div className="card-wrapper">
      {props.readyOrders === false && (
        <a
          className={`card-status ${isCanceled ? 'gray' : 'green'}`}
          ref={ref}
          onClick={() => {
            if (!isCanceled) {
              playChange();
            }
          }}
        >
          {isPlaying ? (
            <CountdownCircleTimer
              key={key}
              isPlaying={isPlaying}
              duration={5}
              colors={[[colors.color_white._0]]}
              onComplete={pressHandler}
              size={30}
              strokeWidth={1}
              trailStrokeWidth={0}
              rotation="counterclockwise"
            >
              {({ remainingTime }) => <p className="time-string">{remainingTime}</p>}
            </CountdownCircleTimer>
          ) : (
            <div className="info-container">
              <span className="circle-confirm">
                <Icon name="confirm" width={16} height={16} />
              </span>
              <p className="status-text">{STRINGS.components.currentOrdersButton.ready}</p>
            </div>
          )}
        </a>
      )}
      <a className="main-container" onClick={() => props.changeCheckView(props.el.id)}>
        <div className="title-wrapper">
          <p className="title-text">
            {STRINGS.components.webkassaChecksButton.checkNumber + props.el?.id}
          </p>
          <div className="sum-text-wrapper">
            <p className="sum-text">
              {STRINGS.components.webkassaChecksButton.status}
              <span className="title-text">
                {' '}
                {props.el?.isSuccess
                  ? STRINGS.components.webkassaChecksButton.success
                  : STRINGS.components.webkassaChecksButton.failed}
              </span>
            </p>
          </div>
        </div>
        <div className="additional-info-wrapper">
          <p className="info-title">
            {STRINGS.components.webkassaChecksButton.checkFiscalNumber}
            <span className="info-text"> {props.el?.webkassaCheckNumber || '-'}</span>
          </p>
          <p className="info-title">
            {STRINGS.components.currentOrdersButton.orderTime}
            <span className="info-text">
              {' '}
              {props.el?.receiptDateTime &&
                new Date(props.el?.receiptDateTime).toLocaleTimeString()}
            </span>
          </p>
          <p className="info-title">
            {STRINGS.components.webkassaChecksButton.total}
            <span className="info-text">
              {' '}
              {JSON.parse(props.el.orderRequest).Payments.reduce((acc, payment) => {
                return acc + payment.Sum;
              }, 0)}
            </span>
          </p>
          <p className="info-title">
            {STRINGS.components.webkassaChecksButton.operationType}
            <span className="info-text"> {WEBKASSA_OPERATION_TYPES[props.el?.operationType]}</span>
          </p>
          <p className="info-title">
            {STRINGS.components.webkassaChecksButton.aggregator}
            <span className="info-text">
              {' '}
              {aggregators.find((aggregator) => aggregator.id === props.el?.aggregatorId)?.name}
            </span>
          </p>
        </div>
      </a>
    </div>
  );
};

export default WebkassaCheck;

/**
 * @prettier
 */

import React, { Fragment } from 'react';
import STRINGS from '../../../localization';
import { Table } from '../../../main/ui-kit';

import './styles.scss';

const AggregatorName = {
  glovo: STRINGS.components.aggregatorButton.aggGlovoName,
  chocofood: STRINGS.components.aggregatorButton.aggChocoName,
  wolt: STRINGS.components.aggregatorButton.aggWoltName,
  yandex: STRINGS.components.aggregatorButton.aggYandexName,
};

export const AggregatorsStatusView = (props) => {
  return (
    <>
      <div className="summary_table">
        <Table
          head={() => (
            <tr className="aggregators_summary_table_heading">
              <th>{STRINGS.screens.aggregators.aggregators}</th>
              <th>{STRINGS.screens.restaurants.status}</th>
              <th>{STRINGS.screens.restaurants.lastUpdate}</th>
            </tr>
          )}
        >
          {Object.entries(props.aggregators)?.map(([key, value]) => (
            <Fragment key={key}>
              <tr className="aggregators_summary_table_body">
                <td>{AggregatorName[key]}</td>
                <td>
                  {value?.isOpen ? STRINGS.screens.restaurants.qr : STRINGS.screens.restaurants.qz}
                </td>
                <td>{value?.closedUpdatedAt || '10.10.2010'}</td>
              </tr>
            </Fragment>
          ))}
        </Table>
      </div>
    </>
  );
};

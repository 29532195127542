/**
 * @prettier
 */

import { useState } from 'react';
import PropTypes from 'prop-types';

import icons from './icons';

import { useComponentWillMount } from '../../main/utils/hooks';
import colors from '../../styles/colors';

const REM_ENABLED = true;
const HTML_FONT_SIZE = 16;

/**
 * @component Icon
 * @example
 * <Icon
 *  name="logo"
 *  width={136}
 *  height={56}
 *  color="#000"
 * />
 */
const Icon = (props) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const convertPxToRem = () => {
    if (REM_ENABLED) {
      setWidth(`${props.width / HTML_FONT_SIZE}rem`);
      setHeight(`${props.height / HTML_FONT_SIZE}rem`);
      return;
    }
    setWidth(props.width);
    setHeight(props.height);
  };

  useComponentWillMount(() => {
    convertPxToRem();
  });

  return icons[props.name]?.code({ width, height, color: props.color }) || null;
};

/**
 * Properties
 */

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
Icon.defaultProps = {
  width: 36,
  height: 36,
  color: colors.color_gray._4,
};

export default Icon;

/**
 * @prettier
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Icon } from '../../ui-kit';
import './styles.scss';
import colors from '../../styles/colors';

/**
 * @component HRowDropdownItem
 * @example
 * <HRowDropdownItem
 *  icon="layer"
 *  name="Storybook"
 *  data={[
 *    { name: 'Buttons', to: '/storybook/buttons/' }
 *  ]}
 * />
 */
const HRowDropdownItem = (props) => {
  const [visible, setVisible] = useState(true);

  const renderRowArrowClassName = () => {
    let str = 'dropdown-arrow';
    if (visible) {
      str += ' dropdown-arrow-up';
    } else {
      str += ' dropdown-arrow-down';
    }
    return str;
  };

  const renderChildrensClassName = () => {
    let str = 'dropdown-children';
    if (visible) {
      str += ' dropdown-children-visible';
    }
    return str;
  };

  return (
    <div className="sidebar-dropdown-list">
      <button onClick={() => setVisible(!visible)}>
        <div>
          {props.icon && (
            <Icon name={props.icon} color={colors.color_gray._4} width={24} height={24} />
          )}
          {props.name && <span>{props.name}</span>}
        </div>
        <div className={renderRowArrowClassName()}>
          <Icon name="arrow-down" color={colors.color_gray._4} width={16} height={16} />
        </div>
      </button>
      {props.linkList?.map((item, index) => (
        <div key={`dropdown-item-${index}`} className={renderChildrensClassName()}>
          <Link
            data-active={item.active}
            to={item.to}
            onClick={() => props.setSidebarVisible(false)}
          >
            {item.name && <span>{item.name}</span>}
          </Link>
        </div>
      ))}
    </div>
  );
};

/**
 * Properties
 */

HRowDropdownItem.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  linkList: PropTypes.array,
  setSidebarVisible: PropTypes.func,
};
HRowDropdownItem.defaultProps = {
  icon: null,
  name: null,
  linkList: null,
  setSidebarVisible: () => {},
};

export default HRowDropdownItem;

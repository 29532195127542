/**
 * @prettier
 */

import React from 'react';

import STRINGS from '../../../localization';
import ProductWishes from '../product-wishes';
import './styles.scss';

const Product = ({ el = {} }) => {
  return (
    <div className="item-container">
      <div className="wrapper">
        <div className="info-wrapper-element">
          <div className="info-container">
            <div className="title-wrapper">
              <span className="title">
                {el.name} х {el.quantity}
              </span>
            </div>
            <ProductWishes modificators={el.modificatorsToAdd} isAdd={true} />
            <ProductWishes modificators={el.modificatorsToPutAway} isAdd={false} />
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div />
        <div className="price-container">
          <span className="price">
            {el.price} {STRINGS.components.descriptionRestaurantModal.currency}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Product;

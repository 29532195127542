import React from 'react';
import STRINGS from '../../../localization';
import { Head } from '../../components';
import ComboFields from '../../components/modals/combo-fields';
import { Paper, Checkbox } from '../../ui-kit';
import '../styles.scss';
import Skelet from './skelet';
import { URL } from '../../../services/config';
import { comboSizes } from '../../../constants/categories';
import NOPHOTO from '../../../assets/nophoto.png';
import { generatePrice } from '../../utils/generate-price';

const View = (props) => {
  const { combo, langId } = props;
  return (
    <>
      <ComboFields
        saveChanges={props.saveChanges}
        getModificatorById={props.getModificatorById}
        getModificators={props.getModificators}
        langId={props.langId}
        loading={props.loading}
        setLoading={props.setLoading}
        getSubstitude={props.getSubstitude}
        getFileById={props.getFileById}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'edit'}
        title={STRINGS.screens.combo.editCombo}
        loadedCombo={combo}
        getProductById={props.getComboById}
        languages={props.languages}
      />
      <Head
        label={
          !props.skeletVisible ? (combo?.definition && combo?.definition?.definitionData?.find((el) => el?.languageId === props.langId)?.title) || STRINGS.screens.modal.noTitle :
          STRINGS.screens.modal.qq
        }
        button={{
          size: 'medium',
          icon: false,
          label: STRINGS.screens.combo.editCombo,
          onClick: () => props.setShowModal('edit'),
        }}
        status={props.history?.location?.state?.status}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.combo.combo,
            link: '/combo',
          },
          {
            label: props.comboId,
          },
        ]}
      />
      {props.skeletVisible || Object.keys(combo).length === 0 ? (
        <Skelet />
      ) : (
        <Paper>
          <div className="combo-view-container">
            <div>
              <div className="block">
                <div className="picture">
                  {combo?.fileId ? (
                    <img className="image" src={`${URL}files/${combo?.fileId}`} />
                  ) : (
                    <img className="image" src={NOPHOTO} />
                  )}
                </div>
              </div>
              <div className="block">
                <div className="position-container">
                  <div className="text">
                    <span className="label">{STRINGS.screens.combo.qy}</span>
                    <input
                      type="number"
                      className="input-id"
                      value={props.serialNumber}
                      onChange={async (e) => {
                        if (e.target.value.length <= 3) {
                          props.setSerialNumber(e.target.value);
                        }
                      }}
                      max="999"
                      data-invalid={props.positionExisting === true}
                      min="1"
                    />
                  </div>
                </div>
                <div>
                  {props.positionExisting === true &&
                    props.serialNumber !== combo?.serialNumber && (
                      <span className="error">{STRINGS.screens.combo.qu}</span>
                    )}
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.modal.general}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.goods.id}:</span>
                  <span>{combo?.posId || STRINGS.screens.modal.noData}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.combo.qh}:</span>
                  <span>
                    {generatePrice(combo?.prices[0]?.amount || combo?.prices[0]?.amountBig)}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.combo.qj}:</span>
                  <span>
                    {generatePrice(combo?.prices[1]?.amount || combo?.prices[1]?.amountBig)}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.combo.qk}:</span>
                  <span>
                    {generatePrice(combo?.prices[2]?.amount || combo?.prices[2]?.amountBig)}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.combo.ql}:</span>
                  <span>
                    {generatePrice(combo?.prices[3]?.amount || combo?.prices[3]?.amountBig)}
                  </span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.combo.qz}:</span>
                  <span>{combo?.isDeal ? STRINGS.screens.combo.qx : STRINGS.screens.combo.qv}</span>
                </div>
                <div className="row">
                  <span>{STRINGS.screens.combo.qg}</span>
                  <span>
                    {combo?.comboMainProduct?.definition?.definitionData?.find(
                      (e) => e?.languageId === langId,
                    ).title || `${combo?.comboMainProduct?.posTitle}`}
                  </span>
                </div>
              </div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.combo.description}</span>
                </div>
                <div className="data">
                  <span>
                    {combo?.definition?.definitionData?.find((el) => el.languageId === props.langId)
                      .description || STRINGS.screens.modal.noData}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="block">
                <div className="subtitle">
                  <span>{STRINGS.screens.combo.qo}</span>
                </div>
                <div className="combo-details">
                  <div className="toolbar">
                    <div className="buttons">
                      {comboSizes?.map((el) => (
                        <button
                          data-active={props.comboSize.includes(el?.label)}
                          onClick={() => props.setComboSize(el?.label)}
                        >
                          {el?.label}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="content-table">
                    <table>
                      <thead>
                        <tr>
                          <th />
                          <th>{STRINGS.screens.combo.qa}</th>
                          <th>{STRINGS.screens.combo.qs}</th>
                          <th>{STRINGS.screens.combo.qd}</th>
                          <th>{STRINGS.screens.combo.qf}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {combo?.comboSizes
                          ?.find((el) => el?.size.includes(props.comboSize))
                          ?.comboSizePositions?.map((el, key) => (
                            <>
                              <tr>
                                <th className="position">{`${STRINGS.screens.combo.qp} ${el?.position}`}</th>
                              </tr>
                              {el?.comboSizePositionProducts?.map((k, key2) => (
                                <tr>
                                  <td>
                                    {k?.product?.definition?.definitionData.find(
                                      (e) => e?.languageId === langId,
                                    )?.title ||
                                      `${k?.product?.posTitle} ${STRINGS.screens.combo.qi}`}
                                  </td>
                                  <td>
                                    <div>
                                      <Checkbox
                                        checked={k?.isMain}
                                        onChange={() =>
                                          props.patchProductAvailibility(
                                            {
                                              id: k?.id,
                                              isMain: !k?.isMain,
                                              available: k?.available,
                                              availableDelivery: k?.availableDelivery,
                                              isMainDelivery: k?.isMainDelivery,
                                            },
                                            key,
                                            key2,
                                            'isMain',
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <Checkbox
                                        checked={k?.isMainDelivery}
                                        onChange={() =>
                                          props.patchProductAvailibility(
                                            {
                                              id: k?.id,
                                              isMain: k?.isMain,
                                              available: k?.available,
                                              availableDelivery: k?.availableDelivery,
                                              isMainDelivery: !k?.isMainDelivery,
                                            },
                                            key,
                                            key2,
                                            'isMainDelivery',
                                          )
                                        }
                                      />
                                    </div>
                                  </td>

                                  <td>
                                    <div>
                                      <Checkbox
                                        checked={k?.available}
                                        onChange={() =>
                                          props.patchProductAvailibility(
                                            {
                                              id: k?.id,
                                              isMain: k?.isMain,
                                              available: !k?.available,
                                              availableDelivery: k?.availableDelivery,
                                              isMainDelivery: k?.isMainDelivery,
                                            },
                                            key,
                                            key2,
                                            'available',
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <Checkbox
                                        checked={k?.availableDelivery}
                                        onChange={() =>
                                          props.patchProductAvailibility(
                                            {
                                              id: k?.id,
                                              isMain: k?.isMain,
                                              available: k?.available,
                                              availableDelivery: !k?.availableDelivery,
                                              isMainDelivery: k?.isMainDelivery,
                                            },
                                            key,
                                            key2,
                                            'availableDelivery',
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                              {el?.comboSizePositionModificators?.map((k, key2) => (
                                <tr>
                                  <td>
                                    {k?.modificator?.definition?.definitionData.find(
                                      (e) => e?.languageId === langId,
                                    )?.title ||
                                      `${k?.modificator?.posTitle} ${STRINGS.screens.combo.qi}`}
                                  </td>
                                  <td>
                                    <div>
                                      <Checkbox
                                        checked={k?.isMain}
                                        onChange={() =>
                                          props.patchModificatorAvailibility(
                                            {
                                              id: k?.id,
                                              isMain: !k?.isMain,
                                              available: k?.available,
                                              availableDelivery: k?.availableDelivery,
                                              isMainDelivery: k?.isMainDelivery,
                                            },
                                            key,
                                            key2,
                                            'isMain',
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <Checkbox
                                        checked={k?.isMainDelivery}
                                        onChange={() =>
                                          props.patchModificatorAvailibility(
                                            {
                                              id: k?.id,
                                              isMain: k?.isMain,
                                              available: k?.available,
                                              availableDelivery: k?.availableDelivery,
                                              isMainDelivery: !k?.isMainDelivery,
                                            },
                                            key,
                                            key2,
                                            'isMainDelivery',
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <Checkbox
                                        checked={k?.available}
                                        onChange={() =>
                                          props.patchModificatorAvailibility(
                                            {
                                              id: k?.id,
                                              isMain: k?.isMain,
                                              available: !k?.available,
                                              availableDelivery: k?.availableDelivery,
                                              isMainDelivery: k?.isMainDelivery,
                                            },
                                            key,
                                            key2,
                                            'available',
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <Checkbox
                                        checked={k?.availableDelivery}
                                        onChange={() =>
                                          props.patchModificatorAvailibility(
                                            {
                                              id: k?.id,
                                              isMain: k?.isMain,
                                              available: k?.available,
                                              availableDelivery: !k?.availableDelivery,
                                              isMainDelivery: k?.isMainDelivery,
                                            },
                                            key,
                                            key2,
                                            'availableDelivery',
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      )}
    </>
  );
};
export default View;

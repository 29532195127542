/**
 * @prettier
 */

import { api, debug } from '../index';
import { URL } from '../config';

export default class GlovoService {
  static #API_ENDPOINTS = {
    glovo: 'glovo-v2/',
    closing: '/closing',
  };

  static async getClosing(restaurantId) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.glovo}${restaurantId}${this.#API_ENDPOINTS.closing}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const res = await request.json();

      debug.success(`Get closing from Glovo for ${restaurantId} restaurant`, res);
      return res;
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.glovo}${restaurantId}${
          this.#API_ENDPOINTS.closing
        }`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async setGlovoClosing(props) {
    try {
      const request = await api.put(
        `${URL}${this.#API_ENDPOINTS.glovo}${props.id}${this.#API_ENDPOINTS.closing}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({ until: props.date }),
        },
      );

      debug.success('Glovo set closing retrieval result', request);

      return request.json();
    } catch {
      return null;
    }
  }
  static async deleteGlovoClosing(restaurantId) {
    try {
      const request = await api.delete(
        `${URL}${this.#API_ENDPOINTS.glovo}${restaurantId}${this.#API_ENDPOINTS.closing}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Glovo delete closing retrieval result', request);

      return request.json();
    } catch {
      return null;
    }
  }

  static async changeClosing(restaurantId, until) {
    try {
      const request = await api.put(
        `${URL}${this.#API_ENDPOINTS.glovo}${restaurantId}${this.#API_ENDPOINTS.closing}`,
        {
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(until),
        },
      );

      debug.success('Glovo change closing retrieval result');

      return true;
    } catch (error) {
      debug.error(
        `Failed with PUT request by path: ${URL}${this.#API_ENDPOINTS.glovo}${restaurantId}${
          this.#API_ENDPOINTS.closing
        }`,
        error,
      );
      throw await error.response?.json();
    }
  }

  static async deleteClosing(restaurantId) {
    try {
      const request = await api.delete(
        `${URL}${this.#API_ENDPOINTS.glovo}${restaurantId}${this.#API_ENDPOINTS.closing}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Glovo delete closing retrieval result');
      return true;
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.glovo}${restaurantId}${
          this.#API_ENDPOINTS.closing
        }`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

/**
 * @prettier
 */

import { api, debug } from '../index';
import { HTTP_STATUS, URL } from '../config';

export default class PunchcardsService {
  static #API_ENDPOINTS = {
    punchcards: 'punch-cards',
  };
  static async updatePunchcard({ id, data }) {
    try {
      const request = await api.patch(`${URL}${this.#API_ENDPOINTS.punchcards}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success(request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request;
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.punchcards}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getPunchcards({ langId, page, size }) {
    try {
      const request = await api.get(
        `${URL}${this.#API_ENDPOINTS.punchcards}?langId=${langId}&page=${page}&size=${size}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      debug.success('Punchcard data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${
          this.#API_ENDPOINTS.punchcards
        }?langId=${langId}&page=${page}&size=${size}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async getPunchcardById({ id }) {
    try {
      const request = await api.get(`${URL}${this.#API_ENDPOINTS.punchcards}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success('Coupon data retrieval result', request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with GET request by path: ${URL}${this.#API_ENDPOINTS.punchcards}/${id}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async updateStatus(id, status) {
    try {
      const request = await api.put(`${URL}${this.#API_ENDPOINTS.punchcards}/${id}/status`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(status),
      });

      debug.success('Punchcard data retrieval result', request);
    } catch (error) {
      debug.error(
        `Failed with PATCH request by path: ${URL}${this.#API_ENDPOINTS.punchcards}/${id}/status`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async addPunchcard(data) {
    try {
      const request = await api.post(`${URL}${this.#API_ENDPOINTS.punchcards}`, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success(request);
    } catch (error) {
      debug.error(
        `Failed with POST request by path: ${URL}${this.#API_ENDPOINTS.punchcards}`,
        error,
      );
      throw await error.response?.json();
    }
  }
  static async deletePunchcard({ id: id }) {
    try {
      const request = await api.delete(`${URL}${this.#API_ENDPOINTS.punchcards}/${id}`, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success(request);

      if (request.status !== HTTP_STATUS.SUCCESS) {
        return Promise.reject(`Incorrect status ${request.status}`);
      }

      return request.json();
    } catch (error) {
      debug.error(
        `Failed with DELETE request by path: ${URL}${this.#API_ENDPOINTS.punchcards}`,
        error,
      );
      throw await error.response?.json();
    }
  }
}

import React, { useEffect, useState } from 'react';

import View from './view';
import UsersService from '../../../services/users';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import STRINGS from '../../../localization';
import { Toast } from '../../ui-kit';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { languages } = useSelector((store) => store.stuff);

  const [loading, setLoading] = useState(false);

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [user, setUser] = useState(null);

  const [showModal, setShowModal] = useState('');

  const history = useHistory();

  let { id } = useParams();

  const { active } = history.location.state;

  const getUser = async () => {
    setSkeletVisible(true);
    try {
      const resp = await UsersService.getUserById(id);
      setUser(resp);
      setSkeletVisible(false);
    } catch (error) {
      setSkeletVisible(false);
    }
  };

  const saveChanges = async (e) => {
    setLoading(true);
    try {
      const result = await UsersService.updateUser(id, e);
      setUser(result);
      Toast.success(STRINGS.notify.success);
    } catch (error) {
      Toast.error(STRINGS.notify.failure);
    }
    setLoading(false);
    setShowModal('');
  };

  useEffect(() => {
    getUser();
  }, [id]);

  return (
    <View
      saveChanges={saveChanges}
      active={active}
      id={id}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      loading={loading}
      setLoading={setLoading}
      langId={langId}
      user={user}
      history={history}
      setSkeletVisible={setSkeletVisible}
      skeletVisible={skeletVisible}
    />
  );
};

export default Container;

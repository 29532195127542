/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';

import YandexDiscountsView from './yandexDiscounts-view';
import { Toast } from '../../ui-kit';
import { translate } from '../../utils/translate-aggregator';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { orderCategories } from '../../../constants/categories';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import STRINGS from '../../../localization';
import YandexDiscountsService from '../../../services/yandexDiscounts';
import { TYPES } from '../../../constants/yandex-discount-types';

const YandexDiscountsContainer = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItemId, setCurrentItemId] = useState(0);
  const [bottomLoading, setBottomLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(orderCategories[0]);
  let { id } = useParams();
  const history = useHistory();

  const [orders, setOrders] = useState();
  const [showModal, setShowModal] = useState('');
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showConfirm, setShowConfirm] = useState('');
  const [skeletVisible, setSkeletVisible] = useState(false);

  const getOrders = async (props) => {
    try {
      return await YandexDiscountsService.getYandexDiscounts(props);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const changeStatus = async (id, status) => {
    try {
      await YandexDiscountsService.updateStatus(id, status);
      let temp = [...orders?.data];
      temp.find((el) => el.id === id).isEnabled = status;
      setOrders((old) => ({ isLast: old.isLast, data: temp }));
      Toast.success(STRINGS.notify.status);
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getNextPage = async () => {
    setBottomLoading(true);
    try {
      const resp = await YandexDiscountsService.getYandexDiscounts({
        page: page + 1,
        size: 30,
        restaurantId: id,
      });
      setBottomLoading(false);
      setPage((old) => old + 1);
      setOrders({
        isLast: resp.isLast,
        data: [...orders.data, ...resp?.data],
      });
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
      setBottomLoading(false);
    }
  };
  useBottomScrollListener(() => {
    if (!bottomLoading && !orders.isLast) {
      getNextPage();
    }
  });

  useEffect(() => {
    const getList = async (props) => {
      setSkeletVisible(true);
      setPage(1);
      const resp = await getOrders(props);
      if (resp) {
        setOrders({
          isLast: resp?.isLast,
          data: [...resp?.data],
        });
        setSkeletVisible(false);
      }
    };
    getList({
      page: 1,
      size: 30,
      restaurantId: id,
    });
  }, [selectedCategory]);

  const addYandexDiscounts = async (f) => {
    let dataToSend = {
      type: f.type,
      restaurantId: f.restaurantId,
      name: f.name,
      discount: f.discount,
      yandexDiscountProducts: f.yandexDiscountProducts || [],
      yandexDiscountCombos: f.yandexDiscountCombos || [],
      yandexDiscountModificators: f.yandexDiscountModificators || [],
    };

    let dataToSeveralRestaurants = {
      type: f.type,
      forAll: f.forAll,
      restaurants: f.forAll
        ? []
        : f.groupRestaurants.map((restaurant) => {
            return {
              restaurantId: restaurant.id,
            };
          }),
      name: f.name,
      discount: f.discount,
      yandexDiscountProducts: f.yandexDiscountProducts || [],
      yandexDiscountCombos: f.yandexDiscountCombos || [],
      yandexDiscountModificators: f.yandexDiscountModificators || [],
    };
    try {
      if (f.groupRestaurants.length < 1 && f.groupRestaurants[0].id === f.restaurantId) {
        await YandexDiscountsService.addYandexDiscounts(dataToSend);
      } else {
        await YandexDiscountsService.addYandexDiscountsForSeveralRestaurants(
          dataToSeveralRestaurants,
        );
      }

      const getList = async (props) => {
        setSkeletVisible(true);
        setPage(1);
        const resp = await getOrders(props);
        if (resp) {
          setOrders({
            isLast: resp?.isLast,
            data: [...resp?.data],
          });
          setSkeletVisible(false);
        }
      };
      await getList({
        page: 1,
        size: 30,
        restaurantId: id,
      });
      Toast.success(STRINGS.notify.qw);
    } catch (error) {
      const getList = async (props) => {
        setSkeletVisible(true);
        setPage(1);
        const resp = await getOrders(props);
        if (resp) {
          setOrders({
            isLast: resp?.isLast,
            data: [...resp?.data],
          });
          setSkeletVisible(false);
        }
      };
      await getList({
        page: 1,
        size: 30,
        restaurantId: id,
      });
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
    setShowModal('');
  };

  const handleDeleteOrder = async () => {
    if (currentItemId) {
      try {
        await YandexDiscountsService.deleteYandexDiscount({ id: currentItemId });
        Toast.success(STRINGS.notify.status);
      } catch (error) {
        if (error?.status) {
          const getList = async (props) => {
            setSkeletVisible(true);
            setPage(1);
            const resp = await getOrders(props);
            if (resp) {
              setOrders({
                isLast: resp?.isLast,
                data: [...resp?.data],
              });
              setSkeletVisible(false);
            }
          };
          await getList({
            page: 1,
            size: 30,
            restaurantId: id,
          });
          Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
        }
      }
    }
    const getList = async (props) => {
      setSkeletVisible(true);
      setPage(1);
      const resp = await getOrders(props);
      if (resp) {
        setOrders({
          isLast: resp?.isLast,
          data: [...resp?.data],
        });
        setSkeletVisible(false);
      }
    };
    await getList({
      page: 1,
      size: 30,
      restaurantId: id,
    });
    setShowConfirm('');
    setCurrentItemId(0);
  };

  return (
    <YandexDiscountsView
      langId={langId}
      restaurantId={id}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      history={history}
      //----------------------------------------------------------------
      orders={orders}
      handleDeleteOrder={handleDeleteOrder}
      selectedOrder={selectedOrder}
      setSelectedOrder={setSelectedOrder}
      //----------------------------------------------------------------
      addYandexDiscounts={addYandexDiscounts}
      setCurrentItemId={setCurrentItemId}
      showModal={showModal}
      setShowModal={setShowModal}
      showConfirm={showConfirm}
      setShowConfirm={setShowConfirm}
      skeletVisible={skeletVisible}
      changeStatus={changeStatus}
      translateAggregator={translate}
      TYPES={TYPES}
      bottomLoading={bottomLoading}
    />
  );
};

export default YandexDiscountsContainer;

export const aggregators = {
    glovo: 'glovo',
    chocofood: 'chocofood',
    wolt: 'wolt',
    yandex: 'yandex',
  };

export const aggregatorName = (id) => {
  switch(id) {
    case 1: return 'Glovo'
    case 2: return 'Chocofood'
    case 3: return 'Yandex'
    case 4: return 'Wolt'
  }
}  

export const groupByEnum = {
  restaurant: 'RESTAURANT',
  region: 'REGION',
};

export const DAY = 'DAY';
export const WEEK = 'WEEK';


/**
 * @prettier
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import './styles.scss';

import { Icon } from '../../ui-kit';
import Skeleton from '../skeleton';

const MIME_TYPES = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/msword': 'doc',
  'application/pdf': 'pdf',
};

/**
 * @component Dropzone
 * @example
 * <Dropzone />
 */
const SingleDropzone = (props) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
    maxFiles: props.maxFiles,
    name: props.name,
    onDrop: (acceptedFiles) => {
      props.onChange(
        acceptedFiles.map((f) =>
          Object.assign(f, {
            preview: URL.createObjectURL(f),
          }),
        ),
      );
    },
  });

  useEffect(
    () => () => {
      URL.revokeObjectURL(props.files?.preview);
    },
    [props.files],
  );
  return (
    <>
      <div
        disabled={props.disabled}
        className={`uploading-files ${props.className}`}
        name={props.name}
      >
        {props.skelet ? (
          <div className="dropzone">
            <Skeleton className="circle" width="fit-content">
              <Icon name="files" width={64} height={64} />
            </Skeleton>
            {props.title && (
              <Skeleton className="description" width="fit-content">
                <p>****************************************************************</p>
              </Skeleton>
            )}
          </div>
        ) : (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} name="file" maxFiles={props.maxFiles} />
            {props.files.length === 0 ? (
              <Icon name="background" width={85} height={85} />
            ) : (
              <div className="file-container">
                <div
                  className="file-container_preview"
                  style={{ backgroundImage: `url(${props.files[0].preview})` }}
                />
                <div className="file-container_title">
                  <p>{props.files[0].name}</p>
                  <p>{`Size: ${props.files[0].size}B`}</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

/**
 * Properties
 */

SingleDropzone.propTypes = {
  title: PropTypes.string,
  maxFiles: PropTypes.number,
  onChange: PropTypes.func,
  files: PropTypes.array,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  skelet: PropTypes.bool,
  iconName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
};
SingleDropzone.defaultProps = {
  title: null,
  maxFiles: 1,
  name: 'file',
  onChange: () => {},
  files: [],
  accept: 'image/*',
  disabled: false,
  skelet: false,
  iconName: 'files',
  className: '',
};

export default SingleDropzone;

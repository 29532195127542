import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { CustomTime, Icon, Button, Checkbox } from '..';
import { SERVICE_VALUES } from '../../../constants/service-types';
import { days } from '../../../constants/week';
import STRINGS from '../../../localization';

const WeekPicker = (props) => {
  const [openTime, setOpenTime] = useState();
  const [closeTime, setCloseTime] = useState();

  useEffect(() => {
    if (props.data) {
      props.setWorkingHours(props.data);
    }
  }, []);

  useEffect(() => {
    const parseTime = (val, type) => {
      return val.reduce(
        (acc, el) => ({
          ...acc,
          [`${el.day},${el.serviceTypeId}`]: el[type],
        }),
        0,
      );
    };
    if (props.workingHours) {
      setOpenTime(parseTime(props.workingHours, 'openTime'));
      setCloseTime(parseTime(props.workingHours, 'closeTime'));
    }
  }, [props.workingHours, props.openTime]);

  const [service, setService] = useState(1);
  const [universal, setUniversal] = useState(false);
  const [universalOpenTime, setUniversalOpenTime] = useState(new Date(new Date(0, 0, 0, 0, 0)));
  const [universalCloseTime, setUniversalCloseTime] = useState(new Date(new Date(0, 0, 0, 0, 0)));

  const handleOperationMode = (tab) => {
    let temp = [...props.workingHours];
    let selected = temp.find((el) => el.day == tab.key && el.serviceTypeId == service);
    if (selected) {
      switch (selected?.operatingMode) {
        case 'DAY': {
          selected.operatingMode = 'DAY_AND_NIGHT';
          break;
        }
        case 'DAY_AND_NIGHT': {
          selected.operatingMode = 'DAY';
          break;
        }
      }
    } else {
      temp.push({
        serviceTypeId: Number.parseInt(service),
        day: tab.key,
        openTime: new Date(new Date(0, 0, 0, 0, 0)).toISOString(),
        closeTime: new Date(new Date(0, 0, 0, 0, 0)).toISOString(),
        operatingMode: 'DAY',
      });
      let selected = temp.find((el) => el.day == tab.key && el.serviceTypeId == service);
      selected.operatingMode = 'DAY_AND_NIGHT';
    }
    props.setWorkingHours(temp);
  };

  const handleUniversalTime = (e, hourType) => {
    let temp = [...props.workingHours];
    let selected = temp.filter((el) => el.serviceTypeId == service);
    if (selected) {
      selected.forEach((el) => (el[hourType] = e));
    }
    props.setWorkingHours(temp);
  };

  const handleOperationTypeButtonAppearance = (tab) => {
    let temp = [...props.workingHours];
    let selected = temp.find((el) => el.day == tab.key && el.serviceTypeId == service);
    if (selected) {
      switch (selected.operatingMode) {
        case 'DAY': {
          return { type: 'default', outline: true, fieldDisabled: false, buttonDisabled: false };
        }
        case 'DAY_AND_NIGHT': {
          return { type: 'primary', outline: false, fieldDisabled: true, buttonDisabled: false };
        }
      }
    } else {
      return { type: 'default', outline: true, buttonDisabled: false, fieldDisabled: false };
    }
  };
  return (
    <div className={`week-container ${props.className}`}>
      <div className="buttons">
        {SERVICE_VALUES.map((el) => (
          <>
            <Button
              type={service == el.key ? 'primary' : 'default'}
              onClick={() => setService(el.key)}
              label={el.label}
              size="medium"
            />
          </>
        ))}
        <Checkbox
          disabled={props.workingHours.length < SERVICE_VALUES.length * days.length}
          label="Расписание"
          checked={!universal}
          onChange={() => setUniversal(!universal)}
        />
      </div>
      <table>
        {universal === true ? (
          <tr className="universal">
            <td>{STRINGS.screens.restaurants.workingHours}</td>
            <td>
              <div className="times">
                <CustomTime
                  value={universalOpenTime}
                  onChange={(e) => {
                    handleUniversalTime(e, 'openTime');
                    setUniversalOpenTime(e);
                  }}
                />
                <div className="dash">
                  <Icon name="minus" color="rgba(0, 0, 0, 0.8)" width={13} height={24} />
                </div>
                <CustomTime
                  value={universalCloseTime}
                  onChange={(e) => {
                    handleUniversalTime(e, 'closeTime');
                    setUniversalCloseTime(e);
                  }}
                />
              </div>
            </td>
          </tr>
        ) : (
          days.map((tab) => (
            <tr data-active={tab.active}>
              <td>{tab.label}</td>
              <td>
                <div className="times">
                  <CustomTime
                    disabled={handleOperationTypeButtonAppearance(tab)?.fieldDisabled}
                    value={openTime && openTime[`${tab.key},${service}`]}
                    onChange={(e) => {
                      let temp = [...props.workingHours];
                      let selected = temp.find(
                        (el) => el.day == tab.key && el.serviceTypeId == service,
                      );
                      if (selected) {
                        selected.openTime = e;
                      } else {
                        temp.push({
                          serviceTypeId: Number.parseInt(service),
                          day: tab.key,
                          openTime: e,
                          closeTime: new Date(new Date(0, 0, 0, 0, 0)).toISOString(),
                          operatingMode: 'DAY',
                        });
                      }
                      props.setWorkingHours(temp);
                    }}
                  />
                  <div className="dash">
                    <Icon name="minus" width={13} height={24} />
                  </div>
                  <CustomTime
                    disabled={handleOperationTypeButtonAppearance(tab)?.fieldDisabled}
                    value={closeTime && closeTime[`${tab.key},${service}`]}
                    onChange={(e) => {
                      let temp = [...props.workingHours];
                      let selected = temp.find(
                        (el) => el.day == tab.key && el.serviceTypeId == service,
                      );
                      if (selected) {
                        selected.closeTime = e;
                      } else {
                        temp.push({
                          serviceTypeId: Number.parseInt(service),
                          day: tab.key,
                          openTime: new Date(new Date(0, 0, 0, 0, 0)).toISOString(),
                          closeTime: e,
                          operatingMode: 'DAY',
                        });
                      }
                      props.setWorkingHours(temp);
                    }}
                  />
                  <Button
                    label="24ч"
                    size="medium"
                    disabled={handleOperationTypeButtonAppearance(tab)?.buttonDisabled}
                    type={handleOperationTypeButtonAppearance(tab)?.type}
                    outline={handleOperationTypeButtonAppearance(tab)?.outline}
                    onClick={() => {
                      handleOperationMode(tab);
                    }}
                  />
                </div>
              </td>
            </tr>
          ))
        )}
      </table>
    </div>
  );
};

WeekPicker.propTypes = {
  defaultValue: PropTypes.string,
  className: PropTypes.string,
};

WeekPicker.defaultProps = {
  default: [],
  workingHours: [],
  defaultValue: '',
  className: '',
  onChange: () => {},
  onBlur: () => {},
};

export default WeekPicker;

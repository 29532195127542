/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import { Icon, Loader } from '..';

/**
 * @component Button
 * @example
 * <Button
 *  size="medium"
 *  type="primary"
 *  outline={true}
 *  label="Primary"
 *  onClick={() => {}}
 * />
 */
const Button = (props) => {
  const renderClassName = () => {
    let str = `btn-root ${sizes[props.size]}`;
    if (props.type) {
      str += ` ${buttonConfigList[props.type]}`;
    }
    if (props.outline) {
      str += ' outline';
    }
    if (props.className) {
      str += ` ${props.className}`;
    }
    return str;
  };

  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
      className={renderClassName()}
      type={props.typeButton}
    >
      {props.icon.name && (!props.icon.side || props.icon.side === 'left') && (
        <div className="icon left">
          <Icon width={props.icon.width} height={props.icon.height} name={props.icon.name} />
        </div>
      )}
      <span className={props.loading ? 'opacity' : ''}>{props.label}</span>
      {props.icon.name && props.icon.side === 'right' && (
        <div className="icon right">
          <Icon width={props.icon.width} height={props.icon.height} name={props.icon.name} />
        </div>
      )}
      {props.loading && <div className="loading"></div>}
    </button>
  );
};

/**
 * Types
 */

const buttonConfigList = {
  default: 'default',
  danger: 'danger',
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
};

/**
 * Sizes
 */

const sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  add: 'add',
};

/**
 * Properties
 */

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'add']),
  type: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'disabled']),
  outline: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,

  icon: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    side: PropTypes.oneOf(['left', 'right']),
  }),

  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  typeButton: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  size: 'large',
  type: 'primary',
  outline: false,
  label: null,
  onClick: () => {},
  icon: {
    name: null,
    side: 'left',
  },
  loading: false,
  disabled: false,
  typeButton: 'button',
  className: '',
};

export default Button;

import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Confirm, Table, Tooltip } from '../../ui-kit';
import CityFields from '../../components/modals/city-fields';
import SkeletManagers from './skelet';
import { Head } from '../../components';

import STRINGS from '../../../localization';
import './styles.scss';

const CitiesView = (props) => {
  return (
    <>
      <CityFields
        loading={props.loading}
        setLoading={props.setLoading}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'editCity'}
        title={STRINGS.screens.cities.editCity}
        getCityById={props.getCityById}
        selectedCity={props.selectedCity}
        restaurants={props.restaurants}
        saveChanges={props.saveChanges}
        languages={props.languages}
      />
      <CityFields
        loading={props.loading}
        setLoading={props.setLoading}
        onClose={() => props.setShowModal('')}
        isOpen={props.showModal === 'addCity'}
        title={STRINGS.screens.cities.addCity}
        getCityById={props.getCityById}
        restaurants={props.restaurants}
        saveChanges={props.addCity}
        languages={props.languages}
      />
      <Confirm
        visible={props.showConfirm === 'delete'}
        title={STRINGS.screens.roles.modal.confirmAction}
        onClose={() => props.setShowConfirm('')}
        subtitle={`${STRINGS.screens.cities.deleteQuestion}`}
        subtitleBold={props.deletableCity?.title}
        buttons={[
          {
            label: STRINGS.screens.roles.modal.delete,
            onClick: () => props.handleDeleteCity(),
          },
          {
            label: STRINGS.screens.roles.modal.leave,
            outline: true,
            onClick: () => {
              props.setShowConfirm('');
            },
          },
        ]}
      />

      <Head
        label={STRINGS.screens.cities.cities}
        button={{
          icon: { name: 'plus', side: 'left' },
          label: STRINGS.screens.cities.addCity,
          onClick: () => props.setShowModal('addCity'),
        }}
        breadCrumbs={[
          {
            label: STRINGS.components.sidebar.manage,
            link: '/',
          },
          {
            label: STRINGS.screens.cities.cities,
          },
        ]}
      />

      <div>
        <div className="summary_table">
          {props.skeletVisible ? (
            <SkeletManagers />
          ) : (
            <Table
              head={() => (
                <tr className="summary_table_heading">
                  <th>{STRINGS.screens.cities.id}</th>
                  <th>{STRINGS.screens.cities.name}</th>
                  <th>{STRINGS.screens.cities.geo}</th>
                  <th />
                </tr>
              )}
            >
              <>
                {props.cities?.map((item) => (
                  <tr key={item.id} className="summary_table_body unselectable">
                    <td>{item.id}</td>
                    <td>
                      {item.cityData?.find((el) => el.languageId === props.langId)?.name ||
                        STRINGS.screens.modal.noData}
                    </td>
                    <td>
                      {(item?.lat && item?.lng && `${item?.lat} ${item?.lng}`) ||
                        STRINGS.screens.modal.noData}
                    </td>
                    <td className="summary_table_edit">
                      <div className="summary_table_edit_wrapper">
                        <Tooltip title={STRINGS.components.tooltips.delete}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.setDeletableCity({
                                title: item.cityData?.find((el) => el.languageId === props.langId)
                                  .name,
                                id: item?.id,
                              });
                              props.setShowConfirm('delete');
                            }}
                          >
                            <Icon name="trash-outline" width={24} height={24} />
                          </div>
                        </Tooltip>
                        <Tooltip title={STRINGS.components.tooltips.edit}>
                          <div
                            className="summary_table_iconWrapper"
                            onClick={() => {
                              props.setSelectedCity({ id: item?.id });
                              props.setShowModal('editCity');
                            }}
                          >
                            <Icon name="edit" width={24} height={24} />
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

CitiesView.propTypes = {
  languages: PropTypes.array,
  cities: PropTypes.array,
  showModal: PropTypes.string,
  setShowModal: PropTypes.func,
  editableCity: PropTypes.object,
  setEditableCity: PropTypes.func,
  saveChanges: PropTypes.func,
};

CitiesView.defaultProps = {
  languages: [],
  cities: null,
  showModal: '',
  setShowModal: () => {},
  editableCity: {},
  setEditableCity: () => {},
  saveChanges: () => {},
};

export default CitiesView;

/**
 * @prettier
 */
import React, { useEffect, useState } from 'react';

import View from './view';
import { ALLERGENS } from '../../../constants/allergens';
import ComboService from '../../../services/combo';
import MainService from '../../../services/main';
import ModificatorsService from '../../../services/modificators';
import { Toast } from '../../ui-kit';

import MenuService from '../../../services/menu';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import STRINGS from '../../../localization';

const Container = () => {
  const langId = useSelector((store) => store.localization.currentLanguage.id);

  const { languages, categories } = useSelector((store) => store.stuff);

  const [comboSize, setComboSize] = useState('M');

  const [loading, setLoading] = useState(false);

  const [skeletVisible, setSkeletVisible] = useState(true);

  const [modificators, setModificators] = useState([]);

  const [combo, setCombo] = useState();

  const [showModal, setShowModal] = useState('');

  const CATEGORIES = categories?.reduce((acc, el) => [...acc, { label: el.title, key: el.id }], []);

  const history = useHistory();

  const [serialNumber, setSerialNumber] = useState('');

  const [positionExisting, setPositionExisting] = useState(false);

  const [initialPosition, setInitialPosition] = useState();

  let { id } = useParams();

  const getFileId = async (file) => {
    if (file?.id) {
      return file.id;
    } else {
      try {
        const id = await MainService.postFile(file);
        return id?.id;
      } catch (error) {
        Toast.error(STRINGS.notify.fileFailure);
      }
    }
  };

  const patchModificatorAvailibility = async (data, key, key2, type) => {
    let temp = { ...combo };
    temp.comboSizes.find((el) => el?.size.includes(comboSize)).comboSizePositions[
      key
    ].comboSizePositionModificators[key2][type] = !temp.comboSizes.find((el) =>
      el?.size.includes(comboSize),
    ).comboSizePositions[key].comboSizePositionModificators[key2][type];
    setCombo(temp);
    try {
      await ComboService.patchModificatorAvailibility(data);
    } catch (e) {}
  };

  const patchProductAvailibility = async (data, key, key2, type) => {
    let temp = { ...combo };
    (temp.comboSizes.find((el) => el?.size.includes(comboSize)).comboSizePositions[
      key
    ].comboSizePositionProducts[key2][type] = !temp.comboSizes.find((el) =>
      el?.size.includes(comboSize),
    ).comboSizePositions[key].comboSizePositionProducts[key2][type]),
      setCombo(temp);
    try {
      await ComboService.patchProductAvailibility(data);
    } catch (e) {}
  };

  const saveChanges = async (posId, f) => {
    setLoading(true);
    let data = {};
    if (f.categoryId === 9) {
      data = Object.assign(data, { isDeal: f.isDeal });
    }
    if (f.definitionId) {
      data = Object.assign(data, { definitionId: f.definitionId });
    }
    if (f.file) {
      data = Object.assign(data, { fileId: await getFileId(f.file) });
    }
    if (f.glovoFile) {
      data = Object.assign(data, { glovoFileId: await getFileId(f.glovoFile) });
    }
    if (f.yandexFile) {
      data = Object.assign(data, { yandexFileId: await getFileId(f.yandexFile) });
    }
    if (f.chocofoodFile) {
      data = Object.assign(data, { chocofoodFileId: await getFileId(f.chocofoodFile) });
    }
    if (f.woltFile) {
      data = Object.assign(data, { woltFileId: await getFileId(f.woltFile) });
    }
    if (f.categoryId !== f.unmodifiedCombo?.categoryId) {
      try {
        await ComboService.updateCategory(id, { categoryId: f.categoryId });
      } catch (e) {}
    }
    try {
      await ComboService.updateCombo(posId, data);
      getCombo(id);
      setShowModal('');
      Toast.success(STRINGS.notify.success);
    } catch (error) {}
    setTimeout(() => setLoading(false), 100);
  };

  useEffect(async () => {
    const putSerialNumber = async (props) => {
      try {
        await ComboService.putSerialNumber(props);
      } catch (error) {}
    };
    const checkSerialNumberExisting = async (sn) => {
      try {
        const resp = await ComboService.checkSerialNumberExisting(sn);
        return resp?.exists;
      } catch (error) {}
    };
    setPositionExisting(false);
    if (serialNumber?.length) {
      const setPosition = async () => {
        if (Number.parseInt(serialNumber) !== initialPosition) {
          const exists = await checkSerialNumberExisting(serialNumber);
          if (exists === true) {
            setPositionExisting(exists);
            putSerialNumber({ posId: combo.posId, serialNumber: initialPosition });
          } else {
            putSerialNumber({ posId: combo.posId, serialNumber: Number.parseInt(serialNumber) });
          }
        }
      };
      setPosition();
    }
  }, [serialNumber]);

  const getFileById = async (id) => {
    try {
      const resp = MainService.getFileById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getModificatorsSuggestions = async (props) => {
    try {
      const resp = await MenuService.getModificatorsSuggestions(props);
      return resp.map((el) => ({ title: el.value, posId: el.id }));
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  const getModificators = async (props) => {
    try {
      const resp = await ModificatorsService.getModificators(props);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getComboById = async (id) => {
    try {
      const resp = await ComboService.getComboById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };
  const getCombo = async (id) => {
    setSkeletVisible(true);
    try {
      const resp = await ComboService.getComboById(id);
      setCombo(resp);
      setSerialNumber(resp?.serialNumber);
      setInitialPosition(resp?.serialNumber);
      setSkeletVisible(false);
    } catch (error) {
      setSkeletVisible(false);
      Toast.error(STRINGS.notify.failure);
    }
  };
  const getModificatorById = async (id) => {
    try {
      const resp = await ModificatorsService.getModificatorById(id);
      return resp;
    } catch (error) {
      if (error?.status) {
        Toast.error(STRINGS.notify[error?.status] || STRINGS.notify.failure);
      }
    }
  };

  useEffect(async () => {
    getCombo(id);
  }, [id]);

  return (
    <View
      serialNumber={serialNumber}
      setSerialNumber={setSerialNumber}
      positionExisting={positionExisting}
      setPositionExisting={setPositionExisting}
      comboSize={comboSize}
      setComboSize={setComboSize}
      patchModificatorAvailibility={patchModificatorAvailibility}
      patchProductAvailibility={patchProductAvailibility}
      saveChanges={saveChanges}
      getModificatorById={getModificatorById}
      getFileById={getFileById}
      getModificators={getModificators}
      languages={languages}
      showModal={showModal}
      setShowModal={setShowModal}
      getModificatorsSuggestions={getModificatorsSuggestions}
      CATEGORIES={CATEGORIES}
      loading={loading}
      setLoading={setLoading}
      ALLERGENS={ALLERGENS}
      langId={langId}
      comboId={id}
      combo={combo}
      history={history}
      getComboById={getComboById}
      setSkeletVisible={setSkeletVisible}
      skeletVisible={skeletVisible}
      modificators={modificators}
    />
  );
};

export default Container;

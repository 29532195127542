/**
 * @prettier
 */

import React from 'react';

import { Spinner, Paper } from '../../ui-kit';
import './styles.scss';

const Loader = () => (
  <Paper>
    <div className="coupon-view-loader">
      <Spinner />
    </div>
  </Paper>
);

export default Loader;

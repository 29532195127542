/**
 * @prettier
 */

/*
  * ---------------------------------------- *
  |               ATTENTION  !!!             |
  * ---------------------------------------- *
  | For better site performance, do not      |
  | change this constants.                   |
  * ---------------------------------------- *
 */

const REM_ENABLED = true;
const HTML_FONT_SIZE = 16;

const convertPxToRem = (s, prefix = true) => {
  if (REM_ENABLED) {
    if (prefix) {
      return `${s / HTML_FONT_SIZE}rem`;
    }
    return s / HTML_FONT_SIZE;
  }
};

export default convertPxToRem;

/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { ALLERGENS } from '../../../../constants/allergens';
import { validateObject } from '../../../utils/input-functions';
import ModificatorsService from '../../../../services/modificators';
import GoodsService from '../../../../services/goods';
import MainService from '../../../../services/main';
import Loader from '../loader';
import Separator from '../../separator';
import { useSelector } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Drawer,
  CustomDropzone,
  CustomSelect,
  DefinitionsProducts,
  CustomDropdown,
  Select,
} from '../../../ui-kit';

import STRINGS from '../../../../localization';

const ProductsFields = (props) => {
  const { categories } = useSelector((store) => store.stuff);

  const maxStep = 6;

  const [categoryId, setCategoryId] = useState(null);

  const [step, setStep] = useState(1);

  const [definitionId, setDefinitionId] = useState();

  const [definition, setDefinition] = useState({});

  const [substitudeQuery, setSubstitudeQuery] = useState('');

  const [selectedSubstituteProducts, setSelectedSubstituteProducts] = useState([]);

  const [productModificators, setProductModificators] = useState([]);

  const [modificatorQuery, setModificatorQuery] = useState('');

  const [selectedProductModificators, setSelectedProductModificators] = useState([]);

  const [selectedAllergens, setSelectedAllergens] = useState(ALLERGENS);

  const [posId, setPosId] = useState();

  const [substituteProducts, setSubstituteProducts] = useState();

  const [posTitle, setPosTitle] = useState();

  const [posTitle2, setPosTitle2] = useState();

  const [weight, setWeight] = useState();

  const [energyValueKcal, setEnergyValueKcal] = useState();

  const [nutrients, setNutrients] = useState({});

  const [status, setStatus] = useState('created');

  const [price, setPrice] = useState([]);

  const [file, setFile] = useState(null);

  const [glovoFile, setGlovoFile] = useState(null);

  const [yandexFile, setYandexFile] = useState(null);

  const [chocofoodFile, setChocofoodFile] = useState(null);

  const [woltFile, setWoltFile] = useState(null);

  const initialize = () => {
    if (!props.loadedProduct) {
      setGlovoFile(null);
      setYandexFile(null);
      setChocofoodFile(null);
      setWoltFile(null);
      setStep(1);
      setPosId();
      setStatus('created');
      setPrice([]);
      setWeight();
      setSelectedAllergens(ALLERGENS);
      setPosTitle();
      setPosTitle2();
      setNutrients({});
      setCategoryId(null);
      setFile();
      setEnergyValueKcal();
      setDefinitionId();
      setSelectedSubstituteProducts([]);
      setSelectedProductModificators([]);
    } else {
      setStep(1);
    }
  };

  const prepareFile = async (fileId) => {
    if (fileId) {
      try {
        let resp = await MainService.getFileById(fileId);
        resp = Object.assign(resp, { preview: URL.createObjectURL(resp), id: fileId });
        return resp;
      } catch (error) {}
    } else {
      return null;
    }
  };

  const getDefaultSubstituteProducts = async (prodIds) => {
    try {
      const prods = await Promise.all(
        prodIds.map(async (id) => await GoodsService.getProductById(id)),
      );
      return prods.map((el) => ({ posId: el.posId, posTitle: el.posTitle, number: 1 }));
    } catch {}
  };

  const getDefaultProductModificators = async (modIds) => {
    const mods = [];
    for (const id of modIds) {
      try {
        const resp = await ModificatorsService.getModificatorById(id);
        mods.push(resp);
      } catch (error) {}
    }
    return mods.map((el) => ({ posId: el.posId, title: el.posTitle, number: 1 }));
  };

  const prepareAllergens = (def) => {
    let options = [];
    options = ALLERGENS.map((el) => ({ ...el, number: 0 }));
    if (selectedAllergens.length > 0) {
      options.filter((el) => def?.includes(el.key)).forEach((el) => (el.number = 1));
    }
    return options;
  };

  const [validation, setValidation] = useState();

  const SCHEME = {
    categoryId: (value) => !!value,
    definitionId: (value) => Number.isInteger(value),
    selectedSubstituteProducts: (value) => true,
    selectedAllergens: (value) => true,
    selectedProductModificators: (value) => true,
  };

  const [isFocus, setIsFocus] = useState({
    definitionId: true,
    selectedSubstituteProducts: true,
    selectedAllergens: true,
    selectedProductModificators: true,
    categoryId: true,
  });

  const mapData = async (prod) => {
    props.setLoading(true);
    try {
      setPosId(prod?.posId);
      setStatus(prod?.status || 'created');
      setPrice(prod?.prices?.map((el) => el?.amount) || [0, 0]);
      setWeight(prod?.weight);
      if (prod?.allergens && Array.isArray(prod?.allergens)) {
        setSelectedAllergens(prepareAllergens(prod?.allergens));
      }
      setPosTitle(prod?.posTitle);
      setPosTitle2(prod?.posTitle2);
      setCategoryId(prod?.categoryId);
      setNutrients(prod?.nutrients);
      setEnergyValueKcal(prod?.energyValueKcal);
      setDefinitionId(prod?.definition?.id);
      setFile(await prepareFile(prod?.fileId));
      setGlovoFile(await prepareFile(prod?.glovoFileId));
      setYandexFile(await prepareFile(prod?.yandexFileId));
      setChocofoodFile(await prepareFile(prod?.chocofoodFileId));
      setWoltFile(await prepareFile(prod?.woltFileId));
      if (prod?.substituteProducts.length) {
        const prodIds = prod?.substituteProducts?.map((el) => el.substituteProductId);
        setSelectedSubstituteProducts(await getDefaultSubstituteProducts(prodIds));
      }
      if (prod?.productModificators.length > 0) {
        const modIds = prod?.productModificators.map((el) => el.modificatorId);
        setSelectedProductModificators(await getDefaultProductModificators(modIds));
      }
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const changeFocus = (field, focus) => {
    setIsFocus((prevState) => ({ ...prevState, [field]: focus }));
  };

  useEffect(async () => {
    const getData = async () => {
      const resp = await props.getModificators({
        langId: 1,
        size: 5,
        page: 1,
        search: modificatorQuery,
      });
      setProductModificators(resp?.data);
    };

    if (modificatorQuery?.length > 0) {
      getData();
    }
  }, [modificatorQuery]);

  useEffect(async () => {
    if (props.isOpen === true) {
      await mapData(await props.getProductById(props.selectedProduct?.id));
    }
  }, [props?.selectedProduct]);

  useEffect(() => {
    if (props?.loadedProduct) {
      mapData(props?.loadedProduct);
    }
  }, [props?.loadedProduct]);

  useEffect(() => {
    if (!props.isOpen) {
      setTimeout(() => initialize(), 100);
    }
  }, [props.isOpen]);

  useEffect(() => {
    setValidation(
      validateObject(
        Object.assign({
          definitionId,
          selectedSubstituteProducts,
          selectedAllergens,
          selectedProductModificators,
          categoryId,
        }),
        SCHEME,
      ),
    );
  }, [
    definitionId,
    categoryId,
    selectedSubstituteProducts,
    selectedAllergens,
    selectedProductModificators,
  ]);

  useEffect(async () => {
    if (props.selectedProduct?.id || !!posId) {
      const resp = await props.getSubstitude({
        search: substitudeQuery,
        langId: 1,
        mainProductId: props.selectedProduct?.id || posId,
      });
      setSubstituteProducts(resp?.map((el) => ({ posTitle: el.value, posId: el.id })));
    }
  }, [substitudeQuery]);

  return (
    <Drawer
      anchor="right"
      visible={props.isOpen}
      title={props.title}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <div className="modal">
        <Form
          onSubmit={() => {
            props.saveChanges(posId, {
              categoryId: Number.parseInt(categoryId),
              file,
              glovoFile,
              yandexFile,
              chocofoodFile,
              woltFile,
              weight: weight,
              nutrients: { ...nutrients },
              price: [...price],
              posTitle: posTitle,
              energyValueKcal: energyValueKcal,
              posTitle2: posTitle2,
              allergens: selectedAllergens.filter((el) => el.number === 1).map((el) => el.key),
              definitionId: definitionId,
              definition,
              substituteProducts: selectedSubstituteProducts.map((el) => ({
                substituteProductId: el.posId,
              })),
              productModificators: selectedProductModificators.map((el) => ({
                isAdditional: true,
                modificatorId: el.posId,
              })),
            });
          }}
        >
          <div className="header">
            <div className="title">
              <span>{props.title}</span>
            </div>
            <div className="step">
              <span>{`${STRINGS.screens.modal.step} ${step}`}</span>
            </div>
          </div>
          {props.loading === true ? (
            <Loader />
          ) : (
            <div className="body">
              {step === 1 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.goods.definition} *</span>
                    </div>
                    <DefinitionsProducts
                      className="container_fullwidth_one_first"
                      label={`${STRINGS.screens.goods.chooseDefinition}`}
                      definitionId={definitionId}
                      setDefinitionId={setDefinitionId}
                      definition={definition}
                      setDefinition={setDefinition}
                      inputType={
                        !isFocus?.definitionId && !validation?.data?.definitionId?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('definitionId', true)}
                      onBlur={() => changeFocus('definitionId', false)}
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.goods.category} *</span>
                    </div>
                    <Select
                      className="container_fullwidth_one_first"
                      size="large"
                      label={STRINGS.screens.aggregators.qh}
                      value={categoryId}
                      onChange={setCategoryId}
                      options={categories}
                    />
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.imgs}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone maxFiles={1} files={file} onChange={setFile} />
                      <span>{STRINGS.screens.combo.qq}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone maxFiles={1} files={glovoFile} onChange={setGlovoFile} />
                      <span>{STRINGS.screens.combo.qw}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone maxFiles={1} files={yandexFile} onChange={setYandexFile} />
                      <span>{STRINGS.screens.combo.qe}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone
                        maxFiles={1}
                        files={chocofoodFile}
                        onChange={setChocofoodFile}
                      />
                      <span>{STRINGS.screens.combo.qr}</span>
                    </div>
                    <div className="img-container">
                      <CustomDropzone maxFiles={1} files={woltFile} onChange={setWoltFile} />
                      <span>{STRINGS.screens.combo.qt}</span>
                    </div>
                  </div>
                </>
              )}
              {step === 3 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.goods.substitute}</span>
                    </div>
                    <CustomSelect
                      className="container_fullwidth_one_first"
                      label={STRINGS.screens.goods.qh}
                      data={substituteProducts}
                      value={substitudeQuery}
                      onChange={setSubstitudeQuery}
                      selectedOptions={selectedSubstituteProducts}
                      setSelectedOptions={setSelectedSubstituteProducts}
                      multiple={false}
                      inputType={
                        !isFocus?.selectedSubstituteProducts &&
                        !validation?.data?.selectedSubstituteProducts?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('selectedSubstituteProducts', true)}
                      onBlur={() => changeFocus('selectedSubstituteProducts', false)}
                    />
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.goods.allergens}</span>
                    </div>
                    <CustomDropdown
                      className="container_fullwidth_one_first"
                      label={STRINGS.screens.goods.qj}
                      data={selectedAllergens}
                      setData={setSelectedAllergens}
                      multiple={false}
                      inputType={
                        !isFocus?.selectedAllergens && !validation?.data?.selectedAllergens?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('selectedAllergens', true)}
                      onBlur={() => changeFocus('selectedAllergens', false)}
                    />
                  </div>
                </>
              )}
              {step === 5 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.ingredients.ingredients}</span>
                    </div>
                    <CustomSelect
                      className="container_fullwidth_one_first"
                      label={STRINGS.screens.ingredients.typeIngredients}
                      data={productModificators}
                      value={modificatorQuery}
                      onChange={setModificatorQuery}
                      selectedOptions={selectedProductModificators}
                      setSelectedOptions={setSelectedProductModificators}
                      multiple={false}
                      inputType={
                        !isFocus?.selectedProductModificators &&
                        !validation?.data?.selectedProductModificators?.isValid
                          ? 'danger'
                          : 'default'
                      }
                      onFocus={() => changeFocus('selectedProductModificators', true)}
                      onBlur={() => changeFocus('selectedProductModificators', false)}
                    />
                  </div>
                </>
              )}
              {step === 6 && (
                <>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.goods.macroNutrients}</span>
                    </div>
                    <Input
                      inputType="default"
                      className="container_fullwidth_third_first"
                      label={STRINGS.screens.goods.energy.proteins}
                      size="large"
                      type="number"
                      value={nutrients?.proteins}
                      onChange={(e) => {
                        let temp = { ...nutrients };
                        temp.proteins = e;
                        setNutrients(temp);
                      }}
                      maxLength={120}
                      name="proteins"
                      defaultValue={props.goodsData?.proteins}
                    />
                    <Input
                      inputType="default"
                      className="container_fullwidth_third_first"
                      label={STRINGS.screens.goods.energy.fats}
                      value={nutrients?.fats}
                      type="number"
                      onChange={(e) => {
                        let temp = { ...nutrients };
                        temp.fats = e;
                        setNutrients(temp);
                      }}
                      size="large"
                      maxLength={120}
                    />
                    <Input
                      inputType="default"
                      className="container_fullwidth_third_first"
                      label={STRINGS.screens.goods.energy.carbs}
                      value={nutrients?.carbohydrates}
                      type="number"
                      onChange={(e) => {
                        let temp = { ...nutrients };
                        temp.carbohydrates = e;
                        setNutrients(temp);
                      }}
                      size="large"
                      maxLength={120}
                      name="carbs"
                    />
                  </div>
                  <div className="container_fullwidth">
                    <div className="subtitle">
                      <span>{STRINGS.screens.modal.additional}</span>
                    </div>
                    <Input
                      inputType="default"
                      className="container_fullwidth_one_first"
                      label={STRINGS.screens.goods.weigth}
                      size="large"
                      type="number"
                      value={weight}
                      onChange={setWeight}
                      maxLength={4}
                    />
                    <Input
                      inputType="default"
                      className="container_fullwidth_one"
                      label={STRINGS.screens.goods.energyValueKcal}
                      size="large"
                      type="number"
                      value={energyValueKcal}
                      onChange={setEnergyValueKcal}
                      maxLength={4}
                    />
                    <Input
                      inputType="default"
                      className="container_fullwidth_one"
                      label={STRINGS.screens.goods.energy.saturatedFats}
                      size="large"
                      type="number"
                      maxLength={120}
                      value={nutrients?.saturatedFat}
                      onChange={(e) => {
                        let temp = { ...nutrients };
                        temp.saturatedFat = e;
                        setNutrients(temp);
                      }}
                    />
                    <Input
                      inputType="default"
                      className="container_fullwidth_one"
                      label={STRINGS.screens.goods.energy.fiber}
                      size="large"
                      value={nutrients?.cellulose}
                      type="number"
                      onChange={(e) => {
                        let temp = { ...nutrients };
                        temp.cellulose = e;
                        setNutrients(temp);
                      }}
                      maxLength={120}
                    />
                    <Input
                      inputType="default"
                      className="container_fullwidth_one"
                      label={STRINGS.screens.goods.energy.sugar}
                      size="large"
                      type="number"
                      value={nutrients?.sugar}
                      onChange={(e) => {
                        let temp = { ...nutrients };
                        temp.sugar = e;
                        setNutrients(temp);
                      }}
                      maxLength={120}
                    />
                    <Input
                      inputType="default"
                      className="container_fullwidth_one"
                      label={STRINGS.screens.goods.energy.salt}
                      size="large"
                      type="number"
                      value={nutrients?.salt}
                      onChange={(e) => {
                        let temp = { ...nutrients };
                        temp.salt = e;
                        setNutrients(temp);
                      }}
                      maxLength={120}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <Separator indent={80} />
          <div className="footer">
            {step !== 1 ? (
              <Button
                disabled={step === 1}
                className="button"
                label={STRINGS.screens.modal.back}
                size="large"
                type="default"
                typeButton="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
                }}
                loading={props.loading}
              />
            ) : (
              <div />
            )}
            {step === maxStep ? (
              <Button
                //disabled={!validation.isValid}
                className="button"
                label={STRINGS.screens.modal.save}
                size="large"
                //type={!validation.isValid ? 'disabled' : 'primary'}
                typeButton="submit"
                loading={props.loading}
              />
            ) : (
              <Button
                className="button"
                label={STRINGS.screens.modal.next}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setStep((prevState) => prevState + 1);
                }}
                type="primary"
                loading={props.loading}
              />
            )}
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

ProductsFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  userData: PropTypes.object,
  saveChanges: PropTypes.func,
  title: PropTypes.string,
  onChange: PropTypes.func,
  withPassword: PropTypes.bool,
};
ProductsFields.defaultProps = {
  isOpen: false,
  close: () => {},
  saveChanges: () => {},
  title: '',
  onChange: () => {},
  withPassword: false,
};

export default ProductsFields;

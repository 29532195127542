import STRINGS from '../localization';

export const orderCategories = [
    {id: 1, key: 'active', label: STRINGS.screens.orders.qq, statuses: 'statuses=created&statuses=ready'},
    {id: 2, key: 'archieve', label: STRINGS.screens.orders.qw, statuses: 'statuses=failed&statuses=picked&statuses=canceled&statuses=ready'},
];

export const menuCategories = [
    {id: 1, key: 'products', label: STRINGS.screens.categories.AF.AFA},
    {id: 2, key: 'combo', label: STRINGS.screens.categories.AF.AFB},
    {id: 3, key: 'modificators', label: STRINGS.screens.categories.AF.AFC},
];

export const comboSizes = [
    {label: 'M'},
    {label: 'L'},
];

export const allCategories = [
    {key: '', label: STRINGS.screens.categories.AF.AFA},
    {key: 'combo', label: STRINGS.screens.categories.AF.AFB},
    {key: 'modificators', label: STRINGS.screens.categories.AF.AFC},
];

/**
 * @prettier
 */

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import colors from '../../../styles/colors';
import { Icon } from '../../../../ui-kit';
import STRINGS from '../../../../localization';
import './styles.scss';

const Order = (props) => {
  const ref = useRef();
  const ordersCancelled = useSelector((store) => store.orders.cancelledOrders);

  const [isPlaying, setIsPlaying] = useState(false);
  const [key, setKey] = useState(0);
  const [isCanceled, setIsCanceled] = useState(false);

  const path = props.readyOrder ? props.el?.orderBody?.orderMetadata : props.el?.orderMetadata;

  const aggregator =
    props.readyOrder && props.el.aggregator?.key ? props.el.aggregator.key : 'mobile';

  const playChange = () => {
    setIsPlaying(!isPlaying);

    setKey((prevKey) => prevKey + 1);
  };

  const aggregatorsForShowDate = ['wolt', 'yandex'];

  useEffect(() => {
    const el = ordersCancelled.find((el) => el === props.el.orderPatternId);
    if (el) {
      setIsCanceled(true);
    }
  }, []);

  const pressHandler = () => {
    let qwe;
    ref.current.classList.toggle('color-delay');

    const arr = [...ref.current.classList];

    if (arr.includes('color-delay')) {
      ref.current.parentElement.classList.add('delete-order');
      props.handlerSend(props.el, 'picked');
    }

    clearTimeout(qwe);
    return [false];
  };

  const getTypeOfPaymentByLanguage = (type) => {
    if (type === 'cash') {
      return STRINGS.components.currentOrdersButton.paymentTypeNameCash;
    }

    return STRINGS.components.currentOrdersButton.paymentTypeNameCard;
  };

  return (
    <div className="card-wrapper">
      {props.readyOrders === false && (
        <a
          className={`card-status ${isCanceled ? 'gray' : 'green'}`}
          ref={ref}
          onClick={() => props.handlerSend(props.el, 'picked')}
        >
          <div className="info-container">
            <span className="circle-confirm">
              <Icon name="confirm" width={16} height={16} />
            </span>
            <p className="status-text">{STRINGS.components.currentOrdersButton.ready}</p>
          </div>
          {/* {isPlaying ? (
            <CountdownCircleTimer
              key={key}
              isPlaying={isPlaying}
              duration={5}
              colors={[[colors.color_white._0]]}
              onComplete={pressHandler}
              size={30}
              strokeWidth={1}
              trailStrokeWidth={0}
              rotation="counterclockwise"
            >
              {({ remainingTime }) => <p className="time-string">{remainingTime}</p>}
            </CountdownCircleTimer>
          ) : (
            <div className="info-container">
              <span className="circle-confirm">
                <Icon name="confirm" width={16} height={16} />
              </span>
              <p className="status-text">{STRINGS.components.currentOrdersButton.ready}</p>
            </div>
          )} */}
        </a>
      )}
      <a className="main-container" onClick={() => props.changeOrderView(props.el.orderPatternId)}>
        <div className="title-wrapper">
          <p className="title-text">
            {STRINGS.components.currentOrdersButton.oderNumber + props.el?.orderPatternId}
          </p>
          <div className="sum-text-wrapper">
            <p className="sum-text">
              {STRINGS.components.currentOrdersButton.sum}
              <span className="title-text"> {path?.price} тг</span>
            </p>
          </div>
        </div>
        <div className="additional-info-wrapper">
          <p className="info-title">
            {STRINGS.components.currentOrdersButton.service}
            <span className="info-text"> {aggregator}</span>
          </p>
          <p className="info-title">
            {STRINGS.components.currentOrdersButton.position}
            <span className="info-text"> {path?.amount}</span>
          </p>
          <p className="info-title">
            {STRINGS.components.currentOrdersButton.orderTime}
            <span className="info-text">
              {' '}
              {path?.receiptDateTime && new Date(path?.receiptDateTime).toLocaleTimeString()}
            </span>
          </p>
          <p className="info-title">
            {STRINGS.components.currentOrdersButton.orderCode}
            <span className="info-text"> {path?.orderCode ?? '-'}</span>
          </p>
          <p className="info-title">
            {STRINGS.components.currentOrdersButton.externalCode}
            <span className="info-text"> {props.el?.orderId ?? '-'}</span>
          </p>
          <p className="info-title">
            {STRINGS.components.currentOrdersButton.paymentType}
            <span className="info-text">
              {' '}
              {getTypeOfPaymentByLanguage(props.el?.orderBody?.orderMetadata?.paymentType)}
            </span>
          </p>
          {props.possibleCancel && props.el?.orderOperator !== 'MOBILE_APP' ? (
            <p className="info-title">
              {STRINGS.components.currentOrdersButton.courier}
              <span className="info-text">
                {path?.estimatedPickupTime === '' || aggregatorsForShowDate.includes(aggregator)
                  ? ' -'
                  : new Date(path?.estimatedPickupTime).toLocaleTimeString()}
              </span>
            </p>
          ) : (
            <p className="info-title">
              {STRINGS.components.currentOrdersButton.client}
              <span className="info-text">
                {props.el?.orderOnTime
                  ? new Date(props.el?.orderOnTime).toLocaleTimeString()
                  : ' -'}
              </span>
            </p>
          )}
          {props.archive && (
            <p className="info-title">
              {STRINGS.components.archiveOrder.status}
              <span className="info-text"> {STRINGS.components.statuses[props.el?.status]}</span>
            </p>
          )}
          {props.archive && props.el?.message && props.el?.message !== 'OK' && (
            <p className="info-title">
              {STRINGS.components.archiveOrder.message}
              <span className="info-text"> {props.el?.message}</span>
            </p>
          )}
        </div>
      </a>
    </div>
  );
};

export default Order;

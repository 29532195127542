import React from 'react';
import Paper from '../paper';
import './styles.scss';

const MainTabs = (props) => {
  return (
    <Paper className="main-tabs">
      {props.data?.map((el, index) => (
        <button
          key={index}
          className={`main-tabs_tab${props.active === el?.key ? ' active' : ''}`}
          value={el?.key}
          type="button"
          data-type={el?.key}
          data-active={props.value === el?.key}
          onClick={() => props.onClick(el?.key)}
        >
          {el.label}
        </button>
      ))}
    </Paper>
  );
};

export default MainTabs;

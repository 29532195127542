/** @format */

import * as React from 'react';
import Product from './product';

export default function Category(props) {
  const { menu, category } = props;

  return (
    <>
      {category.products.map((productId) => (
        <Product menu={menu} productId={productId} key={productId} />
      ))}
    </>
  );
}

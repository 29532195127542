/**
 * @prettier
 */

import { api, debug } from '../index';
import { URL } from '../config';

export default class YandexService {
  static async sendMenu({ data, restaurantId }) {
    let link = `${URL}yandex/menu/${restaurantId}/send`;
    try {
      const request = await api.post(link, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      debug.success(`Send menu to Yandex from ${restaurantId} restaurant result`, request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with sending menu to Yandex from ${restaurantId} restaurant`, error);
      throw await error.response?.json();
    }
  }
  static async setClosing(data) {
    let link = `${URL}yandex/${data.restaurantId}/closing`;
    try {
      const request = await api.patch(link, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ open: data.isActive }),
      });

      debug.success('Data set Yandex closing retrieval result', request);

      return true;
    } catch (error) {
      debug.error('Failed with configure closing', error);
      throw await error.response?.json();
    }
  }
  static async getClosing(id) {
    let link = `${URL}yandex/${id}/closing`;
    try {
      const request = await api.get(link, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success(`Get closing from Yandex for ${id} restaurant`, request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with get closing from Yandex for ${id} restaurant`, error);
      throw await error.response?.json();
    }
  }
  static async getClosingCrm(id) {
    let link = `${URL}yandex/crm/${id}/closing`;
    try {
      const request = await api.get(link, {
        headers: {
          'content-type': 'application/json',
        },
      });

      debug.success(`Get closing from Yandex for ${id} restaurant`, request);

      return request.json();
    } catch (error) {
      debug.error(`Failed with get closing from Yandex for ${id} restaurant`, error);
      throw await error.response?.json();
    }
  }
}
